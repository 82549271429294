import * as React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Box from '@mui/material/Box';
import { Paper } from '@mui/material';


var nfObject = new Intl.NumberFormat("en-US");
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    
    return (
        <div
            key={`simple-tabpanel-${index}`}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <List>
                   
                    {/* <Typography> */}
                        {children}
                    {/* </Typography> */}
              
                </List>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function TabsComponent({ availArray, compArray, totalAvail, printing }) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    var availIndex = 0
    var transactionIndex = 1
    if (!availArray || availArray.length === 0) {
        transactionIndex = 0
    }
    


    var tabs = []
    if (availArray && availArray.length > 0) {
        tabs.push(<Tab key={1} label={"Available Spaces (" + availArray.length + ") | " +
            ((totalAvail > 0) ? (nfObject.format(totalAvail) + " SF"): "")}  />)

        // ...a11yProps(availIndex)
    }
    if (compArray && compArray.length > 0) {
        tabs.push(<Tab key={2} label={"Lease Comps(" + compArray.length + ")"}/>)
    }




    return (
        <Box sx={{ width: '100%', paddingBottom: 2}}>
            <Box >
                <Tabs value={value} onChange={handleChange} >
                    {tabs}


                </Tabs>
            </Box>
            {availArray && availArray.length > 0 ? <Box  style={
                {
                    maxHeight: printing? 400: 600, overflow: 'scroll', scrollbarWidth:'none'
                }
                
            } sx={{
           

                    '&::-webkit-scrollbar': { display: 'none' } }}>
                <CustomTabPanel value={value} index={availIndex}>
                    {availArray}
                </CustomTabPanel></Box> : <></>}
            {compArray && compArray.length > 0 ? <Paper style={{
                maxHeight: printing ? 400 : 600, overflow: 'scroll', scrollbarWidth: 'none'
            }}
                sx={{


                    '&::-webkit-scrollbar': { display: 'none' }
                }}>
                <CustomTabPanel value={value} index={transactionIndex}>
                    {compArray}
                </CustomTabPanel> </Paper> : <></>}

        </Box>
    );
}
