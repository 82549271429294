import  React from "react";
//import BingMapsReact from "bingmaps-react";
import {useSearchParams} from "react-router-dom"
import {useQuery} from 'react-query'
import ParcelDetail from "../components/Parcel/ParcelDetail";
import Header from "../components/Common/Header";




export default function Parcel({user}) {
  let [query, setQuery] = useSearchParams();
  let id = query.get("id")
  var qs = process.env.REACT_APP_DATABASE + "api/getParcel?id=" + id +"&contactId="+user.contact.id



  
  const fetchData = async () => {
    const result = await fetch(qs)
    return  await result.json()
}


// const fetchPeopleData = async () => {
//   const result = await fetch(process.env.REACT_APP_DATABASE + 'api/getContacts?contactId='+user.contact.id)
//   return  await result.json()
// }

  const { data:propertyDataArray, status:statusPropertyData, error:errorPropertyData } = useQuery('parcelData', fetchData)
  // const { data:peopleDataArray, status:statusPeopleData, error:errorPeopleData } = useQuery('peopleData', fetchPeopleData)



  if (statusPropertyData === "loading"
    // || statusPeopleData === "loading"
  ) {
    
  return (
    <>
      <div className="absolute top-1/3 w-full items-center flex flex-col">
        <img style={{width: 100}} src="https://images.cubicsearch.com/Wait.gif" />
        <div>Loading Parcel Data...</div>
      </div>
    </>
  );

}
// if (statusPropertyData === "error" || statusPeopleData === "error") {
//   return(errorPropertyData?.message)

// }
  if (!propertyDataArray || !propertyDataArray.id
    // || !peopleDataArray
  ) {
  return (   <div>
    <div>
      <Header user={user}/>
    </div>
    <div className = "py-4">
      <img src="https://images.cubicsearch.com/noresults.png" className="w-full"/>
    </div>
  </div>)
}
const propertyData = propertyDataArray;
// const people =  getContacts(peopleDataArray)



   

    return (
        <div>
            <div>
          <ParcelDetail parcelSearchResults={propertyData}
            // people={people}
            user={user} />
            </div>
        </div>
    )
}

 function getContacts(data) {
 // ;
  var peopleArray = []
  //   const data = await searchResults.json();
   data.contact.map((person) => {
       var myJsonToAdd = {}
       if (person.images.length >0 ) 
       {
           if(person){
          myJsonToAdd = {"id": person.id,
                      "name" : person.FirstName + " " + person.LastName + " - " + checkOffice(person),
                      "imageURL" : person.images[0].img
              
                      
                      }
                  }
                  else {
                      myJsonToAdd = {"id": person.id,
                      "name" : person.FirstName + " " + person.LastName,
                      "imageURL" : person.images[0].img
              
                      
                      }
                  }
         
                  
      }
      else 
      {
          if (person.office){
              
          myJsonToAdd =  {"id": person.id,
          "name" : person.FirstName + " " + person.LastName + " - " + checkOffice(person),
          "imageURL" : "https://images.cubicsearch.com/UserLogo.png"
  
          
          }
      }
          else {
              myJsonToAdd =  {"id": person.id,
          "name" : person.FirstName + " " + person.LastName,
          "imageURL" : "https://images.cubicsearch.com/UserLogo.png"
  
          
          }
  
          }
          
  
      }
      peopleArray.push(myJsonToAdd)
   })
    //  
      return peopleArray.sort(sortByProperty("name"))
  }

  function sortByProperty(property){  
    return function(a,b){  
       if(a[property] > b[property])  
          return 1;  
       else if(a[property] < b[property])  
          return -1;  
   
       return 0;  
    }  

 }

 function checkOffice (person) {
   if (person.office && person.office.company) {
     return person.office.company.Name
   }
   else return "Add Company"
 }