export default function WebsiteNew() {

return (

<div className="px-4 py-5 sm:px-6">
<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
<div className="sm:col-span-2">
    {/* <dt className="text-sm font-medium text-gray-500">Address</dt> */}
    <dd className="mt-1 text-sm text-gray-900">{inputComponent("text", "URL Link", "id", "src")}   </dd>
    {/*<dd className="mt-1 text-sm text-gray-900">{nfObject.format(SizeMin)} - {nfObject.format(SizeMax)} SF</dd>*/}
</div>
<div className="sm:col-span-2">
    {/* <dt className="text-sm font-medium text-gray-500">Description</dt> */}
    <dd className="mt-1 text-sm text-gray-900">{inputComponent("text", "Description", "id", "Description")}</dd>
    {/*<dd className="mt-1 text-sm text-gray-900">{AskRate ? nfObjectRate.format(AskRate) : "Please Inquire"}/SF/Month {Type}</dd>*/}
    
</div>
</dl>
</div>)

}


function inputComponent(inputType, defaultShow , id, name, tailwind) {
    if (inputType === 'date') {
        return(
            <input
                className="shadow-sm focus:ring-red-500 focus:border-red-500  sm:text-sm border-gray-300 rounded-md"
                type={inputType}
                name={name}
                id={name}
                defaultValue={defaultShow && defaultShow !== 'null'? defaultShow : ""}
            />  
        )
    }
    else {
    return(
       
        <input
            className={"w-full shadow-sm focus:ring-red-500 focus:border-red-500  sm:text-sm border-gray-300 rounded-md " + tailwind}
            type={inputType}
            name={name}
            id={name}
            defaultValue={defaultShow && defaultShow !== 'null'? defaultShow : ""}            
        />    
      
    )
    }
 }