import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { formatPhoneNumber } from "../Common/FormatPhoneNumber";
import { Typography, Link } from "@mui/material";

function ContactView({ contactDataIn }) {
    
    contactDataIn = contactDataIn || {}; // Ensure contactDataIn is an object to avoid null references

    // Number formatting objects
    const nfObject = new Intl.NumberFormat('en-US');
    const nfObjectRate = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    const nfObjectPercent = new Intl.NumberFormat('en-US', { style: 'percent' });

    // Fields to show
    const fieldsToShow = ["Email", "Linkedin", "Website", "Phone", "Notes", "Title", "License"];
    const firstName = contactDataIn.FirstName || '';
    const lastName = contactDataIn.LastName || '';
 
    return (
        <Grid2>
            <Grid2><Typography>{firstName + " " + lastName}</Typography></Grid2>
            {fieldsToShow.map((field) => {
                const value = contactDataIn[field];
                const regex = /email|website|linkedin/i; // The 'i' makes it case-insensitive

                if (value) { // Check if the value is not undefined or null
                    if (field.includes("Phone")) {
                        return (<Grid2 key={field}><Typography>{formatPhoneNumber(value)}</Typography></Grid2>);
                    }
                    else if (regex.test(field)) {
                        return <Grid2 key={field}>
                            <CustomLink value={value}/></Grid2>
                    }
                    else if (field.includes("Notes"))
                    {
                     return <></>
                    }
                    
                    else {
                        return <Grid2 key={field}><Typography>{value}</Typography></Grid2>;
                    }
                }
                return null; // Return null if value is undefined or null, this won't render anything
            })}
        
        </Grid2>
    );
}




export function CustomLink({ value }) {
    const MAX_LENGTH = 30; // Define maximum length for the link display

    // Function to determine if the value is an email address
    const isEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    // Determine if the value is an email and prepend mailto: if it is
    const href = isEmail(value) ? `mailto:${value}` :
        (value.startsWith('http://') || value.startsWith('https://') ? value : `http://${value}`);

    // Function to truncate the link text with an ellipsis if too long
    const displayText = (text) => {
        return text.length > MAX_LENGTH ? `${text.substring(0, MAX_LENGTH)}...` : text;
    };

    return (
        <Grid2>
            <a href={href} target="_blank" rel="noopener noreferrer"
                style={{
                    cursor: 'pointer',
                    color: '#0073e6', // Light blue color typical for links
                    textDecoration: 'none', // Remove underline
                }}>
                {displayText(value)}
            </a>
        </Grid2>
    );
}

function findStringCaseInsensitive(array, searchString) {
    const lowerSearchString = searchString.toLowerCase();
    return array.findIndex(item => item.toLowerCase() === lowerSearchString);
}

export default ContactView;
