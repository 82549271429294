import { formatPhoneNumber } from "../Common/FormatPhoneNumber";
import { mobileAndTabletCheck } from "../Search/Deck";
import { ContactDataAvail } from "../Search/InfoCard";
import Grid from "@mui/material/Unstable_Grid2/Grid2"
export default function ContactListView({ peopleIn }) {
  return (
    <Grid container spacing={2}>
      {peopleIn.map((person) => {
        if (person.avail_contacts && person.avail_contacts.length > 0) {
          person.avail_contact = person.avail_contacts[0]
        }
        return (
          <>
            <Grid>
              {ContactDataAvail(person)}
            </Grid>
          </>
        )
      })}
    </Grid>
  )
}

// function formatPhoneNumber(phoneNumberString) {
//     var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
//     var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
//     if (match) {
//       return '(' + match[1] + ') ' + match[2] + '-' + match[3]
//     }
//     return null
//   }
