import React, { useMemo, useRef } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import ReactToPrint from "react-to-print";

// Styled components for consistent styling
const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  height: "100%", // Use full height of dialog
}));

const PrintButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

// Component to be printed
const PrintableList = React.forwardRef(({ aggregatedData }, ref) => (
  <div ref={ref}>
    <Typography variant="h5" gutterBottom>
      Office Recipients List
    </Typography>
    <List>
      {aggregatedData.map((office, index) => (
        <ListItem key={index} alignItems="flex-start">
          <ListItemAvatar>
            <Avatar
              src={office.logo}
              alt={`${office.name} Logo`}
              variant="square"
              sx={{
                width: 56,
                height: 56,
                mr: 2,
                borderRadius: 1,
                border: "1px solid #ccc",
                backgroundColor: "#fff",
              }}
            >
              {/* Fallback content if logo fails to load */}
              {!office.logo && (
                <Typography variant="h6" color="textSecondary">
                  {office.name.charAt(0)}
                </Typography>
              )}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant="h6" component="div">
                {office.name}
              </Typography>
            }
            secondary={
              <>
                <Typography variant="body2" color="textSecondary">
                  {office.StreetNumber} {office.StreetName}
                  {office.Suite ? `, Suite ${office.Suite}` : ""}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {office.City}, {office.State}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Number of Recipients: {office.count}
                </Typography>
              </>
            }
          />
        </ListItem>
      ))}
    </List>
  </div>
));

PrintableList.propTypes = {
  aggregatedData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      logo: PropTypes.string,
      count: PropTypes.number.isRequired,
      StreetNumber: PropTypes.string,
      StreetName: PropTypes.string,
      Suite: PropTypes.string,
      City: PropTypes.string,
      State: PropTypes.string,
    })
  ).isRequired,
};

function OfficeListDialog({ open, onClose, recipients }) {
  const componentRef = useRef();

  // Aggregate data: Group by company and count unique recipients
  const aggregatedData = useMemo(() => {
    const data = {};

    recipients.forEach((recipient) => {
      const company = recipient.office?.company;
      const streetNumber = recipient.office?.StreetNumber || "";
      const streetName = recipient.office?.StreetName || "";
      const suite = recipient.office?.Suite || "";
      const city = recipient.office?.City || "";
      const state = recipient.office?.State || "";
      const firstName = recipient.FirstName?.trim() || "";
      const lastName = recipient.LastName?.trim() || "";

      if (company && company.Name) {
        const companyName = company.Name;
        const uniqueKey = `${firstName.toLowerCase()}_${lastName.toLowerCase()}`;

        if (!data[companyName]) {
          data[companyName] = {
            name: companyName,
            logo: company.images?.[0]?.img || "",
            count: 0,
            StreetNumber: streetNumber,
            StreetName: streetName,
            Suite: suite,
            City: city,
            State: state,
            uniqueRecipients: new Set(),
          };
        }

        // Add unique recipient based on FirstName and LastName
        if (!data[companyName].uniqueRecipients.has(uniqueKey)) {
          data[companyName].uniqueRecipients.add(uniqueKey);
          data[companyName].count += 1;
        }

        // Optionally, check for address consistency
        if (
          data[companyName].StreetNumber !== streetNumber ||
          data[companyName].StreetName !== streetName ||
          data[companyName].Suite !== suite ||
          data[companyName].City !== city ||
          data[companyName].State !== state
        ) {
          console.warn(
            `Address mismatch for company "${companyName}". Using the first encountered address.`
          );
        }
      }
    });

    // Convert the aggregated data into a sorted array
    return Object.values(data)
      .map((office) => ({
        name: office.name,
        logo: office.logo,
        count: office.count,
        StreetNumber: office.StreetNumber,
        StreetName: office.StreetName,
        Suite: office.Suite,
        City: office.City,
        State: office.State,
      }))
      .sort((a, b) => b.count - a.count); // Sort by count (descending)
  }, [recipients]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Office Recipients
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <StyledDialogContent>
        {/* Scrollable Printable Area */}
        <div style={{ flexGrow: 1, overflowY: "auto" }}>
          <PrintableList aggregatedData={aggregatedData} ref={componentRef} />
        </div>

        {/* Print Button */}
        <ReactToPrint
          trigger={() => (
            <PrintButton variant="contained" color="primary" fullWidth>
              Print List
            </PrintButton>
          )}
          content={() => componentRef.current}
          documentTitle="Office Recipients List"
          pageStyle="@media print { body { -webkit-print-color-adjust: exact; } }"
        />
      </StyledDialogContent>
    </Dialog>
  );
}

OfficeListDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  recipients: PropTypes.arrayOf(
    PropTypes.shape({
      FirstName: PropTypes.string,
      LastName: PropTypes.string,
      Email: PropTypes.string,
      office: PropTypes.shape({
        company: PropTypes.shape({
          Name: PropTypes.string.isRequired,
          images: PropTypes.arrayOf(
            PropTypes.shape({
              img: PropTypes.string,
            })
          ),
        }),
        StreetNumber: PropTypes.string,
        StreetName: PropTypes.string,
        Suite: PropTypes.string,
        City: PropTypes.string,
        State: PropTypes.string,
      }),
    })
  ).isRequired,
};

export default OfficeListDialog;
