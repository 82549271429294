import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, Dialog, DialogTitle, Button, TextField, IconButton, Typography, Paper, Divider } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddListDialog from './MailLists/AddListDialog';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListIcon from '@mui/icons-material/List'; // Assuming you want to use the List icon
import RecipientsDialog from './MailLists/RecipientsDialog';
import axios from 'axios';
import ButtonBase from '@mui/material/ButtonBase';


function RecipientListsComponent({ initialRecipientLists, initialExclusionRecipientLists, edit, contactId, setLists, setExclusionLists, campaignMailer }) {


    const [recipientLists, setRecipientLists] = useState(initialRecipientLists);

    const [exclusionRecipientLists, setExclusionRecipientLists] = useState(initialExclusionRecipientLists);

    const [selectedList, setSelectedList] = useState(null);
    const [selectedExclusionList, setSelectedExclusionList] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogExclusion, setOpenDialogExclusion] = useState(false);
    const [dialogOpenAddList, setDialogOpenAddList] = useState(false);
    const [dialogOpenAddExclusionList, setDialogOpenAddExclusionList] = useState(false);

    useEffect(() => {

        setRecipientLists(initialRecipientLists);
    }, [initialRecipientLists]); // This effect runs whenever `initialRecipientLists` changes
    useEffect(() => {

        setExclusionRecipientLists(initialExclusionRecipientLists);

    }, [initialExclusionRecipientLists]); // This effect runs whenever `initialRecipientLists` changes

    const openDialogAddList = () => {
        setDialogOpenAddList(true);
    };

    const closeDialogAddList = () => {
        setDialogOpenAddList(false);
    };
    const openDialogAddExclusionList = () => {
        setDialogOpenAddExclusionList(true);
    };

    const closeDialogAddExclusionList = () => {
        setDialogOpenAddExclusionList(false);
    };



    const handleListItemClick = async (list) => {
        
        if (list.bId) {
            list.cubicId = list.id // for some reason the id starts as the bId then it switches to the cubicId after opening the choose lists dialog, if there is a bId, then id is probably cubicId this is a bug that needs attention
        }
        else {
            var cubicId = await axios.post(process.env.REACT_APP_DATABASE + "api/getCubicIdFromBIdMailList", { id: list?.id })
            if (cubicId) {
                list.cubicId = cubicId.data.cubicId
            }
            else (
                alert("no id")
            )
        }
        setSelectedList(list);
        setOpenDialog(true);
    };

    const handleExclusionListItemClick = async (list) => {
        if (list.bId) {
            list.cubicId = list.id // for some reason the id starts as the bId then it switches to the cubicId after opening the choose lists dialog, if there is a bId, then id is probably cubicId this is a bug that needs attention
        }
        else {
            var cubicId = await axios.post(process.env.REACT_APP_DATABASE + "api/getCubicIdFromBIdMailList", { id: list?.id }).catch((error) => {
                console.log(error)
            })
            if (cubicId) {
                list.cubicId = cubicId.data.cubicId
            }
            else (
                alert("no id")
            )
        }
      
        setSelectedExclusionList(list);
        setOpenDialogExclusion(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleCloseExclusionDialog = () => {
        setOpenDialogExclusion(false);
    };

    return (
        <div >
            <Paper sx={{ padding: 3, marginBottom: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Recipient Lists
                </Typography>
                <Divider sx={{ mb: 2 }} />
                {edit && (
                    <>

                        <Button variant="contained" color="primary" onClick={openDialogAddList}>
                            Choose Lists
                        </Button>
                        {/* Render your recipient lists here */}
                        <AddListDialog open={dialogOpenAddList} initialRecipientLists={recipientLists} onClose={closeDialogAddList}
                            contactId={contactId} setLists={setLists} campaignMailer={campaignMailer} disabledLists={exclusionRecipientLists} />

                    </>
                )}

                <List>
                    {recipientLists.map((list) => {



                        if (list.name !== 'Empty List') {
                            return (
                                <ListItem
                                    key={list.id}


                                >
                                    <ButtonBase
                                        onClick={() => handleListItemClick(list)}
                                        sx={{
                                            display: 'flex',
                                            width: '100%',
                                            textAlign: 'left',
                                            '&:hover': {
                                                backgroundColor: 'action.hover',
                                                // Add more hover styles here
                                            },
                                            '&:active': {
                                                backgroundColor: 'primary.light', // Style for click action
                                                color: 'primary.contrastText',
                                                // Add more active/click styles here
                                            }
                                        }}
                                    >
                                        <ListItemIcon>
                                            <ListIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={list.name} />
                                    </ButtonBase>
                                </ListItem>
                            )
                        }

                    })}
                </List>
                <Typography variant="h6" gutterBottom>
                    Exclusion Lists
                </Typography>
                <Divider sx={{ mb: 2 }} />
                {edit && (
                    <>

                        <Button variant="contained" color="primary" onClick={openDialogAddExclusionList}>
                            Choose Lists
                        </Button>
                        {/* Render your recipient lists here */}
                        <AddListDialog open={dialogOpenAddExclusionList} initialRecipientLists={exclusionRecipientLists} onClose={closeDialogAddExclusionList}
                            contactId={contactId} setLists={setExclusionLists} campaignMailer={campaignMailer} disabledLists={recipientLists} />

                    </>
                )}

                <List>
                    {exclusionRecipientLists.map((list) => (
                        <ListItem
                            key={list.id}


                        >
                            <ButtonBase
                                onClick={() => handleExclusionListItemClick(list)}
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    textAlign: 'left',
                                    '&:hover': {
                                        backgroundColor: 'action.hover',
                                        // Add more hover styles here
                                    },
                                    '&:active': {
                                        backgroundColor: 'primary.light', // Style for click action
                                        color: 'primary.contrastText',
                                        // Add more active/click styles here
                                    }
                                }}
                            >
                                <ListItemIcon>
                                    <ListIcon />
                                </ListItemIcon>
                                <ListItemText primary={list.name} />
                            </ButtonBase>
                        </ListItem>
                    ))}
                </List>
            </Paper>
            <RecipientsDialog
                open={openDialog}
                onClose={() => handleCloseDialog()}
                mailListId={selectedList?.cubicId}
                contactId={contactId}
                MailListName={selectedList?.name}
                selectedList={selectedList}
                campaignMailer={campaignMailer}
                exclusion={false}



            />
            <RecipientsDialog
                open={openDialogExclusion}
                onClose={() => handleCloseExclusionDialog()}
                mailListId={selectedExclusionList?.cubicId}
                contactId={contactId}
                MailListName={selectedExclusionList?.name}
                selectedList={selectedExclusionList}
                campaignMailer={campaignMailer}
                exclusion={true}



            />
            {/* <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{selectedList?.name}</DialogTitle>
              
            </Dialog> */}
        </div>
    );
}

export default RecipientListsComponent;
