import React from 'react';
import { TextField } from '@mui/material';

function ProjectEdit({ dataIn, setDataIn }) {
  const nfObjectRate = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });



  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataIn((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (




    <TextField
      sx={{ margin: 5 }}
      fullWidth
      variant="outlined"
      type="textarea"
      name="Notes"
      id="Notes"
      label="Notes"
      defaultValue={dataIn.Notes ? dataIn.Notes : ""}
      multiline
      rows={4}
      margin="dense"
      onChange={handleChange}
    />


  );
}

export default ProjectEdit;
