import React, { useState } from 'react';
import { Button, CircularProgress } from '@mui/material';

const LookupButton = ({ contact, handleLookup, getLookupButtonText }) => {
    const [loading, setLoading] = useState(false);

    const handleClick = async () => {
        setLoading(true);
        try {
            await handleLookup(contact.id, [!contact.Email ? 'Email' : '', !contact.Title ? 'Title' : ''].filter(Boolean));
        } catch (error) {
            console.error('Error during lookup:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Button
            size="small"
            variant="outlined"
            onClick={handleClick}
            style={{ marginTop: 4 }}
            disabled={loading}
        >
            {loading ? <CircularProgress size={20} /> : getLookupButtonText(contact)}
        </Button>
    );
};

export default LookupButton;
