import { useState } from "react";
import AvailListRows from "./AvailListRows";
import BuildingAvailableEditTable from "./BuildingAvailableEditTable";
import { Button } from "@mui/material";
import { mobileAndTabletCheck } from "../Search/Deck";
import { Save, Edit } from "@mui/icons-material";
async function setAdd(
  addingAvail,
  setAddingAvail,
  setButtonText,
  buildingId,
  availOrComp
) {
  if (addingAvail) {
    setButtonText(
      availOrComp === "Comp"
        ? <Edit sx={{width: 18, height: 18}}/>
        : <Edit sx={{width: 18, height: 18}}/>
    );
    //const data = await fetch(fetchLocation);
    ////test - console.log(data);
  } else {
    setButtonText(<Save/>);
  }
  setAddingAvail(!addingAvail);
}

export default function AvailsList({
  availsIn,
  buildingId,
  contactId,
  highestPermission,
  availOrComp,
  guest
}) {
  const [addingAvail, setAddingAvail] = useState(false);
  //test - console.log("Available In:");
  //test - console.log(availsIn);
  const [buttonText, setButtonText] = useState(<Edit sx={{width: 18, height: 18}}/>);
  const [compButtonText, setCompButtonText] = useState(
    <Edit sx={{width: 18, height: 18}}/>
  );
  const [availsState, setAvailsState] = useState(availsIn);

  var title = "Available Spaces";
  var noResults = "No Available Spaces";

  if (availOrComp === "Comp") {
    title = "Transactions & Tenants";
    noResults = "Transactions & Tenants";
  }

  if (availsState.length > 0) {
    //test - console.log("availsState updates");
    //test - console.log(availsState);
    return (
      <>
        <div role="list" className="py-4 px-0 sm:px-0 lg:px-0">
          <div className="flex sm:items-center">
       
            <div style={{ paddingLeft:  0 }} className="text-xl font-semibold text-gray-900">{title}</div>
       
    
        <Button variant={'outlined'} sx={{ marginLeft:1, minWidth:0, width: 20, height: 24,  borderColor: 'black', color: 'black' }}
                onClick={() =>
                  {if (guest === true || guest === 'true'){
                    alert("Please login or sign up to make edits.");
                }else { setAdd(
                  addingAvail,
                  setAddingAvail,
                  availOrComp === "Comp" ? setCompButtonText : setButtonText,
                  buildingId,
                  availOrComp
                )}}
              }
              >
                {availOrComp === "Comp" ? compButtonText : buttonText}
              </Button>
     
          </div>
        </div>
        {addingAvail ? (
          <BuildingAvailableEditTable
            buildingId={buildingId}
            contactId={contactId}
            dataIn={availsState}
            setDataIn={setAvailsState}
            highestPermission={highestPermission}
            availOrComp={availOrComp}

          />
        ) : (
          <AvailListRows availsIn={availsState} availOrComp={availOrComp} />
        )}
      </>
    );
  } else {
    //test - console.log("NOTHING!!!");
    //test - console.log(availsState);
    return (
      <div role="list" className="px-0 sm:px-0 lg:px-0">
        <div className="flex sm:items-center">
          <div style={{ paddingLeft: mobileAndTabletCheck() ? 0 : 0 }} className="text-xl font-semibold text-gray-900">{noResults}</div>

        
    <Button variant={'outlined'} sx={{ marginLeft:1, minWidth:0, width: 20, height: 24,  borderColor: 'black', color: 'black' }}
            onClick={() => {
              //alert("here here")
              if (guest === true || guest === 'true') {
                alert("Please login or sign up to make edits.");
              } else {
                setAdd(
                  addingAvail,
                  setAddingAvail,
                  availOrComp === "Comp" ? setCompButtonText : setButtonText,
                  buildingId,
                  availOrComp
                );
              }
            }}
          >
            {availOrComp === "Comp" ? compButtonText : buttonText}
          </Button>
 
        </div>
        {addingAvail ? (
          <BuildingAvailableEditTable
            contactId={contactId}
            buildingId={buildingId}
            dataIn={availsState}
            setDataIn={setAvailsState}
            highestPermission={highestPermission}
            availOrComp={availOrComp}
          />
        ) : (
          <AvailListRows availsIn={availsState} availOrComp={availOrComp} />
        )}
      </div>
    );
  }
}
