import React from 'react';
import InputMask from 'react-input-mask';
import { Grid, TextField } from '@mui/material';

function GroupContactNew({ person, handleChange }) {
    return (
        <Grid sx={{marginTop: 5}} container spacing={4}>
            {/* Wrap each TextField in a Grid item and set the spacing */}
            <Grid item xs={12} sm={6}>
                <TextField name="FirstName" label="First Name" defaultValue={person?.FirstName} value={person?.FirstName} onChange={handleChange} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField name="LastName" label="Last Name" defaultValue={person?.LastName} value={person?.LastName} onChange={handleChange} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField name="Email" label="Email" defaultValue={person?.Email} value={person?.Email} onChange={handleChange} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                <InputMask
                    mask="(999) 999-9999"
                    maskChar=" "
                    value={person?.Phone}
                    onChange={handleChange} // Pass the handleChange directly to InputMask
                >
                    {(inputProps) => (
                        <TextField
                            {...inputProps} // Spread inputProps to inherit necessary props from InputMask
                            name="Phone"
                            label="Phone"
                            // variant="outlined"
                            fullWidth
                        />
                    )}
                </InputMask>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField name="Linkedin" label="Linkedin" defaultValue={person?.Linkedin} value={person?.Linkedin} onChange={handleChange} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField name="Website" label="Website" defaultValue={person?.Website} value={person?.Website} onChange={handleChange} fullWidth />
            </Grid>
            {/* Continue wrapping TextField components for other fields */}
            <Grid item xs={12} sm={6}>
                <TextField name="Title" label="Title" value={person?.Title} onChange={handleChange} fullWidth />
            </Grid>
            {/* Additional fields here */}
            <Grid item xs={12}>
                <TextField
                    name="Notes"
                    label="Notes"
                    multiline
                    rows={3}
                    value={person?.Notes} // Ensure you have a `Notes` field in your state
                    onChange={handleChange}
                    fullWidth
                    margin="normal" // Adjust margin as needed
                />
            </Grid>
        </Grid>
    );
}

export default GroupContactNew;
