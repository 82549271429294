/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useState } from 'react'
import { Check as CheckIcon, ExpandMore as SelectorIcon } from '@mui/icons-material'
import { Combobox } from '@headlessui/react'
import { Autocomplete, TextField } from '@mui/material'

const roles = [
  { id: 1, name: 'Edit' },
  { id: 2, name: 'View' },
  // More users...
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function PermissionCombo({selectedPermission, setSelectedPermission}) {


  const [query, setQuery] = useState('')
  const [inputValue, setInputValue] = useState('');
  //const [selectedPermission, setSelectedPermission] = useState( { id: 1, name: 'Broker' })

  const filteredroles =
    query === ''
      ? roles
      : roles.filter((role) => {
          return role.name.toLowerCase().includes(query.toLowerCase())
        })

        if (filteredroles.length === 0  && selectedPermission.name !== query) {

            setSelectedPermission({id:1, name:query})
        }


  return (
    <Autocomplete
      id="role-combo-box"
      value={selectedPermission}
      options={roles}
      getOptionLabel={(option) => option.name}
      onChange={(event, newValue) => {
        setSelectedPermission(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        if (!roles.some(role => role.name.toLowerCase() === newInputValue.toLowerCase())) {
          setSelectedPermission({ id: 1, name: newInputValue }); // Update this logic as per your requirement
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Permission"
          variant="outlined"
        />
      )}
    />
  )
}


