import React from 'react';
import { TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

function MyDatePicker({ date, handleSpecificDateChange, index }) {
    // Convert the initial date to a dayjs object
    const [value, setValue] = React.useState(dayjs(date));

    const handleChange = (newValue) => {
        // Set the new value as a dayjs object
        setValue(newValue);

        // Make sure to convert the dayjs object back to a Date object if needed elsewhere
        handleSpecificDateChange(newValue ? newValue.toDate() : null, index);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
                label="Delivery Date"
                inputFormat="MM/DD/YYYY"
                value={value}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} />}
                disablePast
                minDate={dayjs()} // Ensure minDate is also a dayjs object
            />
        </LocalizationProvider>
    );
}

export default MyDatePicker;
