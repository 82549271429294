import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ListItem, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import axios from 'axios';

export default function ProjectsCombo({
  projectsDataArray,
  selectedProject,
  setSelectedProject,
  setCreateProject,
  contactId,
  setProjectsDataArray, // Assuming you're managing projects data array state similarly to clients,
  selectedClient,
  setAddSurvey
}) {
  const [inputValue, setInputValue] = useState(selectedProject?.ProjectName || '');
  const [resetKey, setResetKey] = useState(0);

  useEffect(() => {
    setInputValue(selectedProject?.ProjectName || '');
  }, [selectedProject]);

  const handleInputChange = (event, newInputValue, reason) => {
    setInputValue(newInputValue);
    if (reason === 'clear') {
      handleClearAndReset();
    }
  };

  const handleClearAndReset = () => {
    setInputValue('');
    setSelectedProject(null);
    setResetKey(prevKey => prevKey + 1);
    setCreateProject(false); // Adjust as needed for your logic
    setAddSurvey(false)
  };

  const endAdornment = (
    <IconButton onClick={() => handleClearAndReset()} edge="end">
      <ClearIcon />
    </IconButton>
  );

  const handleCreateProject = async (inputValue) => {
    console.log(`Create project with name: ${inputValue}`);
    var qs = process.env.REACT_APP_DATABASE + "api/createProject";
    
    var newProject = await axios.post(qs, {
      contactId: contactId,
      ProjectName: inputValue,
      clientId: selectedClient.clientId
    });

    if (newProject?.data?.newProject) {
      var projectToAdd = newProject.data.newProject;
      projectToAdd.id = projectToAdd.id; // Ensure this matches your expected schema

      setProjectsDataArray([...projectsDataArray, projectToAdd]);
      setSelectedProject(projectToAdd);
      setAddSurvey(true)
    } else {
      alert("Problem with creating new project");
    }
  };

  const filteredProjects = projectsDataArray?.filter((project) =>
    project?.ProjectName?.toLowerCase().includes(inputValue?.toLowerCase())
  );

  return (
    <Autocomplete
      key={resetKey}
      value={selectedProject}
      onChange={(event, newValue) => {
        if (newValue?.inputValue) {
          // When freeSolo inputValue is used to create a new option
          handleCreateProject(newValue.inputValue);
        } else {
          setSelectedProject(newValue);
          setAddSurvey(true)
        }
      }}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      options={filteredProjects?.length > 0 ? filteredProjects : inputValue ? [{ inputValue, ProjectName: `Create "${inputValue}"` }] : []}
      getOptionLabel={(option) => option.ProjectName || option.inputValue}
      filterOptions={(options) => options}
      renderOption={(props, option) => (
        <ListItem {...props} key={option.id || option.inputValue}>
          {option.ProjectName || `Create "${option.inputValue}"`}
        </ListItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Project Name"
          InputProps={{
            ...params.InputProps,
            endAdornment: inputValue ? endAdornment : undefined,
          }}
        />
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      noOptionsText={inputValue ? `Press Enter to create "${inputValue}"` : 'No options'}
      freeSolo
    />
  );
}
