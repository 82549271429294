import React, { useState, useEffect } from 'react';
import "./split.css"; // Your CSS styles
import axios from 'axios';
import { Resizable } from 're-resizable';

import { Button, Box, Tabs, Typography, Link, Breadcrumbs, Tab, CircularProgress, Modal, Backdrop, Fade } from '@mui/material';

import EmailDisplay from './EmailDisplay';
import Header from '../Common/Header';
import CampaignDisplay from './CampaignDisplay';
import { useNavigate, useLocation } from 'react-router-dom';
import { DangerZoneCampaign } from './DangerZoneCampaign'





const CampaignPreview = ({ campaignId, contactId, user }) => {
    const [campaign, setCampaign] = useState(null);
    const [index, setIndex] = useState(0)
    const [newCampaignName, setNewCampaignName] = useState('');
    const [value, setValue] = useState('0');
    const [edit, setEdit] = useState(false)
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const sessionId = query.get('session_id');
    const [subscriptionDetails, setSubscriptionDetails] = useState(null)

    const navigate = useNavigate()


    useEffect(() => {
    
     
        if (sessionId) {
            const fetchSession = async () => {
                try {
                    const response = await axios.get(process.env.REACT_APP_DATABASE + `api/retrieve-session/${sessionId}`);
                    const { subscriptionId } = response.data;

                    // Assign the subscription to the campaign
                    var success = await axios.post(process.env.REACT_APP_DATABASE + 'api/assign-subscription', {
                        subscriptionId,
                        campaignId,
                    }).catch((error)=>{console.log(error)});
                    
                    if (success.data.success) {
                      
                        window.location.href = `/campaignDashboard/campaigns/${campaignId}/preview`;
                    }
                    else {
                        alert("Sorry, there was an issue, please click Manage Subscriptions to check your account.")
                        navigate(`/campaignDashboard/campaigns`);
                    }

                    console.log('Subscription assigned to campaign');
                } catch (error) {
                    console.error('Error assigning subscription:', error);
                }
            };

            fetchSession();
        }
    }, [sessionId, campaignId]);


    const checkSubscription = async () => {
        var subscriptionInfo = await axios.get(`${process.env.REACT_APP_DATABASE}api/check-subscription-status/${campaign.subscriptionId}`).catch((error) => { console.log(error) })

        if (subscriptionInfo?.data) {
            setSubscriptionDetails(subscriptionInfo.data)
        }
    }


    useEffect(() => {
        if (campaign?.subscriptionId) {
            checkSubscription()
        }
    }, [campaign])



    const sortCampaignMailersBySendDate = () => {
        const sortedCampaignMailers = campaign?.campaignMailers?.length > 0 ? [...campaign?.campaignMailers].sort((a, b) => {
            return new Date(a.sendDate) - new Date(b.sendDate);
        }) : [];

        setCampaign({
            ...campaign,
            campaignMailers: sortedCampaignMailers
        });
    };





    const handleSaveClick = async (campaignDetails) => {
        // Here you would typically save the edited details to your backend or state management
        setLoading(true); // Start loading
        try {


            if (campaignDetails?.params) {
                campaignDetails.params.contactId = contactId
            }
            else {
                campaignDetails.params = {}
                campaignDetails.params.contactId = contactId
            }
            const result = await axios.put(`${process.env.REACT_APP_DATABASE}api/campaigns/${campaignDetails.id}`, campaignDetails);
            // Handle success
            console.log('Save successful', result.data);
        } catch (error) {
            // Handle error
            
            alert(error?.response?.data?.message || error.message)
            console.error('Error saving campaign details', error);
        } finally {
            setEdit(false)
            setLoading(false); // Stop loading

        }
    };

    useEffect(() => {
        // Fetch the specific campaign based on the campaignId

        setLoading(true)

        axios.get(`${process.env.REACT_APP_DATABASE}api/campaigns/${campaignId}`, {
            params: {
                contactId: contactId,
            },
        })
            .then((response) => {





                var frequencyDates = response.data.campaignMailers.map((campaignMailer) => campaignMailer.sendDate)

                if (!response?.data?.frequency?.specificDates) {
                    response.data.frequency = { frequencyTyp: 'specific', frequencyDates: [] }
                }
                else {
                    response.data.frequency.specificDates = frequencyDates || []
                }
                setCampaign(response.data);
                setLoading(false)

            })
            .catch(error => console.error('Error fetching campaign:', error));
    }, [location.search, campaignId, contactId]);

    useEffect(() => {
        sortCampaignMailersBySendDate()
    }, [])









    if (!campaign) {
        return <div>Loading...</div>;
    }


    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    // };



    const LoadingOverlay = ({ open }) => (
        <Modal
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
                style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },  // Optional: to add a backdrop color
            }}
        >
            <Fade in={open}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'transparent',  // Ensure the background is transparent
                    p: 0,  // Ensure no padding
                    m: 0,  // Ensure no margin
                    border: 'none',  // Ensure no border
                    outline: 'none',  // Ensure no outline
                }}>
                    <CircularProgress />
                </Box>
            </Fade>
        </Modal>
    );

  


    const handleSubscribeClick = async () => {
        // Logic to handle subscription
        console.log("Subscribe button clicked");
        var return_url = window.location.origin + "/campaignDashboard/campaigns"
        var response = await axios.post(process.env.REACT_APP_DATABASE + "api/createCheckOutSession", { ...user.contact, return_url, campaign }).catch((error) => { console.log(error) })
        
        if (response?.data) {
            window.location.href = response.data.url
        }
    };


    return (
        <>
            {!loading && campaign && (campaign.subscriptionId === null || !subscriptionDetails?.isActive) && (contactId !== 998) && (
                <Box
                    onClick={(event) => {
                        
                        if (contactId !== 998) {
                            event.stopPropagation()
                        }
                       
                    }}
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 1000, // Ensure this value is higher than other elements
                    }}>
                    <Button variant="contained" color="primary" onClick={handleSubscribeClick}>
                        Subscribe
                        To Unlock Blamo
                    </Button>
                </Box>
            )}
            <nav className="sticky z-50 top-0">
                <Header
                    key="1"
                    icon="campaign"
                    placeholderLocation={"Campaign"}
                    user={user}
                    highestPermission={'Lead Edit'}
                />
            </nav>


            {/* {campaign && !loading? */}
            <LoadingOverlay open={loading || !campaign} />
            <Breadcrumbs sx={{ marginLeft: 2, cursor:'pointer' }} aria-label="breadcrumb">
                <Link underline="hover" color="inherit" onClick={() => { (navigate("/")) }} >
                    Home
                </Link>
                <Link underline="hover" color="inherit" onClick={() => { (navigate("/campaignDashboard/campaigns/")) }} >
                    Campaigns
                </Link>
                {/* <Link underline="hover" color="inherit" onClick={() => { (navigate("/client?id=" + campaign?.project?.client?.id)) }} >
                    {campaign?.project?.client?.ClientName}
                </Link>
                <Link underline="hover" color="inherit" onClick={() => { (navigate("/project?id=" + campaign?.project?.id)) }} >
                    {campaign?.project?.ProjectName}
                </Link> */}
                <Typography color="blue">{campaign?.campaignName}</Typography>
            </Breadcrumbs>
            <div className="split-container" style={{ display: 'flex', flexDirection: 'row', height: '100vh' }}>
                <Resizable

                    defaultSize={{
                        width: '25%', // Initial width set to 50% of the container
                        height: '100%', // Take full height of the container
                    }}
                    style={{ position: 'relative' }} // Position relative to allow absolute positioning of children
                    handleStyles={{
                        right: { // Style the right handle
                            backgroundColor: '#FFFFFF',
                            width: '10px',
                            height: '100%',
                            opacity: 1,
                        }
                    }}
                >
                    <div className="left-panel">

                        <div style={{ marginTop: 2 }}>
                            <CampaignDisplay clientName={campaign?.project?.client?.ClientName}
                                projectName={campaign?.project?.ProjectName}
                                campaignName={campaign?.campaignName} emailDetails={campaign}
                                setEmailDetails={setCampaign}
                                contactId={contactId} edit={edit}
                                setIndex={setIndex}
                                index={index}
                                setEdit={setEdit} handleSaveClick={handleSaveClick}
                                contact={user.contact}
                            />

                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 100 }}>
                                {edit && <DangerZoneCampaign campaignId={campaignId} onDeleteSuccess={() => { navigate("/campaignDashboard/campaigns") }} />}
                            </div>
                        </div>

                        {/* Additional campaign details */}
                    </div>

                </Resizable>
                <div className="right-panel" style={{ flex: 1, marginLeft: 15, marginRight: 5 }}>

                    <Box sx={{ width: '100%', height: '70%' }}>
                      

                        <EmailDisplay clientName={campaign?.project?.client?.ClientName}
                            projectName={campaign?.project?.ProjectName}
                            campaignName={campaign?.campaignName}
                            emailDetails={campaign}
                            setEmailDetails={setCampaign}
                            contactId={contactId}
                            index={index} edit={edit} setEdit={setEdit}
                            handleSaveClick={handleSaveClick}
                            loading={loading}
                        />

                        <div>
                            {/* <Button variant='contained' onClick={saveContent}>Save</Button>
                                        <Button variant='contained' onClick={saveAsNewCampaign}>Save As...</Button> */}
                        </div>
                        {/* </div> */}
                        {/* ))} */}
                        {/* </TabContext> */}
                    </Box>

                </div>

            </div>
            {/* :
                // <div style={{display: 'flex', justifyContent:'center'}}><CircularProgress /></div> */}
        </>
    );
};

function a11yProps(index) {
    return {
        id: `campaign-tab-${index}`,
        'aria-controls': `campaign-tabpanel-${index}`,
    };
}
export default CampaignPreview;
