import React, { useState, useEffect } from "react";
import ContactDetail from "../components/Contacts/ContactDetail";
import { useSearchParams } from "react-router-dom";
import Header from "../components/Common/Header";
import axios from "axios";
import { CircularProgress, Skeleton, Box, Typography } from "@mui/material";

export default function Contact({ user }) {
  const [contact, setContact] = useState(null);
  const [loading, setLoading] = useState(true);
  let [searchParams] = useSearchParams();
  let id = searchParams.get("id");

  const fetchContact = async () => {
    const qs = `${process.env.REACT_APP_DATABASE}api/getContact?id=${id}&contactId=${user.contact.id}`;
    try {
      const { data } = await axios.get(qs);

      setContact(data.contact);
    } catch (error) {
      console.error(error);
      setContact(null);
    }
    setLoading(false);
  };
  useEffect(() => {
   

    if (id) {
      fetchContact();
    }
  }, [id, user.contact.id]);

  if (loading) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
        <CircularProgress />
        <Typography variant="body1" sx={{ mt: 2 }}>Loading...</Typography>
      </Box>
    );
  }

  if (!contact) {
    return (
      <div>
        <Header user={user} />
        <Box p={4} display="flex" justifyContent="center">
          <Typography variant="h6">No contact found or error in loading</Typography>
        </Box>
      </div>
    );
  }

  
  return (
    <div>
      <ContactDetail
        contactData={contact}
        setData={setContact}
        user={user}
        fetchContact={fetchContact}
        setLoading={setLoading}
      />
    </div>
  );
}
