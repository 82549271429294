import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Check as CheckIcon } from "@mui/icons-material";
import { formatPhoneNumber } from "../../FormatPhoneNumber";

export default function OpinionInfoSplash({
  open,
  setOpen,
  opinion,
  contactId,
  entityId,
  setData,
  entity,
  // setListReset
}) {
  var groupList = [];
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-102" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100 hover:cursor-pointer">
                    <img
                      src={
                        opinion.contact &&
                        opinion.contact.images &&
                        opinion.contact.images.length > 0
                          ? opinion.contact.images[0].img
                          : "https://images.cubicsearch.com/UserLogo.png"
                      }
                      alt=""
                      className="flex h-10 w-10 flex-shrink-0 rounded-full object-cover"
                      onClick={() => {
                        window.open("/contact?id=" + opinion.contact.id);
                      }}
                    />
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    <div>
                      {(opinion.contact && opinion.contact.FirstName
                        ? opinion.contact.FirstName
                        : "") +
                        " " +
                        (opinion.contact && opinion.contact.LastName
                          ? opinion.contact.LastName
                          : "")}
                    </div>
                    <div className="text-xs">
                      {opinion.contact && opinion.contact.Phone
                        ? formatPhoneNumber(opinion.contact.Phone)
                        : ""}
                    </div>
                    <div className="text-xs">
                      {opinion.contact && opinion.contact.Email
                        ? opinion.contact.Email
                        : ""}
                    </div>
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Opinion Information
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {opinion.Field + ": " + opinion.OpinionValue}
                      </p>
                      <p className="text-sm text-gray-500">
                        {"Notes: " + opinion?.Message}
                      </p>
                      <p className="text-sm text-gray-500">
                        {"Date: " +
                          new Date(opinion?.createdAt).toLocaleDateString()}
                      </p>
                      <p className="text-sm text-gray-500">
                        {"Visible to the group: " +
                          opinion.group_journals.map((group_journal) => {
                            return group_journal.group.GroupName;
                          })}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex mt-5 sm:mt-6 justify-center">
                  <div className="w-full px-2">
                    {" "}
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                      onClick={() => setOpen(false)}
                    >
                      OK
                    </button>
                  </div>
                  {contactId === opinion.AuthorUserID ? (
                    <div className="w-full px-2">
                      {" "}
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                        onClick={() => {
                          deleteOpinion(
                            opinion.id,
                            contactId,
                            entityId,
                            setData,
                            entity
                            // setListReset
                          );
                          setOpen(false);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function deleteOpinion(
  opinionId,
  contactId,
  entityId,
  setData,
  entity
  // setListReset
) {
  //test - console.log(opinionId);
  fetch(
    process.env.REACT_APP_DATABASE + "api/deleteJournal?id=" + opinionId
  ).then((resultInfo) => {
    resultInfo.json().then(async () => {
    
    
      if (entity === "availId") {
        var qsAvailableOnly =
          process.env.REACT_APP_DATABASE + "api/getAvailableOnly?id=";
        var qsAvailableContactsOnly =
          process.env.REACT_APP_DATABASE + "api/getAvailableContactsOnly?id=";
        var qsAvailableJournalsOnly =
          process.env.REACT_APP_DATABASE + "api/getAvailableJournalsOnly?id=";
        //var qsAvailableWebsitesOnly = process.env.REACT_APP_DATABASE + "api/getAvailableWebsitesOnly?id=" + id + "&contactId=" + user.contact.id

        const availableJournalsOnly = await fetch(
          qsAvailableJournalsOnly + entityId + "&contactId=" + contactId
        );
        const availableJournals = await availableJournalsOnly.json();

        const availableContactsOnly = await fetch(
          qsAvailableContactsOnly + entityId + "&contactId=" + contactId
        );
        const availableContacts = await availableContactsOnly.json();

        const availableOnly = await fetch(
          qsAvailableOnly + entityId + "&contactId=" + contactId
        );
        const available = await availableOnly.json();

        fetchLocation =
          process.env.REACT_APP_DATABASE + "api/getAvailableWebsitesOnly?id=";
        const finalSearchResults = await fetch(
          fetchLocation + entityId + "&contactId=" + contactId
        );
        const finalData = await finalSearchResults.json();

        available.avail.generic_journals = availableJournals.journals;
        available.avail.contacts = availableContacts.contacts;
        available.avail.websites = finalData.websites;

        setData(available.avail);
      } else if (entity === "buildingId") {
        var fetchLocation = process.env.REACT_APP_DATABASE + "api/getBuilding?id=";
        fetch(fetchLocation + entityId + "&contactId=" + contactId).then(
          (searchResults) => {
            searchResults.json().then((data) => {
              // setListReset(true)

              data.building.avails = data.avails;
              setData(data.building);
            });
          }
        );
      }

      //test - console.log(fetchLocation);
    });
  });
}
