import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { TextField, List, ListItem, ListItemText } from '@mui/material';
import { FlyToInterpolator } from 'deck.gl';

const MapboxAutocomplete = () => {
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [userLocation, setUserLocation] = useState(null);
    const accessToken = process.env.REACT_APP_MAPBOX; // Replace with your Mapbox access token

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    });
                },
                (error) => {
                    console.error('Error fetching user location:', error);
                    setUserLocation({
                        latitude: 0,
                        longitude: 0
                    }); // Default location if unable to get user location
                }
            );
        } else {
            setUserLocation({
                latitude: 0,
                longitude: 0
            }); // Default location if geolocation is not supported
        }
    }, []);

    const fetchSuggestions = async (searchText) => {
        const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(searchText)}.json?access_token=${accessToken}&limit=5&autocomplete=true&proximity=${userLocation.longitude},${userLocation.latitude}`;

        try {
            const response = await Axios.get(url);
            const results = response.data.features;
            setSuggestions(results.map(result => ({
                name: result.place_name,
                coordinates: result.center
            })));
        } catch (error) {
            console.error('Error fetching data:', error);
            setSuggestions([]);
        }
    };

    return (
        <div>
            <TextField
                label="Search City or State"
                value={query}
                onChange={(e) => {
                    setQuery(e.target.value);
                    if (e.target.value.length > 2) {
                        fetchSuggestions(e.target.value);
                    } else {
                        setSuggestions([]);
                    }
                }}
                variant="outlined"
                fullWidth
                margin="normal"
            />
            <List component="nav" aria-label="search results">
                {suggestions.map((suggestion, index) => (
                    <ListItem button key={index} onClick={() => {
                        console.log(suggestion.coordinates); // Or handle the selection in another way
                        setQuery(suggestion.name);
                        setSuggestions([]);
                    }}>
                        <ListItemText primary={suggestion.name} />
                    </ListItem>
                ))}
            </List>
        </div>
    );
};

export const MapboxAutocompleteLatLon = ({ setLatitude, setLongitude, setViewState }) => {
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [userLocation, setUserLocation] = useState(null);
    const accessToken = process.env.REACT_APP_MAPBOX; // Replace with your Mapbox access token


    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    });
                },
                (error) => {
                    console.error('Error fetching user location:', error);
                    setUserLocation({
                        latitude: 0,
                        longitude: 0
                    }); // Default location if unable to get user location
                }
            );
        } else {
            setUserLocation({
                latitude: 0,
                longitude: 0
            }); // Default location if geolocation is not supported
        }
    }, []);

    const fetchSuggestions = async (searchText) => {
        const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(searchText)}.json?access_token=${accessToken}&limit=5&autocomplete=true&proximity=${userLocation.longitude},${userLocation.latitude}`;

        // const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(searchText)}.json?access_token=${accessToken}&limit=5&autocomplete=true`;

        try {
            const response = await Axios.get(url);
            const results = response.data.features;
            setSuggestions(results.map(result => ({
                name: result.place_name,
                coordinates: result.center
            })));
        } catch (error) {
            console.error('Error fetching data:', error);
            setSuggestions([]);
        }
    };

    return (
        <div>
            <TextField
                label="Search City or State"
                value={query}
                onChange={(e) => {
                    setQuery(e.target.value);
                    if (e.target.value.length > 2) {
                        fetchSuggestions(e.target.value);
                    } else {
                        setSuggestions([]);
                    }
                }}
                variant="outlined"
                fullWidth
                margin="normal"
            />
            <List component="nav" aria-label="search results">
                {suggestions.map((suggestion, index) => (
                    <ListItem button key={index} onClick={() => {
                        console.log(suggestion.coordinates); // Or handle the selection in another way
                        setQuery(suggestion.name);
                        setSuggestions([]);
                        // Set latitude and longitude using the passed-in functions
                        if (setLatitude && setLongitude  && setViewState) {
                            
                            setLatitude(suggestion.coordinates[1]);
                            setLongitude(suggestion.coordinates[0]);
                            setViewState({
                                longitude: suggestion.coordinates[0],
                                latitude: suggestion.coordinates[1],
                                zoom: 13,
                                pitch: 60,
                                transitionDuration: 2000, // Duration in milliseconds
                                transitionInterpolator: new FlyToInterpolator(),
                                // transitionEasing: d3.easeCubic  // Optional easing function
                            });
                        }
                    }}>
                        <ListItemText primary={suggestion.name} />
                    </ListItem>
                ))}
            </List>
        </div>
    );
};




export const MapboxAutocompleteCityState = ({ setCity, setState }) => {
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const accessToken = process.env.REACT_APP_MAPBOX; // Replace with your Mapbox access token
    const [userLocation, setUserLocation] = useState(null);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    });
                },
                (error) => {
                    console.error('Error fetching user location:', error);
                    setUserLocation({
                        latitude: 0,
                        longitude: 0
                    }); // Default location if unable to get user location
                }
            );
        } else {
            setUserLocation({
                latitude: 0,
                longitude: 0
            }); // Default location if geolocation is not supported
        }
    }, []);
    const fetchSuggestions = async (searchText) => {
        // const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(searchText)}.json?access_token=${accessToken}&limit=5&autocomplete=true&proximity=${userLocation.longitude},${userLocation.latitude}`;

        const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(searchText)}.json?access_token=${accessToken}&limit=5&autocomplete=true&types=place&proximity=${userLocation.longitude},${userLocation.latitude}`;

        try {
            const response = await Axios.get(url);
            const results = response.data.features;
            setSuggestions(results.map(result => ({
                name: result.place_name,  // The full place name
                city: result.text, // Generally, the city name
                state: result.context?.find(c => c.id.startsWith('region'))?.text, // Region or state
                coordinates: result.center
            })));
        } catch (error) {
            console.error('Error fetching data:', error);
            setSuggestions([]);
        }
    };

    return (
        <>
            <TextField
                label="Search City or State"
                value={query}
                onChange={(e) => {
                    setQuery(e.target.value);
                    if (e.target.value.length > 2) {
                        fetchSuggestions(e.target.value);
                    } else {
                        setSuggestions([]);
                    }
                }}
                variant="outlined"
                fullWidth
                // margin="normal"
            />
            <List component="nav" aria-label="search results">
                {suggestions.map((suggestion, index) => (
                    <ListItem button key={index} onClick={() => {
                        setCity(suggestion.city);
                        setState(suggestion.state);
                        setQuery(suggestion.name); // You might use the city or the full place name here
                        setSuggestions([]);
                    }}>
                        <ListItemText primary={suggestion.name} />
                    </ListItem>
                ))}
            </List>
        </>
    );
};
export default MapboxAutocomplete;
