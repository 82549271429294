import React, { useState, useEffect } from 'react';
import {
    Dialog, DialogTitle, DialogContent, Button, Checkbox, TextField,
    List, ListItem, ListItemText, ListItemIcon, ListItemButton, Collapse, DialogActions, IconButton
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert'; // For the ellipsis icon
import { Menu, MenuItem } from '@mui/material'; // For the menu components
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddBoxIcon from '@mui/icons-material/AddBox';
import FolderIcon from '@mui/icons-material/Folder'; // Importing Folder Icon
import GroupOutlined from '@mui/icons-material/GroupOutlined';
import PermissionsDialog from './PermissionsDialog';
import RecipientsDialog from './RecipientsDialog';
import ListAltIcon from '@mui/icons-material/ListAlt'; // Assuming you want a list-like icon

import axios from 'axios';
import MailListDialog from './MailListDialog';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import FolderSelectionDialog from './FolderSelectionDialog';

// Mock API call
const fetchFoldersAndMailLists = async (contactId) => {
    var qs = process.env.REACT_APP_DATABASE + "api/getMailFolders"
    var folderResponse = await axios.post(qs, { contactId: contactId })
    if (folderResponse.data.success) {
        return folderResponse.data.mailFolders
    }
};

function AddListDialog({ open, onClose, contactId, setLists, initialRecipientLists, campaignMailer, disabledLists }) {
    const [foldersData, setFolders] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [expandedFolders, setExpandedFolders] = useState({});
    const [checkedItems, setCheckedItems] = useState({});

    // Function within AddListDialog
    const [permissionDialogOpen, setPermissionDialogOpen] = useState(false);
    const [selectedItemForPermissions, setSelectedItemForPermissions] = useState(null);
    const [isRecipientsDialogOpen, setIsRecipientsDialogOpen] = useState(false);
    const [selectedMailListId, setSelectedMailListId] = useState(null);
    const [selectedMailList, setSelectedMailList] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentItem, setCurrentItem] = useState(null); // To track the current folder or recipient list being interacted with
    const [showDelete, setShowDelete] = useState(false)
    const [showFolders, setShowFolders] = useState(false)

    // Transform initialRecipientLists to match the expected structure of checkedItems
    function transformInitialRecipientLists(initialLists) {
        const transformed = {};
        initialLists.forEach(list => {
            if (!transformed[list.folderName]) {
                transformed[list.folderName] = [];
            }
            transformed[list.folderName].push(list.id);
        });
        return transformed;
    }

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchFoldersAndMailLists(contactId);
            setFolders(data);

            const initialCheckedItems = {};

            data.forEach(folder => {
                if (folder?.mailLists) {
                    initialCheckedItems[folder.folderName] = [];
                    initialRecipientLists = initialRecipientLists.map(obj => {
                        if (!obj.hasOwnProperty('bId')) {
                            obj.bId = obj.id;
                        }
                        return obj;
                    });
                    folder.mailLists.forEach(mailList => {
                        if (initialRecipientLists.some(list => list.bId === mailList.bId)) {
                            initialCheckedItems[folder.folderName].push(mailList.id);
                        }
                    });
                }
            });

            setCheckedItems(initialCheckedItems);
        };

        if (open) {
            fetchData();
        }
        setSearchTerm("");
    }, [open, initialRecipientLists]);

    useEffect(() => {
        const newExpandedFolders = foldersData.reduce((acc, folder) => {
            const isFolderMatch = folder?.folderName.toLowerCase().includes(searchTerm.toLowerCase());
            const isMailListMatch = folder?.mailLists.some(mailList => mailList?.listName.toLowerCase().includes(searchTerm.toLowerCase()));

            acc[folder?.folderName] = isFolderMatch || isMailListMatch;
            return acc;
        }, {});
        setExpandedFolders(newExpandedFolders);
    }, [searchTerm, foldersData]);

    const handleMenuClick = (event, item) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setCurrentItem(item);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        const isFolder = currentItem.hasOwnProperty('folderName');

        if (isFolder) {
            setFolders(prevFolders => prevFolders.map(folder => {
                if (folder?.id === currentItem.id) {
                    var qs = process.env.REACT_APP_DATABASE + "api/deleteMailFolder"
                    axios.post(qs, { folderId: folder?.bId }).then((result) => { console.log(result) }).catch((error) => { console.log(error) })
                }
                return folder;
            }));
        } else {
            setFolders(prevFolders => prevFolders.map(folder => {
                const mailLists = folder?.mailLists.filter(mailList => {
                    if (mailList?.id === currentItem.id) {
                        var qs = process.env.REACT_APP_DATABASE + "api/deleteMailList"
                        axios.post(qs, { mailListId: mailList?.bId }).then((result) => { console.log(result) }).catch((error) => { console.log(error) })
                    }
                    return (mailList.id !== currentItem.id)
                });
                return { ...folder, mailLists };
            }));
        }
    };

    const handleMenuOptionClick = (action) => {
        handleClose();

        if (action === 'Rename') {
            const currentName = currentItem.folderName || currentItem.listName;
            const newName = window.prompt('Enter the new name:', currentName);

            if (newName && newName.trim() && newName !== currentName) {
                const isFolder = currentItem.hasOwnProperty('folderName');

                if (isFolder) {
                    setFolders(prevFolders => prevFolders.map(folder => {
                        if (folder?.id === currentItem.id) {
                            var qs = process.env.REACT_APP_DATABASE + "api/updateMailFolder"
                            axios.post(qs, { folderName: newName, folderId: folder?.bId }).then((result) => { console.log(result) }).catch((error) => { console.log(error) })
                            return { ...folder, folderName: newName };
                        }
                        return folder;
                    }));
                } else {
                    setFolders(prevFolders => prevFolders.map(folder => {
                        const mailLists = folder?.mailLists.map(mailList => {
                            if (mailList?.id === currentItem.id) {
                                var qs = process.env.REACT_APP_DATABASE + "api/updateMailList"
                                axios.post(qs, { listName: newName, mailListId: mailList?.bId }).then((result) => { console.log(result) }).catch((error) => { console.log(error) })
                                return { ...mailList, listName: newName };
                            }
                            return mailList;
                        });
                        return { ...folder, mailLists };
                    }));
                }
            }
        } else if (action === 'Delete') {
            setShowDelete(true);
        } else if (action === 'Manage Permissions') {
            setSelectedItemForPermissions(currentItem);
            setPermissionDialogOpen(true);
        } else if (action === 'Copy') {
            setShowFolders(true);
        }

        var listsForEmailDisplay = convertCheckedItemsToArray(checkedItems, foldersData);
        setLists(listsForEmailDisplay);
    };

    const handleMailListClick = (mailListId, mailList) => {
        setSelectedMailList(mailList);
        setSelectedMailListId(mailListId);
        setIsRecipientsDialogOpen(true);
    };

    const handleOpenPermissionsDialog = (item) => {
        setSelectedItemForPermissions(item);
        setPermissionDialogOpen(true);
    };

    const handleAddLists = () => {
        var listsForEmailDisplay = convertCheckedItemsToArray(checkedItems, foldersData);
        setLists(listsForEmailDisplay);
        onClose();
        console.log('Add Lists');
    };

    const canAdd = Object.values(checkedItems).some(listIds => listIds.length > 0);
    const canDuplicate = Object.values(checkedItems).flat().length === 1;
    const canDelete = Object.values(checkedItems).flat().length > 0;

    const convertCheckedItemsToArray = (updatedCheckedItems, foldersData) => {
        let checkedItemsArray = [];

        Object.entries(updatedCheckedItems).forEach(([folderName, mailListIds]) => {
            const folder = foldersData.find(folder => folder?.folderName === folderName);
            if (!folder) return;

            mailListIds.forEach(mailListId => {
                const mailList = folder.mailLists.find(mailList => mailList.id === mailListId);
                if (!mailList) return;

                checkedItemsArray.push({
                    id: mailListId,
                    name: mailList.listName,
                    folderId: folder.id,
                    folderName: folder.folderName,
                    bId: mailList.bId
                });
            });
        });

        return checkedItemsArray;
    };

    const handleCheckboxChange = (name, mailListId = null) => {
        setCheckedItems(prevCheckedItems => {
            const updatedCheckedItems = { ...prevCheckedItems };
            const folder = foldersData.find(folder => folder?.folderName === name);
            const allMailListIds = folder?.mailLists.map(mailList => mailList?.id);

            if (mailListId) {
                if (!updatedCheckedItems[name]) {
                    updatedCheckedItems[name] = [mailListId];
                } else if (updatedCheckedItems[name].includes(mailListId)) {
                    updatedCheckedItems[name] = updatedCheckedItems[name].filter(id => id !== mailListId);
                } else {
                    updatedCheckedItems[name].push(mailListId);
                }
            } else {
                if (updatedCheckedItems[name] && updatedCheckedItems[name].length === allMailListIds.length) {
                    delete updatedCheckedItems[name];
                } else {
                    updatedCheckedItems[name] = allMailListIds;
                }
            }

            setExpandedFolders(prevExpanded => ({
                ...prevExpanded,
                [name]: true,
            }));

            return updatedCheckedItems;
        });
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const toggleFolder = (name) => {
        setExpandedFolders(prev => ({ ...prev, [name]: !prev[name] }));
    };

    // Filter folders and recipient lists based on search term
    const filteredFolders = foldersData?.map(folder => {
        const isFolderMatch = folder.folderName.toLowerCase().includes(searchTerm.toLowerCase());
        const filteredMailLists = folder.mailLists.filter(mailList =>
            mailList.listName.toLowerCase().includes(searchTerm.toLowerCase())
        );

        return {
            ...folder,
            mailLists: isFolderMatch ? folder.mailLists : filteredMailLists
        };
    }).filter(folder =>
        folder.folderName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        folder.mailLists.length > 0
    );

    const handleCreateNewFolder = async () => {
        const newName = prompt("Enter new folder name:");
        var qs = process.env.REACT_APP_DATABASE + "api/createMailListFolder"
        var folderResponse = await axios.post(qs, { folderName: newName, contactId: contactId })
        if (newName && folderResponse) {
            setFolders([...foldersData, {
                folderName: newName,
                bId: folderResponse.data.bId,
                id: folderResponse.data.id,
                mailLists: []
            }]);
        }
    };

    const handleCreateNewMailList = async (folderIn) => {
        const newMailListName = prompt("Enter new list name:");
        var qs = process.env.REACT_APP_DATABASE + "api/createMailList"
        var listResponse = await axios.post(qs, { listName: newMailListName, contactId: contactId, folderIdBrevo: folderIn.bId, folderIdCubic: folderIn.id })
        if (newMailListName && listResponse) {
            setFolders(foldersData.map(folder => {
                if (folder?.folderName === folderIn.folderName) {
                    const updatedMailLists = [...folder?.mailLists, { id: listResponse.data.id, bId: listResponse.data.bId, listName: newMailListName }];
                    return { ...folder, mailLists: updatedMailLists };
                }
                return folder;
            }));
        }
    };

    const handleCopyList = async (folderIn) => {
        const currentName = currentItem.folderName || currentItem.listName;
        const newMailListName = window.prompt('Enter the new name:', "Copy of " + currentName);
        if (newMailListName) {
            var qs = process.env.REACT_APP_DATABASE + "api/copyMailList"
            var listResponse = await axios.post(qs, { listName: newMailListName, contactId: contactId, folderIdBrevo: folderIn.bId, folderIdCubic: folderIn.id, listId: currentItem.bId, listOrFolder: 'list' })
            setFolders(foldersData.map(folder => {
                if (folder?.id === folderIn.id) {
                    const updatedMailLists = [...folder?.mailLists, { id: listResponse.data.id, bId: listResponse.data.bId, listName: newMailListName }];
                    return { ...folder, mailLists: updatedMailLists };
                }
                return folder;
            }));
        }
    };

    return (
        <>
            <Dialog open={open} onClose={() => {
                var listsForEmailDisplay = convertCheckedItemsToArray(checkedItems, foldersData)
                setLists(listsForEmailDisplay)
                onClose()
            }} fullWidth maxWidth="sm">
                <DialogTitle>Select Recipient List(s) for Campaign</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        margin="dense"
                        label="Search folders or lists"
                        variant="outlined"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                    <Button onClick={handleCreateNewFolder} variant="outlined" color="primary" startIcon={<AddBoxIcon />}>
                        Create New Folder
                    </Button>

                    <List>
                        {filteredFolders.map(folder => (
                            <React.Fragment key={folder?.folderName}>
                                <ListItem
                                    key={folder?.folderName}
                                    onClick={() => toggleFolder(folder?.folderName)}
                                >
                                    <Checkbox
                                        checked={checkedItems[folder?.folderName]?.length === folder?.mailLists.length && folder?.mailLists.length > 0}
                                        onChange={() => handleCheckboxChange(folder?.folderName)}
                                        disabled={folder?.mailLists.length === 0}
                                        edge="start"
                                    />
                                    <ListItemIcon>
                                        <FolderIcon sx={{ color: "orange" }} />
                                    </ListItemIcon>
                                    <ListItemText primary={folder?.folderName} />
                                    <IconButton
                                        aria-label="more"
                                        aria-controls="long-menu"
                                        aria-haspopup="true"
                                        onClick={(event) => handleMenuClick(event, folder)}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    {expandedFolders[folder?.folderName] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </ListItem>
                                <Collapse
                                    in={expandedFolders[folder?.folderName]}
                                    timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {folder?.mailLists.map(mailList => (
                                            <ListItem
                                                key={mailList?.id} button sx={{ paddingLeft: 11 }}
                                            >
                                                <Checkbox
                                                    checked={checkedItems[folder?.folderName]?.includes(mailList?.id) ?? false}
                                                    onChange={() => handleCheckboxChange(folder?.folderName, mailList?.id)}
                                                    disabled={disabledLists.some((disabledItem) => {
                                                        var testId = disabledItem.bId
                                                        if (!disabledItem.bId) {
                                                            testId = disabledItem.id
                                                        }
                                                        return (testId === mailList.bId)
                                                    })}
                                                />
                                                <ListItemIcon>
                                                    <ListAltIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={mailList?.listName} onClick={() => handleMailListClick(mailList?.id, mailList)} />
                                                <IconButton
                                                    sx={{ marginRight: 3.3 }}
                                                    aria-label="more"
                                                    aria-controls="long-menu"
                                                    aria-haspopup="true"
                                                    onClick={(event) => handleMenuClick(event, mailList)}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                            </ListItem>
                                        ))}
                                        <ListItem onClick={() => { handleCreateNewMailList(folder) }} sx={{ paddingLeft: 12, display: 'flex', alignItems: 'center' }}>
                                            <div sx={{ display: 'inline-flex', marginRight: '8px' }}>
                                                <AddBoxIcon />
                                            </div>
                                            <ListItemText primary="Create New Recipient List" sx={{ marginLeft: 1 }} />
                                        </ListItem>
                                    </List>
                                </Collapse>
                            </React.Fragment>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddLists} variant="contained" color="primary" disabled={!canAdd}>Choose Recipient Lists For Campaign</Button>
                </DialogActions>
            </Dialog>
            <PermissionsDialog
                open={permissionDialogOpen}
                onClose={() => setPermissionDialogOpen(false)}
                item={selectedItemForPermissions}
            />
            <RecipientsDialog
                open={isRecipientsDialogOpen}
                onClose={() => setIsRecipientsDialogOpen(false)}
                mailListId={selectedMailListId}
                contactId={contactId}
                MailListName={selectedMailList?.listName}
                selectedList={selectedMailList}
                campaignMailer={campaignMailer}
            />
            <Menu
                id="folder-mailList-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => handleMenuOptionClick('Rename')}>Rename  {currentItem?.hasOwnProperty('folderName') ? "Folder" : "List"}</MenuItem>
                {!currentItem?.hasOwnProperty('folderName') ? <MenuItem onClick={() => handleMenuOptionClick('Copy')}>Copy {currentItem?.hasOwnProperty('folderName') ? "Folder" : "List"}</MenuItem> : <></>}
                <MenuItem onClick={() => handleMenuOptionClick('Delete')}>Delete {currentItem?.hasOwnProperty('folderName') ? "Folder" : "List"}</MenuItem>
                <MenuItem onClick={() => handleMenuOptionClick('Manage Permissions')}>Manage Permissions</MenuItem>
            </Menu>
            <MailListDialog
                open={permissionDialogOpen}
                onClose={() => { setPermissionDialogOpen(false) }}
                mailListId={selectedItemForPermissions?.id}
                mailListName={selectedItemForPermissions?.listName}
                folderId={selectedItemForPermissions?.id}
                folderName={selectedItemForPermissions?.folderName}
                contactId={contactId}
                folder={currentItem?.hasOwnProperty('folderName')}
            />
            <DeleteConfirmationDialog open={showDelete} setOpen={setShowDelete} onDelete={handleDelete} />
            <FolderSelectionDialog open={showFolders} onClose={() => { setShowFolders(false) }} folders={foldersData}
                onSelectFolder={handleCopyList} listName={currentItem?.folderName || currentItem?.listName} />
        </>
    );
}

export default AddListDialog;
