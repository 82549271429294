
import { useState, useEffect } from "react";
import SearchResults from "./pages/SearchResults";
import Available from "./pages/Available";
import Parcel from "./pages/Parcel";
import Company from "./pages/Company";
import MyGroups from "./pages/MyGroups";
import Group from "./pages/Group";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import PropertyInfo from "./pages/PropertyInfo";
import ClientInfo from "./pages/Client";
import ProjectInfo from "./pages/Project";
import Survey from "./pages/Survey";
import ContactClientsInfo from "./pages/MyClients";
import axios from "axios"
import Footer from "./components/Common/Footer";


import Dashboard from "./components/Campaigns/Dashboard";


import { CircularProgress } from "@mui/material";
import { createCookie } from "./components/Common/Cookies";


export default function UserLookupRoute({ email, guest, parentLoading }) {


  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null)
  useEffect(() => {

    async function getUser() {
      try {

        const resultEffect = await axios.get(`${process.env.REACT_APP_DATABASE}api/fetchUserData?id=${email}&&guest=${guest}`);
        setResult(resultEffect.data);
        return (resultEffect.data)
      } catch (err) {
        console.log(err);
        setError(err);
      } finally {
        setLoading(false);
      }
    }


    if (email && !parentLoading) {
      getUser().then(async (contact) => {
        
        if (contact) {
          GetUserInformation({ contactId: contact.user.ContactID, guest: false }).then((contact) => {

            console.log(contact)
          }).catch((error) => {
            console.log(error)
          })
        }
      });

    }
    //else if (!parentLoading) {

    //   setLoading(false);
    //   setUserData({
    //     user:
    //     {
    //       contact:
    //         { guest: true }
    //     }
    //   })
    //   GetUserInformation({ guest: true }).then((contact) => {
    //     console.log(contact)
    //   }).catch((error) => {
    //     console.log(error)
    //   })  
    // }

  }, [email]);

  useEffect(() => {

    if (result?.user?.contact?.id) {
      var userDataLocal = result

      if (userDataLocal.user.contact.guest === 0) {
        userDataLocal.user.contact.guest = false
      }
      else {
        userDataLocal.user.contact.guest = true
      }

      if (userDataLocal?.user?.contact) {
        document.cookie = "contactId=" + userDataLocal.user.contact.id + ";SameSite='none'"
      }
      else {
        userDataLocal = userDataLocal ?? {};
        userDataLocal.user = userDataLocal.user ?? {};
        userDataLocal.user.contact = userDataLocal.user.contact ?? {};

        // Assign guest: true to userData.user.contact
        userDataLocal.user.contact = { ...userDataLocal.user.contact, guest: true };

      }

      setUserData(userDataLocal)
    }

  }, [result])

  if (loading) {
    return (
      <div className="absolute top-1/3 w-full items-center flex flex-col">
        <CircularProgress style={{ marginBottom: 20 }} />
        <div>...Getting User Data</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="absolute top-1/3 w-full items-center flex flex-col">
        <div>Failed to fetch user data. Please try again.</div>
      </div>
    );
  }

  if ((!userData && !guest) || !email) {
    return (
      <div className="absolute top-1/3 w-full items-center flex flex-col">
        <CircularProgress style={{ marginBottom: 20 }} />
        <div>...Getting User Data</div>
      </div>
    );
  }





  return (
    <>
      {/* <div style={{backgroundColor:'darkgray'}}> */}
      <BrowserRouter>
        <Routes>
          <Route index element={<><div style={{ backgroundColor: '#555555' }}>
            <div style={{ height: window.innerHeight - 120, overflow: 'hidden' }}>

              <Home user={userData?.user} guest={guest} />
            </div>
            <div style={{
              position: 'absolute', bottom: 0, paddingTop: 40,
              width: '100%', height: 150, color: 'white', backgroundColor: '#555555', overflow: 'hidden'
            }}>
              <Footer />
            </div>
          </div>
          </>} />
          <Route
            path="search"
            element={<SearchResults user={userData?.user} guest={guest} />}
          />
          <Route
            path="contact"
            element={<Contact user={userData?.user} guest={guest} />}
          />
          <Route
            path="property"
            element={<PropertyInfo user={userData?.user} guest={guest} />}
          />
          <Route
            path="available"
            element={<Available user={userData?.user} guest={guest} />}
          />
          <Route
            path="parcel"
            element={<Parcel user={userData?.user} guest={guest} />}
          />
          <Route
            path="company"
            element={<Company user={userData?.user} guest={guest} />}
          />
          <Route
            path="mygroups"
            element={<MyGroups user={userData?.user} guest={guest} />}
          />
          <Route
            path="group"
            element={<Group user={userData?.user} guest={guest} />}
          />
          <Route
            path="survey"
            element={<Survey user={userData?.user} guest={guest} />}
          />
          <Route
            path="client"
            element={<ClientInfo user={userData?.user} guest={guest} />}
          />
          <Route
            path="project"
            element={<ProjectInfo user={userData?.user} guest={guest} />}
          />
          <Route
            path="contactclients"
            element={<ContactClientsInfo user={userData?.user} guest={guest} />}
          />

          <Route
            path="campaignDashboard/campaigns/:id/preview"
            element={<Dashboard contact={userData?.user?.contact} user={userData?.user} guest={guest} />}
          />
          <Route
            path="campaignDashboard/*"
            element={<Dashboard contact={userData?.user?.contact} user={userData?.user} guest={guest} />}
          />



          <Route path="*" element={<NoPage />} guest={guest} />
        </Routes>
      </BrowserRouter>

      {/* </div> */}
    </>
  )

}








async function GetUserInformation({ contactId, guest }) {
  try {
    let ipAddress;
    let machineId;
    let guestId;
    let latitude;
    let longitude;
    let timestamp;

    // Function to get computer information
    function getComputerInfo() {
      return {
        browser: navigator.userAgent,
        platform: navigator.platform,
        os: navigator.appVersion,
      };
    }

    const computerInfo = getComputerInfo();

    // Function to handle successful position retrieval
    function showPosition(position) {
      longitude = position.coords.longitude;
      latitude = position.coords.latitude;
      timestamp = position.timestamp;

      document.cookie = `longitude=${longitude};SameSite='none'`;
      document.cookie = `latitude=${latitude};SameSite='none'`;
      document.cookie = `timestamp=${timestamp};SameSite='none'`;

      if (guest === true && !guestId) {
        if (!machineId) {
          const dateNow = new Date().getMilliseconds();
          machineId = `${ipAddress}${dateNow}`;
          guestId = machineId.replaceAll(".", "");
          document.cookie = `machineId=${machineId};SameSite='none'`;
          document.cookie = `guestId=${guestId};SameSite='none'`;
        }
      }
      else {
        createCookie(machineId, contactId)
      }

      const typePage = window.location.href.includes("www.cubicsearch.com")
        ? window.location.href.substring(
          window.location.href.indexOf("www.cubicsearch.com") +
          "www.cubicsearch.com".length,
          window.location.href.indexOf("?") > 0
            ? window.location.href.indexOf("?")
            : undefined
        )
        : window.location.href.substring(
          window.location.href.indexOf("http://localhost:8081/") +
          "http://localhost:8081/".length,
          window.location.href.indexOf("?") > 0
            ? window.location.href.indexOf("?")
            : undefined
        );
      var id = getFirstNumber(window.location.href)
      
      const qs = {
        src: window.location.href,
        contactId: guest === true ? (machineId ? guestId : "xxx") : contactId || 0,
        Longitude: longitude,
        Latitude: latitude,
        ipAddress: ipAddress,
        pageType: typePage,
        entityId: id,
        machineId: machineId,
        browser: computerInfo.browser,
        platform: computerInfo.platform,
        os: computerInfo.os,
      };

      axios.post(
        `${process.env.REACT_APP_DATABASE}api/createWebVisit`
        // `https://db.cubicsearch.com/cubic-search-back-end/api/createWebVisit`
        ,
        qs).catch(console.error);
    }

    // Function to handle permission denial for geolocation
    function notShowPosition(error) {
      console.log('Geolocation permission denied:', error);
    }



      try {
        const response = await axios.get(process.env.REACT_APP_DATABASE + "api/getIp");
        console.log(response.data);
        ipAddress = response.data.ip || 'blocked'
      } catch (error) {
        console.error("Problem fetching IP address:", error);
        ipAddress = "blocked";
      }

    document.cookie = `ip=${ipAddress};SameSite='none'`;

    // Get geolocation
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, notShowPosition);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }

    // Return the collected information
    return {
      ipAddress,
      machineId,
      guestId,
      latitude,
      longitude,
      timestamp,
      computerInfo,
    };
  } catch (error) {
    console.error("Error in GetUserInformation function:", error);
    return null;
  }
}


// Function to get the first number from the query string
function getFirstNumber(queryString) {
  const regex = /[?&][^=]+=([^&]*)/;
  const match = queryString.match(regex);
  if (match && match[1]) {
    const firstNumber = match[1].match(/\d+/);
    return firstNumber ? firstNumber[0] : null;
  }
  return null;
}