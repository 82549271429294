import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';

const CampaignNameField = ({ selectedClient, selectedProject, campaignName, setCampaignName }) => {
    // Using a state to hold the value of the text field allows it to be editable
    
    // const [campaignName, setCampaignName] = useState('');

    // useEffect to update the campaignName whenever clientName or projectName changes
    useEffect(() => {
        const defaultCampaignName = `${selectedClient?.ClientName}-${selectedProject?.ProjectName} New Campaign ${new Date().toLocaleDateString()}`;
        setCampaignName(defaultCampaignName);
    }, [selectedClient, selectedProject]);

    // Handling text field change
    const handleChange = (event) => {
        setCampaignName(event.target.value);
    };

    return (
        <TextField
            label="Campaign Name"
            variant="outlined"
            fullWidth
            value={campaignName}
            onChange={handleChange}
        />
    );
};

export default CampaignNameField;
