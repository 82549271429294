import React from 'react';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Paper, Divider } from '@mui/material';

const ContactDetails = ({ contacts }) => {
    if (!contacts || contacts.length === 0) {
        return (
            <Typography variant="subtitle1" gutterBottom>
                No contact information provided.
            </Typography>
        );
    }

    return (
        <Paper elevation={3} sx={{ my: 2, p: 2 }}>
            <Typography variant="h6" gutterBottom>
                From Contacts
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <List>
                {contacts.map((contact, index) => (
                    <>
                    <ListItem key={index} alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar alt={contact.name} src={contact.avatar} />
                        </ListItemAvatar>
                        <ListItemText
                                primary={contact?.name ? contact?.name: (index === 0?"Cubic Search": "No Name Provided")}
                                secondary={contact?.email ? contact?.email :(index === 0 ? "support@cubicsearch.com" : "No Email Provided")}
                        />
                    </ListItem>
                        {(index === 0)  && !contact?.email ? <Typography sx={{fontSize: 10}} variant='subtitle1'>An empty 1st sender field will default the campaign to send from Cubic Search.</Typography>: <></>}
                    </>
                ))}
            </List>
        </Paper>
    );
};

export default ContactDetails;
