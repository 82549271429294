import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { ConvertImagePath } from "../Common/ThumbFiles";
import Zoom from "react-medium-image-zoom";

import "react-medium-image-zoom/dist/styles.css";

import "./styles.css";
import GoogleKeenSlider from "./GoogleKeenSlider";
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import { mobileAndTabletCheck } from "../Search/Deck";
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button'
import { Image } from "@mui/icons-material";
import ImageGalleryDialogue from "./ImageGalleryDialogue"

import { useState } from "react";

import TheCarousel from "./TheCarousel";

function ThumbnailPlugin(mainRef) {
  return (slider) => {
    function removeActive() {
      slider.slides.forEach((slide) => {
        slide.classList.remove("active");
      });
    }
    function addActive(idx) {
      slider.slides[idx].classList.add("active");
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener("click", () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx);
        });
      });
    }

    slider.on("created", () => {
      if (!mainRef.current) return;
      if (
        slider &&
        slider.track &&
        slider.track.details &&
        slider.track.details.rel
      ) {
        addActive(slider.track.details.rel);
      }
      addClickEvents();
      mainRef.current.on("animationStarted", (main) => {
        removeActive();
        const next = main.animator.targetIdx || 0;
        addActive(main.track.absToRel(next));
        slider.moveToIdx(next);
      });
    });
  };
}

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}


export default function KeenSlider({ dataIn, width = '100%', height = '100%' }) {
  const [openImageDialogue, setOpenImageDialogue] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
  });
  const [thumbnailRef] = useKeenSlider(
    {
      initial: 0,
      slides: {
        perView:
          Math.round(window.window.innerWidth / 150) > dataIn.images.length
            ? dataIn.images.length
            : Math.round(window.window.innerWidth / 150),
        spacing: 10,
      },
    },
    [ThumbnailPlugin(instanceRef)]
  );

  const srcset = (image, size, cols = 1, rows = 1) => {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`,
    };
  };

  const getItemProps = (index, totalImages) => {
    if (totalImages === 1) {
      return { cols: 2, rows: 2 };
    } else if (totalImages === 2) {
      return { cols: 2, rows: 1 };
    } else {
      return {
        cols: index === 0 || index === 3 ? 2 : 1,
        rows: index === 0 ? 2 : 1,
      };
    }
  };
  if (dataIn.images.length === 0) {
    return <GoogleKeenSlider dataIn={dataIn} width={width} />;
  }

  const handleClose = () => {
    setOpenImageDialogue(false);
  };

  const imageList = dataIn.images.map((image) => ({
    original: image.img,
    thumbnail: ConvertImagePath(image.img),
  }));

  return (
    <>
      <Box
        onClick={() => setOpenImageDialogue(true)}
        sx={{
          position: 'relative',
          borderRadius: mobileAndTabletCheck() ? 0 : 3,
          overflowY: 'clip',
          width,
          height,
        }}
      >
      
        {mobileAndTabletCheck() ? (
          <TheCarousel imageList={imageList} />
        ) : (
            <Box
              sx={{
                width: '100%',
                maxWidth: 1000,
                maxHeight: 600,
                borderRadius: '16px',
                overflow: 'hidden',
                boxShadow: 3,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                position: 'relative'
              }}
            >
              {!mobileAndTabletCheck() && dataIn.images.length > 1 && (
                <Button
                  sx={{
                    position: 'absolute',
                    bottom: 20,
                    right: 50,
                    zIndex: 10,
                  }}
                  endIcon={<Image />}
                  variant="contained"
                >
                  All Photos
                </Button>
              )}
            <ImageList
              sx={{ width: '100%', height: '100%' }}
              variant="quilted"
              cols={dataIn.images.length > 4 ? 4 : dataIn.images.length}
              rowHeight={dataIn.images.length === 1 ? 'auto' : window.innerHeight / 4}
            >
              {dataIn.images.slice(0, 4).map((item, index) => {
                const itemProps = getItemProps(index, dataIn.images.length);

                return (
                  <ImageListItem key={item.img} {...itemProps}>
                    <img
                      {...srcset(item.img, 121, itemProps.cols, itemProps.rows)}
                      alt={item.title}
                      loading="lazy"
                      style={{
                        width: '100%',
                        height: dataIn.images.length === 1 ? 'auto' : '100%',
                        objectFit: dataIn.images.length === 1 ? 'contain' : 'cover',
                      }}
                    />
                  </ImageListItem>
                );
              })}
            </ImageList>
          </Box>
        )}
      </Box>
      <ImageGalleryDialogue imageList={imageList} open={openImageDialogue} handleClose={handleClose} />
    </>
  );
}



export function KeenSliderHalf({ dataIn, width }) {
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
  });
  const [thumbnailRef] = useKeenSlider(
    {
      initial: 0,
      slides: {
        perView:
          Math.round(window.window.innerWidth / 150) > dataIn.images.length
            ? dataIn.images.length
            : Math.round(window.window.innerWidth / 150),
        spacing: 10,
      },
    },
    [ThumbnailPlugin(instanceRef)]
  );

  return (
    <>
      <div className=" flex justify-center">
        <div className={window.window.innerWidth < 900 ? "w-full" : "w-third"}>
          <div ref={sliderRef} className="keen-slider">
            {/* <div className="keen-slider__slide number-slide1">1</div>
        <div className="keen-slider__slide number-slide1">1</div> */}
            {dataIn.images && dataIn.images.length > 0 ? (
              dataIn.images.map((item) => (
                <>
                  <div
                    className="keen-slider__slide number-slide1 rounded-2xl flex object-cover"
                    key={item.id}
                  >
                    <Zoom>
                      <img
                        src={item.img}
                        width={
                          width
                            ? width
                            : window.window.innerWidth > 700
                              ? "1200"
                              : "500"
                        }

                      />
                    </Zoom>
                  </div>
                </>
              ))
            ) : (
              <div
                className="keen-slider__slide number-slide1 rounded-2xl object-cover"
                key={1}
              >
                <img
                  src={
                    dataIn.Latitude
                      ? "https://dev.virtualearth.net/REST/V1/Imagery/Map/Birdseye/" +
                      dataIn.Latitude.toString().trim() +
                      "," +
                      dataIn.Longitude.toString().trim() +
                      "/20?dir=270&ms=900,700&key=AntwMqF4SuxAC8AfmB9-AuOVzrtOhbiNMZFVVe68dkbt5IjzUIzmWS8dfFmo9-IL"
                      : "https://images.cubicsearch.com/3dgifmaker05280.gif"
                  }
                  className=" rounded-2xl h-full"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src =
                      "https://images.cubicsearch.com/small%20logo%20with%20space.png";
                  }}

                />
              </div>
            )}

          </div>

          <div ref={thumbnailRef} className="keen-slider thumbnail">
            {dataIn.images && dataIn.images.length > 1
              ? dataIn.images.map((item) => (
                <div
                  className="keen-slider__slide number-slide1 rounded-2xl "
                  key={item.id}
                >
                  <img
                    className="rounded-2xl h-full"
                    src={ConvertImagePath(item.img)}

                  />
                </div>
              ))
              : ""}

          </div>
        </div>
      </div>
    </>
  );
}

export function KeenSliderWide({ dataIn }) {
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
  });
  const [thumbnailRef] = useKeenSlider(
    {
      initial: 0,
      slides: {
        perView: dataIn.images.length,
        spacing: 10,
      },
    },
    [ThumbnailPlugin(instanceRef)]
  );

  return (
    <>
      <div ref={sliderRef} className="keen-slider">

        {dataIn.images
          ? dataIn.images.map((item) => (
            <div
              className="keen-slider__slide number-slide1 rounded-2xl object-cover"
              key={item.id}
            >
              <img
                src={item.img}
                className=" rounded-2xl w-full"

              />
            </div>
          ))
          : ""}

      </div>

      <div ref={thumbnailRef} className="keen-slider thumbnail">
        {dataIn.images && dataIn.images.length > 1
          ? dataIn.images.map((item) => (
            <div
              className="keen-slider__slide number-slide1 rounded-2xl "
              key={item.id}
            >
              <img
                className="rounded-2xl  max-w-full max-h-full"
                src={item.img}

              />
            </div>
          ))
          : ""}

      </div>
    </>
  );
}



function Arrow(props) {
  const disabeld = props.disabled ? " arrow--disabled" : ""
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${props.left ? "arrow--left" : "arrow--right"
        } ${disabeld}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  )
}