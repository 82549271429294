// export var createCookie = function(name, value, days) {
//     var expires;
//     if (days) {
//         var date = new Date();
//         date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
//         expires = "; expires=" + date.toGMTString();
//     }
//     else {
//         expires = "";
//   }

//     document.cookie = name + "=" + value + expires + "; SameSite=Strict"// path=/"+";SameSite='none'";
// }

// Function to create a cookie with SameSite attribute
export function createCookie(name, value, days) {
  let cookieString = `${name}=${value}; SameSite=Strict`;

  if (days) {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + (days * 24 * 60 * 60 * 1000));
    cookieString += `; expires=${expirationDate.toUTCString()}`;
  }

  document.cookie = cookieString;
}

// Function to get the value of a cookie by name
export function getCookie(name) {
  const cookies = document.cookie.split(';').map(cookie => cookie.trim());
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split('=');
    if (cookieName === name) {
      return cookieValue;
    }
  }
  return null; // Cookie not found
}
// export function getCookie(c_name) {
//     if (document.cookie.length > 0) {
//         let c_start = document.cookie.indexOf(c_name + "=");
//         if (c_start !== -1) {
//             c_start = c_start + c_name.length + 1;
//             let c_end = document.cookie.indexOf(";", c_start);
//             if (c_end === -1) {
//                 c_end = document.cookie.length;
//             }
//             return document.cookie.substring(c_start, c_end);
//         }
//     }
//     return "";
// }
  
  export  function checkCookie() {
    let user = getCookie("username");
    if (user !== "") {
      alert("Welcome again " + user);
    } else {
      user = prompt("Please enter your name:", "");
      if (user !== "" && user !== null) {
        createCookie("username", user, 365);
      }
    }
}
  
export const scrambleString = (str) => {
  // Convert the string to an array of characters
  const charArray = str.split('');

  // Shuffle the array using Fisher-Yates algorithm
  for (let i = charArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [charArray[i], charArray[j]] = [charArray[j], charArray[i]];
  }

  // Join the shuffled characters back into a string
  return charArray.join('');
};