

import AvailableContactLookupComboBox from "../Availables/AvailableContactLookupComboBox";
import ContactNew from "../Common/ContactNew";
import RoleCombo from "../Building/RoleCombo";
import GroupCombo from "../Common/GroupCombo";
import React, { Fragment, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import NewContactDialog from "../Groups/NewContactDialog";

export default function BuildingContactSelectModal({
  open,
  setOpen,

  peopleIn,
  setPeopleIn,
  buildingId,
  triggerSetItems,
  contactId,
  groupsIn,
}) {
  //test - console.log("LOOP IN AVAIALABLE");
  const [buttonText, setButtonText] = useState("Select Contact");


  const [selectedPerson, setSelectedPerson] = useState();
  const [addPerson, setAddPerson] = useState(false);
  const [nameState, setName] = useState()
  const [selectedRole, setSelectedRole] = useState({ id: 1, name: "Landlord Broker" });
  const [selectedGroup, setSelectedGroup] = useState(
    groupsIn.length > 0 ? { groupId: groupsIn[0].id, name: groupsIn[0].GroupName } : null
  );

  const [newText, setNewText] = useState(null)
  

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
      maxWidth={'md'}
      fullWidth={true}

    >
      <DialogTitle id="form-dialog-title">Select or Add Contact</DialogTitle>
      <DialogContent>
        {/* Content goes here */}

        <div className="mt-2">
          <AvailableContactLookupComboBox

            selectedPerson={selectedPerson}
            setName={setName}
            setSelectedPerson={setSelectedPerson}
            setButtonText={setButtonText}
            setAddPerson={setAddPerson}
            open={open}
            contactId={contactId}
            setNewText={setNewText}
          />
        </div>
        <div style={{marginTop: 10}}>
          <RoleCombo
            selectedRole={selectedRole}
            setSelectedRole={setSelectedRole}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <GroupCombo
            groupsIn={groupsIn}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
          />
        </div>

        {addPerson ? (
          <div>
            <div className="py-2 text-center">Create A New Contact</div>
            <ContactNew NameIn={nameState} />
          </div>
        ) : (
          ""
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!selectedPerson?.id || (!selectedRole?.name?.length > 0) || (!selectedGroup?.groupId)}
          onClick={() => {


            addContact(
              selectedPerson.id,
              buildingId,
              setPeopleIn,
              peopleIn,
              triggerSetItems,
              selectedRole.name,
              contactId,
              selectedGroup.groupId
            );
            setOpen(false);

          }}
        >
          {buttonText}
        </Button>
      </DialogActions>
      <NewContactDialog NameIn={newText} open={addPerson} onClose={() => { setAddPerson(false) }} createContact={async (person) => {


        await newContact(
          buildingId,
          person,
          setPeopleIn,
          triggerSetItems,
          selectedRole.name,
          contactId,
          selectedGroup.groupId
        );
        setOpen(false);

        setAddPerson(false); // Close the dialog
      }} />
    </Dialog>
  );
}

async function newContact(
  buildingId,
  person,
  setPeopleIn,
  triggerSetItems,
  role,
  userId,
  selectedGroup
) {
  var fetchLocation =
    process.env.REACT_APP_DATABASE +
    "api/addContactBuilding?buildingId=" +
    buildingId +
    "&groupId=" +
    selectedGroup +
    "&role=" +
    role +
    "&" +
    Object.keys(person)
      .map((key) => key + "=" + person[key])
      .join("&");
  var result = await fetch(fetchLocation);
  fetchLocation = process.env.REACT_APP_DATABASE + "api/getBuilding?id=";


  const searchResults = await fetch(
    fetchLocation + buildingId + "&contactId=" + userId
  );
  const data = await searchResults.json();
  // var myJson = data.building[0].contacts; // search results come in an array, and the element is also an array
  
  var myJson = data.contacts; // search results come in an array, and the element is also an array
  
  var sort = orderBuildingContacts(
    myJson.sort(
      (a, b) => {
        
        return (a.building_contacts[0].sortOrder - b.building_contacts[0].sortOrder)
      }
    )
  );
  setPeopleIn(
    myJson
  );


  triggerSetItems(
    myJson
  );
}
async function addContact(
  contactId,
  buildingId,
  setPeopleIn,
  peopleIn,
  triggerSetItems,
  role,
  userId,
  selectedGroup
) {
  var fetchLocation =
    process.env.REACT_APP_DATABASE +
    "api/addBuildingContact?buildingId=" +
    buildingId +
    "&&contactId=" +
    contactId +
    "&role=" +
    role +
    "&groupId=" +
    selectedGroup;
  //test - console.log(fetchLocation);
  const resultInfo = await fetch(fetchLocation);
  var myWaitVar = await resultInfo.json();
  fetchLocation = process.env.REACT_APP_DATABASE + "api/getBuilding?id=";
  const searchResults = await fetch(
    fetchLocation + buildingId + "&contactId=" + userId
  );
  
  //test - console.log(fetchLocation + buildingId + "&contactId=" + userId);
  const data = await searchResults.json();
  // var myJson = data.building[0].contacts; // search results come in an array, and the element is also an array
  var myJson = data.contacts; // search results come in an array, and the element is also an array
  
  var sort = orderBuildingContacts(
    myJson.sort(
      (a, b) => {
        
        return a.building_contacts[0].sortOrder - b.building_contacts[0].sortOrder
      }
    )
  );
  setPeopleIn(
    myJson.sort(
      (a, b) => a.building_contacts[0].sortOrder - b.building_contacts[0].sortOrder
    )
  );
  triggerSetItems(
    myJson.sort(
      (a, b) => a.building_contacts[0].sortOrder - b.building_contacts[0].sortOrder
    ),
    peopleIn
  );
}

async function orderBuildingContacts(contacts) {
  //test - console.log("Update the order in the database");
  ////test - console.log(buildings)
  var mynum = 0;

  contacts.map(async (contact, index) => {
    mynum = index + 1;
    //test - console.log(contact.id);
    
    var qs = "id=" + contact.building_contacts[0].id + "&sortOrder=" + mynum;
    var resultInfo = await fetch(
      process.env.REACT_APP_DATABASE + "api/sortBuildingContacts?" + qs
    );
  });
}