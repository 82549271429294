import Map from "react-map-gl";

import {
  Marker,
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  AttributionControl,
} from "react-map-gl";
import { useState, useCallback } from "react";
//   import Pin from './Pin';
import type { MarkerDragEvent, LngLat } from "react-map-gl";

import * as React from "react";

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX

export default function MapSinglePoint({ dataIn, Latitude, Longitude, highestPermission }) {
  const [marker, setMarker] = useState({
    latitude: dataIn.Latitude,
    longitude: dataIn.Longitude,
  });
  const [events, logEvents] = useState<Record<string, LngLat>>({});

const onMarkerDragStart = useCallback((event) => {
	logEvents((_events) => ({ ..._events, onDragStart: event.lngLat }));
}, []);

const onMarkerDrag = useCallback((event) => {
	logEvents((_events) => ({ ..._events, onDrag: event.lngLat }));

	setMarker({
		longitude: event.lngLat.lng,
		latitude: event.lngLat.lat,
	});
}, []);

  const onMarkerDragEnd = useCallback((event) => {
    logEvents((_events) => ({ ..._events, onDragEnd: event.lngLat }));
    //test - console.log(event.lngLat);

    fetch(
      process.env.REACT_APP_DATABASE + "api/updateBuildingLocation?id=" +
        dataIn.id +
        "&Latitude=" +
        event.lngLat.lat +
        "&Longitude=" +
        event.lngLat.lng
    );
  }, []);


  var draggable = false
  if (highestPermission === "Edit" || highestPermission === "Lead Edit") {
    draggable = true
  }
  else {
    draggable = false
  }

  return (
    <>
      <Map
        mapStyle="mapbox://styles/schordr/ckvq4te666xrm14lq6wmj1fi0"
        initialViewState={{
          longitude: Longitude,
          latitude: Latitude,
          zoom: 15,
        }}
        style={{ width: "100%", height: 600 }}
        mapboxAccessToken={MAPBOX_TOKEN}
      >
        <GeolocateControl position="top-left" />
        <FullscreenControl position="top-left" />
        <NavigationControl position="top-left" />
        <ScaleControl />

        <Marker
          key={"marker"}
          longitude={marker.longitude}
          latitude={marker.latitude}
          draggable = {draggable}
          onDragStart={onMarkerDragStart}
          onDrag={onMarkerDrag}
          onDragEnd={onMarkerDragEnd}
          anchor='bottom'
        >
          <Pin></Pin>
        </Marker>

        {
          <Popup longitude={Number(Longitude)} latitude={Number(Latitude)}>
            <div className="text-sm whitespace-pre-line">
              {highestPermission === "Edit" || highestPermission === "Owner"? "Drag Marker to Proper Location! \n\n" : "" +
                dataIn.StreetNumber +
                " " +
                dataIn.StreetName +
                "\n"}
              {dataIn.City}, {dataIn.State}
            </div>
            <img
              width="100%"
              src={dataIn.images.length > 0 ? dataIn.images[0].img : ""}
            />
          </Popup>
        }
      </Map>
    </>
  );
}

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
C20.1,15.8,20.2,15.8,20.2,15.7z`;

const pinStyle = {
  cursor: "pointer",
  fill: "#d00",
  stroke: "none",
};

function Pin({ size = 40 }) {
  return (
    <svg height={size} viewBox="0 0 24 24" style={pinStyle}>
      <path d={ICON} />
    </svg>
  );
}
