// MapDialog.jsx

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  Button,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { generateGeoJSON } from "./utils/mapUtils"; // Ensure this utility is correctly implemented
import jsPDF from "jspdf"; // Import jsPDF

// Set your Mapbox access token
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX;

// Styled container for the map dialog content
const StyledDialogContent = React.forwardRef((props, ref) => (
  <div ref={ref} {...props} />
));

const StyledContent = styled(StyledDialogContent)(({ theme }) => ({
  position: "relative",
  height: "80vh",
}));

// Styled container for dialog actions (using DialogActions component)
const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2),
}));

// Utility function to sanitize company names for Mapbox image names
const sanitizeCompanyName = (companyName) => {
  return companyName.replace(/\s+/g, "_").replace(/[^a-zA-Z0-9_]/g, "");
};

function MapDialog({ open, onClose, recipients }) {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null); // Reference to the Mapbox map instance

  // State to hold the current viewport
  const [viewport, setViewport] = useState({
    longitude: 0,
    latitude: 0,
    zoom: 2,
  });

  // State for loading indicator
  const [isLoading, setIsLoading] = useState(false);

  // Function to calculate the map view based on recipients
  const calculateMapView = () => {
    if (recipients.length === 0) {
      return { longitude: 0, latitude: 0, zoom: 2 };
    }

    const validRecipients = recipients.filter(
      (r) =>
        r.office?.Longitude !== undefined &&
        r.office?.Latitude !== undefined &&
        !isNaN(r.office.Longitude) &&
        !isNaN(r.office.Latitude)
    );

    if (validRecipients.length === 0) {
      return { longitude: 0, latitude: 0, zoom: 2 };
    }

    if (validRecipients.length === 1) {
      const single = validRecipients[0];
      return {
        longitude: single.office.Longitude,
        latitude: single.office.Latitude,
        zoom: 12,
      };
    }

    const longitudes = validRecipients.map((r) => r.office.Longitude);
    const latitudes = validRecipients.map((r) => r.office.Latitude);

    const minLng = Math.min(...longitudes);
    const maxLng = Math.max(...longitudes);
    const minLat = Math.min(...latitudes);
    const maxLat = Math.max(...latitudes);

    // Calculate the center
    const centerLng = (minLng + maxLng) / 2;
    const centerLat = (minLat + maxLat) / 2;

    // Determine the zoom level based on the spread
    const lngDiff = maxLng - minLng;
    const latDiff = maxLat - minLat;
    const maxDiff = Math.max(lngDiff, latDiff);
    let zoom = 2;

    if (maxDiff < 0.1) zoom = 12;
    else if (maxDiff < 1) zoom = 8;
    else if (maxDiff < 10) zoom = 5;
    else if (maxDiff < 50) zoom = 3;
    else zoom = 2;

    return { longitude: centerLng, latitude: centerLat, zoom };
  };

  // Initialize the map when the dialog opens
  useEffect(() => {
    if (open) {
      const timeout = setTimeout(() => {
        if (mapContainerRef.current && !mapRef.current) {
          const initialView = calculateMapView();

          console.log("Initializing Mapbox map with view:", initialView);

          // Initialize the Mapbox map
          mapRef.current = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: "mapbox://styles/mapbox/dark-v11",
            center: [initialView.longitude, initialView.latitude],
            zoom: initialView.zoom,
            preserveDrawingBuffer: true, // Add this line
          });

          // Add navigation controls
          mapRef.current.addControl(new mapboxgl.NavigationControl(), "top-right");

          // Listen for the map's load event
          mapRef.current.on("load", () => {
            console.log("Map has loaded successfully.");
            const geojson = generateGeoJSON(recipients);

            // Log sanitized company names in GeoJSON
            geojson.features.forEach((feature) => {
              console.log(`GeoJSON feature companyName: ${feature.properties.companyName}`);
            });

            // Extract unique companies and their logos
            const uniqueCompanies = {};
            geojson.features.forEach((feature) => {
              const { logo, companyName } = feature.properties;
              if (logo && !uniqueCompanies[companyName]) {
                uniqueCompanies[companyName] = logo;
              }
            });

            // Define an async function to load images and then add layers
            const loadImagesAndAddLayers = async () => {
              setIsLoading(true); // Start loading
              console.log("Loading images and adding layers.");

              try {
                const loadPromises = Object.entries(uniqueCompanies).map(
                  ([companyName, logoUrl]) => loadImageWithSVGSupport(logoUrl, companyName)
                );

                // Wait for all images to load and add
                await Promise.all(loadPromises);
                console.log("All images loaded and added to Mapbox.");

                // List all images in Mapbox for verification
                listMapboxImages();

                // Add the GeoJSON source
                mapRef.current.addSource("markers", {
                  type: "geojson",
                  data: geojson,
                });

                // Add the Circle Layer
                mapRef.current.addLayer({
                  id: "circle-layer",
                  type: "circle",
                  source: "markers",
                  paint: {
                    "circle-radius": 30, // Adjust as needed
                    "circle-color": "#FFFFFF",
                    "circle-stroke-color": "#000000",
                    "circle-stroke-width": 1,
                    "circle-opacity": 0.8,
                  },
                });

                // Add the Symbol Layer
                mapRef.current.addLayer({
                  id: "symbol-layer",
                  type: "symbol",
                  source: "markers",
                  layout: {
                    "icon-image": ["get", "companyName"],
                    "icon-size": 0.5,
                    "icon-allow-overlap": true,
                    "icon-ignore-placement": true,
                  },
                  paint: {
                    "icon-opacity": 1,
                  },
                });

                console.log("Layers added to Mapbox.");
              } catch (error) {
                console.error("Error loading images and adding layers:", error);
              } finally {
                setIsLoading(false); // End loading
              }
            };

            // Call the async function
            loadImagesAndAddLayers();
          });

          // Update the viewport state on map move
          mapRef.current.on("moveend", () => {
            const center = mapRef.current.getCenter();
            const zoom = mapRef.current.getZoom();
            setViewport({
              longitude: center.lng,
              latitude: center.lat,
              zoom: zoom,
            });
          });

          // Listen for missing images
          mapRef.current.on("styleimagemissing", (event) => {
            console.warn(`Missing image: ${event.id}`);
            // Optionally, you can load a default image here or handle it as needed
          });
        }
      }, 100); // Delay to ensure DOM rendering

      // Cleanup function
      return () => {
        clearTimeout(timeout);
        if (mapRef.current) {
          mapRef.current.remove();
          mapRef.current = null;
          console.log("Mapbox map removed.");
        }
      };
    }
  }, [open, recipients]);

  // Function to list all images in Mapbox for verification
  const listMapboxImages = () => {
    const style = mapRef.current.getStyle();
    if (style && style.images) {
      console.log("Current Mapbox Images:", Object.keys(style.images));
    } else {
      console.warn("Mapbox style or images not available yet.");
    }
  };

  // Function to resize images to a square canvas with fixed dimensions
  const resizeImage = (image, size) => {
    const canvas = document.createElement("canvas");
    canvas.width = size;
    canvas.height = size;
    const ctx = canvas.getContext("2d");

    // Clear the canvas
    ctx.clearRect(0, 0, size, size);

    // Calculate scaling to maintain aspect ratio
    const aspectRatio = image.width / image.height;
    let drawWidth, drawHeight;
    if (aspectRatio > 1) {
      drawWidth = size - 8; // Adjust padding as needed
      drawHeight = drawWidth / aspectRatio;
    } else {
      drawHeight = size - 8;
      drawWidth = drawHeight * aspectRatio;
    }

    // Draw the original image centered
    ctx.drawImage(
      image,
      (size - drawWidth) / 2,
      (size - drawHeight) / 2,
      drawWidth,
      drawHeight
    );

    // Verify canvas dimensions
    if (canvas.width !== size || canvas.height !== size) {
      throw new RangeError(
        `Canvas dimensions mismatch: expected ${size}x${size}, got ${canvas.width}x${canvas.height}`
      );
    }

    return canvas;
  };

  // Function to load image with SVG support
  const loadImageWithSVGSupport = async (
    logoUrl,
    companyName,
    retryCount = 0
  ) => {
    const MAX_RETRIES = 3;
    const DEFAULT_IMAGE_URL = "/path/to/default-image.png"; // Replace with your default image path

    const sanitizedCompanyName = sanitizeCompanyName(companyName);

    try {
      let image;

      if (logoUrl.toLowerCase().endsWith(".svg")) {
        // Fetch SVG as text
        const response = await fetch(logoUrl);
        if (!response.ok)
          throw new Error(`Failed to fetch SVG: ${response.statusText}`);
        const svgText = await response.text();

        // Convert SVG text to a raster image
        image = await convertSVGToImage(svgText);
        console.log(`SVG converted to raster image for ${companyName}`);
      } else {
        // For non-SVG images, use standard image loading
        image = await loadImageAsPromise(logoUrl);
        console.log(
          `Image loaded for ${companyName}: ${image.width}x${image.height}`
        );
      }

      // Resize the image to ensure consistent dimensions
      const resizedCanvas = resizeImage(image, IMAGE_SIZE);
      console.log(
        `Image resized for ${companyName}: ${resizedCanvas.width}x${resizedCanvas.height}`
      );

      // Convert Canvas to ImageBitmap
      const imageBitmap = await createImageBitmap(resizedCanvas);

      // Add the ImageBitmap to the map with sanitized name
      if (!mapRef.current.hasImage(sanitizedCompanyName)) {
        mapRef.current.addImage(sanitizedCompanyName, imageBitmap, {
          sdf: false,
        });
        console.log(`ImageBitmap added to Mapbox for ${companyName}`);
      } else {
        console.warn(
          `Image for ${companyName} already exists. Skipping addition.`
        );
      }
    } catch (error) {
      console.error(`Error loading image for ${companyName}:`, error);
      if (retryCount < MAX_RETRIES) {
        console.log(
          `Retrying to load image for ${companyName} (${retryCount + 1})`
        );
        await loadImageWithSVGSupport(logoUrl, companyName, retryCount + 1);
      } else {
        // Load default image after max retries
        try {
          const defaultImage = await loadImageAsPromise(DEFAULT_IMAGE_URL);
          console.log(
            `Default image loaded for ${companyName}: ${defaultImage.width}x${defaultImage.height}`
          );
          const resizedDefaultCanvas = resizeImage(defaultImage, IMAGE_SIZE);
          console.log(
            `Default image resized for ${companyName}: ${resizedDefaultCanvas.width}x${resizedDefaultCanvas.height}`
          );

          const defaultImageBitmap = await createImageBitmap(
            resizedDefaultCanvas
          );

          if (!mapRef.current.hasImage(sanitizedCompanyName)) {
            mapRef.current.addImage(sanitizedCompanyName, defaultImageBitmap, {
              sdf: false,
            });
            console.log(
              `Default ImageBitmap added to Mapbox for ${companyName}`
            );
          }
        } catch (defaultError) {
          console.error(
            `Error loading default image for ${companyName}:`,
            defaultError
          );
        }
      }
    }
  };

  // Constants for new sizes
  const IMAGE_SIZE = 80; // Updated image size

  // Utility function to convert SVG text to Image
  const convertSVGToImage = (svgText) => {
    return new Promise((resolve, reject) => {
      const svgBlob = new Blob([svgText], {
        type: "image/svg+xml;charset=utf-8",
      });
      const url = URL.createObjectURL(svgBlob);
      const img = new Image();
      img.crossOrigin = "Anonymous"; // To handle CORS
      img.onload = () => {
        URL.revokeObjectURL(url);
        resolve(img);
      };
      img.onerror = (err) => {
        URL.revokeObjectURL(url);
        reject(new Error("Failed to convert SVG to Image."));
      };
      img.src = url;
    });
  };

  // Utility function to load images as Promises (standardized for all image types)
  const loadImageAsPromise = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous"; // To handle CORS
      img.onload = () => resolve(img);
      img.onerror = (err) =>
        reject(new Error(`Failed to load image from ${url}`));
      img.src = url;
    });
  };

  // Function to handle JPEG image generation and download
  const handleDownloadJPG = () => {
    if (mapRef.current) {
      try {
        console.log("Attempting to capture map using Mapbox GL's getCanvas().toDataURL().");
        
        // Capture the map canvas as a JPEG data URL
        const mapCanvas = mapRef.current.getCanvas();
        const mapImageURL = mapCanvas.toDataURL('image/jpeg', 1.0); // High quality

        // Optional: Display the captured image in a new window for debugging
        // const debugWindow = window.open("", "_blank");
        // debugWindow.document.write(`<img src="${mapImageURL}" alt="Captured Map" />`);

        // Create a temporary link to trigger the download
        const link = document.createElement('a');
        link.href = mapImageURL;
        link.download = 'map.jpg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        console.log("JPEG image saved successfully.");
      } catch (error) {
        console.error("Error generating JPEG image with Mapbox GL's getCanvas().toDataURL():", error);
        alert("Unable to capture the map for image generation.");
      }
    } else {
      console.log("Mapbox map is not available.");
      alert("Mapbox map is not available.");
    }
  };

  // Function to handle PDF generation and download
  const handleDownloadPDF = () => {
    if (mapRef.current) {
      try {
        console.log("Attempting to capture map using Mapbox GL's getCanvas().toDataURL().");
        
        // Capture the map canvas as a JPEG data URL
        const mapCanvas = mapRef.current.getCanvas();
        const mapImageURL = mapCanvas.toDataURL('image/jpeg', 1.0); // High quality

        // Optional: Display the captured image in a new window for debugging
        // const debugWindow = window.open("", "_blank");
        // debugWindow.document.write(`<img src="${mapImageURL}" alt="Captured Map" />`);

        // Create a new jsPDF instance with landscape orientation
        const pdf = new jsPDF({
          orientation: 'landscape',
          unit: 'px',
          format: [mapCanvas.width, mapCanvas.height],
        });

        // Add the image to the PDF
        pdf.addImage(mapImageURL, 'JPEG', 0, 0, mapCanvas.width, mapCanvas.height);

        // Optionally, add a title or other text
        // pdf.setFontSize(20);
        // pdf.text('Map Snapshot', 20, 30);

        // Save the PDF
        pdf.save('map.pdf');
        
        console.log("PDF saved successfully.");
      } catch (error) {
        console.error("Error generating PDF with jsPDF:", error);
        alert("Unable to generate the PDF.");
      }
    } else {
      console.log("Mapbox map is not available.");
      alert("Mapbox map is not available.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>
        Marketing
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <StyledContent id="map-container" ref={mapContainerRef}>
        {isLoading && (
          <CircularProgress
            size={60}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-30px",
              marginLeft: "-30px",
            }}
          />
        )}
        {/* Your existing map rendering logic */}
      </StyledContent>
      <StyledDialogActions>
        {/* Button to Download Image */}
        <Button
          variant="contained"
          color="secondary"
          onClick={handleDownloadJPG}
          disabled={isLoading}
          sx={{ marginRight: 2 }} // Optional: Add some spacing
        >
          Download Image
        </Button>
        
        {/* Button to Download PDF */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownloadPDF}
          disabled={isLoading}
        >
          Download PDF
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
}

MapDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  recipients: PropTypes.arrayOf(
    PropTypes.shape({
      office: PropTypes.shape({
        Longitude: PropTypes.number,
        Latitude: PropTypes.number,
        company: PropTypes.shape({
          images: PropTypes.arrayOf(
            PropTypes.shape({
              img: PropTypes.string,
            })
          ),
          name: PropTypes.string,
        }),
      }),
    })
  ).isRequired,
};

export default MapDialog;
