import {  Tooltip,Fab } from "@mui/material";
import { useState, useMemo, useEffect, useImperativeHandle, useCallback, useRef, forwardRef, createRef } from "react";
import React from "react";
import Refresh from '@mui/icons-material/Refresh'
import { UpdateDisabled } from "@mui/icons-material";

const FabRefresh = React.forwardRef(({ reloadOnMove, setReloadOnMove, setSuperMapRef, toolTips }, ref)=>{
    const [showWait, setShowWait] = useState(false)

    useImperativeHandle(ref, () => ({


        setShowWait(newVal) {
            setShowWait(newVal)
        }
    }))

    return(
        <>
            <Tooltip open={toolTips} disableFocusListener title="Refresh Properties When Map Moved" placement="left">
            <Fab sx={{ backgroundColor: 'white', color: 'indigo' }} className={showWait ? "loaderIcon" : ""} onClick={
                () => {
                    if (!reloadOnMove) {

                        setSuperMapRef(true) // if it is chaning from a no then we are going to a yes, so search
                    }
                    setReloadOnMove(!reloadOnMove);
                }
            }>
                {reloadOnMove ? <Refresh /> : <UpdateDisabled />}

            </Fab>
        </Tooltip>
    </>)
        })
export default FabRefresh;