import * as React from "react";

import NonLinearSlider from "../SearchBar/SliderSF";
import { InputComponent } from "../InputComponent";
import AddProperty from "../AddProperty/AddProperty";
import { Checkbox, DialogTitle, DialogContent, DialogContentText, DialogActions, createTheme, ThemeProvider } from "@mui/material";




import { Button } from "@mui/material";

import Dialog from '@mui/material/Dialog';
import MultipleSelectChip from "./MultipleSelectChip";
import { mobileAndTabletCheck } from "../../Search/Deck";

import { createCookie, getCookie } from "../Cookies";




export default function FilterDialogue({
    filtersClose,
    setAvailablesCheck,
    closeDialogue,
    postFilters,
    compsCheck,
    setCompsCheck,
    allCheck,
    setAllCheck,

    setMinAvailSize,
    setMaxAvailSize,
    minAvailSize,
    maxAvailSize,
    buildingPropertyType,
    setBuildingPropertyType,
    availPropertyType,
    setAvailPropertyType,
    filters,
    setFilters,
    searchResults,
    setSearchResults,
    initialViewState,
    user,
    showFilters,

    propertyMapRef,
    availablesCheck,
    dateMax,
    dateMin,
    setDateMax,
    setDateMin,
    location }) {


    const closeSetter = () => {

        filters.availPropertyType = availPropertyType
        filters.buildingPropertyType = buildingPropertyType
        setFilters(filters)
        filtersClose()
        // alert(JSON.stringify(filters))
        closeDialogue()

    }
    // Create a custom theme with adjusted zIndex
    const customTheme = createTheme({
        components: {
            MuiDialog: {
                styleOverrides: {
                    root: {
                        zIndex: 3000, // Set your desired zIndex value for the Dialog
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        zIndex: 4000, // Set a higher zIndex value for the Paper component (menus)
                    },
                },
            },
            MuiPopover: {
                styleOverrides: {
                    paper: {
                        zIndex: 4000, // Set a higher zIndex value for the Popover component (calendar)
                    },
                },
            },
            MuiMenu: {
                styleOverrides: {
                    paper: {
                        zIndex: 4000, // Set a higher zIndex value for the Menu component
                    },
                },
            },
        
        },
    });

    const standardFilters = {
        "availMin": 0, "availMax": 200, "dateMin": null,
        "dateMax": null, "buildingPropertyType": "Any",
        "availPropertyType": "Any", "compsCheck": true,
        "allCheck": false, "availablesCheck": true, "idArray": [],
        "maxPropsForDetail": 50, "contactId": user.contact.id, "contact_id": user.contact.id
    }
    return (
        <ThemeProvider theme={customTheme}>
            <Dialog

                sx={{ zIndex: 1000 }}
                maxWidth='md'
                open={showFilters}
                onClose={
                    () => {
                        closeSetter()
                    }
                }


            >

                <DialogTitle>Search Filters</DialogTitle>
                <DialogContent>
                    <div style={{ maxHeight: mobileAndTabletCheck() ? 500 : 1000, overflowY: 'scroll' }}>
                        <div style={{ overflowX: 'hidden' }}>


                            <>
                                <div className="flex flex-col w-full">
                                    <div className="flex flex-col w-full  px-4 pb-2">
                                        <div className="w-full text-xs">

                                            <NonLinearSlider
                                                setMinAvailSize={setMinAvailSize}
                                                setMaxAvailSize={setMaxAvailSize}
                                                minAvailSize={minAvailSize}
                                                maxAvailSize={maxAvailSize}


                                            />
                                        </div>
                                    </div>

                                    <>
                                        <div className="text-m ">
                                            <div>
                                                {" "}
                                                <div className="flex w-full ">
                                                    <div className="w-full">
                                                        <div className="px-2 flex stackOnSmall items-start ">
                                                            <div className="flex flex-col w-full ">

                                                                <MultipleSelectChip Options={[
                                                                    "Life Science",
                                                                    "GMP",
                                                                    "Industrial",
                                                                    "Flex",
                                                                    "Office",
                                                                    "Retail",
                                                                    "Other",]} setAvailPropertyType={setBuildingPropertyType} availPropertyType={buildingPropertyType} title={"Building Type"} />

                                                            </div>
                                                            <div>
                                                                <MultipleSelectChip Options={[
                                                                    "Life Science",
                                                                    "GMP",
                                                                    "Industrial",
                                                                    "Flex",
                                                                    "Office",
                                                                    "Retail",
                                                                    "Other",]} setAvailPropertyType={setAvailPropertyType}
                                                                    availPropertyType={availPropertyType} title={"Available Space Type"}
                                                                />
                                                            </div>

                                                        </div>
                                                        <div className="flex w-full pt-4" >
                                                            {" "}
                                                            <div className="w-full">
                                                                <Checkbox
                                                                    onChange={(e) => {

                                                                        setAvailablesCheck(e.target.checked);
                                                                        var newFilters = filters
                                                                        newFilters.availablesCheck = ((e.target.checked))

                                                                        setFilters(newFilters)
                                                                        postFilters(newFilters, searchResults, initialViewState, user.contact.id, setFilters, setSearchResults, propertyMapRef)

                                                                    }}
                                                                    checked={availablesCheck}
                                                                    name="availablesCheck"
                                                                />{" "}
                                                                Availables
                                                            </div>
                                                            <div className="w-full">
                                                                <Checkbox
                                                                    onChange={(e) => {

                                                                        setCompsCheck(e.target.checked);
                                                                        var newFilters = filters
                                                                        newFilters.compsCheck = ((e.target.checked))

                                                                        setFilters(newFilters)
                                                                        postFilters(newFilters, searchResults, initialViewState, user.contact.id, setFilters, setSearchResults, propertyMapRef)

                                                                    }}
                                                                    checked={compsCheck}

                                                                    // checked={compsCheck}
                                                                    name="compsCheck"
                                                                />{" "}
                                                                Lease Comps
                                                            </div>

                                                            <div className="w-full">
                                                                <Checkbox
                                                                    onChange={(e) => {

                                                                        setAllCheck(e.target.checked);
                                                                        var newFilters = filters
                                                                        newFilters.allCheck = ((e.target.checked))

                                                                        setFilters(newFilters)
                                                                        postFilters(newFilters, searchResults, initialViewState, user.contact.id, setFilters, setSearchResults, propertyMapRef)

                                                                    }}
                                                                    checked={allCheck}

                                                                    // checked={compsCheck}
                                                                    name="allCheck"
                                                                />{" "}
                                                                All Properties
                                                            </div>


                                                        </div>
                                                        <div className="flex px-2">


                                                            <div className="px-4 showOnMobile">
                                                                <AddProperty />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="text-3xl font-semibold mt-2 mb-6 hidden-mobile">
                                            {location ? "Properties in " + location : ""}
                                        </h1>
                                    </>
                                    <div className="py-2 px-2 w-full">
                                        <div className="text-xs w-full">

                                            <div >Avail Occupancy or Transaction Date</div>
                                            <div style={{ justifyContent: 'space-between' }} className="flex py-2 stackOnSmall">

                                                <div >
                                                    <div>
                                                        From
                                                    </div>
                                                    <InputComponent
                                                        inputType={"dateRangeStart"}
                                                        name={"dateMin"}
                                                        valueIn={dateMin}
                                                        setvalueIn={setDateMin}
                                                        defaultShow={dateMin}
                                                        formatting={"text-xs"}
                                                    />
                                                </div>
                                                <div>
                                                    <div>
                                                        To
                                                    </div>
                                                    <InputComponent
                                                        inputType={"dateRangeEnd"}
                                                        name={"dateMax"}
                                                        valueIn={dateMax}
                                                        setvalueIn={setDateMax}
                                                        defaultShow={dateMax}
                                                        formatting={"text-xs"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>

                        </div>
                    </div>


                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {
                        closeSetter()
                        closeDialogue()
                    }}>Set</Button>
                    <Button variant="outlined" onClick={() => {

                        setMinAvailSize(standardFilters.availMin)
                        setMaxAvailSize(standardFilters.availMax)
                        setBuildingPropertyType(standardFilters.buildingPropertyType)
                        setAvailPropertyType(standardFilters.availPropertyType)
                        setAvailablesCheck(standardFilters.availablesCheck)
                        setCompsCheck(standardFilters.compsCheck)
                        setAllCheck(standardFilters.allCheck)
                        setDateMin(standardFilters.dateMin)
                        setDateMax(standardFilters.dateMax)
                        // closeSetter()
                        // setSearchResults([]);
                        // closeDialogue()
                    }}>Reset (Defaults)</Button>
                    {/* // this should really say remove filters */}
                </DialogActions>

            </Dialog>
        </ThemeProvider>
    )
}

