import React, { useState, useMemo, useEffect } from "react";
import { styled, keyframes } from "@mui/material/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Button,
  Avatar,
  Typography,
  Fab,
} from "@mui/material";
import RemoveCircleOutline from "@mui/icons-material/RemoveCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import SearchDialog from "./FindMore/SearchDialog";
import DeleteContactDialog from "./DeleteContactDialog";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import MapIcon from "@mui/icons-material/Map";
import ListIcon from "@mui/icons-material/List";
import MapDialog from "./MapDialog";
import OfficeListDialog from "./OfficeListDialog";

// Define the animation outside the styled component
const pulseAnimation = keyframes`
    0% { transform: scale(1); opacity: 1; }
    100% { transform: scale(1.1); opacity: 0.8; }
`;

// Apply the animation within the styled component
const Pulse = styled("div")(({ theme }) => ({
  animation: `${pulseAnimation} 1s infinite alternate`,
}));

// Sticky container for search and buttons
const StickyContainer = styled("div")(({ theme }) => ({
  position: "sticky",
  top: 0,
  zIndex: 1,
  backgroundColor: "white",
  padding: theme.spacing(2),
}));

// New DialogContainer as a styled div with relative positioning
const DialogContainer = styled("div")(({ theme }) => ({
  position: "relative",
  maxHeight: "70vh", // Adjust based on your design needs
  display: "flex",
  flexDirection: "column",
}));

// Floating button styled to be inside the dialog
const DialogFloatingButton = styled(Fab)(({ theme }) => ({
  position: "absolute",
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2, // Ensure it's above the content
}));

// Floating button styled to be inside the dialog
const DialogFloatingButtonList = styled(Fab)(({ theme }) => ({
  position: "absolute",
  bottom: theme.spacing(2),
  right: theme.spacing(12),
  zIndex: 2, // Ensure it's above the content
}));

function RecipientsDialog({
  open,
  onClose,
  mailListId,
  contactId,
  MailListName,
  selectedList,
  campaignMailer,
  exclusion,
}) {
  const [loading, setLoading] = useState(true);
  const [recipients, setRecipients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRecipients, setSelectedRecipients] = useState({});
  const [showNewContacts, setShowNewContacts] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteRecipients, setDeleteRecipients] = useState(null);
  const [mapOpen, setMapOpen] = useState(false);
  const [officeListOpen, setOfficeListOpen] = useState(false);

  const onMakeExclusionList = async (recipientArray) => {
    let exclusionListName = window.prompt(
      "Please enter Exclusion List Name",
      "Exclusion List" +
        " : " +
        MailListName +
        " - " +
        new Date().toDateString()
    );
    if (exclusionListName?.length > 0) {
      const qs = process.env.REACT_APP_DATABASE + "api/createAndAssociateExclusion";
      const contactsArray = recipientArray;
      try {
        await axios.put(qs, {
          contactsArray,
          mailListId,
          selectedList,
          exclusionListName,
          contactId,
          folderId: selectedList.folderId,
          campaignMailer,
        });
      } catch (error) {
        console.error("Error creating exclusion list:", error);
      }
    }
  };

  const fetchData = async () => {
    if (!mailListId) return [];
    const qs = `${process.env.REACT_APP_DATABASE}api/getMailerContacts`;
    try {
      const response = await axios.post(qs, { id: mailListId });
      return response.data;
    } catch (error) {
      console.error("Problem with axios mail contacts call", error);
      return [];
    }
  };

  const fetchAndSetData = async () => {
    try {
      const recipientInfo = await fetchData();
      setRecipients(recipientInfo || []);
      return !!recipientInfo;
    } catch (error) {
      console.error(error);
      setRecipients([]);
      return false;
    }
  };

  useEffect(() => {
    if (open && !showNewContacts) {
      setLoading(true);
      fetchAndSetData().then(() => {
        setLoading(false);
      });
    }
  }, [open, showNewContacts, mailListId]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleDeleteRecipients = (recipientsArray) => {
    setDeleteRecipients(recipientsArray);
    setShowDelete(true);
  };

  const handleAddRecipient = () => {
    setShowNewContacts(true);
  };

  const handleMapClick = () => {
    setMapOpen(true);
  };

  const handleMapClose = () => {
    setMapOpen(false);
  };

  const handleOpenOfficeList = () => {
    setOfficeListOpen(true);
  };

  const handleCloseOfficeList = () => {
    setOfficeListOpen(false);
  };

  const filteredRecipients = useMemo(() => {
    return recipients.filter((recipient) => {
      const recipientString = Object.values(recipient).join(" ").toLowerCase();
      return recipientString.includes(searchTerm);
    });
  }, [recipients, searchTerm]);

  const getMapView = () => {
    if (filteredRecipients.length === 0) {
      return {
        longitude: 0,
        latitude: 0,
        zoom: 2,
      };
    }
    // Implement logic to calculate the map view based on recipients
  };

  const mapView = useMemo(() => getMapView(), [filteredRecipients]);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <span style={{ display: "flex", alignItems: "center" }}>
            {exclusion && (
              <span style={{ color: "#f50057", marginRight: 4 }}>
                Exclusion
              </span>
            )}
            <MailOutlineIcon style={{ marginRight: 4 }} />
            Recipient List: {MailListName}
          </span>
        </DialogTitle>
        <DialogContainer>
          <DialogContent
            dividers
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              paddingTop: 0, // Remove top padding to eliminate the gap
              paddingBottom: "80px", // Space for the floating buttons
            }}
          >
            <StickyContainer>
              <TextField
                fullWidth
                margin="normal"
                label="Search Mail List Recipients"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
              />

              <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                <Pulse>
                  <Button
                    startIcon={<AddIcon />}
                    onClick={handleAddRecipient}
                    variant="contained"
                    sx={{ fontSize: 12 }}
                  >
                    Get More Recipients
                  </Button>
                </Pulse>

                <Button
                  startIcon={<RemoveCircleOutline />}
                  variant="outlined"
                  sx={{ fontSize: 12 }}
                  onClick={() => handleDeleteRecipients(filteredRecipients)}
                >
                  Remove All Recipients Shown
                </Button>
              </div>
            </StickyContainer>
            {loading ? (
              <Typography variant="body1">Loading...</Typography>
            ) : (
              <List>
                {filteredRecipients.map((recipient) => (
                  <ListItem
                    key={recipient.id}
                    button
                    onClick={() => {
                      window.open("/contact?id=" + recipient.id);
                    }}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleDeleteRecipients([recipient]);
                        }}
                        aria-label="remove recipient"
                      >
                        <RemoveCircleOutline />
                      </IconButton>
                    }
                  >
                    <Avatar
                      style={{
                        marginRight: 2,
                        borderRadius: "10%",
                        objectFit: "contain",
                      }}
                      imgProps={{ style: { objectFit: "contain" } }}
                      src={
                        recipient.images?.[0]?.img ||
                        recipient?.office?.company?.images?.[0]?.img
                      }
                    />
                    <ListItemText
                      primary={`${recipient.FirstName} ${recipient.LastName}`}
                      secondary={
                        <>
                          {recipient.Title && (
                            <Typography component="span" variant="body2">
                              {recipient.Title}
                            </Typography>
                          )}
                          <Typography
                            component="span"
                            variant="body2"
                            style={{ display: "block" }}
                          >
                            {recipient.Email}
                          </Typography>
                          <Typography variant="body2">
                            {recipient?.office?.City}, {recipient?.office?.State}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </DialogContent>

          {/* Floating buttons outside DialogContent */}
          <DialogFloatingButton
            color="primary"
            onClick={handleMapClick}
            aria-label="Show Map"
          >
            <MapIcon />
          </DialogFloatingButton>

          <DialogFloatingButtonList
            color="primary"
            onClick={handleOpenOfficeList}
            aria-label="Show List"
          >
            <ListIcon />
          </DialogFloatingButtonList>
        </DialogContainer>
      </Dialog>

      <SearchDialog
        open={showNewContacts}
        handleClose={() => {
          setSearchTerm("");
          setShowNewContacts(false);
        }}
        contactId={contactId}
        mailListId={mailListId}
        setContacts={() => {
          console.log("set contacts");
        }}
        MailListName={MailListName}
        recipients={recipients}
        fetchData={fetchAndSetData}
      />

      {/* Map Dialog */}
      <MapDialog
        open={mapOpen}
        onClose={handleMapClose}
        mapView={mapView}
        recipients={filteredRecipients}
      />

      {/* Office List Dialog */}
      <OfficeListDialog
        open={officeListOpen}
        onClose={handleCloseOfficeList}
        recipients={recipients}
      />

      <DeleteContactDialog
        exclusion={exclusion}
        open={showDelete}
        onClose={() => setShowDelete(false)}
        deleteRecipients={deleteRecipients}
        MailListName={MailListName}
        setSearchTerm={setSearchTerm}
        onMakeExclusionList={onMakeExclusionList}
        onRemoveEverywhere={async () => {
          const emailArray = deleteRecipients.map((recipient) =>
            recipient?.Email?.toLowerCase()
          );

          try {
            const result = await axios.post(
              process.env.REACT_APP_DATABASE + "api/removeContactFromListAPI",
              {
                mailListId,
                deleteRecipients,
              }
            );

            if (result) {
              const updatedRecipients = recipients.filter(
                (recipient) =>
                  !emailArray.includes(recipient.Email.toLowerCase())
              );
              setRecipients(updatedRecipients);
            }
          } catch (error) {
            console.error(error);
            alert("Failed to delete contacts.");
          }
        }}
      />
    </>
  );
}

export default RecipientsDialog;
