// getGeo.js

// Import required modules
import axios from 'axios';
import geojsonArea from '@mapbox/geojson-area';

// Ensure environment variables are loaded
// If you're using a tool like Create React App, this may not be necessary
// import dotenv from 'dotenv';
// dotenv.config();

/**
 * Fetches parcel data for the given viewport and updates the map data.
 *
 * @param {Function} setMapData - Function to update the map data.
 * @param {Object} myViewport - Viewport object containing _ne and _sw coordinates.
 */
export async function getGeo(setMapData, myViewport) {
    // Minimum area threshold for filtering parcels
    const minArea = 500;

    // Calculate center latitude and longitude of the viewport
    const centerLat = (myViewport._ne.lat + myViewport._sw.lat) / 2;
    const centerLng = (myViewport._ne.lng + myViewport._sw.lng) / 2;

    // Construct the query string to update the elevation data
    const elevationUrl = `${process.env.REACT_APP_DATABASE}api/updateElevationLocalAPI?Latitude=${centerLat}&Longitude=${centerLng}`;

    try {
        // Fetch elevation data
        const elevationResponse = await axios.get(elevationUrl);
        const elevation = elevationResponse.data.elevation;

        // Construct the initial parcels query URL
        const parcelsUrl = `${process.env.REACT_APP_DATABASE}api/getGeoParcels?&viewport=${encodeURIComponent(
            JSON.stringify(myViewport)
        )}`;

        // Prepare bounds for different APIs
        const myBoundsSocrata = `${myViewport._ne.lat},${myViewport._sw.lng},${myViewport._sw.lat},${myViewport._ne.lng})`;
        const myBoundsArcGIS = `${myViewport._sw.lng},${myViewport._sw.lat},${myViewport._ne.lng},${myViewport._ne.lat}`;

        // Helper function to process response data
        const processResponseData = (responseData) => {
            let features = responseData.features || responseData;
            if (Array.isArray(features)) {
                features = features.filter((newMapDatum) => {
                    return geojsonArea.geometry(newMapDatum.geometry) > minArea;
                });

                if (features.length > 0) {
                    return {
                        type: 'FeatureCollection',
                        features,
                        elevation,
                    };
                }
            }
            return null;
        };

        // Fetch parcels from the initial API
        let response = await fetch(parcelsUrl);
        if (response.ok) {
            const newMapData = await response.json();
            const result = processResponseData(newMapData);
            if (result) {
                setMapData(result);
                return; // Exit the function since we have data
            }
        }

        // List of alternative parcel data sources
        const parcelAPIs = [
            // San Mateo County
            `https://data.smcgov.org/resource/nb88-e3ki.geojson?$where=within_box(the_geom,${myBoundsSocrata}`,
            // San Francisco
            `https://data.sfgov.org/resource/acdm-wktn.geojson?$where=within_box(shape,${myBoundsSocrata}`,
            // ArcGIS Parcels
            `https://services5.arcgis.com/ROBnTHSNjoZ2Wm1P/arcgis/rest/services/Parcels/FeatureServer/0/query?where=1%3D1&outFields=*&geometry=${myBoundsArcGIS}&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelOverlaps&outSR=4326&f=json`,
            // California Parcels
            `https://services.gis.ca.gov/arcgis/rest/services/Boundaries/UCD_Parcels/MapServer/0/query?where=1%3D1&outFields=*&geometry=${myBoundsArcGIS}&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelIntersects&outSR=4326&f=geojson`,
            // North Carolina Wake County
            `https://maps.wakegov.com/arcgis/rest/services/Property/Parcels/MapServer/0/query?where=1%3D1&outFields=*&geometry=${myBoundsArcGIS}&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelIntersects&outSR=4326&f=geojson`,
            // North Carolina Statewide
            `https://services.nconemap.gov/secure/rest/services/NC1Map_Parcels/MapServer/1/query?where=1%3D1&outFields=*&geometry=${myBoundsArcGIS}&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelIntersects&outSR=4326&f=geojson`,
            // Massachusetts
            `https://services1.arcgis.com/hGdibHYSPO59RG1h/arcgis/rest/services/L3_TAXPAR_POLY_ASSESS_gdb/FeatureServer/0/query?where=1%3D1&outFields=*&geometry=${myBoundsArcGIS}&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelIntersects&outSR=4326&f=geojson`,
            // Hawaii
            `https://geodata.hawaii.gov/arcgis/rest/services/ParcelsZoning/MapServer/25/query?where=1%3D1&outFields=*&geometry=${myBoundsArcGIS}&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelIntersects&outSR=4326&f=geojson`,
        ];

        // Iterate through alternative APIs until data is found
        for (const apiUrl of parcelAPIs) {
            response = await fetch(apiUrl);
            if (response.ok) {
                const responseData = await response.json();
                const result = processResponseData(responseData);
                if (result) {
                    setMapData(result);
                    return; // Exit the function since we have data
                }
            }
        }

        // If no data is found after checking all sources
        console.warn('No parcel data found for the given viewport.');
    } catch (err) {
        console.error('Connection Error:', err.message);
    }
}
