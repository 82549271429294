import React from 'react';
import Slider from '@mui/material/Slider'; // Import MUI Slider component
import Box from '@mui/material/Box'; // Import Box for layout

const SliderControl = ({ value, onChange }) => {
    const handleChange = (event, newValue) => {
        onChange(360 - newValue); // Adjusting value for the same logic used previously
    };

    return (
        <Box sx={{ margin: '20px', width: '300px' }}>
            <Slider
                value={value} // Adjust the value to work as before
                onChange={handleChange}
                min={0}
                max={360}
                aria-labelledby="continuous-slider" // Accessibility label
            />
        </Box>
    );
};

export default SliderControl;
