import React, { useState } from "react";
import MyGroupsDetail from "../components/MyGroups/MyGroupsDetail"
import { useSearchParams } from "react-router-dom"
import { useQuery } from 'react-query'



export default function MyGroups({ user }) {

  var qsInvitedTo = process.env.REACT_APP_DATABASE + "api/getGroupsInvitedTo?id=" + user.contact.id

  
  const fetchGroupsInvitedToData = async () => {
    const result = await fetch(qsInvitedTo)
    return await result.json()
  }

  const { data: groupsInvitedToData, status: statusGroupsInvitedToData, error: errorGroupsInvitedToData } = useQuery('groupsInvitedToData', fetchGroupsInvitedToData)



  if (statusGroupsInvitedToData === "loading") {

    return (
      <>
        <div className="absolute top-1/3 w-full items-center flex flex-col">
          <img style={{ width: 100 }} src="https://images.cubicsearch.com/Wait.gif" />
          <div>Loading Group Data...</div>
        </div>
      </>
    );

  }

  if (statusGroupsInvitedToData === "error") {
    return ("Groups Invite To Error:" + errorGroupsInvitedToData.message)

  }

  if (!groupsInvitedToData) {
    return ("No results")
  }


  return <div>

    <div>
      <MyGroupsDetail groupsInformation={groupsInvitedToData} contactId={user.contact.id} user={user} />
    </div>

  </div>

}
