/* This example requires Tailwind CSS v2.0+ */
export default function HeaderForClient({
  setEditMode,
  setEditButtonText,
  dataIn,
  setData,
  editMode,
  editButtonText,
  editClick,
  highestPermission,
  contactId
}) {
  return (
    <div className="md:flex md:items-center md:justify-between">
      <div className="flex-1 min-w-0">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          Client Details
        </h2>{" "}
        <p />
        <h3>
          {dataIn.ClientName} 
        </h3>{" "}
        <p />
        {/* <h3>{dataIn.City}</h3> */}
      </div>
      <div className="mt-4 flex md:mt-0 md:ml-4">
        <a href="#projects">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Projects
          </button>
        </a>
        <a>
          {highestPermission === "Edit" || highestPermission === "Lead Edit" ||  contactId === 1? (
            <button
              type="button"
              className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              onClick={() => {
                editClick(
                  setEditMode,
                  setEditButtonText,
                  editMode,
                  dataIn,
                  setData
                );
              }}
            >
              {editButtonText}
            </button>
          ) : (
            ""
          )}
        </a>
      </div>
    </div>
  );
}
