import * as React from "react";
import { List, arrayMove } from "react-movable";
import DemoCarousel from "../../Images/Carousel";
import MyGroupSelectModal from "../../MyGroups/SelectGroupModal/MyGroupSelectModal";

import Folder from "@mui/icons-material/Folder";
import { mobileAndTabletCheck } from "../../Search/Deck";
import { Typography, Avatar } from "@mui/material";
import { Tooltip } from "@mui/material";


export default function SurveyGroupsEditTable({
  groupsIn,
  surveyId,
  setGroupsIn,
  contactId,
  groupsCombo,
  highestPermission,
  setData
}) {
  const [showGroupModal, setShowGroupModal] = React.useState(false);
  const [items, setItems] = React.useState(mapArray(groupsIn));
  //test - console.log("rebuilding Edit Table");
  //test - console.log(highestPermission);


  const triggerSetItems = (newGroupsIn) => {
    // this forces the formatted table to refresh
    //test - console.log("I'm setting the formatted array!");
    setItems(mapArray(newGroupsIn));
  };

  const moveBothArrays = (formattedArray, oldIndex, newIndex, surveyId) => {
    setItems(arrayMove(formattedArray, oldIndex, newIndex));
    setGroupsIn(arrayMove(groupsIn, oldIndex, newIndex));
    orderGroups(arrayMove(groupsIn, oldIndex, newIndex), setGroupsIn, surveyId);
  };

  const addMe = () => {
    setShowGroupModal(true);
  };

  // var items = mapArray(groupsIn);

  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      ></link>
      <div className=" pt-8 pb-2 flex justify-end ">
        <button
          type="button"
          className="inline-flex  self -center items-center content-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
          onClick={() => addMe(groupsIn, setGroupsIn, contactId, surveyId)}
        >
          <i className="fa fa-plus "></i> <div className="px-2">Add Group</div>
        </button>
      </div>
      {groupsIn.length > 1 ? (
        <div className="bg-gray-200 border-solid border-2  border-gray-100 ">
          <div className="px-6">Drag Rows to Change Order</div>
        </div>
      ) : (
        ""
      )}
      <div className="mt-8 flex flex-col"></div>

      <List
        values={mapArray(groupsIn)}
        onChange={({ oldIndex, newIndex }) =>
          //  moveBothArrays(mapArray(groupsIn.sort((a,b)=> a.survey_groups[0].sortOrder - b.survey_groups[0].sortOrder)), oldIndex, newIndex, surveyId)
          moveBothArrays(mapArray(groupsIn), oldIndex, newIndex, surveyId)
        }
        renderList={({ children, props }) => <ul {...props}>{children}</ul>}
        renderItem={({ value, props, index }) => (
          <li {...props}>
            <div className="hover:bg-gray-50 ">
              <div className="grid grid-cols-6 gap-4">
                <div className="col-span-5">{value}</div>

                <div class="col-span-1 flex items-center justify-self-end px-4">
                  {
                    (items[index].props.GroupName === "Me" && highestPermission !== 'Lead Edit') ||  (highestPermission === "Edit" || (items[index].props.GroupName !== "Me" && highestPermission === "Lead Edit") || contactId === 1) ? (
                      <div>
                        <button
                          data-tip
                          data-for="deleteTip"
                          className=" rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                          onClick={() => {
                            //setGroupsIn(
                            index !== "undefined"
                              ? removeFromDBandArray(
                                  groupsIn,
                                  surveyId,
                                  setGroupsIn,
                                  index
                                )
                              : mapArray(groupsIn);
                            //);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </button>
   
                      </div>
                    ) : (
                      <></>
                    )
                    // items[index].props.GroupName + " can not be deleted"
                  }
                  {
                    items[index].props.GroupName !== "Me" ? (
                      <div className="px-2">
                        <button
                          data-tip
                          data-for="openTip"
                          className=" rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                          onClick={async () => {
                            //test - console.log("I'm here waiting for the base64");

                            //    const {Base64} = require('js-base64');
                            var myArray = mapArray(groupsIn);
                            //var dataObjectBase64 = Base64.encode(myArray[index].key.toString());
                            window.open("group?id=" + myArray[index].key, mobileAndTabletCheck()?"_self": null);
                          }}
                        >
                          <Folder
                            className="h-4 w-4 text-white shadow-sm"
                            aria-hidden="true"
                          />
                        </button>
    
                      </div>
                    ) : (
                      <div></div>
                    )
                    // <div>Me group can not be edited</div>
                  }
                </div>
              </div>
            </div>
          </li>
        )}
      />
      <MyGroupSelectModal
        open={showGroupModal}
        setOpen={setShowGroupModal}
        groupsCombo={groupsCombo}
        contactId={contactId}
        entityId={surveyId}
        entity={"survey"}
        groupsIn={groupsIn}
        setGroupsIn={setGroupsIn}
        triggerSetItems={triggerSetItems}
        highestPermission = {highestPermission}
        setData={setData}
      />
    </>
  );
}

export function arrayRemove(array, index) {
  //test - console.log(array.length + " coming in");
  array = array.slice();

  array.splice(index, 1);
  //test - console.log(array.length + " going out");
  return array;
}

function mapArray(groupIn, setItems) {
  var myArray = [];
  var nfObject = new Intl.NumberFormat("en-US");
  var nfObjectRate = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  var options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    //second: 'numeric',
    hour12: false,
    timeZone: "America/Los_Angeles",
  };
  var nfObjectDate = new Intl.DateTimeFormat("en-US");

  //test - console.log("Map Array!");
  //test - console.log(groupIn);

  groupIn.map((group) =>
    myArray.push(
      <div key={group.id} GroupName={group.GroupName}>
        <div className="flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden">
                <div className="min-w-full ">
                  <div>
                    <table className="min-w-full">
                      <thead>
                        <tr className="text-left ">
                          <th className="w-60"></th>
                          <th className="w-20"></th>
                          <th className="w-20"></th>
                          <th className="w-20"></th>
                          <th className="w-40"></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          className="text-left  hover:cursor-pointer"
                          onClick={async () => {
                            //const {Base64} = require('js-base64');
                            // var dataObjectBase64 = Base64.encode(group.id.toString());
                            window.open("group?id=" + group.id, mobileAndTabletCheck()?"_self": null);
                            //    await Alert('Alert text', 'Alert title');
                          }}
                        >
                          <th className="flex items-center">
                            <div className="flex justify-center w-half ">
                              {group.images ? (
                                <DemoCarousel
                                  key={group.id}
                                  images={group.images}
                                />
                              ) : (
                                  <Typography component={'div'}variant="h5" sx={{ display: 'flex', padding: 1, backgroundColor: '#4e46e5', color: 'white' }}>  <Tooltip placement="top" title={group.GroupName}>
        <Avatar alt={group.GroupName}

          onClick={() => { window.open("group?id=" + group.id, mobileAndTabletCheck() ? "_self" : null) }}
          sx={{ cursor: 'pointer', marginRight: 3 }}
          src={
            group.images.length > 0
              ? group.images.sort(
                (a, b) => a.sortOrder - b.sortOrder
              )[0].img
              : null
          }

        >{group.GroupName && group.GroupName.length > 0 && group.GroupName[0]}</Avatar>
      </Tooltip>{group.GroupName} </Typography>
                              )}
                            </div>
                            <div
                              className="w-half"
                              onClick={async () => {
                                //const {Base64} = require('js-base64');
                                //var dataObjectBase64 = Base64.encode(group.id.toString());
                                window.open("group?id=" + group.id, mobileAndTabletCheck()?"_self": null);
                                //    await Alert('Alert text', 'Alert title');
                              }}
                            >
                              <div className="px-6 ">
                                <div className="font-medium text-gray-900">
                                  {group.GroupName
                                    ? group.GroupName
                                    : "Untitled Group Created " +
                                      group.createdAt}
                                </div>
                                {/* <div className="font-medium text-gray-500"> {group.Suite !=='0' && group.Suite? "Suite " + group.Suite: "Please Add Suite"}</div>  */}
                              </div>
                            </div>
                          </th>
                          <th className=" w-20">
                            <div>
                              <div className="font-medium text-gray-900">
                                Created:{" "}
                                {nfObjectDate.format(
                                  new Date(group.createdAt)
                                ) || "No Recorded Date"}
                              </div>
                            </div>
                          </th>
                          {/* <th className="w-20">
                            <div className="font-medium text-gray-900">
                              Updated:{" "}
                              {nfObjectDate.format(new Date(group.updatedAt)) ||
                                "No Recorded Date"}
                            </div>
                          </th> */}
                          <th className="w-20">
                            <div className="font-medium text-gray-900">
                              Permission:{" "}
                              {group.survey_groups[0].groupPermission ||
                                "No Recorded Date"}
                            </div>
                          </th>
                          <th className="w-20">
                            {/* <div className="font-medium text-gray-900">{group.Condition || "Contact agent or owner"}</div> */}
                          </th>
                          <th className=" w-40">
                            <div>
                              {/* {group.groupcontacts.contacts.sort((a,b)=> a.group_contact.sortOrder - b.group_contact.sortOrder).map((contact) => (
              <div className="flex items-center py-1">
                  <div className="h-10 w-10 rounded-full">

                      <img className="h-10 w-10 flex-shrink-0 rounded-full object-cover" src={contact.images.length > 0 ? contact.images.sort((a,b)=> a.sortOrder - b.sortOrder)[0].img : "https://images.cubicsearch.com/UserLogo.png"} alt="" />
              
                  </div>
                  <div className="w-30">
                      <div className="font-medium text-gray-900">{contact.FirstName} {contact.LastName}</div>
                      <div className="text-gray-500">{contact.office && contact.office.company ? contact.office.company.Name : "Add Company"}</div>
                  </div>
              </div>
          ))} */}
                            </div>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );

  return myArray;
}

async function deleteGroup(groupId, surveyId) {
  var qs = "groupId=" + groupId + "&surveyId=" + surveyId;
  //test - console.log(
  //   process.env.REACT_APP_DATABASE + "api/markDeleteSurveyGroup?" +
  //     qs
  // );
  var resultInfo = await fetch(
    process.env.REACT_APP_DATABASE + "api/markDeleteSurveyGroup?" +
      qs
  );
}

async function orderGroups(groups, setGroupsIn, surveyId) {
  //test - console.log("Update the order in the database");
  ////test - console.log(groups)

  groups.map(async (group, index) => {
    //test - console.log(group.id);
    var qs =
      "groupId=" + group.id + "&sortOrder=" + index + "&surveyId=" + surveyId;
    //test - console.log(
    //   process.env.REACT_APP_DATABASE + "api/sortSurveyGroups?" +
    //     qs
    // );
    var resultInfo = await fetch(
      process.env.REACT_APP_DATABASE + "api/sortSurveyGroups?" +
        qs
    );
  });
  setGroupsIn(groups);
  // setGroupsIn(groups.sort((a,b)=> a.survey_groups[0].sortOrder - b.survey_groups[0].sortOrder))
  return 1;
}

const removeFromDBandArray = (groupsIn, surveyId, setGroupsIn, index) => {
  var newDataIn = [...groupsIn];
  //
  //test - console.log("removing from array");
  //test - console.log(groupsIn.length);
  //test - console.log(index);
  deleteGroup(newDataIn[index].id, surveyId);
  setGroupsIn(arrayRemove(newDataIn, index));

  //test - console.log(groupsIn.length);

  //return arrayRemove(formattedArray, index)
};
