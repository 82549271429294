import React from "react";
import { useState } from "react";

import "keen-slider/keen-slider.min.css";
import Header from "../Common/Header.js";
import HeaderForProject from "./HeaderForProject.js";
import MainProjectInfo from "./MainProjectInfo.js";
import KeenSlider from "../Images/KeenSlider.js";

import ProjectEdit from "./ProjectEdit.js";
import ImageUploadGallery from "../Common/Uploader/ImageUploadGallery";
import axios from 'axios'
import { markDeleteImage, orderImages } from "../Availables/AvailableDetail";
import { Edit, Save } from "@mui/icons-material"
import { Grid, Box } from "@mui/material";

async function updateProject(data) {
  //test - console.log("my Data" + data);

  const qs = Object.keys(data) // turn the parameters into a get query string parameters style
    .map((key) => `${key}=${encodeURIComponent(data[key])}`)
    .join("&");

  //   const {Base64} = require('js-base64');
  //   var dataObjectBase64 = Base64.encode(qs);
  ////test - console.log(dataObjectBase64    );
  //      var resultInfo = await fetch(process.env.REACT_APP_DATABASE + "api/updateProject?" + qs);
  var resultInfo = await fetch(
    process.env.REACT_APP_DATABASE + "api/updateProject?" +
      qs
  );

  var var2 = await resultInfo.json();
  //test - console.log("results = " + var2);
}

export default function Project({
  ProjectInformation,
  // peopleCombo,
  user,
  groups,
  groupsCombo,
}) {
  const [projectStateData, setProjectStateData] =
    useState(ProjectInformation);
  const [editMode, setEditMode] = useState("View");
 
  var initialPics = [];

  var highestPermission = "None";
  if (user.contact.id === 1) {
    highestPermission = "Lead Edit";
  } else {
    groups.map((group) => {
      group.project_groups.map((project_group) => {
        if (
          highestPermission !== "View" &&
          highestPermission !== "Edit" &&
          highestPermission !== "Lead Edit"
        ) {
          highestPermission = project_group.groupPermission;
        } else {
          if (
            (highestPermission === "View" &&
              project_group.groupPermission === "Edit") ||
            project_group.groupPermission === "Lead Edit"
          ) {
            highestPermission = project_group.groupPermission;
          } else if (
            highestPermission === "Edit" &&
            project_group.groupPermission === "Lead Edit"
          ) {
            highestPermission = project_group.groupPermission;
          }
        }
      });
    });
  }

  function editClick() {
  if (editMode === "Edit") {
    setEditMode("View");
    updateProject(projectStateData);
  } else {
    setEditMode("Edit");

  }
}

  //test - console.log(projectStateData);
  projectStateData.images.map((image) => {
    initialPics.push({
      source: image.img,
      img: image.img,
      name: image.id,
    });
    //return <></>
  });

  async function addProjectImage(image) {
    const updatedImages = [...projectStateData.images, {
      source: image.message,
      img: image.message,
      url: image.message,
      name: image.name,
      id: image.name
    }];

    var result = await axios.get(process.env.REACT_APP_DATABASE + "api/addProjectImage", {
      params: {
        img: image.message,
        myId: user.contact.id,
        projectId: projectStateData.id,
        sortOrder: updatedImages.length
      }
    })
    setProjectStateData({ ...projectStateData, images: updatedImages });
  }

  return (
    <div>
      <Box sx={{ position: 'sticky', top: 0, zIndex: 50 }}>
        <Header
          key="1"
          placeholderLocation={projectStateData.ProjectName}
          icon="project"
          user={user}
          highestPermission={highestPermission}
        />
      </Box>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={10} md={8} lg={6} xl={6}>
          <Box sx={{ px: 5, py: 10 }}>
            <HeaderForProject
              setEditMode={setEditMode}
         

              editMode={editMode}
              dataIn={projectStateData}
              editClick={editClick}
              setData={setProjectStateData}
              highestPermission={highestPermission}
              contactId={user.contact.id}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ maxHeight: 300, width: 500, alignSelf: 'center' }}>
              {editMode === "View" ? (
                <Box sx={{ maxWidth: 400 }}>
                  <KeenSlider dataIn={projectStateData} width={200} />
                </Box>
              ) : (
                <ImageUploadGallery
                  initialImages={initialPics}
                  onDeleted={(image) => markDeleteImage(image, projectStateData, setProjectStateData)}
                  onSortEnd={async (images) => {
                    await orderImages(images, projectStateData, setProjectStateData);
                  }}
                  action={process.env.REACT_APP_IMAGE}
                  onSuccess={(image) => {
                    addProjectImage(image);
                  }}
                />
              )}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {editMode === "View" ? (
              <MainProjectInfo
                dataIn={projectStateData}
                setDataIn={setProjectStateData}
                contactId={user.contact.id}
                groups={groups}
                groupsCombo={groupsCombo}
                highestPermission={highestPermission}
              />
            ) : (
              <ProjectEdit dataIn={projectStateData} setDataIn={setProjectStateData} />
            )}
          </Box>
        </Grid>
      </Grid>
    </div>

  );
}










function updateJson(jsonFieldIn, reqValueIn) {
  if (reqValueIn !== "") {
    if (reqValueIn === -1) {
      jsonFieldIn = null;
    } else {
      jsonFieldIn = reqValueIn;
    }
  }
  // return jsonFieldIn;
  return reqValueIn;
}
