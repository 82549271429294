import React, { useState, useEffect, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, CircularProgress, ListSubheader, Button, Avatar, ListItemAvatar, TextField, List, ListItem, ListItemText, Checkbox } from '@mui/material';
import axios from 'axios';
import { debounce } from 'lodash';

function ContactsDialogTest({ open, onClose, contactId, campaignMailerId }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [showCreateContactForm, setShowCreateContactForm] = useState(false);
    const [newContact, setNewContact] = useState({ FirstName: '', LastName: '', Email: '', images: [] });

    useEffect(() => {
        if (!campaignMailerId) {
            // alert("Problem with ID");
        }

        const getContact = async () => {
            const qs = `${process.env.REACT_APP_DATABASE}api/getContact?id=${contactId}&contactId=${contactId}`;
            const contact = await axios.get(qs);
            return contact?.data?.contact;
        };

        if (open) {
            getContact().then((contact) => {
                if (contact) {
                    setContacts([contact]);
                } else {
                    setContacts([]);
                }
            }).catch((error) => console.log(error));
            setSearchTerm('');
        }
    }, [open, contactId, campaignMailerId]);

    const handleSelectContact = (id) => {
        const selectedIndex = selectedContacts.indexOf(id);
        let newSelectedContacts = [];

        if (selectedIndex === -1) {
            newSelectedContacts = newSelectedContacts.concat(selectedContacts, id);
        } else if (selectedIndex === 0) {
            newSelectedContacts = newSelectedContacts.concat(selectedContacts.slice(1));
        } else if (selectedIndex === selectedContacts.length - 1) {
            newSelectedContacts = newSelectedContacts.concat(selectedContacts.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedContacts = newSelectedContacts.concat(
                selectedContacts.slice(0, selectedIndex),
                selectedContacts.slice(selectedIndex + 1),
            );
        }

        setSelectedContacts(newSelectedContacts);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedContacts = contacts.map((n) => n.id);
            setSelectedContacts(newSelectedContacts);
            return;
        }
        setSelectedContacts([]);
    };

    const emailTest = async () => {
        console.log('Selected Contacts to Email:', selectedContacts);
        const theContactList = filterSelectedContacts(contacts, selectedContacts);

        const response = await axios.post(`${process.env.REACT_APP_DATABASE}api/emailTestBrevo`, {
            contactId,
            selectedContacts: theContactList,
            campaignMailerId,
        }).catch((error) => console.log(error));

        onClose();
    };

    const debouncedSearch = useCallback(debounce(async (query) => {
        if (!query) {
            setContacts([]);
            setLoading(false);
            return;
        }
        setLoading(true);

        const source = axios.CancelToken.source();
        try {
            const response = await axios.get(`${process.env.REACT_APP_DATABASE}api/getContacts`, {
                cancelToken: source.token,
                params: {
                    contactId,
                    query,
                    limit: 100,
                },
            });

            setContacts(response.data.contact);
        } catch (error) {
            if (!axios.isCancel(error)) {
                console.error('Search API request failed: ', error);
                setContacts([]);
            }
        } finally {
            setLoading(false);
        }
    }, 750), [contactId]);

    useEffect(() => {
        if (searchTerm) {
            debouncedSearch(searchTerm);
        } else {
            setContacts([]);
        }
        return () => debouncedSearch.cancel();
    }, [searchTerm, debouncedSearch]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filterSelectedContacts = (contacts, selectedContactIds) => {
        return contacts.filter(contact => selectedContactIds.includes(contact.id));
    };

    const handleCreateContact = () => {
        setShowCreateContactForm(true);
    };

    const handleSaveNewContact = () => {
        const newContactWithId = { ...newContact, id: Date.now().toString() };
        setContacts([...contacts, newContactWithId]);
        setSelectedContacts([newContactWithId.id]);
        setShowCreateContactForm(false);
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Search For Contacts For the Test Email</DialogTitle>
            <DialogContent>
                {showCreateContactForm ? (
                    <div>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="First Name"
                            variant="outlined"
                            value={newContact.FirstName}
                            onChange={(e) => setNewContact({ ...newContact, FirstName: e.target.value })}
                            autoFocus
                        />
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Last Name"
                            variant="outlined"
                            value={newContact.LastName}
                            onChange={(e) => setNewContact({ ...newContact, LastName: e.target.value })}
                        />
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Email"
                            variant="outlined"
                            value={newContact.Email}
                            onChange={(e) => setNewContact({ ...newContact, Email: e.target.value })}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSaveNewContact}
                        >
                            Save Contact
                        </Button>
                    </div>
                ) : (
                    <>
                        <List
                            subheader={
                                <ListSubheader component="div" style={{
                                    position: 'sticky', top: 0, zIndex: 1,
                                    backgroundColor: '#ffffff',
                                    borderBottom: '1px solid #e0e0e0',
                                }}>
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        label="Search Website or Name"
                                        variant="outlined"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                        autoFocus
                                    />
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: '10px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Checkbox
                                                onChange={handleSelectAllClick}
                                            />
                                            Select All
                                        </div>
                                        <Button
                                            disabled={!selectedContacts || selectedContacts?.length === 0}
                                            variant="contained" color="primary" onClick={emailTest}>
                                            Email Test
                                        </Button>
                                    </div>
                                </ListSubheader>
                            }
                        >
                            {loading ? <CircularProgress /> : (
                                contacts?.map((contact) => (
                                    <ListItem key={contact.id} button>
                                        <Checkbox
                                            checked={selectedContacts.includes(contact.id)}
                                            onChange={() => handleSelectContact(contact.id)}
                                        />
                                        <ListItemAvatar>
                                            <Avatar src={contact.images?.[0]?.img} />
                                        </ListItemAvatar>
                                        <ListItemText primary={`${contact.FirstName} ${contact.LastName}`} secondary={contact.Email} />
                                    </ListItem>
                                ))
                            )}
                        </List>
                        {contacts?.length === 0 && !loading && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleCreateContact}
                            >
                                Create New Contact
                            </Button>
                        )}
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
}

export default ContactsDialogTest;
