import axios from 'axios';
import polylabel from 'polylabel';


export function getZoomFromElevation(elevation) {
    return 23.621 - 1.158 * Math.log(elevation)

    // 23.621 - y = 1.158ln(elevation)
}

export function getElevationFromZoom(zoom) {
    if (!zoom) {
        zoom = 15
    }
    return Math.exp((23.621 - zoom)) / 1.158
}





export function center(arr) {



    // var region = new Region(polygon)
    var y = (polylabel(arr)[0])
    var x = (polylabel(arr)[1])
    // //test - console.log(x)
    // //test - console.log(y)
    return [x, y]
}

// const BUILDING_DATA =
//     'https://data.sfgov.org/resource/ynuv-fyni.geojson';




export function getViewportElevation(mapRef, backUpCoords) {
    console.log("viewport elevation")
    return new Promise(async function (resolve, reject) {



        var myCenter = null


        if (mapRef && mapRef.current && backUpCoords && backUpCoords.Elevation) {
            var bounds = mapRef.current.getBounds()
            if ((bounds._ne.lat >= backUpCoords.Latitude) && (bounds._sw.lat <= backUpCoords.Latitude)
                && (bounds._ne.lng >= backUpCoords.Longitude) && (bounds._sw.lng <= backUpCoords.Longitude)) {

                resolve({ elevation: backUpCoords.Elevation })
                return (true)

            }


        }
        if (mapRef && mapRef.current) {


            myCenter = mapRef.current.getCenter()

        }
        else if (backUpCoords) {
            myCenter = {}

            myCenter.lat = backUpCoords.Latitude
            myCenter.lng = backUpCoords.Longitude
        }
        
        if (myCenter) {
            
            var elevationData = await axios.get(process.env.REACT_APP_DATABASE +
                "api/getElevationLocal?latitude=" + (myCenter.lat +
                    "&longitude=" + myCenter.lng))


         
            if (elevationData && elevationData.data.elevation !== "not found") {

                resolve(elevationData.data)

            } else {

                resolve({ elevation: 5 })
            }
        }




        else {

            resolve({ elevation: 5 })
        }

    })
}


export function sizeThresholds(d, camElevation, distanceFromGround) {

    if (camElevation > 13000000) {

        return d.data["SUM(avails.SizeMax)"] / 800000
    }
    else if (camElevation > 8000000) {
        // alert("40")
        return d.data["SUM(avails.SizeMax)"] / 50000
    } else if (distanceFromGround > 10000) {

        return d.data["SUM(avails.SizeMax)"] / 7000
    }
    else if ((!distanceFromGround) || (distanceFromGround < 10000)) {

        return d.data["SUM(avails.SizeMax)"] / 1000
    }
    else {
        // alert(distanceFromGround)
        // alert(camElevation)
    }
}
