import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { createCookie } from '../Common/Cookies';
import InfoCard from './InfoCard';
import { CardActions, CardContent, Card, CardHeader } from '@mui/material';

export default function FavoriteDialog({ open, setOpen,
    setSelectedLocation, setShowSurveys,
    selectedLocation, property, setSaveSurveyOpen,
setSearchResults}) {
    
    
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

   
    if (open){
    return (
        <React.Fragment>

            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const email = formJson.email;
                        console.log(email);
                        handleClose();
                    },
                }}
            >
              
                
                <DialogActions>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <Card sx={{ margin: 4, padding: 2 }}>
                            <CardHeader
                               
                                title="New Survey"> </CardHeader>
                            <CardContent>
                                Start a new survey for a new or existing client
                            </CardContent>
                            <CardActions>
                                <Button variant='contained' style={{ margin: 2 }} onClick={
                                    () => {
                                        setSearchResults([property])
                                        setSaveSurveyOpen(true)
                                       
                                        handleClose()
                                    }}>New Survey</Button>
                            </CardActions>
                        </Card>

                        <Card sx={{ margin: 4, padding: 2 }}>
                            <CardHeader

                                title="Existing Survey"> </CardHeader>
                            <CardContent>
                                Add this property to one or more of your existing surveys
                            </CardContent>
                            <CardActions>
                                <Button variant='contained' style={{ margin: 2 }} onClick={() => {

                                    if (selectedLocation?.id !== property.id) // if we are not the selected location then set us
                                    {
                                        if (setSelectedLocation) {

                                            setSelectedLocation(property)
                                            createCookie("selectedLocation", property.id, 1)
                                            setShowSurveys(true)

                                        }
                                        else {
                                            alert("This action can not be done from this page.")
                                        }
                                    }
                                    else {
                                        setShowSurveys(true) // we are the selected location
                                    }
                                    handleClose()
                                }}>Add To Survey</Button>
                            </CardActions>
                        </Card>
                        
                      
                       
                    </div>
                </DialogActions>
            </Dialog>
        </React.Fragment>
        );
    }
    else {
        return <></>
    }
}