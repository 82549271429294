import { useState } from "react";
import MyGroupsRows from "./MyGroupsRows";
import MyGroupsEditTable from "./MyGroupsEditTable";
import { Save, Edit } from "@mui/icons-material";
import { Typography, Button } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {TextField} from "@mui/material";
import { mobileAndTabletCheck } from "../Search/Deck";


async function setAdd(addingGroup, setAddingGroup, setButtonText, contactId) {
    var fetchLocation = process.env.REACT_APP_DATABASE + "api/addGroup?id="+ contactId

    // const searchResults = await data.json();
    
    if (addingGroup) {
        setButtonText(<Edit sx={{width: 18, height: 18}}/>)
        //const data = await fetch(fetchLocation);
        ////test - console.log(data);
    }
    else {
        setButtonText(<Save/>)
    }
    setAddingGroup(!addingGroup)

}
  
  export default function MyGroupsList({groupsIn, contactId}) {
      const [addingGroup, setAddingGroup] = useState(false);
    //test - console.log("Group In:");
    //test - console.log(groupsIn);
    const [ buttonText, setButtonText] = useState(<Edit sx={{width: 18, height: 18}}/>)
      const [groupsState, setGroupsState] = useState(groupsIn)
      const [filteredDataIn, setFilteredDataIn] = useState(groupsIn?.sort((a, b) => { return b.updatedAt - a.updatedAt }))

    if (groupsState.length > 0){
        //test - console.log("groupsState updates")
        //test - console.log(groupsState)
        return (
            <div style={{
                marginLeft: !mobileAndTabletCheck() ? '25%' : null, marginRight: !mobileAndTabletCheck() ? '25%' : null,
            }}>
                <div >
                    <Typography component={'div'}variant="h4">My Groups and Discussions</Typography>
                    <Button
                        variant={'outlined'}
                        sx={
                            {
                                marginLeft: 1, minWidth: 0,
                                width: 20,
                                height: 24, borderColor: 'black', color: 'black'
                            }}
                        onClick={() => setAdd(addingGroup, setAddingGroup, setButtonText, contactId)}>
              
                            {buttonText}
                    </Button>
               
                </div>


                <div >
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <TextField

                            id="combo-box-demo"

                            sx={{ width: 300 }}
                            label={"Search Groups"}

                            onChange={(input) => {

                                var filteredData = groupsState.filter((data) => {
                                    
                                    if (data.GroupName.includes(input.target.value) || input.target.length === 0) {
                                        return true
                                    }
                                })

                                setFilteredDataIn(filteredData)
                            }}
                        />
                    </div>
                    
               
                {
                    addingGroup ? <MyGroupsEditTable contactId={contactId}
                            dataIn={groupsState} setDataIn={setGroupsState}
                            filteredDataIn={filteredDataIn} setFilteredDataIn={setFilteredDataIn} /> : 
                            <MyGroupsRows contactId={contactId} groupsIn={filteredDataIn}
                                />
                }
              
                </div>
           
         </div>                    
        )
     }   
     else {
       
         return(
            <div role="list" className="px-0 sm:px-0 lg:px-0">
            <div className="flex justify-between sm:items-center">
             
                <h1 className="text-xl font-semibold text-gray-900">No Groups</h1>
                <div className="mt-4 sm:mt-0 sm:ml-16  sm:flex-none">
                <button
                    type="button"
                    className="inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                    onClick={()=>setAdd(addingGroup, setAddingGroup, setButtonText, contactId )}
                >
                    {buttonText}
                </button>
              
                </div>
            </div>
            {
                    addingGroup ? <MyGroupsEditTable contactId={contactId} dataIn={groupsState} setDataIn={setGroupsState}/> : <MyGroupsRows contactId = {contactId} groupsIn={groupsState}/>
                }
                
            </div>
            
         )

     }            
}
