import React, { useState, useEffect, useCallback } from 'react';
import { Button, Grid, TextField, CircularProgress, ListItemAvatar, Autocomplete, Avatar, Typography } from '@mui/material';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { MapboxAutocompleteCityState } from './MapboxAutocomplete';
import FindContactsResultDialog from './FindContactsResultDialog';
import ContactsDialog from '../ContactsDialog';
import CompanySearchComponent from '../CompanySearchComponent';
import CompanyAutoComplete from './CompanyAutoComplete';

export default function SearchForm({ searchMethod, setSearchMethod,
    nameIn, domainIn, companyIn, updateMe, office, contactIn, contactId, mailListId, MailListName, recipients, fetchData }) {
    const [companyName, setCompanyName] = useState(companyIn?.Name || '');

    const [loading, setLoading] = useState(false);
    const [domain, setDomain] = useState(domainIn || '');
    const [company, setCompany] = useState(companyIn || {});
    const [contactName, setContactName] = useState(nameIn || '');
    const [emailResult, setEmailResult] = useState('');
    const [city, setCity] = useState(office?.City)
    const [state, setState] = useState(office?.State)
    const [showContactResults, setShowContactResults] = useState(false)
    const [contactResults, setContactResults] = useState(null)
    const [showNewContacts, setShowNewContacts] = useState(false)
    const [keyWords, setKeywords] = useState("")


    useEffect(() => {


        setDomain(domainIn)
        setCompany(companyIn)
        setCompanyName(companyIn?.Name || '')
        setContactName(nameIn)
        setCity(office?.City)
        setState(office?.State)

    }, [nameIn, domainIn, companyIn])
    const handleKeywordsChange = (event) => {
        setKeywords(event.target.value);
    };


    const findPeople = async (extensive, companyIn) => {
        if (searchMethod === 'name') {
            setLoading(true)
            await searchEmail()
            setLoading(false)
            setShowContactResults(true)
        }
        else if (searchMethod === 'domain') {
            setLoading(true)

            var results = await axios.post(process.env.REACT_APP_DATABASE + "api/findPeopleForOfficeLocation",
                {
                    company: companyIn || company, // if called from teh results page they will have company saved in case we null it here
                    City: city,
                    State: state,
                    keyWords: keyWords,
                    contactId: contactId,
                    extensive: extensive || false
                }).catch((error) => { console.log(error) })
            setLoading(false)
            if (results?.data) {

                setContactResults(results.data)
                setShowContactResults(true)
            }
        }

    }


    const searchEmail = async () => {
        var theCompany = company
        
        if (!domain || !contactName) {
            alert('Domain or contact name is missing, or contains errors.');
            return;
        }
        if (!company) {
            setCompany({ Name: companyName })
            theCompany = { Name: companyName }
        }

        const splitName = contactName.split(' ');
        const firstName = splitName[0];
        const lastName = splitName.slice(1).join(' ');  // Handle names longer than two parts.

        if (firstName && lastName) {
            try {
                setLoading(true);

                const response = await axios.post(process.env.REACT_APP_DATABASE + 'api/searchEmail', {
                    firstName,
                    lastName,
                    domain,
                    company: theCompany,
                    updateMe,
                    city,
                    state,
                    contact: contactIn
                })
                setLoading(false);

                if (response?.data?.contact && isNonEmptyString(response?.data?.contact, 'Email')) {

                    var email = response?.data?.contact?.Email
                    var contact = response?.data?.contact
                    setEmailResult(`Email found: ${email}`);
                    setContactResults(prevContacts => {
                        // Check if prevContacts is null or not an array
                        if (!Array.isArray(prevContacts)) {
                            // If prevContacts is null or not an array, initialize it as an array with the new element
                            return [contact];
                        }
                        // Otherwise, append the new contact to the existing array
                        return [...prevContacts, contact];
                    });
                    // handleClose()
                } else if (response?.data?.SDeepSearch && response?.data?.email?.previous) {
                    setEmailResult('This email is already in the queue for us to find.  Please check back later.');
                }
                else if (response?.data?.SDeepSearch && !response?.data?.email?.previous) {
                    setEmailResult('This email is now in the queue for us to find.  Please check back later.');
                }
                else {
                    setEmailResult('Error finding contact')
                }


            } catch (error) {

                setLoading(false);
                setEmailResult(`Error: ${error.message}`);
            }
        }
        else {
            alert("Please enter a first and last name")
        }
    };
    function isNonEmptyString(json, key) {
        // Check if the key exists in the JSON

        if (key in json) {
            // Check if the value is a string and has a length greater than 0
            return typeof json[key] === 'string' && json[key]?.length > 0;
        }
        return false; // Return false if the key does not exist or conditions are not met
    }

    const extractDomain = (url) => {
        if (!url) return '';

        // Ensure the url includes a protocol
        if (!url.match(/^http?:\/\//i) && !url.match(/^https?:\/\//i)) {
            url = 'http://' + url; // Defaulting to HTTP if no protocol is specified
        }

        try {
            const { hostname } = new URL(url);
            return hostname;
        } catch (error) {
            console.error('Invalid URL:', error);
            return '';
        }
    };



    useEffect(() => {
        if (searchMethod === 'email') {
            setShowNewContacts(true)
        }
    }, [searchMethod]);




    return (
        <>
            {searchMethod === 'name' && (
                <Grid container spacing={2}>
                    <Grid sx={{ mt: 2 }} item xs={12}>
                        <TextField
                            label="Contact Name"
                            fullWidth
                            value={contactName}
                            onChange={(e) => setContactName(e.target.value)}
                        />

                    </Grid>

                    <Grid sx={{ mt: 2 }} item xs={12}>
                        <Grid sx={{ mt: 2 }} item xs={12}>
                            <CompanyAutoComplete
                                extractDomain={extractDomain}
                                setDomain={setDomain}
                                companyName={companyName}
                                setCompanyName={setCompanyName}
                                selectedCompany={company}
                                setSelectedCompany={setCompany}
                            />
                        </Grid>

                    </Grid>
                    <Grid sx={{ mt: 2 }} item xs={12}>

                        <MapboxAutocompleteCityState setCity={setCity} setState={setState} />
                    </Grid>
                </Grid>
            )}
            {searchMethod === 'email' && (
                <ContactsDialog
                    open={showNewContacts}
                    onClose={() => {
                        // setSearchTerm('');
                        setShowNewContacts(false);
                        setSearchMethod('')
                    }}
                    defaultText={''}
                    contactId={contactId}
                    mailListId={mailListId}
                       recipients={recipients}
                                        fetchData={fetchData}
                />
            )}
            {searchMethod === 'company' && (
                <div style={{ display: 'flex', height: '100%', width: '100%', overflow: 'hidden' }}>

                    <CompanySearchComponent
                        mailListId={mailListId}
                        contactId={contactId}
                        MailListName={MailListName}
                        setSearchMethod={setSearchMethod}
                           recipients={recipients}
                        fetchData={fetchData}
                        findPeople={findPeople}

                    />
                </div>
            )}
            {searchMethod === 'domain' && (
                <Grid container spacing={2}>
                    <Grid sx={{ mt: 2 }} item xs={12}><CompanyAutoComplete extractDomain={extractDomain} setDomain={setDomain} companyName={companyName} setCompanyName={setCompanyName} selectedCompany={company} setSelectedCompany={setCompany} /></Grid>


                    <Grid sx={{ mt: 2 }} item xs={12}>

                        <TextField onChange={handleKeywordsChange} sx={{ width: '100%' }} label="Job Title or Keywords"></TextField>
                    </Grid>

                    <Grid sx={{ mt: 2 }} item xs={12}>

                        <MapboxAutocompleteCityState setCity={setCity} setState={setState} />
                    </Grid>
                </Grid>
            )}
            {/* {loading && <CircularProgress />} */}
            {emailResult && <Typography>{emailResult}</Typography>}
            {loading && <CircularProgress />}
            {(searchMethod !== 'email') && (searchMethod !== 'company') && <Button disabled={!company}
                onClick={
                   ()=>{findPeople()}
                }
                variant="contained" color="primary" sx={{ mt: 2 }}>

                {company && <div style={{ display: 'flex' }}>
                    <Avatar style={{
                        marginRight: 4,
                        borderRadius: '10%',
                        objectFit: 'contain'  // This is actually applied to the img element inside Avatar
                    }}
                        imgProps={{
                            style: {
                                objectFit: 'contain' // Ensures the image fits within the bounds of the Avatar without stretching
                            }
                        }} src={company?.images?.[0]?.img} />   </div>}
                <Typography> Find People</Typography>


            </Button>}

            <Button onClick={() => setSearchMethod('')} variant="text" sx={{ mt: 2, position: 'absolute', top: 0, right: 40 }}>Search Methods</Button>
            <FindContactsResultDialog
                open={showContactResults}
                onClose={() => {
                    setShowContactResults(false)
                    setSearchMethod('')
                    // handleClose()
                }}
                contacts={contactResults}
                setContacts={setContactResults}
                contactId={contactId}
                mailListId={mailListId}
                   recipients={recipients}
                fetchData={fetchData}
                findPeople={findPeople}
                company={company}
            />
        </>
    );
}
