import { useState } from "react";
import ClientListRows from "./ClientListRows";
import ContactClientsClientEditTable from "./ContactClientsClientEditTable";
import { Save, Edit } from "@mui/icons-material";

async function setAdd(addingClient, setAddingClient, setButtonText, contactclientsId) {
  // var fetchLocation =
  //   process.env.REACT_APP_DATABASE + "api/addClient?id=" +
  //   contactclientsId;

  // const searchResults = await data.json();

  if (addingClient) {
    setButtonText("Edit Clients");
    //const data = await fetch(fetchLocation);
    ////test - console.log(data);
  } else {
    setButtonText(<Save/>);
  }
  setAddingClient(!addingClient);
}

export default function ClientsList({ clientsIn, contactclientsId, contactId, highestPermission }) {
  const [addingClient, setAddingClient] = useState(false);
  //test - console.log("Client In:");
  //test - console.log(clientsIn);
  const [buttonText, setButtonText] = useState(<Edit sx={{width: 18, height: 18}}/>);
  const [clientsState, setClientsState] = useState(clientsIn);

  if (clientsState && clientsState.length > 0) {
    //test - console.log("clientsState updates");
    //test - console.log(clientsState);
    return (
      <>
        <div role="list" className="px-0 sm:px-0 lg:px-0">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">
                Clients
              </h1>
            </div>
            <div className="mt-4 sm:mt-0 justify-end sm:ml-16 sm:flex-none">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                onClick={() =>
                  setAdd(addingClient, setAddingClient, setButtonText, contactclientsId)
                }
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
        {addingClient ? (
          <ContactClientsClientEditTable
            contactclientsId={contactclientsId}
            contactId={contactId}
            dataIn={clientsState}
            setDataIn={setClientsState}
            highestPermission = {highestPermission}
          />
        ) : (
          <ClientListRows clientsIn={clientsState} />
        )}
      </>
    );
  } else {
    //test - console.log("NOTHING!!!");
    //test - console.log(clientsState);
    return (
      <div role="list" className="px-0 sm:px-0 lg:px-0">
        <div className="flex justify-between sm:items-center">
          <h1 className="text-xl font-semibold text-gray-900">
            No Clients
          </h1>
          <div className="mt-4 sm:mt-0 sm:ml-16  sm:flex-none">
            <button
              type="button"
              className="inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
              onClick={() =>
                setAdd(addingClient, setAddingClient, setButtonText, contactclientsId)
              }
            >
              {buttonText}
            </button>
          </div>
        </div>
        {addingClient ? (
          <ContactClientsClientEditTable
            contactId={contactId}
            contactclientsId={contactclientsId}
            dataIn={clientsState}
            setDataIn={setClientsState}
          />
        ) : (
          <ClientListRows clientsIn={clientsState} />
        )}
      </div>
    );
  }
}
