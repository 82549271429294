// client/src/components/Dashboard.js
import React  from 'react';
import {  Route, Routes } from 'react-router-dom';
import CampaignList from './CampaignList';
import CampaignForm from './CampaignForm';
import CampaignPreview from './CampaignPreview';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';


function Dashboard({ contact, user, guest }) {

    const { id } = useParams();

    const { loginWithRedirect } = useAuth0();
    if (guest === true || guest === 'true') {
    loginWithRedirect()
    }

  

    return (<>
      
        {
        // subscriptionValid &&
        <Routes>
            <Route path="/campaigns" element={<CampaignList user={user} contact={contact} />} />
            <Route path="/campaigns/new" element={<CampaignForm contactId={contact.id} />} />
            <Route path="/campaigns/:id/preview/*" element={<CampaignPreview contactId={contact.id} campaignId={id} />} />
            <Route path="" element={<CampaignPreview contactId={contact.id} campaignId={id} user={user} />} />
        </Routes>}
    </>
    );
}

export default Dashboard;