import React, { useRef, useState, } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import AIGenerateDialog from "./AIGenerateDialog"
import { Box } from '@mui/material';
import IframeContent from './IframeContent'

function MyTinyMCEEditor({ emailDetails, setEmailDetails, index, readOnly }) {
    const editorRef = useRef(null);
    const [dialogOpen, setDialogOpen] = useState(false);


    const handleGenerateAIMessage = (emailJson) => {

        if (editorRef.current) {

            // editorRef.current.setContent(html);
            setEmailDetails(prevState => ({
                ...prevState,
                campaignMailers: prevState.campaignMailers.map((mailer, idx) =>
                    idx === index
                        ? {
                            ...mailer,
                            subject: emailJson.subject,
                            preheaderText: emailJson.preheader,
                            content: {
                                ...mailer.content,
                                html: emailJson.body// Assuming you meant to update body
                            }
                        }
                        : mailer
                )
            }));


        }
    };

    const handleInsertLink = () => {
        // Prompt the user for an email address
        const defaultEmail = 'support@cubicsearch.com';
        const userEmail = prompt('Please enter a valid email address:', defaultEmail);

        // Validate the email address (simple validation)
        if (userEmail && validateEmail(userEmail)) {
            return userEmail;
        } else {
            alert('Please enter a valid email address.');
            return null;
        }
    };

    const validateEmail = (email) => {
        // Basic email validation regex
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };
    
    
    const handleCustomActionZoom = (editor, requestedTime) => {
        const subject = encodeURIComponent('Call or Zoom - ' + emailDetails.campaignMailers[index].subject);
        const body = encodeURIComponent(`Please let me know a convenient time for a call or Zoom. How about ${requestedTime}?`);

        const userEmail = handleInsertLink();
        if (userEmail) {
            const mailtoLink =
                `<a href="mailto:${userEmail}?subject=${subject}&body=${body}" style="background-color: #4caf50; color: white; padding: 10px 20px; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer; border-radius: 5px;" title="Request a time to connect"> Request Call or Zoom</a>`;
            editor.insertContent(mailtoLink);
        }
       
    };



    const handleCustomActionTour = (editor, requestedTime) => {
        const subject = encodeURIComponent('Tour - ' + emailDetails.campaignMailers[index].subject);
        const body = encodeURIComponent(`Please let me know a convenient time for a property tour. How about ${requestedTime}?`);

        const userEmail = handleInsertLink();
        const mailtoLink =
            `<a href="mailto:${userEmail}?subject=${subject}&body=${body}" style="background-color: #4caf50; color: white; padding: 10px 20px; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer; border-radius: 5px;" title="Visit property"> Request Tour</a>`;
        editor.insertContent(mailtoLink);
    };

    const handleCustomActionMoreInfo = (editor, requestedInfo) => {
        const subject = encodeURIComponent('More Information Request ' + emailDetails.campaignMailers[index].subject);
        const body = encodeURIComponent(`Can you share more information regarding this? ${requestedInfo}`);
        const userEmail = handleInsertLink();
        const mailtoLink =
            `<a href="mailto:${userEmail}?subject=${subject}&body=${body}" style="background-color: #4caf50; color: white; padding: 10px 20px; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer; border-radius: 5px;" title="Get market pricing or activity"> More Information</a>`;
        editor.insertContent(mailtoLink);
    };

    const initCustomPlugin = (editor) => {
        editor.ui.registry.addButton('customButtonTour', {
            text: 'Request Tour Button',
            onAction: () => {
                editor.windowManager.open({
                    title: 'Insert Tour Request Link',
                    body: {
                        type: 'panel',
                        items: [
                            {
                                type: 'textarea',
                                name: 'requestedTime',
                                label: 'Sample Requested Tour Time',
                                title: 'Check out the property in person',
                            },
                        ]
                    },
                    initialData: {
                        requestedTime: 'Next Tuesday at 11 AM'
                    },
                    buttons: [
                        {
                            type: 'cancel',
                            text: 'Close'
                        },
                        {
                            type: 'submit',
                            text: 'Insert',
                            primary: true
                        }
                    ],
                    onSubmit: (api) => {
                        const data = api.getData();
                        const requestedTime = data.requestedTime;
                        handleCustomActionTour(editor, requestedTime);
                        api.close();
                    }
                });
            }
        });

        editor.ui.registry.addButton('customButtonZoom', {
            text: 'Request Zoom or Call Button',
            onAction: () => {
                editor.windowManager.open({
                    title: 'Insert Zoom Request',
                    body: {
                        type: 'panel',
                        items: [
                            {
                                type: 'textarea',
                                name: 'requestedTime',
                                label: 'Sample Requested Zoom Time',
                                title: 'Connect for more information',
                            },
                        ]
                    },
                    initialData: {
                        requestedTime: 'Next Tuesday at 11 AM'
                    },
                    buttons: [
                        {
                            type: 'cancel',
                            text: 'Close'
                        },
                        {
                            type: 'submit',
                            text: 'Insert',
                            primary: true
                        }
                    ],
                    onSubmit: (api) => {
                        const data = api.getData();
                        const requestedTime = data.requestedTime;
                        handleCustomActionZoom(editor, requestedTime);
                        api.close();
                    }
                });
            }
        });

        editor.ui.registry.addButton('customButtonMoreInfo', {
            text: 'Request More Information Button',
            onAction: () => {
                editor.windowManager.open({
                    title: 'Insert Request For More Information',
                    body: {
                        type: 'panel',
                        items: [
                            {
                                type: 'textarea',
                                name: 'requestedInfo',
                                label: 'Default email response text after recipient clicks button',
                                placeholder: 'Please send the latest pricing, expenses, and brochure. Do you have activity on this property?',
                            },
                        ],
                    },
                    initialData: {
                        requestedInfo: 'Please send the latest pricing. Do you have activity on this property?'
                    },
                    buttons: [
                        {
                            type: 'cancel',
                            text: 'Close'
                        },
                        {
                            type: 'submit',
                            text: 'Insert',
                            primary: true
                        }
                    ],
                    onSubmit: (api) => {
                        const data = api.getData();
                        const requestedInfo = data.requestedInfo;
                        handleCustomActionMoreInfo(editor, requestedInfo);
                        api.close();
                    }
                });
            }
        });

        editor.ui.registry.addButton('customButtonGenerateAIMessage', {
            text: 'Generate AI Message',
            onAction: () => {
                setDialogOpen(true);
            }
        });




    };

    console.log(emailDetails?.campaignMailers[index]?.content?.html || '')



    return (
        <>
            <AIGenerateDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                emailDetails={emailDetails}

                onGenerate={handleGenerateAIMessage}
                index={index}
            />
            {readOnly ? (
                <Box sx={{boxShadow: 3}}>
                <IframeContent htmlContent={emailDetails?.campaignMailers?.[index]?.content?.html } />
                </Box>
                    ) : (
                <Editor
                    apiKey={process.env.REACT_APP_TINY}
                    onInit={(evt, editor) => editorRef.current = editor}
                    init={{
                        selector: 'textarea',
                        setup: (editor) => {
                            initCustomPlugin(editor);
                        },
                        valid_elements: '*[*]',
                        extended_valid_elements: 'div[*],span[*],a[*],img[*],p[*],ul[*],li[*]',
                        plugins: 'anchor autolink charmap codesample emoticons imagetools image link lists media searchreplace table visualblocks wordcount',
                        toolbar: ['customButtonGenerateAIMessage | undo redo | blocks fontfamily fontsize ',
                            'bold italic underline strikethrough | link image media table',
                            'align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                            'customButtonTour | customButtonZoom | customButtonMoreInfo'],
                        image_advtab: true, // Enable advanced tab for image dialog
                        image_title: true,
                        automatic_uploads: true,
                        file_picker_types: 'image',
                        height: '80vh',
                        width: '100%',
                        file_picker_callback: function (callback, value, meta) {
                            if (meta.filetype === 'image') {
                                const input = document.createElement('input');
                                input.setAttribute('type', 'file');
                                input.setAttribute('accept', 'image/*');

                                input.onchange = async function () {
                                    const file = this.files[0];
                                    const imageUrl = await uploadImageToServer(file);
                                    callback(imageUrl, { alt: file.name });
                                };

                                input.click();
                            }
                        }
                    }}
                    onEditorChange={(newContent) => {
                        emailDetails.campaignMailers[index].content = { html: newContent };
                        var copy = JSON.parse(JSON.stringify(emailDetails));
                        setEmailDetails(copy);
                    }}
                    value={emailDetails?.campaignMailers[index]?.content?.html || ''}
                />
            )}
        </>
    );
}

export default MyTinyMCEEditor;

const uploadImageToServer = async (file) => {
    const data = new FormData();
    data.append('file', file);

    try {
        const response = await fetch(process.env.REACT_APP_IMAGE, {
            method: 'POST',
            body: data,
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const imageResponse = await response.json();
        return imageResponse.message; // Adjust this according to your actual API response
    } catch (error) {
        console.error('Error uploading image:', error);
        return '';
    }
};

const EmailContent = ({ emailDetails, index }) => {
    const htmlContent = emailDetails?.campaignMailers[index]?.content?.html || '';

    return (
        <Box sx={{ padding: '1rem', background: '#f9f9f9', borderRadius: '4px', border: '1px solid #ddd' }}>
            {htmlContent ? (
                <div
                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                    style={{ padding: '1rem', background: '#ffffff' }}
                />
            ) : (
                <p>No content available.</p>
            )}
        </Box>
    );
};