/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { Settings as CogIcon } from "@mui/icons-material";
import axios from "axios";
import { LicenseInfo } from "@mui/x-license-pro";

import { Box, Link } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";

LicenseInfo.setLicenseKey(
  process.env.REACT_APP_MUI
);

export default function ClientSurveyGrid({ open, setOpen, contactId, propertyId }) {
  const [dataIn, setDataIn] = useState(null);
  const cancelButtonRef = useRef(null);
  const gridRef = useRef(null);
  const [selectedRows, setSelectedRows] = useState(null);


  if (open && !dataIn) {
    var qs = process.env.REACT_APP_DATABASE;

    qs = qs + "api/getClientsProjectsSurveys?contactId=" + contactId;

    //test - console.log(qs);
    fetch(qs).then((journalsRaw) => {
      journalsRaw.json().then((dataInJSON) => {
        // 
        setDataIn(dataInJSON);
      });
    });
  }

  const rows = [];

  if (dataIn && dataIn.length > 0) {
    dataIn.map((data, i) => {

      var index = rows.findIndex((row) => {
        return data.surveyId === row.surveyId;
      });

      if (index >= 0) {
        //alert(index);
      }
      if (index < 0) {
        rows.push({
          id: i + 1,
          surveyId: data.surveyId,

          surveyCreatedAt: new Date(data.survey.createdAt),
          surveyUpdatedAt: new Date(data.survey.updatedAt),
          surveyNotes: data.survey.Notes,
          surveyName: data.survey.SurveyName,
          groupId: data.groupId,
          projectName:
            data.survey && data.survey.project
              ? data.survey.project.ProjectName
              : "",
          projectId:
            data.survey && data.survey.project
              ? data.survey.project.id
              : "",
          clientName:
            data.survey && data.survey.project && data.survey.project.client
              ? data.survey.project.client.ClientName
              : "",
          clientId:
            data.survey && data.survey.project && data.survey.project.client
              ? data.survey.project.client.id
              : "",
          permission: data.groupPermission,
        });
      }
    });
  }



  const columns = [
    // { field: 'id', headerName: 'ID', width: 90 },
    {
      field: "surveyName",
      headerName: "Survey",
      width: 300,
      editable: true,
      resizable: true,
      renderCell: (params) => {
        return (

          <Link href={"search?surveyId=" + params.row.surveyId}>{params.row.surveyName}</Link>


        );
      },
    },
    {
      field: "surveyDetail",
      headerName: "Details",
      width: 125,
      editable: true,
      resizable: true,
      renderCell: (params) => {
        return (
          <a
            href={"survey?id=" + params.row.surveyId}
            target="_self"
            className="text-blue cursor-pointer underline"
          >
            <CogIcon className="h-6 w-6" />
          </a>
        );
      },
    },

    // {
    //   field: "surveyNotes",
    //   headerName: "Notes",
    //   width: 300,
    //   editable: false,
    // },
    // {
    //   field: "surveyCreatedAt",
    //   headerName: "Date Created",
    //   width: 300,
    //   type: "date",
    //   editable: false,
    // },
    {
      field: "surveyUpdatedAt",
      headerName: "Date Updated",
      width: 150,
      type: "date",
      editable: false,
    },
    {
      field: "projectName",
      headerName: "Project",
      width: 300,
      editable: false,
      renderCell: (params) => {
        return (
          <a
            href={"project?id=" + params.row.projectId}
            target="_blank"
            className="text-blue cursor-pointer underline"
          >
            {params.row.projectName}
          </a>
        );
      },
    },
    {
      field: "clientName",
      headerName: "Client",
      // type: 'number',
      width: 300,
      editable: true,
      renderCell: (params) => {
        return (
          <a
            href={"client?id=" + params.row.clientId}
            target="_blank"
            className="text-blue cursor-pointer underline"
          >
            {params.row.clientName}
          </a>
        );
      },
    },

  ];

  return (
    <>
      <Dialog maxWidth={'xl'} open={open} onClose={() => { setOpen(false) }}
        title="Surveys, Projects, and Clients">
        
        <DialogContent >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src="https://images.cubicsearch.com/small%20survey.png"
              className="h-12 w-12"
              aria-hidden="true"
            />
          </div>
  
          <Box sx={{ height: 400, width: "100%" }}>
            <DataGridPro

              initialState={{
                sorting: {
                  sortModel: [
                    { field: "surveyUpdatedAt", sort: "desc" },
                  ],
                },
              }}
              ref={gridRef}
              rows={rows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              isRowSelectable={(params) => {
                return (
                  params.row.permission === "Edit" ||
                  params.row.permission === "Lead Edit"
                );
              }}
              onRowSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRowData = rows.filter((row) => {
                  return selectedIDs.has(row.id);
                });
                setSelectedRows(selectedRowData);
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
        variant="contained"
            onClick={() => setOpen(false)}

          >
            OK
          </Button>
          <Button
          variant="outlined"
            onClick={async () => {

              if (!propertyId) {
                deleteSurveys(
                  selectedRows,
                  contactId,
                  setDataIn,
                  setSelectedRows
                );
              }
              else {
                var surveysAdded = await addToSurveys(selectedRows, contactId, propertyId)
                if (surveysAdded.data.result > 0) {

                  alert("Your property has been added to the survey(s).  To view a survey, click on a link.")


                }
                else {
                  console.log(surveysAdded)
                  alert("There was a problem with the add survey request.")

                }
              }
            }}
          >

            {propertyId ? "Add Building to Surveys" : "Delete Selected"}
          </Button>

        </DialogActions>
      </Dialog>

    </>
  )







}

function addToSurveys(myRows, contactId, propertyId) {
  return new Promise(async (resolve, reject) => {

    var theData = {
      surveyRows: myRows,
      propertyId: propertyId,
      contactId: contactId,
    }

    var response = await axios.post(process.env.REACT_APP_DATABASE + "api/addToSurveys", theData)
    if (response) {
      resolve(response)
      return true
    }
    else {
      reject({ reason: "axios failure" })
      return (false)
    }
  })

}

function deleteSurveys(myrows, contactId, setDataIn, setSelectedRows) {
  var surveyIds = [];
  var surveyGroupJsons = [];
  //test - console.log("deleting");
  ;
  myrows.map((row) => {
    surveyIds.push(row.surveyId);
    surveyGroupJsons.push({ surveyId: row.surveyId, groupId: row.groupId });

    //test - console.log(surveyIds);
    //test - console.log(surveyGroupJsons);
  });

  var qs = process.env.REACT_APP_DATABASE;

  var stringSurveyIds = encodeURI(JSON.stringify(surveyIds));
  var stringSurveyGroupJsons = encodeURI(JSON.stringify(surveyGroupJsons));
  qs =
    qs +
    "api/markDeleteSurveyList?surveyIds=" +
    stringSurveyIds +
    "&&surveyGroupIds=" +
    stringSurveyGroupJsons;

  //test - console.log(qs);
  fetch(qs)
    .then((response) => {
      ;
      //test - console.log(response);
      var qs = process.env.REACT_APP_DATABASE;

      qs = qs + "api/getClientsProjectsSurveys?contactId=" + contactId;

      //test - console.log(qs);
      fetch(qs).then((journalsRaw) => {
        journalsRaw.json().then((dataInJSON) => {
          // 

          setSelectedRows([]);
          setDataIn(dataInJSON);
        });
      });
    })
    .catch((err) => {
      alert(err.message);
    });
}
