import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Checkbox,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    Box,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Typography,
    FormControlLabel,
    Link,
    Tooltip
} from '@mui/material';

import LookupButton from './LookupButton';
import CenteredLoader from '../CenteredLoader';

function FindContactsResultDialog({ open, onClose, contacts, setContacts, contactId, mailListId, Title, recipients, fetchData, findPeople, company }) {
    const [selectedContacts, setSelectedContacts] = useState({});
    const [recipientsIdLocal, setRecipientsIdLocal] = useState([]);
    const [recipientsEmailLocal, setRecipientsEmailLocal] = useState([]);
    const [loading, setLoading] = useState(false);
    const [saveCompany, setSaveCompany] = useState(null);

    useEffect(() => {
        if (company && company !== '' &&  company !== saveCompany) {
            setSaveCompany(company);
        }
    }, [company]);

    useEffect(() => {
        if (open) {
            // Initialize contacts from props and sort them
            const sortedContacts = (contacts || []).sort((a, b) => {
                // Sort by email presence, last name, and first name
                if (!a.Email && b.Email) return 1;
                if (a.Email && !b.Email) return -1;
                if (a.LastName < b.LastName) return -1;
                if (a.LastName > b.LastName) return 1;
                return a.FirstName.localeCompare(b.FirstName);
            }).filter(contact => contact.Title !== 'Place Holder');

            setContacts(sortedContacts);
            const newSelected = {};
            sortedContacts.forEach(contact => {
                newSelected[contact.id] = selectedContacts[contact.id] || false;
            });
            setSelectedContacts(newSelected);
            setRecipientsIdLocal(recipients?.map(recipient => recipient.id));
            setRecipientsEmailLocal(recipients?.map(recipient => recipient.Email));
        }
    }, [open, contacts]); // Run this effect only when the dialog opens

    const handleLookup = async (id, fields) => {
        try {
            // add searchEmail here if we already have linkedin information or title
            const response = await axios.post(`${process.env.REACT_APP_DATABASE}api/verifyPerson`,
                { id, fields, verifyLinkedInJson: true });
            const updatedContact = response?.data; // The updated contact data from the API

            if (updatedContact) {
                const newContacts = contacts.map(contact => {
                    if (contact.id === id) {
                        // Update the contact with new data from the API
                        return {
                            ...contact,
                            ...updatedContact,
                            Email: updatedContact.Email || contact.Email, // Update from API or keep existing
                            Title: updatedContact.Title || contact.Title, // Update from API or keep existing
                            images: updatedContact.images || []
                        };
                    }
                    return contact;
                });
                setContacts(newContacts);
                await fetchData();
            }
        } catch (error) {
            console.error('Failed to fetch updated data:', error);
            return null;
        }
    };

    const handleAvatarClick = contactId => {
        const url = `${window.location.origin}/contact?id=${contactId}`;
        window.open(url, '_blank');
    };

    const handleSelectAll = event => {
        const newSelected = {};
        contacts.forEach(contact => {
            if (contact.Email) { // Only enable selecting if the contact has an email
                newSelected[contact.id] = event.target.checked;
            }
        });
        setSelectedContacts(newSelected);
    };

    const toggleContactSelection = id => {
        setSelectedContacts(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    const isAllSelected = contacts?.length > 0 && Object.values(selectedContacts).every(Boolean);
    const anySelected = Object.values(selectedContacts)?.some(value => value);
    const [anySelectable, setAnySelectable] = useState(false);

    useEffect(() => {
        setAnySelectable(contacts?.some(contact => {
            // Check if contact has a non-null Email
            if (contact.Email) {
                // Check if this contact is not in recipients array
                return !recipients?.some(recipient => recipient.Email && recipient.Email.toLowerCase() === contact.Email.toLowerCase());
            }
            return false;
        }));
    }, [recipients, contacts]);

    const getLookupButtonText = contact => {
        const missing = [];
        if (!contact.Email) missing.push('Email');
        if (!contact.Title) missing.push('Title');
        if (!contact.Linkedin) missing.push('LinkedIn');
        if (!contact?.images?.length > 0) missing.push('Photo');
        return `Lookup ${missing.join(', ')}`;
    };

    const getImageUrl = contact => {
        return contact.images?.[0]?.img || contact?.office?.company?.images?.[0]?.img;
    };

    const getAvatar = contact => {
        const imageUrl = getImageUrl(contact);
        const isCompanyLogo = !contact.images?.[0]?.img && contact?.office?.company?.images?.[0]?.img;

        return (
            <div
                style={{
                    width: 40,
                    height: 40,
                    borderRadius: isCompanyLogo ? '5px' : '50%',
                    backgroundImage: `url(${imageUrl})`,
                    backgroundSize: isCompanyLogo ? 'contain' : 'cover', // 'contain' ensures the entire logo is visible
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden' // Ensures no overflow outside the borderRadius
                }}
                onClick={event => {
                    event.stopPropagation();
                    handleAvatarClick(contact.id);
                }}
            />
        );
    };

    const handleAddContacts = async () => {
        // Implement logic to add selected contacts to your list
        console.log('Selected Contacts to Add:', selectedContacts);

        const theContacts = contacts.filter(contact => selectedContacts[contact.id]);
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_DATABASE}api/importContactsToBrevoAPI`, {
            contactId,
            selectedContacts: theContacts,
            mailListId
        }).catch(error => { console.log(error); });
        await fetchData();
        setLoading(false);
        onClose();
        // After adding, you might want to clear the selection or close the dialog
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} fullWidth={true}>
                <DialogTitle>
                    Add Recipients
                    <Typography>{Title}</Typography>
                    <Box sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1, padding: 1, borderBottom: '1px solid #ccc' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isAllSelected}
                                    onChange={handleSelectAll}
                                    indeterminate={!isAllSelected && anySelected}
                                    disabled={!anySelectable} // Disable if no checkboxes are selectable
                                />
                            }
                            label="Select All"
                        />
                    </Box>

                    <Button onClick={() => findPeople(true, saveCompany)}>Find Even More</Button>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ position: 'relative' }}>
                        <List>
                            {contacts?.map(contact => {
                                let title;
                                if (recipientsEmailLocal?.includes(contact.Email) || recipientsIdLocal?.includes(contact.id)) {
                                    title = 'This contact is already in your mail list.  Go back to the list to remove them';
                                } else if (!contact.Email) {
                                    title = "An email must exist to select, either click Lookup Email, or click the picture of the recipient and add an email in their record.";
                                } else {
                                    title = "This contact can be added to list";
                                }
                                return (
                                    <ListItem key={contact.id} alignItems="flex-start">
                                        <Tooltip title={title}>
                                            <span>
                                                <Checkbox
                                                    checked={selectedContacts[contact.id] || recipientsEmailLocal?.includes(contact.Email) || recipientsIdLocal?.includes(contact.id) || false}
                                                    onChange={() => toggleContactSelection(contact.id)}
                                                    disabled={!contact.Email || recipientsEmailLocal?.includes(contact.Email) || recipientsIdLocal?.includes(contact.id)} // Disable checkbox if no email
                                                />
                                            </span>
                                        </Tooltip>
                                        <Tooltip title="Click for contact detail">
                                            <ListItemAvatar>
                                                {getAvatar(contact)}
                                            </ListItemAvatar>
                                        </Tooltip >
                                        <ListItemText
                                            primary={`${contact.FirstName} ${contact.LastName}`}
                                            secondary={
                                                <>
                                                    {contact.Title ? (
                                                        contact.Linkedin ? (
                                                            <Tooltip title="Linkedin">
                                                                <Link href={contact.Linkedin} target="_blank">{contact.Title}</Link>
                                                            </Tooltip>
                                                        ) : (
                                                            <Typography>{contact.Title}</Typography>
                                                        )
                                                    ) : (
                                                        <Tooltip title="Linkedin">
                                                            <Link href={contact.Linkedin} target="_blank">{contact.Linkedin}</Link>
                                                        </Tooltip>
                                                    )}
                                                    <div>{contact.Email}</div>
                                                    {(!contact.Email || !contact.Title || !contact.Linkedin || (!contact?.images?.length > 0)) && (
                                                        <Tooltip title="Get more recipient data">
                                                            <LookupButton
                                                                contact={contact}
                                                                handleLookup={handleLookup}
                                                                getLookupButtonText={getLookupButtonText}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </>
                                            }
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Tooltip title="Have Cubic Search get more contacts from the web">
                        <Button
                            variant='contained'
                            onClick={event => {
                                event.stopPropagation();
                                onClose();
                            }}
                            color="primary"
                        >
                            Another Search Method
                        </Button>
                    </Tooltip>
                    <Tooltip title="Add these contacts to your mailing list">
                        <Button
                            variant='contained'
                            onClick={event => {
                                event.stopPropagation();
                                handleAddContacts();
                            }}
                            disabled={!anySelected}
                            color="primary"
                        >
                            Add to Mail List
                           
                        </Button>
                    </Tooltip>
                </DialogActions>
            </Dialog>
            {loading && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        zIndex: 2000,
                    }}
                >
                    <CircularProgress size={48} />
                </Box>
            )}

        </>
    );
}

export default FindContactsResultDialog;
