import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Box, Button } from '@mui/material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Paper from '@mui/material/Paper';
import OfficeDialog from '../Offices/OfficeDialog';

export default function OfficeCombo({
  offices,
  selectedOffice,
  setSelectedOffice,
  createOffice
}) {
  const [query, setQuery] = useState('');
  const [showOffice, setShowOffice] = useState(false)

  const handleDialogOpen = () => {
    setShowOffice(true);
  };

  const handleDialogClose = () => {
    setShowOffice(false);
  };
  // useEffect(() => {
  //   if (offices?.length > 0) {
  //     setCreateNewOffice(false);
  //     setButtonText("Select Company & Office");
  //   } else {
  //     setCreateNewOffice(true);
  //     setButtonText("Add New Office");
  //   }
  // }, [offices, setCreateNewOffice, setButtonText]);

  if (!offices) {
    return null;
  }

  return (
    <>
      <Autocomplete
        id="officeValue"
        value={selectedOffice}
        onChange={(event, newValue) => {
          setSelectedOffice(newValue);
        }}
        inputValue={query}
        onInputChange={(event, newInputValue) => {
          setQuery(newInputValue);
        }}
        options={offices}
        getOptionLabel={(option) => {
          let labelParts = [];

          // Check and add street number and name if present
          if (option.StreetNumber && option.StreetName) {
            labelParts.push(`${option.StreetNumber} ${option.StreetName}`);
          }

          // Add city if present
          if (option.City) {
            // Add a comma only if there are previous parts
            if (labelParts.length > 0) {
              labelParts.push(`, ${option.City}`);
            } else {
              labelParts.push(option.City);
            }
          }

          // Add state if present
          if (option.State) {
            labelParts.push(`, ${option.State}`);
          }

          // Join all parts to form the final label string
          return labelParts.join('');
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        filterOptions={filterOptions}
        renderOption={(props, option) => (
          <Box component="li" {...props} sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 1,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'  // Ensures text does not wrap or overflow
          }}>
            {option.id === 'new' ? (
              <Button fullWidth onClick={handleDialogOpen}>{option.Name}</Button>
            ) : (
              <>
                <div>
                  {option.StreetNumber && option.StreetName && (
                    <div style={{ color: 'gray', fontSize: '0.8rem' }}>
                      {option.StreetNumber + " " + option.StreetName}
                    </div>
                  )}

                  {option.City && (
                    <div style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                      {option.City}{option.State ? ", " + option.State : ""}
                    </div>
                  )}
                </div>
                {selectedOffice?.id === option?.id ? <CheckCircle style={{ color: '#1976d2', marginLeft: 'auto' }} /> : <></>}
              </>
            )}
          </Box>

        )}

        renderInput={(params) => (
          <TextField
            {...params}
            label="Office"
            variant="outlined"
            fullWidth
          />
        )}
        sx={{ width: '100%', maxWidth: '100%' }}  // Ensures it expands fully in all cases

        // Inside your Autocomplete component
        PaperComponent={(props) => (
          <Paper {...props} style={{ width: 'inherit', marginTop: 8 }} />
        )}
      />

      <div>

        <OfficeDialog
          open={showOffice}
          onClose={handleDialogClose}
          onSave={async (office) => {
           
            var newOffice = await createOffice(office)
            setSelectedOffice(newOffice)
          }}
          initialData={{ City: query,  }}
        />
      </div>
    </>
  );
}


const filterOptions = (options, { inputValue }) => {
  const filtered = options.filter(option => {
    const text = inputValue.toLowerCase();
    // Check if input matches street number, street name, city, or state
    return (
      option.StreetNumber?.toString().toLowerCase().includes(text) ||
      option.StreetName?.toLowerCase().includes(text) ||
      option.City?.toLowerCase().includes(text) ||
      option.State?.toLowerCase().includes(text)
    );
  });

  // Always add the create new office option
  filtered.push({
    id: 'new',
    Name: `Create Office in '${inputValue}'`
  });

  return filtered;
};


function MyComponent({ option, selected, ...props }) {
  return (
    <Box component="li" {...props} sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 1
    }}>
      <div style={{ flex: 1, overflow: 'hidden' }}>
        {option.StreetNumber && option.StreetName && (
          <div style={{
            color: 'gray',
            fontSize: '0.8rem'
          }}>
            {option.StreetNumber + " " + option.StreetName}
          </div>
        )}
        {option.City && (
          <div style={{
            fontWeight: 'bold',
            fontSize: '1rem',
            marginBottom: '0.5rem'
          }}>
            {option.City}{option.State ? ", " + option.State : ""}
          </div>
        )}
      </div>
      {selected && (
        <CheckCircle style={{ color: '#1976d2', marginLeft: 'auto' }} />
      )}
    </Box>
  );
}
