
export function useUpload() {
    const uploadFile = ({
        file,
        action,
        headers = {},
        withCredentials = false,
        onProgress,
        onSuccess,
        onError
    }) => {
        const xhr = new XMLHttpRequest();

        xhr.upload.onprogress = function (event) {
            if (event.lengthComputable) {
                const percentage = Math.round((event.loaded * 100) / event.total);
                onProgress(percentage);
            }
        };

        xhr.onload = function () {
            if (xhr.status >= 200 && xhr.status < 300) {
                const response = JSON.parse(xhr.responseText);
                onSuccess(response);
            } else {
                onError('Upload failed: ' + xhr.statusText);
            }
        };

        xhr.onerror = function () {
            onError('Network error');
        };

        xhr.open('POST', action, true);
        xhr.withCredentials = withCredentials;

        Object.keys(headers).forEach(key => {
            xhr.setRequestHeader(key, headers[key]);
        });

        const formData = new FormData();
        formData.append('file', file);

        xhr.send(formData);

        return {
            abort: () => xhr.abort()
        };
    };

    return { uploadFile };
}
