import { useState } from "react";
import ProjectGroupsEditTable from "./ProjectGroupsEditTable";
import ProjectGroupsRows from "./ProjectGroupsRows";
import { Save, Edit } from "@mui/icons-material";

async function setAdd(addingGroup, setAddingGroup, setButtonText, contactId) {
  var fetchLocation =
    process.env.REACT_APP_DATABASE + "api/addGroup?id=" +
    contactId;

  // const searchResults = await data.json();

  if (addingGroup) {
    setButtonText(<Edit sx={{width: 18, height: 18}}/>);
    //const data = await fetch(fetchLocation);
    ////test - console.log(data);
  } else {
    setButtonText(<Save/>);
  }
  setAddingGroup(!addingGroup);
}

export default function ProjectGroupList({
  groupsIn,
  setGroupsIn,
  contactId,
  projectId,
  groupsCombo,
  highestPermission,
  setData,
}) {
  const [addingGroup, setAddingGroup] = useState(false);
  //test - console.log("Group In:");
  //test - console.log(groupsIn);
  const [buttonText, setButtonText] = useState(<Edit sx={{width: 18, height: 18}}/>);
  //   const [groupsState, setGroupsState] = useState(groupsIn)

  if (groupsIn.length > 0) {
    //test - console.log("groupsIn updates");
    //test - console.log(groupsIn);
    return (
      <>
        <div role="list" className="px-0 sm:px-0 lg:px-0">
          <div className="sm:flex sm:items-center">
            {/* <div> */}
            {/* <div> */}
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">
                Your Groups Who See Project Details
              </h1>
            </div>
            {/* <div className="sm:flex-auto">
                    
            
                </div> */}
            {/* </div> */}

            {/* </div> */}
            <div className="mt-4 sm:mt-0 justify-end sm:ml-16 sm:flex-none">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700  focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                onClick={() =>
                  setAdd(addingGroup, setAddingGroup, setButtonText, contactId)
                }
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
        {addingGroup ? (
          <ProjectGroupsEditTable
            contactId={contactId}
            projectId={projectId}
            groupsIn={groupsIn}
            groupsCombo={groupsCombo}
            setGroupsIn={setGroupsIn}
            highestPermission={highestPermission}
            setData={setData}
          />
        ) : (
          <ProjectGroupsRows
            groupsIn={groupsIn}
            contactId={contactId}
            highestPermission={highestPermission}
          />
        )}
      </>
    );
  } else {
    //test - console.log("NOTHING!!!");
    //test - console.log(groupsIn);
    return (
      <div role="list" className="px-0 sm:px-0 lg:px-0">
        <div className="flex justify-between sm:items-center">
          <h1 className="text-xl font-semibold text-gray-900">No Groups</h1>
          <div className="mt-4 sm:mt-0 sm:ml-16  sm:flex-none">
            <button
              type="button"
              className="inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
              onClick={() =>
                setAdd(addingGroup, setAddingGroup, setButtonText, contactId)
              }
            >
              {buttonText}
            </button>
          </div>
        </div>
        {addingGroup ? (
          <ProjectGroupsEditTable
            contactId={contactId}
            projectId={projectId}
            groupsIn={groupsIn}
            groupsCombo={groupsCombo}
            setGroupsIn={setGroupsIn}
            highestPermission={highestPermission}
            setData={setData}
          />
        ) : (
          <ProjectGroupsRows groupsIn={groupsIn} />
        )}
      </div>
    );
  }
}
