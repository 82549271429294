import { useState } from "react";
import SurveyListRows from "./SurveyListRows";
import ProjectSurveyEditTable from "./ProjectSurveyEditTable";
import { Save, Edit } from "@mui/icons-material";

async function setAdd(addingSurvey, setAddingSurvey, setButtonText, projectId) {
  // var fetchLocation =
  //   process.env.REACT_APP_DATABASE + "api/addSurvey?id=" +
  //   projectId;

  // const searchResults = await data.json();

  if (addingSurvey) {
    setButtonText(<Edit sx={{width: 18, height: 18}}/>);
    //const data = await fetch(fetchLocation);
    ////test - console.log(data);
  } else {
    setButtonText(<Save/>);
  }
  setAddingSurvey(!addingSurvey);
}

export default function SurveysList({ surveysIn, projectId, contactId, highestPermission }) {
  const [addingSurvey, setAddingSurvey] = useState(false);
  //test - console.log("Survey In:");
  //test - console.log(surveysIn);
  const [buttonText, setButtonText] = useState(<Edit sx={{width: 18, height: 18}}/>);
  const [surveysState, setSurveysState] = useState(surveysIn);

  if (surveysState && surveysState.length > 0) {
    //test - console.log("surveysState updates");
    //test - console.log(surveysState);
    return (
      <>
        <div role="list" className="px-0 sm:px-0 lg:px-0">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">
                Surveys
              </h1>
            </div>
            <div className="mt-4 sm:mt-0 justify-end sm:ml-16 sm:flex-none">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                onClick={() =>
                  setAdd(addingSurvey, setAddingSurvey, setButtonText, projectId)
                }
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
        {addingSurvey ? (
          <ProjectSurveyEditTable
            projectId={projectId}
            contactId={contactId}
            dataIn={surveysState}
            setDataIn={setSurveysState}
            highestPermission = {highestPermission}
          />
        ) : (
          <SurveyListRows surveysIn={surveysState} />
        )}
      </>
    );
  } else {
    //test - console.log("NOTHING!!!");
    //test - console.log(surveysState);
    return (
      <div role="list" className="px-0 sm:px-0 lg:px-0">
        <div className="flex justify-between sm:items-center">
          <h1 className="text-xl font-semibold text-gray-900">
            No Surveys
          </h1>
          <div className="mt-4 sm:mt-0 sm:ml-16  sm:flex-none">
            <button
              type="button"
              className="inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
              onClick={() =>
                setAdd(addingSurvey, setAddingSurvey, setButtonText, projectId)
              }
            >
              {buttonText}
            </button>
          </div>
        </div>
        {addingSurvey ? (
          <ProjectSurveyEditTable
            contactId={contactId}
            projectId={projectId}
            dataIn={surveysState}
            setDataIn={setSurveysState}
          />
        ) : (
          <SurveyListRows surveysIn={surveysState} />
        )}
      </div>
    );
  }
}
