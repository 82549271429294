
import React, { useState } from 'react';
import { List, arrayMove } from 'react-movable';
import AvailableContactSelectModal from './AvailableContactSelectModal';
import { formatPhoneNumber } from '../Common/FormatPhoneNumber';
import { Tooltip, Button, List as MuiList, ListItem, IconButton, Typography, Box } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import {Delete as TrashIcon} from '@mui/icons-material';
import MissingFieldsSplash from '../Common/MissingFieldsSplash';
import { mobileAndTabletCheck } from '../Search/Deck';

export default function AvailableContactEditTable({
  peopleIn,
  setPeopleIn,
  availId,
  // peopleCombo,
  contactId,
  groupsIn,
  highestPermission
}) {
  //test - console.log("Edit Table before Map array");
  const [noGroups, setNoGroups] = useState(false);
  const removeFromBothArrays = (
    index,
    newPeopleIn,
    groupContactId,
    groupContactArrayIndex
  ) => {
    // contactId is not coming through
    var newDataIn = [...peopleIn];

    // if (newDataIn.length === 0) {
    // if you have just added the contact for some reason it will show up here with no visibility to the peopleIn parameter
    newDataIn = [...newPeopleIn];
    // }
    var myContactId = contactId;

    if (newDataIn.length > 0) {
      deleteAvailableContactRelationship(
        newDataIn[index].id,  //was avail_contact.id but threw error 
        availId,
        newDataIn[index],
        myContactId,
        groupContactId
      );

      newDataIn[index].group_contacts = arrayRemove(
        newDataIn[index].group_contacts,
        groupContactArrayIndex
      );
      if (newDataIn[index].group_contacts.length === 0) {
        newDataIn = arrayRemove(newDataIn, index);
      }
      // setPeopleIn(arrayRemove(newDataIn, index));
      setPeopleIn(newDataIn);
      // return arrayRemove(items, index);
      setItems(mapArray(newDataIn, removeFromBothArrays, highestPermission));
    }
  };

  const [items, setItems] = React.useState(
    mapArray(peopleIn, removeFromBothArrays, highestPermission)
  );
  const [showContactModal, setShowContactModal] = React.useState(false);

  const triggerSetItems = (newPeopleIn) => {
    // this forces the formatted table to refresh
    //test - console.log("I'm setting the formatted array!");
    setItems(mapArray(newPeopleIn, removeFromBothArrays, highestPermission));
  };
  const moveBothArrays = (formattedArray, oldIndex, newIndex) => {
    setItems(arrayMove(formattedArray, oldIndex, newIndex));
    setPeopleIn(arrayMove(peopleIn, oldIndex, newIndex));
    orderContacts(arrayMove(peopleIn, oldIndex, newIndex));
  };

  const addMe = () => {
    setShowContactModal(true);
  };
  //


  return (
    <>
      <Box sx={{ py: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="error"
          startIcon={<FolderIcon />}
          onClick={() => {
            if (groupsIn.length > 0) {
              addMe();
            } else {
              setNoGroups(true);
            }
          }}
        >
          Add a New Contact, or a Group to See an Existing Contact
        </Button>
      </Box>
      <Box sx={{ bgcolor: 'grey.300', border: 1, borderColor: 'grey.100', p: 2 }}>
        Drag Rows to Change Order
      </Box>
      <List
        values={items}
        onChange={({ oldIndex, newIndex }) => moveBothArrays(items, oldIndex, newIndex)}
        renderList={({ children, props }) => <MuiList {...props}>{children}</MuiList>}
        renderItem={({ value, props, index }) => (
          <ListItem {...props} sx={{ '&:hover': { bgcolor: 'grey.50' }, border: 1, borderColor: 'grey.100' }}>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', gap: 2 }}>
              <Box sx={{ gridColumn: 'span 5' }}>{value}</Box>
              <Box sx={{ gridColumn: 'span 1', display: 'flex', justifyContent: 'end', p: 1 }}>
                <Tooltip title="Open">
                  <IconButton
                    onClick={() => {
                      window.open("contact?id=" + items[index].key, mobileAndTabletCheck() ? "_self" : null);
                    }}
                    color="primary"
                  >
                    <FolderIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </ListItem>
        )}
      />
      <MissingFieldsSplash
        open={noGroups}
        setOpen={setNoGroups}
        title="Please Add a Group with Visibility to Relationship"
        message="If available is public, you do not have permission to add publicly visible contacts, if this is private then you do not have permissions to add contacts."
      />
      <AvailableContactSelectModal
        open={showContactModal}
        setOpen={setShowContactModal}
        availId={availId}
        setPeopleIn={setPeopleIn}
        groupsIn={groupsIn}
        triggerSetItems={triggerSetItems}
      />
    </>
  );
}


//  function formatPhoneNumber(phoneNumberString) {
//   var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
//   var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
//   if (match) {
//     return '(' + match[1] + ') ' + match[2] + '-' + match[3]
//   }
//   return null
// }

export function arrayRemove(array, index) {
  // array = array.slice();
  array.splice(index, 1);
  return array;
}

function mapArray(peopleIn, removeFromBothArrays, highestPermission) {
  var myArray = [];
  //test - console.log("Mapping");
  //test - console.log(peopleIn);
  //test - console.log(peopleIn[0]);
  peopleIn.map((person, index) =>
    myArray.push(
      <div key={person.id} className="grid grid-cols-5 gap-4">
        <div className="whitespace-nowrap py-4 pl-4 pr-3 flex items-center text-sm sm:pl-6">
          <div className="flex items-center">
            {/* <div style={imagecropper}>
                 <img style={profilepic} src={person.image.img} alt="" />
            </div> */}
            <div className="h-10 w-10 rounded-full">
              <img
                className="h-10 w-10 flex-shrink-0 rounded-full object-cover"
                src={
                  person.images.length > 0
                    ? person.images.sort((a, b) => a.sortOrder - b.sortOrder)[0]
                      .img
                    : "https://images.cubicsearch.com/UserLogo.png"
                }
                alt=""
              />
            </div>
            <div className="ml-4">
              <div className="font-medium text-gray-900">
                {person.FirstName} {person.LastName}
              </div>
              <div className="text-gray-500">{person.Email}</div>
            </div>
          </div>
        </div>
        <div className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex items-center">
          <div>
            <div className="text-gray-900">{person.Title}</div>
            <div className="text-gray-500">
              {formatPhoneNumber(person.Phone)}
            </div>
          </div>
        </div>
        <div className="whitespace-nowrap px-3 py-4 text-sm flex items-center text-gray-500">
          <div className="h-10 w-20 flex-shrink-4">
            <img
              className="h-10 w-20 object-contain "
              src={
                person.office && person.office.company
                  ? person.office.company.images[0].img
                  : "https://images.cubicsearch.com/CompanyLogo.png"
              }
              alt=""
            />
            {/* <div style={imagecropper}>
            <img style={profilepic} src={person.office.company.images[0].img} alt="" />
            </div> */}
          </div>
        </div>
        <div className="whitespace-nowrap px-3 py-4 text-sm flex items-center text-gray-500">
          {person.avail_contacts[0].RelationshipType}
        </div>
        <div className="whitespace-nowrap px-3 py-4 text-sm flex flex-col items-center text-gray-500">
          {/* {person.group_contacts.map((group_contact) => {
            return (
            <div>{group_contact.group.GroupName}</div>
            
            );
          })} */}
          {person.group_contacts.map((group_contact, groupArrayIndex) => {
            return (
              <div
                // GroupContactId={group_contact.id}
                // GroupId={group_contact.group.id}
                // GroupName={group_contact.group.GroupName}
                className="w-full"
              >
                <div className="flex justify-between w-full">
                  <div className="whitespace-normal flex self-center">
                    {group_contact.group.GroupName}
                  </div>
                  <div className="px-2 py-1">
                    {group_contact.group.id === 1 &&
                      highestPermission !== "Lead Edit" &&
                      highestPermission !== "Edit" && group_contact.group.GroupName != "Me" ? (
                      ""
                    ) : (
                      <Tooltip arrow title={"Delete Visibility of Relationship"}>
                        <button

                          onClick={() => {
                            removeFromBothArrays(
                              index,
                              peopleIn,
                              group_contact.id,
                              groupArrayIndex
                            );
                          }}
                          className="z-50 rounded-md border border-transparent bg-red-600 px-2 h-6 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                        >
                          <TrashIcon
                            className="h-4 w-4 text-white shadow-sm"
                            aria-hidden="true"
                          />

                        </button>
                      </Tooltip>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    )
  );
  return myArray;
}

async function deleteAvailableContactRelationship(
  relationshipID,
  availId,
  person,
  contactId,
  groupContactId
) {
  var qs =
    "id=" +
    relationshipID +
    "&contactId=" +
    contactId +
    "&availId=" +
    availId +
    "&groupContactId=" +
    groupContactId
  // +
  // "&person=" +
  // btoa(encodeURIComponent(JSON.stringify(person)));

  // encodeURI(window.btoa(JSON.stringify(person)));
  //test - console.log(
  //   process.env.REACT_APP_DATABASE + "api/deleteContactRelationship?" +
  //   qs
  // );
  var resultInfo = await fetch(
    process.env.REACT_APP_DATABASE + "api/deleteContactRelationship?" +
    qs
  );
}

async function orderContacts(contacts) {
  //test - console.log("Update the order in the database");
  //test - console.log(contacts);

  contacts.map(async (contact, index) => {
    //test - console.log(contact.id);
    var qs = "id=" + contact.avail_contacts[0].id + "&sortOrder=" + index;
    var resultInfo = await fetch(
      process.env.REACT_APP_DATABASE + "api/sortAvailableContacts?" +
      qs
    );
  });
}
