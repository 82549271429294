import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    List,
    ListItem,
    Menu,
    MenuItem,

    ListItemText,
    Avatar,
    Button,
    IconButton,
    Tooltip,
    Box,

    Typography,
    Divider,
    AvatarGroup
} from '@mui/material';

import RemoveIcon from '@mui/icons-material/Remove';

import GroupAutocomplete from './MailLists/GroupAutocomplete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';


// Mock function to fetch groups associated with a mailList
// In real application, this should make an actual API call
const fetchGroupsForCampaign = async (campaignId, contactId) => {
    // Replace this with real API call
    var qs = process.env.REACT_APP_DATABASE + "api/getCampaignGroups"
    var groups = await axios.get(qs, {
        params: {
            contactId: contactId,
            campaignId: campaignId
        }
    })


    if (groups?.data) {

        var groupsFormatted = groups.data.map((group) => {
            var contacts = group.group_contacts.map((group_contact) => {
                return (group_contact.contact)
            })
            // alert(JSON.stringify(group))

            group.campaign_groups.map((campaignGroup) => {

                if (campaignGroup.campaignId === campaignId) {

                    group.PermissionLevel = campaignGroup.groupPermission

                }
            })
            group.contacts = contacts

            return group
        })
        // alert(JSON.stringify(groupsFormatted))
        return groupsFormatted
    }
    else {
        return [

        ];
    }

    return [
        // {
        //     id: 'group1',
        //     GroupName: 'Group 1',
        //     PermissionLevel: 'Edit',
        //     contacts: [
        //         { name: 'John Doe', avatar: '/path/to/avatar1.jpg' },
        //         { name: 'Jane Doe', avatar: '/path/to/avatar2.jpg' },
        //     ],
        // },
        // Add more groups as needed
    ];
};

const PermissionLevels = {
    'Lead Edit': 3,
    'Edit': 2,
    'View': 1
};

const CampaignGroupsDialog = ({ open, onClose, campaignId, campaignName, contactId }) => {
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null)
    const [showPermission, setShowPermission] = useState(false)
    const [showRemove, setShowRemove] = useState(false)
    const [maxPermission, setMaxPermission] = useState('View')
    const [anchorEl, setAnchorEl] = useState(null);
    const [activeGroupId, setActiveGroupId] = useState(null);

    const openMenu = Boolean(anchorEl);
    const navigate = useNavigate()
    const isMenuOpen = (groupId) => activeGroupId === groupId;

    // Handle opening a menu for a specific group
    const handleClick = (groupId) => (event) => {
        setActiveGroupId(groupId);
        setAnchorEl(event.currentTarget);
    };

    // Handle closing the currently open menu
    const handleClose = () => {
        setActiveGroupId(null); // Reset active group ID
        setAnchorEl(null);
    };

    const handleChangePermission = (newPermission, groupIn) => {

        var updatedGroups

        
        console.log(newPermission); // Here you can handle the permission change
        updatedGroups = groups.map(group => {
            if (group.id === groupIn.id) {
                return { ...group, PermissionLevel: newPermission };
            }
            return group;
        });
        var qs = process.env.REACT_APP_DATABASE + "api/updateCampaignPermission"
        var groupsResponse = axios.post(qs, {

            contactId: contactId,
            campaignId: campaignId,
            groupPermission: newPermission,
            groupId: groupIn.id,



        })



        setGroups(updatedGroups)
        handleClose();
    };

    useEffect(() => {

        const loadData = async () => {

            const groups = await fetchGroupsForCampaign(campaignId, contactId);
            alert(JSON.stringify(groups))
            setGroups(groups);

        };

        if (open) {
            loadData();
        }
    }, [open, campaignId]);

    useEffect(() => {


        let highestPermissionRank = 1

        for (let i = 0; i < groups.length; i++) {
            const currentPermissionRank = PermissionLevels[groups[i].PermissionLevel];

            if (currentPermissionRank > highestPermissionRank) {
                highestPermissionRank = currentPermissionRank;
                setMaxPermission(groups[i].PermissionLevel);

            }
        }
    }, [groups]);

    const handleAddGroup = (group) => {
        // Logic to add a new group

        setSelectedGroup(group)
        setShowPermission(true)



    };

    const handleRemoveGroup = (group) => {
        setSelectedGroup(group)
        setShowRemove(true)

        // Logic to remove a group
    };

    const handleCreateNewGroup = () => {
        // Logic to create a new group
    };


    return (
        <>
            <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
                <DialogTitle>Group access to {campaignName} campaign </DialogTitle>
                <DialogContent>
                    <List>
                        {groups.map((group) =>
                            group?.id && (


                                <ListItem key={group.id} secondaryAction={
                                    <IconButton edge="end" aria-label="delete" disabled={group.contactId === contactId && group.GroupName === "Me" ||
                                        !maxPermission.includes("Edit") && group.createdBy !== contactId} onClick={() => handleRemoveGroup(group)}>
                                        <RemoveIcon />
                                    </IconButton>
                                }>

                                    <ListItemText primary={group.GroupName}
                                        onClick={()=>{navigate("/group?id="+group?.id)}}

                                        secondary={
                                            <Box display="flex" alignItems="center" >

                                                <span>{group.PermissionLevel + " Permissions"}</span>
                                                <IconButton aria-label="more" onClick={handleClick(group.id)} disabled={maxPermission.includes('View') || group.PermissionLevel.includes("Lead")}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    id={`permission-menu-${group.id}`}
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={isMenuOpen(group.id)} // Check if this group's menu is open
                                                    onClose={handleClose}
                                                >
                                                    <Typography component="div" style={{ padding: '10px 16px' }}>
                                                        Change Permission
                                                    </Typography>
                                                    <Divider />
                                                    <MenuItem onClick={() => handleChangePermission('View', group)}>View</MenuItem>
                                                    <MenuItem onClick={() => handleChangePermission('Edit', group)}>Edit</MenuItem>

                                                </Menu>
                                            </Box>
                                        }
                                    />
                                    <AvatarGroup max={4}>
                                        {group.contacts.map((contact, index) => (
                                            <Tooltip key={index} title={contact.FirstName + " " + contact.LastName + " - " + contact.Email}>
                                                <Avatar alt={contact.FirstName} src={contact?.images?.[0]?.img || 'https://cubicsearch.com/noImage.jpg'} />
                                            </Tooltip>
                                        ))}
                                    </AvatarGroup>

                                </ListItem>
                            ))
                        }
                    </List>
                    {maxPermission.includes("Edit") ? <GroupAutocomplete contactId={contactId} addToMailList={handleAddGroup} /> : <></>}
                </DialogContent>
            </Dialog>
            <PermissionsDialog open={showPermission} setOpen={setShowPermission} setGroups={setGroups}
                groups={groups} group={selectedGroup} campaignId={campaignId} contactId={contactId} maxPermission={maxPermission} />
            <RemoveDialog open={showRemove} setOpen={setShowRemove} setGroups={setGroups} groups={groups}
                group={selectedGroup} campaignId={campaignId} contactId={contactId} maxPermission={maxPermission} />
        </>
    );
};
export default CampaignGroupsDialog;

function PermissionsDialog({ open, setOpen, setGroups, groups, group, campaignId, contactId, maxPermission }) {


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handlePermissionChoice = (choice) => {


        console.log(`User chose: ${choice}`);
        // Process the choice here (e.g., update state or call a function)
        group.PermissionLevel = choice
        groups.push(group)


        var qs = process.env.REACT_APP_DATABASE + "api/addCampaignGroup"
        var groupsResponse = axios.post(qs, {

            contactId: contactId,
            campaignId: campaignId,
            groupPermission: choice,
            groupId: group.id


        })


        setGroups(JSON.parse(JSON.stringify(groups)))
        handleClose(); // Close the dialog after making a choice
    };

    return (
        <div>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Choose Permissions</DialogTitle>
                <DialogContent>
                    {maxPermission.includes("Edit") ? <p>Select the type of group permissions for this campaign:</p> : <p>This group will have View Privalegs with this campaign</p>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handlePermissionChoice('View')}>View</Button>
                    {maxPermission.includes("Edit") ? <Button onClick={() => handlePermissionChoice('Edit')} color="primary">
                        Edit
                    </Button> : <></>}
                </DialogActions>
            </Dialog>
        </div>
    );
}

function RemoveDialog({ open, setOpen, setGroups, groups, group, campaignId, contactId }) {


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRemove = () => {

        // Process the choice here (e.g., update state or call a function)

        groups = groups.filter(groupMap => groupMap.id !== group.id);



        var qs = process.env.REACT_APP_DATABASE + "api/removeCampaignGroup"
        var groupsResponse = axios.post(qs, {

            contactId: contactId,
            campaignId: campaignId,
            groupId: group.id


        }).catch((error) => { alert(error) })

        setGroups(JSON.parse(JSON.stringify(groups)))
        handleClose(); // Close the dialog after making a choice
    };

    return (
        <div>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Remove Group</DialogTitle>
                <DialogContent>
                    <p>This group will no longer have access to this campaign:</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onclose()}>Cancel</Button>
                    <Button onClick={() => handleRemove()} color="primary">
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
