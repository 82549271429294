import React from "react";
import { useState } from "react";

import "keen-slider/keen-slider.min.css";
import Header from "../Common/Header";
import MainContactClientsInfo from "./MainContactClientsInfo";






export default function ContactClients({
  ContactClientsInformation,
  peopleCombo,
  user,
  groups,
  groupsCombo,
}) {
  const [contactclientsStateData, setContactClientsStateData] = useState(ContactClientsInformation);


  var highestPermission = "Lead Edit";

  return (
    <div>
      <nav className="sticky z-50 top-0">
        <Header
          key="1"
          placeholderLocation={"My Clients"}
          icon="contactclients"
          user={user}
          highestPermission={highestPermission}
        />
      </nav>



      <MainContactClientsInfo
        dataIn={contactclientsStateData}
        peopleCombo={peopleCombo}
        setDataIn={setContactClientsStateData}
        contactId={user.contact.id}
        groups={groups}
        groupsCombo={groupsCombo}
        highestPermission={highestPermission}
      />
    </div>
  );
}
