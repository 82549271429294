/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";




import { Chat, CopyAll, Share } from "@mui/icons-material";

import { CardMedia, Dialog, DialogActions, DialogContent, Button, Card } from "@mui/material";
import { mobileAndTabletCheck } from "../Search/Deck";

export default function ShareSurveySplash({ open, setOpen, website, id }) {

  const [returnHTML, setReturnHTML] = useState(<></>)
  const cancelButtonRef = useRef(null);
  var finalLink = null

  if (id) {
    finalLink = website + id + ".html"
  }
  else {
    finalLink = website
  }
  finalLink = finalLink?.replace(/\s+/g, '');
  finalLink = finalLink?.replace(/%20/g, '')


    return (

      <Dialog open={open} onClose={() => { setOpen(false) }} title="Share">
        <DialogContent sx={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
          <Share className="w-12 h-12" />
          <div>
            {mobileAndTabletCheck() ? "To share, you can copy the link or use sms messaging." : "Copy share link to clipboard."}
          </div>
        </DialogContent>
        <DialogActions>

          {mobileAndTabletCheck() ? <a href={"sms:&body=" + finalLink}>
            <Button variant="contained"
              onClick={()=>{setOpen(false)}}>  Message
              <Chat
              style={{ marginLeft: 5 }} className="h-6 w-6 " />
            </Button>

          </a> : <></>}
          <Button
            variant="outlined"
            className="w-40 border-2 py-2  rounded-md"
            onClick={() => {
              navigator.clipboard.writeText(finalLink)
              setOpen(false);
            }}
          >
            Copy
            <CopyAll style={{ marginLeft: 5 }} className="h-6 w-6" />
          </Button>
        </DialogActions>

      </Dialog>
    )


  return returnHTML





}
