export default function JournalTable({ journals }) {
  
  return (
    <div className="mt-8 flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Field
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    New Value
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Old Value
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3.5 text-right text-sm font-semibold text-gray-900"
                  >
                    Date and Time
                  </th>
                </tr>
              </thead>
              <tbody
                className="divide-y divide-gray-200 bg-white"
                
              >
                {journals?.map((journal) => {  
                return (
                  
                  <tr key={journal?.id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                      <div className="flex items-center">
                        <div className="h-10 w-10 flex-shrink-0">
                          <img
                            className="h-10 w-10 rounded-full"
                            src={
                              journal?.contact &&
                              journal?.contact?.images &&
                              journal?.contact?.images.length > 0
                                ? journal?.contact?.images[0].img
                                : "https://images.cubicsearch.com/UserLogo.png"
                            }
                            alt=""
                          />
                        </div>
                        <div className="ml-4">
                          <div className="font-medium text-gray-900">
                            {journal?.contact?.FirstName +
                              " " +
                              journal?.contact?.LastName}{" "}
                          </div>
                          <div className="text-gray-500">
                            {journal?.contact?.Email}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap text-left px-3 py-4 text-sm text-gray-500">
                      <div className="text-gray-900">{journal?.Field}</div>
                      {/* <div className="text-gray-500">{person.department}</div> */}
                    </td>
                    <td className="whitespace-nowrap text-left px-3 py-4 text-sm text-gray-500">
                      <div className="text-gray-900">{journal?.NewValue}</div>
                      {/* <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                      Active
                    </span> */}
                    </td>
                    <td className="whitespace-nowrap text-left px-3 py-4 text-sm text-gray-500">
                      {" "}
                      <div className="text-gray-900">{journal?.OldValue}</div>
                    </td>
                    <td className="relative whitespace-nowrap text-left py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <div className="text-gray-900">
                        {new Date(journal?.createdAt).toLocaleString()}
                      </div>
                    </td>
                  </tr>
                )})}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
