import React from 'react';
import { useState } from 'react';
import { Box, Typography, List, ListItem, Link, ListItemText, Divider, Paper } from '@mui/material';

function truncateString(str) {
    if (str) {
        if (str.length > 10) {
            return str.substring(0, 10) + "...";
        } else {
            return str;
        }
    }
    else {
        return 'Campaig...'
    }
}

const FrequencyDetails = ({ frequency, emailDetails, setIndexIn, indexIn }) => {
    // const [selected, setSelected] = useState(0)
    const handleListItemClick = (event, indexLocal) => {
        // setSelected(index); // Set the selected item index
        setIndexIn(indexLocal)
    };
    
    return (
        <Paper elevation={3} sx={{ my: 2, p: 2 }}>
            <Typography variant="h6" gutterBottom>
                Outgoing Messages
            </Typography>
        

            {frequency
                // && frequency.frequencyType === 'specific'
                && (
                <>
   
                    <List>
                        {frequency.specificDates && frequency.specificDates.length > 0 ? (
                            frequency.specificDates.map((date, index) => (
                                <ListItem
                                    onClick={(event) => handleListItemClick(event, index)}
                                    disablePadding
                                    sx={{
                                        marginBottom: '8px', // Space below each ListItem
                                        '&:hover, &.Mui-selected, &.Mui-selected:hover': {
                                            backgroundColor: 'rgba(0, 0, 200, 0.04)',
                                            borderRadius: '10px', // Ensures border radius on hover and selected
                                        },
                                        ...(indexIn === index && {
                                            backgroundColor: 'rgba(0, 0, 200, 0.08)', // Additional styling for selected item
                                            borderRadius: '10px'
                                        }),
                                    }}
                                    key={index} disableGutters>
                                    <ListItemText sx={{ paddingLeft: 1, }} primary={<Link> {(index+1) +'. '+ (emailDetails.campaignMailers?.[index]?.subject || 'Untitled Mailer')}</Link>}
                                        secondary={ 'Delivery Date: '+ new Date(date).toLocaleDateString()} />
                                </ListItem>
                            ))
                        ) : (
                            <ListItem>
                                <ListItemText primary="No specific dates provided" />
                            </ListItem>
                        )}
                    </List>
                </>
            )}

             {/* {frequency && frequency.frequencyType !== 'specific' && (
                <>
                    <Typography variant="subtitle1" gutterBottom>
                        Frequency: {frequency.frequencyType?.charAt(0).toUpperCase() + frequency.frequencyType.slice(1)}
                    </Typography>
                    <List>
                        <ListItem disableGutters>
                            <ListItemText primary={`Start Date: ${new Date(frequency.startDate).toLocaleDateString()}`} />
                        </ListItem>
                        <ListItem disableGutters>
                            <ListItemText primary={`End Date: ${new Date(frequency.endDate).toLocaleDateString()}`} />
                        </ListItem>
                        <ListItem disableGutters>
                            <ListItemText primary={`Every: ${frequency.intervalNumber} ${frequency.repeatInterval}(s)`} />
                        </ListItem>
                    </List>
                    <Typography variant="subtitle1" gutterBottom>
                        Specific Dates
                    </Typography>
                    <List>
                        {frequency.specificDates && frequency.specificDates.length > 0 ? (
                            frequency.specificDates.map((date, index) => (
                                <ListItem
                                    onClick={(event) => handleListItemClick(event, index)}
                                    disablePadding
                                    sx={{
                                        marginBottom: '8px', // Space below each ListItem
                                        '&:hover, &.Mui-selected, &.Mui-selected:hover': {
                                            backgroundColor: 'rgba(0, 0, 200, 0.04)',
                                            borderRadius: '10px', // Ensures border radius on hover and selected
                                        },
                                        ...(indexIn === index && {
                                            backgroundColor: 'rgba(0, 0, 200, 0.08)', // Additional styling for selected item
                                            borderRadius: '10px'
                                        }),
                                    }}
                                    key={index} disableGutters>
                                    <ListItemText sx={{ paddingLeft: 1 }} primary={(index + 1) + '. ' + (emailDetails.campaignMailers?.[index]?.subject || 'Untitled Mailer')}
                                        secondary={'Delivery Date: ' + new Date(date).toLocaleDateString()} />
                                </ListItem>
                            ))
                        ) : (
                            <ListItem>
                                <ListItemText primary="No specific dates provided" />
                            </ListItem>
                        )}
                    </List>
                </>
            )}  */}
        </Paper>
    );
};

export default FrequencyDetails;
