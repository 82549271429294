
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { GroupCenter } from "../Search/InfoCard";
import { useEffect, useState } from "react";
import axios from "axios";
import { mobileAndTabletCheck } from "../Search/Deck";

export default function MyGroupsRows({ groupsIn, contactId, filteredDataIn , setFilteredDataIn  }) {
  const [messageCount, setMessageCount] = useState(null)



  var groupsArray = groupsIn.map((eachGroup) => {
    return eachGroup.id
  })

  var queryString = process.env.REACT_APP_DATABASE + "api/getMessagesCountsGroupList"


  useEffect(() => {
    axios.post(queryString, { groupsArray: groupsArray }).then((results) => {
      setMessageCount(results.data.messages)
    }).catch((error) => {
      console.log(error)
    })
  }, [])

  if ((messageCount) && groupsIn.length > 0) {



    // we sort right here
    var messageArray = []
    var blankArray = []
    groupsIn.map((a) => {
      var aHasMessage = messageCount.findIndex((element) => {
        // 
        return element.groupId === a.id
      })

      if (aHasMessage >= 0) {
        a.recentDate = messageCount[aHasMessage].recentDate
        messageArray.push(a)
      }
      else {
        a.recentDate = a.updatedAt
        messageArray.push(a)
      }


    })
    messageArray.sort((a, b) => {





      return new Date(b.recentDate) - new Date(a.recentDate)




    })

    groupsIn = messageArray
    return (
      tableRows(groupsIn, contactId)
    );
  } else {
    return <></>;
  }
}

function tableRows(groupsIn, contactId, buildingId) {
  var array = []







  // array.sort((a, b) => { return ('' + a.GroupName).localeCompare(b.GroupName) }) //a.GroupName.localCompare(b.GroupName))

  return (
    <>
      <Grid2 sx={{margin :!mobileAndTabletCheck()? 3:null }} container >

        {groupsIn.map((group) => {
          if (group.id != 1) {
            return (<>
              <Grid2 sx={{ width: mobileAndTabletCheck() ? '100%' : null, marginBottom: mobileAndTabletCheck() ? 2 : 5 }}>
                {GroupCenter(group, contactId, buildingId,)}
              </Grid2>
            </>)
          }
        })}

      </Grid2>
    </>
  );
}


