/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { LicenseInfo } from "@mui/x-license-pro";

import { Box } from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
var nfObjectPercent = new Intl.NumberFormat("en-US", { style: "percent" });
LicenseInfo.setLicenseKey(
  process.env.REACT_APP_MUI
);

export default function PropertyGrid({ open, setOpen, dataIn }) {
  const cancelButtonRef = useRef(null);
  const gridRef = useRef(null);
  const [selectedRows, setSelectedRows] = useState(null);
  var availSF = 0;
  var totalSF = 0;
  const rows = [];
 
  if (dataIn && dataIn.length > 0) {




    dataIn.map((data, i) => {
      var editable = false;

      data.groups? 
      data.groups.map((group) => {
        if (
          group.building_group.groupPermission === "Edit" ||
          group.building_group.groupPermission === "Edit"
        ) {
          editable = true;
        }
      }): editable = false
   
      var totalAvail = 0
      data.avails ? data.avails.map((available) => {
        totalAvail =
          totalAvail + (available.Status !== "Lease Comp" ? available.SizeMax : 0);
      }) : totalAvail = 0
      var index = rows.findIndex((row) => {
        return data.id === row.propertyId;
      });

      if (index >= 0) {
        //alert(index);
      }
      if (index < 0) {
        rows.push({
          id: i + 1,
          propertyId: data.id,

          YearBuilt: data.YearBuilt,
          // surveyUpdatedAt: new Date(data.survey.updatedAt),
          StreetNumber: data.StreetNumber,
          StreetName: data.StreetName,
          PropertyType: data.PropertyType,
          CampusName: data.CampusName,
          PropertyName: data.PropertyName,
          City: data.City,
          Size: data.Size,
          Editable: editable,
          totalAvail:totalAvail,
          // permission: data.groupPermission,
        });
        
        availSF = availSF + totalAvail;
        totalSF = totalSF + data.Size;
      }
    });
  }

  const columns = [
    // { field: 'id', headerName: 'ID', width: 90 },

    {
      field: "StreetNumber",
      headerName: "Street #",
      width: 100,
      editable: (params) => {
        return params.row.editable;
      },
      resizable: true,
      renderCell: (params) => {
        return (
          <a
            href={"property?id=" + params.row.propertyId}
            target="_blank"
            className="text-blue cursor-pointer underline"
          >
            {params.row.StreetNumber}
          </a>
        );
      },
    },
    {
      field: "StreetName",
      headerName: "Street",
      width: 300,
      editable: (params) => {
        return params.row.editable;
      },
      renderCell: (params) => {
        return (
          <a
            href={"property?id=" + params.row.propertyId}
            target="_blank"
            className="text-blue cursor-pointer underline"
          >
            {params.row.StreetName}
          </a>
        );
      },
    },
    {
      field: "PropertyType",
      headerName: "Property Type",
      width: 200,
      editable: (params) => {
        return params.row.editable;
      },
      type: "singleSelect",
      valueOptions: [
        "Life Science",
        "Life Science or Industrial",
        "Life Science or Office",
        "Life Science, R&D, or Office",
        "Life Science, R&D, or Industrial",
        "GMP",
        "R&D",
        "R&D or Office",
        "Office",
        "Industrial",
        "Retail",
        "Other",
      ],
    },
    {
      field: "YearBuilt",
      headerName: "Year Built",
      width: 150,
      editable: (params) => {
        return params.row.editable;
      },
      resizable: true,
    },
    {
      field: "Size",
      headerName: "Size",
      // type: 'number',
      width: 100,
      editable: (params) => {
        return params.row.editable;
      },
      type: "number",
      // renderCell: (params) => {
      //   return (

      //      params.row.Size.toString()
      //      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")

      //   );
      // },
    },
    {
      field: "totalAvail",
      headerName: "Avail SF",
      // type: 'number',
      width: 100,
      // editable: (params)=>{return params.row.editable},
      type: "number",
    },
    {
      field: "CampusName",
      headerName: "Campus",
      // type: 'number',
      width: 200,
      editable: (params) => {
        return params.row.editable;
      },
    },
    {
      field: "PropertyName",
      headerName: "Building Name",
      // type: 'number',
      width: 200,
      editable: (params) => {
        return params.row.editable;
      },
    },
    {
      field: "City",
      headerName: "City",
      // type: 'number',
      width: 100,
      editable: (params) => {
        return params.row.editable;
      },
    },
  
  ];

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-102"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-100 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8  sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 ">
                    {/* <UserIcon className="h-6 w-6 text-red-600" aria-hidden="true" /> */}
                    <img
                      src="https://images.cubicsearch.com/small%20survey.png"
                      className="h-12 w-12 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Building List
                    </Dialog.Title>
                    <div className="mt-2 ">
                      <Box sx={{ height: 400, width: "100%" }}>
                        <DataGridPro
                          slots={{ toolbar: GridToolbar }}
                          initialState={{
                            sorting: {
                              sortModel: [
                                { field: "City", sort: "asc" },
                                { field: "StreetName", sort: "asc" },
                                { field: "StreetNumber", sort: "asc" },
                              ],
                            },
                          }}
                          ref={gridRef}
                          rows={rows}
                          columns={columns}
                          pageSize={10}
                          rowsPerPageOptions={[10]}
                          // checkboxSelection
                          disableSelectionOnClick
                          experimentalFeatures={{ newEditingApi: true }}
                          isRowSelectable={(params) => {
                            return true;
                            // params.row.permission === "Edit" ||
                            // params.row.permission === "Lead Edit"
                          }}
                          onRowSelectionModelChange={(ids) => {
                            const selectedIDs = new Set(ids);
                            const selectedRowData = rows.filter((row) => {
                              return selectedIDs.has(row.id);
                            });
                            setSelectedRows(selectedRowData);
                          }}
                        />
                      </Box>
                    </div>
                  </div>
                </div>
                {/* <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"> */}
                <div>
                  Total Available SF:{" "}
                  {availSF.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </div>
                <div>
                  Total Size SF:{" "}
                  {totalSF.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </div>{" "}
                <div>
                  Availability:
        
                  {nfObjectPercent.format(availSF/totalSF)}
                </div>
                <div className="flex w-full justify-center py-2">
                  <div className="px-2 w-quarter">
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      OK
                    </button>
                  </div>
                  {/* <div className="px-2 w-quarter"> */}
                  {/* <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-red-300 shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                      onClick={() => {
                        deleteSurveys(
                          selectedRows,
                          contactId,
                          setDataIn,
                          setSelectedRows
                        );
                      }}
                      ref={cancelButtonRef}
                    >
                      Delete
                    </button> */}
                  {/* </div> */}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function deleteSurveys(myrows, contactId, setDataIn, setSelectedRows) {
  var surveyIds = [];
  var surveyGroupJsons = [];
  //test - console.log("deleting");
  ;
  myrows.map((row) => {
    surveyIds.push(row.surveyId);
    surveyGroupJsons.push({ surveyId: row.surveyId, groupId: row.groupId });

    //test - console.log(surveyIds);
    //test - console.log(surveyGroupJsons);
  });

  var qs = process.env.REACT_APP_DATABASE;

  var stringSurveyIds = encodeURI(JSON.stringify(surveyIds));
  var stringSurveyGroupJsons = encodeURI(JSON.stringify(surveyGroupJsons));
  qs =
    qs +
    "api/markDeleteSurveyList?surveyIds=" +
    stringSurveyIds +
    "&&surveyGroupIds=" +
    stringSurveyGroupJsons;

  //test - console.log(qs);
  fetch(qs)
    .then((response) => {
     
      //test - console.log(response);
      var qs = process.env.REACT_APP_DATABASE;

      qs = qs + "api/getClientsProjectsSurveys?contactId=" + contactId;

      //test - console.log(qs);
      fetch(qs).then((journalsRaw) => {
        journalsRaw.json().then((dataInJSON) => {
          // 

          setSelectedRows([]);
          setDataIn(dataInJSON);
        });
      });
    })
    .catch((err) => {
      alert(err.message);
    });
}
