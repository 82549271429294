import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Check as CheckIcon } from '@mui/icons-material';

export default function GroupCombo({ selectedGroup, setSelectedGroup, groupsIn }) {
  // Initializing groups data
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    if (groupsIn?.length > 0) {
      const initialGroups = groupsIn.map((group, index) => ({
        id: index, // Assuming groupsIn already contains an id, you might not need to use index
        name: group?.GroupName,
        groupId: group?.id
      }));

      const publicPresent = initialGroups.find(group => group.groupId === 1);
      if (!publicPresent) {
        initialGroups.unshift({ id: 0, name: "Anyone with access", groupId: 1 });
      }

      setGroups(initialGroups);
    }
  }, [groupsIn]);

  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  return (
    <Autocomplete
      id="group-combo-box"
      options={groups}
      getOptionLabel={(option) => option?.name || ''}
      value={selectedGroup}
      onChange={(event, newValue) => {
        setSelectedGroup(newValue);
      }}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Group"
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {option.name}
          {selected ? <CheckIcon fontSize="small" style={{ marginRight: 8 }} /> : null}
        </li>
      )}
    />
  );
}
