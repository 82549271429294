
import AvailableContactLookupComboBox from "./AvailableContactLookupComboBox";
import ContactNew from "../Common/ContactNew";
import RoleCombo from "../Building/RoleCombo";
import GroupCombo from "../Common/GroupCombo";
import React, { Fragment, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import NewContactDialog from "../Groups/NewContactDialog";
 

export default function AvailableContactSelectModal({
  open,
  setOpen,
  // peopleCombo,
  peopleIn,
  setPeopleIn,
  availId,
  triggerSetItems,
  contactId,
  groupsIn,
}) {
  //test - console.log("LOOP IN AVAIALABLE");
  const [buttonText, setButtonText] = useState("Select Contact");

  const cancelButtonRef = useRef(null);
  const [selectedPerson, setSelectedPerson] = useState();
  const [addPerson, setAddPerson] = useState(false);
  const [nameState, setName] = useState()
  const [selectedRole, setSelectedRole] = useState({ id: 1, name: "Landlord Broker" });
  const [selectedGroup, setSelectedGroup] = useState(
    groupsIn?.length > 0 ? { groupId: groupsIn?.[0]?.id, name: groupsIn?.[0]?.GroupName } : null
  );
  const [openSplash, setOpenSplash] = useState(false);
  const [newText, setNewText] = useState(null)

  return (<>
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
      maxWidth={'md'}
      fullWidth={true}

    >
      <DialogTitle id="form-dialog-title">Select or Add Contact</DialogTitle>
      <DialogContent>
        {/* Content goes here */}
      
        <div style={{ marginTop: 10 }}>
          <AvailableContactLookupComboBox
            // peopleCombo={peopleCombo}
            selectedPerson={selectedPerson}
            setName={setName}
            setSelectedPerson={setSelectedPerson}
            setButtonText={setButtonText}
            setAddPerson={setAddPerson}
            open={open}
            contactId={contactId}
            setNewText={setNewText}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <RoleCombo
            selectedRole={selectedRole}
            setSelectedRole={setSelectedRole}
          />
        </div>
        <div style={{marginTop: 10}}>
          <GroupCombo
            groupsIn={groupsIn}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}

          />
        </div>

    {addPerson ? (
      <div>
        <div className="py-2 text-center">Create A New Contact</div>
        <ContactNew NameIn={nameState} />
      </div>
    ) : (
      ""
    )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Cancel
        </Button>
        <Button
         variant="contained"
          onClick={() => {
     
           
                addContact(
                  selectedPerson.id,
                  availId,
                  setPeopleIn,
                  peopleIn,
                  triggerSetItems,
                  selectedRole.name,
                  contactId,
                  selectedGroup.groupId
                );
                setOpen(false);
            
          }}
        >
          {buttonText}
        </Button>
      </DialogActions>
      <NewContactDialog NameIn={newText} open={addPerson} onClose={() => { setAddPerson(false) }} createContact={async (person) => {


        await newContact(
          availId,
          person,
          setPeopleIn,
          triggerSetItems,
          selectedRole.name,
          contactId,
          selectedGroup.groupId
        );
        setOpen(false);

        setAddPerson(false); // Close the dialog
      }} />
    </Dialog>

  
  </>
  );
}

async function newContact(
  availId,
  person,
  setPeopleIn,
  triggerSetItems,
  role,
  userId,
  selectedGroup
) {
  var fetchLocation =
    process.env.REACT_APP_DATABASE +
    "api/addContact?availId=" +
    availId +
    "&groupId=" +
    selectedGroup +
    "&role=" +
    role +
    "&" +
    Object.keys(person)
      .map((key) => key + "=" + person[key])
      .join("&");
  var result = await fetch(fetchLocation);
  fetchLocation = process.env.REACT_APP_DATABASE + "api/getAvailableContactsOnly?id=";
  const searchResults = await fetch(
    fetchLocation + availId + "&contactId=" + userId
  );
  const data = await searchResults.json();
  var myJson = data.contacts; // search results come in an array, and the element is also an array
  var sort = orderAvailContacts(
    myJson.sort((a, b) => a.avail_contacts[0].sortOrder - b.avail_contacts[0].sortOrder)
  );
  setPeopleIn(
    myJson.sort((a, b) => a.avail_contacts[0].sortOrder - b.avail_contacts[0].sortOrder)
  );
  triggerSetItems(
    myJson.sort((a, b) => a.avail_contacts[0].sortOrder - b.avail_contacts[0].sortOrder)
  );
}
async function addContact(
  contactId,
  availId,
  setPeopleIn,
  peopleIn,
  triggerSetItems,
  role,
  userId,
  selectedGroup
) {
  var fetchLocation =
    process.env.REACT_APP_DATABASE +
    "api/addAvailableContact?availableId=" +
    availId +
    "&&contactId=" +
    contactId +
    "&role=" +
    role +
    "&groupId=" +
    selectedGroup;
  //test - console.log(encodeURI(fetchLocation));
  const resultInfo = await fetch(encodeURI(fetchLocation));
  fetchLocation = process.env.REACT_APP_DATABASE + "api/getAvailableContactsOnly?id=";
  const searchResults = await fetch(
    fetchLocation + availId + "&contactId=" + userId
  );
  const data = await searchResults.json();
 

  var myJson = data.contacts; // search results come in an array, and the element is also an array
  var sort = orderAvailContacts(
    myJson.sort((a, b) => a.avail_contacts[0].sortOrder - b.avail_contacts[0].sortOrder)
  );
  setPeopleIn(
    myJson.sort((a, b) => a.avail_contacts[0].sortOrder - b.avail_contacts[0].sortOrder)
  );
  triggerSetItems(
    myJson.sort((a, b) => a.avail_contacts[0].sortOrder - b.avail_contacts[0].sortOrder)
  );
  //window.location.href = window.location.href;  There is a bug that right after you add a contact there is something funky about it if you try to delete it right away and you add more than one contact.
}

async function orderAvailContacts(contacts) {
  //test - console.log("Update the order in the database");
  ////test - console.log(avails)
  var mynum = 0;

  contacts.map(async (contact, index) => {
    mynum = index + 1;
    //test - console.log(contact.id);
    var qs = "id=" + contact.avail_contacts[0].id + "&sortOrder=" + mynum;
    var resultInfo = await fetch(
      process.env.REACT_APP_DATABASE + "api/sortAvailableContacts?" + qs
    );
  });
}
