import React from "react";
import { Typography, Button, Box, Link } from "@mui/material";
import { formatPhoneNumber } from "../Common/FormatPhoneNumber";
import { mobileAndTabletCheck } from "../Search/Deck";
import { CustomLink } from "./ContactView"

export default function ContactCompanyList({
  officeIn,
  showCompanyModal,
  setShowCompanyModal,
  highestPermission,
  guest
}) {
  function buttonClick() {
    if (guest === true || guest === 'true') {
      alert("Please login or sign up to make edits.");
    } else {
      setShowCompanyModal(true);
    }
  }

  return (
    <Box sx={{ textAlign: 'start', my: 2, marginLeft: 5 }}>
      {/* <Typography variant="h6" gutterBottom>Company Information</Typography> */}
      {officeIn && officeIn.company ? (
        <>
          <div style={{ display: 'flex' }}>
            <div style={{marginRight: 10}}>
              <img
                src={officeIn.company.images.length > 0 ? officeIn.company.images[0].img : "https://images.cubicsearch.com/CompanyLogo.png"}
                alt={officeIn.company.Name || "Company Logo"}
                style={{ maxWidth: 100, height: 'auto', cursor: 'pointer' }}
                onClick={() => window.open(`company?id=${officeIn.company.id}`, mobileAndTabletCheck() ? "_self" : null)}
              />
            </div>
            <div>
              <Typography variant="subtitle1" component="div">{officeIn.company.Name || "Add Company"}</Typography>
              <CustomLink value={officeIn.company.Website || "Add Website"} />
              <Typography variant="body2">{formatPhoneNumber(officeIn.Phone) || "Add Phone"}</Typography>
              <Typography variant="body2">
                {officeIn.StreetNumber}  {officeIn.StreetName || "Add Street"}, {officeIn.City || "Add City"}, {officeIn.State || "Add State"}
              </Typography>
              {highestPermission.includes("Edit") && (
                <Button
                  variant="text"
                  size="small"
                  color="secondary"
                  onClick={buttonClick}
                  sx={{ mt: 1 }}
                >
                  Edit Company
                </Button>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <Typography>No company information available.</Typography>
          {highestPermission.includes("Edit") && (
            <Button
              variant="text"
              size="small"
              color="secondary"
              onClick={buttonClick}
              sx={{ mt: 1 }}
            >
              Add Company
            </Button>
          )}
        </>
      )}
    </Box>
  );
}
