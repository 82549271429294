import React from 'react';
import { TextField, Grid, Typography } from '@mui/material';

function InputComponent({ inputType, value, name, onChange }) {
  return (
    <TextField
      variant="outlined"
      type={inputType}
      name={name}
      label={name}
      value={value}
      onChange={onChange}
      fullWidth
      margin="normal"
    />
  );
}

function CompanyEdit({ companyDataIn, setCompanyDataIn }) {
  // Handlers for changes in each field that update the companyDataIn state
  const handleNameChange = (event) => {
    setCompanyDataIn({ ...companyDataIn, Name: event.target.value });
  };

  const handleWebsiteChange = (event) => {
    setCompanyDataIn({ ...companyDataIn, Website: event.target.value });
  };

  const handleNotesChange = (event) => {
    setCompanyDataIn({ ...companyDataIn, Notes: event.target.value });
  };

  return (
    <Grid container spacing={2} sx={{ padding: 2 }}>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2" gutterBottom>
          Company Name
        </Typography>
        <InputComponent
          inputType="text"
          value={companyDataIn.Name || ''}
          name="Name"
          onChange={handleNameChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2" gutterBottom>
          Website
        </Typography>
        <InputComponent
          inputType="text"
          value={companyDataIn.Website || ''}
          name="Website"
          onChange={handleWebsiteChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2" gutterBottom>
          About
        </Typography>
        <TextField
          variant="outlined"
          multiline
          rows={4}
          name="Notes"
          id="Notes"
          value={companyDataIn.Notes || ''}
          onChange={handleNotesChange}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}

export default CompanyEdit;
