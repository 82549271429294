import * as React from "react";

import NonLinearSlider from "../SearchBar/SliderSF";
import { InputComponent } from "../InputComponent";
import AddProperty from "../AddProperty/AddProperty";
import { Checkbox, checkboxClasses, DialogTitle, DialogContent, DialogContentText, DialogActions, CardActions, Tooltip } from "@mui/material";




import { Button } from "@mui/material";

import Dialog from '@mui/material/Dialog';
import MultipleSelectChip from "./MultipleSelectChip";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Filter, Tune } from "@mui/icons-material";

import Fab from "@mui/material/Fab";

export default function FilterCard({ filterInputs }) {

    

    let {
        filtersClose,
        setAvailablesCheck,

        postFilters,
        compsCheck,
        setCompsCheck,
        allCheck,
        setAllCheck,

        setMinAvailSize,
        setMaxAvailSize,
        minAvailSize,
        maxAvailSize,

        buildingPropertyType,
        setBuildingPropertyType,
        availPropertyType,
        setAvailPropertyType,
        filters,
        setFilters,
        searchResults,
        setSearchResults,
        initialViewState,
        user,


        propertyMapRef,
        availablesCheck,
        dateMax,
        dateMin,
        setDateMax,
        setDateMin,
        location
    } = filterInputs


    React.useEffect(() => {
        filtersClose()
    },
        [minAvailSize, maxAvailSize, buildingPropertyType, availPropertyType, dateMin, dateMax]
    )
    return (

        <Card variant='elevation'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ padding: 5 }}>
                    <Fab sx={{ backgroundColor: 'white', color: 'indigo' }}>

                        <Tune />
                    </Fab>
                </div>
                <Typography component={'div'}gutterBottom variant="h5">
                    Search Filters
                </Typography>
            </div>
            <>
                <div className="flex flex-col w-full">
                    <div className="flex flex-col w-full  px-4 pb-2">
                        <div className="w-full text-xs">
                            {/* <div className="sm:px-0 py-2">Available/Comp SF:</div> */}
                            <NonLinearSlider
                                setMinAvailSize={setMinAvailSize}
                                setMaxAvailSize={setMaxAvailSize}
                                minAvailSize={minAvailSize}
                                maxAvailSize={maxAvailSize}
                  

                            />
                        </div>
                    </div>

                    <>
                        <div className="text-m ">
                            <div>
                                {" "}
                                <div className="flex w-full ">
                                    <div className="w-full">
                                        <div className="px-2  ">
                                            <div className="flex flex-col w-full ">

                                                <MultipleSelectChip Options={[
                                                    "Life Science",
                                                    "GMP",
                                                    "Industrial",
                                                    "Flex",
                                                    "Office",
                                                    "Retail",
                                                    "Other",]} setAvailPropertyType={setBuildingPropertyType} availPropertyType={buildingPropertyType} title={"Building or Space Type"} />

                                            </div>
                                            <div >
                                                <MultipleSelectChip Options={[
                                                    "Life Science",
                                                    "GMP",
                                                    "Industrial",
                                                    "Flex",
                                                    "Office",
                                                    "Retail",
                                                    "Other",]} setAvailPropertyType={setAvailPropertyType} availPropertyType={availPropertyType} title={"Available Space Type"} />

                                            </div>

                                        </div>
                                        <div className="flex w-full pt-4" >
                                            {" "}
                                            <Tooltip title="Show Availables (Red)">
                                                <div className="w-full">
                                             
                                                    <Checkbox
                                                        sx={{
                                                            [`&, &.${checkboxClasses.checked}`]: {
                                                                color: 'red',
                                                            },
                                                        }}
                                                    onChange={(e) => {

                                                        setAvailablesCheck(e.target.checked);
                                                        var newFilters = filters
                                                        newFilters.availablesCheck = ((e.target.checked))

                                                        setFilters(newFilters)
                                                        postFilters(newFilters, searchResults, initialViewState, user.contact.id, setFilters, setSearchResults, propertyMapRef)

                                                    }}
                                                    checked={availablesCheck}
                                                    name="availablesCheck"
                                                    /> {" "}
                                                <Typography component={'div'}sx={{ fontSize: 10 }}>Availables</Typography>
                                                </div>
                                            </Tooltip>
                                            <Tooltip title="Show Comps (Green)">
                                            <div className="w-full">
                                             
                                                    <Checkbox
                                                        
                                                        sx={{
                                                            [`&, &.${checkboxClasses.checked}`]: {
                                                                color: 'green',
                                                            },
                                                        }}
                                                    onChange={(e) => {

                                                        setCompsCheck(e.target.checked);
                                                        var newFilters = filters
                                                        newFilters.compsCheck = ((e.target.checked))

                                                        setFilters(newFilters)
                                                        postFilters(newFilters, searchResults, initialViewState, user.contact.id, setFilters, setSearchResults, propertyMapRef)

                                                    }}
                                                    checked={compsCheck}

                                                    // checked={compsCheck}
                                                    name="compsCheck"
                                                    />{" "}
                                               
                                                <Typography component={'div'}sx={{ fontSize: 10 }}>Lease Comps</Typography>
                                                </div>
                                            </Tooltip>
                                            <Tooltip title="Show All Properties (Black)">
                                            <div className="w-full">
                                               
                                                <Checkbox
                                                    sx={{
                                                        [`&, &.${checkboxClasses.checked}`]: {
                                                            color: 'black',
                                                        },
                                                    }}
                                                    onChange={(e) => {

                                                        setAllCheck(e.target.checked);
                                                        var newFilters = filters
                                                        newFilters.allCheck = ((e.target.checked))

                                                        setFilters(newFilters)
                                                        postFilters(newFilters, searchResults, initialViewState, user.contact.id, setFilters, setSearchResults, propertyMapRef)

                                                    }}
                                                    checked={allCheck}

                                                    // checked={compsCheck}
                                                    name="allCheck"
                                                    />
                                                  
                                                <Typography component={'div'}sx={{ fontSize: 10 }}>All Properties</Typography>
                                            </div>
                                            </Tooltip>{" "}
                                        </div>
                                        <div className="flex px-2">

                                            <div className="px-4 showOnMobile">
                                                <AddProperty />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h1 className="text-3xl font-semibold mt-2 mb-6 hidden-mobile">
                            {location ? "Properties in " + location : ""}
                        </h1>
                    </>
                    <div className="py-2 px-2 w-full">
                        <div className="text-xs w-full">
                            {/* <div>Occupancy/Transaction:{" "}</div> */}
                            <div >Avail Occupancy or Transaction Date</div>
                            <div style={{ justifyContent: 'space-between' }} className="flex py-2 stackOnSmall">

                                <div >
                                    <div>
                                        From
                                    </div>
                                    <InputComponent
                                        inputType={"dateRangeStart"}
                                        name={"dateMin"}
                                        valueIn={dateMin}
                                        setvalueIn={setDateMin}
                                        defaultShow={dateMin}
                                        formatting={"text-xs"}
                                    />
                                </div>
                                <div>
                                    <div>
                                        To
                                    </div>
                                    <InputComponent
                                        inputType={"dateRangeEnd"}
                                        name={"dateMax"}
                                        valueIn={dateMax}
                                        setvalueIn={setDateMax}
                                        defaultShow={dateMax}
                                        formatting={"text-xs"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>





        </Card>
    )
}

