import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import React from "react";
import { BarChart } from '@mui/x-charts/BarChart';


export default function ClusterCard({ cluster }) {
    
    if (!cluster.data || !cluster.data.CityArray || !cluster.data.CityArray) {
        return <></>

    }

    var typesArray = []
    var valuesArray = []

    var Cities = cluster.data.CityArray.join(", ")
    var States = cluster.data.StateArray.join(", ")

    Object.keys(cluster.data.ConsolidatedSF).forEach(function (k) {
        if (cluster.data.ConsolidatedSF[k].SF && cluster.data.ConsolidatedSF[k].SF > 0) {

            typesArray.push(k)
            valuesArray.push(cluster.data.ConsolidatedSF[k].SF)
        }

    });
    var barChartJSON = {
        xAxis: typesArray,
        series: valuesArray

    }
    var zoom
    if (cluster.viewState) {
        zoom = cluster.viewState.zoom
    }
    else {
        zoom = 18
    }
    var qs = "https://services1.arcgis.com/0EOqWPtpCmgQr2ay/arcgis/rest/services/Major_Cities_of_the_World/FeatureServer/0/query?where=1%3D1&outFields=*&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelIntersects&outSR=4326&f=json&geometry="
    var envelope = cluster.data["min(buildings.Longitude)"]
        + "," + cluster.data["min(buildings.Latitude)"]
        + "," + cluster.data["max(buildings.Longitude)"]
        + "," + cluster.data["max(buildings.Latitude)"]

    console.log(qs + envelope)
    var streetAddress0 = "https://www.google.com/maps/embed/v1/view?key=" + process.env.REACT_APP_GoogleMapsAPIKey + "&center=" +
        (cluster.data["avg(buildings.Latitude)"]) + "," + cluster.data["avg(buildings.Longitude)"] +
        "&zoom=" + zoom + "&maptype=satellite"

    console.log(streetAddress0)
    
    var totalBuildings = 0
    
    
    return (
        <>    <Card sx={{ maxWidth: 600, minWidth: 300 }} variant='outlined'>

            <CardContent sx={{width: 'auto'}}>
                <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
                    {/* <div style={{ padding: 5 }}>
                        <Fab sx={{ backgroundColor: 'white', color: 'indigo' }}>

                            <BarChartOutlined />
                        </Fab>
                    </div> */}
                    <div>
                        <Typography component={'div'}gutterBottom variant="h6" >
                            Available Space by Property Type
                        </Typography>
                    </div>

                </div>
                <BarChart

                    yAxis={[{
                         scaleType: 'band',
                        data: barChartJSON?.xAxis, tickLabelStyle: {
                            angle: -45,
                            textAnchor: 'end',
                            fontSize: 14,
                            width: 60
                        },
                    },]}
                    series={[{ data: barChartJSON?.series, color: '#e15759', label: 'Square Feet Available' }]}
                    // width={400}
                    height={300}
                    margin={{ left: 65, right: 100 }}

                    layout='horizontal'


                />
               <Typography component={'div'}gutterBottom maxWidth={600} noWrap={true} variant="h5" >
                    { Cities}
                </Typography>
                <Typography component={'div'}gutterBottom maxWidth={600} noWrap={true} variant="h5" >
                    {States}
                </Typography>
                <Typography component={'div'}variant="body2" color="text.secondary">
                    <p>{parseInt(cluster.data["SUM(avails.SizeMax)"]).toLocaleString()} Available Square Feet</p>
                    <p>{cluster.data.idArray.length} Building(s)</p>


                </Typography>
            </CardContent>
            <CardActions>
                {/* <Button size="small">Share</Button>
            <Button size="small">Learn More</Button> */}
            </CardActions>
        </Card>
        </>)
}

export function ClusterCardTotals({ cluster }) {


    
    if (!cluster.data) {
        return <></>

    }
    var typesArray = []
    var valuesArray = []

    //  var Cities = cluster && cluster.data && cluster.data.CityArray ? cluster.data.CityArray.join(", "): ""
    // var States = cluster && cluster.data && cluster.data.StateArray? cluster.data.StateArray.join(", "): ""


    Object.keys(cluster.data).forEach(function (k) {
        if (cluster.data[k].SF && cluster.data[k].SF > 0) {

            typesArray.push(k)
            valuesArray.push(cluster.data[k].SF)
        }

    });
    var barChartJSON = {
        xAxis: typesArray,
        series: valuesArray

    }


    
    // var totalSpaces = 0
    // Object.keys(cluster.data).map((eachKeyName) => {
    //     totalSpaces += parseInt(cluster.data[eachKeyName].count)
    // })


    return (
        <>
            <div style={{ padding: 5 }} >
                <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>

                    {/* <Fab sx={{ backgroundColor: 'white', color: 'indigo' }}>

                        <BarChartOutlined />
                    </Fab> */}

                    <div style={{paddingLeft: 5}}>
                        <Typography component={'div'}gutterBottom variant="h6" >
                            Available Space by Property Type
                        </Typography>
                    </div>
                </div>
               


                <div style={{ padding: 10}}>
                    <Typography component={'div'}gutterBottom variant='caption' >
                        {cluster.totalBuildings} properties
                        {/* {totalSpaces} spaces */}
                    </Typography>
                </div>
            </div>
            <Card sx={{ maxWidth: 600, minWidth: 300}} >
                {/* <CardMedia
                sx={{ height: 300}}

                title="Keep Searching!"
            > */}

                {/* <iframe loading='lazy'
                    // width="450"
                    // height="250"
                    className={window.window.innerWidth < 900 ? "w-full rounded-xl h-full" : "w-3-fourth rounded-xl"}
                    style={{ border: 0, height: '100%' }}
                    referrerPolicy="no-referrer-when-downgrade"
                    src={
                        streetAddress0}

                    // src={"https://www.google.com/maps/embed/v1/place?q=" +
                    // plusCode + "&key="+ process.env.REACT_APP_GoogleMapsAPIKey +"&zoom=18&maptype=satellite"}// +"&heading=210&pitch=10&fov=35"}
                    allowFullScreen={true}>
                </iframe> */}
                {/* </CardMedia> */}
                <CardContent sx={{width:'auto'}}>
                    <BarChart

                        yAxis={[{
                             scaleType: 'band',
                            data: barChartJSON?.xAxis, tickLabelStyle: {
                                angle: -45,
                                textAnchor: 'end',
                                fontSize: 14,
                                width: 60
                            },
                        },]}
                        series={[{ data: barChartJSON?.series, color: '#e15759', label: 'Square Feet Available' }]}
                    
                        height={300}
                        margin={{ left: 65, right: 100 }}

                        layout='horizontal'




                    />
                    {/*<Typography component={'div'}gutterBottom maxWidth={600} noWrap={true} variant="h5" >
                        {Cities}
                    </Typography>
                   <Typography component={'div'}gutterBottom maxWidth={600} noWrap={true} variant="h5" >
                        {States}
                    </Typography> */}
                    <Typography component={'div'}gutterBottom variant="h5" >
                        Data represents properties within current map window

                    </Typography>
                    <Typography component={'div'}variant="body2" color="text.secondary">
                        {/* <div>{parseInt(cluster.data["SUM(avails.SizeMax)"]).toLocaleString()} Available Square Feet</div> */}
                        {/* <div>{cluster.data["count(buildings.Latitude)"]} Building(s)</div> */}


                    </Typography>
                </CardContent>
                <CardActions>
                    {/* <Button size="small">Share</Button>
            <Button size="small">Learn More</Button> */}
                </CardActions>
            </Card>
        </>)
}