import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from "@mui/material";
import ClientsCombo from "./ClientsCombo"; // Ensure this component is compatible with MUI if it's custom

import ProjectsCombo from "./ProjectsCombo"; // Ensure this component is compatible with MUI if it's custom
import axios from "axios";
import SurveyNameField from './SurveyNameField'


function ClientModalSaveSurvey({
  open,
  setOpen,
  id,
  searchResults,
  availMin,
  availMax,
  dateMin,
  dateMax,
  buildingPropertyType,
  availPropertyType,
  compsCheck,
  allCheck,
  availablesCheck,
  // Pass the rest of your props here
}) {
  const [clientGroups, setClientGroups] = useState(null);
  const [clientsDataArray, setClientsDataArray] = useState(null)
  const [projectGroups, setProjectGroups] = useState(null);
  const [highestPermission, setHighestPermission] = useState("View");
  const [newClientName, setNewClientName] = useState(null);
  const [selectedClient, setSelectedClient] = useState({ id: 1, name: "" });
  const [clientId, setClientId] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [createClient, setCreateClient] = useState(false);
  const [createProject, setCreateProject] = useState(false);
  const [addProject, setAddProject] = useState(false);
  const [addSurvey, setAddSurvey] = useState(false);
  const [buttonText, setButtonText] = useState("Save Survey");
  const [projects, setProjects] = useState(null);

  const [newProjectName, setNewProjectName] = useState(
    "New Project - " + new Date().toLocaleDateString()
  );
  const [clientName, setClientName] = useState(null);
  const [showWait, setShowWait] = useState(null)
  const [surveyName, setSurveyName] = useState(
   ''
  );
  const [surveyNotes, setSurveyNotes] = useState(null);

  const navigate = useNavigate()

  useEffect(() => {

    const loadClients = async () => {
      var qs = process.env.REACT_APP_DATABASE + "api/myClients";

      var clientsDataArrayData = await axios.post(qs, {contactId: id}).catch((error) => { console.log(error) })
      if (clientsDataArrayData?.data) {
        var clients = clientsDataArrayData?.data.map((client) => {
          client.clientId = client.id
          return client
        })
        setClientsDataArray(clients)
      }
      else {
        alert("Error fetching clients")
      }

    }
    if (open) {
      loadClients()
    }
  }, [open])

  async function getProjects(clientId) {

    if (clientId) {
      var qs =
        process.env.REACT_APP_DATABASE +
        "api/getProjectsForClient?contactId=" +
        id +
        "&clientId=" +
        clientId;

      //test - console.log(qs);
      var response = await axios.get(qs)
      var projectsDataArray = response.data
      setProjects(projectsDataArray);
      setSelectedProject(projectsDataArray[0] || null);
    }
    else {
    
      setProjects([])
      setSelectedProject(null)
    }

  }

  const handleCreateSurvey = async () => {
    setShowWait(true)
    var response = await axios.post(
      process.env.REACT_APP_DATABASE +
        "api/createSurvey"
      ,
      {
        contactId: id,
        SurveyName: surveyName,
        Notes: surveyNotes,
        clientId: selectedClient.id,
        projectId: selectedProject.id,
        availMin: availMin,
        availMax: availMax,
        dateMax: dateMax,
        dateMin: dateMin,
        buildingPropertyType: buildingPropertyType,
        availPropertyType: availPropertyType,
        compsCheck: compsCheck,
        availablesCheck: availablesCheck
      } 
    ).catch((error) => { console.log(error) })
    
      
    //test - console.log(data);
    if (response?.data?.id){
    var newSurveyId = response.data.id;
    var surveyResultsFromFrontEnd = searchResults.map(
      ({ id }, index) => ({
        buildingId: id,
        sortOrder: index,
        surveyId: newSurveyId,
      })
    ); // This extracts just the ids of the buildings


    
    var responseCreate = await axios.post(
      process.env.REACT_APP_DATABASE +
      "api/createSurveyResults", { surveyResultsFromFrontEnd, contactId: id }

    ).catch((error) => { console.log(error) })

    var createMeta = await axios.get(
      process.env.REACT_APP_DATABASE +
      "api/metaSurvey?id=" +
      newSurveyId
    )



    navigate("/search?surveyId=" + newSurveyId)
      setShowWait(false)
    }
    else {
      alert("problem with save")
    }
    setOpen(false);




  }

  // Your axios calls and other logic here

  return (
    <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="client-modal-title">
      <DialogTitle id="client-modal-title">Choose or Create a Client for this New Survey</DialogTitle>
      <DialogContent dividers>
        {
          // Conditional rendering based on whether the clients data is loaded or not
          clientsDataArray ? (
            <>
              <ClientsCombo
                clients={clientsDataArray}
                selectedClient={selectedClient}
                setSelectedClient={setSelectedClient}
                setClientId={setClientId}
                setButtonText={setButtonText}
                setAddClient={setCreateClient}
                setNewClientName={setNewClientName}
                setShowProjects={setAddProject}
                getProjects={getProjects}
                setHighestPermission={setHighestPermission}
                setClientGroups={setClientGroups}
                setClientsDataArray={setClientsDataArray}
                contactId={id}
              />
              <div style={{ marginTop: 10 }}>
                {addProject && selectedClient && <ProjectsCombo
                  clientId={clientId}
                  selectedProject={selectedProject}
                  projectsDataArray={projects}
                  setProjectsDataArray={setProjects}
                  setSelectedProject={setSelectedProject}
                  setButtonText={setButtonText}
                  setCreateProject={setCreateProject}
                  setNewProjectName={setNewProjectName}
                  contactId={id}
                  newSurveyName={surveyName}
                  setSurveyName={setSurveyName}
                  setProjectId={setProjectId}
                  setProjectGroups={setProjectGroups}
                  selectedClient={selectedClient}
                  setAddSurvey={setAddSurvey}
                />}
              </div>
              <div style={{ marginTop: 10 }}>
                {(selectedClient &&  selectedProject) && <SurveyNameField selectedClient={selectedClient} selectedProject={selectedProject} setSurveyName={setSurveyName } surveyName={surveyName} />}
              </div>
              {/* Additional content goes here */}
            </>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </div>
          )
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Cancel
        </Button>
        <Button variant={"contained"} disabled={(!surveyName) || (!selectedProject) || (!selectedClient)} onClick={async () => {
          await handleCreateSurvey()
        }} color="primary">
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ClientModalSaveSurvey;





