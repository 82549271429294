import { AvailGroup } from "../../Search/InfoCard";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Paper } from "@mui/material";

export default function AvailableGroupsRows({ groupsIn, contactId, available }) {
  return (
    <Grid2 container spacing={4}>
      {groupsIn.map((group, index) => (
        <Grid2 key={group.id.toString() + index}>
          <Paper elevation={6}>
            <AvailGroup group={group} contactId={contactId} available={available} />
          </Paper>
        </Grid2>
      ))}
    </Grid2>
  );
}
