// import { useState } from "react";
// import AddPropertyModal from "./AddPropertyModal";
import { data } from "autoprefixer";
import { formatPhoneNumber } from "../FormatPhoneNumber";
import { checkCookie, getCookie, createCookie } from "../Cookies";
import axios from "axios";


export default function getSearchData(
  setProperties,
  contactId,
  limit,
  query,
  setSearching,
  controllers,
  setControllers,
  waiting,

) {

  return new Promise(async (resolve, reject) => {
    // if (waiting) {
    //   controllers.controller.abort()
    //   controllers.googleController.abort()

    // }


    query = query ? query + "&contactId=" + contactId : "";
    var getSearchPropertyData =
      process.env.REACT_APP_DATABASE +
      "api/PropertyData?contactId=" +
      contactId +
      "&limit=" +
      limit +
      "&query=" +
      encodeURI(query);

    var getSearchPeopleData =
      process.env.REACT_APP_DATABASE +
      "api/getPeople?contactId=" +
      contactId +
      "&limit=" +
      limit +
      "&query=" +
      encodeURI(query);
    //;
    //test - console.log(encodeURI(getSearchPropertyData));
    if (controllers.controller ) {
      controllers.controller.abort()
      

    }

     if (controllers.googleController) {
      controllers.googleController.abort()
     }
    
     if (controllers.peopleController) {
      controllers.peopleController.abort()
    }
   

    var newGoogleController = new AbortController()
    var newController = new AbortController()
    var newPeopleController = new AbortController()
    var resultPropertyData = []
    // axios.get(encodeURI(getSearchPropertyData), {
    //   signal: newController.signal
    // }).then((resultPropertyData) => {
    setTimeout(() => {
      newGoogleController.abort();
    }, 1000);
    var googleResponsePromise = await axios.get(
      encodeURI(process.env.REACT_APP_DATABASE + "api/getGoogleInfo?query="
      + query),
      {
        signal: newGoogleController.signal
      },).catch((error) => { console.log("Google aborted") })
    
    setTimeout(() => {
      newController.abort();
    }, 2000);
    var resultPropertyDataPromise = await
      axios.get(getSearchPropertyData, {signal: newController.signal})
        .catch((error) => { console.log("regular search aborted") })
    
    setTimeout(() => {
      newPeopleController.abort();
    }, 1500);
    var resultPeopleDataPromise = await
      axios.get(encodeURI(getSearchPeopleData, {signal: newPeopleController.signal})).catch((error) => { console.log("people search aborted") })

    //  var resultParcelsInner = await resultPropertyData.json();

 
      var googleResponseData = googleResponsePromise
      var resultParcels = resultPropertyDataPromise?.data ||[]
      var peopleData = resultPeopleDataPromise?.data ||[]
      var googleResponse = []
      var people = []
      var campusNames = []
      if (googleResponseData && googleResponseData?.data) {
        googleResponse = googleResponseData?.data?.google || []



      }

      if (peopleData) {


        people = peopleData?.people || [];

      }
      if (resultParcels) {
        campusNames = resultParcels?.campusNames
      }
      console.log("Setting results");

      // if (resultParcelsInner && resultParcelsInner.empty && (googleResponse.candidates.length === 0)) {
      //   setProperties(resultParcelsInner);
      //   return;
      // }

      var propertyTypes = [];
      var nfObject = new Intl.NumberFormat("en-US");



      if (googleResponse.candidates) {
        googleResponse.candidates.forEach((googleResult) => {



          propertyTypes.unshift({
            // img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSg2iegcITjdMxf13Wvyrwpk3ftTZbKuNYVl9tw7kmjA&s",
            order: 2,
            img: "https://images.cubicsearch.com/icons/flag.png",
            id: googleResult.geometry,
            apn: googleResult.formatted_address ? googleResult.formatted_address : "",
            city: googleResult.rating ? googleResult.rating : "No Ratings",
            address: googleResult.name,
            type: "Move Map Here",
            name: googleResult.name + googleResult.formatted_address,
          });
        })
      }
      // var cities = resultParcels ? getCities(resultParcels.buildings) : null
      people.forEach((contact, index) => {
        if (contact.FirstName || contact.LastName || contact.Email.includes("@")) {

          var image = "https://images.cubicsearch.com/UserLogo.png"

          if (contact.images.length > 0) {
            var mainIndex = 0
            var mainIndex = contact.images.findIndex((imageJson) => {
              return imageJson.sortOrder === 0
            })
            if (!mainIndex || mainIndex < 0) {
              mainIndex = 0
            }
            image = contact?.images[mainIndex]?.img
          }
          propertyTypes.unshift({
            order: 1,
            img: image,
            id: "contact-" + contact.id,
            apn: contact?.office?.company?.Name,
            // ? contact.office.company.Name
            // : "",
            city: "", // contact.office && contact.office.City ? contact.office.City : "",
            address: (contact.FirstName || "") + " " + (contact.LastName || "") + " " + " " + contact.Email,
            type: "Contact",
            name: contact.FirstName + " " + contact.LastName + " " + contact.Email,
          });
        }
      });
      // if (cities) {
      //   cities.forEach((City, index) => {
      //     propertyTypes.push({
      //       img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkruUk8-Vhjckl2Gaax3ZViX0vmPbbOU2cdgA74k-9D7n-m_AOdKTn&usqp=CAE&s",
      //       id: City.name,
      //       apn: City.name ? City.name : "",
      //       city: City.name,
      //       address: City.name,
      //       type: "City",
      //       name: City.name,
      //     });
      //   });
      // }

      if (campusNames) {

        resultParcels.campusNames.forEach((campusName, index) => {

          propertyTypes.push({
            order: 3,
            img: "https://images.cubicsearch.com/small%20survey.png",
            id: "campus-" + campusName,
            apn: "",
            city: campusName, // contact.office && contact.office.City ? contact.office.City : "",
            address: " ",
            type: "Campus",
            name: campusName,
          });
        });
      }
      if (resultParcels) {


        // resultParcels.people.forEach((contact, index) => {


        resultParcels?.avails?.forEach((available) => {
          var floor = available.Floor
            ? available.Floor === -1
              ? "Entire Building"
              : "| Floor " + available.Floor
            : "";

          if (available.Status !== "Lease Comp") {
            propertyTypes.push({
              order: 4,
              img: "https://images.cubicsearch.com/small%20spaces.png",
              id: "avail-" + available.id,
              apn:
                nfObject.format(available.SizeMin) +
                "-" +
                nfObject.format(available.SizeMax) +
                " SF",
              city: available.building.City,
              address:
                (available.building.CampusName
                  ? available.building.CampusName + " | "
                  : "") +
                (available.building.PropertyName
                  ? available.building.PropertyName + " | "
                  : "") +
                available.building.StreetNumber +
                " " +
                available.building.StreetName +
                " " +
                floor,
              type: "Available Space",
              name:
                available.building.StreetNumber +
                " " +
                available.building.StreetName +
                // " | " +
                // building.City +
                ", " +
                available.building.State +
                " | " +
                available.building.PropertyName,
            });
          } else {
            if (
              available.building &&
              propertyTypes.findIndex((entry, i) => {
                return entry.id === "comp-" + available.id;
              }) < 0
            ) {
              propertyTypes.push({
                order: 5,
                img: "https://images.cubicsearch.com/compIcon.png",
                id: "comp-" + available.id,
                apn: available.LeasedSpace
                  ? nfObject.format(available.LeasedSpace) + " SF"
                  : "",
                city:
                  available.building && available.building.City
                    ? available.building.City
                    : "",
                address:
                  (available.Tenant ? available.Tenant + " | " : "") +
                  (available.building.CampusName
                    ? available.building.CampusName + " | "
                    : "") +
                  (available.building.PropertyName
                    ? available.building.PropertyName + " | "
                    : "") +
                  available.building.StreetNumber +
                  " " +
                  available.building.StreetName +
                  " " +
                  floor,
                type: "Lease Transaction",
                name:
                  available.building.StreetNumber +
                  " " +
                  available.building.StreetName +
                  // " | " +
                  // building.City +
                  ", " +
                  available.building.State +
                  " | " +
                  available.Tenant,
              });
            }
          }
        });

        resultParcels?.buildings?.forEach((building) => {
          if (
            propertyTypes.filter((e) => e.id === "building-" + building.id).length ===
            0
          ) {
            propertyTypes.push({
              order: 6,
              img: "https://images.cubicsearch.com/small%20logo.png",
              id: "building-" + building.id,
              apn: "", //building.PropertyName,
              city: building.City,
              address:
                (building.CampusName ? building.CampusName + " | " : "") +
                " " +
                (building.PropertyName ? building.PropertyName + " | " : "") +
                " " +
                building.StreetNumber +
                " " +
                building.StreetName,
              type: "Building",
              name:
                building.StreetNumber +
                " " +
                building.StreetName +
                // " | " +
                // building.City +
                ", " +
                building.State +
                " | " +
                building.PropertyName +
                " | " +
                building.CampusName,
            });
            building.avails.forEach((available) => {
              var floor = available.Floor
                ? available.Floor === -1
                  ? "Entire Building"
                  : "| Floor " + available.Floor
                : "";

              if (available.Status !== "Lease Comp") {
                propertyTypes.push({
                  order: 7,
                  img: "https://images.cubicsearch.com/small%20spaces.png",
                  id: "avail-" + available.id,
                  apn:
                    nfObject.format(available.SizeMin) +
                    "-" +
                    nfObject.format(available.SizeMax) +
                    " SF",
                  city: building.City,
                  address:
                    (building.CampusName ? building.CampusName + " | " : "") +
                    (building.PropertyName ? building.PropertyName + " | " : "") +
                    building.StreetNumber +
                    " " +
                    building.StreetName +
                    " " +
                    floor,
                  type: "Available Space",
                  name:
                    building.StreetNumber +
                    " " +
                    building.StreetName +
                    // " | " +
                    // building.City +
                    ", " +
                    building.State +
                    " | " +
                    building.PropertyName,
                });
              } else {
                if (
                  propertyTypes.findIndex((entry, i) => {
                    return entry.id === "comp-" + available.id;
                  }) < 0
                ) {
                  propertyTypes.push({
                    order: 8,
                    img: "https://images.cubicsearch.com/compIcon.png",
                    id: "comp-" + available.id,
                    apn: available.LeasedSpace
                      ? nfObject.format(available.LeasedSpace) + " SF"
                      : "",
                    city: building.City,
                    address:
                      (available.Tenant ? available.Tenant + " | " : "") +
                      (building.CampusName ? building.CampusName + " | " : "") +
                      (building.PropertyName ? building.PropertyName + " | " : "") +
                      building.StreetNumber +
                      " " +
                      building.StreetName +
                      " " +
                      floor,
                    type: "Lease Transaction",
                    name:
                      building.StreetNumber +
                      " " +
                      building.StreetName +
                      // " | " +
                      // building.City +
                      ", " +
                      building.State +
                      " | " +
                      available.Tenant,
                  });
                }
              }
            });
          }
        });

        resultParcels?.parcels && resultParcels?.parcels?.forEach((parcel, index) => {

          propertyTypes.push({
            order: 9,
            img: "https://images.cubicsearch.com/small%20parcel%203.png",
            id: "parcel-" + parcel.id,
            apn: parcel.APN,
            city: parcel.City,
            address: parcel.StreetNumber + " " + parcel.StreetName,
            type: "Parcel",
            name:
              parcel.StreetNumber +
              " " +
              parcel.StreetName +
              // " | " +
              // parcel.City +
              ", " +
              parcel.State +
              " | " +
              parcel.APN + " | " + parcel.Owner1LastName,

          });
        })
      }
      // resultParcels.buildings = resultParcels.buildings.slice(0, 50)


      if (googleResponseData) {

        setProperties(propertyTypes.sort(sortByProperty("order")));
        
      }
    
    
    setControllers({ controller: newController, googleController: newGoogleController, peopleController: newPeopleController })
    resolve(true)
    return (true)
  }) 
 
  // }).catch((error) => {
  //   //test - console.log("regular search aborted")

}

// export default  function AddPropertyData({open, setOpen}) {
//     const [properties, setProperties] = useState([])
//     if (open && properties.length ===0) {
//         getData(setProperties)
//     }

//     return (
//         <>
//                 <AddPropertyModal open={open} setOpen= {setOpen} properties = {properties}/>
//         </>

//     )

// }

export function sortByProperty(property) {
  return function (a, b) {
    if (a[property] > b[property]) return 1;
    else if (a[property] < b[property]) return -1;

    return 0;
  };
}
function getCities(dataIn) {
  //     var getSearchPropertyData = process.env.REACT_APP_DATABASE + "api/CityData";
  //     var resultPropertyData = await fetch(getSearchPropertyData)
  //     var resultCities = await resultPropertyData.json()
  var cities = [];
  //   //  var nfObject = new Intl.NumberFormat('en-US');

  var result = null;
  dataIn.forEach((data, index) => {

    result = cities.find(({ name }) => name === data.City);

    if (!result) {
      if (data.City !== "null" && data.City !== "" && data.City) {
        cities.push({ id: data.City, name: data.City });
      }
    }
  });

  return cities.sort(sortByProperty("name"));
}
