// import { Alert } from "@mui/material";
import { useState } from "react";
//  import ContactEditList from ".depreciated/ContactEditList";
import ContactListView from "./ContactListView"

import AvailableContactEditTable from "./AvailableContactEditTable";
import { arrayRemove } from "./AvailableContactEditTable";
import { Edit, Save } from "@mui/icons-material"
import { Button } from "@mui/material";


export default function ContactList({
  peopleIn,
  jsonformsDataIn,
  setPeopleIn,
  // peopleCombo,
  contactId,
  groupsIn,
  highestPermission,
  availOrComp,
  guest,
}) {
  const [editContactsMode, setEditContactsMode] = useState(false);
  //
  //  setData(jsonformsDataIn.contacts.sort((a,b)=> a.avail_contact.sortOrder - b.avail_contact.sortOrder))
  if (
    !highestPermission ||
    (highestPermission !== "Lead Edit" && highestPermission !== "Edit")
  ) {
    groupsIn.map((group, index) => {
      if (group.id === 1) {
        groupsIn = arrayRemove(groupsIn, index);
      }
    });
  }
  return (
    <>
      <div className="px-0 sm:px-0 lg:px-0">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <div className="text-xl font-semibold text-gray-900">
              {availOrComp !== "comp"
                ? "Available Space Contacts"
                : "Lease Transaction Contacts"}
              <Button variant={'outlined'} sx={{ marginLeft: 1, minWidth: 0, width: 20, height: 24, borderColor: 'black', color: 'black' }}
                onClick={() => {

                  if (guest === true || guest === 'true') {
                    alert("Please login or sign up to make edits.");
                  } else {

                    setEditContactsMode(!editContactsMode);
                  }
                }}
              >
                {!editContactsMode ? <Edit sx={{ width: 18, height: 18 }} /> : <Save />}
              </Button>
            </div>
            <div>

              {availOrComp !== "comp"
                ? "Each Availble Contact has Specific Visibility"
                : "Each Transaction Contact has Specific Visibility"}{" "}
            </div>
          </div>


        </div>
        {/* {!editContactsMode? <ContactListView peopleIn = {peopleIn}/> : <ContactEditList peopleIn = {peopleStateIn} /> } */}
        {!editContactsMode ? (
          <ContactListView peopleIn={peopleIn} />
        ) : (
          <AvailableContactEditTable
            peopleIn={peopleIn}
            setPeopleIn={setPeopleIn}
            availId={jsonformsDataIn.id}
            // peopleCombo={peopleCombo}
            contactId={contactId}
            groupsIn={groupsIn}
            highestPermission={highestPermission}
          />
        )}
      </div>
    </>
  );
}
