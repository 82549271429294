import React from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

export default function CompanyOfficesList({ officesIn, setShowCompanyModal }) {
  function buttonClick() {
    setShowCompanyModal(true);
  }

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 16px' }}>
        <Typography variant="h6" component="h1" color="text.primary">
          Offices
        </Typography>
        {/* <Button
          variant="contained"
          color="error"
          onClick={buttonClick}
          sx={{ mt: { xs: 2, sm: 0 } }} // margin top on xs screens, none on sm and up
        >
          Edit Offices
        </Button> */}
      </div>
      <TableContainer component={Paper} sx={{ marginTop: 4, maxHeight: 600, overflow: 'auto' }}>
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Address</TableCell>
              <TableCell>Phone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {officesIn.map((office) => (
              <TableRow key={office.id || "0"}>
                <TableCell>
                  <Typography variant="body2" color="text.primary">
                    {office.StreetNumber} {office.StreetName}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {office.City}, {office.State}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color="text.primary">
                    {office.Phone}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
