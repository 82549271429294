export default function PermissionInfo( icon, highestPermission ) {
    // 
  var returnMessage = "";
  if (icon === "search") {
  } else if (icon === "property") {
    if (highestPermission === "Edit" || highestPermission === "Lead Edit") {
      returnMessage =
        "With Owner and Edit Privalege, you can edit the details and add or remove the group called Public Domain which allows all users view privaleges.\n" + 
        "You can add and remove contacts viewable by the Public Domain.\n You can also modify the location of the building by dragging the red marker below.\n\n" +
        "If another private group has access to this record and has added comments, you are subject to the private group's permission to edit private information";
    }
    if (highestPermission === "View") {
        returnMessage = "You may view infomation, add private groups, and add private contacts.  Your changes will not be seen by the Public Domain.  You need to add your private group before you add information."
    }
  }else if (icon === "space") {
    if (highestPermission === "Edit" || highestPermission === "Lead Edit") {
      returnMessage =
        "With Owner and Edit Privalege, you can edit the details and add or remove the group called Public Domain which allows all users view privaleges." + 
        "You can add and remove contacts viewable by the Public Domain." +
        "If another private group has access to this record and has added comments, you are subject to the private group's permission to edit private information";
    }
    if (highestPermission === "View") {
        returnMessage = "You may view infomation, add private groups, and add private contacts.  Your changes will not be seen by the Public Domain.  You need to add your private group before you add information."
    }
  } else if (icon === "space edit") {
    if (highestPermission === "Edit" || highestPermission === "Lead Edit") {
        returnMessage =
          "With Owner and Edit Privalege, you can edit the details and add or remove the group called Public Domain which allows all users view privaleges." + 
          "You can add and remove contacts viewable by the Public Domain." +
          "If another private group has access to this record and has added comments, you are subject to the private group's permission to edit private information";
      }
      if (highestPermission === "View") {
          returnMessage = "You may view infomation, add private groups, and add private contacts.  Your changes will not be seen by the Public Domain.  You need to add your private group before you add information."
      }
  } else if (icon === "contact edit") {
    if (highestPermission === "Edit" || highestPermission === "Lead Edit") {
        returnMessage =
          "If the profile is not associated with a CubicSearch user, and since you have access to this record you have Edit rights.  In this case a historical journal will show changes to this record as multiple people may have access.  You can also edit the record if it is your profile, and only you will be able to edit your profile";
      }
      if (highestPermission === "View") {
          returnMessage = "This record is the profile of another user, and you do not have rights to edit this profile."
      }
  } else if (icon === "parcel") {
    if (highestPermission === "Edit" || highestPermission === "Lead Edit") {
        returnMessage =
          "With Owner and Edit Privalege, you can edit the details and add or remove the group called Public Domain which allows all users view privaleges." + 
          "You can add and remove contacts viewable by the Public Domain." +
          "If another private group has access to this record and has added comments, you are subject to the private group's permission to edit private information";
      }
      if (highestPermission === "View") {
          returnMessage = "You may view infomation, add private groups, and add private contacts.  Your changes will not be seen by the Public Domain.  You need to add your private group before you add information."
      }
  } else if (icon === "company") {
    if (highestPermission === "Edit" || highestPermission === "Lead Edit") {
        returnMessage =
          "With Owner and Edit Privalege, you can edit the details and add or remove the group called Public Domain which allows all users view privaleges." + 
          "You can add and remove contacts viewable by the Public Domain." +
          "If another private group has access to this record and has added comments, you are subject to the private group's permission to edit private information";
      }
      if (highestPermission === "View") {
          returnMessage = "You may view infomation, add private groups, and add private contacts.  Your changes will not be seen by the Public Domain.  You need to add your private group before you add information."
      }
  } else if (icon === "group") {
    if (highestPermission === "Edit" || highestPermission === "Lead Edit") {
        returnMessage =
          "With Owner and Edit Privalege, you can edit the name of the group, edit the picture of the group, and add or remove contacts from the group." + 
          "You can also change whether or not the members of the group can see eachother in this group.  In marketing groups, you may not want all your prospects to see eachother."
        
      }
      if (highestPermission === "View") {
          returnMessage = "With View permission, you can remove yourself from the group if you wish and have view access to the group's data."
      }
  }
  return returnMessage
}

export function Title(icon) {
  var returnMessage = "";
  if (icon === "search") {
  } else if (icon === "property") {
     return "Building Details"
  }else if (icon === "space") {
   return "Available Details"
  } else if (icon === "space edit") {
   return "Available Details"
  } else if (icon === "contact edit") {
  
      return "Contact Details"
  } else if (icon === "parcel") {
    return "Parcel Detao;s"
  } else if (icon === "company") {
   
      return "Company Details"
  } else if (icon === "group") {
    return "Group Details"
  }else if (icon === "comp") {
    return "Lease Transaction"
  }
  //return returnMessage
}
