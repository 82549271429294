import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";

import {
  Check as CheckIcon,
  ExpandMore as SelectorIcon,
  Info as InformationCircleIcon,
} from "@mui/icons-material";
import OpinionInfoSplash from "./Journals/Opinions/OpinionInfoSplash";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function OpinionList({
  opinions,
  contactId,
  entityId,
  setData,
  entity,
  // rerender,
  // listReset,
  // setListReset
}) {
  const [selected, setSelected] = useState(opinions[0]);
  const [showOpinionSplash, setshowOpinionSplash] = useState(false);

  // if (listReset) {
  //   
  //   // setListReset(false)
  //   setSelected(opinions[0])

  // }
  // if (rerender) {
  //   //test - console.log(rerender)
  // }

  if (!opinions || opinions.length === 0) {
    return;
  }
  if (
    opinions &&
    opinions.length > 0 &&
    opinions.findIndex((el) => {
      return el.id === selected.id;
    }) === -1
  ) {
    setSelected(opinions[0]);
  }

  //test - console.log("OpinionList Entity:" + entity);
  return (
    <>
      <div className=" w-30">
        {selected.Field.replace(/([A-Z])/g, " $1").trim()} Opinion(s){" "}
        <InformationCircleIcon
          className="h-4 w-4"
          data-tip
          data-for={selected.Field}
          onClick={() => {
            setshowOpinionSplash(true);
          }}
        />
      </div>
      <OpinionInfoSplash
        open={showOpinionSplash}
        setOpen={setshowOpinionSplash}
        opinion={selected}
        contactId={contactId}
        entityId={entityId}
        setData={setData}
        entity={entity}
      />

      <Listbox value={selected} onChange={setSelected}>
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium text-gray-700"></Listbox.Label>
            <div className="relative mt-1">
              <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                <span className="flex items-center">
                  <img
                    src={
                      selected.contact &&
                      selected.contact.images &&
                      selected.contact.images.length > 0
                        ? selected.contact.images[0].img
                        : "https://images.cubicsearch.com/UserLogo.png"
                    }
                    alt=""
                    className="h-6 w-6 flex-shrink-0 rounded-full"
                  />
                  <span className="ml-3 block truncate">
                    {
                    (
                      selected.contact && selected.contact.FirstName
                      ? selected.contact.FirstName
                      : "") +
                      " " +
                      ( selected.contact &&selected.contact.LastName
                        ? selected.contact.LastName
                        : "") +
                      " - " +
                      (selected.OpinionValue ? selected.OpinionValue : "") +
                      " - " +
                      (selected.Message ? selected.Message : "")}
                  </span>
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <SelectorIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {opinions.map((opinion) => {
                    var counter = 0;
                    return (
                      <Listbox.Option
                        key={opinion.id}
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-indigo-600"
                              : "text-gray-900",
                            "relative cursor-default select-none py-2 pl-3 pr-9"
                          )
                        }
                        value={opinion}
                      >
                        {({ selected, active }) => (
                          <>
                            <div className="flex items-center">
                              <img
                                src={
                                  opinion &&
                                  opinion.contact &&
                                  opinion.contact.images &&
                                  opinion.contact.images.length > 0
                                    ? opinion.contact.images[0].img
                                    : "https://images.cubicsearch.com/UserLogo.png"
                                }
                                alt=""
                                className="h-6 w-6 flex-shrink-0 rounded-full"
                              />
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "ml-3 block truncate"
                                )}
                              >
                                {opinion.contact?.FirstName +
                                  " " +
                                  opinion.contact?.LastName +
                                  " - " +
                                  opinion.OpinionValue +
                                  " " +
                                  opinion.Message}
                              </span>
                            </div>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-indigo-600",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : (
                              () => {
                                counter = counter + 1;
                                //test - console.log("counter " + counter);
                                //test - console.log(opinions.length);
                                if (counter === opinions.length) {
                                  setSelected(opinions[0]);
                                }
                              }
                            )}
                          </>
                        )}
                      </Listbox.Option>
                    );
                  })}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </>
  );
}
