import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
            width: 250,
            zindex: 300000

        },

    },
    sx: {
        zIndex: 1601,
    }
};



function getStyles(name, personName, theme) {
    return {
        zindex: 4000,
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function MultipleSelectChip({ Options, setAvailPropertyType, availPropertyType, title }) {
    const theme = useTheme();

    if (!availPropertyType || availPropertyType === 'Any' || availPropertyType[0] === 'Any') {
        availPropertyType = ["Life Science",
            "GMP",
            "Industrial",
            "Flex",
            "Office",
            "Retail",
            "Other"]
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setAvailPropertyType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );

    };

    if (!Array.isArray(availPropertyType)) {
        availPropertyType = [availPropertyType]
    }

    return (
        <div>
            <FormControl sx={{ m: 1, width: 300, zindex: 4000 }} variant='standard'>
                <InputLabel id="demo-multiple-chip-label">{title}</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"

                    id="demo-multiple-chip"
                    multiple
                    value={availPropertyType}
                    onChange={handleChange}
                    // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => {
                        
                        return (

                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {Array.isArray(selected) ? selected.map((value) => (


                                    <Chip key={value} label={value} />
                                )) : [selected].map((value) => (


                                    <Chip key={value} label={value} />
                                ))}
                            </Box>
                        )
                    }
                    }
                    MenuProps={MenuProps}
                >
                    {Options.map((name) => {
                        
                        
                        return (
                            <MenuItem

                                key={name}
                                value={name}
                                style={getStyles(name, availPropertyType, theme)}
                            >
                                {name}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </div>
    );
}