import React from "react";
import { useState } from "react";

// import "keen-slider/keen-slider.min.css";
import Header from "../Common/Header.js";
import HeaderForBuilding from "./HeaderForBuilding.js";
import MainPropertyInfo from "./MainPropertyInfo.js";
import KeenSlider from "../Images/KeenSlider.js";
import ImageUploadGallery from "../Common/Uploader/ImageUploadGallery.js";
import axios from 'axios'
import MapSinglePoint from "../Search/MapSinglePoint.tsx";
import { Edit, Save } from "@mui/icons-material"
import { mobileAndTabletCheck } from "../Search/Deck.js";
import { markDeleteImage, orderImages } from "../Availables/AvailableDetail.js";


async function updateBuilding(data, journalArray) {
  //test - console.log("my Data" + data);

  const qs = Object.keys(data) // turn the parameters into a get query string parameters style
    .map((key) => `${key}=${encodeURIComponent(data[key])}`)
    .join("&");

  var shortData = { ...data };

  var deleteFields = [
    "markedDelete",
    "Longitude",
    "Latitude",
    "createdAt",
    "updatedAt",
    "campus_id",
    "building_groups",
    "contacts",
    "avails",
    "websites",
    "companies",
    "parcels",
    "campus",
    "images",
  ];

  deleteFields.map((field) => {
    delete shortData[field];
  });

  var xhr = new XMLHttpRequest();

  xhr.onreadystatechange = function () {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      //test - console.log(xhr.responseText);
    }
  };
  xhr.open(
    "POST",
    process.env.REACT_APP_DATABASE + "api/updateBuildingPost",
    true
  );
  xhr.setRequestHeader("Content-Type", "application/json");
  // alert(PropertyInfo.YearBuilt)

  xhr.send(
    JSON.stringify({
      data: shortData,
      id: data.id,
      journalArray: journalArray,
    })
  );

  const qs1 = "&&data=" + encodeURIComponent(JSON.stringify(shortData));

  //   const {Base64} = require('js-base64');
  //   var dataObjectBase64 = Base64.encode(qs);
  ////test - console.log(dataObjectBase64    );

  //      var resultInfo = await fetch(process.env.REACT_APP_DATABASE + "api/updateBuilding?" + qs);

  // //test - console.log(process.env.REACT_APP_DATABASE + "api/updateBuilding?id=" + shortData.id + qs1 )
  // var resultInfo = await fetch(
  //   process.env.REACT_APP_DATABASE + "api/updateBuilding?id=" + data.id + qs1
  // );

  // var var2 = await resultInfo.json();
  // //test - console.log("results = " + var2);
}

export default function Property({
  PropertyInformation,
  peopleCombo,
  user,
  groups,
  groupsCombo,
  campusNames,
}) {
  const [propertyStateData, setPropertyStateData] =
    useState(PropertyInformation);
  const [editMode, setEditMode] = useState("View");
  const [editButtonText, setEditButtonText] = useState(<Edit sx={{ width: 18, height: 18 }} />);
  var initialPics = [];

  var highestPermission = "None";
  if (user?.contact?.guest === true || user?.contact?.guest === 'true') {
    highestPermission = "View";
  } else {
    if (user.contact.id === 1) {
      highestPermission = "Lead Edit";
    } else {
      groups.map((group) => {
        group.building_groups.map((building_group) => {
          if (
            highestPermission !== "View" &&
            highestPermission !== "Edit" &&
            highestPermission !== "Lead Edit"
          ) {
            highestPermission = building_group.groupPermission;
          } else {
            if (
              (highestPermission === "View" &&
                building_group.groupPermission === "Edit") ||
              building_group.groupPermission === "Lead Edit"
            ) {
              highestPermission = building_group.groupPermission;
            } else if (
              highestPermission === "Edit" &&
              building_group.groupPermission === "Lead Edit"
            ) {
              highestPermission = building_group.groupPermission;
            }
          }
        });
      });
    }
  }



  //test - console.log(propertyStateData);

  propertyStateData.images.map((image) => {
    initialPics.push({
      source: image.img,
      img: image.img,
      name: image.id,
      id: image.id
    });
    //return <></>
  });


  // const handlePaste = (e) => {
  //   if (e.clipboardData.files.length && editMode === "Edit") {
  //     const fileObject = e.clipboardData.files[0];

  //     var data = new FormData()
  //     data.append('file', fileObject)


  //     fetch(process.env.REACT_APP_IMAGE, {
  //       method: 'POST',
  //       body: data
  //     }).then((imageResponse) => {
  //       imageResponse.json().then((image) => {
  //         image.source = image.message
  //         addBuildingImage(image, propertyStateData, setPropertyStateData)
  //         alert("Image Added Please Click Save to Check")
  //       })
  //     })
  //       .catch((err) => {
  //         alert("error")
  //         //test - console.log(err)
  //         alert(err.message)
  //       })



  //   } else {
  //     // alert(
  //     //   "No image data was found in your clipboard. Copy an image first or take a screenshot."
  //     // );
  //   }
  // }
  async function addBuildingImage(image) {
 

    var result = await axios.get(process.env.REACT_APP_DATABASE + "api/addBuildingImage", {
      params: {
        img: image.message,
        myId: user.contact.id,
        buildingId: propertyStateData.id,
        sortOrder: initialPics?.length || 0
      }
    })
    const updatedImages = [...initialPics, {
      source: result?.data?.img,
      img: result?.data?.img,
      url: result?.data?.img,
      name: image.name,
      id: result?.data?.id
    }];
    
    setPropertyStateData({ ...propertyStateData, images: updatedImages });
  }



  return (

    <div>
      <nav className="sticky z-50 top-0">
        <Header
          key="1"
          placeholderLocation={(
            (propertyStateData.StreetNumber &&
              propertyStateData.StreetNumber.toLowerCase() != 'null') ?
              propertyStateData.StreetNumber : "") +
            " " +
            propertyStateData.StreetName +
            ", " +
            propertyStateData.City
            + " " +
            (propertyStateData.CampusName && (propertyStateData.CampusName != propertyStateData.StreetNumber + " " + propertyStateData.StreetName) ?
              " | " + propertyStateData.CampusName : "") +
            (propertyStateData.PropertyName ? " | " + propertyStateData.PropertyName : "")

          }
          icon="property"
          user={user}
          highestPermission={highestPermission}
          entityId={propertyStateData.id}
        />
      </nav>
      <div
        style={{
          display: (mobileAndTabletCheck() ? '' : 'flex'),
          flexDirection: (mobileAndTabletCheck() ? '' : 'column'),
          overflowX: 'hidden',
          justifyContent: (mobileAndTabletCheck() ? '' : 'center'),
          alignItems: (mobileAndTabletCheck() ? '' : 'center')
        }}
      >
        <div
          style={{ width: '100%', maxWidth: 2000, paddingRight: mobileAndTabletCheck() ? 0 : 80, paddingLeft: mobileAndTabletCheck() ? 0 : 80 }}
        >
          <div style={{ padding: 10 }}>
            <HeaderForBuilding
              setEditMode={setEditMode}
              setEditButtonText={setEditButtonText}
              editButtonText={editButtonText}
              editMode={editMode}
              dataIn={propertyStateData}
              editClick={editClick}
              setData={setPropertyStateData}
              highestPermission={highestPermission}
              contactId={user.contact.id}
              guest={user.contact.guest}
            />
          </div>
          {/* <div className="flex flex-col">
        <div className="flex flex-col max-w-full sm:max-w-xl self-center">
          <div className="flex flex-col  max-w-full"> */}
          <div className=" self-center "
            // onPaste={handlePaste}
            >
            {editMode === "View" ? (
              // <div className="rounded-2xl">
              <KeenSlider dataIn={propertyStateData} />
              // </div>
            ) : (
              <ImageUploadGallery
                initialImages={initialPics}
                onDeleted={async (image) => {
                  
                  await markDeleteImage(image, propertyStateData, setPropertyStateData)
                }
                }
                onSortEnd={async (images) => {

                  await orderImages(
                    images,

                    propertyStateData,
                    setPropertyStateData
                  )
                }
                }
                action={process.env.REACT_APP_IMAGE}
                onSuccess={(image) => {
                  addBuildingImage(image)
                }}
              />

            )}
          </div>
          {/* </div>
      </div> */}
          {/* {editMode === "View" ? ( */}

          <MainPropertyInfo
            dataIn={propertyStateData}
            peopleCombo={peopleCombo}
            setDataIn={setPropertyStateData}
            contactId={user.contact.id}
            guest={user.contact.guest}
            groups={groups}
            groupsCombo={groupsCombo}
            highestPermission={highestPermission}
            setEditMode={setEditMode}
            setEditButtonText={setEditButtonText}
            editButtonText={editButtonText}
            editMode={editMode}
            editClick={editClick}
            updateBuilding={updateBuilding}
            campusNames={campusNames}
          />
          {/* ) : (
        <BuildingEdit dataIn={propertyStateData} />
      )} */}

        </div>
        <div style={{ maxWidth: 2000, paddingRight: mobileAndTabletCheck() ? 0 : 80, paddingLeft: mobileAndTabletCheck() ? 0 : 80 }} className="w-full h-300">
          <MapSinglePoint
            dataIn={PropertyInformation}
            Latitude={PropertyInformation.Latitude}
            Longitude={PropertyInformation.Longitude}
            highestPermission={highestPermission}
          />
          {/* {BingMap(propertyData.Latitude, propertyData.Longitude, pushPins)} */}
        </div>
      </div>

    </div>
  );
}








function editClick(
  setEditModeFunctionIn,
  setEditButtonTextFunctionIn,
  editMode,
  dataIn,
  setData,
  contactId
) {
  var entries = [];
  for (const [key, value] of Object.entries(dataIn)) {
    entries.push({ fieldName: `${key}`, fieldValue: value });
  }

  if (editMode === "Edit") {
    var journalArray = [];
    entries.map((entry) => {
      if (document.getElementById(entry.fieldName)) {
        if (
          !(
            document.getElementById(entry.fieldName).value === "" &&
            !dataIn[entry.fieldName]
          )
        ) {
          if (
            dataIn[entry.fieldName]?.toString() !==
            document.getElementById(entry.fieldName).value
          ) {
            if (entry.fieldName.indexOf("Date") >= 0) {
              var date1 = new Date(dataIn[entry.fieldName]);
              var date2 = new Date(
                document.getElementById(entry.fieldName).value
              );
              var DifferenceTime = date2.getTime() - date1.getTime();

              var DifferenceDays = DifferenceTime / (1000 * 3600 * 24);

              if (-10 < DifferenceDays < 10) {
                //do nothing its close
              } else {
                journalArray.push({
                  buildingId: dataIn.id,
                  AuthorUserID: contactId,
                  Field: entry.fieldName,
                  OldValue: dataIn[entry.fieldName],
                  NewValue: document.getElementById(entry.fieldName).value,
                });
              }
            } else {
              journalArray.push({
                buildingId: dataIn.id,
                AuthorUserID: contactId,
                Field: entry.fieldName,
                OldValue: dataIn[entry.fieldName],
                NewValue: document.getElementById(entry.fieldName).value,
              });
            }
          }
        }
      }
      if (document.getElementById(entry.fieldName)) {
        dataIn[entry.fieldName] = updateJson(
          dataIn[entry.fieldName],
          document.getElementById(entry.fieldName).value
        );
      }
    });

    var newData = { ...dataIn };
    setEditModeFunctionIn("View");
    setEditButtonTextFunctionIn(<Edit sx={{ width: 18, height: 18 }} />);
    setData(newData);
    updateBuilding(dataIn, journalArray);
  } else {
    setEditModeFunctionIn("Edit");
    setEditButtonTextFunctionIn(<Save />);
  }
}

function updateJson(jsonFieldIn, reqValueIn) {
  var whatWeSaveToJsonAndDB = reqValueIn;
  //jsonFieldIn = reqValueIn

  // we don't need to change teh jsonFieldIN, we just need return the proper value to set the new main data json

  if (reqValueIn && reqValueIn !== "") {
    if (reqValueIn === -1) {
      whatWeSaveToJsonAndDB = null;
    } else {
      whatWeSaveToJsonAndDB = reqValueIn;
    }
  } else {
    whatWeSaveToJsonAndDB = null;
  }
  // return jsonFieldIn;
  //test - console.log("json" + jsonFieldIn);
  //test - console.log("req" + reqValueIn);
  //test - console.log("save" + whatWeSaveToJsonAndDB);
  return whatWeSaveToJsonAndDB;
}
