import Header from "../Common/Header";
import {  useState } from "react";

import InfoCardEdit from "./InfoCardEdit";
import InfoCard from "../Search/InfoCard";
import { useNavigate, createSearchParams } from "react-router-dom";

import { Menu as MenuIcon, Add as PlusIcon, Delete as TrashIcon } from "@mui/icons-material";
import { Tabs } from "../Common/Tabs";
import { InputComponent } from "../Common/InputComponent";

import axios from "axios";
import { Edit, Save } from "@mui/icons-material"
//import GoToPropertyPage from "../Common/Navigation";

const integerFields = [
  "TotalSqFt",
  // "AssessedLandValue",
  // "AssessedBuildingValue",
  // "AssessedTotalValue",
  "LotSqFt",
  "TermAvailable",
  "GradeDoors",
  "DockDoors",
  "Sinks",
  "Hoods",
  "OfficeCeilingHeight",
  "WarehouseShellClearHeight",
  "CurrentCapacity",
  "HypotheticalCapacity",
  "ColumnSpacing",
  "LabCeilingHeight",
];

const currencyFields = [
  "AssessedLandValue",
  "AssessedBuildingValue",
  "AssessedTotalValue",
  "LastMarketSalePrice",
  // "LandValue"

];

const commaFields = ["TotalSqFt",
  "LotSqFt",];

const decimalFields = [
  "Acres",
] 
const percentFields = [

  "PercentOffice",
  "PercentLab",
  "PercentWarehouse",
  "PercentRaisedFloor",
  "LoadFactor",
  "ContractedEscalations",
];

const textAreaFields = ["Notes"];

const dateFields = [
  "LastMarketSaleDate",
  "DateListed",
  "DateAvailable",
  "DateConstructionComplete",
  "DateOccupancy",
  "ExecutionDate",
  "CommencementDate",
];

const optionFields = [
  {
    field: "PropertyType",
    options: [
      "Life Science",
      "Life Science or Industrial",
      "Life Science or Office",
      "Life Science, R&D, or Office",
      "Life Science, R&D, or Industrial",
      "GMP",
      "Industrial",
      "R&D",
      "R&D or Office",
      "Office",
      "Retail",
      "Other",
    ],
  },
  {
    field: "NewDealOrRenewal",
    options: ["New Deal", "Renewal", "Renewal Downsize", "Renewal Expansion"],
  },
  {
    field: "Furniture",
    options: ["None", "Partial", "Plug and Play", "New", "Available", "Other"],
  },
  {
    field: "FreightLegal",
    options: ["Yes", "No", "Limited", "Potential", "Other"],
  },
  {
    field: "ExposedCeilings",
    options: ["None", "Partial", "Yes", "Potential", "Other"],
  },
  {
    field: "Condition",
    options: [
      "Existing Lab",
      "Existing Office",
      "Fully Built",
      "Shell",
      "Under Construction",
      "Planned",
      "Other",
    ],
  },
  {
    field: "Status",
    options: [
      "Available",
      "LOI Activity",
      "Encumbered",
      "LOI Signed",
      "Lease Out",
      "Lease Comp",
      "Off Market",
      "Other",
    ],
  },
  {
    field: "SubleaseOrDirect",
    options: ["Sublease", "Direct", "Sublease and Direct", "Other"],
  },
  {
    field: "RentStructure",
    options: ["NNN", "IG", "FSG", "Other"],
  },
  {
    field: "JanitorialResponsibility",
    options: ["Landlord", "Tenant", "Sublandlord", "Subtenant", "Other"],
  },
  {
    field: "MaintenanceResponsibility",
    options: ["Landlord", "Tenant", "Sublandlord", "Subtenant", "Other"],
  },
  {
    field: "LabEquipment",
    options: ["Freezers, Biosafety Cabinets, Glassware", "None", "Other"],
  },
  {
    field: "ConstructionStage",
    options: ["Planned", "Under Construction", "Complete"],
  },
  {
    field: "AVEquipment",
    options: ["TVs, phones, montiors", "None", "Other"],
  },
  {
    field: "Flooring",
    options: [
      "Carpet in Office, VCT in Lab",
      "Polished Concrete",
      "Carpet",
      "None - Concrete",
      "Other",
    ],
  },
  {
    field: "ServerRoom",
    options: [
      "Yes with dedicated HVAC",
      "Closet no dedicated HVAC",
      "Data Center with Raised Floor",
      "None",
      "Potential",
      "Shared",
      "Other",
    ],
  },
  {
    field: "SpecializedAirHandling",
    options: [
      "Single Pass 7+ airchanges per hour",
      "Recirculated Air dedicated to Lab",
      "Recirculated Air",
      "None",
      "Potential",
      "Other",
    ],
  },
  {
    field: "BackupPower",
    options: [
      "Building Shared Generator",
      "Dedicated",
      "UPS",
      "Generator",
      "Potential",
      "Planned",
      "Other",
    ],
  },
  {
    field: "220Outlets",
    options: [
      "In Benches",
      "Walls Only",
      "Yes",
      "No",
      "Potential",
      "Planned",
      "Other",
    ],
  },
  {
    field: "ChemicalStorage",
    options: [
      "In Suite",
      "Basement",
      "Yes",
      "No",
      "Potential",
      "Planned",
      "Other",
    ],
  },
  {
    field: "CompressedAir",
    options: ["Centralized", "Yes", "No", "Potential", "Planned", "Other"],
  },
];
const tabFields = {
  "General Info": [
    "APN",
    "APN2",
    "Acres",
    "StreetNumber",
    "StreetName",
    "City",
    "State",
    "Zip",
    "FAR",
    "Country",
    "County",
    "Notes",
    "Owner1FirstName",
    "Owner1LastName",
    "Owner2FirstName",
    "Owner2LastName",
    // "MailingStreet",
    "MailingCity",
    "MailingState",
    // "MailingStreetType",
    "MailingUnitNumber",
    // "MailingHouseNumber",
    "MailingStreetName",
    "MailingStreetNumber",
    "MailingCity",
    "MailingState",
    "MailingZip",
    "AssessedLandValue",
    "AssessedBuildingValue",
    "AssessedTotalValue",
    "LandUse",
    "Block",
    "SiteZip",
    "SiteCity",
    "LotAcres",
    "FIPSCode",
    "SiteState",
    "YearBuilt",
    // "LandValue",
    "TaxAmount",
    "SiteNumber",
    "SiteStreet",
    "SiteCounty",
    "MailingZip",
    "OTSaleDate",
    "OTDeedType",
    "PropertyID",
    "LandUseType",

    "Subdivision",
    "CensusTract",
    "TractNumber",
    "LotSqFt",
    "ParkingSqFt",
    "ParkingDesc",
    "OTSalePrice",

    "TotalStories",
    "ParkingCount",

    "TotalSqFt",
    "ExemptionType",
    "OTDocument",
    "PriorSaleDate",
    "SiteStreetType",
    "NOTNOD_Flag",
    "NOTNOD_Type",
    "LotIdentifier1",
    "AssessmentYear",
    "PriorSalePrice",
    "SiteZipPlusFour",
    // "CommofUnits",
    // "ResFullBaths",
    // "ResHalfBaths",
    "OTRecordingDate",
    "MailingDirection",
    "LegalDescription",
    "ImprovementValue",
    "SiteStreetAddress",
    "SiteApartmentUnit",

    "TotalAssessedValue",
    "LastMarketSaleDate",
    "LastMarketSalePrice",
    "MailingStreetAddress",
    "MailingPostDirection",
    "LastMarketSaleDoc",
    "SiteStreetPreDirection",
    "PriorSaleRecordingDate",
    "SiteStreetPostDirection",
    "LastMarketSaleRecordingDate",
    "LegalBookPage_SUBDIV_PLAT_BOOK",
    "LegalBookPage_SUBDIV_PLAT_PAGE",
    "DelinquencyDocumentRecorded",
  ],
};

const tabs = [
  {
    name: "General Info",
    href: "#",
    icon: MenuIcon,
    current: false,
  },
];

function updateJson(jsonFieldIn, reqValueIn) {
  if (reqValueIn !== "") {
    if (reqValueIn === -1) {
      jsonFieldIn = null;
    } else {
      jsonFieldIn = reqValueIn;
    }
  }
  // return jsonFieldIn;
  return reqValueIn;
}

function editClick(
  setEditModeFunctionIn,
  setEditButtonTextFunctionIn,
  editMode,
  dataIn,
  setData
) {
  if (editMode === "Edit") {
    setEditModeFunctionIn("View");
    setEditButtonTextFunctionIn(<Edit sx={{width: 18, height: 18}}/>);

    dataIn.APN = updateJson(dataIn.APN, document.getElementById("APN").value);
    dataIn.APN2 = updateJson(
      dataIn.APN2,
      document.getElementById("APN2").value
    );
    dataIn.Notes = updateJson(
      dataIn.Notes,
      document.getElementById("Notes").value
    );
    dataIn.Acres = updateJson(
      dataIn.Acres,
      document.getElementById("Acres").value
    );
    dataIn.StreetNumber = updateJson(
      dataIn.StreetNumber,
      document.getElementById("StreetNumber").value
    );
    dataIn.StreetName = updateJson(
      dataIn.StreetName,
      document.getElementById("StreetName").value
    );
    dataIn.City = updateJson(
      dataIn.City,
      document.getElementById("City").value
    );
    dataIn.State = updateJson(
      dataIn.State,
      document.getElementById("State").value
    );
    dataIn.Zip = updateJson(dataIn.Zip, document.getElementById("Zip").value);
    dataIn.Country = updateJson(
      dataIn.Country,
      document.getElementById("Country").value
    );
    dataIn.FAR = updateJson(dataIn.FAR, document.getElementById("FAR").value);
    dataIn.County = updateJson(
      dataIn.County,
      document.getElementById("County").value
    );

    setData(dataIn);
    updateParcel(dataIn);
  } else {
    setEditModeFunctionIn("Edit");
    setEditButtonTextFunctionIn(<Save/>);
  }
}

async function updateParcel(data) {
  const qs = Object.keys(data) // turn the parameters into a get query string parameters style
    .map((key) =>
      key !== "images" && key !== "building" && key !== "contacts"
        ? `${key}=${encodeURIComponent(data[key])}`
        : ""
    )
    .join("&");

  //test - console.log(process.env.REACT_APP_DATABASE + "api/updateParcel?" + qs);
  //   const {Base64} = require('js-base64');
  //   var dataObjectBase64 = Base64.encode(qs);
  ////test - console.log(dataObjectBase64    );
  //   var resultInfo = await fetch(process.env.REACT_APP_DATABASE + "api/updateParcel?" + qs);
  var resultInfo = await fetch(
    process.env.REACT_APP_DATABASE + "api/updateParcel?" + qs
  );

  var var2 = await resultInfo.json();
  //test - console.log("results = " + var2);
}

export default function ParcelDetail({
  Mode,
  parcelSearchResults,

  user,
  guest,
}) {
  const [tabHighLight, setTabHighLight] = useState("General Info");
  var nfObject = new Intl.NumberFormat("en-US");


  var nfObjectPercent = new Intl.NumberFormat("en-US", { style: "percent" });
  const [dataIn, setData] = useState(parcelSearchResults);
  //  const [ peopleIn, setPeopleIn ] = useState(parcelSearchResults.contacts.sort((a,b)=> a.avail_contact.sortOrder - b.avail_contact.sortOrder))
  const [editMode, setEditMode] = useState(Mode || "View");
  const [editButtonText, setEditButtonText] = useState(<Edit sx={{width: 18, height: 18}}/>);
  const navigate = useNavigate();
  var entries = [];
  for (const [key, value] of Object.entries(dataIn)) {
    entries.push({ fieldName: `${key}`, fieldValue: value });
  }
  var contactId = user.contact.id;
  var missingInfo = [];
  var icon = null;
  var askRate = "Please Inquire";
  var initialPics = [];

  if (!Mode) {
    icon = "parcel";
  } else {
    icon = Mode;
  }
  //test - console.log("icon: " + icon);

  return (
    <div>
      <nav className="sticky z-50 top-0">
        <Header
          key="1"
          placeholderLocation={
            dataIn.StreetNumber + " " + dataIn.StreetName + " APN " + dataIn.APN
          }
          icon={icon}
          user={user}
        />
      </nav>
      <div className="px-4 py-5 sm:px-6"></div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 sm:px-6">
          <div className="py-4">
            <h1 className="text-xl font-semibold text-gray-900">
              Parcel Information
            </h1>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {dataIn.StreetNumber} {dataIn.StreetName}, {dataIn.City}{" "}
            </p>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {dataIn.APN !== 0 ? " Parcel " + dataIn.APN : "Please Add Parcel"}
            </p>
          </div>
        </div>
        <div className="md:flex md:items-center md:justify-between  border-gray-500 px-6">
          <div className="  ">
            <h1 className="text-xl font-semibold text-gray-900">
              Parcel Details
            </h1>
          </div>
          <div className="px-0 py-6">
            {/* {user.contact.id === 1 ? ( */}
            <button
              onClick={() => {
                if (user.contact.guest === true || user.contact.guest === 'true') {
                  alert("Please login or sign up to make edits.");
                } else {
                  editClick(
                    setEditMode,
                    setEditButtonText,
                    editMode,
                    dataIn,
                    setData
                  );
                }
              }}
              type="button"
              className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              {editButtonText}
            </button>
            {/* ) : (
              ""
            )} */}
          </div>
        </div>

        <div>
          <Tabs
            tabHighLight={tabHighLight}
            setTabHighLight={setTabHighLight}
            tabs={tabs}
            dataIn={dataIn}
            setData={setData}
            update={updateParcel}
            editMode={editMode}
            type="Parcel"
            contactId={contactId}
          />
        </div>
        <div className="  py-2 sm:px-6 ">
          <dl className="grid grid-cols-1 bg-gray-100 rounded-xl px-4 py-4 gap-x-4 gap-y-8 sm:grid-cols-2">
            {entries.map((entry) => {
              var opinions = [];
              // const [opinions, setOptions] = useState(
              //   dataIn.generic_journals.filter((journal) => {
              //     if ((journal.Field = entry.fieldName)) return true;
              //   })
              // );

              if (
                [
                  "id",
                  "markedDelete",
                  "Longitude",
                  "Latitude", 
                  "createdAt",
                  "updatedAt",
                  "campus_id",
                  "avail_groups",
                  "contacts",
                  "buildings",
                  "websites",
                  "companies",
                  "parcels",
                  "campus",
                  "images",
                ].indexOf(entry.fieldName) === -1
              ) {
                if (tabFields[tabHighLight].indexOf(entry.fieldName) !== -1) {
                  if (!entry.fieldValue) {
                    missingInfo.push(entry.fieldName);
                  }

                  if (editMode === "View") {
                    return (
                      <div
                        className={
                          !entry.fieldValue &&
                            entry.fieldName !== "Expenses" &&
                            entry.fieldName !== "AskRate" &&
                            entry.fieldName !== "AskTI"
                            ? //   ||
                            // entry.fieldName === "StreetName" ||
                            // entry.fieldName === "StreetNumber"
                            "hidden"
                            : "col-span-2 sm:col-span-1 "
                        }
                      >
                        <div className="flex">
                          <div className="w-full flex items-start">
                            <dt className="text-sm mt-1 font-medium text-gray-500 px-2 w-2-third sm:w-half">
                              {entry.fieldName === "TermAvailable"
                                ? entry.fieldName
                                  .replace(/([A-Z])/g, " $1")
                                  .trim() + " (Months)"
                                : entry.fieldName === "AskRate"
                                  ? entry.fieldName
                                    .replace(/([A-Z])/g, " $1")
                                    .trim() + " ($/SF/Month)"
                                  : entry.fieldName === "AskTI"
                                    ? entry.fieldName
                                      .replace(/([A-Z])/g, " $1")
                                      .trim() + " (Allowance $/SF from LL)"
                                    : entry.fieldName
                                      .replace(/([A-Z])/g, " $1")
                                      .trim()}{" "}
                              {/* <div className="w-10 h-10"> */}
                              {/* </div> */}
                            </dt>
                            <dd
                              className={
                                textAreaFields.indexOf(entry.fieldName) > -1
                                  ? "mt-1 font-semibold text-sm text-gray-900 whitespace-pre-line w-full"
                                  : "mt-1 font-semibold text-sm text-gray-900 w-full"
                              }
                            >
                              {(commaFields.indexOf(entry.fieldName) === -1) &
                                (dateFields.indexOf(entry.fieldName) === -1)
                                ? entry.fieldValue && entry.fieldValue !== ""
                                  ? entry.fieldName === "Floor" &&
                                    entry.fieldValue === -1
                                    ? "Entire Building"
                                    : currencyFields.indexOf(
                                      entry.fieldName
                                    ) === -1
                                      ? decimalFields.indexOf(entry.fieldName) ===
                                        -1
                                        ? entry.fieldValue
                                        : decimalConvert(entry.fieldValue)
                                      : moneyConvert(entry.fieldValue)
                                  : ""
                                : commaFields.indexOf(entry.fieldName) >= 0
                                  ? entry.fieldValue && entry.fieldValue !== ""
                                    ? entry.fieldValue
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    : ""
                                  : entry.fieldValue
                                    ? new Date(
                                      entry.fieldValue
                                    ).toLocaleDateString()
                                    : ""}

                              {/* <OpinionButton
                                props={{
                                  contactId: contactId,
                                  entity: "parcelId",
                                  entityId: dataIn.id,
                                  groupsIn: ApexInformation.groups,
                                  fieldName: entry.fieldName,
                                  setData: setData,
                                  setRerender: setRerender,
                                  // setListReset: setListReset
                                  // setShowWait: setShowWait
                                }}
                              /> */}
                            </dd>
                            <dd className="mt-1 text-sm text-gray-900 px-2 w-half flex justify-center">
                              {/* {dataIn.generic_journals.map(
                                (journal) => {
                                  ////test - console.log(journal);
                                  if (
                                    journal.Field === entry.fieldName &&
                                    (journal.OpinionValue || journal.Message)
                                  ) {
                                    opinions.push(journal);
                                  }
                                }
                              )} */}

                              {/* {opinions.length > 0 ? (
                                <div className="text-sm text-gray-500 flex flex-col w-full items-center">
                                  <div className=" px-2 w-full">
                                    <OpinionList
                                      opinions={opinions}
                                      contactId={contactId}
                                      entityId={dataIn.id}
                                      setData={setData}
                                      entity={"availId"}
                                      // listReset={listReset}
                                      // setListReset={setListReset}
                                    />
                                  </div>
                                </div>
                              ) : (
                                ""
                              )} */}
                            </dd>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className="col-span-2 sm:col-span-1 ">
                        <dt className="text-sm font-medium text-gray-500">
                          {entry.fieldName === "TermAvailable"
                            ? entry.fieldName
                              .replace(/([A-Z])/g, " $1")
                              .trim() + " (Months)"
                            : entry.fieldName === "AskRate"
                              ? entry.fieldName
                                .replace(/([A-Z])/g, " $1")
                                .trim() + " ($/SF/Month)"
                              : entry.fieldName === "AskTI"
                                ? entry.fieldName
                                  .replace(/([A-Z])/g, " $1")
                                  .trim() + " (Allowance $/SF from LL)"
                                : entry.fieldName
                                  .replace(/([A-Z])/g, " $1")
                                  .trim()}{" "}
                        </dt>
                        <dd
                          className={
                            textAreaFields.indexOf(entry.fieldName) > -1
                              ? "mt-1 font-semibold text-sm text-gray-900 whitespace-pre-line w-full"
                              : "mt-1 font-semibold text-sm text-gray-900 w-full"
                          }
                        >
                          {textAreaFields.indexOf(entry.fieldName) > -1 ? (
                            <InputComponent
                              inputType={"textarea"}
                              defaultShow={""}
                              id={dataIn.id}
                              name={entry.fieldName}
                              valueIn={entry.fieldValue}
                            />
                          ) : integerFields.indexOf(entry.fieldName) === -1 ? (
                            <>
                              {optionFields.filter(function (el) {
                                if (el.field === entry.fieldName) {
                                  return true;
                                }
                              }).length > 0 ? (
                                <InputComponent
                                  inputType={"simpleselect"}
                                  defaultShow={entry.fieldValue}
                                  id={dataIn.id}
                                  name={entry.fieldName}
                                  valueIn={entry.fieldValue}
                                  options={
                                    optionFields.filter(function (el) {
                                      if (el.field === entry.fieldName) {
                                        //test - console.log(el.options);
                                        return true;
                                      }
                                    })[0].options
                                  }
                                />
                              ) : dateFields.indexOf(entry.fieldName) === -1 ? (
                                currencyFields.indexOf(entry.fieldName) ===
                                  -1 ? (
                                  percentFields.indexOf(entry.fieldName) ===
                                    -1 ? (
                                    commaFields.indexOf(entry.fieldName) ===
                                      -1 ? (
                                      <InputComponent
                                        inputType={"text"}
                                        defaultShow={entry.fieldValue}
                                        id={dataIn.id}
                                        name={entry.fieldName}
                                        valueIn={entry.fieldValue}
                                      />
                                    ) : (
                                      <InputComponent
                                        inputType={"number"}
                                        defaultShow={entry.fieldValue}
                                        id={dataIn.id}
                                        name={entry.fieldName}
                                        valueIn={entry.fieldValue}
                                      />
                                    )
                                  ) : (
                                    <InputComponent
                                      inputType={"decimal"}
                                      defaultShow={entry.fieldValue}
                                      id={dataIn.id}
                                      name={entry.fieldName}
                                      valueIn={entry.fieldValue}
                                    />
                                  )
                                ) : (
                                  <InputComponent
                                    inputType={"currency"}
                                    defaultShow={entry.fieldValue}
                                    id={dataIn.id}
                                    name={entry.fieldName}
                                    valueIn={entry.fieldValue}
                                  />
                                )
                              ) : (
                                <InputComponent
                                  inputType={"date"}
                                  defaultShow={
                                    entry.fieldValue
                                      ? new Date(entry.fieldValue)
                                      : null
                                  }
                                  id={dataIn.id}
                                  name={entry.fieldName}
                                  valueIn={entry.fieldValue}
                                />
                              )}
                            </>
                          ) : (
                            <InputComponent
                              inputType={"number"}
                              defaultShow={entry.fieldValue}
                              id={dataIn.id}
                              name={entry.fieldName}
                              valueIn={entry.fieldValue}
                            />
                          )}{" "}
                        </dd>
                      </div>
                    );
                  }
                }
              }
            })}
            {/* {missingInfo.length > 1 ? (
              <>
                <div className="col-span-2">
                  <h2 className="text-medium">Missing Information:</h2>
                  <h2 className="text-xs">
                    {missingInfo.map((field, index) => {
                      var bar = missingInfo.length >= index + 2 ? " | " : " ";
                      return field.replace(/([A-Z])/g, " $1").trim() + bar;
                    })}
                  </h2>
                </div>

                {missingInfo.length >= 1 ? (
                  <button
                    type="button"
                    className="ml-3 inline-flex justify-center w-20 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    onClick={() => {
                      if (user.contact.guest === true || user.contact.guest === 'true') {
                        alert("Please login or sign up to make edits.");
                      } else {
                        editClick(
                          setEditMode,
                          setEditButtonText,
                          editMode,
                          dataIn,
                          setData,
                          contactId
                        );
                      }
                    }}
                  >
                    {editButtonText}
                  </button>
                ) : (
                  ""
                )}
              </>
            ) : (
              <></>
            )} */}

            {/* Missing Info: {missingInfo.toString()} */}
          </dl>
        </div>
        {/* {editMode !== "Edit" ? (
          ""
        ) : ( */}
        <div className="flex px-6 py-2 w-full justify-end">
          {" "}
          <button
            className="inline-flex s-20  items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
            onClick={() => {
              if (user.contact.guest === true || user.contact.guest === 'true') {
                alert("Please login or sign up to make edits.");
              } else {
                addBuilding(dataIn, dataIn.id, user.contact.id, setData);
              }
            }}
          >
            <div className="cursor-pointer text-white  md:inline ">
              <div className=" flex items-center">
                <PlusIcon className="w-4 h-4" />{" "}
                <div className="px-2">Add Building </div>
              </div>
            </div>
          </button>
        </div>
        {/* )} */}
        {editMode !== "Edit"
          ? dataIn.buildings.map((property) => {
            return (
              <div>
                <InfoCard
                  property={property}
                  onClick={GoToPropertyPage}
                  onHover={hoverOverInfocard}
                  removeButton={false}
                  

                />
              </div>
            );
          })
          : dataIn.buildings.map((property) => {
            return (
              <div className="flex">
                <div className="w-3quarters">
                  <InfoCardEdit
                    property={property}
                    // onClick={GoToPropertyPage}
                    onHover={hoverOverInfocard}
                    setDataIn={setData}
                    contactId={contactId}
                  />
                </div>
                <div className="w-quarter flex px-10 justify-end items-center ">
                  <button
                    className="h-10"
                    onClick={() => {
                      deleteBuilding(property.id);
                    }}
                  >
                    <TrashIcon
                      className="h-10 w-10 text-red-600 shadow-sm hover:text-red-700"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            );
          })}
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        {/* <ContactList peopleIn = {peopleIn} dataIn = {dataIn} setPeopleIn = {setPeopleIn} peopleCombo = {people}/>  */}
      </div>
    </div>
  );

  function GoToPropertyPage(id) {
    //This has to be here because navigate has to be created in this component

    const gotoPage = "/property";
    const query = "id=" + id;

    function goto(page, query, navigate) {
      navigate({
        pathname: page,
        search: `?${createSearchParams(query)}`,
      });
      window.location.reload();
    }

    goto(gotoPage, query, navigate);
  }
}

function hoverOverInfocard(property) {
  //test - console.log(property.id);
}

const addBuilding = async (dataIn, id, contactId, setData) => {
  var abridgedDataIn = { ...dataIn };
  delete abridgedDataIn.buildings;

  const qs = encodeURI(
    Object.keys(abridgedDataIn) // turn the parameters into a get query string parameters style
      .map((key) => `${key}=${abridgedDataIn[key]}`)
      .join("&")
  );

 
  var geoCode = await fetch(
    "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
    JSON.stringify(
      abridgedDataIn.StreetNumber +
      "%20" +
      abridgedDataIn.StreetName +
      "%20" +
      abridgedDataIn.City
    ) +
    ".json?proximity=ip&types=place%2Cpostcode%2Caddress&access_token=" + process.env.REACT_APP_MAPBOX
  );
  var mycode = await geoCode.json();
  //test - console.log(mycode);
  var Longitude = (mycode?.features?.length > 0) && mycode?.features[0]?.center[0];
  var Latitude = (mycode?.features?.length > 0) && mycode?.features[0]?.center[1];
  abridgedDataIn.Longitude = Longitude;
  abridgedDataIn.Latitude = Latitude;

  
  var reload = await axios.get(
    process.env.REACT_APP_DATABASE +
    "api/getParcel?id=" +
    id +
    "&contactId=" +
    contactId
  ).catch((error) => { console.log(error) })
  var reloadJson = await reload.data;
  setData(reloadJson);
  // //test - console.log(mycheck);
 
  await axios.post(
    process.env.REACT_APP_DATABASE + "api/addBuildingPost?",
    {
      abridgedDataIn: abridgedDataIn,
      Latitude: Latitude,
      Longitude: Longitude,
      contactId: contactId,
    }
  );
}



const deleteBuilding = async (id) => {
  //var newDataIn = [...dataIn]
  //
  // setDataIn(arrayRemove(newDataIn, index))

  var resultInfo = await fetch(
    process.env.REACT_APP_DATABASE + "api/deleteBuilding?id=" + id
  );
  window.location.reload();

  //return arrayRemove(formattedArray, index)
};

function moneyConvert(stringIn) {
  var nfObjectRate = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });


  stringIn = stringIn.replaceAll("$", "")
  stringIn = stringIn.replaceAll(",", "")

  return nfObjectRate.format(stringIn)
}

function decimalConvert(stringIn) {
  var nfObjectDecimal = new Intl.NumberFormat("en-US", {
    style: "decimal"
  })
  if (isNaN(stringIn)) {


    stringIn = stringIn.replaceAll("$", "")
    stringIn = stringIn.replaceAll(",", "")

    return nfObjectDecimal.format(stringIn)
  }
  else {
    return nfObjectDecimal.format(stringIn)
  }
}