import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createCookie } from '../Common/Cookies';

const OptInDialog = ({ open, onClose }) => {
    
    const [optInChecked, setOptInChecked] = useState(false);

    const handleOptInChange = () => {
        setOptInChecked(!optInChecked);
       
    };

    const handleDialogClose = () => {
        onClose(optInChecked); // Pass the opt-in status to the parent component
       
    };

    return (
        <Dialog open={open} onClose={handleDialogClose}>
            <DialogTitle>Opt-In for SMS</DialogTitle>
            <DialogContent>
                <p>
                    By checking the box below, you are giving permission to opt-in for SMS notifications to create or log into a Cubic Search account.
                </p>
                <FormControlLabel
                    control={<Checkbox checked={optInChecked} onChange={handleOptInChange} />}
                    label="I agree to receive SMS notifications."
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={
                    () => {
                        createCookie("OptIn", 'true')
                        handleDialogClose()
                    }}
                    variant="contained" color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default OptInDialog;
