import Iframely from "../../Images/IFramely";
import { useState } from "react";
import { Share } from "@mui/icons-material";

import { mobileAndTabletCheck } from "../../Search/Deck";

export default function WebsiteCard({ website }) {
  const [open, setOpen] = useState(true);
  const [buttonText, setButtonText] = useState("Hide");
  var mysrcPost =
    website.src.includes("http") || website.src.includes("https://")
      ? website.src
      : "https://" + website.src;
  var url = mysrcPost.replace(
    "cubesearch.sfo3.digitaloceanspaces.com",
    "images.cubicsearch.com"
  );
  url = url.replace(
    "sfo3.digitaloceanspaces.com/cubesearch/",
    "https://images.cubicsearch.com/"
  );

  function changeState() {
    if (open) {
      setOpen(false);
      setButtonText("Show");
    } else {
      setOpen(true);
      setButtonText("Hide");
    }
  }



  return (
    <>
      <div className="flex flex-col w-full">
        <div className="flex items-center">
          <div>
            <button
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
              onClick={changeState}
            >
              {buttonText}
            </button>
          </div>
          <div className="px-2">
            <button
              data-tip
              data-for="openTip"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
              onClick={() => {
                navigator.clipboard.writeText(url);
                alert("Link copied to clipboard!");
              }}
            >
              <Share className=" h-5 w-6" />
            </button>
     
          </div>
          <div className="w-half">
            <h1 className="px-2 w-full text-xl font-semibold text-gray-900">
              {website.Description}
            </h1>
          </div>
        </div>

        <div className="w-full max-h-mostscreen py-2 px-2 overflow-y-scroll">
          {/* <div className="w-full"> */}
          {open ? (
            // <div className="w-full">
            <>
              {/* <Iframely url={url} description={website.Description} /> */}
              {mobileAndTabletCheck() ? (
                <Iframely url={url} description={website.Description} />
              ) : url.indexOf(".pdf") > 0 &&
                url.indexOf("images.cubicsearch.com") > 0 ? (
                <iframe className="w-full min-h-most-screen" src={url}></iframe>
              ) : (
                <div>
                  <object
                    className="w-full min-h-half-screen"
                    type="text/html"
                    data={url}
                  >
                    {" "}
                    <Iframely url={url} description={website.Description} />
                  </object>
                </div>
              )}
              {/* <iframe src={mysrc} title = {website.Description} width="100%" height="600" allowFullScreen="true"></iframe>  */}
              {/* : ""</div>} */}
            </> // </div>
          ) : (
            ""
          )}
          {/* </div> */}
        </div>
      </div>
    </>
  );
}
