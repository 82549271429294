
import * as React from "react";
import { List, arrayMove } from "react-movable";
import { SizableDemoCarousel } from "../Images/Carousel";


import Folder from "@mui/icons-material/Folder";
import Delete from "@mui/icons-material/Delete";
import { mobileAndTabletCheck } from "../Search/Deck";
import { Tooltip } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
const addMe = async (dataIn, setDataIn, buildingId, contactId, availOrComp) => {
  var fetchLocationAdd =
    process.env.REACT_APP_DATABASE +
    "api/addAvailable?id=" +
    buildingId +
    "&contactId=" +
    contactId+
    "&availOrComp=" + availOrComp
  //test - console.log(fetchLocationAdd);
  const resultInfo = await fetch(fetchLocationAdd);
  var fetchLocationGet = process.env.REACT_APP_DATABASE + "api/getBuilding?id=";
  const searchResults = await fetch(
    fetchLocationGet + buildingId + "&contactId=" + contactId
  );
  const data = await searchResults.json();

  var myJson = data.avails.filter((availIn) => {
    if (availOrComp === "Comp") {
      if (availIn.Status === "Lease Comp") {
        return true;
      } else return false;
    } else
    {
      if (availIn.Status !== "Lease Comp") {
        return true;
      } else return false;
    }
  });

 // var myJson = data.avails; // search results come in an arra
  var sort = orderAvails(myJson, setDataIn);
};

export default function BuildingAvailableEditTable({
  dataIn,
  setDataIn,
  buildingId,
  contactId,
  highestPermission,
  availOrComp,
}) {
  //test - console.log("rebuilding Edit Table");
  //test - console.log(dataIn);
  //setItems(mapArray(dataIn))

  const removeFromBothArrays = (
    index,
    newAvailsIn,
    availId,
    groupId,
    availGroupsArrayIndex
  ) => {
    // contactId is not coming through
    var newDataIn = [...dataIn];

    if (newDataIn.length === 0) {
      // if you have just added the contact for some reason it will show up here with no visibility to the peopleIn parameter
      newDataIn = [...newAvailsIn];
    }

    if (newDataIn.length > 0) {
      // database call
      deleteBuildingAvailableRelationship(availId, groupId, contactId);

      newDataIn[index].avail_groups = arrayRemove(
        newDataIn[index].avail_groups,
        availGroupsArrayIndex
      );
      if (newDataIn[index].avail_groups.length === 0) {
        newDataIn = arrayRemove(newDataIn, index);
      }
      // setPeopleIn(arrayRemove(newDataIn, index));
      setDataIn(newDataIn);
      // return arrayRemove(items, index);
      //   setItems(mapArray(newDataIn, removeFromBothArrays));
    }
  };

  // const [items, setItems] = React.useState(
  //   mapArray(availsIn, removeFromBothArrays)
  // );

  const moveBothArrays = (formattedArray, oldIndex, newIndex) => {
    //setItems(arrayMove(formattedArray, oldIndex, newIndex))
    setDataIn(arrayMove(dataIn, oldIndex, newIndex));
    orderAvails(arrayMove(dataIn, oldIndex, newIndex), setDataIn);
  };

  function mapArray(availIn, setItems) {
    var myArray = [];
    var nfObject = new Intl.NumberFormat("en-US");
    var nfObjectRate = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    var nfObjectDate = new Intl.DateTimeFormat("en-US");
    //
    availIn.map((available, index) => {
      var AVHighestPermission = "View";
      myArray.push(
        <div key={available.id}>
          <div className="flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <div className="min-w-full ">
                    <div>
                      <table className="min-w-full">
                        <thead>
                          <tr className="text-left ">
                            <th className="w-60"></th>
                            <th className="w-20"></th>
                            <th className="w-20"></th>
                            <th className="w-20"></th>
                            <th className="w-40"></th>
                            <th className="w-20"></th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            className="text-center text-xs hover:cursor-pointer"
                            // onClick={async () => {
                            //   //const {Base64} = require('js-base64');
                            //   // var dataObjectBase64 = Base64.encode(available.id.toString());
                            //   window.open("available?id=" + available.id, mobileAndTabletCheck()?"_self": null);
                            //   //    await Alert('Alert text', 'Alert title');
                            // }}
                          >
                            <th>
                              <div className="w-60">
                                <div className="w-full">
                                  <SizableDemoCarousel
                                    key={available.id}
                                    images={available.images}
                                  />
                                </div>
                                <div
                                  className="w-full flex items-center"
                                  onClick={async () => {
                                    //const {Base64} = require('js-base64');
                                    //var dataObjectBase64 = Base64.encode(available.id.toString());
                                    window.open("available?id=" + available.id, mobileAndTabletCheck()?"_self": null);
                                    //    await Alert('Alert text', 'Alert title');
                                  }}
                                >
                                  <div className="px-6">
                                    <div>
                                      {availOrComp === "Comp"? available.Tenant: ""}
                                    </div>
                                    <div className="font-medium text-gray-900">
                                      {available.Floor !== 0
                                        ? available.Floor === -1
                                          ? "Entire Building"
                                          : "Floor " + available.Floor
                                        : "Please Add Floor"}
                                    </div>
                                    <div className="font-medium text-gray-500">
                                      {" "}
                                      {available.Suite !== "0" &&
                                      available.Suite
                                        ? "Suite " + available.Suite
                                        : "Please Add Suite"}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th className="w-20">
                              <div>
                                <div className="font-medium text-gray-900">
                                  {availOrComp === "Comp"? nfObject.format(available.LeasedSpace): nfObject.format(available.SizeMin) +" - "+ nfObject.format(available.SizeMax)} 
                                   {" "} SF
                                </div>
                                <div className="font-medium text-gray-500">
                                  {availOrComp === "Comp"? nfObjectRate.format(available.InitialRent) : 
                                  nfObjectRate.format(available.AskRate)} / SF
                                  / Month
                                </div>
                              </div>
                            </th>
                            <th className="w-20">
                              <div className="font-medium text-gray-900">
                                {availOrComp === "Comp"?  nfObjectDate.format(
                                  new Date(available.ExecutionDate)): nfObjectDate.format(
                                  new Date(available.DateOccupancy)
                                ) || "Contact agent or owner"}
                              </div>
                            </th>
                            <th className="w-20">
                              <div className="font-medium text-gray-900">
                                {available.Condition ||
                                  "Contact agent or owner"}
                              </div>
                            </th>
                            <th className="w-20 z-50">
                              <div className="font-medium text-gray-900 ">
                                {available.avail_groups.map(
                                  (avail_group, groupArrayIndex) => {
                                    
                                    return (
                                      <div
                                        // GroupContactId={avail_group.id}
                                        // GroupId={avail_group.group.id}
                                        // GroupName={avail_group.group.GroupName}
                                        className="w-full"
                                      >
                                        <div className="flex justify-between w-full hover:bg-red-700">
                                          <div className="flex flex-col w-30 py-2 text-xs text-left whitespace-pre-line self-center">
                                            {/* <img src={avail_group.group.images[0].img}/> I'm not getting the images yet from DB  add later*/}
                                            <div>
                                              Group Name :{" "}
                                              {avail_group.group.GroupName}{" "}
                                              {"\n"}
                                              {/* {avail_group.group.id}- */}
                                              Available Rights :{" "}
                                              {avail_group.groupPermission}
                                            </div>
                                            {/* <div>
                                              Group Rights :
                                              {avail_group.group.group_contacts.map(
                                                (group_contact) => {
                                                  if (
                                                    AVHighestPermission ===
                                                      "View" &&
                                                    (group_contact.PermissionLevel ===
                                                      "Lead Edit" ||
                                                      group_contact.PermissionLevel ===
                                                        "Edit")
                                                  ) {
                                                    AVHighestPermission =
                                                      group_contact.PermissionLevel;
                                                  }
                                                  // ;
                                                  if (
                                                    group_contact.contact_id ===
                                                      contactId &&
                                                    group_contact.PermissionLevel !=
                                                      "Data"
                                                  ) {
                                                    return group_contact.PermissionLevel;
                                                  } else {
                                                    return "";
                                                  }
                                                }
                                              )}{" "}
                                            </div> */}
                                          </div>
                                          <div className="px-2 py-2">
                                            {((AVHighestPermission === "Edit" ||
                                              AVHighestPermission ===
                                                "Lead Edit") &&
                                              avail_group.groupId === 1) ||
                                            highestPermission === "Lead Edit" ||
                                            highestPermission === "Edit" ||
                                            contactId === 1 ? (
                                              <button
                                                data-tip
                                                data-for="deleteTip"
                                                onClick={() => {
                                                  // ;
                                                  removeFromBothArrays(
                                                    index,
                                                    dataIn,
                                                    avail_group.availId,
                                                    avail_group.groupId,
                                                    groupArrayIndex
                                                  );
                                                }}
                                                className="z-50 rounded-md border border-transparent bg-red-600 px-2 h-6 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                                              >
                                                <Delete
                                                  className="h-4 w-4 text-white shadow-sm"
                                                  aria-hidden="true"
                                                />
                                                <Tooltip
                                                    title={'Delete'}
                                                    placement='top'
                                                >
                                                  Delete Visibility of Available
                                                </Tooltip>
                                              </button>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </th>
                            <th className=" w-40">
                              <div>
                                {available.contacts
                                  .sort(
                                    (a, b) =>
                                      a.avail_contact.sortOrder -
                                      b.avail_contact.sortOrder
                                  )
                                  .map((contact) => (
                                    <div className="flex items-center py-1">
                                      <div className="h-10 w-10 rounded-full">
                                        <img
                                          className="h-10 w-10 flex-shrink-0 rounded-full object-cover"
                                          src={
                                            contact.images.length > 0
                                              ? contact.images.sort(
                                                  (a, b) =>
                                                    a.sortOrder - b.sortOrder
                                                )[0].img
                                              : "https://images.cubicsearch.com/UserLogo.png"
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <div className="w-30">
                                        <div className="font-medium text-gray-900">
                                          {contact.FirstName} {contact.LastName}
                                        </div>
                                        <div className="text-gray-500">
                                          {contact.office &&
                                          contact.office.company
                                            ? contact.office.company.Name
                                            : "Add Company"}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });

    return myArray;
  }

  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      ></link>
      <div className=" pt-8 pb-2 flex justify-end ">
        <button
          type="button"
          className="inline-flex  self -center items-center content-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
          onClick={() => addMe(dataIn, setDataIn, buildingId, contactId, availOrComp)}
        >
          <i className="fa fa-plus "></i>{" "}
          <div className="px-2">
            {availOrComp === "Comp"
              ? "Add Lease Comp or Occupant"
              : "Add Available Space"}
          </div>
        </button>
      </div>
      {dataIn.length > 1 ? (
        <div className="bg-gray-200 border-solid border-2  border-gray-100 ">
          <div className="px-6">Drag Rows to Change Order</div>
          <div className="px-6">
            <div className="flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden">
                    <div className="min-w-full ">
                      <div>
                        <table className="min-w-full">
                          <thead>
                            <tr className="text-left ">
                              <th className="w-60">Floor and Suite</th>
                              <th className="w-20">Size and Rent</th>
                              <th className="w-20">{availOrComp === "Comp"? "Date Executed" : "Date Occupancy"}</th>
                              <th className="w-20">Condition</th>
                              <th className="w-40">
                                Groups Who Can See Available
                              </th>
                              <th className="w-60">Contacts</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="mt-8 flex flex-col"></div>

      <List
        values={mapArray(dataIn)}
        onChange={({ oldIndex, newIndex }) =>
          moveBothArrays(mapArray(dataIn), oldIndex, newIndex)
        }
        renderList={({ children, props }) => <ul {...props}>{children}</ul>}
        renderItem={({ value, props, index }) => (
          <li {...props}>
            <div className="hover:bg-gray-50 ">
              <div className="flex w-full border">
                <div className="col-span-6 w-full">{value}</div>

                <div class="col-span-1 flex items-center justify-self-end px-4 ">
                 
                  <div>
                    <button
                      data-tip
                      data-for="copyTip"
                      onClick={() => {
                        // ;
                        var myArray = mapArray(dataIn);

                        //test - console.log(
                        //   process.env.REACT_APP_DATABASE +
                        //     "api/duplicateAvailable?contactId=" +
                        //     contactId +
                        //     "&availId=" +
                        //     myArray[index].key
                        // );
                        fetch(
                          process.env.REACT_APP_DATABASE +
                            "api/duplicateAvailable?contactId=" +
                            contactId +
                            "&availId=" +
                            myArray[index].key
                        ).then((response) => {
                          var fetchLocationGet =
                            process.env.REACT_APP_DATABASE +
                            "api/getBuilding?id=";
                          //test - console.log("complete");
                          //test - console.log(
                          //   fetchLocationGet +
                          //     buildingId +
                          //     "&contactId=" +
                          //     contactId
                          // );
                          fetch(
                            fetchLocationGet +
                              buildingId +
                              "&contactId=" +
                              contactId
                          ).then((searchResults) => {
                            searchResults.json().then((data1) => {
                              //test - console.log(data1);
                              var myJson = data1.avails; // search results come in an arra

                              var sort = orderAvails(myJson, setDataIn);
                            });
                          });
                        });

                        // const data = await searchResults.json();
                      }}
                      className=" rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                    >
                      <ContentCopy
                        className="h-4 w-4 text-white shadow-sm"
                        aria-hidden="true"
                      />
               
                    </button>
                  </div>
                  <div className="px-2">
                    <button
                      data-tip
                      data-for="openTip"
                      className=" rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                      onClick={async () => {
                        //test - console.log("I'm here waiting for the base64");

                        //    const {Base64} = require('js-base64');
                        var myArray = mapArray(dataIn);
                        //var dataObjectBase64 = Base64.encode(myArray[index].key.toString());
                        window.open("available?id=" + myArray[index].key, mobileAndTabletCheck()?"_self": null);
                      }}
                    >
                      <Folder
                        className="h-4 w-4 text-white shadow-sm"
                        aria-hidden="true"
                      />
                    </button>
             
                  </div>
                </div>
              </div>
            </div>
          </li>
        )}
      />
    </>
  );
}

export function arrayRemove(array, index) {
  array = array.slice();
  array.splice(index, 1);
  return array;
}

async function deleteBuildingAvailableRelationship(
  availId,
  groupId,
  contactId
) {
  var qs =
    "availId=" + availId + "&groupId=" + groupId + "&contactId=" + contactId;
  var resultInfo = await fetch(
    process.env.REACT_APP_DATABASE + "api/markDeleteAvailGroup?" + qs
  );
  //test - console.log(
  //   process.env.REACT_APP_DATABASE + "api/markDeleteAvailGroup?" + qs
  // );
  // window.location.href = window.location.href;
}

async function orderAvails(avails, setDataIn) {
  //test - console.log("Update the order in the database");
  ////test - console.log(avails)

  avails.map(async (avail, index) => {
    //test - console.log(avail.id);
    var qs = "id=" + avail.id + "&sortOrder=" + index;
    var resultInfo = await fetch(
      process.env.REACT_APP_DATABASE + "api/sortBuildingAvailables?" + qs
    );
    setDataIn(avails);
    return resultInfo;
  });
}

// const removeFromDBandArray = (dataIn, setDataIn, index) => {
//   var newDataIn = [...dataIn];
//   //
//   deleteAvailable(newDataIn[index].id);
//   setDataIn(arrayRemove(newDataIn, index));

//   //return arrayRemove(formattedArray, index)
// };
