import GroupFeedModal from "./GroupFeedModal";
import { useState } from 'react'

function GroupView({GroupData} ) {
  function showFeed() {
    if (!openFeed) {
        setOpenFeed(true)
    }
  }

  const people = [
    {
      name: 'Lindsay Walton',
      imageUrl:
        'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80',
    },
    // More people...
  ]
  const activityItems = [
    { id: 1, person: people[0], client: 'Workcation', commit: '2d89f0c8', environment: 'production', time: '1h' },
    // More items...
  ]
  var nfObject = new Intl.NumberFormat('en-US');
  var nfObjectRate = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
  var nfObjectPercent = new Intl.NumberFormat('en-US', { style: 'percent' });
  const mydate = new Date(GroupData.createdAt);
  const mydateGroup = new Date(GroupData.updatedAt);
  const [openFeed, setOpenFeed] = useState(false);
    return (
       <>
            <div className=" px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Group Name</dt>
                <dd className="mt-1 text-sm text-gray-900">{GroupData.GroupName ? GroupData.GroupName : "Untitled Group"}</dd>
            </div>
            <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Can View Members See Each Other?</dt>
                <dd className="mt-1 text-sm text-gray-900">{GroupData.MembersVisible ? GroupData.MembersVisible : "No"}</dd>
            </div>
           
            <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Group Notes</dt>
                <dd className="mt-1 text-sm text-gray-900">{GroupData.Notes ? GroupData.Notes : ""}</dd>
            </div>


            </dl>
            </div>
            <GroupFeedModal id="RentFeedModal" open = {openFeed} setOpen = {setOpenFeed}/>
            </>
    )

}

export default GroupView;

