import React from 'react';
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Logout } from '@mui/icons-material';

const EmailConfirmation = ({ userEmail, setSubmittedEmail }) => {

    const {logout} = useAuth0()
    const [localEmail, setLocalEmail] = useState(null)
    return (
        <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <Box sx={{ textAlign: 'center', mb: 3 }}>
                <img src="https://images.cubicsearch.com/Logos/Logo%20Transparent.png" alt="Logo" style={{ width: 300 }} />
            </Box>
            <Typography variant="h5" gutterBottom>
                We need to confirm your email address
            </Typography>
            <TextField
                type="email"
                label="Enter your email"
                variant="outlined"
                fullWidth
                value={userEmail}
                onChange={(e) => setLocalEmail(e.target.value)}
                sx={{ mb: 2 }}
            />
            <Button variant="contained" color="primary" onClick={()=>{setSubmittedEmail(localEmail)}} fullWidth>
                Submit
            </Button>

            <Button
                sx={{ margin: 4 }}
                variant="outlined"
                color="primary"
                onClick={() => { logout({ returnTo: window.location.origin }) }}
                startIcon={<Logout />}

            >
                Change Account
            </Button>
        </Container>
    );
};

export default EmailConfirmation;
