import React, { useState } from "react"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import "./styles.css"

export default ({ dataIn, width }) => {
    const [currentSlide, setCurrentSlide] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const [sliderRef, instanceRef] = useKeenSlider({
        initial: 0,
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
    })


    if (!dataIn || !dataIn.Latitude) {
        return (<></>)
    }

    var address = ("https://www.google.com/maps/embed/v1/place?q=" +
        // plusCode +
        dataIn.StreetNumber + " " + dataIn.StreetName + "," + dataIn.City +
        "&key="+ process.env.REACT_APP_GoogleMapsAPIKey +"&zoom=18&maptype=satellite").replaceAll(" ", "+")
    if (!dataIn.StreetNumber || dataIn.StreetNumber.length === 0) {
        address = ("https://www.google.com/maps/embed/v1/view?" +
            // plusCode.replace("+","%2") +
            // property.StreetNumber + " " + property.StreetName + "," + property.City +
            "&key="+ process.env.REACT_APP_GoogleMapsAPIKey +"&zoom=18&maptype=satellite&center=" + dataIn.Latitude + "," + dataIn.Longitude).replaceAll(" ", "+")
    }

    var streetAddress0 = "https://www.google.com/maps/embed/v1/streetview?key="+ process.env.REACT_APP_GoogleMapsAPIKey +"&location=" +
        (dataIn.Latitude) + "," + dataIn.Longitude +
        "&pitch=10&fov=100"
    var streetAddress1 = "https://www.google.com/maps/embed/v1/streetview?key="+ process.env.REACT_APP_GoogleMapsAPIKey +"&location=" +
        (dataIn.Latitude + 0.00025) + "," + dataIn.Longitude +
        "&heading=180&pitch=10&fov=100"
    var streetAddress2 = "https://www.google.com/maps/embed/v1/streetview?key="+ process.env.REACT_APP_GoogleMapsAPIKey +"&location=" +
        (dataIn.Latitude - 0.00025) + "," + dataIn.Longitude +
        "&heading=0&pitch=10&fov=100"
    var streetAddress3 = "https://www.google.com/maps/embed/v1/streetview?key="+ process.env.REACT_APP_GoogleMapsAPIKey +"&location=" +
        (dataIn.Latitude) + "," + (dataIn.Longitude - 0.00011) +
        "&heading=90&pitch=10&fov=100"
    var streetAddress4 = "https://www.google.com/maps/embed/v1/streetview?key="+ process.env.REACT_APP_GoogleMapsAPIKey +"&location=" +
        (dataIn.Latitude) + "," + (dataIn.Longitude + 0.0002) +
        "&heading=270&pitch=10&fov=100"

    //test - console.log(streetAddress1)
    //     {
    //         img: "https://maps.googleapis.com/maps/api/streetview?return_error_code=true&size=500x300&fov=120&heading=180&location=" + (latitude + 0.00025) + "," + longitude + "&pitch=0&key=AIzaSyAvE4FeatjC8DsSzU_3aZ50C6jffeUIEds",
    //         sortOrder: 0
    //     },
    //     {
    //         img: "https://maps.googleapis.com/maps/api/streetview?return_error_code=true&size=500x300&fov=120&heading=0&location=" + (latitude - 0.00025) + "," + longitude + "&pitch=0&key=AIzaSyAvE4FeatjC8DsSzU_3aZ50C6jffeUIEds",
    //         sortOrder: 1
    //     },
    //     {
    //         img: "https://maps.googleapis.com/maps/api/streetview?return_error_code=true&size=500x300&fov=120&heading=90&location=" + (latitude) + "," + (longitude - 0.00015) + "&pitch=0&key=AIzaSyAvE4FeatjC8DsSzU_3aZ50C6jffeUIEds",
    //         sortOrder: 2
    //     },
    //     {
    //         img: "https://maps.googleapis.com/maps/api/streetview?return_error_code=true&size=500x300&fov=120&heading=270&location=" + (latitude) + "," + (longitude + 0.00015) + "&pitch=0&key=AIzaSyAvE4FeatjC8DsSzU_3aZ50C6jffeUIEds",
    //         sortOrder: 3
    //     },
    //     {
    //         img: "https://maps.googleapis.com/maps/api/streetview?return_error_code=true&size=500x300&fov=120&location=" + latitude + "," + longitude + "&pitch=0&key=AIzaSyAvE4FeatjC8DsSzU_3aZ50C6jffeUIEds",
    //         sortOrder: 4
    //     }
    return (
        <>
            <div className="navigation-wrapper">
                <div ref={sliderRef} className="keen-slider" style={{ height: 500 }}>
                    <div className="keen-slider__slide number-slide2">

                        <iframe
                            // width="450"
                            // height="250"
                            className={window.window.innerWidth < 900 ? "w-full rounded-xl h-full" : "w-3-fourth rounded-xl"}
                            style={{ border: 0, height: '100%' }}
                            referrerPolicy="no-referrer-when-downgrade"
                            src={
                                address}

                            // src={"https://www.google.com/maps/embed/v1/place?q=" +
                            // plusCode + "&key="+ process.env.REACT_APP_GoogleMapsAPIKey +"&zoom=18&maptype=satellite"}// +"&heading=210&pitch=10&fov=35"}
                            allowFullScreen={true}>
                        </iframe>
                    </div>
                    <div className="keen-slider__slide number-slide2">

                        <iframe
                            // width="450"
                            // height="250"
                            className={window.window.innerWidth < 900 ? "w-full rounded-xl h-full" : "w-3-fourth rounded-xl h-full"}
                            // style={{ border: 0, height: 200, width: 320 }}
                            referrerPolicy="no-referrer-when-downgrade"
                            src={
                                streetAddress0}

                            // src={"https://www.google.com/maps/embed/v1/place?q=" +
                            // plusCode + "&key="+ process.env.REACT_APP_GoogleMapsAPIKey +"&zoom=18&maptype=satellite"}// +"&heading=210&pitch=10&fov=35"}
                            allowFullScreen={true}>
                        </iframe>

                    </div>

                    <div className="keen-slider__slide number-slide2">
                        <iframe
                            // width="450"
                            // height="250"
                            className={window.window.innerWidth < 900 ? "w-full rounded-xl h-full" : "w-3-fourth rounded-xl h-full"}
                            // style={{ border: 0, height: 200, width: 320 }}
                            referrerPolicy="no-referrer-when-downgrade"
                            src={
                                streetAddress1}

                            // src={"https://www.google.com/maps/embed/v1/place?q=" +
                            // plusCode + "&key="+ process.env.REACT_APP_GoogleMapsAPIKey +"&zoom=18&maptype=satellite"}// +"&heading=210&pitch=10&fov=35"}
                            allowFullScreen={true}>
                        </iframe>
                    </div>
                    <div className="keen-slider__slide number-slide2">  <iframe
                        // width="450"
                        // height="250"
                        className={window.window.innerWidth < 900 ? "w-full rounded-xl h-full" : "w-3-fourth rounded-xl h-full"}
                        // style={{ border: 0, height: 200, width: 320 }}
                        referrerPolicy="no-referrer-when-downgrade"
                        src={
                            streetAddress2}

                        // src={"https://www.google.com/maps/embed/v1/place?q=" +
                        // plusCode + "&key="+ process.env.REACT_APP_GoogleMapsAPIKey +"&zoom=18&maptype=satellite"}// +"&heading=210&pitch=10&fov=35"}
                        allowFullScreen={true}>
                    </iframe></div>
                    <div className="keen-slider__slide number-slide2">  <iframe
                        // width="450"
                        // height="250"
                        className={window.window.innerWidth < 900 ? "w-full rounded-xl h-full" : "w-3-fourth rounded-xl h-full"}
                        // style={{ border: 0, height: 200, width: 320 }}
                        referrerPolicy="no-referrer-when-downgrade"
                        src={
                            streetAddress3}

                        // src={"https://www.google.com/maps/embed/v1/place?q=" +
                        // plusCode + "&key="+ process.env.REACT_APP_GoogleMapsAPIKey +"&zoom=18&maptype=satellite"}// +"&heading=210&pitch=10&fov=35"}
                        allowFullScreen={true}>
                    </iframe></div>
                    <div className="keen-slider__slide number-slide2">  <iframe
                        // width="450"
                        // height="250"
                        className={window.window.innerWidth < 900 ? "w-full rounded-xl h-full" : "w-3-fourth rounded-xl h-full"}
                        // style={{ border: 0, height: 200, width: 320 }}
                        referrerPolicy="no-referrer-when-downgrade"
                        src={
                            streetAddress4}

                        // src={"https://www.google.com/maps/embed/v1/place?q=" +
                        // plusCode + "&key="+ process.env.REACT_APP_GoogleMapsAPIKey +"&zoom=18&maptype=satellite"}// +"&heading=210&pitch=10&fov=35"}
                        allowFullScreen={true}>
                    </iframe></div>
                </div>
                {loaded && instanceRef.current && (
                    <>
                        <Arrow
                            left
                            onClick={(e) =>
                                e.stopPropagation() || instanceRef.current?.prev()
                            }
                            disabled={currentSlide === 0}
                        />

                        <Arrow
                            onClick={(e) =>
                                e.stopPropagation() || instanceRef.current?.next()
                            }
                            disabled={
                                currentSlide ===
                                instanceRef.current.track.details.slides.length - 1
                            }
                        />
                    </>
                )}
            </div>
            {loaded && instanceRef.current && (
                <div className="dots">
                    {[
                        ...Array(instanceRef.current.track.details.slides.length).keys(),
                    ].map((idx) => {
                        return (
                            <button
                                key={idx}
                                onClick={() => {
                                    instanceRef.current?.moveToIdx(idx)
                                }}
                                className={"dot" + (currentSlide === idx ? " active" : "")}
                            ></button>
                        )
                    })}
                </div>
            )}
        </>
    )
}

function Arrow(props) {
    const disabeld = props.disabled ? " arrow--disabled" : ""
    return (
        <svg
            onClick={props.onClick}
            className={`arrow ${props.left ? "arrow--left" : "arrow--right"
                } ${disabeld}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            {props.left && (
                <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
            )}
            {!props.left && (
                <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
            )}
        </svg>
    )
}