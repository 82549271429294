/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Check as CheckIcon } from '@mui/icons-material';
import { useRef } from 'react';
import { Link as LinkIcon } from '@mui/icons-material';

export default function GroupFeedModal({open, setOpen}) {
  //const [open, setOpen] = useState(true)
  const cancelButtonRef = useRef(null);
  const people = [
    {
      name: 'Lindsay Walton',
      imageUrl:
        'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80',
    },
    // More people...
  ]
  const activityItems = [
    { id: 1, person: people[0], client: 'Workcation', commit: '2d89f0c8', environment: 'production', time: '1h' },
    // More items...
  ]

  return (
    <Transition.Root show={open} as={Fragment}>
    <Dialog as="div" className="fixed z-102 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div>
              <ul role="list" className="divide-y divide-gray-200">
                {activityItems.map((activityItem) => (
                  <li key={activityItem.id} className="py-4">
                    <div className="flex space-x-3">
                      <img className="h-6 w-6 rounded-full" src={activityItem.person.imageUrl} alt="" />
                      <div className="flex-1 space-y-1">
                        <div className="flex items-center justify-between">
                          <h3 className="text-sm font-medium">{activityItem.person.name}</h3>
                          <p className="text-sm text-gray-500">{activityItem.time}</p>
                        </div>
                        <p className="text-sm text-gray-500">
                          Deployed {activityItem.client} ({activityItem.commit} in master) to {activityItem.environment} and blah blah blah blah blah blah blabh bs;dlfkj sd;flksj df;lskdjf sd;lfkjs df;lksjd fsd;lfkjsd 
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
             <button type="button"
                     className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm">OK</button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                onClick={() => setOpen(false)}
                ref={cancelButtonRef}
              >
                Cancel
              </button>
            </div>
          
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>
  )
}
