import React, { useEffect, useState } from "react";
//import Property from "../components/Building/Property";
//import BingMapsReact from "bingmaps-react";
import { useSearchParams } from "react-router-dom";

import GroupDetail from "../components/Groups/GroupDetail";
import Header from "../components/Common/Header";
import axios from "axios";



export default function Group({ user }) {

  const [peopleData, setPeopleData] = useState(null)
  const [groupData, setGroupData] = useState(null)
  let [query, setQuery] = useSearchParams();

  let id = query.get("id");

  var qs;




  useEffect(() => {
    // axios.get(  process.env.REACT_APP_DATABASE +
    //     "api/getContacts?contactId=" +
    //   user.contact.id + "&groupId="+ id).then((resultsPeople) => {
    qs =
      process.env.REACT_APP_DATABASE +
      "api/getGroup?id=" +
      id +
      "&contactId=" +
      user.contact.id;
    axios.get(qs).then((resultsGroup) => {
      
      setGroupData(resultsGroup.data)
      
      var groupContacts = []
      if (resultsGroup.length > 0) {
        resultsGroup.data.group_contacts.map((group_contact) => {
          
          groupContacts.push(group_contact.contact)
        })
      }

      setPeopleData(groupContacts)
    })

    // })

  }, [user])




  //
  if (!peopleData || !groupData) {
    return (
      <>
        <div className="absolute top-1/3 w-full items-center flex flex-col">
          <img style={{ width: 100 }} src="https://images.cubicsearch.com/Wait.gif" />
          <div>Loading Group Data...</div>
        </div>
      </>
    );
  }


  if (!groupData || !peopleData || groupData.length === 0) {
    alert("Please sign in with your email for your account, or the email where you received your message.  If you are logged in, please refresh this page to ensure access.  For help please email support@cubicsearch.com")
    return (
      <div>
        <div>
          <Header user={user} />
        </div>
        <div className="py-4">
          <img
            src="https://images.cubicsearch.com/noresults.png"
            className="w-full"
          />
        </div>
      </div>
    );
  }
  //const groupData = groupDataArray[0];
  var OwnerRecord = groupData.group_contacts.filter(function (person) {
    return (
      person.PermissionLevel === "Lead Edit" ||
      person.PermissionLevel === "Edit"
    );
  });

  //test - console.log("Owner Record");
  //test - console.log(OwnerRecord);

  var MyRecord = groupData.group_contacts.filter(function (person) {
    return person.contact_id === user.contact.id;
  });
  //test - console.log("My Record");
  //test - console.log(MyRecord);
  var MyPermission = "View";
  //
  if (
    MyRecord &&
    MyRecord.length &&
    MyRecord.length > 0 &&
    MyRecord[0].PermissionLevel
  ) {
    if (MyRecord.length === 1) {
      MyPermission = MyRecord[0].PermissionLevel;
    } else {
      MyRecord.map((eachRecord) => {
        if (
          MyPermission !== "LeadEdit" &&
          eachRecord.PermissionLevel === "Lead Edit"
        ) {
          MyPermission = "Lead Edit";
        }
        if (
          MyPermission !== "LeadEdit" &&
          eachRecord.PermissionLevel === "Edit"
        ) {
          MyPermission = "Edit";
        }
      });
    }
  } else {
    MyPermission = "View";
  }
  //test - console.log("Permission: " + MyPermission);

  const people = getContacts(peopleData);

  //const {Base64} = require('js-base64');
  //var myData = Base64.decode(data.substring(5,data.length));
  //const groupData = JSON.parse(myData);
  var pushPins = [];

  groupData.group_contacts.map((member) => {
    // add all the peopel to a pushpin map
    pushPins.push({
      center: {
        latitude:
          member.contact && member.contact.office
            ? member.contact.office.Latitude
            : null,
        longitude:
          member.contact && member.contact.office
            ? member.contact.office.Longitude
            : null,
      },
      options: {
        title: member.contact
          ? member.contact.FirstName + " " + member.contact.LastName
          : "", //+ ", " + member.contact.office && member.contact.office.City? member.contact.office.City : "": "",
      },
    });
  });
  // const pushPin = {
  //   center: {
  //     latitude: groupData.contact.office.Latitude,
  //     longitude: groupData.contact.office.Latitude,
  //   },
  //   options: {
  //     title: groupData.contact.FirstName + " " + groupData.contact.StreetName + ", " + groupData.building.City,
  //   },
  // }
  // const pushPins = [pushPin];

  return (
    <div>
      <div >
        <GroupDetail
          Group={groupData}
          people={people}
          user={user}
          icon="group"
          permission={MyPermission}
        />
      </div>

      <div className="px-6 py-4">
        {/* {Group.length >0 ? <ContactMap searchResults={Group} setPopupInfo = {setPopUpContact} popupInfo = {popUpContact}/> : <></>} */}

        {/* {BingMap(groupData.building.Latitude, groupData.building.Longitude, pushPins)}   */}
      </div>
    </div>
  );
}

function getContacts(data) {
  var peopleArray = [];
  //   const data = await searchResults.json();
  data.map((person) => {
    var myJsonToAdd = {};
    if (person.images.length > 0) {
      if (person.office && person.office.company) {
        myJsonToAdd = {
          id: person.id,
          name:
            person.FirstName +
            " " +
            person.LastName +
            " - " +
            person.office.company.Name,
          imageURL: person.images[0].img,
        };
      } else {
        myJsonToAdd = {
          id: person.id,
          name: person.FirstName + " " + person.LastName,
          imageURL: person.images[0].img,
        };
      }
    } else {
      if (person.office && person.office.company) {
        myJsonToAdd = {
          id: person.id,
          name:
            person.FirstName +
            " " +
            person.LastName +
            " - " +
            person.office.company.Name,
          imageURL:
            "https://images.cubicsearch.com/UserLogo.png",
        };
      } else {
        myJsonToAdd = {
          id: person.id,
          name: person.FirstName + " " + person.LastName,
          imageURL:
            "https://images.cubicsearch.com/UserLogo.png",
        };
      }
    }
    peopleArray.push(myJsonToAdd);
  });
  return peopleArray.sort(sortByProperty("name"));
}

function sortByProperty(property) {
  return function (a, b) {
    if (a[property] > b[property]) return 1;
    else if (a[property] < b[property]) return -1;

    return 0;
  };
}
