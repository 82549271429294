import React from "react";
import { useRef, useEffect, useState, useImperativeHandle } from "react"
import Paper from "@mui/material/Paper";
import { TextInput } from "./TextInput.js";
import { MessageLeft, MessageRight } from "./Message.js";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import axios from "axios"
import { BuildingSubGroup } from "../InfoCard.js";
import { AvailableSubGroup } from "../InfoCard.js";
import { Avatar } from "@mui/material";
import ScrollableFeed from 'react-scrollable-feed'
import { isConstructorDeclaration } from "typescript";



const Messages = React.forwardRef(
    (props, ref) => {


        const [list, setList] = useState([]);
        const { groupId, type, typeId } = props
        const [trigger, setTrigger] = useState(null)
        const scrollRef = useRef()


        var queryString = process.env.REACT_APP_DATABASE +
            "api/getMessages"

        const mapResponse = (listItem) => {
            var contact
            // if (listItem.group.group_contacts) {


            var group_contact = listItem.group.group_contacts.find((element) => {

                if (element.contact && element.contact.id) {
                    return element.contact.id === listItem.AuthorUserID
                }
                else { return false }
            })

            if (group_contact) {
                contact = group_contact.contact
            }
            else {

                contact = {
                    FirstName: 'Outsider',
                    LastName: '',
                    Email: '',
                    images: [],
                    id: -2,
                }

            }

            if (contact && contact.images && contact.images.length > 1) {
                contact.images.sort((a, b) => a.sortOrder - b.sortOrder)
            }

            // else {
            //     // we need to look up the contacts
            //     var contactData = await axios.get("api/getMessageContact?contactId=" +props.contactId +"&authorId=" + listItem.AuthorUserID)
            //     contact = contact.data
            //     if (contact.images && contact.images.length > 1) {
            //         contact.images.sort((a, b) => a.sortOrder - b.sortOrder)
            //     }
            // }

            var photo

            if (contact && contact.images.length > 0) {

                photo = contact.images[0]
            }
            var message
            if (listItem.Message) {
                message = listItem.Message
            }
            else if (listItem.Field) {
                message = "Data Field:" + listItem.Field + " changed to " + listItem.NewValue + " | Old Value: " + listItem.OldValue
            }


            if (listItem.AuthorUserID === props.contactId) {



                return (<MessageRight
                    message={message}
                    timestamp={new Date(listItem.createdAt).toLocaleString()}
                    photoURL={photo ? photo.img : null}
                    displayName={contact.FirstName + " " + contact.LastName}
                    authorId={contact.id}
                    displayEmail={contact.Email}
                    avatarDisp={photo ? true : false}
                    messageId={listItem.id}
                    setTrigger={setTrigger}
                />

                )

            }
            else {
                return (<MessageLeft
                    message={message}
                    timestamp={new Date(listItem.createdAt).toLocaleString()}
                    photoURL={photo && photo.img}
                    displayName={contact.FirstName + " " + contact.LastName}
                    authorId={contact.id}
                    displayEmail={contact.Email}
                    avatarDisp={photo ? true : false} />)

            }
        }



        async function createItem(message) {
            var queryString = process.env.REACT_APP_DATABASE +
                "api/addOpinionPost"
            var result = await axios.post(queryString, {
                opinionData: {

                    Message: message,
                    buildingId: props.building && props.building.id ? props.building.id : null,
                    availId: props.availId ? props.availId : null,
                    groupId: props.groupId,

                }
                ,
                groupJournalData: {
                    contactId: props.contactId,
                    groupId: props.groupId,

                }
            })



            queryString = process.env.REACT_APP_DATABASE +
                "api/getMessages"


            var messagesRefresh = await axios.post(queryString, props)
                .catch(function (err) {

                    console.log(err)

                })

            if (messagesRefresh) {
                setList(messagesRefresh?.data?.messages)
            }

        }

        useImperativeHandle(ref, () => ({
            getList() {

                return (list)


            },

        }))



        useEffect(() => {



            async function getMessagesEffect() {
                var messages = await axios.post(queryString, props)
                    .catch(function (err) {

                        console.log(err)
                        // handle error

                    })


                setList(messages?.data?.messages)
                console.log("useEffect")
            }

            getMessagesEffect()

        }, [trigger]);


        if (list && list.length > 0) {



            var buildingsArray = []
            var availsArray = []
            var contactsArray = []
            var generalArray = []


            for (var listItem of list) {
                if (listItem.building) {
                    var found = buildingsArray.findIndex((building) => {
                        return listItem.building.id === building.id
                    })
                    if (found === -1) {
                        buildingsArray.push(listItem.building)
                    }
                }
                else if (listItem.availId) {
                    var found = availsArray.findIndex((avail) => {
                        return listItem.avail.id === avail.id
                    })
                    if (found === -1) {
                        availsArray.push(listItem.avail)
                    }
                }
                else if (listItem.contactId) {
                    contactsArray.push(listItem)
                }
                else {
                    generalArray.push(listItem)
                }
            }

            var availsTopArray = []
            for (var avail of availsArray) {

                var index = -1

                for (var building of buildingsArray) {
                    if (building.id === avail.building_id) {
                        index = 1
                    }
                }

                if (index === 1) {

                }
                else {
                    availsTopArray.push(avail)

                }
            }

            return (
                <>
                    <div>

                        <div style={{
                            position: 'relative',

                            maxHeight: window.innerHeight / 3,
                            overflowY: 'hidden',
                        }} zdepth={2}>

                            <div style={{
                                padding: 5,
                                width: '100%',

                            }}>

                                <div
                                    // className="chatbox"
                                    style={{
                                        height: generalArray.length * 100, maxHeight: window.innerHeight / 3, overflow: 'auto',
                                        // display: 'flex', flexDirection: 'column-reverse'
                                    }}
                                >

                                    <ScrollableFeed>
                                        {generalArray && generalArray
                                            // .slice().reverse()
                                            .map((item, index) => {
                                                if (index === list.length - 1) {
                                                    return (<div
                                                    // ref={scrollRef}
                                                    >

                                                        {mapResponse(item)}
                                                    </div>)
                                                }
                                                else {
                                                    return (
                                                        <div>

                                                            {mapResponse(item)}
                                                        </div>
                                                    )
                                                }
                                            })}
                                    </ScrollableFeed>
                                </div>

                            </div>
                        </div>
                        <TextInput setTrigger={setTrigger} contactId={props.contactId} groupId={groupId} type={type} typeId={typeId} onClick={(message) => {

                            if (message && message.target && message.target.value) {
                                createItem(message.target.value)

                            }

                        }} />
                    </div>
                    <div style={{
                        marginTop: 10,
                        // display: 'flex', justifyContent: 'end',
                        width: '100%'
                    }}>
                        <div
                            style={{ width: '100%' }}
                        >
                            {buildingsArray.map((building) => {

                                return (<Accordion>
                                    <AccordionSummary sx={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, marginTop: 4, backgroundColor: '#dddddd' }} >   <Typography component={'div'}sx={{ display: 'flex', alignItems: 'center', padding: 1, borderRadius: 2, backgroundColor: '#1769aa', color: 'white' }}>
                                        <>{(building.images.length > 0 ? <Avatar sx={{ marginRight: 2 }} src={building.images[0].img}></Avatar> : <></>)} {" " + building.StreetNumber + " " + building.StreetName} Building Discussions</>
                                    </Typography></AccordionSummary>

                                    <AccordionDetails>


                                        {BuildingSubGroup(props.group, props.contactId, building)}



                                    </AccordionDetails>

                                </Accordion>)
                            }
                            )}
                        </div>
                        <div>
                            {availsTopArray.length > 0 ? <div style={{ marginTop: 10,  justifyContent: 'end', width: '100%' }}>


                                {availsTopArray.map((avail) => {

                                    
                                    return (<div
                                        style={{ width: '100%' }}
                                    >
                                        <Accordion defaultExpanded={true}>

                                            <AccordionSummary sx={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, marginTop: 4, backgroundColor: '#dddddd' }} >   <Typography component={'div'}sx={{ display: 'flex', alignItems: 'center', padding: 1, borderRadius: 2, backgroundColor: '#1769aa', color: 'white' }}>
                                                <>

                                                    {(avail?.images?.length > 0 ?
                                                        <Avatar sx={{ marginRight: 2 }} src={avail?.images[0]?.img}></Avatar>
                                                        :
                                                        <></>)}
                                                    <div>
                                                        <Typography> {avail?.building?.StreetNumber} {" "}
                                                            {avail?.building?.StreetName} {" "}
                                                            {avail?.building?.City} {" "}
                                                        </Typography>

                                                        <Typography>
                                                            {(avail?.Suite ? "Suite " + avail?.Suite : "") +
                                                                " " + (avail?.Floor ? "Floor " + (avail?.Floor === -1 ? "Entire Building" : avail?.Floor) : "")}
                                                        </Typography>
                                                        <Typography>
                                                            Available Space Discussion
                                                        </Typography>
                                                    </div>
                                                </>
                                            </Typography></AccordionSummary>
                                            {/* <Typography component={'div'}sx={{ padding: 1, borderRadius: 2, backgroundColor: '#1769aa', color: 'white' }}></Typography> */}

                                            <AccordionDetails >
                                              
                                                <div style={{ width: '100%' }}>{AvailableSubGroup(props.group, props.contactId, avail)}</div>

                                            </AccordionDetails>
                                        </Accordion>
                                    </div>)
                                }
                                )}
                            </div>

                                : <></>}
                        </div>
                    </div>

                </>
            );
        }
        else {
            return (

                <div>


                    <TextInput setTrigger={setTrigger} contactId={props.contactId} groupId={groupId} type={type} typeId={typeId} onClick={(message) => {

                        if (message && message.target && message.target.value) {
                            createItem(message.target.value)
                        }

                    }} />
                </div>
            );
        }
    })

export const BuildingSubMessages = React.forwardRef(
    (props, ref) => {
        const groupId = props.groupId
        const type = "buildingId"
        const typeId = props.building.id
        const [trigger, setTrigger] = useState(null)
        const [list, setList] = useState([]);
        const scrollRef = useRef()
        var queryString = process.env.REACT_APP_DATABASE +
            "api/getMessages"

        const mapResponse = (listItem) => {
            var contact
            // if (listItem.group.group_contacts) {


            var group_contact = listItem.group.group_contacts.find((element) => {

                if (element && element.contact && element.contact.id && listItem && listItem.AuthorUserID) {
                    return element.contact.id === listItem.AuthorUserID
                }
                else { return false }
            })

            if (group_contact) {
                contact = group_contact.contact
            }
            else {

                contact = {
                    FirstName: 'Outsider',
                    LastName: '',
                    Email: '',
                    images: [],
                    id: -2,
                }
            }

            if (contact.images && contact.images.length > 1) {
                contact.images.sort((a, b) => a.sortOrder - b.sortOrder)
            }



            var photo

            if (contact && contact.images.length > 0) {

                photo = contact.images[0]
            }
            var message
            if (listItem.Message) {
                message = listItem.Message
            }
            else if (listItem.Field) {
                message = "Data Field:" + listItem.Field + " changed to " + listItem.NewValue + " | Old Value: " + listItem.OldValue
            }

            if (listItem.AuthorUserID === props.contactId) {


                return (<MessageRight
                    message={message}
                    timestamp={new Date(listItem.createdAt).toLocaleString()}
                    photoURL={photo ? photo.img : null}
                    displayName={contact.FirstName + " " + contact.LastName}
                    displayEmail={contact.Email}
                    avatarDisp={photo ? true : false}
                    messageId={listItem.id}
                    setTrigger={setTrigger}
                    authorId={contact.id}
                />)
            }
            else {
                return (<MessageLeft
                    message={message}
                    timestamp={new Date(listItem.createdAt).toLocaleString()}
                    photoURL={photo && photo.img}
                    displayName={contact.FirstName + " " + contact.LastName}
                    displayEmail={contact.Email}
                    authorId={contact.id}
                    avatarDisp={photo ? true : false} />)
                

            }
        }



        async function createItem(message) {
            var queryString = process.env.REACT_APP_DATABASE +
                "api/addOpinionPost"
            var result = await axios.post(queryString, {
                opinionData: {

                    Message: message,
                    buildingId: props.building.id ? props.building.id : null,
                    availId: props.availId ? props.availId : null,
                    groupId: props.groupId,

                }
                ,
                groupJournalData: {
                    contactId: props.contactId,
                    groupId: props.groupId,

                }
            })



            queryString = process.env.REACT_APP_DATABASE +
                "api/getMessages"


            var messagesRefresh = await axios.post(queryString, props)
                .catch(function (err) {

                    console.log(err)

                })

            if (messagesRefresh) {
                setList(messagesRefresh?.data?.messages)
            }

        }

        useImperativeHandle(ref, () => ({
            getList() {

                return (list)


            },

        }))





        useEffect(() => {

            axios.post(queryString, props)
                .catch(function (err) {

                    console.log(err)
                    // handle error

                }).then((

                    messages
                ) => {

                    setList(messages?.data?.messages)
                    // scrollRef.current.scrollIntoView()    
                })
        }, [trigger]);


        if (list && list.length > 0) {



            var buildingsArray = []
            var availsArray = []
            var contactsArray = []
            var generalArray = []


            list.map((listItem, index) => {
                if (listItem.building) {

                    buildingsArray.push(listItem)
                }
                else if (listItem.avail || listItem.availId) {

                    var availFound = availsArray.findIndex((avail) => {

                        return listItem.avail.id === ((listItem.avail && listItem.avail.id) || listItem.availId)
                        // don't add

                    })

                    if (availFound === -1) {
                        availsArray.push(listItem)
                    }
                }
                else if (listItem.contactId) {
                    contactsArray.push(listItem)
                }
                else {
                    generalArray.push(listItem)
                }
            })


            
            return (
                <>
                    <div >

                        <div style={{
                            position: 'relative',

                            maxHeight: window.innerHeight / 3,
                            overflowY: 'hidden',
                        }} zdepth={2}>

                            <div style={{
                                padding: 5,
                                width: '100%',

                            }}>

                                <div id="style-1"
                                    style={{
                                        height: buildingsArray.length * 100, maxHeight: window.innerHeight / 3, overflow: 'auto',
                                        // display: 'flex', flexDirection: 'column-reverse'
                                    }}
                                >
                                    <ScrollableFeed>
                                        {

                                            buildingsArray && buildingsArray
                                                // .slice().reverse()
                                                .map((item, index) => {
                                                    if (index === list.length - 1) {
                                                        return (<div
                                                        // ref={scrollRef}
                                                        >

                                                            {mapResponse(item)}
                                                        </div>)
                                                    }
                                                    else {
                                                        return (
                                                            <div>

                                                                {mapResponse(item)}
                                                            </div>
                                                        )
                                                    }
                                                })}
                                    </ScrollableFeed>

                                </div>

                            </div>
                        </div>
                        <TextInput setTrigger={setTrigger} contactId={props.contactId} groupId={groupId} type={type} typeId={typeId} onClick={(message) => {

                            if (message && message.target && message.target.value) {
                                createItem(message.target.value)

                            }

                        }} />
                    </div>

                    {availsArray.length > 0 ? <div style={{ marginTop: 10, display: 'flex', justifyContent: 'end', width: '100%' }}>


                        {availsArray.map((item) => {

                            return (<div
                                style={{ width: '100%' }}
                            >
                                <Accordion defaultExpanded={true}>

                                    <AccordionSummary sx={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, marginTop: 4, backgroundColor: '#dddddd' }} >   <Typography component={'div'}sx={{ display: 'flex', alignItems: 'center', padding: 1, borderRadius: 2, backgroundColor: '#1769aa', color: 'white' }}>
                                        <>{(item.avail.images.length > 0 ?
                                            <Avatar sx={{ marginRight: 2 }} src={item.avail.images[0].img}></Avatar>
                                            : <></>)}
                                            {(item.avail.Suite ? "Suite " + item.avail.Suite
                                                : "") + " " + (item.avail?.Floor ? "Floor " + (item.avail?.Floor === -1 ? "Entire Building" : item.avail?.Floor)
                                                    : "")}

                                            Available 1
                                        </>
                                    </Typography></AccordionSummary>
                                    {/* <Typography component={'div'}sx={{ padding: 1, borderRadius: 2, backgroundColor: '#1769aa', color: 'white' }}></Typography> */}

                                    <AccordionDetails >
                                        <div style={{ width: '100%' }}>{AvailableSubGroup(props.group, props.contactId, item.avail, props.building)}</div>

                                    </AccordionDetails>
                                </Accordion>
                            </div>)
                        }
                        )}
                    </div>

                        : <></>}
                </>
            );
        }
        else {
            return (

                <div>


                    <TextInput setTrigger={setTrigger} contactId={props.contactId} groupId={groupId} type={type} typeId={typeId} onClick={(message) => {

                        if (message && message.target && message.target.value) {
                            createItem(message.target.value)
                        }

                    }} />


                </div>

            );
        }
    })

export const AvailableSubMessages = React.forwardRef(
    (props, ref) => {
        const groupId = props.groupId
        const type = "availId"
        
        const typeId = props.available?.id
        const scrollRef = useRef(null);
        const [list, setList] = useState([]);
        const [trigger, setTrigger] = useState(null)
        var queryString = process.env.REACT_APP_DATABASE +
            "api/getMessages"

        const mapResponse = (listItem) => {
            var contact
            // if (listItem.group.group_contacts) {


            var group_contact = listItem.group.group_contacts.find((element) => {

                if (element.contact && element.contact.id) {
                    return element.contact.id === listItem.AuthorUserID
                }
                else { return false }
            })
            if (group_contact && group_contact.contact) {
                contact = group_contact.contact

                if (contact.images && contact.images.length > 1) {
                    contact.images.sort((a, b) => a.sortOrder - b.sortOrder)
                }
            }
            else {

                contact = {
                    FirstName: 'Outsider',
                    LastName: '',
                    Email: '',
                    images: [],
                    id: -2,
                }
            }

            // else { // this happens when you delete a contact from a goup
            //     // we need to look up the contacts
            //     var contactData = await axios.get("api/getMessageContact?contactId=" +props.contactId +"&authorId=" + listItem.AuthorUserID)
            //     contact = contact.data
            //     if (contact.images && contact.images.length > 1) {
            //         contact.images.sort((a, b) => a.sortOrder - b.sortOrder)
            //     }
            // }

            var photo

            if (contact && contact.images.length > 0) {

                photo = contact.images[0]
            }
            var message
            if (listItem.Message) {
                message = listItem.Message
            }
            else if (listItem.Field) {
                message = "Data Field:" + listItem.Field + " changed to " + listItem.NewValue + " | Old Value: " + listItem.OldValue
            }

            if (listItem.AuthorUserID === props.contactId) {


                return (<MessageRight
                    message={message}
                    timestamp={new Date(listItem.createdAt).toLocaleString()}
                    photoURL={photo ? photo.img : null}
                    displayName={contact.FirstName + " " + contact.LastName}
                    displayEmail={contact.Email}
                    avatarDisp={photo ? true : false}
                    messageId={listItem.id}
                    authorId={contact.id}
                    setTrigger={setTrigger}
                />)
            }
            else {
                return (<MessageLeft
                    message={message}
                    timestamp={new Date(listItem.createdAt).toLocaleString()}
                    photoURL={photo && photo.img}
                    displayName={contact.FirstName + " " + contact.LastName}
                    displayEmail={contact.Email}
                    authorId={contact.id}
                    avatarDisp={photo ? true : false} />)

            }
        }



        async function createItem(message) {
            var queryString = process.env.REACT_APP_DATABASE +
                "api/addOpinionPost"
            var result = await axios.post(queryString, {
                opinionData: {

                    Message: message,
                    buildingId: props.building && props.building.id ? props.building.id : null,
                    availId: props.available && props.available.id ? props.available.id : null,
                    groupId: props.groupId,

                }
                ,
                groupJournalData: {
                    contactId: props.contactId,
                    groupId: props.groupId,

                }
            })


            queryString = process.env.REACT_APP_DATABASE +
                "api/getMessages"


            var messagesRefresh = await axios.post(queryString, props)
                .catch(function (err) {

                    console.log(err)

                })

            if (messagesRefresh) {
                setList(messagesRefresh?.data?.messages)
            }

        }

        useImperativeHandle(ref, () => ({
            getList() {

                return (list)


            },

        }))





        useEffect(() => {

            axios.post(queryString, props)
                .catch(function (err) {

                    console.log(err)
                    // handle error

                }).then((

                    messages
                ) => {

                    setList(messages?.data?.messages)
                    // scrollRef.current.scrollIntoView()
                })
        }, [trigger]);


        if (list && list.length > 0) {



            var buildingsArray = []
            var availsArray = []
            var contactsArray = []
            var generalArray = []


            list.map((listItem, index) => {
                if (listItem.building) {

                    buildingsArray.push(listItem)
                }
                else if (listItem.avail || listItem.availId) {
                    availsArray.push(listItem)
                }
                else if (listItem.contactId) {
                    contactsArray.push(listItem)
                }
                else {
                    generalArray.push(listItem)
                }
            })

            return (
                <>
                    <div>

                        <div style={{
                            position: 'relative',

                            maxHeight: window.innerHeight / 3,
                            overflowY: 'hidden',
                        }} zdepth={2}>

                            <div style={{
                                padding: 5,
                                width: '100%',

                            }}>

                                <div id="style-1"
                                    style={{
                                        height: availsArray.length * 100, maxHeight: window.innerHeight / 3, overflow: 'auto',
                                        // display: 'flex', flexDirection: 'column-reverse'
                                    }}
                                >
                                    <ScrollableFeed>
                                        {

                                            availsArray && availsArray
                                                // .slice().reverse()
                                                .map((item, index) => {
                                                    if (index === list.length - 1) {
                                                        return (<div
                                                        // ref={scrollRef}
                                                        >

                                                            {mapResponse(item)}
                                                        </div>)
                                                    }
                                                    else {
                                                        return (
                                                            <div>

                                                                {mapResponse(item)}
                                                            </div>
                                                        )
                                                    }
                                                })}
                                    </ScrollableFeed>
                                </div>

                            </div>
                        </div>
                        <TextInput setTrigger={setTrigger} contactId={props.contactId} groupId={groupId} type={type} typeId={typeId} onClick={(message) => {

                            if (message && message.target && message.target.value) {
                                createItem(message.target.value)

                            }

                        }} />
                    </div>


                </>
            );
        }
        else {
            return (

                <div>


                    <TextInput setTrigger={setTrigger} contactId={props.contactId} groupId={groupId} type={type} typeId={typeId} onClick={(message) => {

                        if (message && message.target && message.target.value) {
                            createItem(message.target.value)
                        }

                    }} />
                </div>
            );
        }
    })
export default Messages
