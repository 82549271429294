import React, { useEffect, useState } from 'react';
import {
    Typography, Box, Paper, TextField, MenuItem,
    Button, Select, FormControl, InputLabel, Stack, Link, Chip, IconButton, Modal, Backdrop, Fade, Divider
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

import SenderNamesForm from './SenderNamesForm';

import ContactDetails from './ContactDetails';
import { CallsToAction } from './CallsToAction';
import MyTinyMCEEditor from './MyTinyMCEEditor';
import { DangerZone } from './DangerZone';
import RecipientListsComponent from './RecipientListComponent';
import CircularProgress from '@mui/material/CircularProgress';
import ContactsDialog from './MailLists/ContactsDialog';
import ContactsDialogTest from './ContactsDialogTest';
import CampaignReportDialog from './CampaignReportDialog'
import DeliveryReportButton from './DeliveryReportButton';
import SendCampaignButton from './SendCampaignButton'



const EmailDisplay = ({ clientName, projectName, campaignName, emailDetails, setEmailDetails, contactId, index, edit, setEdit, handleSaveClick, loading }) => {

    const { subject, senderInfo, callsToAction, preheaderText, content, segmentation } =
        emailDetails?.campaignMailers?.length > 0 && emailDetails?.campaignMailers[index] ||
        { subject: null, senderInfo: [], callsToAction: [], preheaderText: null, segmentation: null, content: null };

    const [showTestEmail, setShowTestEmail] = useState(false)
    const [showReports, setShowReports] = useState(false)
    const [campaignId, setCampaignId] = useState(false)

    useEffect(() => {
        if (emailDetails?.campaignMailers?.[index]) {
            
            setCampaignId(emailDetails.campaignMailers[index].bId)
        }
    }, [emailDetails])

    const setLists = (newLists) => {


        emailDetails.campaignMailers[index].recipientLists = newLists
        var copy = JSON.parse(JSON.stringify(emailDetails))
        setEmailDetails(copy);
    }
    const setExclusionLists = (newLists) => {



        emailDetails.campaignMailers[index].exclusionRecipientLists = newLists
        var copy = JSON.parse(JSON.stringify(emailDetails))
        setEmailDetails(copy);
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    };
    const LoadingOverlay = ({ open }) => (
        <Modal
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <CircularProgress />
                </Box>
            </Fade>
        </Modal>
    );







    var mailerInfo = emailDetails?.campaignMailers?.[index]?.campaignInfo


    var emailSent = mailerInfo?.status

    console.log(mailerInfo)
    var sentDate = mailerInfo?.sentDate
    var sentDateConverted = null

    if (sentDate){
     sentDateConverted = new Date(mailerInfo?.sentDate).toLocaleDateString()
    }
    if (!edit || sentDate) {
        return (<>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

            </Box>
            <Paper elevation={3} sx={{ padding: 2, margin: 2, paddingBottom: 40 }}>
                <Typography sx={{display: 'flex'}} variant="h5" component="h3">
                    {mailerInfo && toTitleCase(mailerInfo?.status)} Message {sentDateConverted && sentDateConverted}
                </Typography>
                {mailerInfo && <DeliveryReportButton mailerInfo={mailerInfo} emailDetails={emailDetails} index={index} />}  
                <Typography variant="subtitle" sx={{ fontSize: 12, color: 'darkgray' }}>
                    This is one message of the campaign, click on other outgoing messages in the campaign menu for others
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Typography variant="h5" gutterBottom>
                    Subject: {subject || 'Untitled Mailer'}
                </Typography>


                <Typography variant="body1" gutterBottom>
                    Preheader: {preheaderText}
                </Typography>


                <RecipientListsComponent
                    initialRecipientLists={emailDetails?.campaignMailers?.[index]?.recipientLists || []}
                    initialExclusionRecipientLists={emailDetails?.campaignMailers?.[index]?.exclusionRecipientLists || []}
                    edit={edit && !sentDate} contactId={contactId} setLists={setLists} setExclusionLists={setExclusionLists} campaignMailer={emailDetails?.campaignMailers?.[index]} />
                <Button disabled={!emailDetails?.campaignMailers?.[index]?.statistics} onClick={() => { setShowReports(true) }} sx={{ marginRight: 3 }} variant='outlined'>Reports</Button>

                <Button disabled={sentDate? true: false}  onClick={() => { setShowTestEmail(true) }} sx={{ marginRight: 3 }} variant='outlined'>Email Me A Test</Button>
                <SendCampaignButton disabled={sentDate? true: false} campaignId={campaignId} />

                <ContactDetails contacts={senderInfo} />
                {/* <HtmlToJsx htmlContent={emailDetails?.campaignMailers[index]?.content?.html} /> */}
                <div style={{ width: '100%', height: '80%', }}>
                    <MyTinyMCEEditor emailDetails={emailDetails}
                        setEmailDetails={setEmailDetails}
                        index={index}
                        readOnly={true}
                    />

                </div>
                {/* <CallsToAction callsToAction={callsToAction} /> */}


            </Paper>
            <ContactsDialogTest open={showTestEmail} onClose={() => { setShowTestEmail(false) }} contactId={contactId} campaignMailerId={emailDetails?.campaignMailers?.[index]?.bId} />
            <CampaignReportDialog campaign={emailDetails?.campaignMailers?.[index]} open={showReports} onClose={() => { showReports() }} />
        </>
        );
    }
    else {
        return (<> <LoadingOverlay open={loading} />
            <EmailDisplayEdit
                clientName={clientName}
                projectName={projectName}
                campaignName={campaignName}
                emailDetails={emailDetails}
                setEmailDetails={setEmailDetails}
                setEdit={setEdit}
                handleSaveClick={handleSaveClick}
                contactId={contactId}
                index={index}
                setLists={setLists}
                setExclusionLists={setExclusionLists}
                setShowTestEmail={setShowTestEmail}
                showTestEmail={showTestEmail}
                campaignId={campaignId}

            /></>)
    }
};

export default EmailDisplay;


export const EmailDisplayEdit = ({ clientName, projectName, campaignName,
    emailDetails, setEmailDetails, setEdit, handleSaveClick, contactId, index, setLists, setExclusionLists, showTestEmail, setShowTestEmail, campaignId }) => {


    // const [edit, setEdit] = useState(false);

    const handleEditChange = (e) => {
        const { name, value } = e.target;

        emailDetails.campaignMailers[index][name] = value
        setEmailDetails(emailDetails);
    };
    const handleEditActionsChange = (e) => {
        const { name, value } = e.target;

        emailDetails.campaignMailers[index].callsToAction = value
        var copy = JSON.parse(JSON.stringify(emailDetails))
        setEmailDetails(copy);
    };
    const handleEditSendersChange = (e) => {
        const { name, value } = e.target;

        emailDetails.campaignMailers[index].senderInfo = value
        var copy = JSON.parse(JSON.stringify(emailDetails))
        setEmailDetails(copy);
    };
    const handleEditChangeSubject = (event) => {
        // Create a new copy of campaignMailers
        let newCampaignMailers = JSON.parse(JSON.stringify(emailDetails.campaignMailers));


        // Update the subject at the specified index
        newCampaignMailers[index].subject = event.target.value;

        // Set the updated campaignMailers back to emailDetails state
        setEmailDetails({ ...emailDetails, campaignMailers: newCampaignMailers });
    };
    const handleEditChangePreheader = (event) => {
        // Create a new copy of campaignMailers
        let newCampaignMailers = [...emailDetails.campaignMailers];

        // Update the subject at the specified index
        newCampaignMailers[index].preheaderText = event.target.value;

        // Set the updated campaignMailers back to emailDetails state
        setEmailDetails({ ...emailDetails, campaignMailers: newCampaignMailers });
    };






    if (emailDetails?.campaignMailers?.[index]) {
        return (
            <>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: 2 }}>

                    {/* <Button
                        startIcon={<SaveIcon />}
                        onClick={() => {

                            handleSaveClick(emailDetails)
                        }}
                        variant="contained"
                    >
                        Save
                    </Button> */}

                </Box>
                <Paper elevation={3} sx={{ padding: 2 }}>

                    <Typography variant="h5" component="h3">
                        Message Edit
                    </Typography>
                    <Divider sx={{ mb: 2 }} />

                    <TextField
                        fullWidth
                        label="Message Subject"
                        name="subject"
                        value={emailDetails?.campaignMailers?.[index]?.subject || ''}
                        onChange={handleEditChangeSubject}
                        margin="normal"
                    />


                    <TextField
                        fullWidth
                        label="Preheader Text"
                        name="preheaderText"
                        value={emailDetails?.campaignMailers?.[index]?.preheaderText || ''}
                        onChange={handleEditChangePreheader}
                        margin="normal"
                    />

                    <RecipientListsComponent initialRecipientLists={emailDetails?.campaignMailers?.[index]?.recipientLists || []}
                        initialExclusionRecipientLists={emailDetails?.campaignMailers?.[index]?.exclusionRecipientLists || []}
                        edit={true} contactId={contactId} setLists={setLists} setExclusionLists={setExclusionLists} campaignMailer={emailDetails?.campaignMailers?.[index]} />

                    <Paper sx={{ padding: 3, }}>
                        <SenderNamesForm emailDetails={emailDetails} handleEditSendersChange={handleEditSendersChange} contactId={contactId} index={index} />

                    </Paper>
                    <Button onClick={() => { setShowTestEmail(true) }} sx={{ marginRight: 3, marginTop: 2 }} variant='outlined'>Email Me A Test</Button>
                    <SendCampaignButton campaignId={campaignId} />
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'start', paddingRight: 10 }}>
                        <Typography sx={{ marginTop: 2, color: 'blue' }}>Email Preview Editor</Typography>
                    </div>
                    <div style={{ width: '100%', height: '80%' }}>
                        <MyTinyMCEEditor emailDetails={emailDetails}
                            setEmailDetails={setEmailDetails}
                            index={index}
                            readOnly={false}
                        />

                    </div>
                    {/* Additional fields for editing Segmentation and Sender Info could go here */}
                    {/* <FormControl fullWidth margin="normal">
                    <InputLabel>Calls to Action</InputLabel>
                    <Select
                        name="callsToAction"
                        value={emailDetails?.campaignMailers[index]?.callsToAction?.length > 0 ? emailDetails.campaignMailers[index].callsToAction : []}
                        label="Calls to Action"
                        onChange={handleEditActionsChange}
                        multiple
                    >
                        <MenuItem value="meet">Meet</MenuItem>
                        <MenuItem value="call">Call</MenuItem>
                        <MenuItem value="zoom">Zoom</MenuItem>
                        <MenuItem value="tour">Tour</MenuItem>
                        <MenuItem value="info">Get More Information</MenuItem>
                    </Select>

                </FormControl> */}
                </Paper>
                <div style={{ marginTop: 20, paddingBottom: 180 }}>
                    <DangerZone mailerId={emailDetails?.campaignMailers[index]?.id} onDeleteSuccess={() => {

                        emailDetails?.campaignMailers.splice(index, 1)
                        emailDetails?.frequency?.specificDates?.splice(index, 1)
                        var copy = JSON.parse(JSON.stringify(emailDetails))
                        setEmailDetails(copy)
                    }} />
                </div>
                <ContactsDialogTest open={showTestEmail} onClose={() => { setShowTestEmail(false) }} contactId={contactId} campaignMailerId={emailDetails?.campaignMailers?.[index]?.bId} />
            </>

        );
    }
    else {

        return (
            <>
                <Box sx={{
                    width: '100%', height: '100%', display: 'flex', flexDirection: 'column', // Use column direction for vertical centering
                    justifyContent: 'center', // This centers vertically in a column direction
                    alignItems: 'center', // This centers horizontally
                }}> Please create or select a message.</Box>
            </>
        )
    }
};


export function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}