import React, { useState, useCallback } from 'react';
import { Dialog, Grid, DialogTitle, DialogContent, TextField, Button, DialogActions, Paper, Tooltip } from '@mui/material';
import axios from 'axios';
import { debounce } from 'lodash';

function OfficeDialog({ open, onClose, onSave, initialData = {} }) {
    const [officeData, setOfficeData] = useState({
        fullAddress: initialData?.fullAddress || '',
        tooltipAddress: '',
        StreetNumber: initialData?.StreetNumber || '',
        StreetName: initialData?.StreetName || '',
        Suite: initialData?.Suite || '',
        Phone: initialData?.Phone || '',
        City: initialData?.City || '',
        State: initialData?.State || '',
        Zip: initialData?.Zip || '',
        id: initialData?.id || null
    });

    const fetchAddressDetails = useCallback(debounce(async (input) => {
        if (!input) return;
        try {
            const response = await axios.post(process.env.REACT_APP_DATABASE + 'api/googleAutoComplete', { input });
            const details = response.data;
            if (!details || !details.address) {
                console.error('No address details found');
                return;
            }

            const fullAddress = details.formatted_address || '';
            const phone = details.phone || '';

            const address = {
                StreetNumber: details.address.find(ac => ac.types.includes('street_number'))?.long_name || '',
                StreetName: details.address.find(ac => ac.types.includes('route'))?.long_name || '',
                Suite: details.address.find(ac => ac.types.includes('subpremise'))?.long_name || '',
                City: details.address.find(ac => ac.types.includes('locality'))?.long_name || '',
                State: details.address.find(ac => ac.types.includes('administrative_area_level_1'))?.short_name || '',
                Zip: details.address.find(ac => ac.types.includes('postal_code'))?.long_name || '',
                Phone: phone
            };

            setOfficeData(prev => ({
                ...prev,
                ...address,
                tooltipAddress: fullAddress
            }));

        } catch (error) {
            console.error('Error fetching from backend:', error);
        }
    }, 500), []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setOfficeData(prev => ({ ...prev, [name]: value }));
        if (name === 'fullAddress') {
            fetchAddressDetails(value); // Call the debounced function
        }
    };

    const handleSubmit = () => {
        onSave(officeData);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>{initialData && initialData.id ? 'Edit Office' : 'Create Office'}</DialogTitle>
            <DialogContent>
                <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
                    <Tooltip title={officeData.tooltipAddress || 'Enter address to see details'} placement="top" arrow>
                        <TextField
                            label="Address Search"
                            name="fullAddress"
                            value={officeData.fullAddress}
                            onChange={handleChange}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                        />
                    </Tooltip>
                </Paper>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField
                            label="Street Number"
                            name="StreetNumber"
                            value={officeData.StreetNumber}
                            onChange={handleChange}
                            fullWidth
                            margin="dense"
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            label="Street Name"
                            name="StreetName"
                            value={officeData.StreetName}
                            onChange={handleChange}
                            fullWidth
                            margin="dense"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Suite"
                            name="Suite"
                            value={officeData.Suite}
                            onChange={handleChange}
                            fullWidth
                            margin="dense"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Phone"
                            name="Phone"
                            value={officeData.Phone}
                            onChange={handleChange}
                            fullWidth
                            margin="dense"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="City"
                            name="City"
                            value={officeData.City}
                            onChange={handleChange}
                            fullWidth
                            margin="dense"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="State"
                            name="State"
                            value={officeData.State}
                            onChange={handleChange}
                            fullWidth
                            margin="dense"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Zip"
                            name="Zip"
                            value={officeData.Zip}
                            onChange={handleChange}
                            fullWidth
                            margin="dense"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Cancel</Button>
                <Button onClick={handleSubmit} color="primary" variant="contained">Save</Button>
            </DialogActions>
        </Dialog>
    );
}

export default OfficeDialog;
