import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Button, DialogActions } from '@mui/material';
import { useDropzone } from 'react-dropzone';

function CompanyDialog({ open, onClose, onSave, initialData = {} }) {
    const [companyData, setCompanyData] = useState({
        Name: initialData.Name || '',
        Website: initialData.Website || '',
        Notes: initialData.Notes || '',
        Logo: initialData.Logo || null
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCompanyData(prevState => ({ ...prevState, [name]: value }));
    };

    const onDrop = (acceptedFiles) => {
        setCompanyData(prevState => ({ ...prevState, Logo: acceptedFiles[0] }));
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*',
        maxFiles: 1,
        maxSize: 5000000
    });

    const handleSubmit = () => {
        onSave(companyData);
        onClose();
    };

    useEffect(() => {
        setCompanyData({
            Name: initialData.Name || '',
            Website: initialData.Website || '',
            Notes: initialData.Notes || '',
            Logo: initialData.Logo || null
        })
    }, [initialData]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>{initialData?.id ? 'Edit Company' : 'Create Company'}</DialogTitle>
            <DialogContent>
                <TextField
                    label="Name"
                    name="Name"
                    value={companyData.Name}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Website"
                    name="Website"
                    value={companyData.Website}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Notes"
                    name="Notes"
                    value={companyData.Notes}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                />
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <p>Drag and drop a company logo here or click to select a file</p>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CompanyDialog;
