import * as React from "react";

import { useState, useEffect, useRef } from "react";
import { useImperativeHandle } from "react";
import { useSearchParams } from "react-router-dom";
import SearchDetail from "../components/Search/SearchDetail";

import Header from "../components/Common/Header";


import { useAuth0 } from "@auth0/auth0-react";

import { WebMercatorViewport } from 'deck.gl';
import axios from "axios";


import getBounds from "geolib/es/getBounds";




import { CircularProgress, Box, Typography } from "@mui/material";
import { mobileAndTabletCheck } from "../components/Search/Deck";



const PostSearch = React.forwardRef(({ user, dbData, filters, hideHeader, geometry,
  reloadOnMove, viewState, selectedLocation, setSelectedLocation }, ref) => {

  const searchDetailRef = React.useRef()
  const [geometryToUpdate, setGeometryToUpdate] = useState(geometry)


  const [minAvailSize, setMinAvailSize] = useState(filters?.availMin);
  const [maxAvailSize, setMaxAvailSize] = useState(filters?.availMax);

  const [compsCheck, setCompsCheck] = useState(filters?.compsCheck);
  const [allCheck, setAllCheck] = useState(filters?.allCheck);

  const [availablesCheck, setAvailablesCheck] = useState(
    filters?.availablesCheck
  );
  const [dateMin, setDateMin] = useState(filters?.dateMin);
  const [dateMax, setDateMax] = useState(filters?.dateMax);
  const [addingNewProperties, setAddingNewProperties] = useState(false);

  const [buildingPropertyType, setBuildingPropertyType] = useState(
    filters?.buildingPropertyType
  );
  const [availPropertyType, setAvailPropertyType] = useState(
    filters?.availPropertyType
  );
  const [surveyName, setSurveyName] = useState(filters?.SurveyName);
  const [surveyNotes, setSurveyNotes] = useState(filters?.Notes);
  const [filtersLocal, setFiltersLocal] = useState(filters)
  const [navHeight, setNavHeight] = useState(0);





  const [searchResultsIn, setSearchResultsIn] = useState(dbData && (dbData.length > 0) && dbData.sort((a, b) => {
    return (b.Latitude - a.Latitude)
  }) ? dbData : []);
  const [boundsRef, setBoundsRef] = useState(null);
  useImperativeHandle(ref, () => ({
    redoIcons() {

      searchDetailRef?.current?.redoIcons(selectedLocation)

    }
  }))



  function sortSetSearchResults(newResults) {
    setSearchResultsIn(newResults.sort((a, b) => {

      if (!a || !b || !a.Latitude || !b.Latitude) {
        return 0
      }
      return (b.Latitude - a.Latitude)
    }))
  }

  console.log("We have the folliwng initial results from the dbData " + (dbData && dbData.length))
  console.log("if this isn't the first one we are running from Search Results 506")

  var bounds = null

  const coordinates = (searchResultsIn && searchResultsIn.length > 0) ? searchResultsIn.map((result) => ({
    latitude: result && result.Latitude,
    longitude: result && result.Longitude,
  })) : []


  const viewport = new WebMercatorViewport({ // this is in case we don't have a camera pointed anywhere.  Probably should never happen
    width: 600,
    height: 400,
    longitude: -122.45,
    latitude: 37.78,
    zoom: 16,
    pitch: 41,
    bearing: 0
  });

  // There is a dilemma, are we getting the bounds of the camera and filtering, or are we taking in properties and changing the camera to fit
  // This depends on whether the camera has been dragged or a search was done, how will we know? I would assume that the properties that
  // come from the database were either a result of a search or the camera, so we should not need to filter by the camera, but 
  // if we moved the camera we would not want to move it again from here.  So its really whether it is a drag or whether it is a search



  function addProperties(propertiesIn) {

    propertiesIn.map((property) => {

      if (property && property.Latitude && property.Longitude) {

        coordinates.push({
          latitude: property.Latitude,
          longitude: property.Longitude,
        });


      }
    });


    if (coordinates && coordinates.length > 0) {


      var newCoords = coordinates.filter((each) => {
        if (!each.latitude) {
          return false
        }
        else {
          return true
        }
      })


      bounds = getBounds(newCoords)
    }


    //test - console.log("boundsy" + bounds)

    return bounds

  }


  var filteredResults = searchResultsIn


  if (!bounds && filteredResults && filteredResults.length > 0) { // 2. we get the bounds of the filtered properties
    //test - console.log("bounds part")
    bounds = addProperties(filteredResults);
  }

  //test - console.log("about to try fitbounds")
  console.log(bounds)
  console.log(viewState)

  
  const { longitude, latitude, zoom } = (bounds && !isNaN(bounds.minLng)) ? viewport.fitBounds([ // if we have bounds we have lat and long from it
    [bounds.minLng - .0005, bounds.minLat - .0005],
    [bounds.maxLng + .0005, bounds.maxLat + .0005]
  ]) : { longitude: null, latitude: null, zoom: null }

  if (viewState) {
    if (viewState.maxZoom < 15) {
      viewState.maxZoom = 19.5
    }
  }
  const [initialViewState, setInitialViewState] = useState(viewState ? viewState : bounds ? // this is the par that is tricky.
    //Have we moved the camera and should we even mess with this, or is it a new search that needs the camera pointed here
    // we basically need a drag state thing that turns on if we drag, otherwise we point the camera
    // howver this is the first load so there is no way we dragged it.  This would only be the case if it was not the initial
    {


      latitude: latitude,
      longitude: longitude,
      zoom: zoom,
      // minZoom: window.innerWidth > 700 ? 4 : 8,
      maxZoom: window.innerWidth > 700 ? 19.5 : 19.5,
      bearing: 0,
      pitch: 41
    } :
    (geometry ? { // if we told it where to go this is it.
      latitude: geometry.location.lat,
      longitude: geometry.location.lng,
      elevation: geometry ? geometry.location.elevation : 0,
      zoom: 15,
      // minZoom: window.innerWidth > 700 ? 4 : 8,
      maxZoom: window.innerWidth > 700 ? 19.5 : 19.5,
      bearing: 0,
      pitch: 41,
      reloadOnMove: true,
      fromBanner: true,

    } :

      null
    )
  );




  if (!filteredResults) {
    // alert("no results")



    return (
      <>
        {hideHeader ? (
          <>No Results</>
        ) : (

          <nav className="sticky shadow-md top-0" style={{ zIndex: 1350, maxHeight: mobileAndTabletCheck() ? 93 : 106 }}>
            <Header
              key="1"
              placeholderLocation={"Search Results"}
              icon="search"
              user={user}
              searchResults={null}
              setSearchResults={sortSetSearchResults}
              addProperties={addProperties}
              setAddingNewProperties={setAddingNewProperties}
              setGeometry={setGeometryToUpdate}
              setInitialViewState={setInitialViewState}
              searchDetailRef={searchDetailRef}
              filters={filters}


            />
          </nav>

        )}
      </>
    );
  } else {


    //test - console.log("returning from Search Results again" + JSON.stringify(bounds))
    return (

      <>
        {hideHeader ? (
          <></>
        ) : (<>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1, user-scalable=0"></meta>



          <Header
            key="1"
            placeholderLocation={"Search Results"}
            icon="search"
            user={user}
            searchResults={searchResultsIn}
            setSearchResults={setSearchResultsIn}
            addProperties={addProperties}
            setAddingNewProperties={setAddingNewProperties}
            setGeometry={setGeometryToUpdate}
            setInitialViewState={setInitialViewState}
            searchDetailRef={searchDetailRef}
            filters={filters}

          />



        </>
        )}
        {searchResultsIn || geometry || geometryToUpdate ? (

          <SearchDetail
            ref={searchDetailRef}
            reloadOnMoveIn={reloadOnMove}
            navHeight={navHeight}
            initialViewState={initialViewState}
            setInitialViewState={setInitialViewState}
            setFilters={setFiltersLocal}
            filters={filtersLocal}
            geometry={geometryToUpdate}
            searchResults={searchResultsIn}
            setSearchResults={setSearchResultsIn}
            user={user}
            setBoundsRef={setBoundsRef}
            setMinAvailSize={setMinAvailSize}
            setMaxAvailSize={setMaxAvailSize}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            minAvailSize={minAvailSize}
            maxAvailSize={maxAvailSize}
            dateMin={dateMin}
            setDateMin={setDateMin}
            dateMax={dateMax}
            setDateMax={setDateMax}
            buildingPropertyType={buildingPropertyType}
            setBuildingPropertyType={setBuildingPropertyType}
            availPropertyType={availPropertyType}
            setAvailPropertyType={setAvailPropertyType}
            bounds={bounds}
            setAddingNewProperties={setAddingNewProperties}
            addingNewProperties={addingNewProperties}
            surveyName={surveyName}
            setSurveyName={setSurveyName}
            surveyNotes={surveyNotes}
            setSurveyNotes={setSurveyNotes}
            compsCheck={compsCheck}
            setCompsCheck={setCompsCheck}
            allCheck={allCheck}
            setAllCheck={setAllCheck}
            availablesCheck={availablesCheck}
            setAvailablesCheck={setAvailablesCheck}
            projectName={
              filters?.project && filters?.project.ProjectName
                ? filters?.project.ProjectName
                : "unassigned project"
            }
            clientName={
              filters?.project &&
                filters?.project.client &&
                filters?.project.client.ClientName
                ? filters?.project.client.ClientName
                : "unassigned client"
            }
          />

        ) : (
          <>No Results</>
        )}

      </>

    )
  }
})

const getData = (qs) => {
  return new Promise(async function (resolve, reject) {

    var data = await axios.get(qs).catch((error) => { console.log(error) })
    if (data && data.data) {
      var searchResults = data.data

      if (searchResults.message === "Permission denied") {
        alert(
          "This information is protected. Please log in to proceed.  If you are logged in, please request to be added to the group with permission to view this survey from the person who sent you this survey."
        );
        document.cookie =
          "website=" + window.location.origin + ";SameSite='none'";

        document.cookie = "checkreroute=true" + "SameSite='none'";
        window.open("/", "_self")
        reject(-1)
        //loginWithRedirect();

      } else {
        resolve(searchResults)
        // setSearchResultsIn(searchResults);
      }
    }
  })
};

// const getFilters = (id, setFilters) => {
const getFilters = (id) => {
  return new Promise(function (resolve, reject) {

    fetch(process.env.REACT_APP_DATABASE + "api/getFilters?id=" + id)

      .then((data) => data.json())
      .then((searchResults) => {
        // setFilters(searchResults);

        resolve(searchResults)
      }).catch((error) => {
        reject(error)
      })
  })
};

export function Search({ user, contactPageId, hideHeader, reloadOnMove }) {


  const [resultsDataArray, setResultsDataArray] = useState(null)
  const [state, setState] = useState(null)
  let [query, setQuery] = useSearchParams();

  const [geometry, setGeometry] = useState(query.get("moveMeHere") ? JSON.parse(query.get("moveMeHere")) : null)
  const { loginWithRedirect } = useAuth0();

  const [dbData, setDBData] = useState(null);
  const mapRef = React.useRef();
  const postSearchRef = useRef();
  const [selectedLocation, setSelectedLocation] = useState(null);



  useEffect(() => {
    async function getData() {
      console.log("Main search just happened")

      var results = await SearchData(user, contactPageId, hideHeader, reloadOnMove, query, setQuery, geometry, setGeometry, loginWithRedirect, dbData, setDBData, mapRef)
        .catch((err) => {

          return (err);
        });


      setResultsDataArray(results);

    }

    getData();
  }, [user, contactPageId])

  useEffect(() => {

    postSearchRef?.current?.redoIcons(selectedLocation)
  }, [selectedLocation])



  // if (state === 'complete' && resultsDataArray && resultsDataArray.dbData) {

  if ((resultsDataArray === -1) || (contactPageId && (resultsDataArray?.dbData?.length === 0))) {

    if (contactPageId) {
      return <><Typography component={'div'} sx={{ margin: 2 }}>No current data</Typography></>
    }
    else {
      return <></>
    }
  }

  else if (resultsDataArray) {
    console.log("going to Post Search")
    
    return (

      <div style={{ width: window.innerWidth, height: window.innerHeight, overflow: 'hidden' }}>
        <PostSearch
          user={resultsDataArray.user}
          dbData={resultsDataArray.dbData}
          contactPageId={contactPageId}
          hideHeader={resultsDataArray.hideHeader}
          reloadOnMove={resultsDataArray.reloadOnMove}
          filters={resultsDataArray.filters}


          geometry={resultsDataArray.geometry}
          setGeometry={resultsDataArray.setGeometry}
          viewState={resultsDataArray.viewState}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          ref={postSearchRef}

        />
      </div>)
  }

  else {

    return (
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="100vh">
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Getting Search Data...
        </Typography>
      </Box>
    );
  }





}
export function SearchData(user, contactPageId, hideHeader, reloadOnMove, query, setQuery, geometry, setGeometry, loginWithRedirect, dbData, setDBData, mapRef) {

  let squareFeet = query.get("squareFeet");
  let surveyId = query.get("surveyId");
  let campusId = query.get("campus")
  let availMinQuery = query.get("squareFeet");
  let minAvail = query.get("minAvail");

  // const [returnValue, setReturnValue] = useState(null)
  // const [queryHappening, setQueryHappening] = useState(false);


  return new Promise(async function (resolve, reject) {




    var qs;
    if (minAvail) {
      qs =
        process.env.REACT_APP_DATABASE +
        "api/getAllBuildingsAvail?squareFeet=1" +
        "&contactId=" +
        user.contact.id;
    } else if (contactPageId) {
      qs =
        // process.env.REACT_APP_DATABASE + "/"
        process.env.REACT_APP_DATABASE +
        "api/getContactBuildingsAvails?" +
        query.toString() +
        "&contactId=" +
        user.contact.id +
        "&contactPageId=" +
        contactPageId;
    } else if (surveyId) {
      if (reloadOnMove !== true) {
        reloadOnMove = false
      }
      qs =
        // process.env.REACT_APP_DATABASE + "/"
        process.env.REACT_APP_DATABASE +
        // "api/getSurveyResults?" +
        "api/buildingcampusAll?" +
        query.toString() +
        "&contactId=" +
        user.contact.id;
    } else {

      if (campusId) {
        // if (reloadOnMove !== true) {
        reloadOnMove = false
        // }
      }
      qs =
        // process.env.REACT_APP_DATABASE + "/"
        process.env.REACT_APP_DATABASE +
        "api/buildingcampus?" +
        query.toString() +
        "&contactId=" +
        user.contact.id;
    }
    //test - console.log(qs);

    // if (!dbData && !queryHappening) {
    // if(!dbData)




    //test - console.log("Query Happening " + qs);
    var viewStateCookie
    console.log("looking up ViewState")
    var rawCookie

      =
      await axios.get(
        process.env.REACT_APP_DATABASE +
        "api/getViewStates?userId=" +
        user.contact.id
      ).catch((error) => {
        console.log(error)
      })



    if (!rawCookie) {
      console.log("no cookie")
      viewStateCookie = []
    }
    else {
      console.log("yes cookie")
      viewStateCookie = rawCookie.data.viewStates[0]
    }
    var MS_PER_MINUTE = 60000;
    var durationInMinutes = 0.5
    var mytime = Date.now()
    var myStartDate = mytime - (durationInMinutes * MS_PER_MINUTE);
    var dateThen = new Date(viewStateCookie && viewStateCookie.createdAt ? viewStateCookie.createdAt : null).getTime()
    
    // if (!viewStateCookie.ViewState.searchResults) {
    //   viewStateCookie.ViewState.searchResults = []
    // }
    if (viewStateCookie && (dateThen > myStartDate) && (window.location.href === viewStateCookie.ViewState.URL) && (!window.location.href.includes("surveyId="))) {

      console.log("we got location")
      

      // resolve({
      //   geometry: geometry,
      //   setGeometry: setGeometry,
      //   user: user,
      //   dbData: viewStateCookie.ViewState.searchResults,
      //   filters: viewStateCookie.ViewState.filters, 
      //   hideHeader: hideHeader,
      //   reloadOnMove: reloadOnMove,

      // })

      resolve({
        geometry: geometry,
        setGeometry: setGeometry,
        user: user,
        dbData: viewStateCookie.ViewState.searchResults,
        filters: viewStateCookie.ViewState.filters,
        hideHeader: hideHeader,
        reloadOnMove: reloadOnMove === false? reloadOnMove: true,
        viewState: viewStateCookie.ViewState.ViewState,
        transitionDuration: 1

      })
      
      return (true)

    }
    else {

      var results = await getData(qs).catch((error) => {
        console.log("error in fectch related buildings")
        reject({ reason: "axios error" })
        return (-1)
      }
      )

      // .then((results => {

      if (surveyId && (surveyId !== "null")) {

        var filters = await getFilters(surveyId)


        resolve({
          geometry: geometry,
          setGeometry: setGeometry,
          user: user,
          dbData: results,
          filters: filters,
          hideHeader: hideHeader,
          reloadOnMove: reloadOnMove
        })
        // this was set Initial View State={setInitialViewState}
        // mapReload={mapReload}
        return (true)
      }

      //  );

      // }

      else {


        // setQueryHappening(false)

        resolve({
          geometry: geometry,
          setGeometry: setGeometry,
          user: user,
          dbData: results,
          filters: {

            availMin: availMinQuery ? availMinQuery : 0,
            availMax: 200,
            dateMin: null,
            dateMax: null,

            buildingPropertyType: "Any",
            availPropertyType: "Any",
            compsCheck: true,
            allCheck: false,
            availablesCheck: true,
          },
          hideHeader: hideHeader,
          reloadOnMove: reloadOnMove
        }

          // this was set Initial View State={setInitialViewState}
          // mapReload={mapReload}
        )

        return (true)


      }

      // })
      // )
    }





  })
}



export default Search;

function sortByProperty(property) {
  return function (b, a) {
    if (a[property] > b[property]) return 1;
    else if (a[property] < b[property]) return -1;

    return 0;
  };
}

const scale = (value) => {
  const previousMarkIndex = Math.floor(value / 25);
  const previousMark = sfMarks[previousMarkIndex];
  const remainder = value % 25;
  if (remainder === 0) {
    return previousMark.scaledValue;
  }
  const nextMark = sfMarks[previousMarkIndex + 1];
  const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
  return remainder * increment + previousMark.scaledValue;
};




const sfMarks = [

  {
    value: 0,
    scaledValue: 0,
    label: "0",
  },
  {
    value: 25,
    scaledValue: 5000,
    label: "5k",
  },
  {
    value: 50,
    scaledValue: 10000,
    label: "10k",
  },
  {
    value: 75,
    scaledValue: 25000,
    label: "25k",
  },
  {
    value: 100,
    scaledValue: 50000,
    label: "50k",
  },
  {
    value: 125,
    scaledValue: 100000,
    label: "100k",
  },
  {
    value: 150,
    scaledValue: 250000,
    label: "250k",
  },
  {
    value: 175,
    scaledValue: 500000,
    label: "500k",
  },
  {
    value: 200,
    scaledValue: 1000000,
    label: "1M",
  },
];

function addMonths(numOfMonths, date) {
  var output = new Date(date);

  // output.setDate(date.getDate());
  if (date.getMonth() + numOfMonths > 12) {
    // output.setYear(date.getFullYear() + 1);
  }
  output.setMonth(date.getMonth() + numOfMonths);

  return output;
}
