import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, Button } from '@mui/material';

// Placeholder for the graph component
const CampaignStatsGraph = ({ stats }) => {
    // Placeholder for rendering a graph based on stats
    return <div>Graph will be here</div>;
};

const CampaignReportDialog = ({ campaign, open, onClose }) => {


    

    if (campaign?.statistics && open) {
        const hasData = Object.values(campaign?.statistics?.globalStats).some(value => value !== 0);
        return (
            <Dialog open={open} onClose={onClose} aria-labelledby="campaign-report-title">
                <DialogTitle id="campaign-report-title">Campaign Report</DialogTitle>
                <DialogContent>
                    {campaign.status !== 'sent' ? (
                        <DialogContentText>
                            The market piece hasn't been approved to be sent yet. Please schedule and activate the campaign.
                        </DialogContentText>
                    ) : hasData ? (
                        // Placeholder for your graph component
                        <CampaignStatsGraph stats={campaign.statistics} />
                    ) : (
                        <DialogContentText>
                            No data available for the campaign.
                        </DialogContentText>
                    )}
                </DialogContent>
                <Button onClick={onClose}>Close</Button>
            </Dialog>
        );
    }
    else {
        return <></>
    }
};

export default CampaignReportDialog