import Iframely from "../../Images/IFramely"
import { useState } from "react"


export  default function WebsiteCard ({website}) {
    const [open, setOpen] = useState(true)
    const [buttonText, setButtonText] = useState("Hide")
    var mysrcPost =  website.src.includes("http") || website.src.includes("https://") ? website.src : "https://"+website.src
    var url = mysrcPost

    function changeState() {
        if (open) {
            setOpen(false)
            setButtonText("Show")
        }
        else {
            setOpen(true)
            setButtonText("Hide")
        }
    }

return (
    <>     
            <div className = "sm:col-span-2">
                <div className="flex">

                <div className = "w-60"><h1 className="text-xl font-semibold text-gray-900">{website.Description}</h1></div>
                <div className = "px-6"><button className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto" onClick={changeState}>{buttonText}</button></div>
            
             </div>
             {
                open? <div className = "px-6 w-half py-8">
           
                <Iframely url = {url}/>
                {/* <iframe src={mysrc} title = {website.Description} width="100%" height="600" allowFullScreen="true"></iframe>  */}
             {/* : ""</div>} */}
                </div>
                :""
                }
             </div>
             </>

            )
  
}