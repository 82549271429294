import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';

const SurveyNameField = ({ selectedClient, selectedProject, surveyName, setSurveyName }) => {
    // Using a state to hold the value of the text field allows it to be editable
    
    // const [surveyName, setSurveyName] = useState('');

    // useEffect to update the surveyName whenever clientName or projectName changes
    useEffect(() => {
        const defaultSurveyName = `${selectedClient?.ClientName}-${selectedProject?.ProjectName} New Survey ${new Date().toLocaleDateString()}`;
        setSurveyName(defaultSurveyName);
    }, [selectedClient, selectedProject]);

    // Handling text field change
    const handleChange = (event) => {
        setSurveyName(event.target.value);
    };

    return (
        <TextField
            label="Survey Name"
            variant="outlined"
            fullWidth
            value={surveyName}
            onChange={handleChange}
        />
    );
};

export default SurveyNameField;
