import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Autocomplete, TextField, CircularProgress, Grid, Typography, Avatar, ListItemAvatar } from '@mui/material';
import debounce from 'lodash.debounce';

const CompanyAutoComplete = ({ extractDomain, setDomain, companyName, setCompanyName, selectedCompany, setSelectedCompany }) => {
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchCompanies = async (query) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_DATABASE}api/getCompaniesQuery`, { query });
            if (response?.data) {
                setCompanies(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch search results:', error);
        } finally {
            setLoading(false);
        }
    };

    const debouncedFetchCompanies = useCallback(debounce(fetchCompanies, 300), []);

    useEffect(() => {
        if (companyName) {
            setLoading(true);
            debouncedFetchCompanies(companyName);
        } else {
            setCompanies([]);
        }
    }, [companyName, debouncedFetchCompanies]);

    const handleInputChange = (event, newInputValue) => {
        setCompanyName(newInputValue);
        if (isValidDomain(newInputValue)) {
            setDomain(newInputValue);
            setSelectedCompany({ Name: newInputValue, Website: newInputValue });
        } else {
            setDomain(null);
            setSelectedCompany(null);
        }
    };

    const handleChange = (event, newValue) => {
        setSelectedCompany(newValue);
        setCompanyName(newValue?.Name || '');
        const extractedDomain = newValue?.Website ? extractDomain(newValue.Website) : null;
        setDomain(extractedDomain);
    };

    const isValidDomain = (domain) => {
        const domainPattern = new RegExp('^(?!-)[A-Za-z0-9-]+(?!-)$');
        const parts = domain.split('.');
        return parts.length > 1 && parts.every(part => domainPattern.test(part));
    };

    const renderOption = (props, option) => (
        <li {...props}>
            <Grid container alignItems="center">
                <Grid item>
                    <ListItemAvatar>
                        <Avatar
                            src={option?.images?.[0]?.img} alt={option?.Name}
                            style={{
                                marginRight: 2,
                                borderRadius: '10%',
                                objectFit: 'contain'
                            }}
                            imgProps={{
                                style: {
                                    objectFit: 'contain'
                                }
                            }}
                        />
                    </ListItemAvatar>
                </Grid>
                <Grid item xs>
                    <Typography variant="body1">{option?.Name}</Typography>
                    <Typography variant="caption" color="text.secondary">{option?.Website}</Typography>
                </Grid>
            </Grid>
        </li>
    );

    return (
        <Autocomplete
            fullWidth
            freeSolo
            options={companies}
            getOptionLabel={(option) => option?.Name || ''}
            renderOption={renderOption}
            value={selectedCompany}
            onChange={handleChange}
            inputValue={companyName}
            onInputChange={handleInputChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Company Name or Domain"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};

export default CompanyAutoComplete;
