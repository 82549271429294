/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useState } from 'react'
import { Check as CheckIcon, ExpandMore as SelectorIcon } from '@mui/icons-material'
import { Combobox } from '@headlessui/react'

const roles = [
  { id: 1, name: 'Landlord Broker' },
  { id: 2, name: 'Tenant Broker' },
  { id: 3, name: 'Sublandlord Broker' },
  { id: 4, name: 'Subtenant Broker' },
  { id: 5, name: 'Owner' },
  { id: 6, name: 'Tenant' },
  { id: 7, name: 'Asset Manager' },
  { id: 8, name: 'Capital Partner' },
  { id: 9, name: 'Client Manager' },
  { id: 10, name: 'Architect' },
  // More users...
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function RoleCombo({selectedRole, setSelectedRole}) {


  const [query, setQuery] = useState('')
  //const [selectedRole, setSelectedRole] = useState( { id: 1, name: 'Broker' })

  const filteredroles =
    query === ''
      ? roles
      : roles.filter((role) => {
          return role.name.toLowerCase().includes(query.toLowerCase())
        })

        if (filteredroles.length === 0  && selectedRole.name !== query) {

            setSelectedRole({id:1, name:query})
        }


  return (
    <Combobox id="roleValue" as="div" value={selectedRole} onChange={setSelectedRole}>
      <Combobox.Label className="block text-sm font-medium text-gray-700">Role</Combobox.Label>
      <div className="relative mt-1">
        <Combobox.Input
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500 sm:text-sm"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(role) => role.name}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredroles.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredroles.map((role) => (
              <Combobox.Option
                key={role.id}
                value={role}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-8 pr-4',
                    active ? 'bg-red-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('pl-3 block truncate', selected && 'font-semibold')}>{role.name}</span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 left-0 flex items-center pl-1.5',
                          active ? 'text-white' : 'text-red-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}


