import React from "react";
import Carousel from 'react-material-ui-carousel';

export default function TheCarousel({ imageList }) {
    // Determine whether to always show navigation buttons
    const shouldShowNavButtons = imageList.length > 1;

    return (
        <Carousel
            navButtonsAlwaysVisible={shouldShowNavButtons}
            autoPlay={false}
            swipe={true}
        >
            {imageList.map((item, i) => (
                <div key={"carousel-item-" + i} style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '70vh', // Adjust height to 70% of the viewport height
                    width: '100%'
                }}>
                    <img
                        src={item.original || item.img || "https://via.placeholder.com/150"} // Adding a placeholder for any missing images
                        alt={`Slide ${i}`}
                        style={{
                            maxHeight: '100%',
                            maxWidth: '100%',
                            objectFit: 'contain' // Ensures the image fits without being cropped
                        }}
                    />
                </div>
            ))}
        </Carousel>
    );
}
