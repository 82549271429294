import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from "@mui/material";
import ClientsCombo from "../Common/ClientProjectSurvey/ClientsCombo";

import ProjectsCombo from "../Common/ClientProjectSurvey/ProjectsCombo";
import axios from "axios";
import CampaignNameField from "../Common/ClientProjectSurvey/CampaignNameField";

function NewEmailCampaignDialog({
    open,
    setOpen,
    id,
    entityIds,
    entityType,
    onNew
   
    // Pass the rest of your props here
}) {

    const [clientsDataArray, setClientsDataArray] = useState(null)
    const [projectGroups, setProjectGroups] = useState(null);
    const [highestPermission, setHighestPermission] = useState("View");

    const [selectedClient, setSelectedClient] = useState({ id: 1, name: "" });
    const [clientId, setClientId] = useState(null);
    const [projectId, setProjectId] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);

    const [createProject, setCreateProject] = useState(false);
    const [addProject, setAddProject] = useState(false);
    const [addCampaign, setAddCampaign] = useState(false);
    const [buttonText, setButtonText] = useState("Save Campaign");
    const [projects, setProjects] = useState(null);

    const [newProjectName, setNewProjectName] = useState(
        "New Project - " + new Date().toLocaleDateString()
    );
    const [clientName, setClientName] = useState(null);
    const [showWait, setShowWait] = useState(null)
    const [campaignName, setCampaignName] = useState(
        ''
    );
    const [campaignNotes, setCampaignNotes] = useState(null);

    const navigate = useNavigate()

    useEffect(() => {

        const loadClients = async () => {
            var qs = process.env.REACT_APP_DATABASE + "api/myClients";
            
            var clientsDataArrayData = await axios.post(qs, { contactId: id }).catch((error) => { console.log(error) })
            if (clientsDataArrayData?.data) {
                var clients = clientsDataArrayData?.data.map((client) => {
                    client.clientId = client.id
                    return client
                })
                setClientsDataArray(clients)
            }
            else {
                alert("Error fetching clients")
            }

        }
        if (open) {
            loadClients()
        }
    }, [open])

    async function getProjects(clientId) {

        if (clientId) {
            var qs =
                process.env.REACT_APP_DATABASE +
                "api/getProjectsForClient?contactId=" +
                id +
                "&clientId=" +
                clientId;

            //test - console.log(qs);
            var response = await axios.get(qs)
            var projectsDataArray = response.data
            setProjects(projectsDataArray);
            setSelectedProject(projectsDataArray[0] || null);
        }
        else {

            setProjects([])
            setSelectedProject(null)
        }

    }

    const handleCreateCampaign = async () => {
        
        setShowWait(true)
        var ids = entityIds.map((entityId) => {
            return entityId.id
        })
        var response = await axios.post(
            process.env.REACT_APP_DATABASE +
            "api/createCampaign"
            ,
            {
                contactId: id,
                campaignName: campaignName,
                Notes: campaignNotes,
                clientId: selectedClient.id,
                projectId: selectedProject.id,
                entityType: entityType,
                entityIds: ids,
               
            }
        ).catch((error) => { console.log(error) })

        
        //test - console.log(data);
        if (response?.data?.id) {
    


            // navigate("/search?campaignId=" + newCampaignId)
            setShowWait(false)
        }
        else {
            alert("problem with save")
        }
        setOpen(false);
        if (onNew) {
            onNew()
        }




    }

    // Your axios calls and other logic here

    return (
        <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="client-modal-title">
            <DialogTitle id="client-modal-title">Choose or Create a Client for this New Campaign</DialogTitle>
            <DialogContent dividers>
                {
                    // Conditional rendering based on whether the clients data is loaded or not
                    clientsDataArray ? (
                        <>
                            <ClientsCombo
                                clients={clientsDataArray}
                                selectedClient={selectedClient}
                                setSelectedClient={setSelectedClient}
                                setShowProjects={setAddProject}
                                getProjects={getProjects}
                                setHighestPermission={setHighestPermission} 
                                setClientsDataArray={setClientsDataArray}
                                contactId={id}
                            />
                            <div style={{ marginTop: 10 }}>
                                {addProject && selectedClient && <ProjectsCombo
                                    clientId={clientId}
                                    selectedProject={selectedProject}
                                    projectsDataArray={projects}
                                    setProjectsDataArray={setProjects}
                                    setSelectedProject={setSelectedProject}
                  
                                    setCreateProject={setCreateProject}
                              
                                    contactId={id}
                              
                                    setProjectId={setProjectId}
                                    setProjectGroups={setProjectGroups}
                                    selectedClient={selectedClient}
                                    setAddSurvey={setAddCampaign}
                                />}
                            </div>
                            <div style={{ marginTop: 10 }}>
                                {(selectedClient && selectedProject) && <CampaignNameField selectedClient={selectedClient} selectedProject={selectedProject} setCampaignName={setCampaignName} campaignName={campaignName} />}
                            </div>
                            {/* Additional content goes here */}
                        </>
                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                        </div>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">
                    Cancel
                </Button>
                <Button variant={"contained"} disabled={(!campaignName) || (!selectedProject) || (!selectedClient)} onClick={async () => {
                    await handleCreateCampaign()
                }} color="primary">
                    {buttonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default NewEmailCampaignDialog;





