
import * as React from "react";
import { List, arrayMove } from "react-movable";
import DemoCarousel from "../Images/Carousel";

import { Folder as FolderOpenIcon, Delete as TrashIcon } from "@mui/icons-material"
import { mobileAndTabletCheck } from "../Search/Deck";

const addMe = async (dataIn, setDataIn, projectId, contactId) => {
  var fetchLocationAdd =
    process.env.REACT_APP_DATABASE + "api/addSurvey?id=" +
    projectId +
    "&contactId=" +
    contactId;
  //test - console.log(fetchLocationAdd);
  const resultInfo = await fetch(fetchLocationAdd);
  var fetchLocationGet =
    process.env.REACT_APP_DATABASE + "api/getProject?id=";
  const searchResults = await fetch(
    fetchLocationGet + projectId + "&contactId=" + contactId
  );
  const data = await searchResults.json();

  var myJson = data.project.surveys; // search results come in an arra
  var sort = orderSurveys(myJson, setDataIn);
};

export default function ProjectSurveyEditTable({
  dataIn,
  setDataIn,
  projectId,
  contactId,
  highestPermission,
}) {
  //test - console.log("reproject Edit Table");
  //test - console.log(dataIn);
  //setItems(mapArray(dataIn))

  const removeFromBothArrays = (
    index,
    newSurveysIn,
    surveyId,
    groupId,
    surveyGroupsArrayIndex
  ) => {
    // contactId is not coming through
    var newDataIn = [...dataIn];

    if (newDataIn.length === 0) {
      // if you have just added the contact for some reason it will show up here with no visibility to the peopleIn parameter
      newDataIn = [...newSurveysIn];
    }

    if (newDataIn.length > 0) {
      // database call
      ;
      deleteProjectSurveyRelationship(surveyId, groupId, contactId);

      newDataIn[index].survey_groups = arrayRemove(
        newDataIn[index].survey_groups,
        surveyGroupsArrayIndex
      );
      if (newDataIn[index].survey_groups.length === 0) {
        newDataIn = arrayRemove(newDataIn, index);
      }
      // setPeopleIn(arrayRemove(newDataIn, index));
      setDataIn(newDataIn);
      // return arrayRemove(items, index);
      //   setItems(mapArray(newDataIn, removeFromBothArrays));
    }
  };

  // const [items, setItems] = React.useState(
  //   mapArray(surveysIn, removeFromBothArrays)
  // );

  const moveBothArrays = (formattedArray, oldIndex, newIndex) => {
    //setItems(arrayMove(formattedArray, oldIndex, newIndex))
    setDataIn(arrayMove(dataIn, oldIndex, newIndex));
    orderSurveys(arrayMove(dataIn, oldIndex, newIndex), setDataIn);
  };

  function mapArray(surveyIn, setItems) {
    var myArray = [];
    var myPermission = "View"
    var nfObject = new Intl.NumberFormat("en-US");
    var nfObjectRate = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    var nfObjectDate = new Intl.DateTimeFormat("en-US");
    // 
    surveyIn.map((survey, index) => {
      var AVHighestPermission = "View";
      myArray.push(
        <div key={survey.id}>
          <div className="flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <div className="min-w-full ">
                    <div>
                      <table className="min-w-full">
                        <thead>
                          <tr className="text-left ">
                            <th className="w-60"></th>
                            <th className="w-20"></th>
                            <th className="w-20"></th>
                            <th className="w-20"></th>
                            <th className="w-40"></th>
                            <th className="w-20"></th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            className="text-center text-xs hover:cursor-pointer"
                            // onClick={async () => {
                            //   //const {Base64} = require('js-base64');
                            //   // var dataObjectBase64 = Base64.encode(survey.id.toString());
                            //   window.open("survey?id=" survey.id, mobileAndTabletCheck()?"_self": null);
                            //   //    await Alert('Alert text', 'Alert title');
                            // }}
                          >
                            <th className="w-80">
                              <div>
                                <div className="w-half">
                                  <DemoCarousel
                                    key={survey.id}
                                    images={survey.images}
                                  />
                                </div>
                                <div
                                  className="w-full flex items-center"
                                  onClick={async () => {
                                    //const {Base64} = require('js-base64');
                                    //var dataObjectBase64 = Base64.encode(survey.id.toString());
                                    window.open("search?surveyId=" + survey.id, mobileAndTabletCheck()?"_self": null);
                                    //    await Alert('Alert text', 'Alert title');
                                  }}
                                >
                                  {/* <div className="px-6 ">
                                    <div className="font-medium text-gray-900">
                                      {survey.Floor > 0
                                        ? "Floor " + survey.Floor
                                        : "Please Add Floor"}
                                    </div>
                                    <div className="font-medium text-gray-500">
                                      {" "}
                                      {survey.Suite !== "0" &&
                                      survey.Suite
                                        ? "Suite " + survey.Suite
                                        : "Please Add Suite"}
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            </th>
                        
                            <th className="w-20">
                              <div className="font-medium text-gray-900">
                                {survey.SurveyName ||
                                  "New Survey"}
                              </div>
                            </th>
                            <th className="w-20">
                              <div className="font-medium text-gray-900">
                                {new Date(survey.createdAt).toLocaleDateString() ||
                                  "Unkown Date"}
                              </div>
                            </th>
                            <th className="w-40 z-50">
                              <div className="font-medium text-gray-900 ">
                                {survey.survey_groups.map(
                                  (survey_group, groupArrayIndex) => {
                                    return (
                                      <div
                                        // GroupContactId={survey_group.id}
                                        // GroupId={survey_group.group.id}
                                        // GroupName={survey_group.group.GroupName}
                                        className="w-full"
                                      >
                                        <div className="flex justify-between w-full hover:bg-red-700">
                                          <div className="flex flex-col w-30 py-2 text-xs text-left whitespace-pre-line self-center">
                                            {/* <img src={survey_group.group.images[0].img}/> I'm not getting the images yet from DB  add later*/}
                                            <div>
                                              Group Name   : {survey_group.group.GroupName} {"\n"}
                                              {/* {survey_group.group.id}- */}
                                              Survey Rights  : {survey_group.groupPermission}
                                            </div>
                                            <div>
                                              Group Rights : 
                                              {
                                               survey_group.group.group_contacts.map(
                                                  (group_contact, index) => {
                                                    if (
                                                      AVHighestPermission ===
                                                        "View" &&
                                                      (group_contact.PermissionLevel ===
                                                        "Lead Edit" ||
                                                        group_contact.PermissionLevel ===
                                                          "Edit")
                                                    ) {
                                                      AVHighestPermission =
                                                        group_contact.PermissionLevel;
                                                    }
                                                    // ;
                                                    if (
                                                      group_contact.contact_id ===
                                                        contactId &&
                                                      group_contact.PermissionLevel !=
                                                        "Data"
                                                    ) {
                                                      if (
                                                        myPermission ===
                                                          "View" &&
                                                        (group_contact.PermissionLevel ===
                                                          "Edit" ||
                                                          group_contact.PermissionLevel ===
                                                            "Lead Edit")
                                                      ) {
                                                        myPermission =
                                                          group_contact.PermissionLevel;
                                                      }
                                                      if (
                                                        myPermission ===
                                                          "Edit" &&
                                                        group_contact.PermissionLevel ===
                                                          "Lead Edit"
                                                      ) {
                                                        myPermission =
                                                          group_contact.PermissionLevel;
                                                      }
                                                    } else {
                                                      // return "";
                                                    }
                                                    if (index ===  survey_group.group.group_contacts.length -1){
                                                      return " " + myPermission
                                                    }
                                                  }
                                                )
                                                }
                                            </div>
                                          </div>
                                          <div className="px-2 py-2">
                                            {((AVHighestPermission === "Edit" ||
                                              highestPermission ===
                                                "Lead Edit") &&
                                              survey_group.groupId === 1) ||
                                            AVHighestPermission === "Lead Edit" ||
                                            AVHighestPermission === "Edit" ||
                                            contactId === 1 ? (
                                              <button
                                                data-tip
                                                data-for="deleteTip"
                                                onClick={() => {
                                                  // ;
                                                  removeFromBothArrays(
                                                    index,
                                                    dataIn,
                                                    survey_group.surveyId,
                                                    survey_group.groupId,
                                                    groupArrayIndex
                                                  );
                                                }}
                                                className="z-50 rounded-md border border-transparent bg-red-600 px-2 h-6 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                                              >
                                                <TrashIcon
                                                  className="h-4 w-4 text-white shadow-sm"
                                                  aria-hidden="true"
                                                />
                           
                                              </button>
                                            ) : (
                                             <> {survey_group.groupId +" " + highestPermission}</>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </th>
                            <th className=" w-40">
                              <div>
                                {survey.contacts
                                  .sort(
                                    (a, b) =>
                                      a.survey_contact.sortOrder -
                                      b.survey_contact.sortOrder
                                  )
                                  .map((contact) => (
                                    <div className="flex items-center py-1">
                                      <div className="h-10 w-10 rounded-full">
                                        <img
                                          className="h-10 w-10 flex-shrink-0 rounded-full object-cover"
                                          src={
                                            contact.images.length > 0
                                              ? contact.images.sort(
                                                  (a, b) =>
                                                    a.sortOrder - b.sortOrder
                                                )[0].img
                                              : "https://images.cubicsearch.com/UserLogo.png"
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <div className="w-30">
                                        <div className="font-medium text-gray-900">
                                          {contact.FirstName} {contact.LastName}
                                        </div>
                                        <div className="text-gray-500">
                                          {contact.office &&
                                          contact.office.company
                                            ? contact.office.company.Name
                                            : "Add Company"}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });

    return myArray;
  }

  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      ></link>
      <div className=" pt-8 pb-2 flex justify-end ">
        <button
          type="button"
          className="inline-flex  self -center items-center content-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
          onClick={() => addMe(dataIn, setDataIn, projectId, contactId)}
        >
          <i className="fa fa-plus "></i>{" "}
          <div className="px-2">Add Survey</div>
        </button>
      </div>
      {dataIn.length > 1 ? (
        <div className="bg-gray-200 border-solid border-2  border-gray-100 ">
          <div className="px-6">Drag Rows to Change Order</div>
          <div className="px-6">
            <div className="flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden">
                    <div className="min-w-full ">
                      <div>
                        <table className="min-w-full">
                          <thead>
                            <tr className="text-left ">
                              <th className="w-60">Survey Picture</th>
                              <th className="w-20">Survey Name</th>
                              <th className="w-20">Survey Created</th>
                              {/* <th className="w-20">Condition</th> */}
                              <th className="w-40">
                                Groups Who Can See Survey
                              </th>
                              <th className="w-60">Contacts</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="mt-8 flex flex-col"></div>

      <List
        values={mapArray(dataIn)}
        onChange={({ oldIndex, newIndex }) =>
          moveBothArrays(mapArray(dataIn), oldIndex, newIndex)
        }
        renderList={({ children, props }) => <ul {...props}>{children}</ul>}
        renderItem={({ value, props, index }) => (
          <li {...props}>
            <div className="hover:bg-gray-50 ">
              <div className="flex w-full border">
                <div className="col-span-6 w-full">{value}</div>

                <div class="col-span-1 flex items-center justify-self-end px-4 ">
               
                  <div className="px-2">
                    <button
                      data-tip
                      data-for="openTip"
                      className=" rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                      onClick={async () => {
                        //test - console.log("I'm here waiting for the base64");

                        //    const {Base64} = require('js-base64');
                        var myArray = mapArray(dataIn);
                        //var dataObjectBase64 = Base64.encode(myArray[index].key.toString());
                        // window.open("search?surveyId=" + myArray[index].key, mobileAndTabletCheck()?"_self": null);
                        window.open("survey?id=" + myArray[index].key, mobileAndTabletCheck()?"_self": null);
                      }}
                    >
                      <FolderOpenIcon
                        className="h-4 w-4 text-white shadow-sm"
                        aria-hidden="true"
                      />
                    </button>
             
                  </div>
                </div>
              </div>
            </div>
          </li>
        )}
      />
    </>
  );
}

export function arrayRemove(array, index) {
  array = array.slice();
  array.splice(index, 1);
  return array;
}

async function deleteProjectSurveyRelationship(
  surveyId,
  groupId,
  contactId
) {
  var qs =
    "surveyId=" + surveyId + "&groupId=" + groupId + "&contactId=" + contactId;
  var resultInfo = await fetch(
    process.env.REACT_APP_DATABASE + "api/markDeleteSurveyGroup?" +
      qs
  );
  //test - console.log(
  //   process.env.REACT_APP_DATABASE + "api/markDeleteSurveyGroup?" +
  //     qs
  // );
  // ;
  // window.location.href = window.location.href;
}

async function orderSurveys(surveys, setDataIn) {
  //test - console.log("Update the order in the database");
  ////test - console.log(surveys)

  surveys.map(async (survey, index) => {
    //test - console.log(survey.id);
    var qs = "id=" + survey.id + "&sortOrder=" + index;
    var resultInfo = await fetch(
      process.env.REACT_APP_DATABASE + "api/sortProjectSurveys?" +
        qs
    );
    setDataIn(surveys);
    return resultInfo;
  });
}

// const removeFromDBandArray = (dataIn, setDataIn, index) => {
//   var newDataIn = [...dataIn];
//   //
//   deleteSurvey(newDataIn[index].id);
//   setDataIn(arrayRemove(newDataIn, index));

//   //return arrayRemove(formattedArray, index)
// };
