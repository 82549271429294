import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Grid, Typography, Tooltip, Avatar, Box } from '@mui/material';
import SearchForm from './SearchForm'
import MailOutlineIcon from '@mui/icons-material/MailOutline';

function SearchDialog({ open, handleClose, setContacts, contactId, mailListId, MailListName, recipients, fetchData }) {
    const [searchMethod, setSearchMethod] = useState('');


    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
            <DialogTitle>

                <span style={{ display: 'flex', alignItems: 'center' }}>

                    <MailOutlineIcon style={{ marginRight: 4 }} />
                    Recipient List: {MailListName}
                </span>

                <div style={{ marginLeft: 28, fontStyle: 'italic', fontWeight: 'bold', fontSize: 16 }}>{searchMethod === '' ? 'Select a Search Method to Find More Recipients' : 'Find Recipients and Add to List'}</div>

            </DialogTitle>
            <DialogContent style={{
                overflowY: 'auto',
                // maxHeight: '70vh'
            }}>
                {!searchMethod ? (
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item>
                            <Tooltip title={
                                <Box sx={{ maxWidth: 300, textAlign: 'start' }}>
                                    <Avatar
                                        alt="Example Image"
                                        src="https://images.cubicsearch.com/Tooltips/SearchLocation2.png"
                                        variant="square"
                                        sx={{ width: '100%', height: 'auto', marginTop: 1, borderRadius: 2 }}
                                    />
                                    <Typography color="inherit">1. Pick a location and diameter <br />2. Choose prospect companies by optional industry keywords <br />3. Get prospect company contacts and add to recipient lists</Typography>
                                </Box>
                            }
                                arrow
                            >
                                <Button variant="outlined" onClick={() => setSearchMethod('company')}>Location and Industry</Button>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title={
                                <Box sx={{ maxWidth: 300, textAlign: 'start' }}>
                                    <Avatar
                                        alt="Example Image"
                                        src="https://images.cubicsearch.com/Tooltips/CompanyTitleLocation.png"
                                        variant="square"
                                        sx={{ width: '100%', height: 'auto', marginTop: 1, borderRadius: 2 }}
                                    />
                                    <Typography color="inherit">1. Pick a prospect company <br />2. Enter optional job title key words and optional location <br />3. Get prospect company contacts and add to recipient lists</Typography>
                                </Box>
                            }
                                arrow
                            >
                                <Button variant="outlined" onClick={() => setSearchMethod('domain')}>Location or Job and Company</Button>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title={
                                <Box sx={{ maxWidth: 300, textAlign: 'start' }}>
                                    <Avatar
                                        alt="Example Image"
                                        src="https://images.cubicsearch.com/Tooltips/CompanyPersonLocation.png"
                                        variant="square"
                                        sx={{ width: '100%', height: 'auto', marginTop: 1, borderRadius: 2 }}
                                    />
                                    <Typography color="inherit">1. Enter person's name and company<br />2. Enter optional location <br />3. Get prospect company contacts and add to recipient lists</Typography>
                                </Box>
                            }
                                arrow
                            >
                                <Button variant="outlined" onClick={() => setSearchMethod('name')}>Name and Company</Button>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title={
                                <Box sx={{ maxWidth: 300, textAlign: 'start' }}>
                                    <Avatar
                                        alt="Example Image"
                                        src="https://images.cubicsearch.com/Tooltips/PersonNameOrEmail.png"
                                        variant="square"
                                        sx={{ width: '100%', height: 'auto', marginTop: 1, borderRadius: 2 }}
                                    />
                                    <Typography color="inherit">1. Enter person's name or email<br />2. Get prospect company contacts and add to recipient lists</Typography>
                                </Box>
                            }
                                arrow
                            >
                                <Button variant="outlined" onClick={() => {

                                    setSearchMethod('email')
                                }}>Name, Email, or Create Contact</Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        <SearchForm
                            searchMethod={searchMethod}
                            setSearchMethod={setSearchMethod}
                            // handleClose={handleClose}
                            // setContacts={setContacts}
                            nameIn={''}
                            domainIn={''}
                            companyIn={''}
                            updateMe={true}
                            office={null}
                            contactIn={null}
                            contactId={contactId}
                            mailListId={mailListId}
                            MailListName={MailListName}
                               recipients={recipients}
                                        fetchData={fetchData}



                        />
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
}

export default SearchDialog;
