import React, { useEffect, useRef } from 'react';

const fallbackHtmlContent = `
    <div style="font-family: Arial, sans-serif; text-align: center; padding: 20px;">
        <h2 style="color: #4CAF50;">Create Your Campaign</h2>
        <p>It looks like you haven't created any content yet.</p>
        <p>Why not start writing your own campaign or let our AI help you?</p>

        <p style="margin-top: 20px;">Need assistance? Our AI can guide you through the process of creating an effective campaign.</p>
    </div>
`;

const IframeContent = ({ htmlContent }) => {
    const iframeRef = useRef(null);

    useEffect(() => {
        if (iframeRef.current) {
            const doc = iframeRef.current.contentDocument;
            if (doc) {
                doc.open();
                doc.write(htmlContent || fallbackHtmlContent);
                doc.close();
            }
        }
    }, [htmlContent]);

    return (
        <iframe
            ref={iframeRef}
            title="Email Content"
            style={{ width: '100%', height: '900px', border: 'none' }}
        />
    );
};

export default IframeContent;

