import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import { EntityTypeSelectionForm } from './EntityTypeSelectionForm'; // Adjust the import path as necessary
import CustomerSubscriptionsDialog from './CustomerSubscriptionsDialog'
import axios from 'axios';

export const EntityTypeSelectionDialog = ({ contact, fetchCampaignsAndClients }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
      
    };

    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', position: 'relative' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'absolute', right: '10%', maxWidth: 'calc(50% + 300px)' }}>
                <Button variant="outlined" onClick={handleClickOpen}>
                    New Campaign
                </Button>
                {/* <Button style={{ marginLeft: 5 }} variant="contained" onClick={() => { redirectToCustomerPortal(contact.id, window.location.href) }}>
                    Manage Subscription(s)
                </Button> */}
                <Button sx={{marginLeft: 2}} variant="contained" color="primary" onClick={handleOpenDialog}>
                    Manage Subscriptions
                </Button>
                <CustomerSubscriptionsDialog open={openDialog} handleClose={handleCloseDialog} contact={contact} />
            </div>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">What Are You Marketing?</DialogTitle>
                <DialogContent>
                    <div style={{ padding: 10, margin: 5 }}>
                        <EntityTypeSelectionForm contact={contact} handleParentClose={handleClose} fetchCampaignsAndClients={fetchCampaignsAndClients} />
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

async function redirectToCustomerPortal(id, return_url) {
    const response = await axios.post(process.env.REACT_APP_DATABASE + 'api/createPortalSession', { id, return_url });

    if (response) {
        const session = await response.data;
        window.location.href = session.url;
    }
}

export default EntityTypeSelectionDialog;
