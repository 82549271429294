import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

function EntityTypeDialog({ open, onClose, setCampaignDetails }) {
    

    const handleListItemClick = (value) => {
        if (value === 'Person') {
            setCampaignDetails(currentState => {
                return {
                    ...currentState,
                    contactIds: [] // The new key-value pair you want to add
                };
            });
        }
        else if (value === 'Company') {
            setCampaignDetails(currentState => {
                return {
                    ...currentState,
                    companyIds: [] // The new key-value pair you want to add
                };
            });
        }
        else if (value === 'Building') {
            setCampaignDetails(currentState => {
                return {
                    ...currentState,
                    buildingIds: [] // The new key-value pair you want to add
                };
            });
        }
        else if (value === 'Space') {
            setCampaignDetails(currentState => {
                return {
                    ...currentState,
                    availIds: [] // The new key-value pair you want to add
                };
            });
        }
         onClose(); // Call the onClose function passed as prop to close the dialog
    };

    return (
        <Dialog open={open} onClose={() => onClose()}>
            <DialogTitle>Choose an Entity Type</DialogTitle>
            <DialogContent>
                <List>
                    {['Person', 'Company', 'Building', 'Space'].map((entityType) => (
                        <ListItem
                            button
                            onClick={() => handleListItemClick(entityType)}
                            key={entityType}
                        >
                            <ListItemText primary={entityType} />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default EntityTypeDialog;
