import React, { useEffect, useState } from "react";
import CompanyDetail from "../components/Companies/CompanyDetail"
import { useSearchParams } from "react-router-dom"

import Header from "../components/Common/Header";
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';




export default function Company({ user }) {

  let [query, setQuery] = useSearchParams();

  const [companyData, setCompanyData] = useState(null);
  const [buildingsData, setBuildingsData] = useState(null);
  const [error, setError] = useState(false);


  let id = query.get("id")




  const getData = async (id) => {
    try {
      const axiosCompanyData = await axios.get(`${process.env.REACT_APP_DATABASE}api/getCompany`, {
        params: { id }
      });
      setCompanyData(axiosCompanyData.data);

      const axiosCompanyBuildingData = await axios.get(`${process.env.REACT_APP_DATABASE}api/getCompanyBuildings`, {
        params: { id }
      });
      setBuildingsData(axiosCompanyBuildingData.data);
    } catch (error) {
      setError(true);
      console.error('Failed to fetch data:', error);
    }
  };


  useEffect(() => {
    if (id) getData(id);
  }, [id]);  // Ensures effect runs again if `id` changes





  if (!companyData || !buildingsData) {
    return (
      <div className="absolute top-1/3 w-full items-center flex flex-col">
        <CircularProgress />
        <div>Loading Company Data...</div>
      </div>
    );
  }

  if (error) {
    return ("Companys Error")

  }
  if (!companyData || !companyData.company || !buildingsData) {
    return (<div>
      <div>
        <Header user={user} />
      </div>
      <div className="py-4">
        <img src="https://images.cubicsearch.com/noresults.png" className="w-full" />
      </div>
    </div>)
  }

  //  const buildingsData = buildingsDataArray.companyBuildings;

  return <div>

    <div>
      <CompanyDetail companyInformation={companyData.company} buildingInformation={buildingsData} user={user} />
    </div>

  </div>

}
