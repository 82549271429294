import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Avatar, Stack, Divider, Box, IconButton, Popover, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Navigation } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CampaignSubscription from './CampaignSubscription';
import {EntityTypeSelectionFormEdit} from './EntityEditForm';

const CampaignCard = ({ campaign, preview, edit, contact, setEmailDetails }) => {
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);
    const [showEntitySelect, setShowEntitySelect] = useState(false);
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    let images = [];


    
    const checkSubscription = async () => {
        try {
            const subscriptionInfo = await axios.get(`${process.env.REACT_APP_DATABASE}api/check-subscription-status/${campaign.subscriptionId}`);
            if (subscriptionInfo?.data) {
                setSubscriptionDetails(subscriptionInfo.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (campaign?.subscriptionId) {
            checkSubscription();
        }
    }, [campaign]);

    if (campaign?.avails) {
        images = campaign.avails.map((avail) => {
            avail?.images?.sort((a, b) => a.sortOrder - b.sortOrder);
            return {
                img: avail?.images?.[0]?.img || null,
                text1: "Available Space: " + (avail?.PropertyType ? avail?.PropertyType : "") + " " + (avail?.SizeMin ? avail?.SizeMin?.toLocaleString() + " to " + avail?.SizeMax?.toLocaleString() : ""),
                text2: avail.building.StreetNumber + " " + avail.building.StreetName + ", " + avail.building.City
            };
        });
    } else if (campaign?.contacts) {
        images = campaign.contacts.map((contact) => {
            contact?.images?.sort((a, b) => a.sortOrder - b.sortOrder);
            return {
                img: contact?.images?.[0]?.img || null,
                text1: "Contact: " + contact.FirstName + " " + contact.LastName + " - " + contact.Title,
                text2: contact.office.company.Name + " " + contact.office.City
            };
        });
    } else if (campaign?.buildings) {
        images = campaign?.buildings?.map((building) => {
            building?.images?.sort((a, b) => a.sortOrder - b.sortOrder);
            return {
                img: building?.images?.[0]?.img || null,
                text1: "Building: " + building.PropertyType + " " + (building.Size ? building.Size.toLocaleString() + " SF" : ""),
                text2: building.StreetNumber + " " + building.StreetName + ", " + building.City
            };
        });
    } else if (campaign?.companies) {
        images = campaign.companies.map((company) => {
            company?.images?.sort((a, b) => a.sortOrder - b.sortOrder);
            return {
                img: company?.images?.[0]?.img || null,
                text1: "Company: " + company.Name,
                text2: truncateString(company?.offices?.map((office) => office?.City)?.join(","), 50)
            };
        });
    }

    function truncateString(str, num) {
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num > 3 ? num - 3 : num) + '...';
    }

    const handlePreviewClick = () => {
        if (preview && edit) {
            setShowEntitySelect(true);
        } else if (preview && !edit) {
            alert("In Edit mode you can add or change the entities you are marketing.");
        } else {
            navigate(`/campaignDashboard/campaigns/${campaign.id}/preview`);
        }
    };

    const handleMoreClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteClick = () => {
        setAnchorEl(null);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const   handleDeleteConfirm = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_DATABASE}api/campaigns/${campaign.id}`);
            navigate('/campaignDashboard/campaigns/');
            window.location.reload();
        } catch (error) {
            console.error('Failed to delete campaign:', error);
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const imageUrl = campaign?.project?.client?.images?.length > 0 ? campaign.project.client.images[0].img : undefined;

    

    return (
        <>
            <Card sx={{
                maxWidth: 345, m: 1, transition: 'box-shadow 0.3s ease-in-out',
                boxShadow: isHovered ? '5px 5px 15px rgba(0, 0, 0, 0.3)' : '2px 2px 4px rgba(0, 0, 0, 0.2)',
                cursor: 'pointer',
                position: 'relative'
            }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={handlePreviewClick}
            >
                {images.length > 0 && (
                    <Swiper
                        modules={[Navigation, Autoplay]}
                        navigation={images?.length > 0}
                        spaceBetween={50}
                        slidesPerView={1}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        style={{
                            height: '200px',
                            "--swiper-pagination-color": "#999",
                            "--swiper-navigation-color": "#999",
                        }}
                    >
                        {images.map((image, index) => (
                            <SwiperSlide key={index} style={{ width: '100%' }}>
                                <div
                                    style={{
                                        backgroundImage: `url(${image.img})`,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex'
                                    }}
                                >
                                    <div style={{
                                        width: '100%',
                                        position: 'absolute',
                                        top: '0%',
                                        left: '0%',
                                        color: 'white',
                                        textAlign: 'start',
                                        backgroundColor: 'rgba(0,0,0,0.5)',
                                        paddingLeft: 6,
                                        height: 80
                                    }}>
                                        <div>{image.text1}</div>
                                        <div>{image.text2}</div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}
                <CardContent>
                    <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
                        <Box
                            onClick={(event) => {
                                event.stopPropagation();
                                if (campaign?.project?.client?.id) {
                                    navigate(`/client?id=${campaign?.project?.client?.id}`);
                                }
                            }}
                            sx={{
                                width: 48,
                                height: 48,
                                borderRadius: '50%',
                                overflow: 'hidden',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#f0f0f0' // Fallback background color
                            }}
                        >
                            {imageUrl ? (
                                <img
                                    src={imageUrl}
                                    alt={campaign?.project?.client?.ClientName.charAt(0)}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain'
                                    }}
                                />
                            ) : (
                                <Avatar
                                    sx={{ width: 48, height: 48 }}
                                    alt={campaign?.project?.client?.ClientName.charAt(0)}
                                >
                                    {campaign?.project?.client?.ClientName.charAt(0)}
                                </Avatar>
                            )}
                        </Box>
                        <Stack>
                            <Typography
                                onClick={(event) => {
                                    event.stopPropagation();
                                    if (campaign?.project?.client?.id) {
                                        navigate(`/client?id=${campaign?.project?.client?.id}`);
                                    }
                                }}
                                variant="subtitle1" component="div">
                                Client: {campaign?.project?.client?.ClientName}
                            </Typography>
                            <Typography
                                onClick={(event) => {
                                    event.stopPropagation();
                                    if (campaign?.project?.id) {
                                        navigate(`/project?id=${campaign?.project?.id}`);
                                    }
                                }}
                                variant="body2" color="text.secondary">
                                Project: {campaign?.project?.ProjectName}
                            </Typography>
                        </Stack>
                        {contact.id === campaign.contactId && (
                            <IconButton
                                aria-describedby={id}
                                onClick={(event) => {
                                    event.stopPropagation()
                                    handleMoreClick(event)
                                }}
                                sx={{ marginLeft: 'auto' }}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        )}
                    </Stack>
                    <Divider sx={{ my: 1.5 }} />
                    <Typography variant="body1" gutterBottom>
                        Campaign: {campaign?.campaignName}
                    </Typography>
                    <Typography variant="subtitle" sx={{ fontSize: 12, color: 'darkgray' }}>
                        Created: {new Date(campaign?.createdAt).toLocaleString()}
                    </Typography>
                    <div>
                        <Typography variant="subtitle" sx={{ fontSize: 12, color: 'darkgray' }}>
                            Updated: {new Date(campaign?.updatedAt).toLocaleString()}
                        </Typography>
                    </div>
                </CardContent>
                {(campaign.subscriptionId === null || !subscriptionDetails?.isActive) && (
                    <Box>
                 
                        <CampaignSubscription
                            images={images}
                            campaign={campaign}
                            contact={contact}
                            isActive={subscriptionDetails?.isActive} />
                    </Box>
                )}
            </Card>
            {preview && <EntityTypeSelectionFormEdit open={showEntitySelect} onClose={() => { setShowEntitySelect(false) }} campaign={campaign} contact={contact} setEmailDetails={setEmailDetails} />}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Button onClick={handleDeleteClick}>Delete Campaign</Button>
            </Popover>
            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Campaign"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Deleting this campaign is permanent and cannot be undone. Are you sure you want to delete this campaign?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CampaignCard;
