import React from "react";
import Project from "../components/Projects/Project.js";
//import BingMapsReact from "bingmaps-react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import MapSinglePoint from "../components/Search/MapSinglePoint.tsx";
import Header from "../components/Common/Header.js";

export default function ProjectInfo({ user }) {
  let [query, setQuery] = useSearchParams();
  let id = query.get("id");
  var qs =
    process.env.REACT_APP_DATABASE + "api/getProject?id=" +
    id +
    "&contactId=" +
    user.contact.id;
  var qsInvitedTo =
    process.env.REACT_APP_DATABASE + "api/getGroupsInvitedTo?id=" +
    user.contact.id;

  const fetchData = async () => {
    const result = await fetch(qs);
    return await result.json();
  };

  const fetchGroupsInvitedToData = async () => {
    const result = await fetch(qsInvitedTo);
    return await result.json();
  };

  const fetchPeopleData = async () => {
    const result = await fetch(
      process.env.REACT_APP_DATABASE + "api/getContacts?contactId=" +
        user.contact.id
    );
    return await result.json();
  };

  //test - console.log(qs);
  const {
    data: projectDataArray,
    status: statusProjectData,
    error: errorProjectData,
  } = useQuery("projectData", fetchData);
  const {
    data: peopleDataArray,
    status: statusPeopleData,
    error: errorPeopleData,
  } = useQuery("peopleData", fetchPeopleData);

  const {
    data: groupsInvitedToData,
    status: statusGroupsInvitedToData,
    error: errorGroupsInvitedToData,
  } = useQuery("groupsInvitedToData", fetchGroupsInvitedToData);

  if (statusProjectData === "loading"){
    return (
      <>
        <div className="absolute top-1/3 w-full items-center flex flex-col">
          <img style={{width: 100}} src="https://images.cubicsearch.com/Wait.gif" />
          <div>Loading Project Data...</div>
        </div>
      </>
    );
  }

  if ( statusPeopleData === "loading") {
    return (
      <>
        <div className="absolute top-1/3 w-full items-center flex flex-col">
          <img style={{width: 100}} src="https://images.cubicsearch.com/Wait.gif" />
          <div>Loading Contacts Data...</div>
        </div>
      </>
    );
  }

  if (statusProjectData === "error" || statusPeopleData === "error") {
    return errorProjectData.message;
  }

  if (statusGroupsInvitedToData === "error") {
    return "Groups Invite To Error:" + errorGroupsInvitedToData.message;
  }

  if (
    !projectDataArray || !projectDataArray.project || 
    projectDataArray.project.length < 1 ||
    !peopleDataArray ||
    projectDataArray.length < 1
  ) {
    return (
      <div>
        <div>
          <Header user={user} />
        </div>
        <div className="py-4">
          <img src="https://images.cubicsearch.com/noresults.png" className="w-full"/>
        </div>
      </div>
    );
  }

  // const projectData = projectDataArray.project[0];
  const projectData = projectDataArray.project;
  const groups = projectDataArray.groups;
  // const people = getContacts(peopleDataArray);


  return (
    <div>
      <div>
        <Project
          ProjectInformation={projectData}
          // peopleCombo={people}
          user={user}
          groups={groups}
          groupsCombo={groupsInvitedToData}
        />
      </div>
    
    </div>
  );
}

function getContacts(data) {
  var peopleArray = [];
  //   const data = await searchResults.json();
  data?.contact?.map((person) => {
    var myJsonToAdd = {};
    if (person.images.length > 0) {
      if (person) {
        myJsonToAdd = {
          id: person.id,
          name:
            person.FirstName +
            " " +
            person.LastName +
            " - " +
            checkOffice(person),
          imageURL: person.images[0].img,
        };
      } else {
        myJsonToAdd = {
          id: person.id,
          name: person.FirstName + " " + person.LastName,
          imageURL: person.images[0].img,
        };
      }
    } else {
      if (person.office) {
        myJsonToAdd = {
          id: person.id,
          name:
            person.FirstName +
            " " +
            person.LastName +
            " - " +
            checkOffice(person),
          imageURL:
            "https://images.cubicsearch.com/UserLogo.png",
        };
      } else {
        myJsonToAdd = {
          id: person.id,
          name: person.FirstName + " " + person.LastName,
          imageURL:
            "https://images.cubicsearch.com/UserLogo.png",
        };
      }
    }
    peopleArray.push(myJsonToAdd);
  });

  return peopleArray.sort(sortByProject("name"));
}

function sortByProject(project) {
  return function (a, b) {
    if (a[project] > b[project]) return 1;
    else if (a[project] < b[project]) return -1;

    return 0;
  };
}

function checkOffice(person) {
  if (person.office && person.office.company) {
    return person.office.company.Name;
  } else return "Add Company";
}
