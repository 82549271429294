import React from "react";
import { useState } from "react";

import "keen-slider/keen-slider.min.css";
import Header from "../Common/Header.js";
import HeaderForClient from "./HeaderForClient.js";
import MainClientInfo from "./MainClientInfo.js";
import KeenSlider from "../Images/KeenSlider.js";

import ClientEdit from "./ClientEdit.js";
import { markDeleteImage, orderImages } from "../Availables/AvailableDetail.js";
import ImageUploadGallery from "../Common/Uploader/ImageUploadGallery.js";
import { Edit, Save } from "@mui/icons-material"
import axios from "axios";

async function updateClient(data) {
  //test - console.log("my Data" + data);

  const qs = Object.keys(data) // turn the parameters into a get query string parameters style
    .map((key) => `${key}=${encodeURIComponent(data[key])}`)
    .join("&");

  var resultInfo = await fetch(
    process.env.REACT_APP_DATABASE + "api/updateClient?" +
    qs
  );

  var var2 = await resultInfo.json();
  //test - console.log("results = " + var2);
}

export default function Client({
  ClientInformation,
  peopleCombo,
  user,
  groups,
  groupsCombo,
}) {
  const [clientStateData, setClientStateData] =
    useState(ClientInformation);
  const [editMode, setEditMode] = useState("View");
  const [editButtonText, setEditButtonText] = useState(<Edit sx={{ width: 18, height: 18 }} />);
  var initialPics = [];



  var highestPermission = "None";
  if (user.contact.id === 1) {
    highestPermission = "Lead Edit";
  } else {
    groups.map((group) => {
      group.client_groups.map((client_group) => {
        if (
          highestPermission !== "View" &&
          highestPermission !== "Edit" &&
          highestPermission !== "Lead Edit"
        ) {
          highestPermission = client_group.groupPermission;
        } else {
          if (
            (highestPermission === "View" &&
              client_group.groupPermission === "Edit") ||
            client_group.groupPermission === "Lead Edit"
          ) {
            highestPermission = client_group.groupPermission;
          } else if (
            highestPermission === "Edit" &&
            client_group.groupPermission === "Lead Edit"
          ) {
            highestPermission = client_group.groupPermission;
          }
        }
      });
    });
  }

  //test - console.log(clientStateData);
  clientStateData.images.map((image) => {
    initialPics.push({
      source: image.img,
      img: image.img,
      name: image.id,
      id: image.id
    });
    //return <></>
  });

  async function addClientImage(image) {


    var result = await axios.get(process.env.REACT_APP_DATABASE + "api/addClientImage", {
      params: {
        img: image.message,
        myId: user.contact.id,
        clientId: clientStateData.id,
        sortOrder: initialPics?.length || 0
      }
    })
    const updatedImages = [...initialPics, {
      source: result?.data?.img,
      img: result?.data?.img,
      url: result?.data?.img,
      name: result?.data?.id,
      id: result?.data?.id
    }];
    
    setClientStateData({ ...clientStateData, images: updatedImages });
  }



  return (
    <div>
      <nav className="sticky z-50 top-0">
        <Header
          key="1"
          placeholderLocation={
            clientStateData.ClientName
          }
          icon="client"
          user={user}
          highestPermission={highestPermission}
        />
      </nav>
      <div className="px-5 py-10">
        <HeaderForClient
          setEditMode={setEditMode}
          setEditButtonText={setEditButtonText}
          editButtonText={editButtonText}
          editMode={editMode}
          dataIn={clientStateData}
          editClick={editClick}
          setData={setClientStateData}
          highestPermission={highestPermission}
          contactId={user.contact.id}
        />
      </div>
      {/* <div className="flex flex-col">
        <div className="flex flex-col max-w-full sm:max-w-xl self-center">
          <div className="flex flex-col  max-w-full"> */}
      <div className="max-h-300 w-500  md:max-h-300 md:w-500 self-center ">
        {editMode === "View" ? (
          <KeenSlider dataIn={clientStateData} />
        ) : (
          <div style={{ marginLeft: 20 }}>
            <ImageUploadGallery
              initialImages={initialPics}
              onDeleted={(image) =>
                markDeleteImage(image, clientStateData, setClientStateData)
              }
              onSortEnd={async (images) => {

                await orderImages(
                  images,

                  clientStateData,
                  setClientStateData
                )
              }
              }
              action={process.env.REACT_APP_IMAGE}
              onSuccess={(image) => {
                addClientImage(image)
              }}
            />
          </div>

        )}
      </div>
      {/* </div>
      </div> */}
      {editMode === "View" ? (
        <MainClientInfo
          dataIn={clientStateData}
          peopleCombo={peopleCombo}
          setDataIn={setClientStateData}
          contactId={user.contact.id}
          groups={groups}
          groupsCombo={groupsCombo}
          highestPermission={highestPermission}
        />
      ) : (
        <ClientEdit dataIn={clientStateData} />
      )}
      {/* <div className="px-10 w-full h-300"> */}
      {/* <MapSinglePoint
          dataIn={ClientInformation}
          Latitude={ClientInformation.Latitude}
          Longitude={ClientInformation.Longitude}
          highestPermission={highestPermission}
        /> */}
      {/* {BingMap(clientData.Latitude, clientData.Longitude, pushPins)} */}
      {/* </div> */}
    </div>
  );
}






function editClick(
  setEditModeFunctionIn,
  setEditButtonTextFunctionIn,
  editMode,
  dataIn,
  setData
) {
  if (editMode === "Edit") {
    setEditModeFunctionIn("View");
    setEditButtonTextFunctionIn(<Edit sx={{ width: 18, height: 18 }} />);


    dataIn.Notes = updateJson(
      dataIn.Notes,
      document.getElementById("Notes").value.replace("&", " and ")
    );
    dataIn.ClientName = updateJson(
      dataIn.ClientName,
      document.getElementById("ClientName").value
    );

    setData(dataIn);
    updateClient(dataIn);
  } else {
    setEditModeFunctionIn("Edit");
    setEditButtonTextFunctionIn(<Save />);
  }
}

function updateJson(jsonFieldIn, reqValueIn) {
  if (reqValueIn !== "") {
    if (reqValueIn === -1) {
      jsonFieldIn = null;
    } else {
      jsonFieldIn = reqValueIn;
    }
  }
  // return jsonFieldIn;
  return reqValueIn;
}
