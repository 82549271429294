import { useState } from "react";
import BuildingGroupsEditTable from "./BuildingGroupsEditTable";
import BuildingGroupsRows from "./BuildingGroupsRows";
import { Save, Edit } from "@mui/icons-material";
import Accordion from '@mui/material/Accordion';
import { IconButton, Button, Typography } from "@mui/material";
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

async function setAdd(addingGroup, setAddingGroup, setButtonText, contactId) {
  var fetchLocation =
    process.env.REACT_APP_DATABASE + "api/addGroup?id=" + contactId;

  // const searchResults = await data.json();

  if (addingGroup) {
    setButtonText(<Edit sx={{width: 18, height: 18}} />);
    //const data = await fetch(fetchLocation);
    ////test - console.log(data);
  } else {
    setButtonText(<Save />);
  }
  setAddingGroup(!addingGroup);
}

export default function BuildingGroupList({

  groupsIn,
  setGroupsIn,
  contactId,
  buildingId,
  building,
  groupsCombo,
  highestPermission,
  setData,
  guest,
}) {

  const [addingGroup, setAddingGroup] = useState(false);
  //test - console.log("Group In:");
  //test - console.log(groupsIn);
  const [buttonText, setButtonText] = useState(<Edit sx={{ width: 18, height: 18 }} />);
  const [expanded, setExpanded] = useState(true)
  
  if (groupsIn.length > 0) {
    //test - console.log("groupsIn updates");
    //test - console.log(groupsIn);
    return (
      <>
        <div role="list" >
          <div className="flex sm:items-center">
            {/* <div> */}
            <div>

              <div className={"text-xl font-semibold text-gray-900"}>
                Building Discussion Groups 
              </div>
              <Typography component={'div'}variant="h7">
                Messages and Changes 
              </Typography>
            </div>




      <Button variant={'outlined'} sx={{ marginLeft:1, minWidth:0, width: 20, height: 24,  borderColor: 'black', color: 'black' }}

              onClick={() => {
                if (guest === true || guest === 'true') {
                  alert("Please login or sign up to make edits.");
                } else {
                  setAdd(
                    addingGroup,
                    setAddingGroup,
                    setButtonText,
                    contactId
                  );
                }
              }}
            >
              {buttonText}
            </Button>

          </div>
        </div>
        {addingGroup ? (
          <BuildingGroupsEditTable
            contactId={contactId}
            buildingId={buildingId}
            groupsIn={groupsIn}
            groupsCombo={groupsCombo}
            setGroupsIn={setGroupsIn}
            highestPermission={highestPermission}
            setData={setData}
          />
        ) : (
          <Accordion expanded={expanded} >
              <AccordionSummary>
                <Typography onClick={()=>{setExpanded(!expanded)}} component={'div'} sx={{ padding: 1, borderRadius: 2, backgroundColor: '#4e46e5', color: 'white' }}>Show Groups</Typography>
              </AccordionSummary>
            <AccordionDetails >
              <BuildingGroupsRows
                groupsIn={groupsIn}
                contactId={contactId}
                  highestPermission={highestPermission}
                  buildingId={buildingId}
                  building= {building}
              />
            </AccordionDetails>
          </Accordion>
        )}
      </>
    );
  } else {
    //test - console.log("NOTHING!!!");
    //test - console.log(groupsIn);
    return (
      <div role="list" className="px-0 sm:px-0 lg:px-0">
        <div className="flex justify-between sm:items-center">
          <h1 className="text-xl font-semibold text-gray-900">No Groups</h1>
          <div className="mt-4 sm:mt-0 sm:ml-16  sm:flex-none">
            <button
              type="button"
              className="inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
              onClick={() =>
                setAdd(addingGroup, setAddingGroup, setButtonText, contactId)
              }
            >
              {buttonText}
            </button>
          </div>
        </div>
        {addingGroup ? (
          <BuildingGroupsEditTable
            contactId={contactId}
            buildingId={buildingId}
            groupsIn={groupsIn}
            groupsCombo={groupsCombo}
            setGroupsIn={setGroupsIn}
            highestPermission={highestPermission}
            setData={setData}
          />
        ) : (
            <BuildingGroupsRows groupsIn={groupsIn} buildingId={buildingId} />
        )}
      </div>
    );
  }
}
