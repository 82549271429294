import { useState } from "react";
import PermissionInfo from "./PermissionsInfo";
import { Title } from "./PermissionsInfo";
import MissingFieldsSplash from "./MissingFieldsSplash";
import { Lock as LockClosedIcon, LockOpen as LockOpenIcon } from "@mui/icons-material";
import { Share } from "@mui/icons-material";

import { Button, CircularProgress, IconButton, Alert } from "@mui/material";
import { Close } from "@mui/icons-material";
import { mobileAndTabletCheck } from "../Search/Deck";


export default function MessageBanner({
  highestPermission,
  icon,
  placeholderLocation,
  entityId,
  contactId,
  guest,
  sponsor,
}) {
  const [showSplash, setShowSplash] = useState(false);
  const [showWait, setShowWait] = useState(false);
  const [show, setShow] = useState(highestPermission ? true : false);
  var message = PermissionInfo(icon, highestPermission);
  var title = Title(icon);

  if (!sponsor) {
    sponsor = {
      name: "Damon Schor",
      company: "CBRE Inc.",
      title: "Executive Vice President",
      phone: "(415) 819-4266",
      email: "damon.schor@cbre.com",
    };
  }
  // 
  if (show) {
    return (
      <div className="bg-indigo-600">
        <div className="mx-auto max-w-7xl py-3 sm:px-6 lg:px-8">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex w-0 flex-1 justify-center items-center">
              <span className="flex items-center  rounded-lg bg-indigo-800 p-2 justify-between sm:w-60">
                {title === "Building Details" ? (
                  <div>
                    <div className="flex justify-end ">
                      {window.innerWidth > 500 ? (
                        <button
                          data-tip
                          data-for="openTip"
                          className="inline-flex items-center justify-center rounded-md border-0 border-transparent  px-4 py-2 text-sm font-medium text-white focus:outline-none  focus:ring-offset-2 sm:w-auto"
                          onClick={() => {
                            setShowWait(true)
                            fetch(
                              process.env.REACT_APP_DATABASE +
                              "api/meta?id=" +
                              entityId
                            ).then(() => {
                              setShowWait(false)
                              navigator.clipboard.writeText(
                                "https://images.cubicsearch.com/meta/buildings/" +
                                encodeURI(
                                  placeholderLocation
                                    .replaceAll(" ", "")
                                    .substring(
                                      0,
                                      placeholderLocation
                                        .replaceAll(" ", "")
                                        .indexOf(",")
                                    ) +
                                  entityId +
                                  ".html"
                                )
                              );
                              alert("Share link copied to clipboard!");
                            });
                          }}
                        >
                          <Share className=" h-6 w-6" />
                        </button>
                      ) : (
                        <a
                          className="px-2"
                          onClick={() => {
                            fetch(
                              process.env.REACT_APP_DATABASE +
                              "api/meta?id=" +
                              entityId
                            ).then(() => {
                              navigator.clipboard.writeText(
                                "https://images.cubicsearch.com/meta/buildings/" +
                                encodeURI(
                                  placeholderLocation
                                    .replaceAll(" ", "")
                                    .substring(
                                      0,
                                      placeholderLocation
                                        .replaceAll(" ", "")
                                        .indexOf(",")
                                    ) +
                                  entityId +
                                  ".html"
                                )
                              );
                            })
                          }}
                          href={
                            `sms:&body=` +
                            "https://images.cubicsearch.com/meta/buildings/" +
                            encodeURI(
                              placeholderLocation
                                .replaceAll(" ", "")
                                .substring(
                                  0,
                                  placeholderLocation
                                    .replaceAll(" ", "")
                                    .indexOf(",")
                                ) +
                              entityId +
                              ".html"
                            )
                          }
                        >
                          {" "}
                          <Share className="text-white h-6 w-6" />{" "}
                        </a>
                      )}
          
                    </div>
                  </div>
                ) : title === "Available Details" ||
                  title === "Lease Transaction" ? (
                  <div>
                    <div className="flex justify-end ">
                      {window.innerWidth > 500 ? (
                        <button
                          data-tip
                          data-for="openTip"
                          className="inline-flex items-center justify-center rounded-md border-0 border-transparent  px-4 py-2 text-sm font-medium text-white focus:outline-none  focus:ring-offset-2 sm:w-auto"
                          onClick={() => {
                            fetch(
                              process.env.REACT_APP_DATABASE +
                              "api/metaAvail?id=" +
                              entityId
                            ).then((metaFileNameRaw) => {

                              metaFileNameRaw.json().then((metaFileName) => {
                                ;
                                navigator.clipboard.writeText(

                                  "https://images.cubicsearch.com/meta/avails/" +
                                  encodeURI(
                                    metaFileName.metaFileName
                                  )
                                );
                                alert("Share link copied to clipboard!");
                              })

                            });
                          }}
                        >
                          <Share className=" h-6 w-6" />
                        </button>
                      ) : (
                        <a
                          className="px-2"
                          onClick={() => {
                            fetch(
                              process.env.REACT_APP_DATABASE +
                              "api/metaAvail?id=" +
                              entityId
                            ).then(({ metaFileNameRaw }) => {


                              metaFileNameRaw.json().then((metaFileName) => {
                                navigator.clipboard.writeText(

                                  "https://images.cubicsearch.com/meta/avails/" +
                                  encodeURI(
                                    metaFileName.metaFileName
                                  )
                                );

                                window.open(`sms:&body=` + "https://images.cubicsearch.com/meta/avails/" +
                                  encodeURI(
                                    metaFileName.metaFileName
                                  ))
                              })
                            })
                          }}
                        // href={`sms:&body=` + "https://images.cubicsearch.com/meta/avails/" +
                        //   encodeURI(
                        //     metaFileName
                        //   )}
                        >
                          {" "}
                          <Share className="text-white h-6 w-6" />{" "}
                        </a>
                      )}
       
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="text-white font-medium text-xs sm:text-sm">
                  {placeholderLocation ? (
                    <>
                      {title}{" "}
                      <p>
                        {" "}
                        {placeholderLocation.replaceAll(
                          "- Suite null",
                          "- Suite tbd"
                        )}{" "}
                      </p>{" "}
                    </>
                  ) : (
                    title
                  )}
                </div>



              </span>

              <p className="ml-3 sm:px-6 truncate text-xs sm:text-sm font-medium text-white">

                <span className="hidden-mobile">You have</span>
                {highestPermission === "View" ? (
                  <LockClosedIcon style={{ paddingLeft: 4, paddingBottom: 6 }} className="text-white h-6 w-6" />
                ) : (
                  <LockOpenIcon style={{ paddingLeft: 4, paddingBottom: 6 }} className=" text-white h-6 w-6" />
                )}

                <span className="break-normal text-xs sm:text-sm">
                  {window.innerWidth <= 760 && highestPermission === "View"
                    ? ""
                    : " " + highestPermission + " Permission "}

                  <span className="hidden-mobile md:hidden">for this data </span> {" "}
                  {mobileAndTabletCheck() ? <></> : <Button variant="outlined" sx={{ textTransform: 'none', color: 'white', borderColor: 'white' }}
                    onClick={() => {
                      setShowSplash(true);
                    }}

                  >
                    Learn more
                  </Button>}
                  <IconButton
                    type="button"
                    className="-mr-1 flex rounded-md p-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                    onClick={() => {
                      setShow(false);
                    }}>
                    <Close sx={{ color: 'white' }} />
                  </IconButton>
                </span>
              </p>
              {highestPermission === "View" &&
                (icon === "property" || icon === "space") &&
                guest !== 1 ? (
                <div className="order-3 px-4 mt-2 w-30 flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
                  <a
                    onClick={() => {

                      requestPermission(entityId, contactId, icon);


                    }}
                    className="flex items-center justify-center rounded-md border border-transparent bg-white sm:px-4 py-2 text-xs sm:text-sm font-medium text-indigo-600 shadow-sm hover:bg-indigo-50 hover:cursor-pointer"
                  >
                    {window.innerWidth < 500
                      ? "Request Edit "
                      : "Request Edit Permission"}
                  </a>
                </div>
              ) : (
                <></>
              )}
            </div>






          </div>
          {/* {window.innerWidth < 500 ? (
            <div className="flex text-xs sm:text-sm text-white justify-center showOnMobile">
              Information Provided By:
            </div>
          ) : (
            <></>
          )} */}
          {/* <div className="flex text-xs sm:text-sm border-t text-white justify-center">
            <div className="hidden-mobile mt-2 px-2 ">
              Information provided by:
            </div>
            <div className="mt-2">
              {sponsor.name} |{" "}
              {(window.innerWidth < 500 ? "" : sponsor.title + " | ") +
                sponsor.company}
            </div>
            <div className="px-2 mt-2">
              {" "}
              <a style={{ color: 'white' }} href={"tel:" + sponsor.phone}>{sponsor.phone}</a>{" "}
            </div>
            <div className="px-2 mt-2">
              <a style={{color: 'white'}}
                className="cursor:pointer underline"
                href={"mailto:" + sponsor.email}
              >
                {sponsor.email}
              </a>
            </div>
          </div> */}
        </div>
        <MissingFieldsSplash
          open={showSplash}
          setOpen={setShowSplash}
          message={message}
          title={highestPermission + " Privaleges"}
        />
        {
          showWait ? (
            <>
              <div
                style={{ userSelect: "none" }} className="absolute top-1/3 w-full items-center flex flex-col">
                <CircularProgress />
                <Alert severity='info'>Preparing Share Link...</Alert>
              </div>
            </>
          ) : (
            <></>
          )}
      </div>
    );
  }
}

function requestPermission(entityId, contactId, icon) {
  var returnMessage = "";


  if (icon === "search") {
  } else if (icon === "property") {
    var url =
      process.env.REACT_APP_DATABASE +
      "api/addMeGroupBuilding?contactId=" +
      contactId +
      "&entityId=" +
      entityId;
    fetch(url).then((result) => {
      window.location.href = window.location.href;
    });
  } else if (icon === "space") {

    var url =
      process.env.REACT_APP_DATABASE +
      "api/addMeGroupAvailable?contactId=" +
      contactId +
      "&entityId=" +
      entityId;

    // alert(url)
    fetch(url).then((result) => {

      window.location.href = window.location.href;
    });
  } else if (icon === "space edit") {
    return "Available Details";
  } else if (icon === "contact edit") {
    return "Contact Details";
  } else if (icon === "parcel") {
    return "Parcel Details";
  } else if (icon === "company") {
    return "Company Details";
  } else if (icon === "group") {
    return "Group Details";
  } else if (icon === "comp") {
    return "Lease Transaction";
  }
  //return returnMessage
}
