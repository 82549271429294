import { useState } from "react";


export default function AddProperty() {
  const [open, setOpen] = useState(false);
  return (
    <>
      {/* <button
        className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-30"
        onClick={() => {
          setOpen(true);
        }}
      >
        <div className="flex">
        
          New Buiding
         
        </div>
        <AddPropertyData open={open} setOpen={setOpen} />
      </button> */}
    </>
  );
}
