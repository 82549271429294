
import axios from "axios"
import { createCookie } from "../Common/Cookies";
import Map from "@mui/icons-material/Map"

import { Tooltip, Typography } from "@mui/material";
import { Button } from "@mui/material";
import { Public } from "@mui/icons-material";


/* This example requires Tailwind CSS v2.0+ */
export default function HeaderForBuilding({
  setEditMode,
  setEditButtonText,
  dataIn,
  setData,
  editMode,
  editButtonText,
  editClick,
  highestPermission,
  contactId,
  guest,
}) {
 
  return (
    <>

  
      <div >
        <div >
     
          <div style={{display:'flex'}}>
            <Typography component={'div'}variant="h5" sx={{paddingRight: 3}}>
              Building
            </Typography>
             
           
            </div>
       
          <Typography component={'div'}variant="h6">
          
              {dataIn.StreetNumber} {dataIn.StreetName}{", "}{dataIn.City}
      
          </Typography>
          <Typography component={'div'}variant="h6">
            {dataIn.CampusName ? dataIn.CampusName : ""}
            {dataIn.CampusName && dataIn.PropertyName ? " | " : ""}
            {dataIn.PropertyName ? dataIn.PropertyName : ""}

          </Typography>
          <div style={{display: 'flex'}}>
          <div style={{paddingRight: 5}}>
          <Tooltip arrow title="Find Location on Main Interactive 3D Map" placement="top" >
            <Button
              variant="outlined"
              sx={{ color: 'black', borderColor: 'black' }}
              onClick={() => {
                createCookie("SearchMe", true, 1)
                var newSpot = { "location": { "lat": dataIn.Latitude, "lng": dataIn.Longitude, "elevation": 0 }, "viewport": { "northeast": { "lat": dataIn.Latitude + .01, "lng": dataIn.Longitude + .01 }, "southwest": { "lat": dataIn.Latitude - .01, "lng": dataIn.Longitude - .01 } } }
                window.location.href = "search?moveMeHere=" + encodeURI(JSON.stringify(newSpot))

              }} >
              3D Map<Public style={{ height: 20, width: 20 }} />
            </Button>
            </Tooltip>
          </div>
          <Tooltip  title="Parcel & Ownership Information" placement="top" >
            <Button
              variant="outlined"
              sx={{ color: 'black', borderColor: 'black' }}
              onClick={() => {
                //test - console.log("COW")
                //test - console.log(dataIn);

                if (dataIn.parcels && dataIn.parcels.length > 0) {
                  window.location.assign("/parcel?id=" + dataIn.parcels[0].id);
                } else {
                  alert("Adding a the parcel to this record.  Will update with a fresh parcel.");
                  getParcels(dataIn)

                }
              }}>
                Parcel  <Map style={{ height: 20, width: 20 }} />
            </Button>
          </Tooltip>
          </div>
      
        </div>
      </div>
    </>
  );
}
function getParcels(dataIn) {




  axios.post(
    process.env.REACT_APP_DATABASE +
    "api/getParcels", {
  
      buildingId: dataIn.id,
      buildingLocation: { Latitude: dataIn.Latitude, Longitude: dataIn.Longitude },
      buildingAddress: dataIn.StreetNumber + " " + dataIn.StreetName + ", " + dataIn.City + " " + (dataIn.State ? dataIn.State : "") + " " + dataIn.Zip,
      streetNumber: dataIn.StreetNumber,
      streetName: dataIn.StreetName,
      city: dataIn.City,
      state: dataIn.State ? dataIn.State : "",
      zip: dataIn.Zip ? dataIn.Zip : ""
    
  }

  ).then((response) => {
    //test - console.log("Info received")
    //test - console.log(response)
    window.location.assign("/parcel?id=" + response.data.parcelId)
  })

}