


export default function ProjectContactNew() {
    return (
         
        <div className="px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">First and Last Name</dt>
            <dd className="mt-1 text-sm text-gray-900">{inputComponent("text", "FirstName", "id", "FirstName")}  {inputComponent("text",  "LastName", "id", "LastName")} </dd>
            {/*<dd className="mt-1 text-sm text-gray-900">{nfObject.format(SizeMin)} - {nfObject.format(SizeMax)} SF</dd>*/}
        </div>
        <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Title</dt>
            <dd className="mt-1 text-sm text-gray-900">{inputComponent("text", "Title", "id", "Title")}</dd>
            {/*<dd className="mt-1 text-sm text-gray-900">{AskRate ? nfObjectRate.format(AskRate) : "Please Inquire"}/SF/Month {Type}</dd>*/}
            
        </div>
        <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Phone</dt>
            <dd className="mt-1 text-sm text-gray-900">{inputComponent("text", "Phone", "id", "Phone")}</dd>
            {/*<dd className="mt-1 text-sm text-gray-900">{Expenses ? nfObjectRate.format(Expenses) : "Please Inquire"}/SF/Month</dd>*/}
        </div>
        <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Email</dt>
            <dd className="mt-1 text-sm text-gray-900">{inputComponent("text", "Email", "id", "Email")}</dd>
            {/*<dd className="mt-1 text-sm text-gray-900">{AskIncreases ? nfObjectPercent.format(AskIncreases) : "Please Inquire"}</dd>*/}
        </div>
        <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Website</dt>
            <dd className="mt-1 text-sm text-gray-900">{inputComponent("text", "Website", "id", "Website")}</dd>                  
            {/*<dd className="mt-1 text-sm text-gray-900">{Condition || "TBD"}</dd>*/}
        </div>
        <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Linkedin</dt>
            <dd className="mt-1 text-sm text-gray-900">{inputComponent("text", "Linkedin", "id", "Linkedin")}</dd>
            {/*<dd className="mt-1 text-sm text-gray-900">{EstFreeRent || "Please Inquire"} </dd>*/}
        </div>
        <div className="sm:col-span-2">
                  <dt className="text-sm font-medium text-gray-500">About</dt>
                  <dd className="mt-1 text-sm text-gray-900">{inputComponent("text", "Notes", "id", "Notes", "w-full h-30")}</dd>
                  {/*<dd className="mt-1 text-sm text-gray-900">{jsonformsDataIn.EstFreeRent || "Please Inquire"} </dd>*/}
        </div>

            
    
        </dl>
        </div>
    )
        
}

function inputComponent(inputType, defaultShow, id, name, tailwind) {
    if (inputType === 'date') {
        return(
            <input
                className="shadow-sm focus:ring-red-500 focus:border-red-500  sm:text-sm border-gray-300 rounded-md"
                type={inputType}
                name={name}
                id={name}
                defaultValue={defaultShow && defaultShow !== 'null'? defaultShow : ""}
            />  
        )
    }
    else {
    return(
       
        <input
            className={"shadow-sm focus:ring-red-500 focus:border-red-500  sm:text-sm border-gray-300 rounded-md " + tailwind}
            type={inputType}
            name={name}
            id={name}
            defaultValue={defaultShow && defaultShow !== 'null'? defaultShow : ""}            
        />    
      
    )
    }
 }

