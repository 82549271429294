import React from 'react';
import { Typography, Grid, Paper } from '@mui/material';

function CompanyView({ companyDataIn }) {
    return (
        <Paper elevation={1} sx={{ p: 2, margin: 'auto', maxWidth: 600 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="text.secondary">
                        Name
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                        {companyDataIn.Name}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="text.secondary">
                        Website
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                        {companyDataIn.Website}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="text.secondary">
                        About
                    </Typography>
                    <Typography variant="body2" color="text.primary" sx={{ whiteSpace: 'pre-line' }}>
                        {companyDataIn.Notes}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default CompanyView;
