
import React, { useState,  } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Popover from '@mui/material/Popover';

import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import MailIcon from '@mui/icons-material/Mail';
import PersonIcon from '@mui/icons-material/Person';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout';
import CampaignIcon from '@mui/icons-material/Campaign';

import ClientSurveyGrid from '../Common/ClientSurveyGrid';
import { Chip, Typography } from '@mui/material';


// Custom styled Menu Item for icon alignment
const CustomMenuItem = styled(MenuItem)({
  display: 'flex',
  alignItems: 'start',
});
// Usage in a component
function EmailCampaignIcon() {


  return (
    <>      <CampaignIcon fontSize="large" sx={{ color: 'purple' }} />

    </>
  )
}




export default function UserMenu({ contact }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showGrid, setShowGrid] = useState(false);





  const navigate = useNavigate();
  const { logout } = useAuth0();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  return (
    <>
      <Tooltip title={`Menus and Account Info for ${contact.FirstName} ${contact.LastName} - ${contact.office && contact.office.company ? contact.office.company.Name : contact.Email}`}>
        <IconButton
          aria-describedby={id}
          variant="contained"
          onClick={handleClick}
          color="inherit"
        >
          <MoreVertIcon />
          <Avatar key={contact.FirstName + contact.LastName} alt={contact.FirstName + ' ' + contact.LastName}

            // onClick={() => { window.open("contact?id=" + contact.id, mobileAndTabletCheck() ? "_self" : null) }}
            sx={{ cursor: 'pointer' }}
            src={
              contact?.images?.length > 0
                ? contact.images.sort(
                  (a, b) => a.sortOrder - b.sortOrder
                )[0].img
                : null
            }

          >{contact.FirstName && contact?.FirstName?.length > 0 && contact.FirstName[0]}</Avatar>
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Paper sx={{ padding: 2, backgroundColor: '#f9f9f9', borderRadius: 2 }}>

          <MenuList>
            <CustomMenuItem onClick={() => setShowGrid(true)}>
              <ListItemIcon>
                <Avatar sx={{ borderRadius: '10%', height: 20, width: 20, objectFit: 'contain', marginTop: 1 }} src="https://images.cubicsearch.com/small%20survey.png" />
              </ListItemIcon>
              <ListItemText primary="Surveys, Clients, and Projects" secondary="View or edit property surveys, clients, projects, and more." />
            </CustomMenuItem>
            <CustomMenuItem onClick={() => navigate("/mygroups?id=" + contact.id)}>
              <ListItemIcon>
                <MailIcon sx={{ color: 'orange' }} />
              </ListItemIcon>
              <ListItemText primary="Message and Group Center" secondary="Review all messages and groups." />
            </CustomMenuItem>
            <CustomMenuItem onClick={() => {
              navigate("/contact?id=" + contact.id)
              // window.open("contact?id=" + contact.id)
            }}>
              <ListItemIcon>
                <PersonIcon sx={{ color: 'primary' }} />
              </ListItemIcon>
              <ListItemText primary="Manage Profie" secondary="Change your photos, contact information, and more." />
            </CustomMenuItem>
            <CustomMenuItem onClick={() => {
               navigate("/campaignDashboard/campaigns") 
            }}>
              <ListItemIcon>
                <EmailCampaignIcon  />
              </ListItemIcon>
              <ListItemText primary={"Email Campaigns"} secondary="Get the word out on your listing or services!" />
            </CustomMenuItem>
            <CustomMenuItem onClick={() => {
              document.cookie = "guest=true" + ";SameSite='none'"
              logout({ returnTo: window.location.origin });
            }}>
              <ListItemIcon>
                <LogoutIcon sx={{ color: 'darkgray' }} />
              </ListItemIcon>
              <ListItemText primary="Logout or Change Password" secondary="Logout to change user account or password." />
            
            </CustomMenuItem>
            {/* Add more menu items as needed */}
           
            <Chip
              sx={{margin: 2, marginLeft: 6}}
              label={
                <Typography variant="h6" sx={{ color: 'indigo', margin: 2, fontSize: 16}}>
                  Signed in as {contact.Email}
                </Typography>
              }
            />
       
          </MenuList>
        </Paper>
      </Popover>
      <ClientSurveyGrid open={showGrid} setOpen={setShowGrid} contactId={contact.id} />
     
    </>
  );
}
