import React, { useState, useMemo, useCallback } from 'react';
import { Box, Snackbar, Alert, IconButton, Chip } from '@mui/material';
import Close from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';

const SnackTour = ({ surveyId, clientName, projectName, getGeoInfo }) => {
    const [openAlert, setOpenAlert] = useState(true);

    const handleClose = useCallback(() => {
        setOpenAlert(false);
    }, []);

    const handleSearchClick = useCallback(() => {
        getGeoInfo()
            .then((coords) => {
                if (!coords) {
                    alert("Please either enable your location, or just put in an address in the search bar. Your location services are turned off for your browser.");
                } else {
                    const newSpot = {
                        location: { lat: coords[1], lng: coords[0], elevation: coords[2] },
                        viewport: {
                            northeast: { lat: coords[1] + 0.01, lng: coords[0] + 0.01 },
                            southwest: { lat: coords[1] - 0.01, lng: coords[0] - 0.01 },
                        },
                    };
                    window.location.href = "search?moveMeHere=" + encodeURI(JSON.stringify(newSpot));
                }
            })
            .catch(() => {
                alert("Please either enable your location, or just put in an address in the search bar. Your location services are turned off for your browser.");
            });
    }, [getGeoInfo]);

    const alertAction = useMemo(
        () => (
            <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleClose}
            >
                <Close fontSize="inherit" />
            </IconButton>
        ),
        [handleClose]
    );

    if (!surveyId) return null;

    return (
        <Box sx={{ width: '100%' }}>
            {openAlert && (
                <Snackbar
                    open={openAlert}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    autoHideDuration={3000}
                    onClose={handleClose}
                >
                    <Alert action={alertAction} sx={{ mb: 2 }}>
                        This is a survey for <Chip label={clientName} variant="outlined" /> project <Chip className="pl-2" label={projectName} variant="outlined" />
                        <div className="pt-2">
                            I'd like to browse outside of this survey...
                            <IconButton onClick={handleSearchClick}>
                                <Search />
                            </IconButton>
                        </div>
                    </Alert>
                </Snackbar>
            )}
        </Box>
    );
};

export default SnackTour;
