
import { useState,  } from "react";


import { Share, RemoveCircle, ZoomIn } from "@mui/icons-material";

import ConfirmInfo from "../Common/ConfirmInfo";

import { ConvertImagePath } from "../Common/ThumbFiles";

import { useInView } from "react-intersection-observer";
import TabsComponent from "./TabsComponent";
import { AvatarGroup, Button, Card, CardContent, CardHeader, Chip, Divider, IconButton, TextField, Typography } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Unstable_Grid2';
import { Paper } from "@mui/material";
import Tooltip from '@mui/material/Tooltip'
import { mobileAndTabletCheck } from "./Deck";
import {  styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { CardMedia, Avatar } from "@mui/material";

import { Public } from "@mui/icons-material";

import {  ShortDemoCarousel } from "../Images/Carousel";
import Messages, { BuildingSubMessages, AvailableSubMessages } from "./Messages/Messages"
import {  DirectionsCar, Favorite } from "@mui/icons-material";

import axios from "axios"

import FavoriteDialogue from "./FavoriteDialogue";
import * as React from 'react';
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";


import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';

import { red } from '@mui/material/colors';


import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareSurveySplash from "../Common/TextOrCopySplash";



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

var nfObject = new Intl.NumberFormat("en-US");
var nfObjectRate = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
var nfObjectPercent = new Intl.NumberFormat("en-US", { style: "percent" });

function InfoCard({
  property,
  onHover,
  showMe,
  onClick,
  searchResults,
  setSearchResults,
  selectedLocation,
  setSelectedLocation,
  removeButton,
  confirm,
  setConfirm,
  contactId,
  printing,
  index,
  setMapButton,
  mapButton,
  removeProperty,
  showGoogle,
  showSurveys,
  setShowSurveys,
  surveyId,
  setSaveSurveyOpen
}) {

  const [plusCode, setPlusCode] = useState(null)
  const { ref, inView, entry } = useInView({
    /* Optional options */
    triggerOnce: true,
    threshold: 0,
  });

  // useEffect(() => {
  //   //do something here when inView is true
  // }, [inView])

  const [showConfirm, setShowConfirm] = useState(false);
  const [favShowDialogue, setFavShowDialogue] = useState(false)
  const [showSurveySplash, setShowSurveySplash] = useState(false)
  const [shareLink, setShareLink] = useState(null)


  var splicedArray = searchResults
  var totalAvail = 0
  property.avails ? property.avails.map((available) => {
    totalAvail =
      totalAvail + (available.Status !== "Lease Comp" ? available.SizeMax : 0);
  }) : totalAvail = 0


  // ;

  var availsOutput = []
  var compsOutput = []

  var availsPhoneOutput = []
  var compsPhoneOutput = []
  var size
  var footprint


  if (property && property.properties && property.properties.Size) {
    size = property.properties.Size
  }
  else if (property.properties && property.properties.Footprint) { footprint = property.properties.Footprint }
  if (property && property.avails) {
    property.avails.sort((a, b) => a.Floor - b.Floor)
      .map((available) => {
        if (available.Status !== "Lease Comp") {
          availsOutput.push(
            <div key={"avail" + available.id} style={{ paddingBottom: 2 }}>
              <Paper sx={{ margin: 2, padding: 2 }} elevation={4}>
                {AvailData(available, printing)}
              </Paper>
            </div>
          );
          availsPhoneOutput.push(
            <div key={"phone" + "avail" + available.id} style={{ paddingBottom: 2 }}>
              <Paper sx={{ margin: 2, padding: 2 }} elevation={4}>
                {AvailPhoneData(available)}
              </Paper>
            </div>
          )
        } else {
          compsOutput.push(
            <div key={"comp" + available.id} style={{ paddingBottom: 2 }}>
              <Paper sx={{ margin: 2, padding: 2 }} elevation={4}>
                {CompData(available, printing)}
              </Paper>
            </div>
          );
          compsPhoneOutput.push(
            <div key={"compphone" + available.id} style={{ paddingBottom: 2 }}>s
              <Paper sx={{ margin: 2, padding: 2 }} elevation={4}>
                {CompPhoneData(available)}
              </Paper>
            </div>
          )
        }
      })
  }
  else {

  }






  //test - console.log("showing a card")
  var City =
    property.City ? property.City :
      (property.properties && property.properties.City) ? property.properties.City : ""
  var CampusName =
    property.CampusName ? property.CampusName :
      (property.properties && property.properties.CampusName) ? property.properties.CampusName : ""
  var PropertyName =
    property.PropertyName ? property.PropertyName :
      (property.properties && property.properties.PropertyName) ? property.properties.PropertyName : ""

  var StreetNumber =
    (property.StreetNumber && (property.StreetNumber.toLowerCase() != 'null')) ? property.StreetNumber :
      (property.properties && property.properties.StreetNumber && (property.properties.StreetNumber.toLowerCase() != 'null')) ? property.properties.StreetNumber : ""
  var StreetName =
    property.StreetName ? property.StreetName :
      (property.properties && property.properties.StreetName) ? property.properties.StreetName : ""
  var State =
    property.State ? property.State :
      (property.properties && property.properties.State) ? property.properties.State : ""



  var PropertyType

  if (property.PropertyType) {
    PropertyType = property.PropertyType
  }
  else if (property && property.properties && property.properties.PropertyType) {
    PropertyType = property.PropertyType
  }
  else if (property.avails && (property.avails.length) > 0 && (property.avails[0].PropertyType)) {
    PropertyType = property.avails[0].PropertyType
  }

  if (property.images.length > 0) {

  }

  return (
    <div
      key={"info" + property.id}
      id={property.id}
      style={{
        borderRadius: 20, width: '100%', display: 'flex', justifyContent: 'center',
        // backgroundImage: "url("+(property?.images[0]?.img)+ ")",
        // backgroundSize: 'cover', // Adjust how the background image is displayed
        // backgroundPosition: 'center', // Center the background image
      }}

      className={
        printing
          ? " px-2 border-2"
          : "px-2 border-b cursor-pointer hover:opacity-80 hover:shadow-lg transition duration-200 ease-out first:border-t"
      }

      onMouseOver={() => {
        if (!printing && onHover) {
          onHover(property);
        }
      }}
    >
      <div style={{ width: printing ? null : '100%', }}>


        {

          // printing ? (
          // <></>
          // )
          // : (

          <div className="flex justify-end items-start py-2 ">


            <div className="flex justify-end  ">
              <Tooltip arrow placement="top" title={"Driving Directions"}>
                <div>
                  <a href={"https://www.google.com/maps/dir/?api=1&destination=" + StreetNumber
                    + "+" +
                    StreetName
                    + "+" +
                    City
                    + "+" +
                    State

                  } target="_blank"><DirectionsCar sx={{ color: 'red' }} /></a>
                </div>
              </Tooltip>
              <Tooltip arrow title="Copy Weblink to Clipboard" placement='top'>
                <div>
                  <button

                    className="inline-flex items-center justify-center border-0 border-transparent  px-4 text-sm font-medium text-red-600 focus:outline-none sm:w-auto"
                    onClick={() => {
                      
                      setShareLink(
                        "https://images.cubicsearch.com/meta/buildings/" +
                        encodeURI(StreetNumber) +
                        encodeURI(StreetName) +
                        encodeURI(property.id) +
                        ".html"

                      );
                      setShowSurveySplash(true)

                    axios.get(process.env.REACT_APP_DATABASE + "api/meta?id=" + property.id).then((result) => {
                      
                 
                    }).catch((err) => { console.log(err.message) })
                     
              




                    }}
                  >

                    <Share className=" h-6 w-6" />

                  </button>
                </div>
              </Tooltip>


              {selectedLocation?.id === property.id || setSelectedLocation ?
                <>
                  <Tooltip arrow placement="top" title="Add to survey">
                    <div key={property.id + "fav"} id={property.id + "fav"}>
                      <button> <Favorite sx={{ color: 'red' }} onClick={async () => {
                        if (!surveyId) {

                          setFavShowDialogue(true)



                        }
                        else {
                          var results = await addToSurvey(surveyId, property.id, contactId)
                        }

                      }}></Favorite></button>
                    </div>
                  </Tooltip>
                  <FavoriteDialogue open={favShowDialogue} setOpen={setFavShowDialogue}
                    setShowSurveys={setShowSurveys}
                    setSelectedLocation={setSelectedLocation}
                    selectedLocation={selectedLocation}
                    property={property}
                    setSaveSurveyOpen={setSaveSurveyOpen}
                    setSearchResults={setSearchResults}

                  />
                </>

                : <></>}
              {/* // only add the favorite if we can use it */}
            </div>

            {printing ? <>  <div style={{ width: 40, height: 40 }} className="flex justify-center items-center text-white rounded-full bg-red-600 ">
              {index + 1}{" "}
            </div></> : <div className="flex justify-end ">

              <Tooltip arrow placement="top" title="Remove from Survey">
                <div>
                  <button

                    className={

                      (removeButton
                        ? "inline-flex items-center justify-center border-0 border-transparent  px-4 text-sm font-medium text-red-600 focus:outline-none sm:w-auto"
                        : " hidden")
                    }
                    onClick={(e) => {
                      // e.stopPropagation();
                      removeProperty(property)
                      if (window.location.href.includes("survey")) {
                        //                       alert(
                        //                         `Make sure you save the survey with the properties you want to keep on the screen.
                        // If they are not there, and you want to save your survey:
                        // 1. Reload the survey to have all the properties showing
                        // 2. Minus the property you don't want 
                        // 3. Save the survey with the reduced amount

                        // If you just want results off the screen then you can use the minus sign with no saving.

                        // If you don't want to save over this survey accidentally, please go to the home screen and start a new search.`)
                      }
                      setSelectedLocation(null)
                      // splicedArray = removeById(searchResults, property.id);

                      // setSearchResults(searchResults);

                      // if (hideMe) {
                      //   hideMe(property.id);
                      // }

                      // //test - console.log("Splice Here" + property.id);
                    }}
                  >
                    {" "}
                    <RemoveCircle className=" h-6 w-6" />{" "}
                  </button>
                </div>

              </Tooltip>
            </div>}
          </div>



          // )
        }

        {
          // window.innerWidth > 1 ?
          (
            <>
              {" "}
              <div className={printing ? "flex  py-8" : "flex"}>
                <div className="flex flex-col w-full">
                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }} className=" ">
                    {property.images ?
                      property.images.length > 0 ? (
                        printing ? (
                          <>
                            <div className="flex" >
                              <div  >
                                <a
                                  href={
                                    "https://cubicsearch.com/property?id=" + property.id
                                  }
                                >
                                  {
                                    (() => {

                                      if (property.images && property.images[0] && (property.images[0].img && (!property.images[0].img.includes("google")))) {
                                        return (
                                          <>
                                            < img style={{ height: 300, width: 500, objectFit: 'cover' }}
                                              className="rounded-lg"
                                              src={ConvertImagePath(property.images[0].img)
                                              } />
                                          </>)
                                      }
                                      else {
                                        return (
                                          <>
                                            <img
                                              className="h-full rounded-lg"
                                              src={"https://dev.virtualearth.net/REST/V1/Imagery/Map/Birdseye/" +
                                                property.Latitude.toString().trim() +
                                                "," +
                                                property.Longitude.toString().trim() +
                                                "/20?dir=270&ms=900,700&key=AntwMqF4SuxAC8AfmB9-AuOVzrtOhbiNMZFVVe68dkbt5IjzUIzmWS8dfFmo9-IL"}
                                            />
                                          </>
                                        )
                                      }

                                    })()
                                  }


                                </a>
                              </div>
                              <div style={{ width: 300, paddingTop: 4, display: 'flex', justifyContent: 'right' }}>

                              </div>
                            </div>
                          </>
                        ) : (
                          <>

                            {/* <Tooltip title="Open Property Page"> */}

                            <div style={{ width: '100%' }}>

                              <ShortDemoCarousel

                                key={property.id}
                                images={property.images}
                                goToURL={"property?id=" + property.id}
                                property={property}

                              />

                            </div>
                            {/* </Tooltip> */}
                          </>

                        )
                      ) : (<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} >
                        <div
                          ref={ref}
                        >
                          {

                            (() => {
                              if (inView) {


                                var address = ("https://www.google.com/maps/embed/v1/place?q=" +
                                  // plusCode +
                                  property.StreetNumber + " " + property.StreetName + "," + property.City +
                                  "&key=" + process.env.REACT_APP_GoogleMapsAPIKey + "&zoom=18&maptype=satellite").replaceAll(" ", "+")
                                if (!property.StreetNumber || property.StreetNumber.length === 0) {
                                  address = ("https://www.google.com/maps/embed/v1/view?" +
                                    // plusCode.replace("+","%2") +
                                    // property.StreetNumber + " " + property.StreetName + "," + property.City +
                                    "&key=" + process.env.REACT_APP_GoogleMapsAPIKey + "&zoom=18&maptype=satellite&center=" + property.Latitude + "," + property.Longitude).replaceAll(" ", "+")
                                }




                                // //test - console.log(address)
                                return (<>
                                  <div style={{ fontSize: 12, paddingLeft: 20 }}>
                                    {size ? (size.toLocaleString() + " SF Building") : ""}
                                    {footprint ? (footprint.toLocaleString() + " SF Footprint") : ""}</div>
                                  <iframe
                                    // width="450"
                                    // height="250"
                                    className="rounded-xl"
                                    style={{ border: 0, height: 240, width: '100%' }}
                                    referrerPolicy="no-referrer-when-downgrade"
                                    src={address}

                                    // src={"https://www.google.com/maps/embed/v1/place?q=" +
                                    // plusCode + "&key="+ process.env.REACT_APP_GoogleMapsAPIKey +"&zoom=18&maptype=satellite"}// +"&heading=210&pitch=10&fov=35"}

                                    allowFullScreen={true}>

                                  </iframe>
                                </>)
                              }


                              else {
                                // //test - console.log("not in view property.id")
                              }
                            })()
                          }
                        </div>
                        <Tooltip arrow title="Open Property Page">
                          <Button variant='contained'

                            onClick={() => { window.open("property?id=" + property.id, mobileAndTabletCheck() ? "_self" : null) }}
                            sx={{ marginY: 2 }}
                            className="flex underline justify-center w-full">Open Property Page</Button>
                        </Tooltip>


                      </div>
                      ) : <></>
                    }
                  </div>
                </div>


              </div>

              <div
                className="flex flex-col flex-grow text-xs sm:text-xm pl-5 "

              >
                {" "}

                <div className="text-left text-xl font-semibold">
                  <div>{PropertyType}</div>
                  <div>{CampusName}</div>


                  {PropertyName}
                </div>




                <div className="flex justify-between">
                  <div>
                    <div className="font-semibold text-md">
                      {StreetNumber} {StreetName},{" "}
                      {City}
                      <div>
                        <div className="text-left">
                          {
                            (() => {
                              if (property.Size && window.innerWidth > 760) {
                                if (property.OSM) {
                                  return (
                                    <div>
                                      {" "}
                                      <div>
                                        Est. Size: {nfObject.format(parseInt(property.Size / 1.5))} - {nfObject.format(property.Size)} SF
                                      </div>

                                    </div>
                                  )
                                }
                                else {
                                  return (
                                    <div>


                                      Property Size: {nfObject.format(property.Size)} SF


                                    </div>
                                  )
                                }

                              }
                              else {
                                return (<></>)
                              }
                            })()
                          }

                        </div>
                      </div>

                      {((!printing && (window.innerWidth > 760))) ?
                        <div
                          onClick={() => {
                            onClick(property.id);
                          }}
                          className="text-left text-md  text-red-600 ">
                          <ZoomIn className="w-6 h-6" />Move Map Here
                        </div> :

                        !mapButton && !printing ? <div
                          onClick={async () => {

                            const delayedStart = () => {

                              onClick(property.id, false)
                            }

                            setMapButton(true)
                            setTimeout(

                              delayedStart
                              , 500)

                          }}

                          className="text-left text-md  text-red-600 ">
                          <ZoomIn className="w-6 h-6" />Move Map Here

                        </div> : <></>}

                    </div>
                  </div>

                </div>



                {confirm && (
                  <div className="z-50 py-2">
                    <button
                      onClick={(event) => {
                        event.stopPropagation();
                        setShowConfirm(true);
                      }}
                      className=" inline-flex items-center justify-start rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                    >
                      Confirm This Property
                    </button>
                  </div>
                )}
                <ConfirmInfo
                  open={showConfirm}
                  setOpen={setShowConfirm}
                  contactId={contactId}
                  PropertyInfo={property}
                />
              </div>
              <div>
                {/* {printing ? ( */}
                <>
                  {/* <div>{property.websites.length > 0 ? "Weblinks" : ""}</div> */}
                  <div>
                    <Grid container spacing={2}>
                      {property && property.websites ? (

                        property.websites.map((website, index) => {
                          return (
                            // <div className="underline text-blue text-xs" key={index}>

                            <Grid key={website.src + index.toString()}>
                              <Button onClick={() => { window.open(website.src, mobileAndTabletCheck() ? "_self" : null) }}
                                // sx={{ color: 'white' }}
                                sx={{
                                  display: 'flex', justifyContent: 'start', marginTop: 1,
                                  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%'
                                }}
                                variant="contained">

                                {website.Description}

                              </Button>
                            </Grid>

                            // </div>
                          );

                        })

                      ) : (
                        <>


                        </>
                      )}
                    </Grid>
                  </div>
                </>
                {/* ) : ( */}
                {/* <></> */}
                {/* ) */}
                {/* } */}


                <TabsComponent availArray={
                  availsOutput
                } compArray={
                  compsOutput
                } totalAvail={totalAvail} printing={printing} />
              </div>

            </>
          )

        }


      </div>
      <ShareSurveySplash open={showSurveySplash} setOpen={setShowSurveySplash} website={shareLink} />
    </div>
  );
}


function InfoCardAvail({
  property,
  onHover,
  showMe,
  onClick,
  searchResults,
  setSearchResults,
  selectedLocation,
  setSelectedLocation,
  removeButton,
  confirm,
  setConfirm,
  contactId,
  printing,
  index,
  setMapButton,
  mapButton,
  removeProperty,
  showGoogle,
  showSurveys,
  setShowSurveys,
  surveyId,
  setSaveSurveyOpen
}) {

  const [plusCode, setPlusCode] = useState(null)
  const { ref, inView, entry } = useInView({
    /* Optional options */
    triggerOnce: true,
    threshold: 0,
  });

  // useEffect(() => {
  //   //do something here when inView is true
  // }, [inView])

  const [showConfirm, setShowConfirm] = useState(false);
  const [favShowDialogue, setFavShowDialogue] = useState(false)


  var splicedArray = searchResults
  var totalAvail = 0
  property.avails ? property.avails.map((available) => {

    totalAvail =
      totalAvail + (available.Status !== "Lease Comp" ? available.SizeMax : 0);
  }) : totalAvail = 0


  return (
    <Card></Card>
  )


}


export const removeById = (arr, id) => {
  const requiredIndex = arr.findIndex((el) => {
    return el.id === id;
  });

  if (requiredIndex === -1) {
    return false;
  }
  arr.splice(requiredIndex, 1);
  return arr;
};

export function AvailData(available, printing) {
  var myDate = null
  if (available.DateOccupancy) {
    myDate = new Date(available.DateOccupancy).toLocaleDateString()
  }


  if (myDate && ((new Date(available.DateOccupancy)) < new Date())) {
    myDate = "Now"
  }
  else if (!myDate) {
    myDate = ""
  }
  if (available.AskRate > 24) {

    // alert (available.AskRate)
    available.AskRate = available.AskRate / 12
  }

  if (available && available.contacts && available.contacts.length > 1) {
    available.contacts.sort((a, b) => { return a.avail_contact.sortOrder - b.avail_contact.sortOrder })
  }

  return (
    <div style={{ padding: mobileAndTabletCheck() ? 0 : 10 }} key={"availData" + available.id}>
      <div className={printing ? "" : ""} >
        <Grid key={"GridContainer" + available.id} container spacing={2} sx={{ cursor: 'pointer', paddingBottom: 3 }} >

          <Grid key={"websitesContainer2" + available.id} container spacing={2}>
            {available && available.websites ? (
              available.websites.map((website, index) => {
                return (
                  // <div className="underline text-blue text-xs" key={index}>
                  <Grid key={website.src + index.toString()}>
                    <Button
                      // sx={{ color: 'white' }}
                      sx={{
                        display: 'flex', justifyContent: 'start', marginTop: 1,
                        whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%'
                      }}
                      variant="contained" onClick={() => { window.open(website.src, mobileAndTabletCheck() ? "_self" : null) }}>


                      {website.Description}

                    </Button>
                  </Grid>
                  // </div>
                );
              })
            ) : (
              <></>
            )}
          </Grid>
          <Grid key={"ChipGrid" + available.id}
            xs={"auto"}
            onClick={() => {
              window.open("available?id=" + available.id, mobileAndTabletCheck() ? "_self" : null);
            }}>
            <Chip label={available.SizeMin !== available.SizeMax ?
              nfObject.format(available.SizeMin) + " - " +
              nfObject.format(available.SizeMax) +
              " SF " : !available.SizeMin || available.SizeMin === 0 ?
                "Call for size" : (nfObject.format(available.SizeMin) + " SF")} variant="outlined" /></Grid>
          {available.Floor > 0 ? <Grid
            key={"SizeMin" + available.id}
            onClick={() => {
              window.open("available?id=" + available.id, mobileAndTabletCheck() ? "_self" : null);
            }}
          ><Chip label={"Floor: " + available.Floor} variant="outlined" /> </Grid> : ""}

          {available.Suite && (available.Suite.length > 0) ? <Grid key={"Suite" + available.id} onClick={() => {
            window.open("available?id=" + available.id, mobileAndTabletCheck() ? "_self" : null);
          }}><Chip label={"Suite " + available.Suite} variant="outlined" /></Grid> : ""}
          {available.SubleaseOrDirect ? <Grid key={"Sublease" + available.id} onClick={() => {
            window.open("available?id=" + available.id, mobileAndTabletCheck() ? "_self" : null);
          }}><Chip label={available.SubleaseOrDirect} variant="outlined" /></Grid> : ""}
          {
            myDate ? (<Grid key={"Occupancy" + available.id} onClick={() => {
              window.open("available?id=" + available.id, mobileAndTabletCheck() ? "_self" : null);
            }}><Chip label={"Occupancy: " + myDate} variant="outlined" /></Grid>) : (<></>)

          }
          {available.Condition ? <Grid key={"Condition" + available.id} onClick={() => {
            window.open("available?id=" + available.id, mobileAndTabletCheck() ? "_self" : null);
          }}><Chip label={available.Condition} variant="outlined" /></Grid> : ""}
          {available.AskRate
            ?
            <Grid key={"AskRate" + available.id} onClick={() => {
              window.open("available?id=" + available.id, mobileAndTabletCheck() ? "_self" : null);
            }}><Chip label={"Rent " + nfObjectRate.format(available.AskRate) +
              " " +
              (available.RentStructure || "") + "/SF/Mo"
            } variant="outlined" /></Grid> : ""}
          {available.Expenses
            ?
            <Grid key={"Expenses" + available.id} onClick={() => {
              window.open("available?id=" + available.id, mobileAndTabletCheck() ? "_self" : null);
            }}><Chip label={"Expenses " + nfObjectRate.format(available.Expenses) + "/SF/Mo"
            } variant="outlined" /></Grid> : ""}
          {available.DockDoors
            ?
            <Grid key={"DockDoors" + available.id} onClick={() => {
              window.open("available?id=" + available.id, mobileAndTabletCheck() ? "_self" : null);
            }}><Chip label={"Dock Doors " + available.DockDoors
            } variant="outlined" /></Grid> : ""}
          {available.GradeDoors
            ?
            <Grid key={"GradeDoors" + available.id} onClick={() => {
              window.open("available?id=" + available.id, mobileAndTabletCheck() ? "_self" : null);
            }}><Chip label={"Grade Doors " + available.GradeDoors
            } variant="outlined" /></Grid> : ""}
          {available.GradeDoors
            ?
            <Grid key={"WarehouseShellClearHeight" + available.id} onClick={() => {
              window.open("available?id=" + available.id, mobileAndTabletCheck() ? "_self" : null);
            }}><Chip label={"Clear Height " + available.WarehouseShellClearHeight
            } variant="outlined" /></Grid> : ""}
          {available.PowerVolts
            ?
            <Grid key={"PowerVolts" + available.id} onClick={() => {
              window.open("available?id=" + available.id, mobileAndTabletCheck() ? "_self" : null);
            }}><Chip label={"Volts " + available.PowerVolts
            } variant="outlined" /></Grid> : ""}
          {available.PowerAmps
            ?
            <Grid key={"PowerAmps" + available.id} onClick={() => {
              window.open("available?id=" + available.id, mobileAndTabletCheck() ? "_self" : null);
            }}><Chip label={"Amps " + available.PowerAmps
            } variant="outlined" /></Grid> : ""}


        </Grid>

        <div style={{ display: mobileAndTabletCheck() ? 'block' : 'flex' }}>


          {/* <Tooltip title="Open Available Page">
            <div style={{
              maxWidth: mobileAndTabletCheck() ? '100%' : 700,
              minHeight: available.images && available.images.length > 0 ? 350 : 0, maxHeight: 350,
              minWidth: (available.images && available.images.length > 0 ? (mobileAndTabletCheck() ? '100%' : 500) : 0)

            }}>

              <SizableDemoCarousel
                key={available.id}
                images={available.images}
                goToURL={"available?id=" + available.id}

              />

            </div>
          </Tooltip> */}
          <div style={{ paddingLeft: 20 }}>
            <Grid key={"contactDataAvail" + available.id} container spacing={4}>

              {available && available.contacts && available.contacts.length > 0 && available.contacts.map((contact) => {
                return ContactDataAvail(contact)
              }
              )}

            </Grid>
          </div>
        </div>





        {/* {available && available.avail_groups ?
          available.avail_groups.findIndex((avail_group) => {
            return avail_group.availId === 1
          }) >= 0 ? returnPublic(available) : returnGroups(available)
          : (<></>)
        } */}
      </div>

    </div>

  );
  // }
}

export function CompData(available, printing) {

  return (
    <div key={"compData" + available.id} style={{ display: 'flex', flexDirection: 'column' }}>
      <Grid container sx={{ cursor: 'pointer', minHeight: 100, paddingBottom: 5 }} spacing={2}

        onClick={() => {
          window.open("available?id=" + available.id, mobileAndTabletCheck() ? "_self" : null);
        }}
      >
        <Grid xs={'auto'}><Chip label={available.Tenant} variant="filled" sx={{ color: "blue" }} /></Grid>
        <Grid><Chip label={nfObject.format(available.LeasedSpace) + " SF"} variant="outlined" /></Grid>
        {available.Floor && (available.Floor > 0) ? <Grid><Chip label={"Floor " + available.Floor} variant="outlined" /></Grid> : ""}
        {available.Suite && (available.Suite.length > 0) ? <Grid><Chip label={"Suite " + available.Suite} variant="outlined" /></Grid> : ""}
        {available.SubleaseOrDirect ? <Grid><Chip label={available.SubleaseOrDirect} variant="outlined" /></Grid> : ""}
        {available.ExecutionDate
          ? <Grid><Chip label={"Signed " +
            new Date(available.ExecutionDate).toLocaleDateString()
          } variant="outlined" /></Grid> : ""}
        {available.Condition ? <Grid><Chip label={available.Condition} variant="outlined" /></Grid> : ""}
        {available.PropertyType ? <Grid><Chip label={available.PropertyType} variant="outlined" /></Grid> : ""}
        {available.InitialRent
          ? <Grid><Chip label={nfObjectRate.format(available.InitialRent) +
            " " +
            (available.RentStructure || "") + "/SF/Mo"
          } variant="outlined" /></Grid> : ""}


      </Grid>


      <Grid container spacing={2}>
        {available && available.websites ? (
          available.websites.map((website, index) => {
            return (
              // <div className="underline text-blue text-xs" key={index}>
              <Grid key={website.src + index.toString()}>
                <Button onClick={() => { window.open(website.src, mobileAndTabletCheck() ? "_self" : null) }} sx={{ color: 'white' }} variant="contained" >
                  {/* <LinkIcon className="text-blue h-4 w-4"></LinkIcon> */}

                  {website.Description}

                </Button>
              </Grid>
              // </div>
            );
          })
        ) : (
          <></>
        )}
        {/* </div> */}
      </Grid>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <Grid container spacing={4}>


            {available && available.contacts && available.contacts.length > 0 && available.contacts.map((contact) => {

              return ContactDataAvail(contact)
            }
            )}

          </Grid>
        </div>
        <div >

          {available && available.avail_groups ?
            available.avail_groups.findIndex((avail_group) => {
              return avail_group.availId === 1
            }) >= 0 ? returnPublic(available) : returnGroups(available)
            : (<></>)
          }

        </div>
      </div>
    </div>
  );
  // }
}

export function CompPhoneData(available) {
  return (
    <div
      className="px-2 hover:outline-red text-xs"
      onClick={() => {
        window.open("available?id=" + available.id, mobileAndTabletCheck() ? "_self" : null);
      }}
    >
      {available.Tenant} | {nfObject.format(available.LeasedSpace)} SF{" "}
      {available.Floor > 0 ? " | Fl: " + available.Floor : ""}{" "}
      {available.SubleaseOrDirect ? " | " + available.SubleaseOrDirect : ""}
      {available.ExecutionDate
        ? " | " + new Date(available.ExecutionDate).toLocaleDateString()
        : ""}
      {available.Condition ? " | " + available.Condition : ""}
      {available.InitialRent
        ? " | " + nfObjectRate.format(available.InitialRent)
        : ""}
    </div>
  );
}


export function AvailPhoneData(available) {



  return (
    <div

      className="px-2 hover:outline-red text-xs"
      onClick={() => {
        window.open("available?id=" + available.id, mobileAndTabletCheck() ? "_self" : null);
      }}
    >
      {nfObject.format(available.SizeMin)} -{" "}
      {nfObject.format(available.SizeMax)} SF{" "}
      {available.Floor > 0 ? " | Fl: " + available.Floor : ""}{" "}
      {available.SubleaseOrDirect ? " | " + available.SubleaseOrDirect : ""}
      {available.DateOccupancy
        ? " | " + new Date(available.DateOccupancy).getFullYear()
        : ""}
      {available.Condition ? " | " + available.Condition : ""}
    </div>
  );
}



function returnGroups(available) {

  return (


    <Accordion>
      <AccordionSummary>Groups</AccordionSummary>
      <AccordionDetails>
        <Grid2 container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} key={available.id.toString() + "groups"}>
          {available.avail_groups.map((avail_group, index) => {
            return (
              // <div className="underline text-blue text-xs" key={index}>




              <Grid2 key={avail_group?.group?.GroupName + index} >

                <Item onClick={() => {
                  window.open(
                    "group?id=" + avail_group.group.id)
                }}>{avail_group?.group?.GroupName}

                </Item>



              </Grid2>

            )
          })}
        </Grid2>
      </AccordionDetails>
    </Accordion>

  )
}


function CompRows({ avails, contactId, group }) {
  
  return (<>



    {avails.map((avail, row) => {



      if (avail.Status === 'Lease Comp') {
        return (
          <>
            {/* <a className="pagebreak" key={"paper" + avail.id + "-" + row} href={window.location.origin + "/available?id=" + avail.id} target="_blank"> <Button sx={{ margin: 1 }} variant='outlined'>Visit Space Page for Detail or Edits </Button></a> */}
            <Paper elevation={2} sx={{ marginBottom: 1, marginLeft: 1, paddingRight: 1 }}>
              <Grid2 container>

                <Grid2 xs={3} key={"grid1" + row}>

                  <ColOut avail={avail} contactId={contactId} group={group} column={["Tenant", "Landlord", "Sublandlord", "SubleaseOrDirect", "Suite", "Floor", "PropertyType", "Condition"]} row={row} />
                </Grid2>
                <Grid2 xs={3} key={"grid2" + row}>
                  <ColOut avail={avail} contactId={contactId} group={group} column={["LeasedSpace", "LeaseTermMonths","ExecutionDate", "NewDealOrRenewal", "DockDoors", "GradeDoors", "ImprovementAllowancePerFoot", "AmortizedImprovementAllowancePerFoot", "DateOccupancy", "Status"]} row={row} />
                </Grid2>
                <Grid2 xs={3} key={"grid3" + row}>
                  <ColOut avail={avail} contactId={contactId} group={group} column={["InitialRent", "Expenses", "ContractedEscalations", "FreeRentMonths", "RentStructure","ImprovementAllowancePerFoot", "AmortizationRate", "ConstructionAbatementMonths", "CommencementDate",]} row={row} values={true} />

                </Grid2>
                <Grid2 key={"grid4" + row} maxHeight={400} maxWidth={200} overflow={'hidden'} padding={1} xs={3} >
                  {avail.websites.map((website, index) => {

                    return (



                      <a style={{ overflow: 'hidden', maxWidth: '100%' }} key={avail.id + "-" + website.src + "-" + index} href={website.src} target="_blank">

                        <iframe

                          onError={(event) => { event.target.src = 'https://www.cubicsearch.com' }}
                          src={website.src} style={{ overflow: 'hidden', maxWidth: '100%' }}>

                        </iframe>

                        <Button
                          sx={{
                            display: 'flex', justifyContent: 'start', marginTop: 1, marginBottom: 1,
                            whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%'
                          }} variant="contained">{website.Description}</Button>
                      </a>

                    )
                  })}
                  {/* <ColOut avail={avail} contactId={contactId} group={group} column={["Amps", "Volts", "Generator", "Biology", "Chemistry"]} row={row} /> */}
                </Grid2>
              </Grid2>
              <Typography key={"end" + row} component={'div'} sx={{ margin: 1 }}>{avail.Notes}</Typography>
              <Typography sx={{ padding: 2 }} fontSize={10}>All rents, expense, sizes, dates and other information herein are estimates and need to be confirmed with a real estate professional or the owner of the property.  These numbers can not be relied upon, and before a lease is executed, each party should consult with their accountant, lawyer, and real estate professional.</Typography>
            </Paper>
          </>)
      }
    })}




  </>)


}


function AvailRows({ avails, contactId, group }) {

  return (<>



    {avails.map((avail, row) => {


      if (avail.Status !== 'Lease Comp') {
        return (
          <>
            <a className="pagebreak" key={"paper" + avail.id + "-" + row} href={window.location.origin + "/available?id=" + avail.id} target="_blank"> <Button sx={{ margin: 1 }} variant='outlined'>Visit Space Page for Detail or Edits </Button></a>
            <Paper elevation={2} sx={{ marginBottom: 1, marginLeft: 1, paddingRight: 1 }}>
              <Grid2 container>

                <Grid2 xs={3} key={"grid1" + row}>

                  <ColOut avail={avail} contactId={contactId} group={group} column={["SubleaseOrDirect", "Suite", "Floor", "PropertyType", "Condition"]} row={row} />
                </Grid2>
                <Grid2 xs={3} key={"grid2" + row}>
                  <ColOut avail={avail} contactId={contactId} group={group} column={["SizeMin", "SizeMax", "WarehouseShellClearHeight", "DockDoors", "GradeDoors", "DateListed", "DateAvailable", "DateOccupancy", "Status"]} row={row} />
                </Grid2>
                <Grid2 xs={3} key={"grid3" + row}>
                  <ColOut avail={avail} contactId={contactId} group={group} column={["AskRate", "Expenses", "AskTI", "AskIncreases", "RentStructure"]} row={row} values={true} />

                </Grid2>
                <Grid2 key={"grid4" + row} maxHeight={400} maxWidth={200} overflow={'hidden'} padding={1} xs={3} >
                  {avail.websites.map((website, index) => {

                    return (



                      <a style={{ overflow: 'hidden', maxWidth: '100%' }} key={avail.id + "-" + website.src + "-" + index} href={website.src} target="_blank">

                        {/* <iframe 
                          
                          onError={(event) => { event.target.src = 'https://www.cubicsearch.com' }}
                            src={website.src} style={{    overflow: 'hidden', maxWidth: '100%'}}>
                          
                        </iframe> */}

                        <Button
                          sx={{
                            display: 'flex', justifyContent: 'start', marginTop: 1, marginBottom: 1,
                            whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%'
                          }} variant="contained">{website.Description}</Button>
                      </a>

                    )
                  })}
                  {/* <ColOut avail={avail} contactId={contactId} group={group} column={["Amps", "Volts", "Generator", "Biology", "Chemistry"]} row={row} /> */}
                </Grid2>
              </Grid2>
              <Typography key={"end" + row} component={'div'} sx={{ margin: 1 }}>{avail.Notes}</Typography>
              <Typography sx={{ padding: 2 }} fontSize={10}>All rents, expense, sizes, dates and other information herein are estimates and need to be confirmed with a real estate professional or the owner of the property.  These numbers can not be relied upon, and before a lease is executed, each party should consult with their accountant, lawyer, and real estate professional.</Typography>
            </Paper>
          </>)
      }
    })}




  </>)


}


function ColOut({ avail, contactId, group, column, row, values }) {


  var entries = Object.entries(avail)
  var monthlyMax
  var monthlyMin

  if (column.includes("AskRate")) {

    if ((avail["AskRate"] && avail["AskRate"] > 0) && (avail["Expenses"] && avail["Expenses"] > 0) && (avail["RentStructure"] !== "FSG") && (avail["RentStructure"] !== "FS")) {
      monthlyMax = (avail["Expenses"] + avail["AskRate"]) * avail["SizeMax"]
      monthlyMin = (avail["Expenses"] + avail["AskRate"]) * avail["SizeMin"]
    }
    else if ((avail["AskRate"] && avail["AskRate"] > 0) && ((avail["RentStructure"] === "FSG") || (avail["RentStructure"] === "FS"))) {
      monthlyMax = (avail["AskRate"]) * avail["SizeMax"]
      monthlyMin = (avail["AskRate"]) * avail["SizeMin"]
    }


  }
  var myDate = null
  if (avail.DateOccupancy) {
    myDate = new Date(avail.DateOccupancy).toLocaleDateString()
  }


  if (myDate && ((new Date(avail.DateOccupancy)) < new Date())) {
    myDate = "Now"
  }
  else if (!myDate) {
    myDate = ""
  }

  return (
    // <div className="underline text-blue text-xs" key={index}>
    <Grid2 direction={'column'}
      container key={avail.id.toString() + row.toString()}>
      {entries.map((entry, index) => {


        if (entry[1] && column.includes(entry[0])) {


          if (["Floor"].includes(entry[0]) && (entry[1] === -1)) {

            return (
              <Box key={"Colout" + entry[0] + "-" + index} padding={1} flexGrow={1} sx={{ fontSize: '10' }}>


                <TextField size='small' style={{ fontSize: 10 }} variant="outlined"
                  placeholder={entry[0]} label={camelCaseToTitleCase(entry[0])} defaultValue={"Entire Building"}> </TextField>


              </Box>
            )
          }
          else if (["SizeMax", "SizeMin", "LeasedSpace"].includes(entry[0]) && (entry[1] > 0)) {


            return (
              <Box key={"Colout" + entry[0] + "-" + index} padding={1} flexGrow={1} sx={{ fontSize: '10' }}>


                <TextField size='small' style={{ fontSize: 10 }} variant="outlined"
                  placeholder={entry[0]} label={camelCaseToTitleCase(entry[0])} defaultValue={entry[1].toLocaleString()}> </TextField>


              </Box>
            )
          } else if (["ContractedEscalations"].includes(entry[0]) && (entry[1] > 0)) {


            return (
              <Box key={"Colout" + entry[0] + "-" + index} padding={1} flexGrow={1} sx={{ fontSize: '10' }}>


                <TextField size='small' style={{ fontSize: 10 }} variant="outlined"
                  placeholder={entry[0]} label={camelCaseToTitleCase(entry[0])} defaultValue={(entry[1] * 100).toFixed(2) + '%'}> </TextField>


              </Box>
            )
          }
          else if (["AskRate", "Expenses", "InitialRent"].includes(entry[0]) && (entry[1] > 0)) {

            if (entry[0] === "AskRate") {
              entry[0] = "Ask Rate SF/Month"
            }
            if (entry[0] === "InitialRent") {
              entry[0] = "Initial Rate SF/Month"
            }

            return (
              <Box key={"Colout" + entry[0] + "-" + index} padding={1} flexGrow={1} sx={{ fontSize: '10' }}>


                <TextField size='small' style={{ fontSize: 10 }} variant="outlined"
                  placeholder={entry[0]} label={camelCaseToTitleCase(entry[0])} defaultValue={entry[1].toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}> </TextField>



              </Box>
            )
          }
          else if (entry[0].includes("DateOccupancy")) {


            return (
              <Box key={"Colout" + entry[0] + "-" + index} padding={1} flexGrow={1} sx={{ fontSize: '10' }}>


                <TextField size='small' style={{ fontSize: 10 }} variant="outlined"
                  placeholder={entry[0]} label={camelCaseToTitleCase(entry[0])} defaultValue={myDate}> </TextField>


              </Box>
            )
          }
          else if (entry[0].includes("Date")) {


            return (
              <Box key={"Colout" + entry[0] + "-" + index} padding={1} flexGrow={1} sx={{ fontSize: '10' }}>


                <TextField size='small' style={{ fontSize: 10 }} variant="outlined"
                  placeholder={entry[0]} label={camelCaseToTitleCase(entry[0])} defaultValue={new Date(entry[1]).toLocaleDateString()}> </TextField>


              </Box>
            )
          }
          else {

            return (

              <Box key={"Colout" + entry[0] + "-" + index} padding={1} flexGrow={1} sx={{ fontSize: 10 }}>

                <TextField size="small" style={{ fontSize: 10 }} variant='outlined'
                  placeholder={entry[0]} label={camelCaseToTitleCase(entry[0])} defaultValue={entry[1]}> </TextField>


              </Box>

            )
          }


        }
      })

      }
      {monthlyMin && monthlyMax ? <> <Box key={"ColoutMaxMin" + "-" + row} padding={1} flexGrow={1} sx={{ fontSize: '10' }}>


        <TextField size='small' style={{ fontSize: 10 }} variant="outlined"
          placeholder={"Monthly Min Rent"} label={"Ask Min Size Rent & Expenses"} defaultValue={monthlyMin.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}> </TextField>

      </Box>
        <Box key={"ColoutMaxMin" + "-" + row} padding={1} flexGrow={1} sx={{ fontSize: '10' }}>
          <TextField size='small' style={{ fontSize: 10 }} variant="outlined"
            placeholder={"Monthly Min Rent"} label={"Ask Max Size Rent & Expenses"} defaultValue={monthlyMax.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}> </TextField>




        </Box>
      </> : <></>
      }

    </Grid2>
    // </div>

  )
}

function returnGroupsContact(contact) {



  return (
    contact.group_contacts.map((contact_group, index) => {


      if ((contact_group.group_id === 1) && !contact_group.group) {
        return (

          // < Grid key={contact_group.group_id.toString() + index.toString()} >
          //   <Box sx={{ flexGrow: 1 }}>
          //     {/* <LinkIcon className="text-blue h-4 w-4"></LinkIcon> */}
          //     {/* <a
          //           href={"/groups?id=" + avail_group.group.id}
          //           target="_blank"
          //           rel="noopener noreferrer"
          //         > */}


          <Item>
            <a
              href={"/groups?id=1"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>Public Domain</div>
            </a>
          </Item>




          //   </Box>

          // </Grid >
        )
      }

      else {


        return (
          // <div className="underline text-blue text-xs" key={index}>
          <Grid key={contact_group?.group?.id.toString() + index.toString()}>
            <Box sx={{ flexGrow: 1 }}>
              {/* <LinkIcon className="text-blue h-4 w-4"></LinkIcon> */}
              {/* <a
                    href={"/groups?id=" + avail_group.group.id}
                    target="_blank"
                    rel="noopener noreferrer"
                  > */}


              <Item>
                <div>  {contact_group?.group?.GroupName}</div>

              </Item>




            </Box>

          </Grid>
          // </div>
        );
      }
    }
    )
  )
}



function returnPublic(available) {
  return (
    <Box sx={{ flexGrow: 1, maxWidth: 200 }}>

      <Grid key={'Public' + available.avail_groups.availId}>

        {/* <LinkIcon className="text-blue h-4 w-4"></LinkIcon> */}
        {/* <a
                    href={"/groups?id=" + avail_group.group.id}
                    target="_blank"
                    rel="noopener noreferrer"
                  > */}


        <Item><div> Public Domain</div>
          {/* <div> View</div>
          <div>Separated Members</div> */}
        </Item>

      </Grid>
    </Box>


  )
}

export function ContactData(contact) {

  var image = "https://images.cubicsearch.com/UserLogo.png"
  if (contact.images && contact.images.length > 0) { image = contact.images.sort((a, b) => a.sortOrder - b.sortOrder)[0].img }
  var imageCompany = "https://images.cubicsearch.com/CompanyLogo.png"
  if (contact.office && contact.office.company && contact.office.company.images && contact.office.company.images.length > 0) { imageCompany = contact.office.company.images.sort((a, b) => a.sortOrder - b.sortOrder)[0].img }

  return (

    <Grid>
      <Card sx={{ display: 'flex', minWidth: 300, maxWidth: '100%' }}>
        <CardMedia onClick={
          () => {
            window.open("contact?id=" + contact.id, mobileAndTabletCheck() ?
              "_self" : null)
          }}

          component="img"
          sx={{ width: 75, height: 112, cursor: 'pointer' }}
          image={image}
          alt={contact.FirstName + " " + contact.LastName}

        />
        <Box
          sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography>{contact.building_contacts[0].RelationshipType}</Typography>
            <Typography component={'div'} variant='body1'>
              {contact.FirstName} {contact.LastName}
            </Typography>
            <Typography component={'div'} variant='body1'>
              {contact.Email}
            </Typography>
            {/* <Typography>{formatPhoneNumber(contact.Phone) + " "}</Typography> */}




          </CardContent>

        </Box>
        {mobileAndTabletCheck() ? <></> : <div style={{ paddingRight: 8 }} >
          <div>
            <img src={imageCompany}
              style={
                {
                  objectFit: 'contain',
                  maxWidth: 75,
                  maxHeight: 50,

                }
              } /></div>
          <div> {returnGroupsContact(contact)}</div>

        </div>}

      </Card>

    </Grid>

  );

}
export function ContactDataAvail(contact) {

  var image = "https://images.cubicsearch.com/UserLogo.png"
  if (contact.images && contact.images.length > 0) { image = contact.images.sort((a, b) => a.sortOrder - b.sortOrder)[0]?.img }
  var imageCompany = "https://images.cubicsearch.com/CompanyLogo.png"
  if (contact.office && contact.office.company && contact.office.company.images && contact.office.company.images.length > 0) { imageCompany = contact.office.company.images.sort((a, b) => a.sortOrder - b.sortOrder)[0].img }

  return (

    <Grid key={"contactGrid" + contact.id}>
      <Card sx={{ display: 'flex', minWidth: 300, maxWidth: '100%' }}>
        <CardMedia onClick={
          () => {
            window.open("contact?id=" + contact.id, mobileAndTabletCheck() ?
              "_self" : null)
          }}

          component="img"
          sx={{ width: 75, height: 112, cursor: 'pointer' }}
          image={image}
          alt={contact.FirstName + " " + contact.LastName}

        />
        <Box
          sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography>{contact.avail_contact.RelationshipType}</Typography>
            <Typography component={'div'} variant='body1'>
              {contact.FirstName} {contact.LastName}
            </Typography>
            <Typography component={'div'} variant='body1'>
              {contact.Email}
            </Typography>
            {/* <Typography>{formatPhoneNumber(contact.Phone)}</Typography> */}

          </CardContent>


        </Box>
        {mobileAndTabletCheck() ? <></> : <div style={{ paddingRight: 8 }} >
          <div>
            <img src={imageCompany}
              style={
                {
                  objectFit: 'contain',
                  maxWidth: 75,
                  maxHeight: 50,

                }
              } /></div>
          <div> {returnGroupsContact(contact)}</div>

        </div>}

      </Card>

    </Grid>

  );

}

// export function AvailReportCard({ building, index }) {

//   var image = "https://images.cubicsearch.com/small%20logo.png"
//   if (building.images && building.images.length > 0) { image = building.images.sort((a, b) => a.sortOrder - b.sortOrder)[0].img }
//   var imageSpace = "https://images.cubicsearch.com/small%20spaces%20square.png"


//   return (

//     <Grid key={"availGrid" + building.id}>
//       <Card sx={{  minWidth: 300, maxWidth: '100%' }}>
//         <CardHeader backgroundColor='blue'><Avatar sx={{width: 5, height: 5}}>{index}Blimey</Avatar></CardHeader>
//         <CardMedia onClick={
//           () => {
//             window.open("property?id=" + building.id, mobileAndTabletCheck() ?
//               "_self" : null)
//           }}

//           component="img"
//           sx={{ width: 75, height: 112, cursor: 'pointer' }}
//           image={image}
//           alt={building.StreetNumber + " " + building.StreetName}

//         />
//         <Box
//           sx={{ display: 'flex', flexDirection: 'column' }}>
//           <CardContent sx={{ flex: '1 0 auto' }}>
//             <Typography>{building.StreetNumber + " " + building.StreetName}</Typography>
//             <Typography  component={'div'} variant='body1'>
//               {building.City} {building.State}
//             </Typography>
//             <Typography  component={'div'} variant='body1'>
//               {building.Size ? building.Size.toLocaleString() : ""}
//             </Typography>
//             {/* <Typography>{formatPhoneNumber(contact.Phone)}</Typography> */}

//           </CardContent>


//         </Box>

//         <div>
//           <img src={image}
//             style={
//               {
//                 objectFit: 'contain',
//                 maxWidth: 75,
//                 maxHeight: 50,

//               }
//             } /></div>
//         <div> <ReturnAvailsCards avails={building.avails} /></div>



//       </Card>

//     </Grid>

//   );

// }
function camelCaseToTitleCase(input) {
  // Handle empty string or non-string input
  if (typeof input !== 'string' || input.trim() === '') {
    return '';
  }

  // Replace camel case with space-separated words
  const words = input.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');

  // Capitalize the first letter of each word
  const titleCaseWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the words into a title case string
  const titleCaseString = titleCaseWords.join(' ');

  return titleCaseString;
}


export function CompReportCard({ building, index, contactId, group }) {
  const [expanded, setExpanded] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  var entries = Object.entries(building)
  var column = ["PropertyType", "YearBuilt", "PowerAmps", "PowerVolts", "Size", "DockDoors", "GradeDoors", "FloorToFloor", "NumberFloors", "UtilityPad", "ParkingRatio",
    "Notes", "Footprint"]
  var image = "https://images.cubicsearch.com/small%20logo.png"
  if (building.images && building.images.length > 0)
  {
    var inPath = building.images.sort((a, b) => a.sortOrder - b.sortOrder)[0].img
    
    if (inPath.search("/buildings") > 0) {
      inPath =  inPath.replace("/buildings", "/buildingThumbs");
    } else if (inPath.search("/availables") > 0) {
      inPath =  inPath.replace("/availables", "/buildingThumbs");
    } else if (inPath.search("/contacts") > 0) {
      inPath =  inPath.replace("/contacts", "/buildingThumbs");
    } else if (inPath.search("/uploads") > 0) {
      inPath =  inPath.replace("/uploads", "/buildingThumbs");
    } else inPath = inPath;

    image = inPath
  }
  var imageSpace = "https://images.cubicsearch.com/small%20spaces%20square.png"
  var googleBackup = "https://www.google.com/maps/embed/v1/view?key=" + process.env.REACT_APP_GoogleMapsAPIKey + "&center=" +
    (building.Latitude) + "," + building.Longitude +
    "&zoom=18&maptype=satellite"

  return (
    < Box className="pagebreak" sx={{ marginTop: 4, padding: 4, maxWidth: '100%' }}>

      <Grid2 container>
        <Grid2 xs={4}>
          <Card sx={{}}>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: red[500] }} aria-label="property number">
                  {index + 1}
                </Avatar>
              }
              action={
                <IconButton aria-label="settings">
                  <MoreVertIcon />
                </IconButton>
              }
              title={building.StreetNumber + " " + building.StreetName}
              subheader={
                (building.CampusName ? building.CampusName + " | " : "") + (building.PropertyName ? building.PropertyName + " | " : "") +
                building.City + ", " + building.State
              }

            />
            <a href={window.location.origin + "/property?id=" + building.id}>
              {building && building.images && (building.images.length > 0) ?
                <CardMedia
                  component="img"
                  // height="194"
                  sx={{ width: '100%', maxHeight: 300, objectFit: 'cover' }}

                  image={image}

                  alt={building.PropertyName}
                /> : <iframe
                  style={{ width: '100%', height: mobileAndTabletCheck() ? window.innerHeight / 3 : 300, cursor: 'pointer' }}
                  onClick={
                    () => {
                      window.open("property?id=" + building.id, mobileAndTabletCheck() ?
                        "_self" : null)
                    }}
                  src={googleBackup}></iframe>}
            </a>
            <CardContent>
              <Typography component={'div'} variant="body2" color="text.secondary">
                {building.notes}
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <div style={{ display: 'flex' }}>
                <div>
                  <a style={{ width: 40, marginLeft: 17 }} href={window.location.origin +
                    `/search?moveMeHere=%7B"location":%7B"lat":` + building.Latitude + `,"lng":` + building.Longitude + `%7D%7D`}
                  //                + `%7D,"viewport":%7B"northeast":%7B"lat":37.4612324,"lng":-121.98267%7D,"southwest":%7B"lat":37.330236,"lng":-122.0650661%7D%7D%7D`}
                  >
                    <Button variant='outlined'> Map <Public style={{ height: 20, width: 20 }} /></Button>
                  </a>
                </div>
                <div style={{ marginLeft: 1 }}>
                  <a href={"https://www.google.com/maps/dir/?api=1&destination=" + building.StreetNumber
                    + "+" +
                    building.StreetName
                    + "+" +
                    building.City
                    + "+" +
                    building.State

                  } target="_blank"><Button variant="outlined">Drive<DirectionsCar sx={{ height: 20 }} /></Button></a>
                </div>
              </div>
              {/* <IconButton aria-label="Like">
                <ThumbUp />
              </IconButton> */}
              <IconButton aria-label="share">
                <Share />
              </IconButton>

              <Typography sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>Building Detail...</Typography>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="Goup Discussion"
              >

                <ExpandMoreIcon />
              </ExpandMore>

            </CardActions>
            <Collapse title="More Detail..." in={expanded} appear={true} timeout="auto" unmountOnExit>

              <CardContent>
                {entries.map((entry) => {

                  if (entry[1] && column.includes(entry[0])) {
                    return (
                      <Box key={"Colout" + entry[0] + "-" + index} padding={1} flexGrow={1} sx={{ fontSize: '10' }}>


                        <TextField size='small' style={{ fontSize: 10 }} variant="outlined"
                          placeholder={entry[0]} label={camelCaseToTitleCase(entry[0])} defaultValue={entry[1].toLocaleString()}> </TextField>


                      </Box>
                    )
                  }
                })}

                {group && BuildingGroup(group, contactId, building)}

                <Grid2 container spacing={2}>
                {building && building.websites ? (

                  building.websites.map((website, index) => {
                    return (
                      // <div className="underline text-blue text-xs" key={index}>

                      <Grid2 key={website.src + index.toString()}>
                        <Button onClick={() => { window.open(website.src, mobileAndTabletCheck() ? "_self" : null) }} sx={{ color: 'white' }} variant="contained">

                          {website.Description}

                        </Button>
                      </Grid2>

                      // </div>
                    );

                  })) : (<></>)

                }
                </Grid2>
              </CardContent>
            </Collapse>
          </Card>
        </Grid2>

        <Grid2 xs={8}>
          <Box sx={{ height: '100%', width: '100%' }}>


            <CompRows avails={building.avails} contactId={contactId} group={group} />

          </Box>


        </Grid2>
      </Grid2>
    </Box>
  );
}

export function AvailReportCard({ building, index, contactId, group }) {
  const [expanded, setExpanded] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  var entries = Object.entries(building)
  var column = ["PropertyType", "YearBuilt", "PowerAmps", "PowerVolts", "Size", "DockDoors", "GradeDoors", "FloorToFloor", "NumberFloors", "UtilityPad", "ParkingRatio",
    "Notes", "Footprint"]
  var image = "https://images.cubicsearch.com/small%20logo.png"
  if (building.images && building.images.length > 0) {
    var inPath = building.images.sort((a, b) => a.sortOrder - b.sortOrder)[0].img

    if (inPath.search("/buildings") > 0) {
      inPath = inPath.replace("/buildings", "/buildingThumbs");
    } else if (inPath.search("/availables") > 0) {
      inPath = inPath.replace("/availables", "/buildingThumbs");
    } else if (inPath.search("/contacts") > 0) {
      inPath = inPath.replace("/contacts", "/buildingThumbs");
    } else if (inPath.search("/uploads") > 0) {
      inPath = inPath.replace("/uploads", "/buildingThumbs");
    } else inPath = inPath;

    image = inPath
  }
  var imageSpace = "https://images.cubicsearch.com/small%20spaces%20square.png"
  var googleBackup = "https://www.google.com/maps/embed/v1/view?key=" + process.env.REACT_APP_GoogleMapsAPIKey + "&center=" +
    (building.Latitude) + "," + building.Longitude +
    "&zoom=18&maptype=satellite"

  return (
    < Box className="pagebreak" sx={{ marginTop: 4, padding: 4, maxWidth: '100%' }}>

      <Grid2 container>
        <Grid2 xs={4}>
          <Card sx={{}}>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: red[500] }} aria-label="property number">
                  {index + 1}
                </Avatar>
              }
              action={
                <IconButton aria-label="settings">
                  <MoreVertIcon />
                </IconButton>
              }
              title={building.StreetNumber + " " + building.StreetName}
              subheader={
                (building.CampusName ? building.CampusName + " | " : "") + (building.PropertyName ? building.PropertyName + " | " : "") +
                building.City + ", " + building.State
              }

            />
            <a href={window.location.origin + "/property?id=" + building.id}>
              {building && building.images && (building.images.length > 0) ?
                <CardMedia
                  component="img"
                  // height="194"
                  sx={{ width: '100%', maxHeight: 300, objectFit: 'cover' }}

                  image={image}

                  alt={building.PropertyName}
                /> : <iframe
                  style={{ width: '100%', height: mobileAndTabletCheck() ? window.innerHeight / 3 : 300, cursor: 'pointer' }}
                  onClick={
                    () => {
                      window.open("property?id=" + building.id, mobileAndTabletCheck() ?
                        "_self" : null)
                    }}
                  src={googleBackup}></iframe>}
            </a>
            <CardContent>
              <Typography component={'div'} variant="body2" color="text.secondary">
                {building.notes}
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <div style={{ display: 'flex' }}>
                <div>
                  <a style={{ width: 40, marginLeft: 17 }} href={window.location.origin +
                    `/search?moveMeHere=%7B"location":%7B"lat":` + building.Latitude + `,"lng":` + building.Longitude + `%7D%7D`}
                  //                + `%7D,"viewport":%7B"northeast":%7B"lat":37.4612324,"lng":-121.98267%7D,"southwest":%7B"lat":37.330236,"lng":-122.0650661%7D%7D%7D`}
                  >
                    <Button variant='outlined'> Map <Public style={{ height: 20, width: 20 }} /></Button>
                  </a>
                </div>
                <div style={{ marginLeft: 1 }}>
                  <a href={"https://www.google.com/maps/dir/?api=1&destination=" + building.StreetNumber
                    + "+" +
                    building.StreetName
                    + "+" +
                    building.City
                    + "+" +
                    building.State

                  } target="_blank"><Button variant="outlined">Drive<DirectionsCar sx={{ height: 20 }} /></Button></a>
                </div>
              </div>
              {/* <IconButton aria-label="Like">
                <ThumbUp />
              </IconButton> */}
              <IconButton aria-label="share">
                <Share />
              </IconButton>

              <Typography sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>Building Detail...</Typography>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="Goup Discussion"
              >

                <ExpandMoreIcon />
              </ExpandMore>

            </CardActions>
            <Collapse title="More Detail..." in={expanded} appear={true} timeout="auto" unmountOnExit>

              <CardContent>
                {entries.map((entry) => {

                  if (entry[1] && column.includes(entry[0])) {
                    return (
                      <Box key={"Colout" + entry[0] + "-" + index} padding={1} flexGrow={1} sx={{ fontSize: '10' }}>


                        <TextField size='small' style={{ fontSize: 10 }} variant="outlined"
                          placeholder={entry[0]} label={camelCaseToTitleCase(entry[0])} defaultValue={entry[1].toLocaleString()}> </TextField>


                      </Box>
                    )
                  }
                })}

                {group && BuildingGroup(group, contactId, building)}
                <Grid2 container spacing={2}>
                {building && building.websites ? (

                  building.websites.map((website, index) => {
                    return (
                      // <div className="underline text-blue text-xs" key={index}>

                      <Grid2 key={website.src + index.toString()}>
                        <a href={website.src}><Button sx={{ color: 'white' }} variant="contained">

                          {website.Description}

                        </Button>
                        </a>
                      </Grid2>

                      // </div>
                    );

                  })) : (<></>)

                  }
                </Grid2>

              </CardContent>
            </Collapse>
          </Card>
        </Grid2>

        <Grid2 xs={8}>
          <Box sx={{ height: '100%', width: '100%' }}>


            <AvailRows avails={building.avails} contactId={contactId} group={group} />

          </Box>


        </Grid2>
      </Grid2>
    </Box>
  );
}

export function GroupCenter(group, contactId) {

  var image = "https://images.cubicsearch.com/GroupImage.png"
  if (group && group.images && group.images.length > 0) { image = group.images.sort((a, b) => a.sortOrder - b.sortOrder)[0].img }
  // var imageCompany = "https://images.cubicsearch.com/CompanyLogo.png"
  // if (contact.office && contact.office.company && contact.office.company.images && contact.office.company.images.length > 0) { imageCompany = contact.office.company.images.sort((a, b) => a.sortOrder - b.sortOrder)[0].img }


  return (


    <Card sx={{
      maxWidth: mobileAndTabletCheck() ? '100%' : '100%',
      width: mobileAndTabletCheck() ? '100%' : 800

      // height: 175
    }}>
      <Typography component={'div'} variant="h5" sx={{ display: 'flex', padding: 1, backgroundColor: '#4e46e5', color: 'white' }}>  <Tooltip placement="top" title={"Open " + group.GroupName + " group page"}>
        <Avatar alt={group.GroupName}

          onClick={() => { window.open("group?id=" + group.id, mobileAndTabletCheck() ? "_self" : null) }}
          sx={{ cursor: 'pointer', marginRight: 3 }}
          src={
            group.images.length > 0
              ? group.images.sort(
                (a, b) => a.sortOrder - b.sortOrder
              )[0].img
              : null
          }

        >{group.GroupName && group.GroupName.length > 0 && group.GroupName[0]}

        </Avatar>

      </Tooltip>{group.GroupName}  </Typography>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }} >

        <div style={{ display: 'flex', alignContent: 'start' }}>


          <Box
            sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '1 0 auto' }}>

              {/* <Typography component={'div'}variant="h4" color="text.secondary" component="div">
                General Group Discussion
              </Typography> */}

              <Typography component={'div'} variant="subtitle1" color="text.secondary" >
                Updated {new Date(group.updatedAt).toLocaleDateString()}
              </Typography>

              <Typography>{group && group.group_contacts && group.group_contacts[0]
                ? group.group_contacts[0].PermissionLevel + " Permission"
                : "Contact agent or owner"}</Typography>




            </CardContent>

          </Box>
          {smallCards(group, contactId)}
        </div>


      </div>
      <Messages contactId={contactId}
        group={group} groupId={group.id} groupCenter={true} />

    </Card>




  );

}
export function AvailableSubGroup(group, contactId, available, building) {

  var image = "https://images.cubicsearch.com/GroupImage.png"
  if (group && group.images && group.images.length > 0) { image = group.images.sort((a, b) => a.sortOrder - b.sortOrder)[0].img }
  // var imageCompany = "https://images.cubicsearch.com/CompanyLogo.png"
  // if (contact.office && contact.office.company && contact.office.company.images && contact.office.company.images.length > 0) { imageCompany = contact.office.company.images.sort((a, b) => a.sortOrder - b.sortOrder)[0].img }

  var googleBackup = building ? "https://www.google.com/maps/embed/v1/view?key=" + process.env.REACT_APP_GoogleMapsAPIKey + "&center=" +
    (building.Latitude) + "," + building.Longitude +
    "&zoom=18&maptype=satellite" : <></>

  console.log(googleBackup)
  return (


    <Card
      variant='elevation'
      elevation={0}
      sx={{
        maxWidth: '100%',
        width: '100%',
        position: 'relative'
        // height: 175
      }}>
      {available && available.images && (available.images.length > 0) ?
        <CardMedia
          style={{
            height: mobileAndTabletCheck() ? 300 : 'auto'
          }}
          onClick={
            () => {
              window.open("available?id=" + available.id, mobileAndTabletCheck() ?
                "_self" : null)
            }}

          component="img"
          sx={{ width: '100%', objectFit: 'contain', cursor: 'pointer' }}
          image={(available.images[0].img)}
          alt={group.GroupName}

        /> : <iframe
          style={{ width: '100%', height: 300, cursor: 'pointer' }}
          onClick={
            () => {
              window.open("available?id=" + available.id, mobileAndTabletCheck() ?
                "_self" : null)
            }}
          src={googleBackup}></iframe>}
      <div onClick={
        () => {
          window.open("available?id=" + available.id, mobileAndTabletCheck() ?
            "_self" : null)
        }}
        style={{ display: mobileAndTabletCheck() ? 'none' : 'block', borderRadius: 10, overflow: 'hidden', color: 'white', position: 'absolute', top: 5, left: 5, backgroundColor: '#00000055' }}>
        <Typography component={'div'} sx={{ padding: 1, backgroundColor: 'black', borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>Available Discussion  </Typography>

        <div style={{ display: 'flex' }}>
         
          <Box
            sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              {/* <Typography>{contact.available_contacts[0].RelationshipType}</Typography> */}
              <Typography component={'div'} variant="h5">
                {(available?.Suite ? "Suite " + available?.Suite + ", " : "") + (available?.Floor ? "Floor" + (parseInt(available?.Floor) === -1 ? 'Entire Building' : available.Floor) : "")}
              </Typography>


              {/* <Typography>{group && group.building_groups && group.building_groups[0]
                ? group.building_groups[0].groupPermission
                : "Contact agent or owner"}</Typography> */}




            </CardContent>


          </Box>
        </div>


      </div>

      <AvailableSubMessages contactId={contactId} group={group} groupId={group.id} available={available} />
    </Card>




  );

}
export function BuildingSubGroup(group, contactId, building) {

  var image = "https://images.cubicsearch.com/GroupImage.png"
  if (group && group.images && group.images.length > 0) { image = group.images.sort((a, b) => a.sortOrder - b.sortOrder)[0].img }
  // var imageCompany = "https://images.cubicsearch.com/CompanyLogo.png"
  // if (contact.office && contact.office.company && contact.office.company.images && contact.office.company.images.length > 0) { imageCompany = contact.office.company.images.sort((a, b) => a.sortOrder - b.sortOrder)[0].img }

  var googleBackup = "https://www.google.com/maps/embed/v1/view?key=" + process.env.REACT_APP_GoogleMapsAPIKey + "&center=" +
    (building.Latitude) + "," + building.Longitude +
    "&zoom=18&maptype=satellite"

  console.log(googleBackup)
  return (


    <Card
      variant='elevation'
      elevation={0}
      sx={{
        maxWidth: '100%',
        width: '100%',
        position: 'relative'


        // height: 175
      }}>
      {building && building.images && (building.images.length > 0) ?
        <CardMedia onClick={
          () => {
            window.open("property?id=" + building.id, mobileAndTabletCheck() ?
              "_self" : null)
          }}

          component="img"
          sx={{
            height: mobileAndTabletCheck() ? window.innerHeight / 3 : 'auto',
            width: mobileAndTabletCheck() ? 'auto' : '100%',
            objectFit: 'contain', cursor: 'pointer'
          }}
          image={(building.images[0].img)}
          alt={group.GroupName}

        /> : <iframe
          style={{ width: '100%', height: mobileAndTabletCheck() ? window.innerHeight / 3 : 300, cursor: 'pointer' }}
          onClick={
            () => {
              window.open("property?id=" + building.id, mobileAndTabletCheck() ?
                "_self" : null)
            }}
          src={googleBackup}></iframe>}
      <div onClick={
        () => {
          window.open("property?id=" + building.id, mobileAndTabletCheck() ?
            "_self" : null)
        }} style={{ borderRadius: 10, overflow: 'hidden', color: 'white', position: 'absolute', top: 5, left: 5, backgroundColor: '#00000055', display: mobileAndTabletCheck() ? 'none' : 'block' }}>
        <Typography component={'div'} sx={{ padding: 1, backgroundColor: 'black', borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>Building Discussion  </Typography>

        <div style={{ display: 'flex' }}>

          <Box
            sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              {/* <Typography>{contact.building_contacts[0].RelationshipType}</Typography> */}
              <Typography component={'div'} variant={mobileAndTabletCheck() ? "subtitle1" : "h6"}>
                {building.StreetNumber + " " + building.StreetName}
              </Typography>
              <Typography variant="subtitle1" component="div">
                {building.City + ", " + building.State}
              </Typography>

              {/* <Typography>{group && group.building_groups && group.building_groups[0]
                ? group.building_groups[0].groupPermission
                : "Contact agent or owner"}</Typography> */}




            </CardContent>


          </Box>
        </div>


      </div>

      <BuildingSubMessages contactId={contactId} group={group} groupId={group.id} building={building} />
    </Card>




  );

}

export function BuildingGroup(group, contactId, building) {

  var image = "https://images.cubicsearch.com/GroupImage.png"
  if (group && group.images && group.images.length > 0) { image = group.images.sort((a, b) => a.sortOrder - b.sortOrder)[0].img }
  // var imageCompany = "https://images.cubicsearch.com/CompanyLogo.png"
  // if (contact.office && contact.office.company && contact.office.company.images && contact.office.company.images.length > 0) { imageCompany = contact.office.company.images.sort((a, b) => a.sortOrder - b.sortOrder)[0].img }



  return (


    <Card
      variant='elevation'
      elevation={0}
      sx={{
        maxWidth: mobileAndTabletCheck() ? '100%' : 500,
        width: mobileAndTabletCheck() ? 360 : 500,

        // height: 175
      }}>
      <Typography component={'div'} sx={{ padding: 1, backgroundColor: '#4e46e5', color: 'white' }}>{building.StreetNumber + " " + building.StreetName} Discussion</Typography>
      <div style={{ display: 'flex' }}>
        {image != "https://images.cubicsearch.com/GroupImage.png" ? <CardMedia onClick={
          () => {
            window.open("group?id=" + group.id, mobileAndTabletCheck() ?
              "_self" : null)
          }}

          component="img"
          sx={{ width: 75, objectFit: 'contain', cursor: 'pointer' }}
          image={image}
          alt={group.GroupName}

        /> : <Tooltip placement="top" title={group.GroupName}>
          <Avatar alt={group.GroupName}

            onClick={() => { window.open("group?id=" + group.id, mobileAndTabletCheck() ? "_self" : null) }}
            sx={{ margin: 2, cursor: 'pointer', marginRight: 3 }}
            src={
              group.GroupName.length > 0 ? group.GroupName[0] : "https://images.cubicsearch.com/GroupImage.png"
            }

          >{group.GroupName && group.GroupName.length > 0 && group.GroupName[0]}</Avatar>
        </Tooltip>}
        <Box
          sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            {/* <Typography>{contact.building_contacts[0].RelationshipType}</Typography> */}
            <Typography component={'div'} variant="h5">
              {group.GroupName}
            </Typography>
            <Typography component={'div'} variant="subtitle1" color="text.secondary" >
              {new Date(group.createdAt).toLocaleDateString()}
            </Typography>

            <Typography>{group && group.building_groups && group.building_groups[0]
              ? group.building_groups[0].groupPermission
              : "Contact agent or owner"}</Typography>

            {smallCards(group, contactId)}


          </CardContent>

        </Box>
      </div>
      <BuildingSubMessages contactId={contactId} group={group} groupId={group.id} building={building} />
    </Card>




  );

}
// import React from "react";
// import { Card, CardContent, CardMedia, Typography, Avatar, Tooltip, Box } from "@mui/material";
// import { mobileAndTabletCheck } from "../path/to/utility"; // Adjust this import based on your project structure
// import AvailableSubMessages from "../path/to/AvailableSubMessages"; // Adjust this import based on your project structure


export function AvailGroup({ group, contactId, available }) {
  var image = "https://images.cubicsearch.com/GroupImage.png";
  if (group && group.images && group.images.length > 0) {
    image = group.images.sort((a, b) => a.sortOrder - b.sortOrder)[0].img;
  }

  return (
    <Card
      variant='elevation'
      elevation={0}
      sx={{
        maxWidth: mobileAndTabletCheck() ? '100%' : 500,
        width: mobileAndTabletCheck() ? 360 : 500
      }}
    >
      <Typography component={'div'} sx={{ padding: 1, backgroundColor: '#4e46e5', color: 'white' }}>
        {(available.Suite ? "Suite " + available.Suite + ", " : "") +
          (available.Floor ? (available.Floor === -1 ? "Entire Building" : "Floor " + available.Floor) : "")} Group
      </Typography>
      <div style={{ display: 'flex' }}>
        {image !== "https://images.cubicsearch.com/GroupImage.png" ? (
          <CardMedia
            onClick={() => {
              window.open("group?id=" + group.id, mobileAndTabletCheck() ? "_self" : null);
            }}
            component="img"
            sx={{ width: 75, objectFit: 'contain', cursor: 'pointer' }}
            image={image}
            alt={group.GroupName}
          />
        ) : (
          <Tooltip placement="top" title={group.GroupName}>
            <Avatar
              alt={group.GroupName}
              onClick={() => {
                window.open("group?id=" + group.id, mobileAndTabletCheck() ? "_self" : null);
              }}
              sx={{ margin: 2, cursor: 'pointer', marginRight: 3 }}
              src={
                group.GroupName.length > 0 ? group.GroupName[0] : "https://images.cubicsearch.com/GroupImage.png"
              }
            >
              {group.GroupName && group.GroupName.length > 0 && group.GroupName[0]}
            </Avatar>
          </Tooltip>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography component={'div'} variant="h5">
              {group.GroupName}
            </Typography>
            <Typography component={'div'} variant="subtitle1" color="text.secondary">
              {new Date(group.createdAt).toLocaleDateString()}
            </Typography>
            <Typography>
              {group && group.avail_groups && group.avail_groups[0] && group.avail_groups[0].groupPermission
                ? group.avail_groups[0].groupPermission
                : "Contact agent or owner"}
            </Typography>
            {smallCards(group, contactId)}
          </CardContent>
        </Box>
      </div>
      <AvailableSubMessages contactId={contactId} group={group} groupId={group.id} available={available} />
    </Card>
  );
}
// export function AvailGroup(group, contactId, available) {

//   var image = "https://images.cubicsearch.com/GroupImage.png"
//   if (group && group.images && group.images.length > 0) { image = group.images.sort((a, b) => a.sortOrder - b.sortOrder)[0].img }
//   // var imageCompany = "https://images.cubicsearch.com/CompanyLogo.png"
//   // if (contact.office && contact.office.company && contact.office.company.images && contact.office.company.images.length > 0) { imageCompany = contact.office.company.images.sort((a, b) => a.sortOrder - b.sortOrder)[0].img }

//   return (


//     <Card sx={{
//       maxWidth: mobileAndTabletCheck() ? '100%' : 500,
//       width: mobileAndTabletCheck() ? 360 : 500
//       // height: 175
//     }}>
//       <Typography component={'div'}sx={{ padding: 1, backgroundColor: '#4e46e5', color: 'white' }}>Discussion Group</Typography>
//       <div style={{ display: 'flex' }}>
//         <CardMedia onClick={
//           () => {
//             window.open("group?id=" + group.id, mobileAndTabletCheck() ?
//               "_self" : null)
//           }}

//           component="img"
//           sx={{ width: 75, objectFit: 'contain', cursor: 'pointer' }}
//           image={image}
//           alt={group.GroupName}

//         />
//         <Box
//           sx={{ display: 'flex', flexDirection: 'column' }}>
//           <CardContent sx={{ flex: '1 0 auto' }}>
//             {/* <Typography>{contact.building_contacts[0].RelationshipType}</Typography> */}
//             <Typography  component={'div'} variant="h5">
//               {group.GroupName}
//             </Typography>
//             <Typography component={'div'} variant="subtitle1" color="text.secondary" >
//               {new Date(group.createdAt).toLocaleDateString()}
//             </Typography>

//             <Typography>{group && group.avail_groups && group.avail_groups[0]
//               ? group.avail_groups[0].groupPermission
//               : "Contact agent or owner"}</Typography>

//             {smallCards(group, contactId)}



//           </CardContent>

//         </Box>

//         <AvailableSubMessages contactId={contactId} group={group} groupId={group.id}  available={available} />
//       </div>

//     </Card>



//   );

// }
export function smallCards(group, contactId) {

  var memberCount = 0

  if (!group.contacts && group.group_contacts) {
    group.contacts = []
    group.group_contacts.map((group_contact, index) => {
      if (group_contact.contact) {
        var contact = group_contact.contact
        contact.group_contact = {}

        contact.group_contact.sortOrder = group_contact.sortOrder
        contact.group_contact.PermissionLevel = group_contact.PermissionLevel
        contact.group_contact.contact_id = group_contact.contact_id

        if (contact?.group_contact?.contact?.group_contact) {
          delete contact.group_contact.contact.group_contact
        }
        group.contacts.push(contact)


      }
    })

  }

  return (

    group.id !== 1 ? (
      <>
        <AvatarGroup max={5}>
          {group.contacts
            .sort(
              (a, b) =>
                a.group_contact.sortOrder - b.group_contact.sortOrder
            )
            .map((member, index) => {



              if (member.group_contact.PermissionLevel !== "Data") {
                memberCount = memberCount + 1
                {/* Show All Members except Data */ }
                return (


                  group.MembersVisible === "Yes" ? (
                    <Tooltip placement="top" title={member.FirstName + " " + member.LastName + " - " +
                      (member && member.office && member.office.company ? member.office.company.Name : member.Email)}>
                      <Avatar key={member.FirstName + member.LastName + index} alt={member.FirstName + ' ' + member.LastName}

                        onClick={() => { window.open("contact?id=" + member.id, mobileAndTabletCheck() ? "_self" : null) }}
                        sx={{ cursor: 'pointer' }}
                        src={
                          member.images.length > 0
                            ? member.images.sort(
                              (a, b) => a.sortOrder - b.sortOrder
                            )[0].img
                            : null
                        }

                      >{member.FirstName && member.FirstName.length > 0 && member.FirstName[0]}</Avatar>
                    </Tooltip>


                  ) : member.group_contact.PermissionLevel ===
                    "Lead Edit" ||
                    member.group_contact.PermissionLevel === "Edit" ||
                    member.group_contact.contact_id === contactId ||
                    contactId === 1 ? (
                    <Tooltip placement="top"
                      title={member.FirstName + " " + member.LastName + " - " +
                        (member && member.office && member.office.company ? member.office.company.Name : member.Email)}>
                      <Avatar alt={member.FirstName + " " + member.LastName}

                        onClick={() => { window.open("contact?id=" + member.id, mobileAndTabletCheck() ? "_self" : null) }}
                        sx={{ cursor: 'pointer' }}
                        src={
                          member.images.length > 0
                            ? member.images.sort(
                              (a, b) => a.sortOrder - b.sortOrder
                            )[0].img
                            : null
                        }

                      >{member.FirstName && member.FirstName.length > 0 && member.FirstName[0]}</Avatar>
                    </Tooltip>
                  ) : (
                    <div>

                    </div>
                  )

                )
              }
            })}
        </AvatarGroup>   </>) : (
      <></>
    )
  )
}

export default InfoCard;


function addToSurvey(surveyId, propertyId, contactId) {
  return new Promise(async (resolve, reject) => {

    var results = await axios.post(process.env.REACT_APP_DATABASE + "api/addToSurvey",
      { surveyId: surveyId, propertyId: propertyId, contactId: contactId })

    if (results && results.data) {
      resolve(results)

      alert("Property added to survey.")
      return (true)
    }
    else {
      console.log(results)
      reject({ reason: "Problem with axios request." })
      alert("There was a problem with the survey add request.")
      return (false)
    }

  })
}