import { useState } from "react";
import AvailableGroupsEditTable from "./AvailableGroupsEditTable";
import AvailableGroupsRows from "./AvailableGroupsRows";
import { Save, Edit } from "@mui/icons-material";
import { Accordion, AccordionSummary, AccordionDetails, IconButton, Button, Typography } from "@mui/material";

async function setAdd(addingGroup, setAddingGroup, setButtonText, contactId) {
  const fetchLocation = process.env.REACT_APP_DATABASE + "api/addGroup?id=" + contactId;

  if (addingGroup) {
    setButtonText(<Edit sx={{ width: 18, height: 18 }} />);
  } else {
    setButtonText(<Save />);
  }
  setAddingGroup(!addingGroup);
}

export default function AvailableGroupList({
  groupsIn,
  setGroupsIn,
  contactId,
  availableId,
  available,
  groupsCombo,
  highestPermission,
  setData,
  guest,
}) {
  const [addingGroup, setAddingGroup] = useState(false);
  const [buttonText, setButtonText] = useState(<Edit sx={{ width: 18, height: 18 }} />);
  const [groupsButtonText, setGroupsButtonText] = useState(<Edit sx={{ width: 18, height: 18 }} />);
  const [expanded, setExpanded] = useState(true);

  if (groupsIn.length > 0) {
    return (
      <>
        <div role="list">
          <div className="flex sm:items-center">
            <div>
              <div className={"text-xl font-semibold text-gray-900"}>
                Available Discussion Groups
              </div>
              <Typography component={'div'} variant="h7">
                Messages and Changes
              </Typography>
            </div>
            <Button
              variant={'outlined'}
              sx={{ marginLeft: 1, minWidth: 0, width: 20, height: 24, borderColor: 'black', color: 'black' }}
              onClick={() => {
                if (guest === true || guest === 'true') {
                  alert("Please login or sign up to make edits.");
                } else {
                  setAdd(addingGroup, setAddingGroup, setGroupsButtonText, contactId);
                }
              }}
            >
              {groupsButtonText}
            </Button>
          </div>
        </div>
        {addingGroup ? (
          <AvailableGroupsEditTable
            contactId={contactId}
            availId={availableId}
            groupsIn={groupsIn}
            groupsCombo={groupsCombo}
            setGroupsIn={setGroupsIn}
            highestPermission={highestPermission}
            setData={setData}
          />
        ) : (
          <Accordion expanded={expanded}>
            <AccordionSummary onClick={() => { setExpanded(!expanded) }}>
              <Typography component={'div'} sx={{ padding: 1, borderRadius: 2, backgroundColor: '#4e46e5', color: 'white' }}>
                Show Groups
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AvailableGroupsRows
                groupsIn={groupsIn}
                contactId={contactId}
                highestPermission={highestPermission}
                availableId={availableId}
                available={available}
              />
            </AccordionDetails>
          </Accordion>
        )}
      </>
    );
  } else {
    return (
      <div role="list" className="px-0 sm:px-0 lg:px-0">
        <div className="flex">
          <Typography variant="h2" fontWeight={'bold'} fontSize={20}>No Groups</Typography>
          <div className="mt-4 sm:mt-0 sm:flex-none">
            <Button
              variant={'outlined'}
              sx={{ marginLeft: 1, minWidth: 0, width: 20, height: 24, borderColor: 'black', color: 'black' }}
              onClick={() => setAdd(addingGroup, setAddingGroup, setButtonText, contactId)}
            >
              {buttonText}
            </Button>
          </div>
        </div>
        {addingGroup ? (
          <AvailableGroupsEditTable
            contactId={contactId}
            availId={availableId}
            groupsIn={groupsIn}
            groupsCombo={groupsCombo}
            setGroupsIn={setGroupsIn}
            highestPermission={highestPermission}
            setData={setData}
          />
        ) : (
          <AvailableGroupsRows
            groupsIn={groupsIn}
            contactId={contactId}
            highestPermission={highestPermission}
            availableId={availableId}
            available={available}
          />
        )}
      </div>
    );
  }
}
