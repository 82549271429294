//

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useState } from "react";
import { Check as CheckIcon, ExpandMore as SelectorIcon } from '@mui/icons-material'
import { Combobox } from "@headlessui/react";

// const groups = [
//   {
//     id: 1,
//     name: 'Leslie Alexander',
//     imageUrl:
//       'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },
//   // More users...
// ]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MyGroupsLookupComboBox({
  groupsCombo,
  selectedGroup,
  setSelectedGroup,
  setButtonText,
  setAddGroup,
  setNewGroupName,
}) {
  //test - console.log("avail group combo");
  //test - console.log(groupsCombo);
  const [query, setQuery] = useState("");

  const filteredGroups =
    query === ""
      ? groupsCombo
      : groupsCombo.filter((group) => {
          return group.GroupName.toLowerCase().includes(query.toLowerCase());
        });

  if (filteredGroups.length > 0) {
    setButtonText("Select Group");
    setAddGroup(false);
  } else {
    setAddGroup(true);
    setButtonText("Add New Group");
    setNewGroupName(query);
    // if ( selectedGroup.name !== query) {

    //   setSelectedGroup({id:1, name:query})
    // }
  }

  return (
    <Combobox as="div" value={selectedGroup} onChange={setSelectedGroup}>
      <Combobox.Label className="block text-sm font-medium text-gray-700">
        Start Typing the Group Name
      </Combobox.Label>
      <div className="relative mt-1">
        <Combobox.Input
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500 sm:text-sm"
          onChange={(event) => {
            setNewGroupName(event.target.value);
            //test - console.log(event.target.value)
  
            setQuery(event.target.value);
            
          }}
          displayValue={(group) => (group ? group.GroupName : "")}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredGroups.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredGroups.map((group) => (
              <Combobox.Option
                key={group.id}
                value={group}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-red-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex items-center">
                      <img
                        src={
                          group.images.length > 0
                            ? group.images[0].img
                            : "https://images.cubicsearch.com/GroupImage.png"
                        }
                        alt=""
                        className="h-6 w-6 flex-shrink-0 rounded-full object-cover"
                      />
                      <span
                        className={classNames(
                          "ml-3 truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {group.GroupName}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-red-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
