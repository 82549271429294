//import Image from "next/image";
import AvailsList from "./AvailsList";
import ContactList from "./ContactList";
import BuildingGroupList from "./BuildingGroups/BuildingGroupList";
import { useState, useEffect } from "react";
import { InputComponent } from "../Common/InputComponent";
import { Tabs } from "../Common/Tabs";

import { Button, IconButton } from "@mui/material";


import WebsiteList from "./Websites/WebsiteList";

import {

  Bookmark as BookmarkAltIcon,
  Work,

  LocalShipping,
} from "@mui/icons-material";

import {Bolt as LightningBoltIcon, Science as BeakerIcon, Business} from "@mui/icons-material"

import { textAlign } from "@mui/system";
import { mobileAndTabletCheck } from "../Search/Deck";
import axios from "axios";

const textAreaFields = ["Notes"];
const integerFields = [
  "FloorToFloor",
  "FloorToFloorL1",
  "FloorToFloorTOP",
  "LiveLoad",
  "FreightElevators",
  "PowerAmps",
  "PowerVolts",
  "RoofScreenHeight",
  "BuildingHeight",
  "Elevation",
  "Size",
  "YearBuilt",
  "YearRenovated",
  "Footprint",

  "NumberFloors",
  "DockDoors",
  "PowerAmps",
  "PowerVolts",
  "GradeDoors",
  "PassengerElevators",
  "Showers",
];

const commaFields = ["Size", "Footprint"];

const decimalFields = ["ParkingRatio",];

const tabFields = {
  "Life Science": [
    "CampusName",
    "PropertyType",
    "FloorToFloor",
    "FloorToFloorL1",
    "FloorToFloorTOP",
    "LiveLoad",
    "FreightElevators",
    "ConstructionType",
    "BackupGenerator",
    "PowerAmps",
    "PowerVolts",
    "RoofScreenHeight",
    "InitialPurpose",
    "CurrentPurpose",
    "PotentialPurpose",
    "UtilityPad",
    "SeparatedLabAir",
    "CoolingSystem",
    "MechanicalMezz",
    "Building Height",
  ],

  "Shell Exterior": [
    "CampusName",
    "StreetName",
    "StreetNumber",
    "PropertyName",
    "PropertyType",
    "Size",
    "City",
    "State",
    "Zip",
    "County",
    "YearBuilt",
    "YearRenovated",
    "Footprint",
    "NumberFloors",
    "BuildingHeight",
    "Elevation",
    "Notes",
    "ConstructionStatus",
    "ConstructionType",
    "CurrentPurpose",
    "ParkingStyle",
    "ParkingRatio",
    "Showers",
    "Gym",
    "MeetingRooms",
    "Expenses",
    "ExpenseDescription",
    "OwnerUserOrInvestor",
  ],
  Infrastructure: [
    "FloorToFloor",
    "FloorToFloorL1",
    "FloorToFloorTOP",
    "LiveLoad",
    "FreightElevators",
    "ConstructionType",
    "BackupGenerator",
    "PowerAmps",
    "PowerVolts",
    "RoofScreenHeight",
    "InitialPurpose",
    "CurrentPurpose",
    "PotentialPurpose",
    "UtilityPad",
    "SeparatedLabAir",
    "CoolingSystem",
    "MechanicalMezz",
    "Building Height",
    "FiberLines",
    "OccupancyRating",
    "ConstructionStatus",
  ],
  Industrial: [
    "CampusName",
    "DockDoors",
    "GradeDoors",
    
    "FloorToFloor",
    "FloorToFloorL1",
    "FloorToFloorTOP",

    "FreightElevators",
    "ConstructionType",
    "BackupGenerator",
    "PowerAmps",
    "PowerVolts",
    "RoofScreenHeight",
  ],
  Land: [
    "InitialPurpose",
    "CurrentPurpose",
    "PotentialPurpose",
    "UtilityPad",
    "ParkingType",
  ],
  Office: ["PassengerElevators", "MeetingRooms", "Showers", "Gym"],
};

function MainPropertyInfo({
  dataIn,
  // peopleCombo,
  setDataIn,
  contactId,
  groups,
  groupsCombo,
  highestPermission,
  editMode,
  setEditMode,
  editClick,
  setEditButtonText,
  editButtonText,
  updateBuilding,
  campusNames,
  guest,
}) {

  const [showJournal, setShowJournal] = useState(false);
  const [campusNamesState, setCampusNamesState] = useState(campusNames)

  useEffect(() => {
    if (editMode ==="Edit") {
      
      axios.get(process.env.REACT_APP_DATABASE + "api/getCampusNames").then((names) => {
        setCampusNamesState(names.data)
      })
    }

  },[editMode])

  var optionFields = [
    {
      field: "PropertyType",
      options: [
        "Life Science",
        "Life Science or Industrial",
        "Life Science or Office",
        "Life Science, R&D, or Office",
        "Life Science, R&D, or Industrial",
        "GMP",
        "R&D",
        "R&D or Office",
        "Office",
        "Industrial",
        "Retail",
        "Other",
      ],
    },
    {
      field: "CampusName",
      options: ["Kilroy Oyster Point", "Hillsdale Mall"],
    },
    {
      field: "ConstructionStatus",
      options: [
        "Planned",
        "Entitled",
        "Site Work",
        "Under Construction",
        "Shell Complete",
        "Interior Under Construction",
        "Existing",
        "Other",
      ],
    },
    {
      field: "ConstructionType",
      options: [
        "Steel",
        "Concrete and Steel",
        "Concrete",
        "Woodframe",
        "Concrete and Woodframe",
        "Other",
      ],
    },
    {
      field: "Gym",
      options: ["Extensive Fitness Facility", "Small Gym", "None", "Other"],
    },
    {
      field: "MeetingRooms",
      options: ["Conference Center", "Private Rooms(s)", "None", "Other"],
    },
    {
      field: "ParkingStyle",
      options: [
        "Structured",
        "Structured Valet",
        "Structure and Surface",
        "Surface",
        "Surface Valet",
        "Surface and Underground",
        "Underground",
        "Partial Structure and Underground",
        "Other",
      ],
    },
    {
      field: "County",
      options: [
        "San Mateo",
        "San Francisco",
        "Alameda",
        "Santa Clara",
        "Contra Costa",
      ],
    },
    {
      field: "OwnerUserOrInvestor",
      options: ["Investor", "Owner User"],
    },
    {
      field: "InitialPurpose",
      options: ["Life Science", "Industrial", "Office", "R&D"],
    },
    {
      field: "PotentialPurpose",
      options: ["Life Science", "Industrial", "Office", "R&D"],
    },
    {
      field: "CurrentPurpose",
      options: ["Life Science", "Industrial", "Office", "R&D"],
    },
    {
      field: "BackupGenerator",
      options: ["Yes", "No"],
    },
    {
      field: "MechanicalMezz",
      options: ["Yes", "No"],
    },
    {
      field: "OccupancyRating",
      options: ["B", "L", "Industrial", "H"],
    },
    {
      field: "CoolingSystem",
      options: ["BSL2", "Single Pass", "Recirculated", "Other", "None"],
    },
    {
      field: "UtilityPad",
      options: ["Generator & Chemicals", "Small", "Potential", "Other", "None"],
    },
  ];

  
  optionFields[1].options = optionFields[1].options.concat(
    campusNamesState.map((cname) => {
    
      return cname.CampusName;
    })
  );
  
   const [groupsIn, setGroupsIn] = useState(groups);

  const [tabHighLight, setTabHighLight] = useState("Shell Exterior");
  var entries = [];
  for (const [key, value] of Object.entries(dataIn)) {
    entries.push({ fieldName: `${key}`, fieldValue: value });
  }
  // var highestPermission = "None";
  var nfObject = new Intl.NumberFormat("en-US");

  const [people, setPeople] = useState(
    dataIn&& dataIn.contacts && dataIn.contacts.sort(
      (a, b) => a.building_contacts[0].sortOrder - b.building_contacts[0].sortOrder
    )
  );

  var avails = dataIn.avails.filter((availIn) => {
    if (availIn.Status !== "Lease Comp") {
      return true;
    } else return false;
  });

  var comps = dataIn.avails.filter((availIn) => {
    if (availIn.Status === "Lease Comp") {
      return true;
    } else return false;
  });

  var missingInfo = [];
  
  return (
    <>
      {/* <JournalSplash
        open={showJournal}
        setOpen={setShowJournal}
        // journalsIn={dataIn.generic_journals}
        contactId={contactId}
        entityId={dataIn.id}
        type={"Building"}
      /> */}
      <div style={{ paddingLeft: mobileAndTabletCheck() ? 10 : 20, paddingRight: mobileAndTabletCheck() ? 0 : 20 }} className="bg-white shadow overflow-hidden sm:rounded-lg">
      

        <div className=" py-4 flex ">

          <div style={{paddingRight: 4}} className="text-xl font-semibold text-gray-900">
            Building Data
          </div>
          {highestPermission === "Edit" ||
            highestPermission === "Lead Edit" ? (
            <Button variant={'outlined'} sx={{ minWidth: 0, width: 20, height: 24, borderColor: 'black', color: 'black' }}
              onClick={() => {
                if (guest === true || guest === 'true') {
                  alert("Please login or sign up to make edits.");
                } else {
                  editClick(
                    setEditMode,
                    setEditButtonText,
                    editMode,
                    dataIn,
                    setDataIn,
                    contactId
                  );
                }
              }}
            >
              {editButtonText}
            </Button>
          ) : (
            ""
          )}



        </div>

        {/* <div>
          <Button variant={'outlined'} size={'small'} sx={{ borderColor: 'black', color: 'black' }}
            onClick={() => {
              if (guest === true || guest === 'true') {
                alert("Please login or sign up to make edits.");
              } else {
                setShowJournal(true);
              }
            }}
          >
            Data History
          </Button>
        </div> */}
        <Tabs
          tabHighLight={tabHighLight}
          setTabHighLight={setTabHighLight}
          tabs={tabs}
          dataIn={dataIn}
          setData={setDataIn}
          update={updateBuilding}
          editMode={editMode}
          contactId={contactId}
          type={"Building"}
        />



        <div className="  py-2 sm:px-6 ">
          <dl className="grid grid-cols-1 bg-gray-100 rounded-xl px-4 py-4 gap-x-4 gap-y-8 sm:grid-cols-2">
            {entries.map((entry, index) => {
              var opinions = [];
              if (
                [
                  "id",
                  "markedDelete",
                  "Longitude",
                  "Latitude",
                  "createdAt",
                  "updatedAt",
                  "campus_id",
                  "building_groups",
                  "contacts",
                  "avails",
                  "websites",
                  "companies",
                  "parcels",
                  "campus",
                  "images",
                ].indexOf(entry.fieldName) === -1
              ) {
                if (tabFields[tabHighLight].indexOf(entry.fieldName) !== -1) {
                  if (!entry.fieldValue) {
                    missingInfo.push(entry.fieldName);
                  }

                  if (editMode === "View") {
                    return (
                      <div
                        key={index}
                        className={
                          !entry.fieldValue ||
                            entry.fieldName === "StreetName" ||
                            entry.fieldName === "StreetNumber" ||
                            entry.fieldName === "County" ||
                            entry.fieldName === "City" ||
                            entry.fieldName === "Zip" ||
                            entry.fieldName === "State"
                            ? "hidden"
                            : "col-span-2 sm:col-span-1  "
                        }
                      >
                        <div className="flex">
                          <div className="w-full flex items-start">
                            <dt className="text-sm mt-1 font-medium text-gray-500 px-2 w-2-third sm:w-half">
                              {entry.fieldName === "ParkingRatio"
                                ? entry.fieldName
                                  .replace(/([A-Z])/g, " $1")
                                  .trim() + " / 1000 SF"
                                : entry.fieldName
                                  .replace(/([A-Z])/g, " $1")
                                  .trim()}
                            </dt>
                            <dd
                              className={
                                textAreaFields.indexOf(entry.fieldName) > -1
                                  ? "mt-1 font-semibold text-sm text-gray-900 whitespace-pre-line w-full"
                                  : "mt-1 font-semibold text-sm text-gray-900 w-full"
                              }
                            >
                              {commaFields.indexOf(entry.fieldName) === -1
                                ? entry.fieldValue
                                  ? entry.fieldValue.toString()
                                  : ""
                                : entry.fieldValue
                                  ? entry.fieldValue
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : ""}

                           
                            </dd>
                           
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div key={index}
                        className="sm:col-span-1 ">
                        <dt className="text-sm font-medium text-gray-500">
                          {entry.fieldName === "ParkingRatio"
                            ? entry.fieldName
                              .replace(/([A-Z])/g, " $1")
                              .trim() + " / 1000 SF"
                            : entry.fieldName.replace(/([A-Z])/g, " $1").trim()}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {optionFields.filter(function (el) {
                            if (el.field === entry.fieldName) {
                              return true;
                            }
                          }).length > 0 ? (
                            <InputComponent
                              inputType={"simpleselect"}
                              defaultShow={entry.fieldValue}
                              id={dataIn.id}
                              name={entry.fieldName}
                              valueIn={entry.fieldValue}
                              options={
                                optionFields.find((el) => {
                                  if (el.field === entry.fieldName) {
                                    return true;
                                  }
                                }).options
                              }
                            />
                          ) : textAreaFields.indexOf(entry.fieldName) > -1 ? (
                            <InputComponent
                              inputType={"textarea"}
                              defaultShow={""}
                              id={dataIn.id}
                              name={entry.fieldName}
                              valueIn={entry.fieldValue}
                            />
                          ) : integerFields.indexOf(entry.fieldName) === -1 ? (
                            decimalFields.indexOf(entry.fieldName) === -1 ? (
                              <InputComponent
                                inputType={"text"}
                                defaultShow={entry.fieldValue}
                                id={dataIn.id}
                                name={entry.fieldName}
                                valueIn={entry.fieldValue}
                              />
                            ) : (
                              <InputComponent
                                inputType={"decimal"}
                                defaultShow={entry.fieldValue}
                                id={dataIn.id}
                                name={entry.fieldName}
                                valueIn={entry.fieldValue}
                              />
                            )
                          ) : (
                            <InputComponent
                              inputType={"number"}
                              defaultShow={entry.fieldValue}
                              id={dataIn.id}
                              name={entry.fieldName}
                              valueIn={entry.fieldValue}
                            />
                          )}{" "}
                        </dd>
                      </div>
                    );
                  }
                }
              }
            })}
            {/* {missingInfo.length > 1 ? (
              <>
                <div className="col-span-2">
                  <h2 className="text-medium">Missing Information:</h2>
                  <h2 className="text-xs">
                    {missingInfo.map((field, index) => {
                      var bar = missingInfo.length >= index + 2 ? " | " : " ";
                      return field.replace(/([A-Z])/g, " $1").trim() + bar;
                    })}
                  </h2>
                </div>

                {missingInfo.length >= 1 ? (
                  <button
                    type="button"
                    className="ml-3 inline-flex justify-center w-20 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    onClick={() => {
                      if (guest === true || guest === 'true') {
                        alert("Please login or sign up to make edits.");
                      } else {
                        editClick(
                          setEditMode,
                          setEditButtonText,
                          editMode,
                          dataIn,
                          setDataIn,
                          contactId
                        );
                      }
                    }}
                  >
                    {editButtonText}
                  </button>
                ) : (
                  ""
                )}
              </>
            ) : (
              <></>
            )} */}

            {/* Missing Info: {missingInfo.toString()} */}
          </dl>
        </div>


        <ContactList
          peopleIn={people}
          dataIn={dataIn}
          setPeopleIn={setPeople}

          contactId={contactId}
          groupsIn={groupsIn}
          highestPermission={highestPermission}
          guest={guest}
        />

        <div>
          <div
            id="availables"
            key="availables"
            className="border-gray-200"
          >
            <AvailsList
              availsIn={avails}
              buildingId={dataIn.id}
              contactId={contactId}
              highestPermission={highestPermission}
              availOrComp="Available"
              guest={guest}
            />
          </div>
          <div
            id="comps"
            key="comps"
            className="border-gray-200 "
          >
            <AvailsList
              availsIn={comps}
              buildingId={dataIn.id}
              contactId={contactId}
              highestPermission={highestPermission}
              availOrComp="Comp"
              guest={guest}
            />
          </div>
        </div>

        <div style={{ paddingLeft: 0, paddingRight: 24, paddingTop: 30 }}
        // className=" border-gray-200 px-2 py-5 sm:px-8"
        >
          <BuildingGroupList
            groupsIn={groupsIn}
            setGroupsIn={setGroupsIn}
            buildingId={dataIn.id}
            building = {dataIn}
            contactId={contactId}
            groupsCombo={groupsCombo}
            highestPermission={highestPermission}
            setData={setDataIn}
            guest={guest}
          />
        </div>
        <div style={{ paddingLeft: 0, paddingRight: 24, paddingTop: 30 }}
        // className="border-t border-gray-200 px-2 py-5 sm:px-6"
        >
          <WebsiteList
            mainPageData={dataIn}
            setMainPageData={setDataIn}
            contactId={contactId}
            highestPermission={highestPermission}
            buildingId={dataIn.id}
          />
        </div>
      </div>



    </>
  );
}

export default MainPropertyInfo;

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

const tabs = [
  {
    name: "Shell Exterior",
    href: "#",
    icon: Business,
    current: true,
  },
  {
    name: "Infrastructure",
    href: "#",
    icon: LightningBoltIcon,
    current: false,
  },
  { name: "Life Science", href: "#", icon: BeakerIcon, current: false },
  { name: "Industrial", href: "#", icon: LocalShipping, current: false },
  { name: "Office", href: "#", icon: Work, current: false },
  { name: "Land", href: "#", icon: BookmarkAltIcon, current: false },
];
