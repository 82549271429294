import React from "react";
import {  useState } from 'react';

import Header from "../Common/Header";
import MyGroupsList from "./MyGroupsList";



export default function MyGroupsDetail ({groupsInformation, groupsInvitedInformation, contactId, user}) {



return (
    <div >
       <nav className="sticky z-50 top-0">
       <Header key="1" icon="group"
        placeholderLocation={'MyGroups'}
        user = {user}
       /></nav>
   
        <div id="groups" key= "groups" style={{maxWidth: '100%', padding :3}}>
      <MyGroupsList groupsIn={groupsInformation} contactId={contactId} />
        </div>
    </div>
);


}

  
  