import { useState } from "react";
import ProjectListRows from "./ProjectListRows";
import ClientProjectEditTable from "./ClientProjectEditTable";
import { Save, Edit } from "@mui/icons-material";
async function setAdd(addingProject, setAddingProject, setButtonText, clientId) {
  // var fetchLocation =
  //   process.env.REACT_APP_DATABASE + "api/addProject?id=" +
  //   clientId;

  // const searchResults = await data.json();

  if (addingProject) {
    setButtonText(<Edit sx={{width: 18, height: 18}}/>);
    //const data = await fetch(fetchLocation);
    ////test - console.log(data);
  } else {
    setButtonText(<Save/>);
  }
  setAddingProject(!addingProject);
}

export default function ProjectsList({ projectsIn, clientId, contactId }) {
  const [addingProject, setAddingProject] = useState(false);
  //test - console.log("Project In:");
  //test - console.log(projectsIn);
  const [buttonText, setButtonText] = useState(<Edit sx={{width: 18, height: 18}}/>);
  const [projectsState, setProjectsState] = useState(projectsIn);

  if (projectsState && projectsState.length > 0) {
    //test - console.log("projectsState updates");
    //test - console.log(projectsState);
    return (
      <>
        <div role="list" className="px-0 sm:px-0 lg:px-0">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">
                Projects
              </h1>
            </div>
            <div className="mt-4 sm:mt-0 justify-end sm:ml-16 sm:flex-none">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                onClick={() =>
                  setAdd(addingProject, setAddingProject, setButtonText, clientId)
                }
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
        {addingProject ? (
          <ClientProjectEditTable
            clientId={clientId}
            contactId={contactId}
            dataIn={projectsState}
            setDataIn={setProjectsState}
          />
        ) : (
          <ProjectListRows projectsIn={projectsState} />
        )}
      </>
    );
  } else {
    //test - console.log("NOTHING!!!");
    //test - console.log(projectsState);
    return (
      <div role="list" className="px-0 sm:px-0 lg:px-0">
        <div className="flex justify-between sm:items-center">
          <h1 className="text-xl font-semibold text-gray-900">
            No Projects
          </h1>
          <div className="mt-4 sm:mt-0 sm:ml-16  sm:flex-none">
            <button
              type="button"
              className="inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
              onClick={() =>
                setAdd(addingProject, setAddingProject, setButtonText, clientId)
              }
            >
              {buttonText}
            </button>
          </div>
        </div>
        {addingProject ? (
          <ClientProjectEditTable
            contactId={contactId}
            clientId={clientId}
            dataIn={projectsState}
            setDataIn={setProjectsState}
          />
        ) : (
          <ProjectListRows projectsIn={projectsState} />
        )}
      </div>
    );
  }
}
