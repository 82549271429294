import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Button, Avatar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';

// Assuming you have a function to fetch groups, e.g., fetchGroups()
// and a function to add a group to the mailList, e.g., addToMailList(group)

function GroupAutocomplete({ contactId, addToMailList }) {
    const [groups, setGroups] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [selectedValue, setSelectedValue] = useState(null)

    const fetchGroups = async () => {
        var qs = process.env.REACT_APP_DATABASE + "api/getGroupsInvitedTo?contactId=" + contactId
        var results = await axios.get(qs)
        if (results) {
            return results.data
        }
        else return null
    }
    useEffect(() => {
        // Fetch groups the user belongs to
        fetchGroups().then(setGroups);
    }, []);

    const handleSelectGroup = (event, value) => {
        if (value) {
            // Add selected group to the mailList
            setSelectedValue(value)
            addToMailList(value);
            setInputValue('')
            //onClose(); // Close dialog after adding
        }
    };

    const handleCreateGroup = () => {
        // Logic to create a new group
        // For simplicity, it's not detailed here
        console.log('Create a new group with name:', inputValue);
        //onClose(); // Close dialog after creation
    };

    const options = groups

    // Adds a special option to create a new group when no results are found or no query is entered
    const getOptionLabel = (option) => {
        // if (option.GroupName === inputValue) {
        //     return `Create "${option}"`;
        // }
        return option.GroupName;
    };
    
    return (
        <>
            <Autocomplete
                freeSolo
                options={options}
                value={selectedValue}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option) => (
                    <li {...props} key={option.id}> {/* Ensure each option has a unique key */}
                        {option.images?.[0]?.img ? <img
                            src={option.images?.[0]?.img || 'http://www.cubicsearch.com/images/none.jpg'}
                            alt={option.GroupName} style={{ width: 56, height: 56, objectFit: 'contain', borderRadius: 10 }} />
                            : <Avatar sx={{ marginRight: 1, width: 50, height: 50 }} src={'http://www.cubicsearch.com/images/none.jpg'} alt={option.GroupName} />
                        }
                        <div style={{ marginLeft: 2 }}>{option.GroupName}</div>
                    </li>
                )}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                onChange={handleSelectGroup}
                renderInput={(params) => (
                    <TextField {...params} label="Add an Existing Group" variant="outlined" placeholder="Search Groups" />
                )}
                getOptionLabel={getOptionLabel}
            />

            {inputValue && !options.includes(inputValue) && (
                <Button startIcon={<AddIcon />} onClick={handleCreateGroup}>
                    Create "{inputValue}"
                </Button>
            )}
        </>
    );
}

export default GroupAutocomplete;
