// client/src/components/CampaignForm.js
import React, { useState } from 'react';
import axios from 'axios';

const CampaignForm = ({contactId}) => {
    const [formData, setFormData] = useState({
        clientName: '',
        projectName: '',
        campaignName: '',
        // Add more fields as needed
    });

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Submit form data to the backend for creating/editing campaigns
        axios.post('/api/campaigns', formData)
            .then(response => console.log('Campaign created/edited:', response.data))
            .catch(error => console.error('Error creating/editing campaign:', error));
    };

    return (
        <div>
            <h2>Create/Edit Campaign</h2>
            <form onSubmit={handleSubmit}>
                {/* Add form fields for clientName, projectName, campaignName, etc. */}
                <label>
                    Client Name:
                    <input type="text" name="clientName" value={formData.clientName} onChange={handleInputChange} />
                </label>
                {/* Add more form fields as needed */}
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default CampaignForm;
