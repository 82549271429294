import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import GroupContactNew from './GroupContactNew';
// Other imports...

const NewContactDialog = ({ open, onClose, createContact, NameIn }) => {
    // `createContact` is a function passed down or defined in this component
    // that handles the actual creation logic, like the async function you have.
    const [person, setPerson] = useState(null)

    const handleCreate = async () => {
        await createContact(person); // Implement your contact creation logic here
        onClose(); // Close the dialog
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setPerson(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }));
    };


    useEffect(() => {
        // if (!person && NameIn) {
        
            if (NameIn && NameIn?.includes(" ")) {
                var [FirstName, LastName] = NameIn.split(" ")
                setPerson({

                    FirstName: FirstName,
                    LastName: LastName
                });
            }
            else {
                setPerson({

                    FirstName: NameIn,

                });
            }
        // }
}, [open])

   

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Create A New Contact</DialogTitle>
            <DialogContent>
                <GroupContactNew handleChange={handleChange} person={person} NameIn={NameIn} onSubmit={handleCreate} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleCreate}>Create</Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewContactDialog