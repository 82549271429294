import React, { useState } from 'react';
import { TextField, Link, Box, Stack, Button, IconButton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import MyDatePicker from './MyDatePicker'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import axios from 'axios'
import { Tooltip } from '@mui/material';

dayjs.extend(customParseFormat);


const EmailFrequencyForm = ({ frequency, setFrequency, emailDetails, setEmailDetails, setIndexIn, indexIn, setSaveEnabled, saveEnabled }) => {
    // const { frequencyType, specificDates, startDate, endDate, repeatInterval, intervalNumber, } = frequency || {};
    // const [frequencyType, setFrequencyType] = useState('specific');  

    const [specificDatesLocal, setSpecificDatesLocal] = useState(frequency?.specificDates || [new Date()]);
    // const [selected, setSelected] = useState(index)
    const handleListItemClick = (event, indexLocal) => {
        // setSelected(index); // Set the selected item index
        setIndexIn(indexLocal)
    };
    // const [startDate, setStartDate] = useState(new Date());
    // const [endDate, setEndDate] = useState(new Date());
    // const [repeatInterval, setRepeatInterval] = useState('days');
    // const [intervalNumber, setIntervalNumber] = useState(1);
    // const [frequency, setFrequency] = useState(null)

    const handleFrequencyTypeChange = (event) => {

        const { name, value } = event.target;

        frequency[name] = value
        var newSpecificDates = handleCalculateDates(frequency)
        setSpecificDatesLocal(newSpecificDates);
        frequency['specificDates'] = newSpecificDates
        setFrequency(frequency);





    };

    const handleAddSpecificDate = async () => {
        var name = 'specificDates'
        // Current date and time
        const currentDate = new Date();

        // Calculate the timestamp for 1 week in advance
        const oneWeekInAdvanceTimestamp = currentDate.getTime() + (7 * 24 * 60 * 60 * 1000);

        // Create a new Date object for 1 week in advance
        const oneWeekInAdvanceDate = new Date(oneWeekInAdvanceTimestamp);



        var newMailer = await axios.post(process.env.REACT_APP_DATABASE + "api/campaignMailer", {
            campaignId: emailDetails.id,
            sendDate: oneWeekInAdvanceDate
        }).catch((error) => {
            console.log(error)
        })
        // console.log(i)


        if (newMailer) {
            emailDetails.campaignMailers.push(newMailer.data)
            emailDetails?.frequency?.specificDates ?
                emailDetails.frequency.specificDates.push(oneWeekInAdvanceDate) :
                emailDetails.frequency = { frequencyType: 'specific', specificDates: [oneWeekInAdvanceDate] }
            var emailDetailsCopy = JSON.parse(JSON.stringify(emailDetails))
            setEmailDetails(emailDetailsCopy)

        }
        // setFrequency(prevDetails => ({
        //     ...prevDetails,
        //     [name]: [...specificDatesLocal, new Date()],
        // }));
        setSpecificDatesLocal([...specificDatesLocal, oneWeekInAdvanceDate]);
        // var emailDetailsCopy = JSON.parse(JSON.stringify(emailDetails))
        // emailDetailsCopy.frequency = emailDetails?.frequency?.specificDates ? emailDetails.frequency : { frequencyType: name, specificDates: [] }
        // emailDetailsCopy.frequency.specificDates.push(oneWeekInAdvanceDate)

    };

    const handleRemoveSpecificDate = (index) => {
        if (index < emailDetails.campaignMailers.length) {
            var qs = process.env.REACT_APP_DATABASE + `api/campaignMailer/${emailDetails.campaignMailers[index].id}`
            var response = axios.delete(qs, {

                // sendDate: value
            })
            setSpecificDatesLocal(specificDatesLocal.filter((_, i) => i !== index));
            frequency['specificDates'] = specificDatesLocal.filter((_, i) => i !== index)
            var copyEmailMailers = JSON.parse(JSON.stringify(emailDetails))
            copyEmailMailers.campaignMailers = copyEmailMailers.campaignMailers.filter((_, i) => i !== index)
            setEmailDetails(copyEmailMailers)
            setFrequency(frequency);



        }

    };

    const handleSpecificDateChange = (value, index) => {

        const newDates = specificDatesLocal.map((date, i) => (i === index ? value : date));
        var datesOK = true
        var today = new Date()
        newDates.forEach(element => {
            if (element < today) {
                datesOK = false
                alert("Dates must be in the future")

            }
        });

        if (saveEnabled && !datesOK) {
            setSaveEnabled(false)
        }
        else if (!saveEnabled && datesOK) {
            setSaveEnabled(true)
            return
        }
        var qs = process.env.REACT_APP_DATABASE + "api/updateCampaignMailerSendDate"
        var response = axios.post(qs, {
            campaignMailerId: emailDetails.campaignMailers[index].id,
            sendDate: value
        })
        frequency['specificDates'] = newDates
        setFrequency(frequency);
        setSpecificDatesLocal(newDates);

    };

    // useEffect(() => {
    //     
    //     var newFrequency = {
    //         frequencyType: frequencyType, specificDatesLocal: specificDatesLocal,
    //         startDate: startDate, endDate: endDate, repeatInterval: repeatInterval, intervalNumber: intervalNumber
    //     }

    //     setFrequency(newFrequency)
    //     handleEditChange({ target: { name: 'frequency', value: newFrequency }})
    // },[ specificDatesLocal, frequencyType, startDate, endDate, repeatInterval, intervalNumber])

    return (

        <Stack

            spacing={3}>
            <Typography sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>Select or Create Messages</Typography>


            {specificDatesLocal.map((date, index) => {

                return (
                    <>
                        {(emailDetails?.campaignMailers?.[index]) && emailDetails?.frequency?.specificDates?.[index] &&
                            < Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2,
                                    marginBottom: '8px', // Space below each Box
                                    padding: '10px', // Add padding around the content
                                    backgroundColor: indexIn === index ? 'rgba(0, 0, 200, 0.08)' : 'transparent',
                                    borderRadius: '10px',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 200, 0.04)',
                                    },
                                    cursor: 'pointer',
                                }}

                                onClick={(event) => {
                                    if (emailDetails?.campaignMailers?.[index]) { handleListItemClick(event, index) }
                                    else {
                                        alert("Updating database, try once more in a second")
                                    }
                                }}
                            >
                                <div>
                                    <div style={{ marginBottom: 6 }}>
                                        <Link> {(index + 1) + '. ' + (emailDetails.campaignMailers?.[index]?.subject || 'Untitled Mailer')}</Link>
                                    </div>


                                    <MyDatePicker date={date} handleSpecificDateChange={handleSpecificDateChange} index={index} />

                                    {/* 
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            label="Delivery Date"
                                            inputFormat="MM/dd/yyyy"
                                            value={new Date(date)}
                                            onChange={(newValue) => handleSpecificDateChange(newValue, index)}
                                            renderInput={(params) => <TextField {...params} />}
                                            disablePast
                                            minDate={new Date()}

                                        />
                                    </LocalizationProvider> */}

                                </div>
                                {index > 0 && (
                                    <Tooltip placement='top' title={'Remove Mailer'}>
                                        <IconButton onClick={() => handleRemoveSpecificDate(index)} size="large">
                                            <RemoveCircleOutlineIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Box >}
                    </>
                )
            })}

            <Button startIcon={<AddCircleOutlineIcon />} onClick={handleAddSpecificDate}>Create Message</Button>
        </Stack>


    );
};

function truncateString(str) {
    if (str) {
        if (str.length > 10) {
            return str.substring(0, 10) + "...";
        } else {
            return str;
        }
    }
    else {
        return 'Campaig...'
    }
}
export default EmailFrequencyForm;

const handleCalculateDates = (frequency) => {
    const startDate = new Date(frequency.startDate);
    const endDate = new Date(frequency.endDate);
    const intervalNumber = parseInt(frequency.intervalNumber, 10);
    let calculatedDates = [];

    // Ensure startDate and endDate are valid dates and intervalNumber is a positive number
    if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime()) && intervalNumber > 0) {
        let currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            calculatedDates.push(new Date(currentDate)); // Add the current date to the array

            // Increment currentDate based on the selected frequency and interval
            switch (frequency.repeatInterval) {
                case 'days':
                    currentDate.setDate(currentDate.getDate() + intervalNumber);
                    break;
                case 'weeks':
                    currentDate.setDate(currentDate.getDate() + 7 * intervalNumber);
                    break;
                case 'months':
                    currentDate.setMonth(currentDate.getMonth() + intervalNumber);
                    break;
                case 'years':
                    currentDate.setFullYear(currentDate.getFullYear() + intervalNumber);
                    break;
                default:
                    // Handle unexpected frequency value if necessary
                    break;
            }
        }
    }

    // Update the specificDates state with the calculated dates
    return (calculatedDates.map(date => date.toISOString().substring(0, 10))); // Format dates as 'YYYY-MM-DD' or as needed
};
