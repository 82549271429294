
import * as React from "react";
import { List, arrayMove } from "react-movable";
import DemoCarousel from "../Images/Carousel";

import { Folder as FolderOpenIcon, Delete as TrashIcon } from "@mui/icons-material"
import { mobileAndTabletCheck } from "../Search/Deck";

const addMe = async (dataIn, setDataIn, contactclientsId, contactId) => {
  var fetchLocationAdd =
    process.env.REACT_APP_DATABASE + "api/addClient?contactId=" +
    contactId;
  //test - console.log(fetchLocationAdd);
  const resultInfo = await fetch(fetchLocationAdd);
  var fetchLocationGet =
    process.env.REACT_APP_DATABASE + "api/getContactclients?id=";
  const searchResults = await fetch(
    fetchLocationGet + contactclientsId + "&contactId=" + contactId
  );
  const data = await searchResults.json();

  var myJson = data.contactclients; // search results come in an arra
  
  var sort = orderClients(myJson, setDataIn);
};

export default function ContactclientsClientEditTable({
  dataIn,
  setDataIn,
  contactclientsId,
  contactId,
  highestPermission,
}) {
  //test - console.log("recontactclients Edit Table");
  //test - console.log(dataIn);
  //setItems(mapArray(dataIn))

  const removeFromBothArrays = (
    index,
    newClientsIn,
    clientId,
    groupId,
    clientGroupsArrayIndex
  ) => {
    // contactId is not coming through
    var newDataIn = [...dataIn];

    if (newDataIn.length === 0) {
      // if you have just added the contact for some reason it will show up here with no visibility to the peopleIn parameter
      newDataIn = [...newClientsIn];
    }

    if (newDataIn.length > 0) {
      // database call
      ;
      deleteContactclientsClientRelationship(clientId, groupId, contactId);

      newDataIn[index].client_groups = arrayRemove(
        newDataIn[index].client_groups,
        clientGroupsArrayIndex
      );
      if (newDataIn[index].client_groups.length === 0) {
        newDataIn = arrayRemove(newDataIn, index);
      }
      // setPeopleIn(arrayRemove(newDataIn, index));
      setDataIn(newDataIn);
      // return arrayRemove(items, index);
      //   setItems(mapArray(newDataIn, removeFromBothArrays));
    }
  };

  // const [items, setItems] = React.useState(
  //   mapArray(clientsIn, removeFromBothArrays)
  // );

  const moveBothArrays = (formattedArray, oldIndex, newIndex) => {
    //setItems(arrayMove(formattedArray, oldIndex, newIndex))
    setDataIn(arrayMove(dataIn, oldIndex, newIndex));
    orderClients(arrayMove(dataIn, oldIndex, newIndex), setDataIn);
  };

  function mapArray(clientIn, setItems) {
    var myArray = [];
    var nfObject = new Intl.NumberFormat("en-US");
    var nfObjectRate = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    var nfObjectDate = new Intl.DateTimeFormat("en-US");
    // 
    clientIn.map((client, index) => {
      var AVHighestPermission = "View";
      myArray.push(
        <div key={client.id}>
          <div className="flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <div className="min-w-full ">
                    <div>
                      <table className="min-w-full">
                        <thead>
                          <tr className="text-left ">
                            <th className="w-60"></th>
                            <th className="w-20"></th>
                            <th className="w-20"></th>
                            <th className="w-20"></th>
                            <th className="w-40"></th>
                            <th className="w-20"></th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            className="text-center text-xs hover:cursor-pointer"
                            // onClick={async () => {
                            //   //const {Base64} = require('js-base64');
                            //   // var dataObjectBase64 = Base64.encode(client.id.toString());
                            //   window.open("client?id=" + client.id, mobileAndTabletCheck()?"_self": null);
                            //   //    await Alert('Alert text', 'Alert title');
                            // }}
                          >
                            <th className="w-80">
                              <div>
                                <div className="w-half">
                                  <DemoCarousel
                                    key={client.id}
                                    images={client.images}
                                  />
                                </div>
                                <div
                                  className="w-full flex items-center"
                                  onClick={async () => {
                                    //const {Base64} = require('js-base64');
                                    //var dataObjectBase64 = Base64.encode(client.id.toString());
                                    window.open("client?id=" + client.id, mobileAndTabletCheck()?"_self": null);
                                    //    await Alert('Alert text', 'Alert title');
                                  }}
                                >
                                  {/* <div className="px-6 ">
                                    <div className="font-medium text-gray-900">
                                      {client.Floor > 0
                                        ? "Floor " + client.Floor
                                        : "Please Add Floor"}
                                    </div>
                                    <div className="font-medium text-gray-500">
                                      {" "}
                                      {client.Suite !== "0" &&
                                      client.Suite
                                        ? "Suite " + client.Suite
                                        : "Please Add Suite"}
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            </th>
                        
                            <th className="w-20">
                              <div className="font-medium text-gray-900">
                                {client.ClientName ||
                                  "Contact agent or owner"}
                              </div>
                            </th>
                            <th className="w-20">
                              <div className="font-medium text-gray-900">
                                {new Date(client.createdAt).toLocaleDateString() ||
                                  "Contact agent or owner"}
                              </div>
                            </th>
                            <th className="w-40 z-50">
                              <div className="font-medium text-gray-900 ">
                                {client.client_groups.map(
                                  (client_group, groupArrayIndex) => {
                                    return (
                                      <div
                                        // GroupContactId={client_group.id}
                                        // GroupId={client_group.group.id}
                                        // GroupName={client_group.group.GroupName}
                                        className="w-full"
                                      >
                                        <div className="flex justify-between w-full hover:bg-red-700">
                                          <div className="flex flex-col w-30 py-2 text-xs text-left whitespace-pre-line self-center">
                                            {/* <img src={client_group.group.images[0].img}/> I'm not getting the images yet from DB  add later*/}
                                            <div>
                                              Group Name   : {client_group.group.GroupName} {"\n"}
                                              {/* {client_group.group.id}- */}
                                              Client Rights  : {client_group.groupPermission}
                                            </div>
                                            <div>
                                              Group Rights : 
                                              {client_group.group.group_contacts.map(
                                                (group_contact) => {
                                                  if (
                                                    AVHighestPermission ===
                                                      "View" &&
                                                    (group_contact.PermissionLevel ===
                                                      "Lead Edit" ||
                                                      group_contact.PermissionLevel ===
                                                        "Edit")
                                                  ) {
                                                    AVHighestPermission =
                                                      group_contact.PermissionLevel;
                                                  }
                                                  // ;
                                                  if (
                                                    group_contact.contact_id ===
                                                      contactId &&
                                                    group_contact.PermissionLevel !=
                                                      "Data"
                                                  ) {
                                                    return group_contact.PermissionLevel;
                                                  } else {
                                                    return "";
                                                  }
                                                }
                                              )}{" "} 
                                            </div>
                                          </div>
                                          <div className="px-2 py-2">
                                            {((AVHighestPermission === "Edit" ||
                                              highestPermission ===
                                                "Lead Edit") &&
                                              client_group.groupId === 1) ||
                                            AVHighestPermission === "Lead Edit" ||
                                            AVHighestPermission === "Edit" ||
                                            contactId === 1 ? (
                                              <button
                                                data-tip
                                                data-for="deleteTip"
                                                onClick={() => {
                                                  // ;
                                                  removeFromBothArrays(
                                                    index,
                                                    dataIn,
                                                    client_group.clientId,
                                                    client_group.groupId,
                                                    groupArrayIndex
                                                  );
                                                }}
                                                className="z-50 rounded-md border border-transparent bg-red-600 px-2 h-6 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                                              >
                                                <TrashIcon
                                                  className="h-4 w-4 text-white shadow-sm"
                                                  aria-hidden="true"
                                                />
                        
                                              </button>
                                            ) : (
                                             <> {client_group.groupId +" " + highestPermission}</>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </th>
                            <th className=" w-40">
                              <div>
                                {client.contacts
                                  .sort(
                                    (a, b) =>
                                      a.client_contact.sortOrder -
                                      b.client_contact.sortOrder
                                  )
                                  .map((contact) => (
                                    <div className="flex items-center py-1">
                                      <div className="h-10 w-10 rounded-full">
                                        <img
                                          className="h-10 w-10 flex-shrink-0 rounded-full object-cover"
                                          src={
                                            contact.images.length > 0
                                              ? contact.images.sort(
                                                  (a, b) =>
                                                    a.sortOrder - b.sortOrder
                                                )[0].img
                                              : "https://images.cubicsearch.com/UserLogo.png"
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <div className="w-30">
                                        <div className="font-medium text-gray-900">
                                          {contact.FirstName} {contact.LastName}
                                        </div>
                                        <div className="text-gray-500">
                                          {contact.office &&
                                          contact.office.company
                                            ? contact.office.company.Name
                                            : "Add Company"}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });

    return myArray;
  }

  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      ></link>
      <div className=" pt-8 pb-2 flex justify-end ">
        <button
          type="button"
          className="inline-flex  self -center items-center content-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
          onClick={() => addMe(dataIn, setDataIn, contactclientsId, contactId)}
        >
          <i className="fa fa-plus "></i>{" "}
          <div className="px-2">Add Client</div>
        </button>
      </div>
      {dataIn.length > 1 ? (
        <div className="bg-gray-200 border-solid border-2  border-gray-100 ">
          <div className="px-6"></div>
          <div className="px-6">
            <div className="flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden">
                    <div className="min-w-full ">
                      <div>
                        <table className="min-w-full">
                          <thead>
                            <tr className="text-left ">
                              <th className="w-60">Client Picture</th>
                              <th className="w-20">Client Name</th>
                              <th className="w-20">Client Created</th>
                              {/* <th className="w-20">Condition</th> */}
                              <th className="w-40">
                                Groups Who Can See Client
                              </th>
                              <th className="w-60">Contacts</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="mt-8 flex flex-col"></div>

      <List
        values={mapArray(dataIn)}
        onChange={({ oldIndex, newIndex }) =>
          moveBothArrays(mapArray(dataIn), oldIndex, newIndex)
        }
        renderList={({ children, props }) => <ul {...props}>{children}</ul>}
        renderItem={({ value, props, index }) => (
          <li {...props}>
            <div className="hover:bg-gray-50 ">
              <div className="flex w-full border">
                <div className="col-span-6 w-full">{value}</div>

                <div class="col-span-1 flex items-center justify-self-end px-4 ">
       
                  <div className="px-2">
                    <button
                      data-tip
                      data-for="openTip"
                      className=" rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                      onClick={async () => {
                        //test - console.log("I'm here waiting for the base64");

                        //    const {Base64} = require('js-base64');
                        var myArray = mapArray(dataIn);
                        //var dataObjectBase64 = Base64.encode(myArray[index].key.toString());
                        window.open("client?id=" + myArray[index].key, mobileAndTabletCheck()?"_self": null);
                      }}
                    >
                      <FolderOpenIcon
                        className="h-4 w-4 text-white shadow-sm"
                        aria-hidden="true"
                      />
                    </button>
        
                  </div>
                </div>
              </div>
            </div>
          </li>
        )}
      />
    </>
  );
}

export function arrayRemove(array, index) {
  array = array.slice();
  array.splice(index, 1);
  return array;
}

async function deleteContactclientsClientRelationship(
  clientId,
  groupId,
  contactId
) {
  var qs =
    "clientId=" + clientId + "&groupId=" + groupId + "&contactId=" + contactId;
  var resultInfo = await fetch(
    process.env.REACT_APP_DATABASE + "api/markDeleteClientGroup?" +
      qs
  );
  //test - console.log(
  //   process.env.REACT_APP_DATABASE + "api/markDeleteClientGroup?" +
  //     qs
  // );
  ;
  // window.location.href = window.location.href;
}

async function orderClients(clients, setDataIn) {
  //test - console.log("Update the order in the database");
  ////test - console.log(clients)

  // clients.map(async (client, index) => {
  //   //test - console.log(client.id);
  //   var qs = "id=" + client.id + "&sortOrder=" + index;
  //   var resultInfo = await fetch(
  //     process.env.REACT_APP_DATABASE + "api/sortContactclientsClients?" +
  //       qs
  //   );
    
  //   return resultInfo;
  // });
  setDataIn(clients);
}

// const removeFromDBandArray = (dataIn, setDataIn, index) => {
//   var newDataIn = [...dataIn];
//   //
//   deleteClient(newDataIn[index].id);
//   setDataIn(arrayRemove(newDataIn, index));

//   //return arrayRemove(formattedArray, index)
// };
