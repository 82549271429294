
import React, { useState, useEffect, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, CircularProgress, Typography, ListSubheader, Button, Avatar, ListItemAvatar, TextField, List, ListItem, ListItemText, Checkbox } from '@mui/material';
import axios from 'axios';
import { debounce } from 'lodash';
import SearchDialog from './FindMore/SearchDialog';
import NewContactDialog from '../../Groups/NewContactDialog';


function ContactsDialog({ open, onClose, contactId, mailListId, defaultText }) {
    const [searchTerm, setSearchTerm] = useState(defaultText);
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [showNewContactDialog, setShowNewContactDialog] = useState(false)
    const [showSearchDialog, setShowSearchDialog] = useState(false)

    const localCallBackNewContact = (newContact) => {
        // alert(JSON.stringify(newContact))
        if (contacts?.length > 0) {
            setContacts([...contacts, newContact])
            setSelectedContacts([...selectedContacts, newContact])
        }
        else {
            setContacts([newContact])
            setSelectedContacts([newContact])
        }

    }
    useEffect(() => {
        setSearchTerm(defaultText)
    }, [open]);
    // Logic to handle selection changes
    const handleSelectContact = (id) => {
        const selectedIndex = selectedContacts.indexOf(id);
        let newSelectedContacts = [];

        if (selectedIndex === -1) {
            newSelectedContacts = newSelectedContacts.concat(selectedContacts, id);
        } else if (selectedIndex === 0) {
            newSelectedContacts = newSelectedContacts.concat(selectedContacts.slice(1));
        } else if (selectedIndex === selectedContacts.length - 1) {
            newSelectedContacts = newSelectedContacts.concat(selectedContacts.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedContacts = newSelectedContacts.concat(
                selectedContacts.slice(0, selectedIndex),
                selectedContacts.slice(selectedIndex + 1),
            );
        }

        setSelectedContacts(newSelectedContacts);
    };



    // Logic to handle select all contacts
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedContacts = contacts.map((n) => n.id);
            setSelectedContacts(newSelectedContacts);
            return;
        }
        setSelectedContacts([]);
    };

    const handleAddContacts = async () => {
        // Implement logic to add selected contacts to your list
        console.log('Selected Contacts to Add:', selectedContacts);
        var theContactList = filterSelectedContacts(contacts, selectedContacts)

        const response = await axios.post(process.env.REACT_APP_DATABASE + `api/importContactsToBrevoAPI`, {

            contactId: contactId,
            selectedContacts: theContactList,
            mailListId: mailListId

        });
        onClose()
        // After adding, you might want to clear the selection or close the dialog
    };

 

    // // Check if all contacts are selected
    // const isAllSelected = contacts?.length > 0 && selectedContacts?.length === contacts?.length;

    // Use useCallback to memoize the debounced function
    const debouncedSearch = useCallback(debounce(async (query) => {
        if (!query) {
            setContacts([]);
            setLoading(false);
            return;
        }
        setLoading(true);

        // Initialize a new cancel token for this request
        const source = axios.CancelToken.source();
        try {
            const response = await axios.get(process.env.REACT_APP_DATABASE + `api/getContacts`, {
                cancelToken: source.token,
                params: {
                    contactId: contactId,
                    query: query,
                    limit: 100
                } // Use the queryString here
            });

            setContacts(response.data.contact); // Adjust according to your API response
        } catch (error) {
            if (!axios.isCancel(error)) {
                console.error('Search API request failed: ', error);
                setContacts([]);
            }
        } finally {
            setLoading(false);
        }
    }, 750), []); // Empty dependency array ensures this is created once per component lifecycle

    useEffect(() => {
        if (searchTerm) {
            debouncedSearch(searchTerm);
        } else {
            setContacts([]); // Clear results if search term is empty
        }
        // Cleanup function to cancel the request if the component unmounts
        return () => debouncedSearch.cancel();
    }, [searchTerm, debouncedSearch]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filterSelectedContacts = (contacts, selectedContactIds) => {
        return contacts.filter(contact => selectedContactIds.includes(contact.id));
    };

    const createNewContactMyGroup = async (newContact) => {
        var newContactResult = await axios.post(process.env.REACT_APP_DATABASE + "api/createContactMyGroup", { contactId: contactId, newContact: newContact })

        localCallBackNewContact(newContactResult.data)

    }

    return (
        <>
            {/* <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
                <DialogTitle>Search For Recipients To Add</DialogTitle>
                <DialogContent> */}
                    <List
                        subheader={
                            <ListSubheader component="div" style={{
                                position: 'sticky', top: 0, zIndex: 1,
                                backgroundColor: '#ffffff', // Adjust this color as needed
                                borderBottom: '1px solid #e0e0e0', // Optional: adds a divider effect
                            }}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    label="Search Recipient Email or Name"
                                    variant="outlined"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    autoFocus
                                />
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: '10px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Checkbox
                                            // Check if all selected logic here
                                            onChange={handleSelectAllClick}
                                        // Other checkbox props
                                        />
                                        Select All
                                    </div>
                                    <Button
                                        disabled={!contacts || contacts?.length === 0}
                                        variant="contained" color="primary" onClick={handleAddContacts}>
                                        Add Contacts
                                    </Button>
                                    {/* <Button

                                        variant="contained" color="primary" onClick={handleMoreContacts}>
                                        Find More...
                                    </Button> */}
                                </div>
                            </ListSubheader>
                        }
                    >
                        {loading ? (
                            <CircularProgress />
                        ) : contacts?.length > 0 ? (
                            contacts.map((contact) => (
                              
                                <MyListItem contact={contact} selectedContacts={selectedContacts} handleSelectContact={handleSelectContact} />
                            ))
                        ) : (
                            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                <Typography variant="body1">No contacts found.</Typography>
                                        {searchTerm?.length > 0 && <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => setShowNewContactDialog(true)}
                                            style={{ marginTop: '10px' }}
                                        >
                                            Create New Contact '{searchTerm}'
                                        </Button>}
                            </div>
                        )}
                    </List>
                {/* </DialogContent>
            </Dialog> */}
            <SearchDialog
                open={showSearchDialog}
                handleClose={() => { setShowSearchDialog(false) }}
                setContacts={setContacts}
                contactId={contactId}
                mailListId={mailListId}
            />
            <NewContactDialog open={showNewContactDialog} onClose={() => { setShowNewContactDialog(false) }}
                createContact={createNewContactMyGroup} NameIn={searchTerm} />
        </>
    );

}




function MyListItem({ contact, selectedContacts, handleSelectContact }) {
    return (
        <ListItem
            key={contact.id}
            button
            onClick={() => { window.open("/contact?id=" + contact.id); }}
        >
            <Checkbox
                checked={selectedContacts.includes(contact.id)}
                onClick={(event) => event.stopPropagation()} // Use onClick to stop propagation
                onChange={(event) => {
                    event.stopPropagation(); // Continue to use stopPropagation in onChange
                    handleSelectContact(contact.id);
                }}
            />
            <ListItemAvatar>
                <Avatar style={{
                    marginRight: 2,
                    borderRadius: '10%',
                    objectFit: 'contain'  // This is actually applied to the img element inside Avatar
                }}
                    imgProps={{
                        style: {
                            objectFit: 'contain' // Ensures the image fits within the bounds of the Avatar without stretching
                        }
                    }} src={contact.images?.[0]?.img || contact?.office?.company?.images?.[0]?.img} />
            </ListItemAvatar>
            <div>
                <ListItemText
                    primary={`${contact.FirstName} ${contact.LastName}`}
                    secondary={`${contact.Title || contact?.office?.company?.Name}`}
                />
                <ListItemText secondary={`${contact.Email}`} />
            </div>
        </ListItem>
    );
}


export default ContactsDialog;


