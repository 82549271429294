import React, { useRef, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import axios from 'axios';

import OfficeCombo from './OfficeCombo';
import CompanyCombo from './CompanyCombo';
import ContactCompanyNew from './ContactCompanyNew';
import ContactOfficeNew from './ContactOfficeNew';
import { AutocompleteAsyncCompany } from '../Campaigns/AutocompleteAsync'
import OfficeDialog from '../Offices/OfficeDialog';




export default function ContactCompanyModal({
  open,
  setOpen,
  office,
  setOffice,
  contactId,
  setContact,
}) {

  const [buttonText, setButtonText] = useState("Select Company");
  const [selectedCompany, setSelectedCompany] = useState({ ...office?.company });
  const [selectedOffice, setSelectedOffice] = useState(office);

  const [createNewOffice, setCreateNewOffice] = useState(false);
  const [showOfficeEdit, setShowOfficeEdit] = useState(false)

  // const [companies, setCompanies] = useState();
  const [offices, setOffices] = useState([]);



  const fetchOffices = async (id) => {
    var companyOffices = await axios.get(process.env.REACT_APP_DATABASE + "api/getCompanyOffices", {
      params: {
        company_id: id
      }
    }).catch((error) => { console.log(error) })

    if (companyOffices) {

      setOffices(companyOffices.data)
    }

  }

  const createOffice = async (office) => {

    
    if (office && !office?.company_id) {
      office.company_id = selectedCompany.id
    }
    var newOffice = await axios.post(process.env.REACT_APP_DATABASE + "api/createOfficeSimple", office
    ).catch((error) => { console.log(error) })

    if (newOffice?.data) {

      return (newOffice.data)
    }
    else {
      return null
    }
  }

  useEffect(() => {
    if (selectedCompany?.id) {
      fetchOffices(selectedCompany?.id)

    }
    else {

      setOffices([])
    }


  }, [selectedCompany])


  return (<>
    <Dialog
      open={open}
      onClose={() => setOpen(false)} aria-labelledby="form-dialog-title"
      sx={{
        '& .MuiDialog-paper': {
          width: '100%', // Full width on smaller screens
          maxWidth: '800px', // Maximum width on larger screens
        }
      }}>
      <DialogTitle id="form-dialog-title">
        <Box display="flex" alignItems="center">

          <Box >Company and Office</Box>

        </Box>
      </DialogTitle>
      <DialogContent dividers>

        <AutocompleteAsyncCompany
          selectedcompany={selectedCompany}
          handleCompanyChange={(newCompany) => {
            setSelectedCompany(newCompany)
            setSelectedOffice(null);
          }

          } contactId={contactId} />


        <div style={{ marginTop: 15 }}>
          <OfficeCombo
            createOffice={createOffice}
            offices={offices}
            selectedOffice={selectedOffice}
            setSelectedOffice={setSelectedOffice}
            // selectedCompany={selectedCompany}
            setCreateNewOffice={setCreateNewOffice}
            setButtonText={setButtonText}

          />

        </div>



      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={() => setShowOfficeEdit(true)} >
          Edit Office Info
        </Button>
        <Button disabled={!selectedOffice?.id || !contactId} variant='contained' onClick={() => handleSave()} color="primary">
          Save Office to Contact
        </Button>
      </DialogActions>
    </Dialog>

    <OfficeDialog open={showOfficeEdit} onClose={() => { setShowOfficeEdit(false) }} initialData={selectedOffice} onSave={async (officeIn) => {

      if (selectedOffice) {
        var newOffice = await axios.post(process.env.REACT_APP_DATABASE + "api/upsertOfficeGetLL", officeIn).catch((error) => {
          console.log(error)
        })
        if (newOffice?.data?.office) {
          setSelectedOffice(newOffice.data.office)
        }
        else {
          alert("problem with saving office data")
        }
      }

    }} />
  </>
  );

  function handleSave() {
    updateContactOffice(
      selectedOffice.id,
      contactId,
      setContact
    );
    setOpen(false);
  }
}

async function newCompany(
  company,
  office,
  setSelectedCompany,
  setSelectedOffice,
  contactId,
  setContact
) {
  var fetchLocation =
    process.env.REACT_APP_DATABASE + "api/createCompany?Name=" +
    company.Name +
    "&Website=" +
    company.Website +
    "&Notes=" +
    company.Notes;
  var result = await fetch(fetchLocation);

  var myJson = await result.json(); // search results come in an array, and the element is also an array

  setSelectedCompany({ id: myJson.company.id, name: myJson.company.Name });
  newOffice(
    office,
    setSelectedOffice,
    myJson.company.id,
    contactId,
    setContact
  );
}

async function newOffice(
  office,
  setSelectedOffice,
  selectedCompanyId,
  contactId,
  setContact
) {
  var fetchLocation =
    process.env.REACT_APP_DATABASE + "api/createOffice?companyId=" +
    selectedCompanyId +
    "&&" +
    Object.keys(office)
      .map((key) => key + "=" + office[key])
      .join("&");
  var result = await fetch(fetchLocation);
  var myJson = await result.json(); // search results come in an array, and the element is also an array

  setSelectedOffice({
    id: myJson.office.id,
    name:
      myJson.office.City +
      ", " +
      myJson.office.State +
      " | " +
      myJson.office.StreetNumber +
      " " +
      myJson.office.StreetName,
  });
  updateContactOffice(myJson.office.id, contactId, setContact);
}
async function updateContactOffice(officeId, contactId, setContact) {
  var fetchLocation =
    process.env.REACT_APP_DATABASE + "api/updateContactOffice?id=" +
    contactId +
    "&&contactId=" +
    contactId +
    "&&officeId=" +
    officeId;
  const resultInfo = await fetch(fetchLocation);
  const data = await resultInfo.json();
  var myJson = data.contact; // search results come in an array, and the element is also an array

  setContact(myJson);
}
