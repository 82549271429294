import React, { useState, useEffect } from 'react';
import { Button, Stack, Box,IconButton, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { AutocompleteAsync } from './AutocompleteAsync';

const SenderNamesForm = ({ emailDetails, handleEditSendersChange, contactId, index }) => {

    
    const [senders, setSenders] = useState(emailDetails?.campaignMailers[index]?.senderInfo || [{ name: '', email: '', avatar: '' }]);

    // useEffect to initialize or update senders when emailDetails or index changes
    useEffect(() => {
        const newSenders = emailDetails?.campaignMailers[index]?.senderInfo || [{ name: '', email: '', avatar: '' }];
        setSenders(newSenders);
    }, [emailDetails, index]); // Watching for changes in emailDetails and index

    // This useEffect synchronizes changes in senders back to the parent component's state.
    useEffect(() => {
        const currentSenderInfo = emailDetails?.campaignMailers[index]?.senderInfo || [{ name: '', email: '', avatar: '' }];
        // Compare the current senderInfo with the updated senders state
        // This comparison is naive and only works if the order and contents are exactly the same
        // For more complex data, consider using a deep comparison function
        if (JSON.stringify(currentSenderInfo) !== JSON.stringify(senders)) {
            handleEditSendersChange({ target: { name: 'senderInfo', value: senders } });
        }
    }, [senders]);
    const handleSenderChange = (index, newValue) => {
        const updatedSenders = senders.map((sender, i) =>
            i === index ? { ...sender, name: newValue?.label, email: newValue?.email, avatar: newValue?.avatar } : sender
        );
        setSenders(updatedSenders);
    };

    const handleAddSender = () => {
        setSenders([...senders, { name: '', email: '', avatar: '' }]);
    };

    const handleRemoveSender = (index) => {
        setSenders(senders.filter((_, i) => i !== index));
    };



    return (
        <Stack spacing={2}>
            <Typography>Sender Names</Typography>
            {senders.map((sender, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <AutocompleteAsync
                        sender={sender}
                        handleSenderChange={handleSenderChange}
                        index={index}
                        contactId={contactId}
                    />
                    {index > 0 && (
                        <IconButton onClick={() => handleRemoveSender(index)}>
                            <RemoveCircleOutlineIcon />
                        </IconButton>
                    )}
                </Box>
            ))}
            <Button startIcon={<AddCircleOutlineIcon />} onClick={handleAddSender}>Add Sender</Button>
        </Stack>
    );
};

export default SenderNamesForm;
