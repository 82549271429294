import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const DeleteContactDialog = ({ open, onClose, onRemoveEverywhere, onMakeExclusionList, deleteRecipients ,MailListName, setSearchTerm, exclusion }) => {
    const handleClose = () => {
        onClose();
    };

    const handleRemoveEverywhere = () => {
        
      
        if (deleteRecipients?.length > 0) {
            onRemoveEverywhere();
            setSearchTerm('')
        }
        onClose();
    };

    const handleMakeExclusionList = () => {
        onMakeExclusionList(deleteRecipients);
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Confirmation</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Delete  contact from {MailListName?"'"+ MailListName+"'": ""} recipient list everywhere 
                    {!exclusion && ` or make a special
                    exclusion list for this campaign?`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleRemoveEverywhere} color="secondary">
                    Remove from {MailListName?.MailListName} Mailing List Everywhere
                </Button>
              { !exclusion && <Button onClick={handleMakeExclusionList} color="primary">
                    Make Exclusion List
                </Button>}
                <Button onClick={handleClose} color="inherit">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteContactDialog;
