import React, { useState } from "react";
import { Grid,  Box, Button, Card, CardContent, Typography, Dialog } from "@mui/material";
import Header from "../Common/Header";
import ContactEdit from "./ContactEdit";
import ContactView from "./ContactView";
import ContactCompanyModal from "./ContactCompanyModal";
import { markDeleteImage, orderImages } from "../Availables/AvailableDetail";

import ContactCompanyList from "./ContactCompanyList";
import Search from "../../pages/SearchResults";
import SearchForm from "../Campaigns/MailLists/FindMore/SearchForm";
import ImageUploadGallery from "../Common/Uploader/ImageUploadGallery";
import axios from 'axios'



export default function ContactDetail({ contactData, setData, user, fetchContact, setLoading }) {
  const [editMode, setEditMode] = useState(false);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [showSearch, setShowSearch] = useState(false)

  const initialPics = contactData?.images?.map(image => ({
    source: image.img,
    "image url": image.img,
    name: image.id,
  })) || [];

  const title = `${contactData?.FirstName || 'New User'} ${contactData?.LastName || ''}`.trim();
  const highestPermission = (contactData?.user && contactData?.id === user?.contact?.id) ? "Lead Edit" : "Edit";
  const updateContactData = (formData) => {

    setData(prevState => {
      // Create a copy of the current state to modify
      let updatedData = { ...prevState };

      // Iterate over formData keys and update the matching keys in contactData
      Object.keys(formData).forEach(key => {

        if (key in prevState) {  // Check if the key exists in the current state
          updatedData[key] = formData[key];
        }
      });

      // Return the updated data to set the new state
      return updatedData;
    });
  };

  var theDomain = contactData?.Domain

  function getDomainFromEmail(email) {
    const domain = email.split('@')[1]; // Split the email by '@' and take the second part.
    return domain ? domain.split('/')[0] : ''; // Further split by '/' and take the first part, if available.
  }

  function getDomainFromURL(url) {
    const withoutProtocol = url.replace(/(^\w+:|^)\/\//, ''); // Remove any protocol
    const withoutWWW = withoutProtocol.replace('www.', ''); // Remove 'www.'
    return withoutWWW.split('/')[0]; // Split by '/' and take the first part.
  }

  if (!theDomain && contactData?.Email) {
    theDomain = getDomainFromEmail(contactData?.Email)
  }
  else if (!theDomain && contactData?.office?.company?.Website) {
    theDomain = getDomainFromURL(contactData?.office?.company?.Website)
  }

  
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Header icon="contact_edit" placeholderLocation={title} user={user} highestPermission={highestPermission} />

      <Grid container spacing={2} justifyContent="center" sx={{ p: 2 }}>

        <><Grid><Card >
          <CardContent>
         
            {editMode ? (<div style={{marginLeft: 20, marginBottom: 20}}>
              <ImageUploadGallery
                initialImages={initialPics}
                onDeleted={(image) =>
                  markDeleteImage(image, contactData, setData)
                }
                onSortEnd={async (images) => {
                  
                  await orderImages(
                    images,
                    contactData,
                    setData
                  )}
                }
                action={process.env.REACT_APP_IMAGE}
                onSuccess={(image) => {
                  addContactImage(image)
                }}
              />

            </div> ) : (
              <img
                  src={contactData?.images?.[0]?.img || "https://images.cubicsearch.com/UserLogo.png"} // Adding a placeholder for any missing images

                style={{
                  maxHeight: '100%',
                  maxWidth: '200px',
                  objectFit: 'contain' // Ensures the image fits without being cropped
                }}
              />
            )}
            {!editMode ? <div>
              <ContactView contactDataIn={contactData} />
            </div> :
              <>
                <div style={{ marginLeft: 20 }}>          {editMode &&
                  <Button variant="outlined" onClick={() => { setShowSearch(true) }}>
                    Populate Contact Info From Web
                  </Button>
                }</div>
                <div style={{ maxWidth: '600px' }}>
                  <ContactEdit contactDataIn={contactData} setContactDataIn={updateContactData} setEditMode={setEditMode} />
                </div></>}
            <div>
              {!editMode && highestPermission.includes("Edit") && (
                <Button onClick={() => setEditMode(!editMode)}>
                  {editMode ? "Save" : "Edit Contact"}
                </Button>
              )}
            </div>



          </CardContent>
        </Card>

        </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <div>
              <ContactCompanyList
                officeIn={contactData?.office}
                setShowCompanyModal={setShowCompanyModal}
                showCompanyModal={showCompanyModal}
                highestPermission={highestPermission}
                guest={user?.guest}
              />
            </div>

            {!editMode && (
              <Typography
                sx={{
                  maxWidth: 600,
                  whiteSpace: 'pre-line',
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word',
                  fontFamily: '"Roboto", "Arial", sans-serif' // Explicitly setting a sans-serif font
                }}
              >
                {contactData.Notes}
              </Typography>
            )}




            {editMode &&
              <Dialog open={showSearch} onClose={() => {
                setShowSearch(false)
              }}>
                <div style={{ padding: 40 }}>
                  <SearchForm searchMethod={'name'}
                    setSearchMethod={() => { console.log('setting search method') }}
                    // setContacts={async () => {
                    //   setLoading(true)
                    //   await fetchContact()
                    //   setLoading(false)
                    //   setEditMode(false)
                    // }}
                    // handleClose={() => {
                    //   setShowSearch(false)
                    // }}
                    nameIn={contactData?.FirstName + " " + contactData?.LastName}
                    domainIn={theDomain}
                    companyIn={contactData?.office?.company}
                    updateMe={true}
                    office={contactData?.office}
                    contactIn={contactData}
                    contactId={user.contact.id}
                    // no mailLIstId since this is coming from a contact and not a mailer


                  />
                </div>
              </Dialog>
            }

          </Grid></>

      </Grid>

      <Grid item xs={12} md={8} sx={{ mt: 2, padding: 10 }}>




      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Search user={user} contactPageId={contactData?.id} hideHeader={true} reloadOnMove={false} />
      </Grid>
      <div>
        <ContactCompanyModal
          open={showCompanyModal}
          setOpen={setShowCompanyModal}
          office={contactData.office ? contactData.office : null}
          contactId={contactData.id}
          setContact={setData}
        />
      </div>
    </Box>
  );

  async function addContactImage(image) {
    const updatedImages = [...contactData.images, {
      source: image.message,
      img: image.message,
      url: image.message,
      name: image.name,
      id: image.name
    }];

    var result = await axios.get(process.env.REACT_APP_DATABASE + "api/addContactImage", {
      params: {
        img: image.message,
        myId: user.contact.id,
        contactId: contactData.id,
        sortOrder: updatedImages.length
    }})
    setData({ ...contactData, images: updatedImages });
  }
}


