import React from 'react';
import {
    Typography, Box, Paper, TextField,
    Button,
    Divider,
    Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { useState, useEffect } from 'react';
import EmailFrequencyForm from './EmailFrequencyForm';

import FrequencyDetails from './FrequencyDetails';

import axios from 'axios'
import CampaignToggle from './CampaignToggle';
import CampaignGroupsDialog from './CampaignGroupsDialog';
import CampaignCard from './CampaignCard';

const CampaignDisplay = ({ campaignName, emailDetails, setEmailDetails, contactId, contact, edit, setEdit, handleSaveClick, setIndex, index }) => {
    // const [frequency, setFrequency] = useState(emailDetails.frequency);
    const [showPermission, setShowPermission] = useState(false)



    const handleEditClick = () => {
        setEdit(true);
    };



    useEffect(() => {

        const checkCampaigns = async () => {
            var activeCampaigns = 0

            if (emailDetails?.campaignMailers?.length > 0) {
                activeCampaigns = emailDetails?.campaignMailers?.filter(item => parseInt(item.active) === 1).length;
            }
            var numberOfMailersDifference = (emailDetails.frequency?.specificDates?.length || 0) - activeCampaigns

            if (numberOfMailersDifference > 0) // this means that regardless if the dates are correct there are either too many campaignMailers or too little, and we need to add or deacitve them
            { // we have too many dates and not enough campaignMailers
                if (numberOfMailersDifference > 12) {
                    alert("Only 12 mail dates can be added at a given time")
                } else {
                    for (var i = 0; i < numberOfMailersDifference; i++) {
                        console.log("aboug to loop at " + i)
                        // Current date and time
                        const currentDate = new Date();

                        // Calculate the timestamp for 1 week in advance
                        const oneWeekInAdvanceTimestamp = currentDate.getTime() + (7 * 24 * 60 * 60 * 1000);

                        // Create a new Date object for 1 week in advance
                        const oneWeekInAdvanceDate = new Date(oneWeekInAdvanceTimestamp);




                    }
                    // alert("resetting")
                    // setEmailDetails(emailDetails)

                }

            }
            if (emailDetails.frequency) {
                // alert(JSON.stringify(emailDetails.frequency))
                // setFrequency(emailDetails.frequency)
            }
        }

        checkCampaigns()
    }, [emailDetails])

    

  

    if (!edit ) {
        return (
            <>
                <Paper elevation={3} sx={{ padding: 2, margin: 2 }}>
                    {/* <Typography variant="h5" component="h3">
                        Campaign
                    </Typography>
                    <Typography variant="subtitle" sx={{ fontSize: 12, color: 'darkgray' }}>
                        Campaigns contain multiple messages
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    <Typography variant="h4"  gutterBottom>
                        {campaignName}
                    </Typography>
                    <Typography variant="subtitle" sx={{ fontSize: 12, color: 'darkgray' }} >
                        Created: {new Date(emailDetails.createdAt).toLocaleString()}
                    </Typography>
                    <div>
                    <Typography variant="subtitle" sx={{ fontSize: 12, color: 'darkgray' }} >
                        Updated: {new Date(emailDetails.updatedAt).toLocaleString()}
                    </Typography>
                    </div> */}

                        <div>
                            <CampaignCard campaign={emailDetails} preview={true} edit={false} contact={contact}/>
                        </div>
               
                    <FrequencyDetails frequency={emailDetails.frequency} emailDetails={emailDetails} setIndexIn={setIndex} indexIn={index} />

                    <Box sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        backgroundColor: 'background.paper', // Use theme background color
                        boxShadow: '0 -2px 4px rgba(0,0,0,0.2)', // Optional shadow for elevation effect
                        py: 2, // Padding Y for vertical padding, adjust as needed
                        display: 'flex',
                        flexDirection: 'column', // Stack vertically
                        alignItems: 'center',
                        gap: 1, // Space between items, adjust as needed
                        zIndex: 1000
                    }}>
                        <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%', gap: 2, marginRight: 2 }}>

                            <CampaignToggle
                                campaign={emailDetails} setCampaign={setEmailDetails} edit={false} />
                            <Button
                                disabled={!emailDetails?.subscriptionId && (contactId !== 998) } 

                                startIcon={<EditIcon />}
                                onClick={handleEditClick}
                                variant="contained"

                            >
                                Edit
                            </Button>
                            <Button disabled={!emailDetails?.subscriptionId && (contactId !== 998)
                                
                            } variant='outlined' onClick={() => { setShowPermission(true) }}> Manage Group Access</Button>
                        </Box>

                    </Box>
                </Paper>
                <CampaignGroupsDialog campaignId={emailDetails.id} open={showPermission}
                    onClose={() => { setShowPermission(false) }} campaignName={campaignName} contactId={contactId} />

            </>
        );
    }
    else {
        return (<><CampaignDisplayEdit emailDetails={emailDetails} setEmailDetails={setEmailDetails}
            handleSaveClick={handleSaveClick}
            contactId={contactId}
            contact={contact}
            edit={edit}
            campaignName={campaignName} showPermission={showPermission}
            setShowPermission={setShowPermission}
            setIndex={setIndex}
            index={index}

        />

        </>)
    }
};

export default CampaignDisplay;


export const CampaignDisplayEdit = ({ emailDetails, setEmailDetails, handleSaveClick, contactId, contact, edit, campaignName, showPermission, setShowPermission, setIndex, index }) => {


    const [saveEnabled, setSaveEnabled] = useState(true)

    const handleEditChange = (e) => {
        const { name, value } = e.target;

        setEmailDetails(prevDetails => ({
            ...prevDetails,
            [name]: value,
        }));
    };







    return (<>
        <Box sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            backgroundColor: 'background.paper', // Use theme background color
            boxShadow: '0 -2px 4px rgba(0,0,0,0.2)', // Optional shadow for elevation effect
            py: 2, // Padding Y for vertical padding, adjust as needed
            display: 'flex',
            flexDirection: 'column', // Stack vertically
            alignItems: 'center',
            gap: 1, // Space between items, adjust as needed
            zIndex: 1000
        }}>
            {/* Grouped Components */}
            <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%', gap: 2, marginRight: 2 }}>
                <CampaignToggle disabled={!saveEnabled} campaign={emailDetails} setCampaign={setEmailDetails} edit={saveEnabled} />
                <Button
                    disabled={!saveEnabled || (!emailDetails?.subscriptionId && (contactId !== 998))}
                    startIcon={<SaveIcon />}
                    onClick={() => handleSaveClick(emailDetails)}
                    variant="contained"
                >
                    Save
                </Button>
                <Button disabled={!emailDetails?.subscriptionId && (contactId !== 998)} variant='outlined' onClick={() => setShowPermission(true)}> Manage Group Access</Button>

            </Box>
        </Box>
        <Paper elevation={3} sx={{ padding: 2, margin: 2 }}>
            <Typography variant="h5" component="h3">
                Campaign Edit
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <TextField
                fullWidth
                label="Campaign Name"
                name="campaignName"
                value={emailDetails.campaignName}
                onChange={handleEditChange}
                margin="normal"
            />
            <Tooltip arrow placement='top' title={'Edit Contents'}>
                <div style={{ width: '100%', display: 'flex', justifyContent:'center' }} >
                    <CampaignCard campaign={emailDetails} preview={true} edit={true}  contact={contact} setEmailDetails={setEmailDetails} />
                </div>
            </Tooltip>

            <Paper sx={{ padding: 3, marginTop: 3 }}>

                <EmailFrequencyForm frequency={emailDetails.frequency || { frequencyType: 'repeating' }}
                    handleEditChange={handleEditChange}
                    emailDetails={emailDetails}
                    setFrequency={(value) => {

                        setEmailDetails(prevDetails => ({
                            ...prevDetails,
                            frequency: value,
                        }))
                    }}
                    indexIn={index}
                    setIndexIn={setIndex}
                    setEmailDetails={setEmailDetails}
                    saveEnabled={saveEnabled}
                    setSaveEnabled={setSaveEnabled}
                />
            </Paper>


        </Paper>
        <CampaignGroupsDialog campaignId={emailDetails.id} open={showPermission}
            onClose={() => { setShowPermission(false) }} campaignName={campaignName} contactId={contactId} />
    </>
    );
};


