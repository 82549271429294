import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
    Grid, CircularProgress, Typography, Paper, Container, Skeleton
} from '@mui/material';
import Header from '../Common/Header';
import EntityTypeSelectionDialog from './EntityTypeSelectionDialog';
import CampaignCard from './CampaignCard';

// CampaignsGrid Component
const CampaignsGrid = ({ campaigns, navigate, contact }) => {
    // Filter campaigns by type
    const contactCampaigns = campaigns.filter(campaign => campaign.contactIds && campaign.contactIds.length > 0);
    const buildingCampaigns = campaigns.filter(campaign => campaign.buildingIds && campaign.buildingIds.length > 0);
    const availCampaigns = campaigns.filter(campaign => campaign.availIds && campaign.availIds.length > 0);
    const companyCampaigns = campaigns.filter(campaign => campaign.companyIds && campaign.companyIds.length > 0);

    
    return (
        <Container maxWidth="lg">
            <Grid container spacing={2} sx={{ marginTop: 2, paddingBottom: 10 }}>
                {contactCampaigns.length > 0 && (
                    <Grid item xs={12}>
                        <Paper sx={{ padding: 3, maxWidth: '100%' }}>
                            <Typography variant="h6">Contact Campaigns</Typography>
                            <Typography variant='caption'>Marketing for a Contact</Typography>
                            <Grid container spacing={2}>
                                {contactCampaigns.map(campaign => (
                                    <Grid item xs={12} sm={6} md={4} key={campaign.id}>
                                        <CampaignCard campaign={campaign} navigate={navigate} contact={contact} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Paper>
                    </Grid>
                )}

                {buildingCampaigns.length > 0 && (
                    <Grid item xs={12}>
                        <Paper sx={{ padding: 3, maxWidth: '100%' }}>
                            <Typography variant="h6">Building Campaigns</Typography>
                            <Typography variant='caption'>Marketing for a Building or Set of Buildings</Typography>
                            <Grid container spacing={2}>
                                {buildingCampaigns.map(campaign => (
                                    <Grid item xs={12} sm={6} md={4} key={campaign.id}>
                                        <CampaignCard campaign={campaign} navigate={navigate} contact={contact} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Paper>
                    </Grid>
                )}

                {availCampaigns.length > 0 && (
                    <Grid item xs={12}>
                        <Paper sx={{ padding: 3, maxWidth: '100%' }}>
                            <Typography variant="h6">Availability Campaigns</Typography>
                            <Typography variant='caption'>Marketing for a Space or Set of Spaces</Typography>
                            <Grid container spacing={2}>
                                {availCampaigns.map(campaign => (
                                    <Grid item xs={12} sm={6} md={4} key={campaign.id}>
                                        <CampaignCard campaign={campaign} navigate={navigate} contact={contact} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Paper>
                    </Grid>
                )}

                {companyCampaigns.length > 0 && (
                    <Grid item xs={12}>
                        <Paper sx={{ padding: 3, maxWidth: '100%' }}>
                            <Typography variant="h6">Company Campaigns</Typography>
                            <Typography variant='caption'>Marketing for a Company</Typography>
                            <Grid container spacing={2}>
                                {companyCampaigns.map(campaign => (
                                    <Grid item xs={12} sm={6} md={4} key={campaign.id}>
                                        <CampaignCard campaign={campaign} navigate={navigate} contact={contact} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Paper>
                    </Grid>
                )}

                {campaigns.length === 0 && (
                    <Grid item xs={12}>
                        <Typography>No campaigns available.</Typography>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

// Main CampaignList Component
const CampaignList = ({ contact, user }) => {
    const navigate = useNavigate();
    const [campaigns, setCampaigns] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // New loading state

    const fetchCampaignsAndClients = async () => {
        setIsLoading(true);
        try {
            const campaignsResponse = await axios.get(`${process.env.REACT_APP_DATABASE}api/campaigns?contactId=${contact?.id}`);
            setCampaigns(campaignsResponse.data);

            // Add any other data fetching here if needed
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // Fetch campaigns and clients data from the backend
    useEffect(() => {
        fetchCampaignsAndClients();
    }, [contact]);

    if (isLoading) {
        return (
            <Grid container spacing={2} sx={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
                
                    <CircularProgress />
            
            </Grid>
        );
    }

    return (
        <>
            <Header key="1" icon="campaign" placeholderLocation="Campaigns" user={user} highestPermission="Lead Edit" />

            <CampaignsGrid campaigns={campaigns} navigate={navigate} contact={contact} />

            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', padding: 20, position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1000, minHeight: '60px' }}>
                <EntityTypeSelectionDialog contact={user.contact} fetchCampaignsAndClients={fetchCampaignsAndClients} />
            </div>
        </>
    );
};

export default CampaignList;
