import * as React from "react";
import { List, arrayMove } from "react-movable";
import GroupContactSelectModal from "./GroupContactSelectModal";
import { formatPhoneNumber } from "../Common/FormatPhoneNumber";

export default function GroupContactEditTable({
  peopleIn,
  setPeopleIn,
  groupId,
  bigDataIn,
  peopleCombo,
  user,
}) {
  //test - console.log("Edit Table before Map array");
  const [items, setItems] = React.useState(mapArray(peopleIn));
  const [showContactModal, setShowContactModal] = React.useState(false);

  const triggerSetItems = (newPeopleIn) => {
    // this forces the formatted table to refresh
    //test - console.log("I'm setting the formatted array!");
    setItems(mapArray(newPeopleIn));
  };

  const removeFromBothArrays = (formattedArray, index) => {
    var newDataIn = [...peopleIn];

    newDataIn = newDataIn.filter((dataPoint) => {
      return dataPoint.PermissionLevel !== 'Data'
    })
    deleteContactRelationship(newDataIn[index].id, user);

    setPeopleIn(arrayRemove(newDataIn, index));

    return arrayRemove(formattedArray, index);
  };

  const moveBothArrays = (formattedArray, oldIndex, newIndex) => {
    setItems(arrayMove(formattedArray, oldIndex, newIndex));
    setPeopleIn(arrayMove(peopleIn, oldIndex, newIndex));
    orderContacts(arrayMove(peopleIn, oldIndex, newIndex));
  };

  const addMe = () => {
    setShowContactModal(true );
  };
  //
  ;
  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      ></link>
      <div className="py-2 flex justify-end ">
        <button
          type="button"
          className="inline-flex  self -center items-center content-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
          onClick={() => addMe()}
        >
          <i className="fa fa-plus "></i> <div className="px-2">Add</div>
        </button>
      </div>
      <div className="bg-gray-200 border-solid border-2  border-gray-100 ">
        <div className="px-6">Drag Rows to Change Order</div>
      </div>

      <List
        values={items}
        onChange={({ oldIndex, newIndex }) =>
          moveBothArrays(items, oldIndex, newIndex)
        }
        renderList={({ children, props }) => <ul {...props}>{children}</ul>}
        renderItem={({ value, props, index }) => (
          <li {...props}>
            <div className="hover:bg-gray-50 border-solid border-2  border-gray-100">
              <div className="grid grid-cols-6 gap-4">
                <div className="col-span-5">{value}</div>
                <div className="col-span-1 flex items-center justify-self-end px-4">
                  {items[index].props.permission !== "Lead Edit" ? (
                    <div>
                      <button
                        className=" rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                        onClick={() => {
                          //test - console.log("Items");
                          //test - console.log(items);
                          setItems(
                            typeof index !== "undefined"
                              ? removeFromBothArrays(items, index)
                              : items
                          );
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                      </button>
                    </div>
                  ) : (
                    items[index].props.permission + " can not be deleted"
                  )}
                  <div className="px-2">
                    <button
                      className=" rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                      onClick={async () => {
                        //const {Base64} = require('js-base64');
                        //var dataObjectBase64 = Base64.encode(items[index].key.toString());
                          

                        
                        window.open("contact?id=" + items[index].key, "_self");;
                      }}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        )}
      />
      <GroupContactSelectModal
        open={showContactModal}
        setOpen={setShowContactModal}
        peopleCombo={peopleCombo}
        groupId={groupId}
        bigDataIn={bigDataIn}
        setPeopleIn={setPeopleIn}
        triggerSetItems={triggerSetItems}
        user={user}
        items={items}
        setItems={setItems}
        contactId={user.contact.id}
        
      />
    </>
  );
}

//  function formatPhoneNumber(phoneNumberString) {
//   var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
//   var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
//   if (match) {
//     return '(' + match[1] + ') ' + match[2] + '-' + match[3]
//   }
//   return null
// }

export function arrayRemove(array, index) {
  array = array.slice();
  array.splice(index, 1);
  return array;
}

function mapArray(peopleIn) {
  var myArray = [];
  //test - console.log("Mapping");
  //test - console.log(peopleIn);

  //test - console.log(peopleIn[0]);
  peopleIn.map(
    (person) => {
       if (person.PermissionLevel !== "Data") {
      myArray.push(
        <div
          key={person.contact.id}
          permission={person.PermissionLevel}
          className="grid grid-cols-4 gap-4"
        >
          <div className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
            <div className="flex items-center">
              {/* <div style={imagecropper}>
                 <img style={profilepic} src={person.image.img} alt="" />
            </div> */}
              <div className="h-10 w-10 rounded-full">
                <img
                  className="h-10 w-10 flex-shrink-0 rounded-full object-cover"
                  src={
                    person.contact &&
                    person.contact.images &&
                    person.contact.images.length > 0
                      ? person.contact.images.sort(
                          (a, b) => a.sortOrder - b.sortOrder
                        )[0].img
                      : "https://images.cubicsearch.com/UserLogo.png"
                  }
                  alt=""
                />
              </div>
              <div className="ml-4">
                <div className="font-medium text-gray-900">
                  {person.contact
                    ? person.contact.FirstName + " " + person.contact.LastName
                    : ""}{" "}
                </div>
                <div className="text-gray-500">
                  {person.contact ? person.contact.Email : ""}
                </div>
              </div>
            </div>
          </div>
          <div className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 justify-items-end">
            <div className="text-gray-900">
              {person.contact ? person.contact.Title : ""}
            </div>
            <div className="text-gray-500">
              {person.contact ? formatPhoneNumber(person.contact.Phone) : ""}
            </div>
          </div>
          <div className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <div className="h-10 w-20 flex-shrink-4">
              <img
                className="h-10 w-20 object-contain "
                src={
                  person.contact &&
                  person.contact.office &&
                  person.contact.office.company
                    ? person?.contact?.office?.company?.images?.[0]?.img
                    : "https://images.cubicsearch.com/CompanyLogo.png"
                }
                alt=""
              />
              {/* <div style={imagecropper}>
            <img style={profilepic} src={person.office.company.images[0].img} alt="" />
            </div> */}
            </div>
          </div>
          <div className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {person.PermissionLevel ? person.PermissionLevel : "undesignated"}
          </div>
        </div>
      );
    }
    }
  );

  return myArray;
}

async function deleteContactRelationship(relationshipID, user) {
  //test - console.log(user);
  var link =
    process.env.REACT_APP_DATABASE + "api/deleteGroupContactRelationship?";
  ;
  var qs =
    "id=" +
    relationshipID +
    "&&email=" +
    user.Email +
    "&&contactId=" +
    user.contact.id;
  //test - console.log(link + qs);
  var resultInfo = await fetch(link + qs);
}

async function orderContacts(contacts) {
  //test - console.log("Update the order in the database");
  //test - console.log(contacts);

  contacts.map(async (contact, index) => {
    //test - console.log(contact.id);
    var qs = "id=" + contact.id + "&sortOrder=" + index;
    var resultInfo = await fetch(
      process.env.REACT_APP_DATABASE + "api/sortGroupContacts?" +
        qs
    );
  });
}
