export function ConvertImagePath(inPath) {
    var fileName = inPath.substring(inPath.lastIndexOf('/')+1)
    var fileNameNoPeriods = fileName

  if (inPath.includes("googleapis")) {
    // alert("google")
    return (inPath)
      
  }
  else {
    // alert(inPath)
  }
    while ((fileNameNoPeriods.indexOf(".") >0) && (fileNameNoPeriods.indexOf(".") < (fileNameNoPeriods.length -6))) {


      fileNameNoPeriods = fileNameNoPeriods.replace(".", "_")
    }

    inPath = inPath.replace(fileName, fileNameNoPeriods)
    if (inPath.search("/buildings") > 0) {
      return inPath.replace("/buildings", "/buildingThumbs")
    }
    else if (inPath.search("/availables") >0) {
      return inPath.replace("/availables", "/buildingThumbs")
    }
    else if (inPath.search("/contacts") >0) {
      return inPath.replace("/contacts", "/buildingThumbs")
    }
    else if (inPath.search("/uploads") >0) {
      return inPath.replace("/uploads", "/buildingThumbs")
    }
    else return inPath
   // return inPath
  }