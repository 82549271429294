import React, { useState } from "react";
import { Grid, TextField, Typography, Box } from "@mui/material";
import { Button } from "@mui/material";
import axios from "axios";

function ContactEdit({ contactDataIn, setContactDataIn, setEditMode }) {
  // Using useState to handle form data changes
  const [formData, setFormData] = useState({
    FirstName: contactDataIn.FirstName || "",
    LastName: contactDataIn.LastName || "",
    Title: contactDataIn.Title || "",
    Phone: contactDataIn.Phone || "",
    Email: contactDataIn.Email || "",
    Website: contactDataIn.Website || "",
    Linkedin: contactDataIn.Linkedin || "",
    Notes: contactDataIn.Notes || "",
    id: contactDataIn.id
  });

  // Function to handle changes in text fields
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6">Contact Information</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="First Name"
            variant="outlined"
            name="FirstName"
            value={formData.FirstName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Last Name"
            variant="outlined"
            name="LastName"
            value={formData.LastName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Title"
            variant="outlined"
            name="Title"
            value={formData.Title}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Phone"
            variant="outlined"
            name="Phone"
            value={formData.Phone}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            name="Email"
            value={formData.Email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Website"
            variant="outlined"
            name="Website"
            value={formData.Website}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Linkedin"
            variant="outlined"
            name="Linkedin"
            value={formData.Linkedin}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="About"
            variant="outlined"
            name="Notes"
            value={formData.Notes}
            multiline
            rows={4}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <div style={{ marginTop: 5 }}>
        <Button sx={{ marginRight: 2 }} variant="contained" onClick={async () => {
          var response = await axios.post(process.env.REACT_APP_DATABASE + "api/updateContactSimple",  formData ).catch((error)=>{console.log(error)})
          if (!response) {
            alert("There was a problem with the update")
          }
          else {
            setContactDataIn(formData)
          }
          window.scrollTo({
            top: 0,  // Scroll to the top of the window
            behavior: 'smooth' // Optional: define smooth scrolling
          });
          setEditMode(false)
        }}>
          Save
        </Button>
        <Button onClick={() => { setEditMode(false) }}>
          Cancel
        </Button>
      </div>
    </Box>
  );
}

export default ContactEdit;
