import * as React from "react";
import { List, arrayMove } from "react-movable";
import WebsiteEntryModal from "./WebsiteEntryModal";
import WebsiteEditModal from "./WebsiteUpdateModal";
import Uploady from "@rpldy/uploady";
import { InputComponent } from "../../Common/InputComponent";
import UploadButton from "@rpldy/upload-button";
import { useItemFinishListener } from "@rpldy/uploady";
import { newWebsite } from "./WebsiteEntryModal";
import { Fragment, useRef, useState } from "react";

import { useItemProgressListener } from "@rpldy/uploady";
import {Line} from "rc-progress"
import { Dialog, DialogContent, Typography, Button, TextField, DialogActions, DialogTitle } from "@mui/material";
import { FileUpload } from "@mui/icons-material";

const UploadProgress = () => {
  const [progress, setProgess] = useState(0);

  const progressData = useItemProgressListener();

  if (progressData && progressData.completed > progress) {
    setProgess(() => progressData.completed);
  }
  return (
    progressData && (
      <Line
        style={{ height: "10px", marginTop: "20px" }}
        strokeWidth={2}
        strokeColor={progress === 100 ? "#00a626" : "#2db7f5"}
        percent={progress}
      />
    )
  );
};

const FileUploadWindow = ({
  buildingId,
  setMainPageData,
  mapSitesSetItems,
  contactId,
  open,
  setOpen,
}) => {
  const [description, setDescription] = useState("Brochure from " + new Date().toLocaleDateString());
  // const [open, setOpen] = useState(true);
  if (!buildingId) {
    
  }
  const cancelButtonRef = useRef(null);

  return (
    <Dialog title="Upload Files" open={open} onClose={() => { setOpen(false) }}>
      <DialogTitle sx={{ bgcolor: '#555555', color: 'white' }}>Upload File</DialogTitle>
      <DialogContent sx={{ padding: 5, }}>
        <div style={{ marginTop: 30 }}>
          <Typography variant="h4" fontSize={24}>   Enter File Description       </Typography>
          <InputComponent
            inputType="dynamic"
            defaultShow={"Description"}

            id="id"
            name="Description"
            valueIn={description}
            setvalueIn={setDescription}
            formatting="w-full"
          /> </div>





      </DialogContent>
      <DialogActions>    <Button variant="contained">
        <Uploady
          destination={{
            url: process.env.REACT_APP_IMAGE,
          }}
        >
                      <FinishedUpload
                        buildingId={buildingId}
                        setMainPageData={setMainPageData}
                        mapSitesSetItems={mapSitesSetItems}
                        contactId={contactId}
                        description={description}
                        setOpen={setOpen}
                      />
          <UploadProgress />
        </Uploady>
      </Button>


        <Button sx={{ padding: 1 }}
          variant='outlined'
        onClick={() => { setOpen(false) }}
        >
          Cancel

        </Button></DialogActions>
    </Dialog>)
};

const FinishedUpload = ({
  buildingId,
  description,
  setMainPageData,
  mapSitesSetItems,
  contactId,
  setOpen
}) => {
  const [finished, setFinished] = useState([]);

  useItemFinishListener((item) => {
    setFinished((finished) => {
      // finished.concat(`${item.file.name}`);
      //test - console.log(item);
      //test - console.log(finished);
      var websiteJson = {
        src: item.uploadResponse.data.message,
        Description: description,
      };
      newWebsite(
        buildingId,
        websiteJson,
        setMainPageData,
        mapSitesSetItems,
        contactId
      );
      setOpen(false)
      
    });
  });

  return (
    <>
      <UploadButton
        // className="inline-flex  self -center items-center content-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
        className="inline-flex  self -center items-center content-center"

      >
        <FileUpload style={{ height: 30, width: 30 }}

        />
        <div className="px-2"> Choose File</div>
      </UploadButton>
    </>
  );
};

export default function BuildingWebsiteEdit({
  mainPageData,
  setMainPageData,
  buildingId,
  bigDataIn,
  websitesCombo,
  contactId,
}) {

  if (!buildingId) {
    
  }
  //test - console.log("Edit Table before Map array");

  const [items, setItems] = React.useState(mapArray(mainPageData.websites));
  const [showWebsiteModal, setShowWebsiteModal] = React.useState(false);
  const [showEditWebsiteModal, setShowEditWebsiteModal] = React.useState(false);
  const [websiteId, setWebsiteId] = React.useState();
  const [webSiteDetail, setWebSiteDetail] = useState({src: "set URL", Description: "Describe contents"})
  const [openWebsites, setOpenWebsites] = React.useState(false);

  const mapSitesSetItems = (websites) => {
    setItems(mapArray(websites));
  };

  const removeFromBothArrays = (formattedArray, index) => {
    var newDataIn = { ...mainPageData };

    deleteWebsite(mainPageData.websites[index].id);
    newDataIn.websites = arrayRemove(newDataIn.websites, index);
    setMainPageData(newDataIn);
    mapSitesSetItems(newDataIn.websites);

    return arrayRemove(formattedArray, index);
  };

  const moveBothArrays = (formattedArray, oldIndex, newIndex) => {
    setItems(arrayMove(formattedArray, oldIndex, newIndex)); // move items in the formatted drag list
    // setWebsitesIn(arrayMove(websitesIn, oldIndex, newIndex))
    var resetMainPageData = { ...mainPageData }; // put the mainPageData in a new variable.
    resetMainPageData.websites = arrayMove(
      mainPageData.websites,
      oldIndex,
      newIndex
    ); // Sort the newMainPageDAta already from arrayMove above
    setMainPageData(resetMainPageData); // set the new state to sorted mainPageData
    orderWebsites(resetMainPageData.websites); // save the sorted websites to the database
  };

  const addMe = () => {
    setShowWebsiteModal(true);
  };

  

  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      ></link>
      <div className="py-2 px-0 flex justify-end ">
        <div>
          <button
            type="button"
            className="inline-flex  self -center items-center content-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
            onClick={() => addMe()}
          >
            <i className="fa fa-plus "></i>{" "}
            <div className="px-2"> Add Website</div>
          </button>
          <div className="pl-2"></div>
        </div>

        <div className="pl-2">
          <button
            type="button"
            className="inline-flex  self -center items-center content-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
            onClick={() => {
              setOpenWebsites(true);
            }}
          >
            <i className="fa fa-plus "></i>{" "}
            <div className="px-2"> Add File(s)</div>
          </button>
        </div>
      </div>
      {items.length > 1 ? (
        <div className="bg-gray-200 border-solid border-2  border-gray-100 ">
          <div className="px-6">Drag Rows to Change Order</div>
        </div>
      ) : (
        ""
      )}

      <List
        values={items}
        onChange={({ oldIndex, newIndex }) =>
          moveBothArrays(items, oldIndex, newIndex)
        }
        renderList={({ children, props }) => <ul {...props}>{children}</ul>}
        renderItem={({ value, props, index }) => (
          <li {...props}>
            <div className="hover:bg-gray-50 border-solid border-2  border-gray-100">
              <div className="grid grid-cols-6 gap-4">
                <div className="col-span-5">{value}</div>
                <div className="col-span-1 flex items-center justify-self-end px-4">
                  <div>
                    <button
                      className=" rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                      onClick={() => {
                        setItems(
                          typeof index !== "undefined"
                            ? removeFromBothArrays(items, index)
                            : items
                        );
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="px-2">
                    <button
                      className=" rounded-md border border-transparent bg-red-600 px-4 h-9 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                      onClick={() => {
                        
                        setWebsiteId(mainPageData.websites[index].id);
                        setWebSiteDetail({src: mainPageData.websites[index].src, Description:  mainPageData.websites[index].Description})
                        setShowEditWebsiteModal(true);
                      }}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        )}
      />
      <FileUploadWindow
        open={openWebsites}
        setOpen={setOpenWebsites}
        buildingId={buildingId}
        setMainPageData={setMainPageData}
        mapSitesSetItems={mapSitesSetItems}
        contactId={contactId}
      
        
      />
      <WebsiteEntryModal
        open={showWebsiteModal}
        setOpen={setShowWebsiteModal}
        mainPageData={mainPageData}
        setMainPageData={setMainPageData}
        mapSitesSetItems={mapSitesSetItems}
        contactId={contactId}
      />
      <WebsiteEditModal
        open={showEditWebsiteModal}
        setOpen={setShowEditWebsiteModal}
        mainPageData={mainPageData}
        setMainPageData={setMainPageData}
        mapSitesSetItems={mapSitesSetItems}
        websiteId={websiteId}
        webSiteDetail= {webSiteDetail}
        contactId={contactId}
      />
    </>
  );
}

function arrayRemove(array, index) {
  array = array.slice();
  array.splice(index, 1);
  return array;
}

function mapArray(websitesIn) {
  var myArray = [];
  //test - console.log("Mapping");
  websitesIn.map((website) =>
    myArray.push(
      <div key={website.id} className="flex">
        <div className="w-half px-3 py-4 text-sm text-gray-500">
          {website.Description}
        </div>
        <div className="w-half px-3 py-4 text-sm text-gray-500">
          {website.src}
        </div>
      </div>
    )
  );
  return myArray;
}

async function deleteWebsite(websiteId) {
  var qs = "id=" + websiteId;
  var resultInfo = await fetch(
    process.env.REACT_APP_DATABASE + "api/deleteWebsite?" + qs
  );
}

async function orderWebsites(websites) {
  //test - console.log("Update the order in the database");
  //test - console.log(websites);

  websites.map(async (website, index) => {
    website.sortOrder = index + 1;
    //test - console.log(website.id);
    var qs = "id=" + website.id + "&sortOrder=" + website.sortOrder;
    var resultInfo = await fetch(
      process.env.REACT_APP_DATABASE + "api/sortWebsites?" + qs
    );
  });
}
