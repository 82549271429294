import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    TextField,
    CircularProgress
} from '@mui/material';
import axios from 'axios';

const AIGenerateDialog = ({ open, onClose, emailDetails, onGenerate, index }) => {
    const [description, setDescription] = useState("I'd like to market this property to users near my building or that might potentially move the company to this area.");
    const [loading, setLoading] = useState(false);


    const handleGenerate = async () => {
        setLoading(true);
        try {
            const response = await axios.post(process.env.REACT_APP_DATABASE + 'api/getAIMarketing', {
               emailDetails,
                description, index
            });

            
            onGenerate(response.data);
        } catch (error) {
            console.error('Failed to generate AI message:', error);
        } finally {
            setLoading(false);
            onClose();
        }
    };


 



    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Generate AI Message</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Provide a brief description of the type of message you would like to send.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="description"
                    label="Description"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    multiline
                    rows={4}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleGenerate} color="primary" disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : 'Generate'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AIGenerateDialog;
