import React from "react";
import ContactClients from "../components/ContactClients/ContactClients.js";
//import BingMapsReact from "bingmaps-react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";


export default function ContactClientsInfo({ user }) {
  let [query, setQuery] = useSearchParams();
  let id = query.get("id");
  var qs =
    process.env.REACT_APP_DATABASE + "api/getContactClients?id=" +
    id +
    "&contactId=" +
    user.contact.id;
  var qsInvitedTo =
    process.env.REACT_APP_DATABASE + "api/getGroupsInvitedTo?id=" +
    user.contact.id;

  const fetchData = async () => {
    const result = await fetch(qs);
    return await result.json();
  };

  const fetchGroupsInvitedToData = async () => {
    const result = await fetch(qsInvitedTo);
    return await result.json();
  };

  const fetchPeopleData = async () => {
    const result = await fetch(
      process.env.REACT_APP_DATABASE + "api/getContacts?contactId=" +
        user.contact.id
    );
    return await result.json();
  };

  //test - console.log(qs);
  const {
    data: contactclientsDataArray,
    status: statusContactClientsData,
    error: errorContactClientsData,
  } = useQuery("contactclientsData", fetchData);
  const {
    data: peopleDataArray,
    status: statusPeopleData,
    error: errorPeopleData,
  } = useQuery("peopleData", fetchPeopleData);

  const {
    data: groupsInvitedToData,
    status: statusGroupsInvitedToData,
    error: errorGroupsInvitedToData,
  } = useQuery("groupsInvitedToData", fetchGroupsInvitedToData);

  if (statusContactClientsData === "loading"){
    return (
      <>
        <div className="absolute top-1/3 w-full items-center flex flex-col">
          <img style={{width: 100}} src="https://images.cubicsearch.com/Wait.gif" />
          <div>Loading Clients Data...</div>
        </div>
      </>
    );
  }

  if ( statusPeopleData === "loading") {
    return (
      <>
        <div className="absolute top-1/3 w-full items-center flex flex-col">
          <img style={{width: 100}} src="https://images.cubicsearch.com/Wait.gif" />
          <div>Loading Contacts Data...</div>
        </div>
      </>
    );
  }

  if (statusContactClientsData === "error" || statusPeopleData === "error") {
    return errorContactClientsData.message;
  }

  if (statusGroupsInvitedToData === "error") {
    return "Groups Invite To Error:" + errorGroupsInvitedToData.message;
  }

  // if (
  //   !contactclientsDataArray || !contactclientsDataArray.contactclients || 
  //   contactclientsDataArray.contactclients.length < 1 ||
  //   !peopleDataArray ||
  //   contactclientsDataArray.length < 1
  // ) {
  //   return (
  //     <div>
  //       <div>
  //         <Header user={user} />
  //       </div>
  //       <div className="py-4">
  //         <img src="https://images.cubicsearch.com/noresults.png" className="w-full"/>
  //       </div>
  //     </div>
  //   );
  // }

  // const contactclientsData = contactclientsDataArray.contactclients[0];
  const contactclientsData = contactclientsDataArray.contactclients;
  const groups = contactclientsDataArray.groups;
  const people = getContacts(peopleDataArray);

//   const pushPin = {
//     center: {
//       latitude: contactclientsData.Latitude,
//       longitude: contactclientsData.Longitude,
//     },
//     options: {
//       title:
//         contactclientsData.StreetNumber +
//         " " +
//         contactclientsData.StreetName +
//         ", " +
//         contactclientsData.City,
//     },
//   };

  return (
    <div>
      <div>
        <ContactClients
          ContactClientsInformation={contactclientsData}
          peopleCombo={people}
          user={user}
          groups={groups}
          groupsCombo={groupsInvitedToData}
        />
      </div>
    
    </div>
  );
}

function getContacts(data) {
  var peopleArray = [];
  //   const data = await searchResults.json();
  data.contact.map((person) => {
    var myJsonToAdd = {};
    if (person.images.length > 0) {
      if (person) {
        myJsonToAdd = {
          id: person.id,
          name:
            person.FirstName +
            " " +
            person.LastName +
            " - " +
            checkOffice(person),
          imageURL: person.images[0].img,
        };
      } else {
        myJsonToAdd = {
          id: person.id,
          name: person.FirstName + " " + person.LastName,
          imageURL: person.images[0].img,
        };
      }
    } else {
      if (person.office) {
        myJsonToAdd = {
          id: person.id,
          name:
            person.FirstName +
            " " +
            person.LastName +
            " - " +
            checkOffice(person),
          imageURL:
            "https://images.cubicsearch.com/UserLogo.png",
        };
      } else {
        myJsonToAdd = {
          id: person.id,
          name: person.FirstName + " " + person.LastName,
          imageURL:
            "https://images.cubicsearch.com/UserLogo.png",
        };
      }
    }
    peopleArray.push(myJsonToAdd);
  });

  return peopleArray.sort(sortByContactClients("name"));
}

function sortByContactClients(contactclients) {
  return function (a, b) {
    if (a[contactclients] > b[contactclients]) return 1;
    else if (a[contactclients] < b[contactclients]) return -1;

    return 0;
  };
}

function checkOffice(person) {
  if (person.office && person.office.company) {
    return person.office.company.Name;
  } else return "Add Company";
}
