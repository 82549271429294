import React, { useState, useRef } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import EmailCampaignReport from './EmailCampaignReport';
import ReactToPrint from 'react-to-print';

const DeliveryReportButton = ({ mailerInfo, emailDetails, index }) => {
    const [open, setOpen] = useState(false);
    const printRef = useRef();
    
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button disabled={mailerInfo.status !== 'sent'} variant="outlined" color="primary" onClick={handleClickOpen}>
                Delivery Report
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>
             
                    <ReactToPrint
                        trigger={() => <Button variant="contained" color="primary" sx={{ marginLeft: 2 }} className="print-button">Print Report</Button>}
                        content={() => printRef.current}
                    />
                </DialogTitle>
                <DialogContent>
                    {mailerInfo && <EmailCampaignReport reportData={mailerInfo} ref={printRef} open={open} emailDetails={emailDetails} index={index} />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DeliveryReportButton;
