import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Link } from '@mui/material';
import { TermsOfService } from '../../Privacy';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import FeaturesDialog from './FeaturesDialog'



import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { mobileAndTabletCheck } from '../Search/Deck';

export default function Footer() {
  const [fullScreen, setFullScreen] = React.useState(null)
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleFullScreenChange = () => {
    const isFullScreen = document.fullscreenElement || document.webkitFullscreenElement;

    if (isFullScreen) {
      setFullScreen(true)
      console.log('The browser is in full screen mode.');
    } else {
      setFullScreen(false)
      console.log('The browser is not in full screen mode.');
    }
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
    };
  }, []);
  const theme = createTheme({
    typography: {
      // In Chinese and Japanese the characters are usually larger,
      // so a smaller fontsize may be appropriate.

      fontSize: 12,
    },
  });

  // if (mobileAndTabletCheck() && fullScreen || !mobileAndTabletCheck()) {
    return (
      <>
        <ThemeProvider theme={theme} >
          <div >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems:'center', marginBottom: 3 }}>

              <Grid2 container sx={{
                justifyContent: 'center', marginLeft: mobileAndTabletCheck()?3:0, marginRight: mobileAndTabletCheck() ? 5 : 5, alignItems: 'center'
              }}>
                <Grid2 sx={{
                  display: 'flex', flexDirection: 'column',
                  alignContent: 'center', justifyContent: 'center',
                  marginBottom: 0
                }}>
                  <div style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
                    <img
                      src="https://images.cubicsearch.com/small%20logo.png"
                      className="w-10 h-10"
                      onClick={() => { window.location.assign("/") }}
                    />
                  </div>
                  <Typography component={'div'}variant='body2'>Cubic Search Inc.</Typography>
                </Grid2>
              </Grid2>
              <div className="hidden-mobile">
                <Grid2 container sx={{ justifyContent: 'center' }}>

                  <Grid2 sx={{ width: 200 }}>
                    <Typography component={'div'}sx={
                      { fontWeight: 'bold' }
                    } variant="body2">COMMUNITY</Typography>
                    <Typography component={'div'}sx={{ cursor: 'pointer' }} variant="body2">
                      <div>
                        <Typography
                          component="button"
                          variant="body2"
                          onClick={handleClickOpen}
                        >
                          Terms of Service
                        </Typography>
                        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                          <DialogTitle>Terms of Service</DialogTitle>
                          <DialogContent>
                            <TermsOfService />
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleClose} color="primary">
                              Close
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                    </Typography>



                  </Grid2>
                  <Grid2 sx={{ width: 200 }} >
                    <Typography component={'div'}sx={
                      { fontWeight: 'bold' }
                    } variant="body2">MARKETING</Typography>
                    <div>
                     <FeaturesDialog/>
                    </div>


                  </Grid2>
                  <Grid2 sx={{ width: 200 }}>

                    <Typography component={'div'}sx={
                      { fontWeight: 'bold' }
                    } variant="body2">SUPPORT</Typography>
                    {/* <Typography component={'div'}variant="body2">Help Center</Typography> */}
                    <Typography component={'div'}style={{ cursor: 'pointer' }} variant="body2"><a href="mailto:support@cubicsearch.com">Contact Us</a></Typography>

                  </Grid2>
                  <Grid2 sx={{ width: 200, whiteSpace: 'normal' }}>
                    <Typography component={'div'}sx={
                      { fontWeight: 'bold' }
                    } variant="body2">ABOUT</Typography>
                    <Typography component={'div'}variant="body2" sx={{ cursor: 'pointer' }}>
                      <a onClick={() => {
                        window.open(window.location.origin + "?privacy=true")
                      }}>Privacy Policy</a>
                    </Typography>
                    <Typography component={'div'}variant="body2">Patents Pending</Typography>
                    <Typography component={'div'}variant="body2">All Rights Reserved, Copyright 2024, Cubic Search Inc.</Typography>

                  </Grid2>

                </Grid2>
              </div>
              <div className="showOnMobile">
                <Grid2 container sx={{ marginLeft: 2, justifyContent: 'center' }}>

                
                  <Grid2 sx={{ width: 200, whiteSpace: 'normal'}}>
                    <Typography component={'div'}sx={
                      { fontWeight: 'bold' }
                    } variant="body2">ABOUT</Typography>
                    <Typography component={'div'}variant="body2" sx={{ cursor: 'pointer' }}>
                      <a onClick={() => {
                        window.open(window.location.origin + "?privacy=true")
                      }}>Privacy Policy</a>
                    </Typography>
                    <Typography component={'div'}variant="body2">Patents Pending</Typography>
                    <Typography component={'div'}variant="body2">All Rights Reserved, Copyright 2024, Cubic Search Inc.</Typography>

                  </Grid2>

                </Grid2>
              </div>
            </div>
          </div>
        </ThemeProvider>

      </>
    );
  // }
  // else {
  //   return <></>
  // }
}


