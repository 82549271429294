import { useState } from "react";

import React from "react";

import { Helmet } from "react-helmet";

import AddProperty from "./AddProperty/AddProperty";

import SearchBarCombo from "./SearchBar/SearchBarCombo";
import getSearchData from "./SearchBar/SearchBarData";


import UserMenu from "../UserMenu/UserMenu";

import LoginButton from "../Auth0/Login";
import { SignupButton } from "../Auth0/Login";
import MessageBanner from "./MessageBanner";
import useSearchParams from "react-router-dom";
import { Box,  Tooltip } from "@mui/material";

import axios from "axios";
import { mobileAndTabletCheck } from "../Search/Deck";
import { displayPartsToString } from "typescript";
// import * as React from 'react';


import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';

import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import { ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";


// In your CSS or JS file
import { createTheme } from '@mui/material/styles';





const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));



const handleIcon = (iconAddress) => {
  const favicon = getFaviconEl(); // Accessing favicon element
  const faviconApple = getFaviconElApple();
  favicon.href = iconAddress;
  faviconApple.href = iconAddress;
};





function Header({
  placeholderLocation,

  icon,
  user,
  highestPermission,
  searchResults,
  setSearchResults,
  addProperties,
  setAddingNewProperties,
  entityId,
  setGeometry,
  setInitialViewState,
  searchDetailRef,
  filters,

}) {
  const [properties, setProperties] = useState({ empty: true });
  const [selectedSearchItem, setSelectedSearchItem] = useState({});
  const [popoverShow, setPopoverShow] = useState(false);
  const navigate = useNavigate()
  const theme = createTheme({
    typography: {
      fontFamily: 'Rubik Lines, system-ui',
    },
  });
  const [controllers, setControllers] = useState(
    {
      controler: new AbortController(),
      googleController: new AbortController()
    });

  const [contact, setContact] = useState(
    user?.contact ? user?.contact : { id: 0 }
  );

  var output = {};
  document.cookie.split(/\s*;\s*/).forEach(function (pair) {
    pair = pair.split(/\s*=\s*/);
    var name = decodeURIComponent(pair[0]);
    var value = decodeURIComponent(pair.splice(1).join("="));
    output[name] = value;
  });

  if (user && user?.contact.id) {
    if (output.guest === false || output.guest === 'false' || output.guest === 0)
    { user.contact.guest = false }
    else {
      output.guest = true
    }
  }


  if (
    window.location.href === window.location.origin + "/" &&
    !(user?.contact?.guest === false || user?.contact?.guest === 'false')
  ) {
    return (
   

      PrimarySearchAppBar()
    );
  }



  handleIcon("https://images.cubicsearch.com/small%20logo.png");
  if (icon === "search") {
    handleIcon("https://images.cubicsearch.com/small%20survey.png");
  } else if (icon === "space") {
    handleIcon("https://images.cubicsearch.com/small%20spaces.png");
  } else if (icon === "space edit") {
    handleIcon("https://images.cubicsearch.com/small%20spaces%20edit.png");
  } else if (icon === "contact_edit") {
    handleIcon("https://images.cubicsearch.com/UserLogo.png");
  } else if (icon === "parcel") {
    handleIcon("https://images.cubicsearch.com/small%20parcel%203.png");
  } else if (icon === "company") {
    handleIcon("https://images.cubicsearch.com/company%20icon.png");
  } else if (icon === "group") {
    handleIcon("https://images.cubicsearch.com/GroupImage.ico");
  } else if (icon === "client") {
    handleIcon("https://images.cubicsearch.com/cubeClientcrop.png");
  } else if (icon === "contactclients") {
    handleIcon("https://images.cubicsearch.com/cubeClientcrop.png");
  } else if (icon === "project") {
    handleIcon("https://images.cubicsearch.com/projectcubic4.png");
  } else if (icon === "survey") {
    handleIcon("https://images.cubicsearch.com/small%20survey.png");
  } else if (icon === "comp") {
    handleIcon("https://images.cubicsearch.com/compIcon.png");
  } 

  return (
   
    PrimarySearchAppBar()
  );





  function PrimarySearchAppBar() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
      setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
      handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
      setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
        <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem>
          <IconButton size="large" aria-label="show 4 new mails" color="inherit">
            <Badge badgeContent={4} color="error">
              <MailIcon />
            </Badge>
          </IconButton>
          <p>Messages</p>
        </MenuItem>
        <MenuItem>
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
          >
            <Badge badgeContent={17} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </MenuItem>
        <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </Menu>
    );



    return (
      <>
        <Box sx={{ flexGrow: 1, maxWidth: '100%', }}>
          <AppBar position='fixed' sx={{ maxWidth: '100%', }}>
            <Toolbar sx={{
              backgroundColor:
                '#555555'
              , height: 100, width: '100%', maxWidth: '100%', display: 'flex', justifyContent: 'space-between'
            }}>
              <IconButton
                onClick={() => { navigate("/") }}
                size={mobileAndTabletCheck ? "medium" : "large"}
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ mr: mobileAndTabletCheck() ? 0 : 2 }}
              >
                <img src="https://images.cubicsearch.com/small%20logo.png" style={{ width: 35, height: 35 }}></img>
              
              </IconButton>

              <ThemeProvider theme={theme}>

                <Typography
                  onClick={() => { navigate("/") }}
                  variant={mobileAndTabletCheck() ? "h6" : "h4"}
                  
                  component="div"
                  sx={{
                    fontFamily: 'Rubik Lines, system-ui',
                    display: window.location.href === (window.location.origin + "/") ? 'block' : { xs: 'none', sm: 'block' }
                  }}
                >
                  CUBIC SEARCH
                </Typography>

              </ThemeProvider>

              <div
                className="thecombocontainer"
                id="thesearchcombo" style={
                {
                  display: window.location.href === (window.location.origin + "/") ? 'none' : null,
                  width: 800
                }}>
                <SearchBarCombo
                  properties={properties}
                  selectedProperty={selectedSearchItem}
                  setSelectedProperty={setSelectedSearchItem}
                  getSearchData={getSearchData}
                  setSearchProperties={setProperties}
                  contactId={user?.contact.id}
                  searchResults={searchResults}
                  setSearchResults={setSearchResults}
                  addProperties={addProperties}
                  setAddingNewProperties={setAddingNewProperties}
                  setGeometry={setGeometry}
                  setInitialViewState={setInitialViewState}
                  searchDetailRef={searchDetailRef}
                  controllers={controllers}
                  setControllers={setControllers}
                  filters={filters}
                  guest={user?.contact?.guest}

                />
              </div>
              <Box  className={"theflexgrow"} />
              <Box sx={{ display: user?.contact?.guest !== false ? 'none' : { xs: 'none', md: 'flex' } }}>
                <IconButton onClick={async () => {
                  // await axios.get(process.env.REACT_APP_DATABASE + "api/getGeosAll")
                  // window.open("mygroups?id=" + contact.id)
                  navigate(`/mygroups?id=`+contact.id)
                }} size="large" color="inherit">
                  <Tooltip placement='left' title="Message and Group Center">
                    <MailIcon />
                  </Tooltip>
                </IconButton>


              </Box>
              <Box id="loginside" sx={{
                marginRight: mobileAndTabletCheck() ? 1 : 0,
                display: 'flex',
                justifyContent: 'center'
                // , width: '20%'
              }}>
                {user?.contact?.guest || !user?.contact ? (
                  <>
                    {" "}

                    <div

                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div
                        style={{ marginBottom: mobileAndTabletCheck() ? 3 : 5 }}
                        className={!mobileAndTabletCheck() ? "w-60" : "w-20"}
                      >
                        <SignupButton />
                      </div>
                      <div style={{ marginBottom: mobileAndTabletCheck() ? 1 : 3 }}
                        className={!mobileAndTabletCheck() ? "w-60" : "w-20"}
                      >
                        <LoginButton />
                      </div>

                    </div>
                  </>
                ) : (
                  <div className=" flex items-start w-30 justify-end  "
                    style={{ paddingRight: mobileAndTabletCheck() ? 0 : 20 }}>
                    <div className="flex items-center  text-gray-500">
                    
                    
                      <UserMenu contact={user?.contact}  />

                
                    </div>
                  </div>
                )}
              </Box>

            </Toolbar>


           {icon !== "campaign"? <div>
              <MessageBanner
                highestPermission={highestPermission}
                icon={icon}
                placeholderLocation={placeholderLocation}
                entityId={entityId}
                contactId={user?.contact.id}
                guest={user?.contact.guest}
              />
            </div>: <></>}
          </AppBar>

          {renderMobileMenu}
          {renderMenu}
          <Box sx={{ height: window.location.href.includes("/search?") || (window.location.href === window.location.origin + "/") ? 100 : 200 }}></Box>
        </Box>

      </>
    );
  }

}

export default Header;

function getFaviconEl() {
  return document.getElementById("favicon");
}

function getFaviconElApple() {
  return document.getElementById("appleFavicon");
}
