//import Image from "next/image";
import ProjectsList from "./ProjectList";
import ContactList from "./ContactList";
import ClientGroupList from "./ClientGroups/ClientGroupList";
import { useState } from "react";

import WebsiteList from "./Websites/WebsiteList";

function MainClientInfo({
  dataIn,
  peopleCombo,
  setDataIn,
  contactId,
  groups,
  groupsCombo,
  highestPermission,
}) {
  const [groupsIn, setGroupsIn] = useState(groups);
  // var highestPermission = "None";
  var nfObject = new Intl.NumberFormat("en-US");

  // groupsIn.map((group) => {
  //   group.client_groups.map((client_group) => {
  //     if (
  //       highestPermission !== "View" &&
  //       highestPermission !== "Edit" &&
  //       highestPermission !== "Lead Edit"
  //     ) {
  //       highestPermission = client_group.groupPermission;
  //     } else {
  //       if (
  //         (highestPermission === "View" &&
  //         client_group.groupPermission === "Edit") ||
  //         client_group.groupPermission === "Lead Edit"
  //       ) {
  //         highestPermission = client_group.groupPermission;
  //       } else if (
  //         highestPermission === "Edit" &&
  //         client_group.groupPermission === "Lead Edit"
  //       ) {
  //         highestPermission = client_group.groupPermission;
  //       }
  //     }
  //   });
  // });

  const [people, setPeople] = useState(
    dataIn.contacts.sort(
      (a, b) => a.client_contact.sortOrder - b.client_contact.sortOrder
    )
  );

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        {/* <h3 className="text-lg leading-6 font-medium text-gray-900">
          {dataIn.StreetNumber} {dataIn.StreetName}, {dataIn.City}{" "}
          {dataIn.State}
        </h3> */}
        {/* <p className="mt-1 max-w-2xl text-sm text-gray-500">
          {dataIn.campus
            ? dataIn.campus.CampusName
            : "Please Provide Campus Name"}
        </p> */}
        <dt className="text-sm font-medium text-gray-500">Client Name</dt>
        <p className="mt-1 max-w-2xl text-sm text-gray-600">
          {dataIn.ClientName}
        </p>
        <div className="py-2 sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">About</dt>
        <dd className="mt-1 text-sm text-gray-900 whitespace-pre-line">
          {dataIn.Notes}
        </dd>
      </div>
      </div>


      {/* <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Client Size</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {nfObject.format(dataIn.Size)} SF
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Client Type</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {dataIn.ClientType}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Site Size</dt>
            <dd className="mt-1 text-sm text-gray-900">2.3 Acres</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Year Built</dt>
            <dd className="mt-1 text-sm text-gray-900">{dataIn.YearBuilt}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Number of Floors
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {dataIn.NumberFloors}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Year Renovated
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {dataIn.YearRenovated}
            </dd>
          </div>
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">About</dt>
            <dd className="mt-1 text-sm text-gray-900 whitespace-pre-line">
              {dataIn.Notes}
            </dd>
          </div>
        </dl>
      </div> */}
      <div
        id="projects"
        key="projects"
        className="border-t border-gray-200 px-4 py-5 sm:px-6"
      >
        <ProjectsList
          projectsIn={dataIn.projects}
          clientId={dataIn.id}
          contactId={contactId}
          highestPermission={highestPermission}
        />
      </div>
      <div className="border-t border-gray-200 px-2 py-5 sm:px-6">
        <ContactList
          peopleIn={people}
          dataIn={dataIn}
          setPeopleIn={setPeople}
          peopleCombo={peopleCombo}
          contactId={contactId}
          groupsIn={groupsIn}
          highestPermission={highestPermission}
        />
      </div>
      <div className="border-t border-gray-200 px-2 py-5 sm:px-6">
        <ClientGroupList
          groupsIn={groupsIn}
          setGroupsIn={setGroupsIn}
          clientId={dataIn.id}
          contactId={contactId}
          groupsCombo={groupsCombo}
          highestPermission={highestPermission}
          setData={setDataIn}
        />
      </div>
      <div className="border-t border-gray-200 px-2 py-5 sm:px-6">
        <WebsiteList
          mainPageData={dataIn}
          setMainPageData={setDataIn}
          contactId={contactId}
          highestPermission={highestPermission}
        />
      </div>
    </div>
  );
}

export default MainClientInfo;
