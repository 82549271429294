import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { mobileAndTabletCheck } from "../Search/Deck";
import { getCookie, createCookie } from "../Common/Cookies";
import OptInDialog from "./OptIn"
import { useState } from "react";
const LoginButton = ({ px, py }) => {
  const { loginWithRedirect } = useAuth0();
  const [optIn, setOptIn] = useState(getCookie("OptIn"))
  const [dialogOpen, setDialogOpen] = useState(false);


  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = (optInStatus) => {
    setDialogOpen(false);
    setOptIn('true')
    console.log('Opt-In Status:', optInStatus);
    loginWithRedirect();
    // You can handle the opt-in status here (e.g., make an API call to record the opt-in status)
  };
  if (!mobileAndTabletCheck()) {
    if (!px) {
      px = "px-4";
    }
    if (!py) {
      py = "py-1";
    }
  } else {
    px = "px-1";
    py = "py-1";
  }

  return (
    <><OptInDialog open={dialogOpen} onClose={handleDialogClose} />
      <button
        className={
          "inline-flex justify-center w-20 sm:w-full rounded-md border border-transparent shadow-sm " +
          px +
          " " +
          py +
          " bg-red-600 text-base font-medium text-white hover:bg-red-700 hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 text-xs sm:text-sm"
        }
        onClick={() => {



          if (!(window.location.href.indexOf("error=unauthorized") > 0)) {
            document.cookie = "website=" + window.location.href + ";SameSite='none'";
          }
          document.cookie = "checkreroute=true" + ";SameSite='none'";
          if (optIn === 'true') {
            loginWithRedirect();
          }
          else {

            handleDialogOpen()
          }

        }}
      >
        Log In
      </button></>
  );
};

export default LoginButton;

export const SignupButton = ({ px, py }) => {
  const { loginWithRedirect } = useAuth0();
  const [optIn, setOptIn] = useState(getCookie("OptIn"))
  const [dialogOpen, setDialogOpen] = useState(false);


  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = (optInStatus) => {
    setDialogOpen(false);
    setOptIn('true')
    console.log('Opt-In Status:', optInStatus);
    loginWithRedirect({ screen_hint: "signup" });
    // You can handle the opt-in status here (e.g., make an API call to record the opt-in status)
  };
  if (!mobileAndTabletCheck()) {
    if (!px) {
      px = "px-4";
    }
    if (!py) {
      py = "py-1";
    }
  } else {
    px = "px-1";
    py = "py-1";
  }
  return (
     <><OptInDialog open={dialogOpen} onClose={handleDialogClose} />

    <button
      style={{ color: 'white', outlineColor: 'white', marginTop: mobileAndTabletCheck() ? null : 3 }}
      className={
        "inline-flex justify-center  sm:w-full rounded-md border  shadow-sm " +
        px +
        " " +
        py +
        "  text-base font-medium  w-20 hover:shadow-xl hover:text-red text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 text-xs sm:text-sm"
      }
      onClick={() => {
        if (!(window.location.href.indexOf("error=unauthorized") > 0)) {
          document.cookie = "website=" + window.location.href + ";SameSite='none'";
        }
        document.cookie = "checkreroute=true" + ";SameSite='none'";
        if (optIn === 'true') {
          loginWithRedirect({ screen_hint: "signup" });
        }
        else {

          handleDialogOpen()
        } 
      }}
    >
      {!mobileAndTabletCheck() ? "Sign Up for a Free Account" : "Sign Up"}
      </button>
      </>
  );
};
