

import SendIcon from '@mui/icons-material/Send';
import {  AttachFile } from '@mui/icons-material';
import * as React from "react";

import Uploady from "@rpldy/uploady";
import { InputComponent } from "../../Common/InputComponent";
import UploadButton from "@rpldy/upload-button";
import { useItemFinishListener, useBatchFinishListener } from "@rpldy/uploady";

import { useRef, useState } from "react";

import { Dialog, DialogContent, Typography, Button, TextField, DialogActions, DialogTitle } from "@mui/material";

import { useItemProgressListener } from "@rpldy/uploady";
import { Line } from "rc-progress"
import axios from 'axios';
import { TextareaAutosize, darkScrollbar } from '@mui/material';


export function newJournalWebsite(groupId, type, typeId, fileName, website, contactId, setTrigger) { // this needs to call the back end and add the website link to a cubic attachement/sharelink just like the email did.

    return new Promise(async (resolve, reject) => {
        //We add in the new avail
        var fetchLocation = process.env.REACT_APP_DATABASE + "api/postAttachment"



        var result = await axios.post(fetchLocation, {
            type: type,
            groupId: groupId,
            typeId: typeId,
            contactId: contactId,
            fileName: fileName,
            shareLink: website
        }).catch((error) => {
            resolve(false)
            return
        })

        resolve(true)
        return
        //We get the data from the database with the new id given to website just added

    })


}

const UploadProgress = () => {
    const [progress, setProgess] = useState(0);

    const progressData = useItemProgressListener();

    if (progressData && progressData.completed > progress) {
        setProgess(() => progressData.completed);
    }
    return (
        progressData && (
            <Line
                style={{ height: "10px", marginTop: "20px" }}
                strokeWidth={2}
                strokeColor={progress === 100 ? "#00a626" : "#2db7f5"}
                percent={progress}
            />
        )
    );
};

const FileUploadWindow = ({
    groupId,
    type,
    typeId,
    contactId,
    open,
    setOpen,
    setTrigger
}) => {
    const [description, setDescription] = useState("File Description" + new Date().toLocaleDateString());
    // const [open, setOpen] = useState(true);

    const cancelButtonRef = useRef(null);

    return (
        <>
            <Dialog title="Upload Files" open={open} onClose={() => { setOpen(false) }}>
                <DialogTitle sx={{ bgcolor: '#555555', color: 'white' }}>Upload File(s)</DialogTitle>
                <DialogContent sx={{ padding: 5, }}>
                    <div style={{ marginTop: 30 }}>
                        <Typography variant="h4" fontSize={24}>   Enter File(s) Description       </Typography>
                        <InputComponent
                            inputType="dynamic"
                            defaultShow={"Description"}

                            id="id"
                            name="Description"
                            valueIn={description}
                            setvalueIn={setDescription}
                            formatting="w-full"
                        /> </div>





                </DialogContent>
             
              

                <DialogActions>
                    <Uploady
                        destination={{
                            url: process.env.REACT_APP_IMAGE,
                        }}
                    >
                        <FinishedUpload
                            groupId={groupId}
                            type={type}
                            typeId={typeId}
                            contactId={contactId}
                            description={description}
                            setOpen={setOpen}
                            setTrigger={setTrigger}
                        />
                    
                      
                    </Uploady>
                    <Button sx={{ padding: 1 }}
                        variant='outlined'
                        onClick={() => { setOpen(false) }}
                    >
                        Cancel

                    </Button>
                </DialogActions >
            </Dialog >

        </>


    );
};

const FinishedUpload = ({
    groupId,
    type,
    typeId,
    description,
    contactId,
    setOpen,
    setTrigger,
}) => {
    const [finished, setFinished] = useState([]);

    useItemFinishListener((item) => {
        setFinished(async (finished) => {
            // finished.concat(`${item.file.name}`);
            //test - console.log(item);
            //test - console.log(finished);

            var finito = await newJournalWebsite(
                groupId,
                type,
                typeId,
                description,
                item.uploadResponse.data.message,
                contactId,
                setTrigger

            );

        



        });
    });

    useBatchFinishListener((batch) => {
        
        setTimeout(() => {

            setOpen(false)
            if (setTrigger) {

                setTrigger(true) // designed to refresh messages for attachements, no one else passes this in
                // alert("Please refresh page if you don't see your file right away.  Thanks!")
            }
            // else if (setTrigger ) {
            //     alert("Something went wrong, please contact support@cubicsearch.com, thank you.")
            // }
        }
            , 1000);
    })
    // put in refresh dialogue here if file problem persists.

    return (
        <>
            <UploadButton
                // className="inline-flex  self -center items-center content-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                // className="inline-flex  self -center items-center content-center"

            >
             <Button variant='contained'>
                    Choose File
                </Button>
                <UploadProgress />
            </UploadButton>
          
        </>
    );
};

const wrapForm = {
    display: "flex",
    justifyContent: "center",
    // width: "95%",
    width: '100%',
    marginTop: 5//`auto`
}

const wrapText = {
    width: "100%",

}




const button = {
    margin: 1
}




export const TextInput = ({ setTrigger, onClick, groupId, type, typeId, contactId }) => {


    const [textValue, setTextValue] = useState(null)
    const [openWebsites, setOpenWebsites] = useState(false);

    const formRef = useRef()
    async function submit(event) {
        onClick(textValue)


        if (groupId != 1) {
            var myInfo = await axios.get(process.env.REACT_APP_DATABASE + 'api/getContactShort?contactId=' + contactId).catch((error) => { console.log(error) }) // this gets the myInfo Variable
            if (!myInfo) {
                myInfo = {

                    "FirstName": "Unfiled",
                    "LastName": "Unfiled",
                    "Email": "noreply@cubicsearch.com",
                    "office": {
                        "company": {
                            "Name": "unfiled"
                        }

                    }
                }
            }

            if (!type || type === 'undefined') {
                type = ""
            }
            var sqlString = process.env.REACT_APP_DATABASE + 'api/getGroupsPost?groupId=' +
                groupId +
                ("&type=" + type || "") +
                "&typeId=" + (typeId || "") +
                "&contactId=" + contactId

            console.log(sqlString)
            var groupInfo = await axios.get(sqlString).catch((error) => { console.log(error) })  // this gets the toInfo and the groupInfo
            // if (!groupInfo) {
            //     groupInfo = {
            //         toInfo: {
            //             "Emails": "damon_schor@yahoo.com"
            //         },
            //         "GroupName": "PSIQ",
            //         "type": "buildingId",
            //         "typeId": "10825538540",


            //         "id": "153",

            //         "AvailableInfo": {
            //             "Floor": "1",
            //             "Suite": "400",
            //             "id": "1234",
            //             "availImage": "https://images.cubicsearch.com/Light%20Banner.jpg"
            //         },
            //         "PropertyInfo": {
            //             "CampusName": "Moran Deal",
            //             "StreetNumber": "150",
            //             "StreetName": "Industrial",
            //             "City": "SC",
            //             "id": "10825538540",
            //             "propertyImage": "https://images.cubicsearch.com/uploads/ahr0chm6ly93d3dsaxn0aw5nc3nlyxjjagnicmvldw4uymxvyi5jb3jllndpbmrvd3mubmv0l2zpbgvhc3nldhmvvvmtu01qtc04mdeyni82ytblytc1ms9lmzrmzjjiys1lmdgyltrjyzutyjg0zi1kmwi1zgu3mdk4mmeuanbne34ff2ba_e082_4cc5_b84f_d1b5de70982a.jpg?w=242&h=242&fit=crop&auto=format&dpr=2"
            //         },

            //     }
            // }


            var message = textValue.target.value



            setTextValue("")
            formRef.current.reset()


            var result = await axios.post(process.env.REACT_APP_MAILER + "routes/generalEmail", {
                "myInfo": myInfo.data,
                "toInfo": groupInfo.data.toInfo,
                "groupInfo": groupInfo.data,
                "Message": message
            })
        }
        else {
            var message = textValue.target.value
            setTextValue("")
            formRef.current.reset()

            // var result = await axios.post(process.env.REACT_APP_DATABASE + "api/postJournalNoEmail", {
            //     "type": type,
            //     "typeId": typeId,
            //     "groupId": groupId,
            //     "Message": message,
            //     contactId: contactId,
            // })
        }
    }

    return (
        <>
            <form ref={formRef} style={wrapForm} noValidate autoComplete="off">
                <Button variant='outlined'
                    sx={{ borderTopLeftRadius: 5, borderBottomLeftRadius: 5, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    onClick={() => {

                        setOpenWebsites(true)
                    }}><AttachFile /></Button>
                <TextareaAutosize

                    id="standard-text"
                    label="Send a message"
                    sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    style={wrapText}

                    variant='outlined'
                    onChange={
                        setTextValue
                    }
                    onKeyDown={(ev) => {
                        console.log(`Pressed keyCode ${ev.key}`);
                        // if (ev.key === 'Enter') {
                        //     submit(ev)
                        //     ev.preventDefault();
                        // }
                    }}
                //margin="normal"
                />
                <Button
                    sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 5, borderBottomRightRadius: 5 }}
                    onClick={(event) => {

                        submit(event)


                    }} variant="contained" color="primary" style={button}>
                    <SendIcon />
                </Button>
            </form>
            <FileUploadWindow
                open={openWebsites}
                setOpen={setOpenWebsites}
                groupId={groupId}
                type={type}
                typeId={typeId}
                contactId={contactId}
                setTrigger={setTrigger}

            />
        </>
    )
}




