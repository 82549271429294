

import getSearchData from "./SearchBar/SearchBarData";
import SearchBarCombo from "./SearchBar/SearchBarCombo";
import { useState } from "react";
import { Button, rgbToHex } from "@mui/material";
import { getGeoInfo } from "../Search/SearchDetail";
import { createCookie, getCookie } from "./Cookies";
import { useNavigate } from "react-router-dom";
import { mobileAndTabletCheck } from "../Search/Deck";
import CircularProgress from '@mui/material/CircularProgress';
import LocationDialog from "./LocationDialog";



function Banner({ login, user, searchResults, setSearchResults }) {
  const [properties, setProperties] = useState({ empty: true });
  const navigate = useNavigate()
  const [selectedSearchItem, setSelectedSearchItem] = useState({});
  const [loading, setLoading] = useState(false)
  const [controllers, setControllers] = useState({
    controller: new AbortController(),
    googleController: new AbortController()
  })
  const [showZipDialog, setShowZipDialog] = useState(false)



  return (


    <>
      <div id="maincontainer"
        className={mobileAndTabletCheck() ? "phoneContainer" : window.innerHeight > 1500 ? "bigContainer" : "rootContainer"}
      >




        <div id="floatContainer"
          style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
          className={

            !mobileAndTabletCheck()
              ? "absolute  top-1/3 "
              : "absolute  top-1/3"
          }
        >

          {login && login === "yes" ? (
            <>
              {properties ? (
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', maxWidth: 900, zIndex: 10 }} >
                  <SearchBarCombo
                    properties={properties}
                    selectedProperty={selectedSearchItem}
                    setSelectedProperty={setSelectedSearchItem}
                    getSearchData={getSearchData}
                    setSearchProperties={setProperties}
                    contactId={user.contact.id}
                    searchResults={searchResults}
                    setSearchResults={setSearchResults}
                    addProperties={null}
                    setAddingNewProperties={null}
                    height="h-16"
                    controllers={controllers}
                    setControllers={setControllers}
                  />
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (

            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', maxWidth: 900, zIndex: 10 }} >
              <SearchBarCombo
                properties={properties}
                selectedProperty={selectedSearchItem}
                setSelectedProperty={setSelectedSearchItem}
                getSearchData={getSearchData}
                setSearchProperties={setProperties}
                contactId={user.contact.id}
                searchResults={searchResults}
                setSearchResults={setSearchResults}
                addProperties={null}
                setAddingNewProperties={null}
                height="h-16"
                controllers={controllers}
                setControllers={setControllers}



              />
            </div>
          )}

          <div style={{ marginTop: 40 }} className='flex justify-center'>

            <div>
              <Button
                sx={{
                  color: 'white',

                  backgroundColor: rgbToHex("rgb(255, 10, 10)"),// rgbToHex("rgb(79, 70, 229)"),
                  border: 1,
                  '&:hover': {
                    backgroundColor: 'darkred',// rgbToHex("rgb(69, 60, 209)"),
                    color: 'white',

                  },

                  textTransform: 'none',
                  paddingX: 8,
                  paddingY: 2,
                  fontSize: mobileAndTabletCheck() ? 18 : 14,
                  borderRadius: 20
                }}
                onClick={() => {
                  setLoading(true)

                  getGeoInfo().then((coords) => {
                    setLoading(false)
                    if (!coords) {
                      alert("Please either enable your location, or just put in an address in the search bar.  Your location services are turned off for your browser.")
                    }
                    else {
                      createCookie("SearchMe", true, 1)
                      var newSpot = { "location": { "lat": coords[1], "lng": coords[0], "elevation": coords[2] }, "viewport": { "northeast": { "lat": coords[1] + .01, "lng": coords[0] + .01 }, "southwest": { "lat": coords[1] - .01, "lng": coords[0] - .01 } } }
                      navigate("/search?moveMeHere=" + encodeURI(JSON.stringify(newSpot)))
                    }

                  }).catch((error) => {
                    var myLocation = getCookie("myLocation")
                    if (!myLocation) {
                      setShowZipDialog(true)
                    }

                  })


                }}
              >
                Buildings Near Me
              </Button>

            </div>
          </div>

          {loading && <CircularProgress style={{ marginTop: '20px' }} />}
        </div>
        <LocationDialog open={showZipDialog} onClose={() => setShowZipDialog(false)} properties={properties}
          selectedProperty={selectedSearchItem}
          setSelectedProperty={setSelectedSearchItem}
          getSearchData={getSearchData}
          setSearchProperties={setProperties}
          contactId={user.contact.id}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          addProperties={null}
          setAddingNewProperties={null}
          height="h-16"
          controllers={controllers}
          setControllers={setControllers}
                user={user}
        />

      </div>
    </>
  );

}

export default Banner;

