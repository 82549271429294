import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, List, Typography, Dialog, Box, Grid, Collapse, Slider } from '@mui/material';
import { MapboxAutocompleteLatLon } from './FindMore/MapboxAutocomplete';
import NewDeck from '../../Search/NewDeck';
import SliderControl from '../../Search/RotaryControl';

import { CircularProgress } from '@mui/material';
import CustomListItem from './FindMore/CutomLIstItem';


function CompanySearchComponent({ mailListId, contactId, MailListName, setSearchMethod, recipients, fetchData }) {

    const [industryType, setIndustryType] = useState('');
    const [geoJson, setGeoJson] = useState([]);
    const [Latitude, setLatitude] = useState(null)
    const [Longitude, setLongitude] = useState(null)
    const [Radius, setRadius] = useState(1000)
    const [selectedItems, setSelectedItems] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [clusterHighlights, setClusterHighlights] = useState(null)
    const itemRefs = useRef({});
    const [lastViewState, setLastViewState] = useState(null)
    const childRef = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const [getPeople, setGetPeople] = useState(false)
    const [nextPageToken, setNextPageToken] = useState(null)
 
    const [viewState, setViewState] = useState({
        longitude: -122.4194,
        latitude: 37.7749,
        zoom: 11,
        pitch: 0,
        bearing: 0
    })



    useEffect(() => {
        if (geoJson) {
            if (childRef.current) {

                childRef.current.handleButtonPress(); // Call the function via the ref
            }
            // Effect to control the opening and closing of the collapse
            setIsOpen(geoJson.length === 0); // Collapse is open when there are no items
        }
    }, [geoJson])




    useEffect(() => {
        // Scroll to newly selected items
        selectedItems.forEach(place => {

            const placeId = place.properties.placeId;
            if (itemRefs.current[placeId]) {
                itemRefs.current[placeId].scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest'
                });
            }
        });
    }, [selectedItems]);


    const handleBearingChange = (newValue) => {
        if (lastViewState) {
            setViewState({
                ...lastViewState,
                bearing: 360 - newValue
            });
            setLastViewState({
                ...lastViewState,
                bearing: 360 - newValue
            });

        } else {
            setViewState({
                ...viewState,
                bearing: 360 - newValue
            });
            setLastViewState({
                ...viewState,
                bearing: 360 - newValue
            });
        }
    };

    function removeCompaniesByPlaceId(placeId) {
        setGeoJson(prevCompanies => {

            // Check if prevContacts is null or not an array
            if (!Array.isArray(prevCompanies)) {
                // If prevContacts is null or not an array, simply return it as is
                return prevCompanies;
            }
            // Filter out the contact with the specified id

            const filteredCompanies = prevCompanies.filter(company => company.properties.placeId !== placeId);
            // Return the updated contacts array
            return filteredCompanies;
        });
    }

    const handleRadiusChange = (event, newValue) => {
        setRadius(newValue);
    };

    const diameterInMiles = 2 * Radius * 0.000621371;

    const toggleItem = (newItem) => {

        const index = selectedItems.findIndex(item => item.properties.placeId === newItem.properties.placeId);

        if (index > -1) {
            // Item exists, remove it
            setSelectedItems(currentItems => currentItems.filter(item => item.properties.placeId !== newItem.properties.placeId));

        } else {
            // Item does not exist, add it
            setSelectedItems(currentItems => [...currentItems, newItem]);
        }
    };




    const toggleClusterHighlights = (clusterProperties) => {

        if (isDeepEqual(clusterHighlights, clusterProperties)) {
            setClusterHighlights(null)
        }
        else {
            setClusterHighlights(clusterProperties)
        }
    };

    const setCircleCenter = (lngLat) => {

        setLatitude(lngLat[1])
        setLongitude(lngLat[0])
    }




    return (
        <Grid container spacing={2} style={{ width: '100%' }}>
            <Grid item xs={12} md={6}>
                <Box padding={2}>
                    <Button
                        onClick={() => {
                            setIsOpen(!isOpen);
                            setGetPeople(false);
                        }}
                    >
                        Search Fields
                    </Button>
                    <Collapse
                        sx={{ border: 1, borderRadius: 5, padding: 3, width: '100%' }}
                        title="Search"
                        in={isOpen}
                    >
                        <MapboxAutocompleteLatLon
                            setLatitude={setLatitude}
                            setLongitude={setLongitude}
                            setViewState={childRef?.current?.setViewState}
                        />
                        <Box sx={{ width: 300, padding: 1 }}>
                            <Typography id="input-slider" gutterBottom>
                                Search Area Size
                            </Typography>
                            <Slider
                                value={Radius}
                                onChange={handleRadiusChange}
                                aria-labelledby="input-slider"
                                min={100}
                                max={10000}
                                valueLabelDisplay="auto"
                            />
                            <Typography>Diameter: {diameterInMiles.toFixed(3)} miles</Typography>
                        </Box>
                        <TextField
                            label="Industry Type"
                            value={industryType}
                            onChange={(e) => setIndustryType(e.target.value)}
                            margin="normal"
                            fullWidth
                        />
                        <Button
                            variant="outlined"
                            onClick={async () => {
                                setIsLoading(true);
                                const result = await axios.post(
                                    `${process.env.REACT_APP_DATABASE}api/gbiz`,
                                    { Latitude, Longitude, keywords: industryType, Radius }
                                );
                                setGeoJson(result?.data?.geoJson?.features || []);
                                setNextPageToken(result?.data?.nextPageToken)
                                setIsLoading(false);
                            }}
                        >
                            Search
                        </Button>
                    </Collapse>
                    {isLoading ? (
                        <Box 
                            onClick={()=>{setIsLoading(false)}}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100vh', // Full viewport height
                                width: '100vw',  // Full viewport width
                                position: 'fixed', // Ensures it covers the entire screen
                                top: 0,
                                left: 0,
                                backgroundColor: 'rgba(255, 255, 255, 0.5)', // Optional: semi-transparent background
                                zIndex: 1000 // Ensure it appears above other elements
                            }}
                            
                        >
                            <CircularProgress size={24} />
                        </Box>
                    ) : geoJson.length > 0 ? (<>                        <List style={{ maxHeight: 'calc(70vh - 200px)', overflow: 'auto' }}>
                            {geoJson.map((item, index) => {
                                const isSelected = selectedItems.some(
                                    (selected) => selected.properties.placeId === item.properties.placeId
                                );
                                const clusterHighlightedProperties =
                                    clusterHighlights?.websites?.some(
                                        (website) => item?.properties?.Website && item?.properties?.Website === website
                                    ) ||
                                    clusterHighlights?.names?.some(
                                        (name) => item?.properties?.Name && item?.properties?.Name === name
                                    );
                                return (
                                    <CustomListItem
                                        key={index}
                                        item={item}
                                        toggleItem={toggleItem}
                                        isSelected={isSelected}
                                        clusterHighlightedProperties={clusterHighlightedProperties}
                                        getPeople={getPeople}
                                        mailListId={mailListId}
                                        contactId={contactId}
                                        removeCompany={removeCompaniesByPlaceId}
                                        itemRefs={itemRefs}
                                        MailListName={MailListName}
                                        setSearchMethod={setSearchMethod}
                                        recipients={recipients}
                                        fetchData={fetchData}
                                     
                                    />
                                );
                            })}
                        </List>
                            {nextPageToken && <Button onClick={async () => {
                                setIsLoading(true);
                                const result = await axios.post(
                                    `${process.env.REACT_APP_DATABASE}api/getNextPageAPI`,
                                    { nextPageToken}
                                );
                                
                                if (result?.data?.geoJson?.features) {
                                    setGeoJson([...geoJson, ...result?.data?.geoJson?.features]);

                                }

                              
                                setNextPageToken(result?.data?.nextPageToken)
                                setIsLoading(false);
                        }}>More Results</Button>}
                        </>
                    ) : (
                        <Typography>No data available.</Typography>
                    )}
                    <Box marginTop={2}>
                        <Button
                            disabled={!selectedItems?.length > 0}
                            variant="contained"
                            onClick={() => {
                                setGeoJson(selectedItems);
                                setGetPeople(true);
                            }}
                        >
                            Select Companies
                        </Button>
                     
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} md={6} position="relative">
               

              

                {/* Map container with margin to accommodate the slider */}
                <Box height="50vh" width="100%" position="relative" sx={{ marginTop: 6, border: 10, borderRadius: 5, overflow: 'hidden' }}>
                    <NewDeck
                        data={geoJson}
                        ref={childRef}
                        selectedItems={selectedItems}
                        toggleItem={toggleItem}
                        toggleClusterHighlights={toggleClusterHighlights}
                        clusterHighlights={clusterHighlights}
                        searchCircle={{ Radius: Radius, Latitude: Latitude, Longitude: Longitude }}
                        setCircleCenter={setCircleCenter}
                        isOpen={isOpen}
                        viewState={viewState}
                        setViewState={setViewState}
                        lastViewState={lastViewState}
                        setLastViewState={setLastViewState}
                    />
                </Box>

                {/* Slider positioned absolutely */}
                <Box sx={{
                    display: 'flex', alignItems: 'center',
                    // position: 'absolute', bottom: 0, left: 0, center: 0
                }}>
                    Rotate Map
                    <SliderControl value={viewState.bearing} onChange={handleBearingChange} />
                </Box>
            </Grid>
        </Grid>
    );


}

export default CompanySearchComponent;



export function isDeepEqual(obj1, obj2) {
    if (obj1 === obj2) {
        return true;
    }
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) {
        return false;
    }
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (let key of keys1) {
        if (!keys2.includes(key)) {
            return false;
        }
        if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
            if (!arraysEqual(obj1[key], obj2[key])) {
                return false;
            }
        } else if (!isDeepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }
    return true;
}

function arraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (let i = 0; i < arr1.length; i++) {
        if (!isDeepEqual(arr1[i], arr2[i])) {
            return false;
        }
    }
    return true;
}