import { InputComponent } from "../Common/InputComponent";
import { useQuery } from "react-query";
import Header from "../Common/Header";
// import Footer from "../Common/Footer";
import LoginButton from "../Auth0/Login";
import { SignupButton } from "../Auth0/Login";
import { CircularProgress } from "@mui/material";
export default function Validate({
  id,
  type,
  personId,
  requestorId,
  requestorEmail,
  response,
}) {
  
  if (type === "Available" || type === "avail") {
    return ValidateAvailable(
      id,
      type,
      personId,
      requestorId,
      requestorEmail,
      response
    );
  } else if (type === "building" || type === "Building") {
    return ValidateBuilding(
      id,
      type,
      personId,
      requestorId,
      requestorEmail,
      response
    );
  }
}

var campusNames = [""];
function ValidateAvailable(
  id,
  // type,
  personId,
  requestorId,
  requestorEmail,
  response
) {
  var qs =
    process.env.REACT_APP_DATABASE +
    "api/getAvailableValidate?id=" +
    id +
    "&contactId=" +
    personId;
  //test - console.log(qs);

  const fetchData = async () => {
    const result = await fetch(qs);
    return await result.json();
  };

  const {
    data: availableData,
    status: statusAvailableData,
    error: errorAvailableData,
  } = useQuery("availableData", fetchData);

  if (statusAvailableData === "loading") {
    return (
      <>
        <div className="absolute top-1/3 w-full items-center flex flex-col">
         <CircularProgress/>
        </div>
      </>
    );
  }

  if (!availableData) {
    return (
      <div>
        <div>
          <Header user={{ contact: { id: 1 } }} />
        </div>
        <div className="py-4">
          <img
            src="https://images.cubicsearch.com/noresults.png"
            className="w-full"
          />
        </div>
      </div>
    );
  }

  var nfObject = new Intl.NumberFormat("en-US");
  var nfObjectDate = new Intl.DateTimeFormat("en-US");
  var nfObjectRate = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  
  if (response === "looksgood") {
    var xhr = new XMLHttpRequest();
    xhr.open("POST", process.env.REACT_APP_MAILER + "routes/looksgood", true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(
      JSON.stringify({
        myInfo: availableData?.mycontact,
        toInfo: requestorEmail,
        PropertyInfo: availableData?.avail?.building,
        AvailableInfo: availableData?.avail,
        type: "Available",
        requestorId: requestorId,
        validate: true,
      })
    );

    return (
      <>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title></title>
        <link
          href="https://fonts.googleapis.com/css?family=Lato:300,400|Montserrat:700"
          rel="stylesheet"
          type="text/css"
        />
        <style>
          @import
          url(//cdnjs.cloudflare.com/ajax/libs/normalize/3.0.1/normalize.min.css);
          @import
          url(//maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css);
        </style>
        <link
          rel="stylesheet"
          href="https://2-22-4-dot-lead-pages.appspot.com/static/lp918/min/default_thank_you.css"
        />
        <script src="https://2-22-4-dot-lead-pages.appspot.com/static/lp918/min/jquery-1.9.1.min.js"></script>
        <script src="https://2-22-4-dot-lead-pages.appspot.com/static/lp918/min/html5shiv.js"></script>

        <body>
          <header class="site-header" id="header">
            <h1 class="site-header__title" data-lead-id="site-header-title">
              THANK YOU!
            </h1>
          </header>

          <div class="main-content">
            <i class="fa fa-check main-content__checkmark" id="checkmark"></i>
            <p class="main-content__body" data-lead-id="main-content-body">
              Thanks a bunch for confirming. It means a lot to us, just
              like you do! We really appreciate you giving us a moment of your
              time today. Thanks for being you.
            </p>
          </div>

         {/* <Footer/> */}
        </body>
      </>
    );
  } else {
    return (
      <>
        <div className="pb-4">
          <Header user={{ contact: { id: 1 } }} />
        </div>
        <div className="w-full px-4 text-xs sm:text-lg flex justify-center">
          <div className="w-full sm:w-half">
            <div className="flex flex-col items-center">
              <div className="flex flex-col text-sm items-center">
                Please update the information below
                <div>
                  <div className="flex items-center">
                    <div className="pr-3">
                      <LoginButton px={"px-2"} py={"py-0"} />
                    </div>{" "}
                    or{" "}
                    <div className="px-2">
                      <SignupButton px={"px-2"} py={"py-0"} />
                    </div>{" "}
                    to view and edit all details
                  </div>
                </div>
              </div>
              <div className="w-full sm:w-2-third justify-center ">
                <div className="py-4">
                  {availableData?.avail?.images?.length > 0 ? (
                    <img
                      className="w-full h-auto"
                      src={availableData?.avail?.images[0].img}
                    />
                  ) : (
                    ""
                  )}
                  <div className="flex flex-col items-center text-sm">
                    <div>
                      {availableData?.avail?.building?.StreetNumber +
                        " " +
                        availableData?.avail?.building?.StreetName +
                        ", " +
                        availableData?.avail?.building?.City}
                    </div>
                    <div>
                      {availableData?.avail?.Floor
                        ? (availableData?.avail?.Floor === -1 ? "Entire Building" : "Floor " + availableData?.avail?.Floor)
                        : "Please Add Floor"}{" "}
                      |{" "}
                      {availableData?.avail?.Suite
                        ? "Suite " + availableData?.avail?.Suite
                        : "Please Add Suite"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div>Size: </div>
              <div>
                <div className="flex stackOnSmall">
                  <div>
                    <InputComponent
                      inputType={"number"}
                      id={"id"}
                      name={"SizeMin"}
                      valueIn={availableData?.avail.SizeMin}
                    />
                  </div>
                  <div>
                    -
                    <InputComponent
                      inputType={"number"}
                      id={"id"}
                      name={"SizeMax"}
                      valueIn={availableData?.avail.SizeMax}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center items-center">
              <div>Date Occupancy: </div>
              <div>
                <InputComponent
                  inputType={"date"}
                  defaultShow={
                    availableData?.avail.DateOccupancy
                      ? new Date(availableData?.avail.DateOccupancy)
                      : null
                  }
                  id={"id"}
                  name={"DateOccupancy"}
                  valueIn={availableData?.avail.DateOccupancy}
                  defaultValue = {1/1/2028}
                  
                />
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div>Direct or Sublease: </div>
              <div>
                <InputComponent
                  inputType={"simpleselect"}
                  defaultShow={
                    availableData?.avail.SubleaseOrDirect
                      ? availableData?.avail.SubleaseOrDirect
                      : null
                  }
                  id={"id"}
                  options={["Sublease", "Direct"]}
                  name={"SubleaseOrDirect"}
                  valueIn={availableData?.avail.SubleaseOrDirect}
                />
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div>Condition of Space: </div>
              <div>
                <InputComponent
                  inputType={"simpleselect"}
                  defaultShow={
                    availableData?.avail.Condition
                      ? availableData?.avail.Condition
                      : null
                  }
                  id={"id"}
                  options={[
                    "Obsolescent",
                    "Shell",
                    "Under Construction",
                    "Office",
                    "2nd Generation Lab",
                    "Existing Lab",
                  ]}
                  name={"Condition"}
                  valueIn={availableData?.avail.Condition}
                />
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div>Use: </div>
              <div>
                <InputComponent
                  inputType={"simpleselect"}
                  defaultShow={
                    availableData?.avail.PropertyType
                      ? availableData?.avail.PropertyType
                      : null
                  }
                  id={"id"}
                  options={[
                    "Life Science",
                    "GMP",
                    "Industrial",
                    "R&D",
                    "Office",
                    "Retail",
                    "Other",
                  ]}
                  name={"PropertyType"}
                  valueIn={availableData?.avail.PropertyType}
                />
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div>Ask Rate: </div>
              <div>
                <InputComponent
                  inputType={"currency"}
                  id={"id"}
                  name={"AskRate"}
                  valueIn={availableData?.avail.AskRate}
                />
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div>Expenses: </div>
              <div>
                <InputComponent
                  inputType={"currency"}
                  id={"id"}
                  name={"Expenses"}
                  valueIn={availableData?.avail.Expenses}
                />
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div>Ask TI Allowance / SF: </div>
              <div>
                <InputComponent
                  inputType={"currency"}
                  id={"id"}
                  name={"AskTI"}
                  valueIn={availableData?.avail.AskTI}
                />
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div>Status: </div>
              <div>
                <InputComponent
                  inputType={"simpleselect"}
                  defaultShow={
                    availableData?.avail.Status
                      ? availableData?.avail.Status
                      : null
                  }
                  id={"id"}
                  options={["Available", "Pending", "Encumbered", "On Hold"]}
                  name={"Status"}
                  valueIn={availableData?.avail.Status}
                />
              </div>
            </div>

            <p>
              Notes:
              <textarea
                className="shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300 rounded-md w-full h-40"
                type={"textarea"}
                name={"Notes"}
                id={"Notes"}
                defaultValue={availableData?.avail.Notes}
              ></textarea>
            </p>
            <div className="pb-4">
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                onClick={async () => {
                  var data = {};
                  var info = document.getElementsByTagName("input");
                  for (var i = 1; i < info.length; i++) {
                    // 0 is a headless react thing
                    var item = info[i];
                    data[item.name] = item.value;
                  }
                  data["Notes"] = document.getElementById("Notes").value;

                  var qs1 =
                    "&&data=" + encodeURIComponent(JSON.stringify(data));
                  var resultInfo = await fetch(
                    process.env.REACT_APP_DATABASE +
                      "api/updateAvailable?id=" +
                      availableData?.avail.id +
                      qs1
                  );

                  var var2 = await resultInfo.json();
                  //test - console.log("results = " + var2);
                  var xhr = new XMLHttpRequest();
                  xhr.open(
                    "POST",
                    process.env.REACT_APP_MAILER + "routes/mailconfirm",
                    true
                  );
                  xhr.setRequestHeader("Content-Type", "application/json");
                  xhr.send(
                    JSON.stringify({
                      myInfo: availableData?.mycontact,
                      toInfo: requestorEmail,
                      PropertyInfo: availableData?.avail?.building,
                      AvailableInfo: availableData?.avail,
                      type: "Available",
                      requestorId: requestorId,
                    })
                  );
                  alert("Information Saved");
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        {/* <div className="sm:pl-20">
          <Footer />
        </div> */}
      </>
    );
  }
}

function ValidateBuilding(
  id,
  type,
  personId,
  requestorId,
  requestorEmail,
  response
) {
  var qs =
    process.env.REACT_APP_DATABASE +
    "api/getBuildingValidate?id=" +
    id +
    "&contactId=" +
    personId;
  //test - console.log(qs);

  const fetchData = async () => {
    const result = await fetch(qs);
    return await result.json();
  };

  const {
    data: buildingData,
    status: statusBuildingData,
    error: errorBuildingData,
  } = useQuery("buildingData", fetchData);

  if (statusBuildingData === "loading") {
    return (
      <>
        <div className="absolute top-1/3 w-full items-center flex flex-col">
          <img style={{width: 100}} src="https://images.cubicsearch.com/Wait.gif" />
          <div>Loading Building Data...</div>
        </div>
      </>
    );
  }

  buildingData?.campusNames.map((campusName) => {
    campusNames.push(campusName.CampusName);
  });

  if (!buildingData) {
    return (
      <div>
        <div>
          <Header user={{ contact: { id: 1 } }} />
        </div>
        <div className="py-4">
          <img
            src="https://images.cubicsearch.com/noresults.png"
            className="w-full"
          />
        </div>
      </div>
    );
  }

  var nfObject = new Intl.NumberFormat("en-US");
  var nfObjectDate = new Intl.DateTimeFormat("en-US");
  var nfObjectRate = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  if (response === "looksgood") {
    var xhr = new XMLHttpRequest();
    xhr.open("POST", process.env.REACT_APP_MAILER + "routes/looksgood", true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(
      JSON.stringify({
        myInfo: buildingData?.mycontact,
        toInfo: requestorEmail,
        PropertyInfo: buildingData?.building,
        AvailableInfo: null,
        type: "Building",
        requestorId: requestorId,
        validate: true,
      })
    );

    return (
      <>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title></title>
        <link
          href="https://fonts.googleapis.com/css?family=Lato:300,400|Montserrat:700"
          rel="stylesheet"
          type="text/css"
        />
        <style>
          @import
          url(//cdnjs.cloudflare.com/ajax/libs/normalize/3.0.1/normalize.min.css);
          @import
          url(//maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css);
        </style>
        <link
          rel="stylesheet"
          href="https://2-22-4-dot-lead-pages.appspot.com/static/lp918/min/default_thank_you.css"
        />
        <script src="https://2-22-4-dot-lead-pages.appspot.com/static/lp918/min/jquery-1.9.1.min.js"></script>
        <script src="https://2-22-4-dot-lead-pages.appspot.com/static/lp918/min/html5shiv.js"></script>

        <body>
          <header class="site-header" id="header">
            <h1 class="site-header__title" data-lead-id="site-header-title">
              THANK YOU!
            </h1>
          </header>

          <div class="main-content">
            <i class="fa fa-check main-content__checkmark" id="checkmark"></i>
            <p class="main-content__body" data-lead-id="main-content-body">
              Thanks a bunch for confirming. It means a lot to us, just
              like you do! We really appreciate you giving us a moment of your
              time today. Thanks for being you.
            </p>
          </div>

         {/* <Footer/> */}
        </body>
      </>
    );
  } else {
    return (
      <>
        <div className="pb-4">
          <Header user={{ contact: { id: 1 } }} />
        </div>
        <div className="w-full px-4 text-xs sm:text-lg flex justify-center">
          <div className="w-full sm:w-half">
            <div className="flex flex-col items-center">
              <div className="flex flex-col text-sm items-center">
                Please update the information below
                <div>
                  <div className="flex items-center">
                    <div className="pr-3">
                      <LoginButton px={"px-2"} py={"py-0"} />
                    </div>{" "}
                    or{" "}
                    <div className="px-2">
                      <SignupButton px={"px-2"} py={"py-0"} />
                    </div>{" "}
                    to view and edit all details
                  </div>
                </div>
              </div>
              <div className="w-full sm:w-2-third justify-center ">
                <div className="py-4">
                  {buildingData?.building?.images?.length > 0 ? (
                    <img
                      className="w-full h-auto"
                      src={buildingData?.building?.images[0].img}
                    />
                  ) : (
                    ""
                  )}
                  <div className="flex flex-col items-center text-sm">
                    <div>
                      {buildingData?.building?.StreetNumber +
                        " " +
                        buildingData?.building?.StreetName +
                        ", " +
                        buildingData?.building?.City}
                    </div>
                    {/* <div>
                      {buildingData?.building?.Size
                        ? "Floor " + buildingData?.building?.Size
                        : "Please Add Size"}{" "}
                      |{" "}
                      {buildingData?.building?.YearBuilt
                        ? "Suite " + buildingData?.building?.YearBuilt
                        : "Please Add YearBuilt"}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div>Size: </div>
              <div>
                <div className="flex stackOnSmall">
                  <div>
                    <InputComponent
                      inputType={"number"}
                      id={"id"}
                      name={"Size"}
                      valueIn={buildingData?.building.Size}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center items-center">
              <div>Year Built: </div>
              <div>
                <InputComponent
                  inputType={"text"}
                  defaultShow={
                    buildingData?.building.YearBuilt
                      ? new Date(buildingData?.building.YearBuilt)
                      : null
                  }
                  id={"id"}
                  name={"YearBuilt"}
                  valueIn={buildingData?.building.YearBuilt}
                />
              </div>
            </div>
            <div className="flex justify-between items-center items-center">
              <div>Year Renovated: </div>
              <div>
                <InputComponent
                  inputType={"text"}
                  defaultShow={
                    buildingData?.building.YearRenovated
                      ? new Date(buildingData?.building.YearRenovated)
                      : null
                  }
                  id={"id"}
                  name={"YearRenovated"}
                  valueIn={buildingData?.building.YearRenovated}
                />
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div>Construction Status: </div>
              <div>
                <InputComponent
                  inputType={"simpleselect"}
                  defaultShow={
                    buildingData?.building.ConstructionStatus
                      ? buildingData?.building.ConstructionStatus
                      : null
                  }
                  id={"id"}
                  options={[
                    "Planning",
                    "Approved",
                    "Site Work",
                    "Under Construction",
                    "Shell Complete",
                    "Existing",
                  ]}
                  name={"ConstructionStatus"}
                  valueIn={buildingData?.building.ConstructionStatus}
                />
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div>Campus Name: </div>
              <div>
                <InputComponent
                  inputType={"simpleselect"}
                  defaultShow={
                    buildingData?.building.CampusName
                      ? buildingData?.building.CampusName
                      : null
                  }
                  id={"id"}
                  options={campusNames.sort()}
                  name={"campusNames"}
                  valueIn={buildingData?.building.CampusName}
                />
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div>Property Type: </div>
              <div>
                <InputComponent
                  inputType={"simpleselect"}
                  defaultShow={
                    buildingData?.building.PropertyType
                      ? buildingData?.building.PropertyType
                      : null
                  }
                  id={"id"}
                  options={[
                    "Life Science",
                    "GMP",
                    "Industrial",
                    "R&D",
                    "Office",
                    "Retail",
                    "Other",
                  ]}
                  name={"PropertyType"}
                  valueIn={buildingData?.building.PropertyType}
                />
              </div>
            </div>

            <div className="flex justify-between items-center">
              <div>Property Name: </div>
              <div>
                <InputComponent
                  inputType={"text"}
                  defaultShow={
                    buildingData?.building.PropertyName
                      ? buildingData?.building.PropertyName
                      : null
                  }
                  id={"id"}
                  name={"PropertyName"}
                  valueIn={buildingData?.building.PropertyName}
                />
              </div>
            </div>

            <p>
              Notes:
              <textarea
                className="shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300 rounded-md w-full h-40"
                type={"textarea"}
                name={"Notes"}
                id={"Notes"}
                defaultValue={buildingData?.building.Notes}
              ></textarea>
            </p>
            <div className="pb-4">
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                onClick={async () => {
                  var data = {};
                  var info = document.getElementsByTagName("input");
                  for (var i = 1; i < info.length; i++) {
                    // 0 is a headless react thing
                    var item = info[i];
                    data[item.name] = item.value;
                  }
                  data["Notes"] = document.getElementById("Notes").value;

                  var qs1 =
                    "&&data=" + encodeURIComponent(JSON.stringify(data));
                  var resultInfo = await fetch(
                    process.env.REACT_APP_DATABASE +
                      "api/updateBuilding?id=" +
                      buildingData?.building.id +
                      qs1
                  );

                  var var2 = await resultInfo.json();
                  //test - console.log("results = " + var2);
                  var xhr = new XMLHttpRequest();
                  xhr.open(
                    "POST",
                    process.env.REACT_APP_MAILER + "routes/mailconfirm",
                    true
                  );
                  xhr.setRequestHeader("Content-Type", "application/json");
                  xhr.send(
                    JSON.stringify({
                      myInfo: buildingData?.mycontact,
                      toInfo: requestorEmail,
                      PropertyInfo: buildingData?.building,
                      type: "Building",
                      requestorId: requestorId,
                      AvailableInfo: null,
                    })
                  );
                  alert("Information Saved");
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        {/* <div className="sm:pl-20">
          <Footer />
        </div> */}
      </>
    );
  }
}
