import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Link as LinkIcon, Print as PrinterIcon } from "@mui/icons-material";

import { useRef } from "react";

import InfoCards from "../Search/InfoCards";
import { useReactToPrint } from "react-to-print";
import { Map } from "react-map-gl";

import { Marker } from "react-map-gl";
import { formatPhoneNumber } from "./FormatPhoneNumber";


export default function PrintSurvey({
  open,
  setOpen,
  searchResults,
  setSearchResults,
  bounds,
  refs,
  mapButton,
  selectedLocation,
  showMarkerPopup,
  showPropertyModal,
  confirm,
  user,
  showPrice,
  surveyName,
  surveyId,
}) {
  // const [open, setOpen] = useState(true);

  const componentRef = useRef(null);
  const mapRef = useRef(null);
  const listRef = useRef(null);

  const [showInfo, setShowInfo] = useState("hidden");
  const handlePrint = useReactToPrint({
    content: () => mapRef.current,
    
  });
  const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX

  
  if (!searchResults || searchResults.length === 0) {
    return (<></>)
  }
  var pins = // useMemo used to be here for some reason.    Took it out so the dots would go away.
    searchResults.map((result, index) => (
      <Marker
        key={result.id}
        longitude={result.Longitude}
        latitude={result.Latitude}
        anchor="bottom"
        onClick={(e) => {
          // If we let the click event propagates to the map, it will immediately close the popup
          // with `closeOnClick: true`
          // onLocationChanged(result);
          e.originalEvent.stopPropagation();
          // onMarkerClick(result);
        }}
      >
        <div className="flex justify-end w-full">
          <div className="flex items-center border-2 justify-center text-white rounded-full bg-red-600 w-6 h-6">
            {index + 1}{" "}
          </div>
        </div>
      </Marker>
    ));

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-102" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-y-auto  rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-2-third sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <PrinterIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Print Survey
                    </Dialog.Title>
                    <button
                      className="inline-flex items-center justify-center rounded-md border bg-white px-4 py-2 text-xs font-medium text-red-600 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-30"
                      onClick={() => {
                        handlePrint();
                      }}
                    >
                      Print
                    </button>

                    <div >
                      <div className="flex justify-center">
                        <div ref={mapRef} className="p-2 w-200 h-180 " style={{ paddingTop: 100 , paddingLeft: 30, paddingRight: 20, paddingBottom: 20}} >
                          <header>
                            <div className="flex flex-col items-start">
                              <img
                                className="flex flex-col h-10 w-20 object-contain items-start"
                                src={
                                  user.contact.office &&
                                  user.contact.office.company
                                    ? user.contact.office.company.images[0].img
                                    : "https://images.cubicsearch.com/CompanyLogo.png"
                                }
                                alt=""
                              />
                              <div className="px-4">
                                {user.contact.FirstName +
                                  " " +
                                  user.contact.LastName}
                              </div>
                              <div className="px-4">{user.contact.Email}</div>
                              <div className="px-4">
                                {formatPhoneNumber(user.contact.Phone)}
                              </div>
                            </div>
                          </header>
                          <div className="flex text-lg justify-center py-4">
                            {" "}
                            {surveyName ? surveyName : "Property Survey"}
                          </div>
                          <div  style={{width:'100%', height: '100%'}} className="px-10 py-10 border-2 rounded-xl   ">
                            <Map
                              mapStyle="mapbox://styles/schordr/ckvq4te666xrm14lq6wmj1fi0"
                              initialViewState={
                                bounds
                                  ? {
                                      bounds: [
                                        {
                                          lat: bounds.minLat,
                                          lng: bounds.minLng,
                                        },
                                        {
                                          lat: bounds.maxLat,
                                          lng: bounds.maxLng,
                                        },
                                      ],
                                      fitBoundsOptions: {
                                        padding: 50,
                                        maxZoom: 15,
                                      },
                                    }
                                  : {
                                      bounds: [
                                        { lat: 37.562937, lng: -122.433014 },
                                        { lat: 37.662937, lng: -122.300014 },
                                      ],
                                      fitBoundsOptions: {
                                        padding: 50,
                                        maxZoom: 12,
                                      },
                                    }
                              }
                              mapboxAccessToken={MAPBOX_TOKEN}
                              preserveDrawingBuffer={true}
                            >
                              {pins}
                            </Map>
                            {surveyId ? (
                              <>
                                <div className="flex flex-col">
                                  <div className="px-2 flex justify-center">
                                    <div className="h-4 w-4">
                                      <LinkIcon></LinkIcon>
                                    </div>
                                    <a
                                      href={
                                        "https://www.cubicsearch.com/search?surveyId=" +
                                        surveyId
                                      }
                                      className="underline text-blue"
                                    >
                                      Cubic Search Survey Link
                                    </a>
                                  </div>
                                  <div className="text-xs flex justify-center pl-4">
                                    {surveyId
                                      ? "https://www.cubicsearch.com/search?surveyId=" +
                                        surveyId
                                      : ""}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div ref={listRef} className="py-10">
                            <InfoCards
                              searchResults={searchResults}
                              setSearchResults={setSearchResults}
                              
                              selectedLocation={selectedLocation}
                              refs={refs}
                              onHover={showMarkerPopup}
                              onClick={showPropertyModal}
                              hideMe={!mapButton}
                              remove={true}
                              confirm={confirm}
                              contactId={user.contact.id}
                              printing={true}
                            />
                          </div>
                          <footer>
                            Cubic Search Survey Printed{" "}
                            {new Date().toLocaleDateString()}
                          </footer>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
