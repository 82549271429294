import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Button, Container, Chip, TextField, Typography, CircularProgress, Alert } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { Logout } from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';

const RequestEmailVerification = ({emailIn}) => {
   
    const [email, setEmail] = useState(emailIn);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const {logout} = useAuth0()

    const handleSendVerificationEmail = async () => {
        setLoading(true);
        setError(null);
        setSuccess(null);
        try {
            await axios.post(process.env.REACT_APP_DATABASE + 'api/resendVerification', { email });
            setSuccess('Verification email sent. Please check your email.');
        } catch (error) {
            console.error('Error sending verification email:', error);
            setError('Failed to send verification email. Please try again.');
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        setEmail(emailIn)
    }, [emailIn])

    return (
        <Container maxWidth="sm">
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100vh"
                textAlign="center"
            >
                <img src={"https://images.cubicsearch.com/Logos/Logo%20Transparent.png"} style={{ width: 300, marginBottom: 20 }} />
                <Typography variant="h4" gutterBottom>
                    Verification Email Sent
                
                </Typography>
                <Typography variant='notes'>Please check your email.  You are partially logged in, but you have yet to confirm your email,
                    and we have to make sure its really you!  If you need another email, click below.</Typography>
                {error && <Alert severity="error">{error}</Alert>}
                {success && <Alert severity="success">{success}</Alert>}
                {email? <Chip label={email} />: <TextField
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    fullWidth
                    margin="normal"
                    variant="outlined"
              
                />}
                <Box mt={2}>
                    <Button
                        sx={{margin: 4}}
                        variant="contained"
                        color="primary"
                        onClick={handleSendVerificationEmail}
                        startIcon={<EmailIcon />}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Send Another Verification Email'}
                    </Button>

                    <Button
                        sx={{ margin: 4 }}
                        variant="outlined"
                        color="primary"
                        onClick={()=>{logout({returnTo: window.location.origin})}}
                        startIcon={<Logout />}
                       
                    >
                       Change Account
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default RequestEmailVerification;
