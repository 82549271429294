import React from "react";
import Client from "../components/Clients/Client.js";
//import BingMapsReact from "bingmaps-react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import MapSinglePoint from "../components/Search/MapSinglePoint.tsx";
import Header from "../components/Common/Header.js";

export default function ClientInfo({ user }) {
  let [query, setQuery] = useSearchParams();
  let id = query.get("id");
  var qs =
    process.env.REACT_APP_DATABASE + "api/getClient?id=" +
    id +
    "&contactId=" +
    user.contact.id;
  var qsInvitedTo =
    process.env.REACT_APP_DATABASE + "api/getGroupsInvitedTo?id=" +
    user.contact.id;

  const fetchData = async () => {
    //test - console.log("fetch " + qs)
    const result = await fetch(qs);
   
    return await result.json();
  };

  const fetchGroupsInvitedToData = async () => {
    //test - console.log("fetch " + qsInvitedTo)
    const result = await fetch(qsInvitedTo);

    return await result.json();
  };

  const fetchPeopleData = async () => {
    //test - console.log("fetch " + process.env.REACT_APP_DATABASE + "api/getContacts?contactId=" +
    // user.contact.id)
    const result = await fetch(
      process.env.REACT_APP_DATABASE + "api/getContacts?contactId=" +
        user.contact.id
    );
    return await result.json();
  };

  //test - console.log(qs);
  const {
    data: clientDataArray,
    status: statusClientData,
    error: errorClientData,
  } = useQuery("clientData", fetchData);
  const {
    data: peopleDataArray,
    status: statusPeopleData,
    error: errorPeopleData,
  } = useQuery("peopleData", fetchPeopleData);

  const {
    data: groupsInvitedToData,
    status: statusGroupsInvitedToData,
    error: errorGroupsInvitedToData,
  } = useQuery("groupsInvitedToData", fetchGroupsInvitedToData);

  if (statusClientData === "loading"){
    return (
      <>
        <div className="absolute top-1/3 w-full items-center flex flex-col">
          <img style={{width: 100}} src="https://images.cubicsearch.com/Wait.gif" />
          <div>Loading Contacts Data...</div>
        </div>
      </>
    );
  }

  if ( statusPeopleData === "loading") {
    return (
      <>
        <div className="absolute top-1/3 w-full items-center flex flex-col">
          <img style={{width: 100}} src="https://images.cubicsearch.com/Wait.gif" />
          <div>Loading Contacts Data...</div>
        </div>
      </>
    );
  }

  if (statusClientData === "error" || statusPeopleData === "error") {
    return errorClientData.message;
  }

  if (statusGroupsInvitedToData === "error") {
    return "Groups Invite To Error:" + errorGroupsInvitedToData.message;
  }

  if (
    !clientDataArray || !clientDataArray.client || 
    clientDataArray.client.length < 1 ||
    !peopleDataArray ||
    clientDataArray.length < 1
  ) {
    return (
      <div>
        <div>
          <Header user={user} />
        </div>
        <div className="py-4">
          <img src="https://images.cubicsearch.com/noresults.png" className="w-full"/>
        </div>
      </div>
    );
  }

  // const clientData = clientDataArray.client[0];
  const clientData = clientDataArray.client;
  const groups = clientDataArray.groups;
  const people = getContacts(peopleDataArray);

  const pushPin = {
    center: {
      latitude: clientData.Latitude,
      longitude: clientData.Longitude,
    },
    options: {
      title:
        clientData.StreetNumber +
        " " +
        clientData.StreetName +
        ", " +
        clientData.City,
    },
  };

  return (
    <div>
      <div>
        <Client
          ClientInformation={clientData}
          peopleCombo={people}
          user={user}
          groups={groups}
          groupsCombo={groupsInvitedToData}
        />
      </div>
    
    </div>
  );
}

function getContacts(data) {
  var peopleArray = [];
  //   const data = await searchResults.json();
  data?.contact?.map((person) => {
    var myJsonToAdd = {};
    if (person.images.length > 0) {
      if (person) {
        myJsonToAdd = {
          id: person.id,
          name:
            person.FirstName +
            " " +
            person.LastName +
            " - " +
            checkOffice(person),
          imageURL: person.images[0].img,
        };
      } else {
        myJsonToAdd = {
          id: person.id,
          name: person.FirstName + " " + person.LastName,
          imageURL: person.images[0].img,
        };
      }
    } else {
      if (person.office) {
        myJsonToAdd = {
          id: person.id,
          name:
            person.FirstName +
            " " +
            person.LastName +
            " - " +
            checkOffice(person),
          imageURL:
            "https://images.cubicsearch.com/UserLogo.png",
        };
      } else {
        myJsonToAdd = {
          id: person.id,
          name: person.FirstName + " " + person.LastName,
          imageURL:
            "https://images.cubicsearch.com/UserLogo.png",
        };
      }
    }
    peopleArray.push(myJsonToAdd);
  });

  return peopleArray.sort(sortByClient("name"));
}

function sortByClient(client) {
  return function (a, b) {
    if (a[client] > b[client]) return 1;
    else if (a[client] < b[client]) return -1;

    return 0;
  };
}

function checkOffice(person) {
  if (person.office && person.office.company) {
    return person.office.company.Name;
  } else return "Add Company";
}
