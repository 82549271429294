/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";

import MyGroupsLookupComboBox from "./MyGroupsLookupComboBox";
// import GroupEdit from '../Groups/GroupEdit'
import MyGroupNew from "./MyGroupNew";
import GroupPermissionCombo from "./GroupPermissionCombo";
import axios from "axios";


const fetchGroupsInvitedToData = async (qsInvitedTo) => {
  return new Promise(async function (resolve, reject) {
    const result = await axios.get(qsInvitedTo)
      .catch((error) => {
        console.log(error)
        reject(error)
      })
    resolve(result.data)
  })

}

export default  function MyGroupSelectModal({
  open,
  setOpen,

  groupsIn,
  setGroupsIn,
  entityId,
  contactId,
  triggerSetItems,
  entity,
  highestPermission,
  setData,
  setGroups,
}) {
  const [loadingGroups, setLoadingGroups] = useState(true);
  const [buttonText, setButtonText] = useState("Select Group");
  const [newGroupName, setNewGroupName] = useState("Enter Group Name");
  const [groupsInvitedState, setGroupsInvitedState] = useState([])

  const cancelButtonRef = useRef(null);
  const [selectedGroup, setSelectedGroup] = useState(
    groupsIn.length > 0 ? { groupId: groupsIn[0].id, name: groupsIn[0].GroupName } : null
  );
  const [addGroup, setAddGroup] = useState(false);
  const [selectedGroupPermission, setSelectedGroupPermission] = useState({
    id: 1,
    name: "View",
  });

  var qsGroups =
    process.env.REACT_APP_DATABASE + "api/getGroupsInvitedTo?id=" + contactId;
   
 
  useEffect(() => { // this will run once and all the loading and data will get filled async


    fetchGroupsInvitedToData(qsGroups).then((groupsData) => {
      
      setGroupsInvitedState(groupsData)
    }).catch((err) => {
      //test - console.log(err);
    }).finally(() => {
      setLoadingGroups(false)
    })




  }, [open]);


  if (loadingGroups) {
    return (
    <>Loading...</>
  )
  }

  var groupsCombo = groupsInvitedState
  if (highestPermission !== "Lead Edit" && highestPermission !== "Edit") {
    groupsCombo.map((group, index) => {
      if (group.id === 1) {
        groupsCombo.splice(index, 1);
        //
      }
    });
  }


  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <img
                    src="https://images.cubicsearch.com/GroupImage.png"
                    className="h-6 w-6 text-red-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Select or Add Group
                  </Dialog.Title>
                  <div className="mt-2">
                    <MyGroupsLookupComboBox
                      groupsCombo={groupsCombo}
                      selectedGroup={selectedGroup}
                      setSelectedGroup={setSelectedGroup}
                      setButtonText={setButtonText}
                      setAddGroup={setAddGroup}
                      setNewGroupName={setNewGroupName}
                    />
                  </div>
                  <div>
                    <GroupPermissionCombo
                      selectedGroupPermission={selectedGroupPermission}
                      setSelectedGroupPermission={setSelectedGroupPermission}
                      highestPermission={highestPermission}
                    />
                  </div>
                </div>
              </div>
              {/* <div>{newGroupName}{addGroup.toString()}</div> */}
              {addGroup ? (
                <div>
                  <div className="py-2 text-center">Create A New Group</div>
                  <MyGroupNew
                    newGroupName={newGroupName}
                    NameIn={selectedGroup}
                  />
                </div>
              ) : (
                ""
              )}
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm"
                  onClick={() => {
                    //test - console.log("button pushed");
                    //test - console.log(selectedGroup);
                    //test - console.log(entityId);
                    //test - console.log(entity);
                    if (buttonText === "Add New Group") {
                      //test - console.log("Add a new group here");
                      var group = {
                        GroupName: document.getElementById("GroupName").value,
                        Notes: document.getElementById("Notes").value,
                        MembersVisible:
                          document.getElementById("MembersVisible").value,
                        // 'contactId' : contactId,
                      };

                      newGroup(
                        entityId,
                        contactId,
                        group,
                        setGroupsIn,
                        triggerSetItems,
                        selectedGroupPermission.name,
                        entity,
                        setData
                      );
                      setOpen(false);
                    } else {
                      addGroupDB(
                        selectedGroup.id,
                        entityId,
                        contactId,
                        setGroupsIn,
                        groupsIn,
                        triggerSetItems,
                        selectedGroupPermission.name,
                        entity,
                        setData,
                        setGroups
                      );
                      // window.location.href = window.location.href
                      setOpen(false);
                    }
                  }}
                >
                  {buttonText}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

async function newGroup(
  entityId,
  contactId,
  group,
  setGroupsIn,
  triggerSetItems,
  groupPermission,
  entity,
  setData
) {
  //test - console.log(entity + entityId);
  if (entity === "available") {
    var fetchLocation =
      process.env.REACT_APP_DATABASE +
      "api/createAvailableGroup?availId=" +
      entityId +
      "&contactId=" +
      contactId +
      "&groupPermission=" +
      groupPermission +
      "&" +
      Object.keys(group)
        .map((key) => key + "=" + encodeURIComponent(group[key]))
        .join("&");

        // //test - console.log(fetchLocation)
    // alert(fetchLocation)
  

    var result = await fetch(fetchLocation);
    //test - console.log(fetchLocation);
    var qsAvailableOnly =
      process.env.REACT_APP_DATABASE + "api/getAvailableOnly?id=";
    var qsAvailableContactsOnly =
      process.env.REACT_APP_DATABASE + "api/getAvailableContactsOnly?id=";
    var qsAvailableJournalsOnly =
      process.env.REACT_APP_DATABASE + "api/getAvailableJournalsOnly?id=";
    //var qsAvailableWebsitesOnly = process.env.REACT_APP_DATABASE + "api/getAvailableWebsitesOnly?id=" + id + "&contactId=" + user.contact.id

    const availableJournalsOnly = await fetch(
      qsAvailableJournalsOnly + entityId + "&contactId=" + contactId
    );
    const availableJournals = await availableJournalsOnly.json();

    const availableContactsOnly = await fetch(
      qsAvailableContactsOnly + entityId + "&contactId=" + contactId
    );
    const availableContacts = await availableContactsOnly.json();

    const availableOnly = await fetch(
      qsAvailableOnly + entityId + "&contactId=" + contactId
    );
    const available = await availableOnly.json();

    fetchLocation =
      process.env.REACT_APP_DATABASE + "api/getAvailableWebsitesOnly?id=";
    const finalSearchResults = await fetch(
      fetchLocation + entityId + "&contactId=" + contactId
    );
    const finalData = await finalSearchResults.json();

    available.avail.generic_journals = availableJournals.journals;
    available.avail.contacts = availableContacts.contacts;
    available.avail.websites = finalData.websites;
    // available.avail.groups = available.avail.avail_groups

    setData(available.avail);
    var data = available;
    var myJson = data.groups; // search results come in an array, and the element is also an array
    // 
    // var sort = orderAvailGroups(myJson.sort((a,b)=> a.avail_group.sortOrder - b.avail_group.sortOrder))
    setGroupsIn(
      myJson.sort((a, b) => a.avail_groups.sortOrder - b.avail_groups.sortOrder)
    );
    triggerSetItems(
      myJson.sort((a, b) => a.avail_groups.sortOrder - b.avail_groups.sortOrder)
    );
  } else if (entity === "building") {
    var fetchLocation =
      process.env.REACT_APP_DATABASE +
      "api/createBuildingGroup?buildingId=" +
      entityId +
      "&contactId=" +
      contactId +
      "&groupPermission=" +
      groupPermission +
      "&" +
      Object.keys(group)
        .map((key) => key + "=" + encodeURIComponent(group[key]))
        .join("&");
    var result = await fetch(fetchLocation);
    //test - console.log(fetchLocation);
    fetchLocation = process.env.REACT_APP_DATABASE + "api/getBuilding?id=";
    const searchResults = await fetch(
      fetchLocation + entityId + "&contactId=" + contactId
    );
    const data = await searchResults.json();
    data.building.avails = data.avails;
    setData(data.building);
    var myJson = data.groups; // search results come in an array, and the element is also an array
    // var sort = orderAvailGroups(myJson.sort((a,b)=> a.avail_group.sortOrder - b.avail_group.sortOrder))
    setGroupsIn(
      myJson.sort(
        (a, b) => a.building_groups.sortOrder - b.building_groups.sortOrder
      )
    );
    triggerSetItems(
      myJson.sort(
        (a, b) => a.building_groups.sortOrder - b.building_groups.sortOrder
      )
    );
  } else if (entity === "project") {
    var fetchLocation =
      process.env.REACT_APP_DATABASE +
      "api/createProjectGroup?projectId=" +
      entityId +
      "&contactId=" +
      contactId +
      "&groupPermission=" +
      groupPermission +
      "&" +
      Object.keys(group)
        .map((key) => key + "=" + encodeURIComponent(group[key]))
        .join("&");
    var result = await fetch(fetchLocation);
    //test - console.log(fetchLocation);
    fetchLocation = process.env.REACT_APP_DATABASE + "api/getProject?id=";
    const searchResults = await fetch(
      fetchLocation + entityId + "&contactId=" + contactId
    );
    const data = await searchResults.json();
    setData(data);
    var myJson = data.groups; // search results come in an array, and the element is also an array
    // var sort = orderAvailGroups(myJson.sort((a,b)=> a.avail_group.sortOrder - b.avail_group.sortOrder))
    setGroupsIn(
      myJson.sort(
        (a, b) => a.project_groups.sortOrder - b.project_groups.sortOrder
      )
    );
    triggerSetItems(
      myJson.sort(
        (a, b) => a.project_groups.sortOrder - b.project_groups.sortOrder
      )
    );
  } else if (entity === "client") {
    var fetchLocation =
      process.env.REACT_APP_DATABASE +
      "api/createClientGroup?clientId=" +
      entityId +
      "&contactId=" +
      contactId +
      "&groupPermission=" +
      groupPermission +
      "&" +
      Object.keys(group)
        .map((key) => key + "=" + encodeURIComponent(group[key]))
        .join("&");
    var result = await fetch(fetchLocation);
    //test - console.log(fetchLocation);
    fetchLocation = process.env.REACT_APP_DATABASE + "api/getClient?id=";
    const searchResults = await fetch(
      fetchLocation + entityId + "&contactId=" + contactId
    );
    const data = await searchResults.json();
    setData(data);
    var myJson = data.groups; // search results come in an array, and the element is also an array
    // var sort = orderAvailGroups(myJson.sort((a,b)=> a.avail_group.sortOrder - b.avail_group.sortOrder))
    setGroupsIn(
      myJson.sort(
        (a, b) => a.client_groups.sortOrder - b.client_groups.sortOrder
      )
    );
    triggerSetItems(
      myJson.sort(
        (a, b) => a.client_groups.sortOrder - b.client_groups.sortOrder
      )
    );
  } else if (entity === "survey") {
    var fetchLocation =
      process.env.REACT_APP_DATABASE +
      "api/createSurveyGroup?surveyId=" +
      entityId +
      "&contactId=" +
      contactId +
      "&groupPermission=" +
      groupPermission +
      "&" +
      Object.keys(group)
        .map((key) => key + "=" + encodeURIComponent(group[key]))
        .join("&");
    var result = await fetch(fetchLocation);
    //test - console.log(fetchLocation);
    fetchLocation = process.env.REACT_APP_DATABASE + "api/getSurvey?id=";
    const searchResults = await fetch(
      fetchLocation + entityId + "&contactId=" + contactId
    );
    const data = await searchResults.json();
    setData(data);
    var myJson = data.groups; // search results come in an array, and the element is also an array
    // var sort = orderAvailGroups(myJson.sort((a,b)=> a.avail_group.sortOrder - b.avail_group.sortOrder))
    setGroupsIn(
      myJson.sort(
        (a, b) => a.survey_groups.sortOrder - b.survey_groups.sortOrder
      )
    );
    triggerSetItems(
      myJson.sort(
        (a, b) => a.survey_groups.sortOrder - b.survey_groups.sortOrder
      )
    );
  }
}
async function addGroupDB(
  groupId,
  entityId,
  contactId,
  setGroupsIn,
  groupsIn,
  triggerSetItems,
  groupPermission,
  entity,
  setData,
  setGroups
) {
  if (entity === "available") {
    
    var fetchLocation =
      
      process.env.REACT_APP_DATABASE +
      "api/addAvailableGroup?availId=" +
      entityId +
      "&&groupId=" +
      groupId +
      "&groupPermission=" +
      groupPermission +
      "&contactId=" +
      contactId;
    //test - console.log(fetchLocation);
    const resultInfo = await fetch(fetchLocation);
    var qsAvailableOnly =
      process.env.REACT_APP_DATABASE + "api/getAvailableOnly?id=";
    var qsAvailableContactsOnly =
      process.env.REACT_APP_DATABASE + "api/getAvailableContactsOnly?id=";
    var qsAvailableJournalsOnly =
      process.env.REACT_APP_DATABASE + "api/getAvailableJournalsOnly?id=";
    //var qsAvailableWebsitesOnly = process.env.REACT_APP_DATABASE + "api/getAvailableWebsitesOnly?id=" + id + "&contactId=" + user.contact.id

    const availableJournalsOnly = await fetch(
      qsAvailableJournalsOnly + entityId + "&contactId=" + contactId
    );
    const availableJournals = await availableJournalsOnly.json();

    const availableContactsOnly = await fetch(
      qsAvailableContactsOnly + entityId + "&contactId=" + contactId
    );
    const availableContacts = await availableContactsOnly.json();

    const availableOnly = await fetch(
      qsAvailableOnly + entityId + "&contactId=" + contactId
    );
    const available = await availableOnly.json();

    fetchLocation =
      process.env.REACT_APP_DATABASE + "api/getAvailableWebsitesOnly?id=";
    const finalSearchResults = await fetch(
      fetchLocation + entityId + "&contactId=" + contactId
    );
    const finalData = await finalSearchResults.json();

    available.avail.generic_journals = availableJournals.journals;
    available.avail.contacts = availableContacts.contacts;
    available.avail.websites = finalData.websites;

    setData(available);
    var data = available;
    var myJson = data.groups; // search results come in an array, and the element is also an array
    // var sort = orderAvailGroups(myJson.sort((a,b)=> a.avail_group.sortOrder - b.avail_group.sortOrder))
    setGroupsIn(
      myJson.sort((a, b) => a.avail_groups.sortOrder - b.avail_groups.sortOrder)
    );
    triggerSetItems(
      myJson.sort((a, b) => a.avail_groups.sortOrder - b.avail_groups.sortOrder)
    );
  } else if (entity === "building") {
    var fetchLocation =
      process.env.REACT_APP_DATABASE +
      "api/addBuildingGroup?buildingId=" +
      entityId +
      "&&groupId=" +
      groupId +
      "&groupPermission=" +
      groupPermission +
      "&contactId=" +
      contactId;
    //test - console.log(fetchLocation);
    const resultInfo = await fetch(fetchLocation);
    fetchLocation = process.env.REACT_APP_DATABASE + "api/getBuilding?id=";
    const searchResults = await fetch(
      fetchLocation + entityId + "&contactId=" + contactId
    );
    const data = await searchResults.json();
    data.building.avails = data.avails;
    setData(data.building);
    var myJson = data.groups; // search results come in an array, and the element is also an array
    // var sort = orderAvailGroups(myJson.sort((a,b)=> a.avail_group.sortOrder - b.avail_group.sortOrder))
    setGroupsIn(
      myJson.sort(
        (a, b) => a.building_groups.sortOrder - b.building_groups.sortOrder
      )
    );
    triggerSetItems(
      myJson.sort(
        (a, b) => a.building_groups.sortOrder - b.building_groups.sortOrder
      )
    );
  } else if (entity === "client") {
    var fetchLocation =
      process.env.REACT_APP_DATABASE +
      "api/addClientGroup?clientId=" +
      entityId +
      "&&groupId=" +
      groupId +
      "&groupPermission=" +
      groupPermission +
      "&contactId=" +
      contactId;
    //test - console.log(fetchLocation);
    const resultInfo = await fetch(fetchLocation);
    fetchLocation = process.env.REACT_APP_DATABASE + "api/getClient?id=";
    const searchResults = await fetch(
      fetchLocation + entityId + "&contactId=" + contactId
    );
    const data = await searchResults.json();
    setData(data.client);
    var myJson = data.groups; // search results come in an array, and the element is also an array
    // var sort = orderAvailGroups(myJson.sort((a,b)=> a.avail_group.sortOrder - b.avail_group.sortOrder))
    setGroupsIn(
      myJson.sort(
        (a, b) => a.client_groups.sortOrder - b.client_groups.sortOrder
      )
    );
    triggerSetItems(
      myJson.sort(
        (a, b) => a.client_groups.sortOrder - b.client_groups.sortOrder
      )
    );
  } else if (entity === "project") {
    var fetchLocation =
      process.env.REACT_APP_DATABASE +
      "api/addProjectGroup?projectId=" +
      entityId +
      "&&groupId=" +
      groupId +
      "&groupPermission=" +
      groupPermission +
      "&contactId=" +
      contactId;
    //test - console.log(fetchLocation);

    const resultInfo = await fetch(fetchLocation);
    fetchLocation = process.env.REACT_APP_DATABASE + "api/getProject?id=";
    const searchResults = await fetch(
      fetchLocation + entityId + "&contactId=" + contactId
    );
    const data = await searchResults.json();
    setData(data.project);
    var myJson = data.groups; // search results come in an array, and the element is also an array
    // var sort = orderAvailGroups(myJson.sort((a,b)=> a.avail_group.sortOrder - b.avail_group.sortOrder))
    setGroupsIn(
      myJson.sort(
        (a, b) => a.project_groups.sortOrder - b.project_groups.sortOrder
      )
    );
    triggerSetItems(
      myJson.sort(
        (a, b) => a.project_groups.sortOrder - b.project_groups.sortOrder
      )
    );
  } else if (entity === "survey") {
    var fetchLocation =
      process.env.REACT_APP_DATABASE +
      "api/addSurveyGroup?surveyId=" +
      entityId +
      "&&groupId=" +
      groupId +
      "&groupPermission=" +
      groupPermission +
      "&contactId=" +
      contactId;
    //test - console.log(fetchLocation);
    const resultInfo = await fetch(fetchLocation);
    fetchLocation = process.env.REACT_APP_DATABASE + "api/getSurvey?id=";
    const searchResults = await fetch(
      fetchLocation + entityId + "&contactId=" + contactId
    );
    const data = await searchResults.json();
    setData(data.survey);
    var myJson = data.groups; // search results come in an array, and the element is also an array
    // var sort = orderAvailGroups(myJson.sort((a,b)=> a.avail_group.sortOrder - b.avail_group.sortOrder))
    setGroupsIn(
      myJson.sort(
        (a, b) => a.survey_groups.sortOrder - b.survey_groups.sortOrder
      )
    );
    triggerSetItems(
      myJson.sort(
        (a, b) => a.survey_groups.sortOrder - b.survey_groups.sortOrder
      )
    );
  }
}

async function orderAvailGroups(groups) {
  //test - console.log("Update the order in the database");
  ////test - console.log(avails)
  var mynum = 0;

  groups.map(async (group, index) => {
    mynum = index + 1;
    //test - console.log(group.id);
    var qs = "id=" + group.avail_group.id + "&sortOrder=" + mynum;
    var resultInfo = await fetch(
      process.env.REACT_APP_DATABASE + "api/sortMyGroups?" + qs
    );
  });
}
