
import * as React from 'react';
 import { List, arrayMove } from 'react-movable';
import WebsiteEntryModal from './WebsiteEntryModal';
import WebsiteEditModal from './WebsiteUpdateModal';


 export default function ProjectWebsiteEdit({mainPageData, setMainPageData, projectId, bigDataIn, websitesCombo, contactId}) {
    //test - console.log("Edit Table before Map array")
    
    const [items, setItems] = React.useState(mapArray(mainPageData.websites));
    const [showWebsiteModal, setShowWebsiteModal] = React.useState(false)
    const [showEditWebsiteModal, setShowEditWebsiteModal] = React.useState(false)
    const [websiteId, setWebsiteId] = React.useState();



    const mapSitesSetItems = (websites) => {
      setItems(mapArray(websites))
    }
    
    const removeFromBothArrays = ( formattedArray, index) => {
      var newDataIn = {...mainPageData}
    
      deleteWebsite(mainPageData.websites[index].id)
      newDataIn.websites = arrayRemove(newDataIn.websites, index)
      setMainPageData(newDataIn)
      mapSitesSetItems(newDataIn.websites)
      
      return arrayRemove(formattedArray, index)

    
    }

    const moveBothArrays = ( formattedArray, oldIndex, newIndex) => {
      setItems(arrayMove(formattedArray, oldIndex, newIndex)) // move items in the formatted drag list
     // setWebsitesIn(arrayMove(websitesIn, oldIndex, newIndex))
      var resetMainPageData = {...mainPageData} // put the mainPageData in a new variable.  
      resetMainPageData.websites = arrayMove(mainPageData.websites, oldIndex, newIndex);// Sort the newMainPageDAta already from arrayMove above
      setMainPageData(resetMainPageData) // set the new state to sorted mainPageData
      orderWebsites(resetMainPageData.websites) // save the sorted websites to the database
    }

    const addMe = () => {
      setShowWebsiteModal(true)

    }
 

   return (
     <>
      
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
    <div className="py-2 px-0 flex justify-end ">
                  <button
                  type="button"
                  className="inline-flex  self -center items-center content-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                  onClick={()=> addMe()}
                >
                  <i className="fa fa-plus " ></i> <div className="px-2"> Add Website</div>
               </button>
               
    </div>
              {items.length>1? <div className="bg-gray-200 border-solid border-2  border-gray-100 " >
                 <div className="px-6" >Drag Rows to Change Order</div>
                    
                </div>: ""}

     <List
       values={items}
       onChange={({ oldIndex, newIndex }) =>
         moveBothArrays(items, oldIndex, newIndex)
       }
       renderList={({ children, props }) => <ul {...props}>{children}</ul>}
       renderItem={({ value, props, index }) => <li {...props}  >  
       <div className="hover:bg-gray-50 border-solid border-2  border-gray-100">
         <div className="grid grid-cols-6 gap-4">
            <div className = "col-span-5" > 
              {value}
            </div> 
            <div className = "col-span-1 flex items-center justify-self-end px-4" >
                <div>
                    <button   className=" rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                    onClick={() => {
                      setItems(
                        typeof index !== 'undefined'
                          ? removeFromBothArrays(items, index)
                          : items
                      );
                    }}
                  
                  >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>
                    </button>  
     
                </div>
                <div className = "px-2">          
                  <button   className=" rounded-md border border-transparent bg-red-600 px-4 h-9 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                  onClick={
                     () => {
                        setWebsiteId(mainPageData.websites[index].id)
                        setShowEditWebsiteModal(true)
                      }
                    }
                >
                    Edit
                </button></div>
            </div>
          </div>
        </div>
     </li>}
     />
       <WebsiteEntryModal open = {showWebsiteModal} setOpen = {setShowWebsiteModal} mainPageData = {mainPageData} setMainPageData = {setMainPageData} mapSitesSetItems = {mapSitesSetItems} contactId = {contactId}/>
       <WebsiteEditModal open = {showEditWebsiteModal} setOpen = {setShowEditWebsiteModal} mainPageData = {mainPageData} setMainPageData = {setMainPageData} mapSitesSetItems = {mapSitesSetItems} websiteId={websiteId} contactId= {contactId}/>
       
     </>
   );
 };


function arrayRemove(array, index) {
  array = array.slice();
  array.splice(index, 1);
  return array;
}

function mapArray(websitesIn) {
  var myArray = []
  //test - console.log("Mapping")
  websitesIn.map((website) => (myArray.push(
    <div key = {website.id} className="flex">
            <div className="w-half px-3 py-4 text-sm text-gray-500">{website.Description}</div>
            <div className="w-half px-3 py-4 text-sm text-gray-500">{website.src}</div>
            
    </div>
  )))
  return myArray;
}

async function deleteWebsite(websiteId) {
    var qs = "id="+websiteId
    var resultInfo = await fetch(process.env.REACT_APP_DATABASE + "api/deleteWebsite?" + qs);
}

async function orderWebsites(websites) {
  //test - console.log("Update the order in the database")
  //test - console.log(websites)
  

  websites.map( async (website, index) => {
    website.sortOrder = index + 1
    //test - console.log(website.id)
    var qs = "id="+website.id+"&sortOrder="+ website.sortOrder 
    var resultInfo = await fetch(process.env.REACT_APP_DATABASE + "api/sortWebsites?" + qs);
   
  })
 

}


