import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Grid, Card, CardMedia, CardContent } from '@mui/material';

const FeaturesDialog = () => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const features = [
        {
            title: 'Email Campaigns',
            description: 'Create and manage email campaigns for buildings, spaces, people, and companies.',
            image: 'https://images.cubicsearch.com/Features/Email.png'
        },
        {
            title: 'Email Lookups',
            description: 'Perform email lookups by geography, company, and person.',
            image: 'https://images.cubicsearch.com/Tooltips/SearchLocation2.png'
        },
        {
            title: 'AI Marketing Writing',
            description: 'Leverage AI for creating compelling marketing content.',
            image: 'https://images.cubicsearch.com/Features/AI.webp'
        },
        {
            title: 'Professional Profiles',
            description: 'Create and manage profiles similar to LinkedIn for professionals and companies.',
            image: 'https://images.cubicsearch.com/Features/Person.png'
        },
        {
            title: 'Property Surveys',
            description: 'Share and manage property surveys easily.',
            image: 'https://images.cubicsearch.com/Features/Survey1.png'
        },
        {
            title: 'Off-Market Properties',
            description: 'Maintain a database of off-market properties.',
            image: 'https://images.cubicsearch.com/Features/Confidential%20Avail.png'
        },
        {
            title: 'Chat and File Storage',
            description: 'Chat and store files at the property level.',
            image: 'https://images.cubicsearch.com/Features/Chat.jpg'
        },
        {
            title: 'Property Information',
            description: 'Access property information and commercial real estate professionals throughout the US.',
            image: 'https://images.cubicsearch.com/Features/Property.png'
        },
        {
            title: 'Extensive Reach',
            description: 'Cubic Search is growing its database.',
            image: 'https://images.cubicsearch.com/Features/Earth.png'
        },
        {
            title: '3D Mapping',
            description: 'Cubic Search is taking mapping to the next level.',
            image: 'https://images.cubicsearch.com/Features/NY.png'
        }
    ];

    return (
        <div>
            <Typography variant='body2' sx={{cursor: 'pointer'}} onClick={handleClickOpen}>
                View Features
            </Typography>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
                <DialogTitle sx={{bgcolor: 'gray',fontWeight: 30, fontSize: 30, marginBottom: 4, color: 'white'}}>Unique Features</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {features.map((feature, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card sx={{ height: 400, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <CardMedia
                                        component="img"
                                        image={feature.image}
                                        alt={feature.title}
                                        sx={{ height: 300, objectFit: 'contain' }}
                                    />
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', flexGrow: 1 }}>
                                        <Typography variant="h6" component="div">
                                            {feature.title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {feature.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default FeaturesDialog;
