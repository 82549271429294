import React, {useEffect, useState} from "react";
import PropertiesCombo from "../Common/AddProperty/PropertiesCombo";
import { Button } from "@mui/material";
const KEY = "c4352282223e52cd121924e2c0a14e7d";

export default function Iframely(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [html, setHtml] = useState({
    __html: '<a target="_blank" rel="noopener noreferrer" class="underline" href="'+props.url+'"> '+props.url+'</a>'
    // <a  style=\"color: red\" target=\"_blank\" rel=\"noopener noreferrer\" href=" + props.url +" >" + props.url + "</a></div>"
  })
  
  useEffect(() => {
    if (props && props.url) {
      fetch(`https://cdn.iframe.ly/api/iframely?url=${encodeURI(props.url)}&key=${KEY}&iframe=1&omit_script=1`)
        .then(res => res.json())
        .then(
          (res) => {
            setIsLoaded(true);
            if (res.html) {
              setHtml({__html: res.html});
            } else if (res.error) {
              setError({code: res.error, message: res.message});
            }
          },
          (error) => {
            setIsLoaded(true);
            console.log(error)
            setError(error);
          }
        )
    } else {
      setError({code: 400, message: 'Provide url attribute for the element'})
    }
  }, []);

  useEffect((props) => {
    window.iframely && window.iframely.load();
  });

  if (error) {
    return <div >
      <Button variant='contained' onClick={() => { window.open(props.url) }}>{props.description}</Button>
      {/* <a href={props.url} className="text-lg underline text-indigo-600">Link: {props.description}</a> */}
    </div>
    // <div>Error: {error.code} - {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    
    return (
       
      <div dangerouslySetInnerHTML = {
        html 
        
      }/> 
      
    );
  }
}