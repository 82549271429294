import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Check as CheckIcon } from "@mui/icons-material";
import { info } from "autoprefixer";
import { InputComponent } from "./InputComponent";

export default function ConfirmInfo({
  open,
  setOpen,
  contactId,
  PropertyInfo,
}) {
  const [resultsInfo, setResultsInfo] = useState(null);
  const cancelButtonRef = useRef(null);
  var nfObject = new Intl.NumberFormat("en-US");
  if (open) {
    var qs =
      process.env.REACT_APP_DATABASE +
      "api/getBuilding?id=" +
      PropertyInfo.id +
      "&contactId=" +
      contactId;
    if (resultsInfo === null) {
      fetch(qs).then((data) =>
        data.json().then((results) => {
          
          setResultsInfo(results);
        })
      );
    } else {
      // ;
    }
  }


  
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-100"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  {resultsInfo ? (
                    <div className="flex flex-col items-center">
                      <div className="py-4">
                        Confirm Building and Available Information
                      </div>
                      <div>
                        <img
                          className="flex w-40 h-20 object-cover rounded-lg"
                          src={resultsInfo && resultsInfo.building.images
                            && (resultsInfo.building.images.length > 0) ? resultsInfo.building.images[0].img :
                            "https://www.google.com/maps/embed/v1/streetview?key=" + process.env.REACT_APP_GoogleMapsAPIKey + "&location=" +
                            (resultsInfo.building.Latitude) + "," + (resultsInfo.building.Longitude + 0.0002) }
                        />
                      </div>
                      <div>
                        {resultsInfo.building.StreetNumber +
                          " " +
                          resultsInfo.building.StreetName +
                          ", " +
                          resultsInfo.building.City}
                      </div>
                    </div>
                  ) : (
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    ></Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {/* {resultsInfo? resultsInfo.building.StreetName: <>Loading Contacts...</>} */}

                        {resultsInfo ? (
                          <div className="flex flex-col ">
                            <div className="flex text-lg py-2">
                              Building Info Confirmation Contacts
                            </div>
                            {!resultsInfo.building || !resultsInfo.building.contacts ||
                              !resultsInfo.building.contacts || resultsInfo.building.contacts.length === 0 ? (
                              <>
                                <div className="flex">Please Add Contacts</div>
                              </>
                            ) : (
                              resultsInfo.building.contacts.map(
                                (contact, index) => {
                                  return (
                                    <>
                                      <div className="flex items-center">
                                        <input
                                          type={"checkbox"}
                                          name={"buildingContact"}
                                          defaultValue={JSON.stringify(contact)}
                                          defaultChecked={true}
                                        />
                                        <div className="px-4 flex ">
                                          <div className="h-6 w-6 rounded-full">
                                            <img
                                              className="h-6 w-6 flex-shrink-0 rounded-full object-cover"
                                              src={
                                                contact.images.length > 0
                                                  ? contact.images.sort(
                                                      (a, b) =>
                                                        a.sortOrder -
                                                        b.sortOrder
                                                    )[0].img
                                                  : "https://images.cubicsearch.com/UserLogo.png"
                                              }
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                        {contact.FirstName +
                                          " " +
                                          contact.LastName +
                                          " - " +
                                          contact.Email}
                                      </div>
                                    </>
                                  );
                                }
                              )
                            )}
                           
                            {resultsInfo.avails.length === 0 ? (
                              <div className="flex text-lg py-2">
                                No Availability Meeting Filtered Criteria
                              </div>
                            ) : (
                              <>
                                <div className="flex text-lg pt-4">
                                  Available Info Confirmation Contacts
                                </div>
                                {resultsInfo.avails.map((avail) => {
                                  return (
                                    <>
                                      <div className="flex py-2">
                                        Floor: {avail?.Floor === -1 ? "All": avail?.Floor} |{" "}
                                        {avail.SubleaseOrDirect
                                          ? avail.SubleaseOrDirect + " | "
                                          : ""}
                                        {nfObject.format(avail.SizeMin)} -{" "}
                                        {nfObject.format(avail.SizeMax)} SF{" "}
                                      </div>
                                      {avail.contacts.length === 0 ? (
                                        <>
                                          <div className="flex">
                                            Please Add Contacts
                                          </div>
                                        </>
                                      ) : (
                                        avail.contacts.map((contact, index) => {
                                          return (
                                            <>
                                              <div className="flex items-center">
                                                <input
                                                  type={"checkbox"}
                                                  name={
                                                    "availableContact" +
                                                    avail.id
                                                  }
                                                  defaultValue={JSON.stringify(
                                                    contact
                                                  )}
                                                  defaultChecked={true}
                                                />
                                                <div className="px-4 flex ">
                                                  <div className="h-6 w-6 rounded-full">
                                                    <img
                                                      className="h-6 w-6 flex-shrink-0 rounded-full object-cover"
                                                      src={
                                                        contact.images.length >
                                                        0
                                                          ? contact.images.sort(
                                                              (a, b) =>
                                                                a.sortOrder -
                                                                b.sortOrder
                                                            )[0].img
                                                          : "https://images.cubicsearch.com/UserLogo.png"
                                                      }
                                                      alt=""
                                                    />
                                                  </div>
                                                </div>
                                                {contact.FirstName +
                                                  " " +
                                                  contact.LastName +
                                                  " - " +
                                                  contact.Email}
                                              </div>
                                            </>
                                          );
                                        })
                                      )}
                                    </>
                                  );
                                })}
                              </>
                            )}
                          </div>
                        ) : (
                          "Loading Contacts"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pt-2">
                Message for recipients </div>
                <div>
                              <InputComponent
                                inputType={"placeholder"}
                                name="Message"
                                id={"Message"}
                                formatting={"w-full"}
                                defaultShow={
                                  "Message describing requirement or purpose"
                                }
                              />
                            </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                    onClick={() => {
                     
                      var contactsAndInfo = [];
                      var checkedBoxes = [];
                      checkedBoxes = getCheckedBoxes("buildingContact");
                      if (checkedBoxes) {
                        checkedBoxes.map((eachContactChecked) => {
                          contactsAndInfo.push({
                            contact: eachContactChecked.value,
                            info: [
                              {
                                detail: resultsInfo.building,
                                type: "building",
                              },
                            ],
                          });
                        });
                      }

                      resultsInfo.avails.map((avail) => {
                        var checkedBoxesAvail = getCheckedBoxes(
                          "availableContact" + avail.id
                        );

                        if (checkedBoxesAvail) {
                          checkedBoxesAvail.map((eachContactCheckedAvail) => {
                            var findIndex = contactsAndInfo.findIndex(
                              (el, index) => {
                                // alert(el.contact);
                                // alert(" match ?" + eachContactCheckedAvail.value);
                                return (
                                  JSON.parse(el.contact).id ===
                                  JSON.parse(eachContactCheckedAvail.value).id
                                );
                              }
                            );
                            if (findIndex > -1) {
                              contactsAndInfo[findIndex].info.push({
                                detail: avail,
                                type: "avail",
                              });
                            } else {
                              contactsAndInfo.push({
                                contact: eachContactCheckedAvail.value,
                                info: [{ detail: avail, type: "avail" }],
                              });
                            }
                          });
                        }
                      });
                      //test - console.log(document.getElementById("Message").value)
                      
                      
                      contactsAndInfo.map((row) => {
                        row.info.map((info) => {
                          //test - console.log(row.contact);
                          //test - console.log(info.type);
                        });
                      });
                      var xhr = new XMLHttpRequest();
                      xhr.open(
                        "POST",
                        process.env.REACT_APP_MAILER + "routes/mailrouter",
                        true
                      );
                      xhr.setRequestHeader("Content-Type", "application/json");
                      // alert(PropertyInfo.YearBuilt)
                  
                      xhr.send(
                        JSON.stringify({
                          myInfo: resultsInfo.mycontact,
                          contactsAndInfo: contactsAndInfo,
                          PropertyInfo: PropertyInfo,
                          Message: document.getElementById("Message").value
                        })
                      );
                      setOpen(false);
                    }}
                  >
                    Email Confirmation Request(s)
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function getCheckedBoxes(chkboxName) {
  var checkboxes = document.getElementsByName(chkboxName);
  var checkboxesChecked = [];
  // loop over them all
  for (var i = 0; i < checkboxes.length; i++) {
    // And stick the checked ones onto an array...
    if (checkboxes[i].checked) {
      checkboxesChecked.push(checkboxes[i]);
    }
  }
  // Return the array if it is non-empty, or null
  return checkboxesChecked.length > 0 ? checkboxesChecked : null;
}
