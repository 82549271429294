import React, { useState } from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import SurveysList from './SurveyList';
import ContactList from './ContactList';
import ProjectGroupList from './ProjectGroups/ProjectGroupList';
import WebsiteList from './Websites/WebsiteList';

function MainProjectInfo({
  dataIn,
  setDataIn,
  contactId,
  groups,
  groupsCombo,
  highestPermission,
}) {
  const [groupsIn, setGroupsIn] = useState(groups);

  const [people, setPeople] = useState(
    dataIn.contacts.sort(
      (a, b) => a.project_contact.sortOrder - b.project_contact.sortOrder
    )
  );

  return (
    <Paper elevation={3} sx={{ padding: 2, width: '100%' }}>
      <Box padding={2}>
        <Typography variant="h6" color="textSecondary">
          Project Name
        </Typography>
        <Typography variant="body1" color="textPrimary" gutterBottom>
          {dataIn.ProjectName}
        </Typography>

        <Typography variant="h6" color="textSecondary">
          About
        </Typography>
        <Typography variant="body1" color="textPrimary" gutterBottom>
          {dataIn.Notes}
        </Typography>
      </Box>

      <Box padding={2} borderTop={1} borderColor="grey.300">
        <SurveysList
          surveysIn={dataIn.surveys}
          projectId={dataIn.id}
          contactId={contactId}
          highestPermission={highestPermission}
        />
      </Box>

      <Box padding={2} borderTop={1} borderColor="grey.300">
        <ContactList
          peopleIn={people}
          dataIn={dataIn}
          setPeopleIn={setPeople}
          contactId={contactId}
          groupsIn={groupsIn}
          highestPermission={highestPermission}
        />
      </Box>

      <Box padding={2} borderTop={1} borderColor="grey.300">
        <ProjectGroupList
          groupsIn={groupsIn}
          setGroupsIn={setGroupsIn}
          projectId={dataIn.id}
          contactId={contactId}
          groupsCombo={groupsCombo}
          highestPermission={highestPermission}
          setData={setDataIn}
        />
      </Box>

      <Box padding={2} borderTop={1} borderColor="grey.300">
        <WebsiteList
          mainPageData={dataIn}
          setMainPageData={setDataIn}
          contactId={contactId}
          highestPermission={highestPermission}
        />
      </Box>
    </Paper>
  );
}

export default MainProjectInfo;
