


export default function CompanyCompanyNew() {
    return (
         
        <div className="px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Company Name</dt>
            <dd className="mt-1 text-sm text-gray-900">{inputComponent("text", "Name", "id", "Name")}</dd>
           
        </div>
        <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Website</dt>
            <dd className="mt-1 text-sm text-gray-900">{inputComponent("text", "Website", "id", "Website")}</dd>
            
        </div>
        <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">Notes</dt>
            <dd className="mt-1 text-sm text-gray-900">{inputComponent("text", "Notes", "id", "Notes")}</dd>
        </div>
        <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Street Number</dt>
            <dd className="mt-1 text-sm text-gray-900">{inputComponent("text", "StreetNumber", "id", "StreetNumber")}</dd>
           
        </div>
        <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Street Name</dt>
            <dd className="mt-1 text-sm text-gray-900">{inputComponent("text", "StreetName", "id", "StreetName")}</dd>
            
        </div>
        <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Suite</dt>
            <dd className="mt-1 text-sm text-gray-900">{inputComponent("text", "Suite", "id", "Suite")}</dd>
           
        </div>
        <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Phone</dt>
            <dd className="mt-1 text-sm text-gray-900">{inputComponent("text", "Phone", "id", "Phone")}</dd>
            
        </div>
        <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">City</dt>
            <dd className="mt-1 text-sm text-gray-900">{inputComponent("text", "City", "id", "City")}</dd>
           
        </div>
        <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">State</dt>
            <dd className="mt-1 text-sm text-gray-900">{inputComponent("text", "State", "id", "State")}</dd>
            
        </div>
        <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Zip</dt>
            <dd className="mt-1 text-sm text-gray-900">{inputComponent("text", "Zip", "id", "Zip")}</dd>
           
        </div>
        </dl>
        </div>
    )
        
}

function inputComponent(inputType, defaultShow , id, name) {
    if (inputType === 'date') {
        return(
            <input
                className="shadow-sm focus:ring-red-500 focus:border-red-500  sm:text-sm border-gray-300 rounded-md"
                type={inputType}
                name={name}
                id={name}
                defaultValue={defaultShow && defaultShow !== 'null'? defaultShow : ""}
            />  
        )
    }
    else {
    return(
       
        <input
            className="shadow-sm focus:ring-red-500 focus:border-red-500  sm:text-sm border-gray-300 rounded-md"
            type={inputType}
            name={name}
            id={name}
            defaultValue={defaultShow && defaultShow !== 'null'? defaultShow : ""}            
        />    
      
    )
    }
 }

