import React, { useState, useEffect } from "react";
//import Property from "../components/Building/Property";
//import BingMapsReact from "bingmaps-react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSearchParams } from "react-router-dom"

import AvailableDetail from "../components/Availables/AvailableDetail";
import Header from "../components/Common/Header";
import axios from "axios";
import { CircularProgress } from "@mui/material";



const fetchAvailableOnlyData = async (qsAvailableOnly) => {
  return new Promise(async function (resolve, reject) {
    const result = await axios.get(qsAvailableOnly)
      .catch((error) => {
        console.log(error)
        reject(error)
      })
    resolve(result?.data)
  })
}

const fetchAvailableJournalsOnlyData = async (qsAvailableJournalsOnly) => {
  return new Promise(async function (resolve, reject) {
    const result = await axios.get(qsAvailableJournalsOnly)
      .catch((error) => {
        console.log(error)
        reject(error)
      })
    resolve(result?.data)
  })

}
const fetchAvailableContactsOnlyData = async (qsAvailableContactsOnly) => {
  return new Promise(async function (resolve, reject) {
    const result = await axios.get(qsAvailableContactsOnly)
      .catch((error) => {
        console.log(error)
        reject(error)
      })
    resolve(result?.data)
  })


}

const fetchAvailableWebsitesOnlyData = async (qsAvailableWebsitesOnly) => {
  return new Promise(async function (resolve, reject) {
    const result = await axios.get(qsAvailableWebsitesOnly)
      .catch((error) => {
        console.log(error)
        reject(error)
      })
    resolve(result?.data)
  })


}




export default function Available({ user }) {


  const [loadingAvailableOnly, setLoadingAvailableOnly] = useState(true);
  const [loadingAvailableContacts, setLoadingAvailableContacts] = useState(true);
  const [loadingAvailableJournals, setLoadingAvailableJournals] = useState(true);
  const [loadingAvailableWebsites, setLoadingAvailableWebsites] = useState(true);
  
  const [availableDataState, setAvailableDataState] = useState({})
  const [availableContactsDataState, setAvailableContactsDataState] = useState({})
  const [availableJournalsDataState, setAvailableJournalsDataState] = useState({})
  const [availableWebsitesDataState, setAvailableWebsitesDataState] = useState({})



  let [query, setQuery] = useSearchParams();

  let id = query.get("id")

  var qsAvailableOnly = process.env.REACT_APP_DATABASE + "api/getAvailableOnly?id=" + id + "&contactId=" + user.contact.id
  var qsAvailableContactsOnly = process.env.REACT_APP_DATABASE + "api/getAvailableContactsOnly?id=" + id + "&contactId=" + user.contact.id
  var qsAvailableJournalsOnly = process.env.REACT_APP_DATABASE + "api/getAvailableJournalsOnly?id=" + id + "&contactId=" + user.contact.id
  var qsAvailableWebsitesOnly = process.env.REACT_APP_DATABASE + "api/getAvailableWebsitesOnly?id=" + id + "&contactId=" + user.contact.id

  useEffect( () => { // this will run once and all the loading and data will get filled async
    
    fetchAvailableOnlyData(qsAvailableOnly).then((availableOnlyData) => {
      setAvailableDataState(availableOnlyData)
    }).catch((err) => {
      //test - console.log(err);
    }).finally(() => {
      setLoadingAvailableOnly(false)
    })
    fetchAvailableContactsOnlyData(qsAvailableContactsOnly).then((availableContactsData) => {
      setAvailableContactsDataState(availableContactsData)
    }).catch((err) => {
      //test - console.log(err);
    }).finally(() => {
      setLoadingAvailableContacts(false)
    })
    fetchAvailableJournalsOnlyData(qsAvailableJournalsOnly).then((availableJournalsData) => {
      setAvailableJournalsDataState(availableJournalsData)
    }).catch((err) => {
      //test - console.log(err);
    }).finally(() => {
      setLoadingAvailableJournals(false)
    })
    fetchAvailableWebsitesOnlyData(qsAvailableWebsitesOnly).then((availableWebsitesData) => {
      setAvailableWebsitesDataState(availableWebsitesData)
    }).catch((err) => {
      //test - console.log(err);
    }).finally(() => {
      setLoadingAvailableWebsites(false)
    })




  }, []);






  if ((loadingAvailableContacts || loadingAvailableJournals || loadingAvailableWebsites || loadingAvailableOnly)) {

    return (
      <>
        <div className="absolute top-1/3 w-full items-center flex flex-col">
          <CircularProgress/>
        </div>
      </>
    );

  }
  else if (!availableDataState.avail) {
    alert("Not Found")
    window.location = ("/")
  }



 
    // const people =  getContacts(peopleDataArray)
  var availableData = availableDataState
  
  availableData.avail.contacts = availableContactsDataState.contacts

    availableData.avail.generic_journals = availableJournalsDataState.journals
    availableData.avail.websites = availableWebsitesDataState.websites
  


    //const {Base64} = require('js-base64');
    //var myData = Base64.decode(data.substring(5,data.length));  
    //const availableData = JSON.parse(myData);
    const pushPin = {
      center: {
        latitude: availableData.avail.building ? availableData.avail.building.Latitude : 37.468319,
        longitude: availableData.avail.building ? availableData.avail.building.Longitude : -122.143936,
      },
      options: {
        title: availableData.avail.building ?
          availableData.avail.building.StreetNumber + " " +
          availableData.avail.building.StreetName + ", " +
          availableData.avail.building.City : "Address Info",
      },
    }
    const pushPins = [pushPin];

    return (<div>

      < div >
        <AvailableDetail PropertyInformation={availableData} user={user}  contactId={user.contact.id} />
      </div >

      <div className="px-6 py-4">

        {/* {BingMap(availableData.building.Latitude, availableData.building.Longitude, pushPins)}  */}

      </div>
    </div >)



}


function getContacts(data) {
  var peopleArray = []
  //   const data = await searchResults.json();
  data.contact.map((person) => {
    var myJsonToAdd = {}
    if (person.images.length > 0) {
      if (person.office && person.office.company) {
        myJsonToAdd = {
          "id": person.id,
          "name": person.FirstName + " " + person.LastName + " - " + person.office.company.Name,
          "imageURL": person.images[0].img


        }
      }
      else {
        myJsonToAdd = {
          "id": person.id,
          "name": person.FirstName + " " + person.LastName,
          "imageURL": person.images[0].img


        }
      }

    }
    else {
      if (person.office && person.office.company) {

        myJsonToAdd = {
          "id": person.id,
          "name": person.FirstName + " " + person.LastName + " - " + person.office.company.Name,
          "imageURL": "https://images.cubicsearch.com/UserLogo.png"


        }
      }
      else {
        myJsonToAdd = {
          "id": person.id,
          "name": person.FirstName + " " + person.LastName,
          "imageURL": "https://images.cubicsearch.com/UserLogo.png"


        }

      }


    }
    peopleArray.push(myJsonToAdd)
  })
  return peopleArray.sort(sortByProperty("name"))
}

function sortByProperty(property) {
  return function (a, b) {
    if (a[property] > b[property])
      return 1;
    else if (a[property] < b[property])
      return -1;

    return 0;
  }
}