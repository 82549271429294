// import { Alert } from "@mui/material";
import { useState } from "react";
//  import ContactEditList from ".depreciated/ContactEditList";
import ContactListView from "./ContactListView";
import GroupContactEditTable from "./GroupContactEditTable";
import { Save, Edit } from "@mui/icons-material";

export default function ContactList({
  peopleIn,
  GroupData,
  setPeopleIn,
  peopleCombo,
  user,
  permission,
}) {
  const [editContactsMode, setEditContactsMode] = useState(false);
  //
  //  setData(GroupData.contacts.sort((a,b)=> a.group_contact.sortOrder - b.group_contact.sortOrder))

  return (
    <>
      <div className="px-0 sm:px-0 lg:px-0">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              Group Contacts
            </h1>
          </div>
          <div className="mt-4 flex space-x-2 sm:mt-0 sm:ml-16 sm:flex-none">
            <div className="px-0">
              {
              GroupData.GroupName !== "Me" &&
              (permission === "Lead Edit" ||
                permission === "Edit" ||
                user.contact.id === 1) ? (
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                  onClick={() => setEditContactsMode(!editContactsMode)}
                >
                  {!editContactsMode ? <Edit sx={{width: 18, height: 18}}/> : <Save/>}
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        {/* {!editContactsMode? <ContactListView peopleIn = {peopleIn}/> : <ContactEditList peopleIn = {peopleStateIn} /> } */}
        {!editContactsMode ? (
          <ContactListView
            peopleIn={
              (GroupData.MembersVisible &&
                GroupData.MembersVisible === "Yes") ||
              permission === "Lead Edit" ||
              permission === "Edit"
                ? peopleIn
                : peopleIn.filter(function (person) {
                    return (
                      person.PermissionLevel === "Lead Edit" ||
                      person.PermissionLevel === "Edit" ||
                      person.contact_id === user.contact.id
                    );
                  })
            }
          />
        ) : (
          <GroupContactEditTable
            peopleIn={peopleIn}
            setPeopleIn={setPeopleIn}
            bigDataIn={GroupData}
            groupId={GroupData.id}
            peopleCombo={peopleCombo}
            user={user}
          />
        )}
      </div>
    </>
  );
}
