import React, { useState, useEffect, useCallback } from 'react';
import { Autocomplete, Button, TextField, Box, Avatar, CircularProgress, InputAdornment } from '@mui/material';
import { debounce } from 'lodash';
import axios from 'axios';
import CompanyDialog from '../Companies/CompanyDialog';



export const AutocompleteAsync = ({ sender, handleSenderChange, index, contactId }) => {

    const [inputValue, setInputValue] = useState(sender.name);
    const [options, setOptions] = useState([{ label: sender.name, email: sender.email, avatar: sender.avatar }]);
    const [selectedValue, setSelectedValue] = useState({ label: sender.name, email: sender.email, avatar: sender.avatar }); // To track the selected value explicitly
    const [loading, setLoading] = useState(false);


    // Debounced function for API calls
    const fetchContacts = debounce(async (search) => {
        if (!search) return; // Prevent fetching when there's no input

        setLoading(true);
        try {
            const response = await getContacts(contactId, search); // Replace with your actual API call

            if (response.ok) {
                const data = await response.json();
                var options = data.contact.map((datum) => {
                    var image = null
                    if (datum.images.length > 0) {
                        datum.images.sort((a, b) => { return (a.sortOrder - b.sortOrder) })
                        image = datum.images[0].img
                    }

                    return ({
                        avatar: image,
                        email: datum.Email,
                        label: (datum.FirstName + " " + datum.LastName),
                        name: (datum.FirstName + " " + datum.LastName),
                        id: datum.id
                    })
                })
                setOptions(options); // Assuming the response is the array of contacts
            } else {
                // Handle server errors or invalid responses
                console.error('Failed to fetch contacts');
                setOptions([]);
            }
        } catch (error) {
            console.error('Error fetching contacts:', error);
            setOptions([]);
        } finally {
            setLoading(false);
        }
    }, 1000); // Adjust debounce time as needed
    useEffect(() => {
        setInputValue(sender.name);
        const newOptions = [{ label: sender.name, email: sender.email, avatar: sender.avatar }];
        setOptions(newOptions);
        // Only set selectedValue if it matches one of the new options
        const matchingOption = newOptions.find(option => option.email === sender.email);
        if (matchingOption) {
            setSelectedValue(matchingOption);
        } else {
            // Handle the case where there's no matching option (e.g., reset selectedValue or set to a default)
            setSelectedValue(null); // or your default value
        }
    }, [sender]);
    useEffect(() => {
        if (inputValue) {
            fetchContacts(inputValue);
        } else {
            setOptions([]);
        }
    }, [inputValue], [fetchContacts]);
    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
    };

    const handleChange = (event, newValue) => {
        setSelectedValue(newValue); // Update the explicitly tracked selected value
        handleSenderChange(index, newValue); // Propagate changes upwards as needed
    };

    return (
        <Autocomplete
            options={options}
            getOptionLabel={(option) => option.label || ''}
            value={selectedValue} // Use the explicitly tracked selected value
            onInputChange={handleInputChange}
            onChange={handleChange}
            isOptionEqualToValue={(option, value) => option.email === value.email}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    <Avatar src={option.avatar} sx={{ width: 24, height: 24, mr: 1 }} />
                    {option.label} - {option.email}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Search and select a contact"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            fullWidth
        />
    );
};

// Mock API call function (replace with your actual API call logic)
async function getContacts(contactId, query) {
    // Simulate an API call
    return fetch(process.env.REACT_APP_DATABASE + `api/getContacts?contactId=${contactId}&&query=${query}`);
}

async function getCompanies(contactId, query) {
    // Simulate an API call
    var result = await axios.post(process.env.REACT_APP_DATABASE + `api/getCompaniesQuery`,
        { contactId: contactId, query: query })

    return result.data
}

async function getSpaces(contactId, query) {
    // Simulate an API call
    var result = await axios.post(process.env.REACT_APP_DATABASE + `api/getSpaces`,
        { contactId: contactId, query: query })
    return result.data
}

async function getBuildings(contactId, query) {
    // Simulate an API call
    var result = await axios.post(process.env.REACT_APP_DATABASE + `api/getBuildings`,
        { contactId: contactId, query: query }).catch((error) => { console.log(error) })
    return result.data
}


export const AutocompleteAsyncGeneral = ({ selectedcontact, handleContactChange, contactId }) => {

    const [inputValue, setInputValue] = useState(selectedcontact?.name);
    const [options, setOptions] = useState([{ label: selectedcontact?.name, email: selectedcontact?.email, avatar: selectedcontact?.avatar }]);
    const [selectedValue, setSelectedValue] = useState({ label: selectedcontact?.name, email: selectedcontact?.email, avatar: selectedcontact?.avatar }); // To track the selected value explicitly
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (selectedcontact) {
            setInputValue(selectedcontact?.name)
            setOptions([{ label: selectedcontact?.name, email: selectedcontact?.email, avatar: selectedcontact?.avatar }])
            setSelectedValue({ label: selectedcontact?.name, email: selectedcontact?.email, avatar: selectedcontact?.avatar })
        }
    }, [selectedcontact])

    // Debounced function for API calls
    const fetchContacts = debounce(async (search) => {
        setLoading(true);
        try {
            const response = await getContacts(contactId, search); // Replace with your actual API call

            if (response.ok) {
                const data = await response.json();
                var options = data.contact.map((datum) => {
                    var image = null
                    if (datum.images.length > 0) {
                        datum.images.sort((a, b) => { return (a.sortOrder - b.sortOrder) })
                        image = datum.images[0].img
                    }

                    return ({
                        avatar: image,
                        email: datum.Email,
                        label: (datum.FirstName + " " + datum.LastName),
                        name: (datum.FirstName + " " + datum.LastName),
                        id: datum.id
                    })
                })
                setOptions(options); // Assuming the response is the array of contacts
            } else {
                // Handle server errors or invalid responses
                console.error('Failed to fetch contacts');
                setOptions([]);
            }
        } catch (error) {
            console.error('Error fetching contacts:', error);
            setOptions([]);
        } finally {
            setLoading(false);
        }
    }, 1000); // Adjust debounce time as needed

    useEffect(() => {
        if (inputValue) {
            fetchContacts(inputValue);
        } else {
            setOptions([]);
        }
    }, [inputValue]);
    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
    };

    const handleChange = (event, newValue) => {
        setSelectedValue(newValue); // Update the explicitly tracked selected value
        handleContactChange(newValue); // Propagate changes upwards as needed
    };

    return (
        <Autocomplete
            options={options}
            getOptionLabel={(option) => option.label || ''}
            value={selectedValue} // Use the explicitly tracked selected value
            onInputChange={handleInputChange}
            noOptionsText={'type name...'} // Or an empty string ''
            onChange={handleChange}
            isOptionEqualToValue={(option, value) => option.email === value.email}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    <Avatar src={option.avatar} sx={{ width: 24, height: 24, mr: 1 }} />
                    {option.label} - {option.email}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Select a contact"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            fullWidth
        />
    );
};



export const ImageWithAspectRatio = ({ src, name }) => {
    const [imageError, setImageError] = useState(false);

    const getInitials = (name = '') => {
        return name ? name
            .split(' ')
            .map((n) => n[0])
            .join('')
            .toUpperCase() : null;
    };

    return (
        <Box
            sx={{
                overflow: 'hidden',
                borderRadius: '4px',
                width: '48px',
                height: '48px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: 'background.paper', // Ensures background color for Avatar visibility
            }}
        >
            {imageError || !src ? (
                <Avatar
                    sx={{
                        width: '100%',
                        height: '100%',
                        bgcolor: 'primary.main', // Optional: Set a background color for the Avatar
                    }}
                >
                    {getInitials(name)}
                </Avatar>
            ) : (
                <img
                    src={src}
                    alt={name}
                    style={{ maxWidth: '100%', maxHeight: '100%', display: 'block' }}
                    onError={() => setImageError(true)}
                />
            )}
        </Box>
    );
};


async function createCompany(company) {
    var result = await axios.post(process.env.REACT_APP_DATABASE + `api/createCompanySimple`,
        { company }).catch((error) => { console.log(error) })

}
async function createNewCompany(newCompany) {
    if (newCompany) {
        var result = await axios.post(process.env.REACT_APP_DATABASE + `api/createCompanyCB`,
            { company: newCompany }).catch((error) => { console.log(error) })

        if (result?.data) {
            return result.data
        }
        else {
            return false
        }
    }
    else {
        return { company: 'empty' }
    }
}

export const AutocompleteAsyncCompany = ({ selectedcompany, handleCompanyChange, contactId }) => {
    const [inputValue, setInputValue] = useState(selectedcompany?.Name);
    const [options, setOptions] = useState([{ Name: selectedcompany?.Name, Website: selectedcompany?.Website, images: selectedcompany?.images, id: selectedcompany?.id }]);
    const [selectedValue, setSelectedValue] = useState({ Name: selectedcompany?.Name, Website: selectedcompany?.Website, images: selectedcompany?.images, id: selectedcompany?.id });
    const [loading, setLoading] = useState(false);
    const [showNewCompany, setShowNewCompany] = useState(false)
    const [companiesData, setCompaniesData] = useState([])



    const handleDialogOpen = () => {
        setShowNewCompany(true);
    };

    const handleDialogClose = () => {
        setShowNewCompany(false);
    };
    const newCo = {
        images: null,
        Website: 'www.newco.com',
        Name: 'Create New Company',
        id: -1
    }
    useEffect(() => {
        
        if (selectedcompany) {
            setInputValue(selectedcompany?.Name)
            setOptions([{ Name: selectedcompany?.Name, Website: selectedcompany?.Website, images: selectedcompany?.images, id: selectedcompany?.id }])
            setSelectedValue({ Name: selectedcompany?.Name, Website: selectedcompany?.Website, images: selectedcompany?.images, id: selectedcompany?.id })
        }
    }, [selectedcompany]);
    useEffect(() => {
        if (inputValue && inputValue.length > 2) {
            fetchCompanies(inputValue);
        } else {
            setOptions([newCo]);
        }
    }, [inputValue]);

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        // setSelectedValue(null)
    };

    const handleChange = async (event, newValue) => {



        
        if (!newValue?.id) {
            
            newValue = await createNewCompany(newValue)

        }

        if (newValue) {
            if (newValue.company === 'empty') {
                // do nothing
            }
            else {
                setSelectedValue(newValue);

            }
        }
        else {
            alert("There is a problem with this company.  Please check to make sure it exists, or enter the company by clicking the button below.")
        }


        if (newValue?.id) {
            handleCompanyChange(newValue);
        }

    };

    function isMatch(item, newValue) {
        return (
            (item?.Name === newValue?.Name && item?.Website === newValue?.Website) || // Both Name and Website match
            (item?.Name === null && newValue?.Name === null && item?.Website === newValue?.Website) || // Both Names are null, Websites match
            (item?.Website === null && newValue?.Website === null && item?.Name === newValue?.Name) // Both Websites are null, Names match
        );
    }
    const fetchCompanies = useCallback(debounce(async (search) => {
        setLoading(true);
        try {
            const data = await getCompanies(contactId, search); // Replace with your actual API call

            const newOptions = data.map(datum => ({
                ...datum,
                label: datum.Name,
                name: datum.Name,
                id: datum.id,
            }));

            // Always add a "Create New Company" option that uses the current search text
            const createNewOption = {
                images: null,
                Website: 'www.newco.com',
                Name: search,
                id: 'new'
            };

            // Combine fetched companies with the create new option
            setOptions([...newOptions, createNewOption]);
        } catch (error) {
            console.error('Error fetching companies:', error);
            setOptions([{
                images: null,
                Website: 'www.newco.com',
                Name: search,
                id: 'new'
            }]);
        } finally {
            setLoading(false);
        }
    }, 1000), []);

    const customFilter = (options, state) => {
        // Ensure the create new option is not filtered out
        return options.filter(option => {

            if (option.id === 'new') return true; // Always include the create new option
            const query = state?.inputValue?.toLowerCase();
            return option?.Name?.toLowerCase()?.includes(query) || option.Website?.toLowerCase()?.includes(query);
        });
    };

    console.log("Options:", options);
    console.log("Selected Value:", selectedValue);
    return (
        <>
            <Autocomplete
                options={options}
                getOptionLabel={(option) => option.Name || ''}
                filterOptions={customFilter}

                value={selectedValue}
                onInputChange={handleInputChange}
                onChange={handleChange}
                noOptionsText={'Type name of company...'}
                isOptionEqualToValue={(option, value) => {
                    console.log("option:", option);
                    console.log("value:", value);
                    return option.id === value.id
                }}
                renderOption={(props, option) => {

                    if (option.Website === 'www.newco.com') {
                        return (
                            <li {...props}>
                                <Button onClick={handleDialogOpen} variant="outlined">Create Company Called '{option.Name}'</Button>
                            </li>
                        )
                    }
                    else {
                        return (

                            <li {...props}>
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <div style={{ marginRight: 10 }}>
                                        <ImageWithAspectRatio src={option?.images?.[0]?.img} name={option?.Name} />
                                    </div>
                                    <div>{option?.Name} - {option?.Website}</div>
                                </Box>
                            </li>
                        );
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select a company"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading && <CircularProgress color="inherit" size={20} />}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                        fullWidth
                    />
                )}
            />
            <div>

                <CompanyDialog
                    open={showNewCompany}
                    onClose={handleDialogClose}
                    onSave={createCompany}
                    initialData={{ Name: inputValue }}
                />
            </div>
        </>
    );
};

export const AutocompleteAsyncSpace = ({ selectedspace, handleSpaceChange, contactId }) => {

    const [inputValue, setInputValue] = useState(null);
    const [options, setOptions] = useState([{
        label: selectedspace?.label, building: selectedspace?.building, avatar: selectedspace?.avatar, id: selectedspace?.id
    }]);
    const [selectedValue, setSelectedValue] = useState({ label: selectedspace?.label, building: selectedspace?.building, avatar: selectedspace?.avatar, id: selectedspace?.id }); // To track the selected value explicitly
    const [loading, setLoading] = useState(false);


    // Debounced function for API calls
    const fetchSpaces = useCallback(debounce(async (search) => {
        setLoading(true);
        try {
            const data = await getSpaces(contactId, search); // Replace with your actual API call
            if (data) {
                var options = data.map((datum) => {
                    var image = null;
                    if (datum.images.length > 0) {
                        datum.images.sort((a, b) => a.sortOrder - b.sortOrder);
                        image = datum.images[0].img;
                    }

                    var statusAndSF = ""
                    if (datum?.Status === "Lease Comp") {
                        statusAndSF =
                            (datum?.LeasedSpace ? "Lease Comp: " + datum?.LeasedSpace.toLocaleString() + " SF" : "")
                        if (datum?.ExecutionDate) {
                            statusAndSF = statusAndSF + " Signed: " + new Date(datum?.ExecutionDate).toLocaleDateString()
                        }
                    }
                    else {
                        statusAndSF = (datum?.SizeMax ? "Available: " + datum?.SizeMax.toLocaleString() + " SF" : "")
                    }
                    return {
                        label: datum?.building?.StreetNumber + " " +
                            datum?.building?.StreetName + ", " + datum?.building?.City + " " + datum?.building?.State,
                        space:
                            (datum?.SubleaseOrDirect ? " " + datum?.SubleaseOrDirect + " | " : "") +
                            (datum?.Suite ? " Suite: " + datum?.Suite : "") +
                            (datum?.Floor && datum?.Floor > 0 ? " Floor: " + datum?.Floor : "") +
                            (datum?.PropertyType ? " Type: " + datum?.PropertyType : "")
                        ,
                        avatar: datum?.images[0]?.img || datum?.building?.images[0]?.img || 'https://images.cubicsearch.com/small%20spaces.png',
                        sf: statusAndSF,
                        id: datum?.id,
                        data: data
                    };
                });
                setOptions(options);
            } else {
                console.error('Failed to fetch spaces');
                setOptions([]);
            }
        } catch (error) {
            console.error('Error fetching spaces:', error);
            setOptions([]);
        } finally {
            setLoading(false);
        }
    }, 1000), []); // Dependencies array is empty, so this function is created only once


    useEffect(() => {
        if (inputValue) {
            fetchSpaces(inputValue);
        } else {
            setOptions([]);
        }
    }, [inputValue]);
    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
    };

    const handleChange = (event, newValue) => {
        setSelectedValue(null); // Update the explicitly tracked selected value
        handleSpaceChange(newValue); // Propagate changes upwards as needed
        setInputValue('')
    };

    return (
        <Autocomplete
            options={options}
            getOptionLabel={(option) => option.label || ''}
            value={selectedValue} // Use the explicitly tracked selected value
            onInputChange={handleInputChange}
            onChange={handleChange}
            noOptionsText={'type address of space...'} // Or an empty string ''
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {/* <Avatar src={option.avatar} sx={{ width: 24, height: 24, mr: 1 }} /> */}
                    <div style={{ marginRight: 10 }}>
                        <ImageWithAspectRatio src={option.avatar} name={option?.label} />
                    </div>
                    <div>
                        <div>
                            {option.label}
                        </div>
                        <div>
                            {option.space}
                        </div>
                        <div>
                            {option.sf}
                        </div>
                    </div>
                </Box>
            )}
            renderInput={(params) => {

                return (
                    <TextField

                        {...params}
                        label="Add a space"
                        placeholder='Add a space'
                        sx={{
                            "& .MuiInputBase-root": {
                                height: 100, // Set the desired height
                            },
                            "& .MuiInputBase-input": {
                                height: "100%",
                                padding: "0 14px", // Adjust padding as needed
                            }
                        }}
                        InputProps={{
                            ...params.InputProps,

                            startAdornment: (
                                <InputAdornment position="start">
                                    {<ImageWithAspectRatio src={selectedValue?.avatar || "https://images.cubicsearch.com/small%20spaces.png"} alt="" name={selectedValue?.label} />}
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )

            }
            }
            fullWidth
        />
    );
};

export const AutocompleteAsyncBuilding = ({ selectedbuilding, handleBuildingChange, contactId }) => {

    const [inputValue, setInputValue] = useState(null);
    const [options, setOptions] = useState([{
        label: selectedbuilding?.label, building: selectedbuilding?.building, avatar: selectedbuilding?.avatar, id: selectedbuilding?.id
    }]);
    const [selectedValue, setSelectedValue] = useState({ label: selectedbuilding?.label, building: selectedbuilding?.building, avatar: selectedbuilding?.avatar, id: selectedbuilding?.id }); // To track the selected value explicitly
    const [loading, setLoading] = useState(false);


    // Debounced function for API calls
    const fetchBuildings = useCallback(debounce(async (search) => {
        setLoading(true);
        try {
            const data = await getBuildings(contactId, search); // Replace with your actual API call
            if (data) {
                var options = data.map((datum) => {
                    var image = null;
                    if (datum.images.length > 0) {
                        datum.images.sort((a, b) => a.sortOrder - b.sortOrder);
                        image = datum.images[0].img;
                    }

                    return {
                        label: datum?.StreetNumber + " " +
                            datum?.StreetName + ", " + datum?.City + " " + datum?.State,
                        building:
                            datum?.StreetNumber + " " +
                            datum?.StreetName + ", " + datum?.City + " " + datum?.State
                        ,
                        avatar: datum?.images[0]?.img || 'https://images.cubicsearch.com/small%20logo.png',
                        propertyName: (datum?.PropertyName || datum?.CampusName ? (datum?.PropertyName || datum?.CampusName) : ""),
                        id: datum?.id
                    };
                });
                setOptions(options);
            } else {
                console.error('Failed to fetch buildings');
                setOptions([]);
            }
        } catch (error) {
            console.error('Error fetching buildings:', error);
            setOptions([]);
        } finally {
            setLoading(false);
        }
    }, 1000), []); // Dependencies array is empty, so this function is created only once


    useEffect(() => {
        if (inputValue) {
            fetchBuildings(inputValue);
        } else {
            setOptions([]);
        }
    }, [inputValue]);
    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
    };

    const handleChange = (event, newValue) => {
        setSelectedValue(newValue); // Update the explicitly tracked selected value
        handleBuildingChange(newValue); // Propagate changes upwards as needed
    };

    return (
        <Autocomplete
            options={options}
            getOptionLabel={(option) => option.label || ''}
            value={selectedValue} // Use the explicitly tracked selected value
            onInputChange={handleInputChange}
            noOptionsText={'type address of building...'} // Or an empty string ''
            onChange={handleChange}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {/* <Avatar src={option.avatar} sx={{ width: 24, height: 24, mr: 1 }} /> */}
                    <div style={{ marginRight: 10 }}>
                        <ImageWithAspectRatio src={option.avatar} name={option?.label} />
                    </div>
                    <div>
                        <div>
                            {option.label}
                        </div>
                        <div>
                            {option.building}
                        </div>
                        <div>
                            {option.sf}
                        </div>
                    </div>
                </Box>
            )}
            renderInput={(params) => {

                return (
                    <TextField

                        {...params}
                        label="Add a building"
                        placeholder='Add a building'
                        sx={{
                            "& .MuiInputBase-root": {
                                height: 100, // Set the desired height
                            },
                            "& .MuiInputBase-input": {
                                height: "100%",
                                padding: "0 14px", // Adjust padding as needed
                            }
                        }}
                        InputProps={{
                            ...params.InputProps,

                            startAdornment: (
                                <InputAdornment position="start">
                                    {<ImageWithAspectRatio src={selectedValue?.avatar || "https://images.cubicsearch.com/small%20logo.png"} alt="" name={selectedValue?.label} />}
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )
            }}
            fullWidth
        />
    );
};