import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import SearchBarCombo from './SearchBar/SearchBarCombo';

const LocationDialog = ({ open, onClose, properties, selectedSearchItem, setSelectedSearchItem, setProperties, user, searchResults, setSearchResults, controllers, setControllers, getSearchData }) => {
    // Placeholder function for handling "Turn On Location" action
   

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Your Location Is Turned Off</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To enhance your experience, you can turn on your location for your browser or this app, or you can enter a zip code or address to set as your location.
                </DialogContentText>
                {/* Placeholder for custom autocomplete component */}
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', maxWidth: 900, zIndex: 10 }} >
                    <SearchBarCombo
                        properties={properties}
                        selectedProperty={selectedSearchItem}
                        setSelectedProperty={setSelectedSearchItem}
                        getSearchData={getSearchData}
                        setSearchProperties={setProperties}
                        contactId={user.contact.id}
                        searchResults={searchResults}
                        setSearchResults={setSearchResults}
                        addProperties={null}
                        setAddingNewProperties={null}
                        height="h-16"
                        controllers={controllers}
                        setControllers={setControllers}



                    />
                </div>
            </DialogContent>
            <DialogActions>
                
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default LocationDialog;
