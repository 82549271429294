import axios from 'axios';

/**
 * Performs a GET request with axios and handles cancellation using an AbortController.
 * @param {string} query - The URL for the GET request.
 * @param {Object} controller - An object to manage the AbortController.
 * @returns {Promise} - Resolves with the response data or rejects with an error.
 */
export function getAxios(query, controller) {
    return new Promise(async (resolve, reject) => {
        try {
            // Abort any previous request
            if (controller.controller) {
                controller.controller.abort();
            }

            // Create a new AbortController
            controller.controller = new AbortController();

            // Perform the GET request
            const result = await axios.get(query, {
                signal: controller.controller.signal
            });

            resolve(result);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log("getAxios cancelled");
            }
            reject(error);
        }
    });
}

/**
 * Performs a POST request with axios and handles cancellation using an AbortController.
 * @param {string} query - The URL for the POST request.
 * @param {Object} json - The JSON payload for the POST request.
 * @param {Object} controller - An object to manage the AbortController.
 * @returns {Promise} - Resolves with the response data or rejects with an error.
 */
export function postAxios(query, json, controller) {
    return new Promise(async (resolve, reject) => {
        try {
            // Abort any previous request
            if (controller.controller) {
                controller.controller.abort();
            }

            // Create a new AbortController
            controller.controller = new AbortController();

            // Perform the POST request
            const result = await axios.post(query, json, {
                signal: controller.controller.signal
            });

            resolve(result);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log("postAxios cancelled");
            }
            reject(error);
        }
    });
}
