import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Avatar, IconButton, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress'; // Ensure this import is at the top
import { PhotoCamera, Close, ContentPaste } from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useUpload } from './useUpload';
function ImageUploadGallery({ initialImages = [], onSortEnd, onDeleted, action, onSuccess }) {
    const [open, setOpen] = useState(false);
    const [previews, setPreviews] = useState(
        []// initialImages.map(img => ({ ...img, progress: 0 }))
    );
    const { uploadFile } = useUpload();
    const fileInputRef = useRef(null);  // Create a ref for the file input
    const processFiles = (files, prepend = false) => {
        handleClose()
        files.forEach(file => {
            const preview = {
                id: `new-${Date.now()}`,
                name: file.name,
                url: URL.createObjectURL(file)
            };
            setPreviews(prev => prepend ? [preview, ...prev] : [...prev, preview]);
            uploadFile({
                file: file,
                action: action,
                onProgress: (progress) => {
                    setPreviews(currentPreviews => currentPreviews.map(p => {
                        return p.name === file.name ? { ...p, progress: progress } : p;
                    }));
                },
                onSuccess: (response) => {
                    console.log('Upload successful', response);
                    onSuccess(response)
                },
                onError: (error) => {
                    console.error('Upload error', error);
                }
            });
        });
    };

    // // Define handlePaste using useCallback to ensure it captures current state
    // const handlePaste = useCallback((e) => {
    //     e.preventDefault();
    //     const items = e.clipboardData.items;
    //     const files = Array.from(items)
    //         .filter(item => item.type.indexOf('image') >= 0)
    //         .map(item => item.getAsFile());

    //     processFiles(files, true);
    // }, [processFiles]); // Include all dependencies that affect handlePaste
    document.addEventListener('DOMContentLoaded', () => {
        const pasteButton = document.getElementById('pasteButton'); // Assuming you have a button with id 'pasteButton'
        if (pasteButton) {
            pasteButton.addEventListener('click', handlePasteAction);
        }
    });
    const handlePasteAction = async () => {
        if (!navigator.clipboard) {
            alert('Clipboard API is not available in your browser.');
            return;
        }

        try {
            const clipboardItems = await navigator.clipboard.read();
            let imageBlob = null;

            for (const item of clipboardItems) {
                for (const type of item.types) {
                    if (type.startsWith('image/')) {
                        imageBlob = await item.getType(type);
                        break;
                    }
                }
                if (imageBlob) break; // Exit the loop if an image is found
            }

            if (imageBlob) {
                processFiles([imageBlob], true); // Assuming processFiles is already handling file uploads
            } else {
                alert("No image data was found in your clipboard. Copy an image first or take a screenshot.");
            }
        } catch (err) {
            console.error('Failed to read clipboard contents:', err);
            alert('Failed to access clipboard. Please ensure clipboard access is allowed in your browser settings.');
        }
    };

    useEffect(() => {
        const initialPreviews = initialImages.map((url, index) => ({
            id: `item-${index}`,
            name: url.name,
            url: url.source
        }));
        setPreviews(initialPreviews);
    }, [initialImages]);

    // useEffect(() => {
    //     // Attach the event listener
    //     document.addEventListener('paste', handlePaste);

    //     // Clean up the event listener
    //     return () => {
    //         document.removeEventListener('paste', handlePaste);
    //     };
    // }, [handlePaste]); // Ensure this effect runs only when handlePaste changes

    const handleUploadButtonClick = () => {
        fileInputRef.current.click();  // Programmatically click the file input
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleFileChange = event => {
        const files = Array.from(event.target.files).filter(file => file.type.startsWith('image/'));
        processFiles(files);
    };

    const handleDragOver = e => {
        e.preventDefault(); // This is necessary to allow the drop
    };

    const handleDrop = e => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files).filter(file => file.type.startsWith('image/'));
        processFiles(files);
    };





    const handleRemove = (index) => {
        onDeleted(initialImages[index])
        setPreviews(prev => prev.filter((_, i) => i !== index));

    };

    const onDragEnd = (result) => {
        const { source, destination } = result;



        if (!destination) return;
        if (source.index !== destination.index) {
            const newItems = Array.from(previews);
            const [movedItem] = newItems.splice(source.index, 1);
            newItems.splice(destination.index, 0, movedItem);
            onSortEnd(newItems)
            setPreviews(newItems);
        }
    };


    return (
        <div style={{ marginBottom: 5 }}>



            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                overflowX: 'auto',  // Allows horizontal scrolling
                                maxWidth: '800px',
                                overflowY: 'hidden',
                                whiteSpace: 'nowrap',  // Prevents wrapping, keeping everything in a single line
                                padding: '10px',
                                gap: '10px',
                                border: '2px dashed #ccc'
                            }
                            }>
                            {previews.map((preview, index) => (
                                <Draggable key={preview.id} draggableId={preview?.id?.toString()} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                                ...provided.draggableProps.style,
                                                zIndex: snapshot.isDragging ? 1000 : 'auto',
                                                cursor: snapshot.isDragging ? 'grabbing' : 'grab',
                                                background: snapshot.isDragging ? 'lightgreen' : 'white',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '10px',  // Adjust gap for visual spacing
                                                padding: '10px',  // Padding for better visual appearance
                                            }}>
                                            <Avatar
                                                src={preview.url}
                                                alt={preview.name}
                                                style={{
                                                    width: 100,
                                                    height: 100,
                                                    borderRadius: 5,
                                                    objectFit: 'contain', // This will not apply directly to img but serves as a reference for what we need
                                                }}
                                                imgProps={{
                                                    style: { // Apply style directly to the img element
                                                        width: '100%',  // Ensure the image covers the area
                                                        height: '100%',
                                                        objectFit: 'contain'  // Make sure the image is fully contained within the avatar
                                                    }
                                                }}
                                            />

                                            <IconButton onClick={() => handleRemove(index)} edge="end" aria-label="delete">
                                                <Close />
                                            </IconButton>
                                            {preview.progress !== undefined && (
                                                <CircularProgress variant="determinate" value={preview.progress} size={20} />
                                            )}
                                            {/* Display progress text or a complete icon/message when done */}
                                            {preview.progress >= 100 ? (
                                                <span>Complete</span>
                                            ) : (
                                                preview?.progress && <span>{preview.progress}%</span>
                                            )}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>

                    )}
                </Droppable>
            </DragDropContext>
            <Typography variant="subtitle1" style={{ textAlign: 'center', margin: '10px 0' }}>
                Drag and drop pictures into the list to upload, or rearrange them by dragging left and right.
            </Typography>
            <Button
                startIcon={<PhotoCamera />}
                style={{marginBottom: 5}}
                variant="contained"
                onClick={handleUploadButtonClick}  // Use this handler instead of opening a dialog
            >
                Upload Images
            </Button>
            <input
                type="file"
                multiple
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                ref={fileInputRef}
                id="file-input"
            />
            <Button startIcon={<ContentPaste />} style={{marginLeft: 5, marginBottom: 5}} variant="contained" onClick={handlePasteAction}>
                Paste Images
            </Button>
        </div>
    );
}

export default ImageUploadGallery;
