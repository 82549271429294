import * as React from 'react';

import Dialog from '@mui/material/Dialog';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';





export default function ImageGalleryDialogue({ imageList, open, handleClose }) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


    return (

        <Dialog


            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'xl'}
            maxHeight={window.innerHeight * .8}


        >

            {imageList.map((item, i) =>


                <img src={item.original} key={i} style={{ padding: 20 }}

                />

            )

            }


        </Dialog>

    );
}