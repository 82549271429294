/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useState } from 'react'
import { Check as CheckIcon, ExpandMore as SelectorIcon } from '@mui/icons-material'
import { Combobox } from '@headlessui/react'

// const offices = [
//   { id: 1, name: 'Office 1' },
//   { id: 2, name: 'Office 2' },
//   { id: 3, name: 'Office 3' },
//   { id: 4, name: 'Office 4' },
//   { id: 5, name: 'Office 5' },
//   // More users...
// ]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function OfficeCombo({createNewCompany, offices, selectedOffice, setSelectedOffice, selectedCompany, setCreateNewOffice, setButtonText}) {


  const [query, setQuery] = useState('')
  //const [selectedOffice, setSelectedOffice] = useState( { id: 1, name: 'Broker' })
  if(!offices) {
    return
}

  const filteredoffices =
    query === ''
      ? offices.filter((office) => {
        return office.companyId === selectedCompany.id 
      })
      : offices.filter((office) => {
          return office.companyId === selectedCompany.id && office.name.toLowerCase().includes(query.toLowerCase())
        })

        if (createNewCompany) {

            //setSelectedOffice({id:1, name:query})
            setCreateNewOffice(true)
            setButtonText("Add New Company")
        }
        else if(filteredoffices.length > 0) {
          setCreateNewOffice(false)
          setButtonText("Select Company & Office")
        }
        else if (selectedCompany.id>0) {
          setCreateNewOffice(true)
          setButtonText("Add New Office")
        }


  return (
    <Combobox id="officeValue" as="div" value={selectedOffice} onChange={setSelectedOffice}>
      <Combobox.Label className="block text-sm font-medium text-gray-700">Office</Combobox.Label>
      <div className="relative mt-1">
        <Combobox.Input
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500 sm:text-sm"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(office) => office.name}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredoffices.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredoffices.map((office) => (
              <Combobox.Option
                key={office.id}
                value={office}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-8 pr-4',
                    active ? 'bg-red-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('pl-3 block truncate', selected && 'font-semibold')}>{office.name}</span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 left-0 flex items-center pl-1.5',
                          active ? 'text-white' : 'text-red-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}


