import Iframely from "../../Images/IFramely";
import { useState } from "react";
import { Share } from "@mui/icons-material";

import { mobileAndTabletCheck } from "../../Search/Deck";
import { Button } from "@mui/material";

export default function WebsiteCard({ website }) {
  const [open, setOpen] = useState(true);
  const [buttonText, setButtonText] = useState("Hide");
  var mysrcPost =
    website.src.includes("http") || website.src.includes("https://")
      ? website.src
      : "https://" + website.src;
  var url = mysrcPost.replace(
    "cubesearch.sfo3.digitaloceanspaces.com",
    "images.cubicsearch.com"
  );
  url = url.replace(
    "sfo3.digitaloceanspaces.com/cubesearch/",
    "https://images.cubicsearch.com/"
  );

  function changeState() {
    if (open) {
      setOpen(false);
      setButtonText("Show");
    } else {
      setOpen(true);
      setButtonText("Hide");
    }
  }


  return (
    <>
      <div className="flex flex-col w-full">
        <div className="flex items-center">
          <div>
            <Button variant={'outlined'}
              sx={{borderColor: 'black', color: 'black'}}
              onClick={changeState}
            >
              {buttonText}
            </Button>
            
          </div>
          <div className="px-2">
            <Button variant={'contained'}
              sx={{
                color: 'white', backgroundColor: 'red' , ':hover': {
                  bgcolor: 'white', // theme.palette.primary.main
                  color: 'red',
                },
}}
              onClick={()=>{navigator.clipboard.writeText(url)
                            alert("Link copied to clipboard!")}}
            >
              <Share style={{height: 26}}/>
            </Button>

          </div>
          <div className="w-half">
            <h1 className="px-2 w-full text-xl font-semibold text-gray-900">
              {website.Description}
            </h1>
          </div>
        </div>

        <div style={{borderRadius: 5}} className="w-full border-2 py-2 px-2">
          {/* <div className="w-full"> */}
          {open ? (
            // <div className="w-full">
            <>
              {/* <Iframely url={url} description={website.Description} /> */}
              {mobileAndTabletCheck() ? (
               ( (url.indexOf(".mov") >0) ||
                (url.indexOf(".MOV") >0)||
                (url.indexOf(".mp4")>0) ||
                (url.indexOf(".MP4") >0))? (
                  <video className="w-full" controls={true} autoPlay={false}>
                    <source src={url} type="video/mp4"></source>
                  </video>
                ) : (
                  <Iframely url={url} description={website.Description} />
                )
              ) : url.indexOf(".pdf") > 0 &&
                url.indexOf("images.cubicsearch.com") > 0 ? (
                <object
                  className="w-full min-h-most-screen"
                  type="application/pdf"
                  data={url}
                ></object>
              ) : ((url.indexOf(".mov") >0) ||
              (url.indexOf(".MOV") >0)||
              (url.indexOf(".mp4")>0) ||
              (url.indexOf(".MP4") >0)) ? (
                <video className="w-full" controls={true} autoPlay={false}>
                  <source src={url} type="video/mp4"></source>
                </video>
              ) : (
                <div>
                  <object
                    className="w-full min-h-half-screen"
                    type="text/html"
                    data={url}
                  >
                    {" "}
                    <Iframely url={url} description={website.Description} />
                  </object>
                </div>
                //
              )}
              {/* <iframe src={mysrc} title = {website.Description} width="100%" height="600" allowFullScreen="true"></iframe>  */}
              {/* : ""</div>} */}
            </> // </div>
          ) : (
            ""
          )}
          {/* </div> */}
        </div>
      </div>
    </>
  );
}
