import { InputComponent } from "./InputComponent";

export default function ContactNew({NameIn}) {
    return (
        <div className="px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                First and Last Name
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                <InputComponent
                  autofocus
                  inputType={"placeholder"}
                  defaultShow={"First Name"}
                  id={"id"}
                  name={"FirstName"}
                  valueIn={NameIn}
                />{" "}
                <InputComponent
                  
                  inputType={"placeholder"}
                  defaultShow={"Last Name"}
                  id={"id"}
                  name={"LastName"}
                />{" "}
    
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Title</dt>
              <dd className="mt-1 text-sm text-gray-900">
              <InputComponent
                  
                  inputType={"placeholder"}
                  defaultShow={"Title"}
                  id={"id"}
                  name={"Title"}
                />{" "}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Phone</dt>
              <dd className="mt-1 text-sm text-gray-900">
              <InputComponent
                  
                  inputType={"placeholder"}
                  defaultShow={"Phone"}
                  id={"id"}
                  name={"Phone"}
                />{" "}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Email</dt>
              <dd className="mt-1 text-sm text-gray-900">
              <InputComponent
                  autofocus
                  inputType={"placeholder"}
                  defaultShow={"Email"}
                  id={"id"}
                  name={"Email"}
                />{" "}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Website</dt>
              <dd className="mt-1 text-sm text-gray-900">
              <InputComponent
                  autofocus
                  inputType={"placeholder"}
                  defaultShow={"Website"}
                  id={"id"}
                  name={"Website"}
                />{" "}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Linkedin</dt>
              <dd className="mt-1 text-sm text-gray-900">
              <InputComponent
                  autofocus
                  inputType={"placeholder"}
                  defaultShow={"LinkedIn"}
                  id={"id"}
                  name={"Linkedin"}
                />{" "}
              </dd>
            </div>
    
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Profile Summary</dt>
              <dd className="mt-1 text-sm text-gray-900">
                <textarea
                  id="SpaceNotes"
                  name="SpaceNotes"
                  rows={3}
                  className="max-w-lg shadow-sm block w-full focus:ring-red-500 focus:border-red-500 sm:text-sm border border-gray-300 rounded-md"
                  defaultValue={""}
                />
                <p className="mt-2 text-sm text-gray-500">
                  Write a few sentences about the this person.
                </p>
              </dd>
            </div>
          </dl>
        </div>
      );
}