import React, { useState } from 'react';
import { Button, Avatar, FormControl, InputLabel, MenuItem, Select, Dialog, DialogTitle, ListItemIcon, Typography, DialogContent, DialogActions, List, ListItem, IconButton, ListItemAvatar, ListItemText } from '@mui/material';
import { AutocompleteAsyncCompany, AutocompleteAsyncGeneral, AutocompleteAsyncSpace, AutocompleteAsyncBuilding } from './AutocompleteAsync';
import DeleteIcon from '@mui/icons-material/Delete';
import { ImageWithAspectRatio } from './AutocompleteAsync';
import ProspectOptionsDialog from './ProspectOptionsDialog'
import axios from 'axios';
import CampaignForm from "./CampaignForm"
import NewEmailCampaignDialog from './NewEmailCampaignDialog';



const PersonModal = ({ open, onClose, contact, contactId, handleParentClose, handleGrandParentClose, fetchCampaignsAndClients }) => {
    const [selectedContact, setSelectedContact] = useState({ name: contact.FirstName + " " + contact.LastName, email: contact.Email, avatar: contact.images[0]?.img || null, id: contact.id })
    const [showProspectDialog, setShowProspectDialog] = useState(false)

    return (
        <Dialog
            maxWidth={'md'} open={open} onClose={onClose}>
            <DialogTitle sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 20, marginRight: 20 }}>
                <Avatar src='https://images.cubicsearch.com/UserLogo.png' >
                </Avatar> Select a Person
            </DialogTitle>
            <DialogContent sx={{ marginTop: 5, padding: 5 }}>
                <div style={{ padding: 5 }}>
                    <AutocompleteAsyncGeneral selectedcontact={selectedContact}
                        handleContactChange={(newContact) => {

                            setSelectedContact(newContact)
   
                        }
                        } contactId={contactId} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={() => { setShowProspectDialog(true) }} sx={{ marginRight: 1 }}>New Campaign</Button>
                <Button variant='contained' onClick={() => { onClose() }} sx={{ marginRight: 5 }}>Cancel</Button>
            </DialogActions>
            <NewEmailCampaignDialog id={contactId} open={showProspectDialog} onNew={() => {
                handleParentClose()
                handleGrandParentClose()
                fetchCampaignsAndClients()
            }}
                setOpen={setShowProspectDialog} entityType={'Contact'} entityIds={[selectedContact]} />
            {/* <ProspectOptionsDialog open={showProspectDialog} onClose={() => { setShowProspectDialog(false) }} /> */}
            {/* Implement person selection logic here */}
        </Dialog>
    );
}
const CompanyModal = ({ open, onClose, company, contactId, handleParentClose, handleGrandParentClose, fetchCampaignsAndClients }) => {
    const [selectedCompany, setSelectedCompany] = useState({ name: company?.Name, website: company?.Website, avatar: company?.images[0]?.img || null, id: company?.id })
    const [showProspectDialog, setShowProspectDialog] = useState(false)
    return (
        <Dialog
            maxWidth={'md'} open={open} onClose={onClose}>
            <DialogTitle sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 20, marginRight: 20 }}>
                <Avatar src='https://images.cubicsearch.com/CompanyLogo.png' >
                </Avatar> Select a Company
            </DialogTitle>
            <DialogContent sx={{ marginTop: 5, padding: 5 }}>
                <div style={{ padding: 5 }}>
                    <AutocompleteAsyncCompany selectedcompany={selectedCompany}
                        handleCompanyChange={(newCompany) => {
                            setSelectedCompany(newCompany)
      
                        }
                        } contactId={contactId} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={() => { setShowProspectDialog(true) }} sx={{ marginRight: 1 }}>New Campaign</Button>
                <Button variant='contained' onClick={() => { onClose() }} sx={{ marginRight: 5 }}>Cancel</Button>
            </DialogActions>
            <NewEmailCampaignDialog id={contactId} open={showProspectDialog} setOpen={setShowProspectDialog}
                entityType={'Company'} entityIds={[selectedCompany]}
                onNew={() => {
                    handleParentClose()
                    handleGrandParentClose()
                    fetchCampaignsAndClients()
                }}/>
            {/* <ProspectOptionsDialog open={showProspectDialog} onClose={() => { setShowProspectDialog(false) }} /> */}
            {/* Implement company selection logic here */}
        </Dialog>
    );
}

const SpaceModal = ({ open, onClose, space, contactId, handleParentClose, handleGrandParentClose, fetchCampaignsAndClients }) => {
    const [showProspectDialog, setShowProspectDialog] = useState(false)
    const [selectedSpaces, setSelectedSpaces] = useState([]);

    // Handler to add a new space to the list
    const handleAddSpace = (newSpace) => {
        if (newSpace?.id) {
            setSelectedSpaces(prevSpaces => [...prevSpaces, newSpace]);
        }
  
    

    };

    // Handler to remove a space from the list
    const handleRemoveSpace = (indexToRemove) => {
        setSelectedSpaces(prevSpaces => prevSpaces.filter((_, index) => index !== indexToRemove));
    };

    const handleProspectSelection = async () => {
        await axios.post(process.env.REACT_APP_DATABASE + "api/getProspects", { contactId: contactId, entityArray: selectedSpaces, entityType: 'space' })
    }

    return (
        <Dialog maxWidth={'md'} open={open} onClose={onClose}>
            <DialogTitle sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 20, marginRight: 20 }}>
                Add Spaces to Marketing Campaign
            </DialogTitle>
            <DialogContent sx={{ marginTop: 5, padding: 5 }}>
                <div style={{ padding: 5 }}>
                    {/* Your Autocomplete component for selecting a space */}
                    <AutocompleteAsyncSpace
                        handleSpaceChange={handleAddSpace}
                        contactId={contactId}
                    />
                </div>
                {/* List selected spaces */}
                <Typography>Selected Spaces</Typography>
                <List>
                    {selectedSpaces.map((space, index) => (
                        <ListItem
                            key={index}
                            secondaryAction={
                                <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveSpace(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            }
                        >
                            <ListItemAvatar>
                                <ImageWithAspectRatio src={space?.avatar || 'https://images.cubicsearch.com/small%20spaces.png'} />
                            </ListItemAvatar>
                            <ListItemText primary={space?.label} />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={async () => {

                    setShowProspectDialog(true)
                }} sx={{ marginRight: 1 }}>New Campaign</Button>
                <Button variant='contained' onClick={onClose} sx={{ marginRight: 5 }}>Cancel</Button>
            </DialogActions>
            <NewEmailCampaignDialog id={contactId} open={showProspectDialog} setOpen={setShowProspectDialog}
                entityType={'Space'} entityIds={selectedSpaces}
                onNew={() => {
                    handleParentClose()
                    handleGrandParentClose()
                    fetchCampaignsAndClients()
                }}/>
            {/* <ProspectOptionsDialog open={showProspectDialog} onSelectOption={handleProspectSelection} onClose={() => { setShowProspectDialog(false) }} /> */}
        </Dialog>
    );
};
const BuildingModal = ({ open, onClose, building, contactId, handleParentClose, handleGrandParentClose, fetchCampaignsAndClients }) => {
    const [showProspectDialog, setShowProspectDialog] = useState(false)
    const [selectedBuildings, setSelectedBuildings] = useState([]);

    // Handler to add a new building to the list
    const handleAddBuilding = (newBuilding) => {
        if (newBuilding?.id) {
            setSelectedBuildings(prevBuildings => [...prevBuildings, newBuilding]);
        }

   
      
    };

    // Handler to remove a building from the list
    const handleRemoveBuilding = (indexToRemove) => {
        setSelectedBuildings(prevBuildings => prevBuildings.filter((_, index) => index !== indexToRemove));
    };

    return (
        <Dialog maxWidth={'md'} open={open} onClose={onClose}>
            <DialogTitle sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 20, marginRight: 20 }}>
                Add Buildings to Marketing Campaign
            </DialogTitle>
            <DialogContent sx={{ marginTop: 5, padding: 5 }}>
                <div style={{ padding: 5 }}>
                    {/* Your Autocomplete component for selecting a building */}
                    <AutocompleteAsyncBuilding
                        handleBuildingChange={handleAddBuilding}
                        contactId={contactId}
                    />
                </div>
                {/* List selected buildings */}
                <Typography>Selected Buildings</Typography>
                <List>
                    {selectedBuildings.map((building, index) => (
                        <ListItem
                            key={index}
                            secondaryAction={
                                <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveBuilding(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            }
                        >
                            <ListItemAvatar>
                                <ImageWithAspectRatio src={building?.avatar || 'https://images.cubicsearch.com/small%logo.png'} />
                            </ListItemAvatar>
                            <ListItemText primary={building?.label} />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={() => {
                    setShowProspectDialog(true)

                }} sx={{ marginRight: 1 }}>New Campaign</Button>
                <Button variant='contained' onClick={onClose} sx={{ marginRight: 5 }}>Cancel</Button>
            </DialogActions>
            <NewEmailCampaignDialog open={showProspectDialog} setOpen={setShowProspectDialog}
                entityType={'Building'} entityIds={selectedBuildings} id={contactId}
                onNew={() => {
                    handleParentClose()
                    handleGrandParentClose()
                    fetchCampaignsAndClients()
                }}/>
            {/* <ProspectOptionsDialog open={showProspectDialog} onClose={() => { setShowProspectDialog(false) }} /> */}
        </Dialog>
    );
};


export const EntityTypeSelectionForm = ({ contact, handleParentClose, fetchCampaignsAndClients }) => {
    const [entityType, setEntityType] = useState('');
    const [openModal, setOpenModal] = useState(false);

    const handleChange = (event) => {
        setEntityType(event.target.value);
        setOpenModal(true); // Open modal after selection
    };

    const handleCloseModal = () => {
        setOpenModal(false);

    };
    const menuItemImages = {
        person: "https://images.cubicsearch.com/UserLogo.png",
        company: "https://images.cubicsearch.com/CompanyLogo.png",
        space: "https://images.cubicsearch.com/small%20spaces.png",
        building: "https://images.cubicsearch.com/small%20logo.png",
    };

    return (
        <FormControl fullWidth>
            <InputLabel id="entity-type-select-label">Select Entity   </InputLabel>
            <Select
                labelId="entity-type-select-label"
                id="entity-type-select"
                value={entityType}
                label="Entity Type"
                onChange={handleChange}
            >
                {Object.entries(menuItemImages).map(([key, imageUrl]) => (
                    <MenuItem sx={{ display: 'flex', flexDirection: 'row' }} value={key} key={key}>
                        <ListItemIcon>
                            <img src={imageUrl} alt={key} style={{ borderRadius: '4px', marginRight: '10px', height: 20, width: 20 }} />
                            <Typography variant="inherit">{key.charAt(0).toUpperCase() + key.slice(1)}</Typography>
                        </ListItemIcon>


                    </MenuItem>
                ))}
            </Select>

            {/* Modals based on selection */}
            {entityType === 'person' && (
                <PersonModal open={openModal} onClose={handleCloseModal} contact={contact} contactId={contact.id}
                    handleParentClose={handleCloseModal} handleGrandParentClose={handleParentClose}
                    fetchCampaignsAndClients={fetchCampaignsAndClients} />
            )}
            {entityType === 'company' && (
                <CompanyModal open={openModal} onClose={handleCloseModal} company={contact?.office?.company}
                    contactId={contact.id} handleParentClose={handleCloseModal} handleGrandParentClose={handleParentClose}
                    fetchCampaignsAndClients={fetchCampaignsAndClients} />
            )}
            {entityType === 'space' && (
                <SpaceModal open={openModal} onClose={handleCloseModal} contactId={contact.id}
                    handleParentClose={handleCloseModal} handleGrandParentClose={handleParentClose}
                    fetchCampaignsAndClients={fetchCampaignsAndClients} />
            )}
            {entityType === 'building' && (
                <BuildingModal open={openModal} onClose={handleCloseModal} contactId={contact.id}
                    handleParentClose={handleCloseModal} handleGrandParentClose={handleParentClose}
                    fetchCampaignsAndClients={fetchCampaignsAndClients} />
            )}

        </FormControl>
    );
};

