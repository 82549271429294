import * as React from "react";
import {  arrayMove } from "react-movable";
import DemoCarousel from "../../Images/Carousel";
import MyGroupSelectModal from "../../MyGroups/SelectGroupModal/MyGroupSelectModal";

import { mobileAndTabletCheck } from "../../Search/Deck";
import { Typography, Avatar, Paper } from "@mui/material";
import { Tooltip } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Button, Box } from "@mui/material";
import {Add as AddIcon} from '@mui/icons-material'


export default function AvailableGroupsEditTable({
  groupsIn,
  availId,
  setGroupsIn,
  contactId,

  highestPermission,
  setData
}) {
  
  const [showGroupModal, setShowGroupModal] = React.useState(false);
  const [items, setItems] = React.useState(mapArray(groupsIn));
  //test - console.log("rebuilding Edit Table");
  //test - console.log(highestPermission);


  const triggerSetItems = (newGroupsIn) => {
    // this forces the formatted table to refresh
    //test - console.log("I'm setting the formatted array!");
    setItems(mapArray(newGroupsIn));
  };

  const moveBothArrays = (formattedArray, oldIndex, newIndex, availId) => {
    setItems(arrayMove(formattedArray, oldIndex, newIndex));
    setGroupsIn(arrayMove(groupsIn, oldIndex, newIndex));
    orderGroups(arrayMove(groupsIn, oldIndex, newIndex), setGroupsIn, availId);
  };





  return (
    <>
      <Box sx={{ pt: 8, pb: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="error"
          onClick={() => setShowGroupModal(true)}
          startIcon={<AddIcon />} // This uses Material-UI's Add icon, replace with FontAwesome if needed
          sx={{
            textTransform: 'none', // Prevents uppercase styling by default
            boxShadow: 1, // Applies box shadow
            ':hover': {
              bgcolor: 'red.700', // Changes background on hover
            },
            ':focus': {
              outline: 'none', // Removes outline
              ring: 2, // Adds focus ring
              ringColor: 'red.500', // Color of the ring
              ringOffset: 2, // Offset of the ring
            },
          }}
        >
          Add Group
        </Button>
      </Box>


      <Box sx={{ height: '100%', margin: 3 }}>
        {groupsIn.map((group, index) => {

          return (
            <>
              <Paper>
                <Box>
                  <Grid2 container spacing={2}>

                    <Grid2><Typography component={'div'}sx={{color:'white', backgroundColor:'red', borderRadius:20, padding: 1}}>{group.GroupName}</Typography></Grid2>
                    <Grid2>


                      <Button

                        onClick={() => {

                          index !== "undefined"
                            ? removeFromDBandArray(
                              groupsIn,
                              availId,
                              setGroupsIn,
                              index,
                              contactId
                            )
                            : mapArray(groupsIn);

                        }}>

                        Delete

                      </Button>

                      <Button
                        data-tip

                        onClick={async () => {

                          var myArray = mapArray(groupsIn);

                          window.open("group?id=" + group.id, mobileAndTabletCheck() ? "_self" : null);
                        }}
                      >
                        Open
                      </Button>

                    </Grid2>
                  </Grid2>



                </Box>
              </Paper>
            </>)
        }

        )}
      </Box>

      <MyGroupSelectModal
        open={showGroupModal}
        setOpen={setShowGroupModal}

        contactId={contactId}
        entityId={availId}
        entity={"available"}
        groupsIn={groupsIn}
      
        triggerSetItems={triggerSetItems}
        highestPermission={highestPermission}
        setData={setData}
        setGroupsIn={setGroupsIn}
      />
    </>
  );
}

export function arrayRemove(array, index) {

  array = array.slice();

  array.splice(index, 1);

  return array;
}

function mapArray(groupIn, setItems) {
  var myArray = [];
  var nfObject = new Intl.NumberFormat("en-US");
  var nfObjectRate = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  var options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",

    hour12: false,
    timeZone: "America/Los_Angeles",
  };
  var nfObjectDate = new Intl.DateTimeFormat("en-US");


  groupIn.map((group) =>
    myArray.push(
      <>
        <Paper elevation={4}>
          <Grid2 container key={group.id} GroupName={group.GroupName}>
            <Grid2
              className="text-left  hover:cursor-pointer"
              onClick={async () => {
                //const {Base64} = require('js-base64');
                // var dataObjectBase64 = Base64.encode(group.id.toString());
                window.open("group?id=" + group.id, mobileAndTabletCheck() ? "_self" : null);
                //    await Alert('Alert text', 'Alert title');
              }}
            >

              {group.images ? (
                <DemoCarousel
                  key={group.id}
                  images={group.images}
                  goToURL={"group?id=" + group.id}
                />
              )
                : (
                  <Typography component={'div'}variant="h5" sx={{ display: 'flex', padding: 1, backgroundColor: '#4e46e5', color: 'white' }}>  <Tooltip placement="top" title={group.GroupName}>
                    <Avatar alt={group.GroupName}

                      onClick={() => { window.open("group?id=" + group.id, mobileAndTabletCheck() ? "_self" : null) }}
                      sx={{ cursor: 'pointer', marginRight: 3 }}
                      src={
                        group.images.length > 0
                          ? group.images.sort(
                            (a, b) => a.sortOrder - b.sortOrder
                          )[0].img
                          : null
                      }

                    >{group.GroupName && group.GroupName.length > 0 && group.GroupName[0]}</Avatar>
                  </Tooltip>{group.GroupName} </Typography>
                )}
            </Grid2>


            <Grid2

              onClick={async () => {

                window.open("group?id=" + group.id, mobileAndTabletCheck() ? "_self" : null);

              }}
            >
              <div className="px-6 ">
                <div className="font-medium text-gray-900">
                  {group.GroupName
                    ? group.GroupName
                    : "Untitled Group Created " +
                    group.createdAt}
                </div>
                {/* <div className="font-medium text-gray-500"> {group.Suite !=='0' && group.Suite? "Suite " + group.Suite: "Please Add Suite"}</div>  */}
              </div>
            </Grid2>
            <Grid2>
              Created:{" "}
              {nfObjectDate.format(
                new Date(group.createdAt)
              ) || "No Recorded Date"}
            </Grid2>

            <Grid2>
              Permission:{" "}
              {group.avail_groups[0].groupPermission ||
                "No Recorded Date"}
            </Grid2>

          </Grid2>
        </Paper>
      </>
    )
  );

  return myArray;
}

async function deleteGroup(groupId, availId, contactId) {
  var qs = "groupId=" + groupId + "&availId=" + availId + '&contactId=' + contactId;

  var resultInfo = await fetch(
    process.env.REACT_APP_DATABASE + "api/markDeleteAvailGroup?" +
    qs
  );
}

async function orderGroups(groups, setGroupsIn, availId) {


  groups.map(async (group, index) => {

    var qs =
      "groupId=" + group.id + "&sortOrder=" + index + "&availId=" + availId;

    var resultInfo = await fetch(
      process.env.REACT_APP_DATABASE + "api/sortAvailGroups?" +
      qs
    );
  });
  setGroupsIn(groups);

  return 1;
}

const removeFromDBandArray = (groupsIn, availId, setGroupsIn, index, contactId) => {
  var newDataIn = [...groupsIn];

  deleteGroup(newDataIn[index].id, availId, contactId);
  setGroupsIn(arrayRemove(newDataIn, index));


};
