import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button,
    Typography, Grid, Card, CardContent, CardMedia, IconButton, Box
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PaymentMethodChange from './PaymentChangeForm';

const CustomerSubscriptionsDialog = ({ open, handleClose, contact }) => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState({});
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);

    const handleDialogOpen = (subscription) => {
        setSelectedSubscription(subscription);
        setPaymentDialogOpen(true);
    };

    const handlePaymentDialogClose = () => {
        setPaymentDialogOpen(false);
        setSelectedSubscription(null);
    };

    useEffect(() => {
        const fetchSubscriptions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_DATABASE}api/customer-subscriptions/${contact.Email}`);
                setSubscriptions(response.data);
            } catch (error) {
                console.error('Error fetching subscriptions:', error);
            }
        };

        if (open) {
            fetchSubscriptions();
        }
    }, [open, contact]);

    useEffect(() => {
        const fetchPaymentMethodDetails = async (paymentMethodId) => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_DATABASE}api/getPaymentMethodDetails`, {
                    paymentMethodId,
                });
                setPaymentMethods((prev) => ({
                    ...prev,
                    [paymentMethodId]: response.data.paymentMethod,
                }));
            } catch (error) {
                console.error('Error fetching payment method details:', error);
            }
        };

        subscriptions.forEach((subscription) => {
            if (subscription.default_payment_method) {
                fetchPaymentMethodDetails(subscription.default_payment_method);
            }
        });
    }, [subscriptions]);

    const cancelSubscription = async (subscriptionId) => {
        try {
            await axios.post(`${process.env.REACT_APP_DATABASE}api/cancel-subscription`, { subscriptionId });
            setSubscriptions(subscriptions.filter(sub => sub.id !== subscriptionId));
            alert('Subscription cancelled successfully.');
        } catch (error) {
            console.error('Error cancelling subscription:', error);
        }
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>
                    Manage Your Subscriptions
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', marginTop: 2 }}>
                        <Typography variant="caption" sx={{ marginRight: 1 }}>
                            Powered by Stripe
                        </Typography>
                        <img src="https://images.cubicsearch.com/Logos/Stripe.png" alt="Stripe logo" style={{ height: '20px' }} />
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    {subscriptions.length > 0 ? (
                        <Grid container spacing={2}>
                            {subscriptions.map((subscription) => {
                                const paymentMethod = paymentMethods[subscription.default_payment_method];

                                return (
                                    <Grid item xs={12} sm={6} key={subscription.id}>
                                        <Card>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image={subscription.metadata.campaign_photo_url}
                                                alt={subscription.metadata.campaign_name}
                                            />
                                            <CardContent>
                                                <Typography variant="h6">{subscription.metadata.campaign_name}</Typography>
                                                <Typography variant="body2">
                                                    {subscription.plan.amount / 100} {subscription.plan.currency.toUpperCase()} per {subscription.plan.interval}
                                                </Typography>
                                                <Typography variant="body2">
                                                    Renews on: {new Date(subscription.current_period_end * 1000).toLocaleDateString()}
                                                </Typography>
                                                <Typography variant="body2">
                                                    Payment Method:{' '}
                                                    {paymentMethod
                                                        ? `${paymentMethod.card.brand.toUpperCase()} •••• ${paymentMethod.card.last4}`
                                                        : subscription.default_payment_method}
                                                </Typography>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleDialogOpen(subscription)}
                                                    sx={{ mt: 2, mr: 1 }}
                                                >
                                                    Change Payment Method
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => cancelSubscription(subscription.id)}
                                                    sx={{ mt: 2 }}
                                                >
                                                    Cancel Plan
                                                </Button>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    ) : (
                        <Typography>No subscriptions available.</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={paymentDialogOpen} onClose={handlePaymentDialogClose} maxWidth="sm" fullWidth>
                <DialogTitle>Change Payment Method
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', marginTop: 2 }}>
                        <Typography variant="caption" sx={{ marginRight: 1 }}>
                            Powered by Stripe
                        </Typography>
                        <img src="https://images.cubicsearch.com/Logos/Stripe.png" alt="Stripe logo" style={{ height: '20px' }} />
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {selectedSubscription && (
                        <PaymentMethodChange
                            subscriptionId={selectedSubscription.id}
                            customerId={selectedSubscription.customer}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePaymentDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CustomerSubscriptionsDialog;
