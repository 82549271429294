import React, { useEffect, useState } from 'react';
import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    TextField, Typography, Grid, Paper
} from '@mui/material';
import axios from 'axios';

const CampaignSubscription = ({ campaign, contact, isActive, images }) => {
    const [open, setOpen] = useState(false);
    const [invoiceEmail, setInvoiceEmail] = useState('');
    const [paymentLinks, setPaymentLinks] = useState({ monthlyUrl: '', annualUrl: '' });
    const [image, setImage] = useState(null);

    useEffect(() => {
        setImage(images?.[0]);
    }, [images]);

    const handleDialogOpen = async () => {
     
        setOpen(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_DATABASE}api/createPaymentSessions`, {
                image: image?.img,
                campaign: campaign,
                contact: contact,
                return_url: window.location.href
            });
            setPaymentLinks(response.data);
        } catch (error) {
            alert('Error fetching payment links: ' + error.message);
        }
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    const handleSendInvoice = async (term) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_DATABASE}api/createSubscriptionAndInvoice`, {
                customerEmail: invoiceEmail,
                isRecurring: true,
                term: term,
                campaignDetails: {
                    amount: campaign.amount,
                    description: campaign.description,
                    image: image?.img,
                    campaign: campaign
                }
            });

            if (response.data.success) {
                alert('Invoice sent successfully!');
            } else {
                alert('Failed to send invoice.');
            }
        } catch (error) {
            alert('Error sending invoice: ' + error.message);
        }

        handleDialogClose();
    };

    return (
        <>
            {(campaign.subscriptionId === null || !isActive)&& (
                <Box
                    onClick={(event) => {
                        if (contact.id !== 998) {
                            event.stopPropagation();
                        }
                    }}
                    sx={{
                        // position: 'absolute',
                        // top: 0,
                        // left: 0,
                        width: '100%',
                        // height: '100%',
                        // backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 1,
                        marginBottom: 5
                    }}
                >
                <Button variant="contained" color="primary" onClick={(event) => {
                    event.stopPropagation()
                    handleDialogOpen()
                }}>
                        Subscribe To Unlock
                    </Button>
                 </Box>
            )}

            <Dialog onClick={(event)=>{event.stopPropagation()}} open={open} onClose={handleDialogClose} maxWidth="md" fullWidth>
                <DialogTitle>Subscribe to Unlock</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        If you are paying, please select how to proceed with the subscription.
                    </DialogContentText>
                    <Grid container spacing={3} sx={{ mb: 3 }}>
                        <Grid item xs={12} md={6}>
                            <Paper elevation={3} sx={{ padding: 3 }}>
                                <Typography variant="h6">Monthly Plan</Typography>
                                <Typography variant="subtitle1">$49.95/month</Typography>
                                <Button variant="contained" color="primary" href={paymentLinks.monthlyUrl} target="_blank">
                                    Choose Monthly Plan
                                </Button>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper elevation={3} sx={{ padding: 3 }}>
                                <Typography variant="h6">Annual Plan</Typography>
                                <Typography variant="subtitle1">$450/year</Typography>
                                <Button variant="contained" color="primary" href={paymentLinks.annualUrl} target="_blank">
                                    Choose Annual Plan
                                </Button>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Box>
                        <Typography variant="h6">Send the Invoice to Client or Admin to Pay for Campaign Subscription</Typography>
                        <Typography variant='body2' sx={{color:'darkgray'}}>Use this option if an outside party is handling the payments for this campaign, such as a client or budget administrator.</Typography>
                        <Typography variant='body2' sx={{ color: 'darkgray' }}>Activiation occurs on payment. Cubic Search will send subscription invoices.</Typography>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Payor Email Address"
                            type="email"
                            fullWidth
                            variant="outlined"
                            value={invoiceEmail}
                            onChange={(e) => setInvoiceEmail(e.target.value)}
                            sx={{ mb: 2 }}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    onClick={() => handleSendInvoice('yearly')}
                                >
                                    Send Invoice Annually
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    onClick={() => handleSendInvoice('monthly')}
                                >
                                    Send Invoice Monthly
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CampaignSubscription;
