import * as React from "react";
import { List, arrayMove } from "react-movable";
import ProjectContactSelectModal from "./ProjectContactSelectModal";
import { formatPhoneNumber } from "../Common/FormatPhoneNumber";
import { Folder as FolderOpenIcon, Delete as TrashIcon } from "@mui/icons-material"

import MissingFieldsSplash from "../Common/MissingFieldsSplash";
import { useState } from "react";

export default function ProjectContactEditTable({
  peopleIn,
  setPeopleIn,
  projectId,
  bigDataIn,
  // peopleCombo,
  contactId,
  groupsIn,
  highestPermission,
}) {
  //test - console.log("Edit Table before Map array");
  const [noGroups, setNoGroups] = useState(false);
  const removeFromBothArrays = (
    index,
    newPeopleIn,
    groupContactId,
    groupContactArrayIndex
  ) => {
    // contactId is not coming through
    var newDataIn = [...peopleIn];

    if (newDataIn.length === 0) {
      // if you have just added the contact for some reason it will show up here with no visibility to the peopleIn parameter
      newDataIn = [...newPeopleIn];
    }
    var myContactId = contactId;

    if (newDataIn.length > 0) {
      deleteProjectContactRelationship(
        newDataIn[index].project_contact.id,
        projectId,
        newDataIn[index],
        myContactId,
        groupContactId
      );

      newDataIn[index].group_contacts = arrayRemove(
        newDataIn[index].group_contacts,
        groupContactArrayIndex
      );
      if (newDataIn[index].group_contacts.length === 0) {
        newDataIn = arrayRemove(newDataIn, index);
      }
      // setPeopleIn(arrayRemove(newDataIn, index));
      setPeopleIn(newDataIn);
      // return arrayRemove(items, index);
      setItems(mapArray(newDataIn, removeFromBothArrays));
    }
  };

  const [items, setItems] = React.useState(
    mapArray(peopleIn, removeFromBothArrays)
  );

  const [showContactModal, setShowContactModal] = React.useState(false);

  const triggerSetItems = (newPeopleIn) => {
    // this forces the formatted table to refresh
    setPeopleIn(newPeopleIn);

    //test - console.log("I'm setting the formatted array!");
    setItems(mapArray(newPeopleIn, removeFromBothArrays));
  };

  const moveBothArrays = (formattedArray, oldIndex, newIndex) => {
    setItems(arrayMove(formattedArray, oldIndex, newIndex));
    setPeopleIn(arrayMove(peopleIn, oldIndex, newIndex));
    orderContacts(arrayMove(peopleIn, oldIndex, newIndex));
  };

  const addMe = () => {
    setShowContactModal(true);
  };
  //
  function mapArray(peopleIn, removeFromBothArrays) {
    var myArray = [];

    peopleIn.map((person, index) =>
      myArray.push(
        <div key={person.id} className="grid grid-cols-5 flex-items-center">
          <div className="flex items-center whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
            <div className="flex items-center">
              {/* <div style={imagecropper}>
                   <img style={profilepic} src={person.image.img} alt="" />
              </div> */}
              <div className="h-10 w-10 rounded-full">
                <img
                  className="h-10 w-10 flex-shrink-0 rounded-full object-cover"
                  src={
                    person.images.length > 0
                      ? person.images.sort(
                          (a, b) => a.sortOrder - b.sortOrder
                        )[0].img
                      : "https://images.cubicsearch.com/UserLogo.png"
                  }
                  alt=""
                />
              </div>
              <div className="ml-4">
                <div className="font-medium text-gray-900">
                  {person.FirstName} {person.LastName}
                </div>
                <div className="text-gray-500">
                  {person.Email && person.Email !== "null" ? person.Email : ""}
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center whitespace-nowrap px-3 py-4 text-sm text-gray-500 justify-items-end">
            <div className="text-gray-900">
              {person.Title && person.Title !== "null" ? person.Title : ""}
            </div>
            <div className="text-gray-500">
              {formatPhoneNumber(person.Phone)}
            </div>
          </div>
          <div className="flex items-center whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <div className="h-10 w-20 flex-shrink-4">
              <img
                className="h-10 w-20 object-contain "
                src={
                  person.office && person.office.company.images
                    ? person.office.company.images[0].img
                    : "https://images.cubicsearch.com/CompanyLogo.png"
                }
                alt=""
              />
              {/* <div style={imagecropper}>
              <img style={profilepic} src={person.office.company.images[0].img} alt="" />
              </div> */}
            </div>
          </div>
          <div className="flex items-center whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {person.project_contact.RelationshipType}
          </div>
          <div className="whitespace-nowrap py-4 text-sm text-gray-500 w-full flex flex-col items-center">
            {person.group_contacts.map((group_contact, groupArrayIndex) => {
              return (
                <div
                  // GroupContactId={group_contact.id}
                  // GroupId={group_contact.group.id}
                  // GroupName={group_contact.group.GroupName}
                  className="w-full"
                >
                  <div className="flex justify-between w-full">
                    <div className="flex self-center">
                      {group_contact.group.GroupName}
                    </div>
                    <div className="px-2 py-1">
                      {group_contact.group.id === 1 &&
                      highestPermission !== "Lead Edit" &&
                      highestPermission !== "Edit" ? (
                        ""
                      ) : (
                        <button
                          data-tip
                          data-for="deleteTip"
                          onClick={() => {
                            removeFromBothArrays(
                              index,
                              peopleIn,
                              group_contact.id,
                              groupArrayIndex
                            );
                          }}
                          className="z-50 rounded-md border border-transparent bg-red-600 px-2 h-6 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                        >
                          <TrashIcon
                            className="h-4 w-4 text-white shadow-sm"
                            aria-hidden="true"
                          />
  
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex items-center"></div>
        </div>
      )
    );
    return myArray;
  }
  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      ></link>
      <div className="py-2 px-0 flex justify-end ">
        <button
          type="button"
          className="inline-flex  self -center items-center content-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
          onClick={() => {
            if (groupsIn.length > 0) {
              addMe();
            } else {
              setNoGroups(true);
            }
          }}
        >
          <i className="fa fa-plus "></i>{" "}
          <div className="px-2"> Add Contact</div>
        </button>
      </div>
      {items && items.length > 1 ? (
        <div className="bg-gray-200 border-solid border-2  border-gray-100 ">
          <div className="px-6">Drag Rows to Change Order</div>
        </div>
      ) : (
        ""
      )}

      <List
        values={items}
        onChange={({ oldIndex, newIndex }) =>
          moveBothArrays(items, oldIndex, newIndex)
        }
        renderList={({ children, props }) => <ul {...props}>{children}</ul>}
        renderItem={({ value, props, index }) => (
          <li {...props}>
            <div className="hover:bg-gray-50 border-solid border-2  border-gray-100">
              <div className="grid grid-cols-6 gap-4">
                <div className="col-span-5">{value}</div>
                <div className="col-span-1 flex items-center justify-self-end px-4">
                  <div></div>
                  <div className="px-2">
                    <button
                      data-tip
                      data-for="openTip"
                      className=" rounded-md border border-transparent bg-red-600 px-4 h-9 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                      onClick={async () => {
                        //const {Base64} = require('js-base64');
                        //var dataObjectBase64 = Base64.encode(items[index].key.toString());
                          

                        
                        window.open("contact?id=" + items[index].key, "_self");;
                      }}
                    >
                      <FolderOpenIcon
                        className="h-4 w-4 text-white shadow-sm"
                        aria-hidden="true"
                      />
              
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        )}
      />
      <MissingFieldsSplash
        open={noGroups}
        setOpen={setNoGroups}
        title={"Please Add a Group with Visibilty to Relationship"}
        message={
          "If survey is public, you do not have permission to add pubilcly visible contacts, if this is private then you do not have permissions to add contacts."
        }
      />
      <ProjectContactSelectModal
        open={showContactModal}
        setOpen={setShowContactModal}
        // peopleCombo={peopleCombo}
        projectId={projectId}
        bigDataIn={bigDataIn}
        setPeopleIn={setPeopleIn}
        triggerSetItems={triggerSetItems}
        contactId={contactId}
        groupsIn={groupsIn}
      />
    </>
  );
}

//  function formatPhoneNumber(phoneNumberString) {
//   var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
//   var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
//   if (match) {
//     return '(' + match[1] + ') ' + match[2] + '-' + match[3]
//   }
//   return null
// }

export function arrayRemove(array, index) {
  array = array.slice();
  array.splice(index, 1);
  return array;
}

// async function deleteProjectContactRelationship(relationshipID) {
// var qs = "id="+relationshipID
// var resultInfo = await fetch(process.env.REACT_APP_DATABASE + "api/deleteProjectContactRelationship?" + qs);
async function deleteProjectContactRelationship(
  relationshipID,
  projectId,
  person,
  contactId,
  groupContactId
) {
  var qs =
    "id=" +
    relationshipID +
    "&groupContactId=" +
    groupContactId +
    "&projectId=" +
    projectId +
    "&contactId=" +
    contactId +
    "&FirstName=" +
    person.FirstName +
    "&LastName=" +
    person.LastName;
  // window.btoa(JSON.stringify(person));
  //test - console.log(
  //   process.env.REACT_APP_DATABASE +
  //     "api/deleteProjectContactRelationship?" +
  //     qs
  // );
  var resultInfo = await fetch(
    process.env.REACT_APP_DATABASE +
      "api/deleteProjectContactRelationship?" +
      qs
  );
}

// }

async function orderContacts(contacts) {
  //test - console.log("Update the order in the database");
  //test - console.log(contacts);

  contacts.map(async (contact, index) => {
    //test - console.log(contact.id);
    var qs = "id=" + contact.project_contact.id + "&sortOrder=" + index;
    var resultInfo = await fetch(
      process.env.REACT_APP_DATABASE + "api/sortProjectContacts?" + qs
    );
  });
}
