/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import WebsiteNew from './WebsiteNew'

import { Link as LinkIcon } from '@mui/material'

export default function WebsiteEntryModal({open, setOpen, mainPageData, setMainPageData, mapSitesSetItems, contactId}) {

 //test - console.log("Website Modal Rebuild")
 const [buttonText, setButtonText] = useState("Add Website")

  const cancelButtonRef = useRef(null);

 

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-102 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
             
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <LinkIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Add Website
                  </Dialog.Title>
                  <div className="mt-2">
              
                    <WebsiteNew/>
                  </div>
                  
                </div>
              </div>
  
              
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm"
                  onClick={() => {
                     
                      var website = {
                          'src' : document.getElementById("src").value,
                          'Description' : document.getElementById("Description").value,

                      };
                        newWebsite(mainPageData.id, website, setMainPageData, mapSitesSetItems, contactId)
                        setOpen(false)
              
                }
            }
                >
                  {buttonText}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}



export async function newWebsite(availId, website, setMainPageData,mapSitesSetItems, contactId ) {
  
  
  //We add in the new avail
    var fetchLocation = process.env.REACT_APP_DATABASE + "api/addAvailableWebsite?availId="+ availId +"&"+Object.keys(website).map(key=>key+'='+encodeURIComponent(website[key])).join('&'); 
  var result = await fetch(fetchLocation)
  //We get the data from the database with the new id given to website just added


  var qsAvailableOnly = process.env.REACT_APP_DATABASE + "api/getAvailableOnly?id=" 
  var qsAvailableContactsOnly = process.env.REACT_APP_DATABASE + "api/getAvailableContactsOnly?id="
  var qsAvailableJournalsOnly = process.env.REACT_APP_DATABASE + "api/getAvailableJournalsOnly?id="
  //var qsAvailableWebsitesOnly = process.env.REACT_APP_DATABASE + "api/getAvailableWebsitesOnly?id=" + id + "&contactId=" + user.contact.id
  
  const availableJournalsOnly = await fetch(qsAvailableJournalsOnly + availId + "&contactId=" + contactId);
  const availableJournals = await availableJournalsOnly.json();

  const availableContactsOnly = await fetch(qsAvailableContactsOnly + availId + "&contactId=" + contactId);
  const availableContacts = await availableContactsOnly.json();

  const availableOnly = await fetch(qsAvailableOnly + availId + "&contactId=" + contactId);
  const available = await availableOnly.json();


  fetchLocation = process.env.REACT_APP_DATABASE + "api/getAvailableWebsitesOnly?id=";
  const finalSearchResults = await fetch(fetchLocation + availId + "&contactId=" + contactId);
  const finalData = await finalSearchResults.json();

   available.avail.generic_journals = availableJournals.journals
  available.avail.contacts = availableContacts.contacts
  available.avail.websites = finalData.websites
 
  
  setMainPageData(available.avail)
  mapSitesSetItems(finalData.websites)

}


async function orderAvailableWebsites(websites ) {
    //test - console.log("Update the order in the database")
    ////test - console.log(avails)
    var mynum = 0;
    
    websites.map( async (website, index) => {
      mynum = index + 1
      //test - console.log(website.id)
      var qs = "id="+website.id+"&sortOrder="+mynum
      var resultInfo = await fetch(process.env.REACT_APP_DATABASE + "api/sortWebsites?" + qs)
    })   
  
  }
