//import Image from "next/image";
import ClientsList from "./ClientList";


function MainContactClientsInfo({
  dataIn,
  peopleCombo,
  setDataIn,
  contactId,
  groups,
  groupsCombo,
  highestPermission,
}) {


  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">

        {/* <dt className="text-sm font-medium text-gray-500">My Clients</dt> */}
       

      </div>


      <div
        id="clients"
        key="clients"
        className="border-t border-gray-200 px-4 py-5 sm:px-6"
      >
        <ClientsList
          clientsIn={dataIn}
          contactclientsId={dataIn.id}
          contactId={contactId}
          highestPermission={highestPermission}
        />
      </div>
    
    </div>
  );
}

export default MainContactClientsInfo;
