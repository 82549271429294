import DemoCarousel from "../Images/Carousel";
import { mobileAndTabletCheck } from "../Search/Deck";

export default function SurveyListRows({ surveysIn }) {
  if (surveysIn && surveysIn.length > 0) {
    return (
      <>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        <div className="px-6">Survey Picture</div>
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Survey Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Date Created
                      </th>
                     {/*  <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Buildout
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Your Groups with Visibility
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Contacts
                      </th> */}

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Your Groups with Visibility
                      </th>
                      <th></th><th></th>
                    </tr>
                  </thead>

                  <tbody>{tableRows(surveysIn)}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
}

function tableRows(surveysIn) {
  var nfObject = new Intl.NumberFormat("en-US");
  var nfObjectRate = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  var nfObjectDate = new Intl.DateTimeFormat("en-US");
  //   ;

  if (surveysIn && surveysIn.length > 0) {
    return (
      <>
        {surveysIn.map((survey) => (
          <tr
            className="hover:bg-gray-200  cursor-pointer"
            onClick={async () => {
              //const {Base64} = require('js-base64');
              // var dataObjectBase64 = Base64.encode(survey.id.toString());
              window.open("search?surveyId=" + survey.id, mobileAndTabletCheck()?"_self": null);
              //    await Alert('Alert text', 'Alert title');
            }}
          >
            <td className="h-40 w-60 items-center object-cover">
              <div className="w-40">
                <DemoCarousel key={survey.id} images={survey.images} />
              </div>
              <div
                onClick={async () => {
                  //const {Base64} = require('js-base64');
                  //var dataObjectBase64 = Base64.encode(survey.id.toString());
                  window.open("search?surveyId=" + survey.id, mobileAndTabletCheck()?"_self": null);
                  //    await Alert('Alert text', 'Alert title');
                }}
              >
                {/* <div className="px-6 ">
                  <div className="font-medium text-gray-900">
                    {survey.Floor > 0
                      ? "Floor " + survey.Floor
                      : "Please Add Floor"}
                  </div>
                  <div className="font-medium text-gray-500">
                    {" "}
                    {survey.Suite !== "0" && survey.Suite
                      ? "Suite " + survey.Suite
                      : "Please Add Suite"}
                  </div>
                </div> */}
              </div>
            </td>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
              <div className="font-medium text-gray-500">
                {survey.SurveyName ? survey.SurveyName : "New Survey"}{" "}
              </div>
            </td>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
              <div className="font-medium text-gray-500">
                {survey.createdAt ? new Date(survey.createdAt).toLocaleDateString() : "Unrecorded Date"}{" "}
              </div>
            </td>
            {/* <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
              <div className="font-medium text-gray-900">
                {nfObject.format(survey.SizeMin)} -{" "}
                {nfObject.format(survey.SizeMax)} SF
              </div>
              <div className="font-medium text-gray-500">
                {survey.AskRate > 0
                  ? nfObjectRate.format(survey.AskRate) + " / SF / Month"
                  : "Please Inquire"}{" "}
              </div>
            </td>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
              <div className="font-medium text-gray-900">
                {nfObjectDate.format(new Date(survey.DateSurvey)) ||
                  "Contact agent or owner"}
              </div>
            </td>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
              <div className="font-medium text-gray-900">
                {survey.Condition || "Contact agent or owner"}
              </div>
            </td>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
              <div className="font-medium text-gray-900 whitespace-pre-line">
                {survey.survey_groups.map((survey_group) => {
                  // return <div>{survey_group.group.GroupName} - {survey_group.groupPermission}</div>;
                  return (
                    <div className="py-2">
                      {" "}
                      Group Name : {survey_group.group.GroupName}
                      {"\n"} Survey Rights : {survey_group.groupPermission}
                    </div>
                  );
                }) || "Contact agent or owner"}
              </div>
              </td>  */}
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
              <div className="font-medium text-gray-900 whitespace-pre-line">
                {survey.survey_groups.map((survey_group) => {
                  // return <div>{survey_group.group.GroupName} - {survey_group.groupPermission}</div>;
                  return (
                    <div className="py-2">
                      {" "}
                      Group Name : {survey_group.group.GroupName}
                      {"\n"} Survey Rights : {survey_group.groupPermission}
                    </div>
                  );
                }) || "Contact agent or owner"}
              </div>
              </td>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
              {survey.contacts
                .sort(
                  (a, b) =>
                    a.survey_contact.sortOrder - b.survey_contact.sortOrder
                )
                .map((contact) => (
                  <div className="flex items-center py-1">
                    <div className="h-10 w-10 rounded-full">
                      <img
                        className="h-10 w-10 flex-shrink-0 rounded-full object-cover"
                        src={
                          contact.images.length > 0
                            ? contact.images.sort(
                                (a, b) => a.sortOrder - b.sortOrder
                              )[0].img
                            : "https://images.cubicsearch.com/UserLogo.png"
                        }
                        alt=""
                      />
                    </div>
                    <div className="ml-4">
                      <div className="font-medium text-gray-900">
                        {contact.FirstName} {contact.LastName}
                      </div>
                      <div className="text-gray-500">
                        {contact.office && contact.office.company
                          ? contact.office.company.Name
                          : "Add Company"}
                      </div>
                    </div>
                  </div>
                ))}
            </td>
          </tr>
        ))}
      </>
    );
  } else {
    return <></>;
  }
}
