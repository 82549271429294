

import React from 'react';
import { Container, Typography, List, ListItem, ListItemText, Link } from '@mui/material';

export const PrivacyPolicy = () => {
    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Privacy Policy
            </Typography>
            <Typography variant="body1" paragraph>
                This privacy policy has been compiled to better serve those who are concerned with how their 'Personally Identifiable Information' (PII) is being used online. PII, as described in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect, or otherwise handle your Personally Identifiable Information in accordance with our website.
            </Typography>

            <Typography variant="h5" gutterBottom>
                What personal information do we collect from the people that visit our blog, website, or app?
            </Typography>
            <Typography variant="body1" paragraph>
                When ordering or registering on our site, as appropriate, you may be asked to enter your email address, pictures, or other details to help you with your experience.
            </Typography>

            <Typography variant="h5" gutterBottom>
                When do we collect information?
            </Typography>
            <Typography variant="body1" paragraph>
                We collect information from you when you register on our site, use Live Chat, or enter information on our site.
            </Typography>

            <Typography variant="h5" gutterBottom>
                How do we use your information?
            </Typography>
            <Typography variant="body1" paragraph>
                We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:
            </Typography>
            <List>
                <ListItem>
                    <ListItemText primary="To personalize your experience and to allow us to deliver the type of content and product offerings in which you are most interested." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="To send periodic emails regarding your order or other products and services." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="To follow up with them after correspondence (live chat, email, or phone inquiries)." />
                </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
                How do we protect your information?
            </Typography>
            <Typography variant="body1" paragraph>
                We do not use vulnerability scanning and/or scanning to PCI standards. We do not use Malware Scanning.
            </Typography>

            <Typography variant="h5" gutterBottom>
                Do we use 'cookies'?
            </Typography>
            <Typography variant="body1" paragraph>
                We do use cookies for tracking purposes.
            </Typography>
            <Typography variant="body1" paragraph>
                You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. Since browser is a little different, look at your browser's Help Menu to learn the correct way to modify your cookies. If you turn cookies off, the website may not work properly.
            </Typography>

            <Typography variant="h5" gutterBottom>
                Third-party disclosure
            </Typography>
            <Typography variant="body1" paragraph>
                We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information.
            </Typography>

            <Typography variant="h5" gutterBottom>
                Third-party links
            </Typography>
            <Typography variant="body1" paragraph>
                We do not include or offer third-party products or services on our website.
            </Typography>

            <Typography variant="h5" gutterBottom>
                California Online Privacy Protection Act
            </Typography>
            <Typography variant="body1" paragraph>
                CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law's reach stretches well beyond California to require any person or company in the United States (and conceivably the world) that operates websites collecting Personally Identifiable Information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals or companies with whom it is being shared.
                <Link href="http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf" target="_blank" rel="noopener noreferrer">See more at: CalOPPA</Link>
            </Typography>
            <Typography variant="body1" paragraph>
                According to CalOPPA, we agree to the following:
            </Typography>
            <List>
                <ListItem>
                    <ListItemText primary="Users can visit our site anonymously." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Once this privacy policy is created, we will add a link to it on our home page or as a minimum, on the first significant page after entering our website." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Our Privacy Policy link includes the word 'Privacy' and can easily be found on the page specified above." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="You will be notified of any Privacy Policy changes on our Privacy Policy Page." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="You can change your personal information by emailing us." />
                </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
                How does our site handle Do Not Track signals?
            </Typography>
            <Typography variant="body1" paragraph>
                We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place.
            </Typography>

            <Typography variant="h5" gutterBottom>
                Does our site allow third-party behavioral tracking?
            </Typography>
            <Typography variant="body1" paragraph>
                It's also important to note that we do not allow third-party behavioral tracking.
            </Typography>

            <Typography variant="h5" gutterBottom>
                COPPA (Children Online Privacy Protection Act)
            </Typography>
            <Typography variant="body1" paragraph>
                When it comes to the collection of personal information from children under the age of 13 years old, the Children's Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, United States' consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children's privacy and safety online.
            </Typography>
            <Typography variant="body1" paragraph>
                We do not specifically market to children under the age of 13 years old.
            </Typography>

            <Typography variant="h5" gutterBottom>
                Fair Information Practices
            </Typography>
            <Typography variant="body1" paragraph>
                The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.
            </Typography>
            <Typography variant="body1" paragraph>
                In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:
            </Typography>
            <List>
                <ListItem>
                    <ListItemText primary="We will notify you via email within 7 business days." />
                </ListItem>
            </List>
            <Typography variant="body1" paragraph>
                We also agree to the Individual Redress Principle which requires that individuals have the right to legally pursue enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or government agencies to investigate and/or prosecute non-compliance by data processors.
            </Typography>

            <Typography variant="h5" gutterBottom>
                CAN SPAM Act
            </Typography>
            <Typography variant="body1" paragraph>
                The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations. We collect your email address in order to:
            </Typography>
            <List>
                <ListItem>
                    <ListItemText primary="Not use false or misleading subjects or email addresses." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Identify the message as an advertisement in some reasonable way." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Include the physical address of our business or site headquarters." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Monitor third-party email marketing services for compliance, if one is used." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Honor opt-out/unsubscribe requests quickly." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Allow users to unsubscribe by using the link at the bottom of each email." />
                </ListItem>
            </List>
            <Typography variant="body1" paragraph>
                If at any time you would like to unsubscribe from receiving future emails, you can email us at support@cubicsearch.com and we will promptly remove you from ALL correspondence.
            </Typography>

            <Typography variant="h5" gutterBottom>
                Contacting Us
            </Typography>
            <Typography variant="body1" paragraph>
                If there are any questions regarding this privacy policy, you may contact us using the information below.
            </Typography>
            <Typography variant="body1" paragraph>
                cubicsearch.com
                <br />
                380 Hamilton Ave Ste 585
                <br />
                Palo Alto, California 94301
                <br />
                USA
                <br />
                support@cubicsearch.com
            </Typography>
        </Container>
    );
};



export const TermsOfService = () => {
    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Terms of Service
            </Typography>
            <Typography variant="body1" paragraph>
                PLEASE READ THIS AGREEMENT BEFORE USING OUR SERVICES. BY ACCESSING OR USING OUR SERVICES, YOU (“the Customer”) SIGNIFY ACCEPTANCE OF AND AGREE TO THE TERMS AND CONDITIONS OF THIS AGREEMENT. IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS OF THIS AGREEMENT, DO NOT ACCESS OR USE THE SERVICES.
            </Typography>

            <Typography variant="h5" gutterBottom>
                1. DEFINITIONS
            </Typography>
            <List>
                <ListItem>
                    <ListItemText primary="“Customer Content” means all data and materials provided by Customer for use in connection with the Services, including, without limitation, customer applications, data files, and graphics." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="“Documentation” means the user guides, online help, release notes, training materials, and other documentation provided or made available to Customer regarding the use or operation of the Services." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="“Host” means the computer equipment on which the Software is installed, which is owned and operated by our service providers." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="“Software” means the object code version of any software to which Customer is provided access as part of the Service, including any updates or new versions." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="“Subscription Term” shall mean that period specified in an order during which Customer will have online access and use of the Software through our Services. The Subscription Term shall renew for successive 12-month periods unless either party delivers written notice of non-renewal to the other party at least 30 days prior to the expiration of the then-current Subscription Term." />
                </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
                2. SERVICES
            </Typography>
            <Typography variant="body1" paragraph>
                During the Subscription Term, Customer will receive a nonexclusive, non-assignable, royalty-free, worldwide right to access and use the Services solely for your internal business operations subject to the terms of this Agreement and up to the number of authorized users documented in the order.
            </Typography>
            <Typography variant="body1" paragraph>
                Customer acknowledges that this Agreement is a services agreement and we will not be delivering copies of the Software to Customer as part of the Services.
            </Typography>

            <Typography variant="h5" gutterBottom>
                3. RESTRICTIONS
            </Typography>
            <List>
                <ListItem>
                    <ListItemText primary="Customer shall not, and shall not permit anyone to: (i) copy or republish the Services or Software, (ii) make the Services available to any person other than authorized users, (iii) use or access the Services to provide service bureau, time-sharing or other computer hosting services to third parties, (iv) modify or create derivative works based upon the Services or Documentation, (v) remove, modify or obscure any copyright, trademark or other proprietary notices contained in the software used to provide the Services or in the Documentation, (vi) reverse engineer, decompile, disassemble, or otherwise attempt to derive the source code of the Software used to provide the Services, except and only to the extent such activity is expressly permitted by applicable law, or (vii) access the Services or use the Documentation in order to build a similar product or competitive product." />
                </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
                4. CUSTOMER RESPONSIBILITIES
            </Typography>
            <List>
                <ListItem>
                    <ListItemText primary="Customer shall provide commercially reasonable information and assistance to enable us to deliver the Services. Upon request, Customer shall promptly deliver Customer Content in an electronic file format specified and accessible by us." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Customer shall comply with all applicable local, state, national, and foreign laws in connection with its use of the Services, including those laws related to data privacy, international communications, and the transmission of technical or personal data." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Customer shall: (a) notify us immediately of any unauthorized use of any password or user ID or any other known or suspected breach of security, (b) report to us immediately and use reasonable efforts to stop any unauthorized use of the Services that is known or suspected by Customer, and (c) not provide false identity information to gain access to or use the Services." />
                </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
                5. ORDERS AND PAYMENT
            </Typography>
            <List>
                <ListItem>
                    <ListItemText primary="Customer shall order Services pursuant to an order form. All services acquired by Customer shall be governed exclusively by this Agreement and the applicable order form." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Unless otherwise provided in the order form, we shall invoice Customer for all fees on the order effective date. Customer shall pay all undisputed invoices within 30 days after Customer receives the invoice. Except as expressly provided otherwise, fees are non-refundable." />
                </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
                6. TERM AND TERMINATION
            </Typography>
            <List>
                <ListItem>
                    <ListItemText primary="The term of this Agreement shall begin on the effective date and shall continue until terminated by either party as outlined in this Section." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Either party may terminate this Agreement immediately upon a material breach by the other party that has not been cured within thirty (30) days after receipt of notice of such breach." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="We reserve the right to suspend delivery of the Services if Customer fails to timely pay any undisputed amounts due under this Agreement." />
                </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
                7. WARRANTIES
            </Typography>
            <Typography variant="body1" paragraph>
                We represent and warrant that we will provide the Services in a professional manner consistent with general industry standards and that the Services will perform substantially in accordance with the Documentation. For any breach of a warranty, Customer’s exclusive remedy shall be as provided in Section 6, Term and Termination.
            </Typography>

            <Typography variant="h5" gutterBottom>
                8. LIMITATIONS OF LIABILITY
            </Typography>
            <Typography variant="body1" paragraph>
                NEITHER PARTY SHALL BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOST BUSINESS, PROFITS, DATA OR USE OF ANY SERVICE, INCURRED BY EITHER PARTY OR ANY THIRD PARTY IN CONNECTION WITH THIS AGREEMENT, REGARDLESS OF THE NATURE OF THE CLAIM (INCLUDING NEGLIGENCE), EVEN IF FORESEEABLE OR THE OTHER PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NEITHER PARTY’S AGGREGATE LIABILITY FOR DAMAGES UNDER THIS AGREEMENT, REGARDLESS OF THE NATURE OF THE CLAIM (INCLUDING NEGLIGENCE), SHALL EXCEED THE FEES PAID OR PAYABLE BY CUSTOMER UNDER THIS AGREEMENT DURING THE 12 MONTHS PRECEDING THE DATE THE CLAIM AROSE.
            </Typography>

            <Typography variant="h5" gutterBottom>
                9. INDEMNIFICATION
            </Typography>
            <List>
                <ListItem>
                    <ListItemText primary="We shall defend Customer against any claim that the Services infringe any patent, copyright, or trademark, or misappropriates any trade secret, and shall pay all damages and costs awarded against Customer in any suit or proceeding arising out of the foregoing." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Customer shall defend us against any claim that the Customer Content infringes any patent, copyright, or trademark, or misappropriates any trade secret, and shall pay all damages and costs awarded against us in any suit or proceeding arising out of the foregoing." />
                </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
                10. CONFIDENTIALITY
            </Typography>
            <List>
                <ListItem>
                    <ListItemText primary="Each party shall treat as confidential all Confidential Information of the other party, shall not use such Confidential Information except to exercise its rights and perform its obligations under this Agreement, and shall not disclose such Confidential Information to any third party." />
                </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
                11. GENERAL PROVISIONS
            </Typography>
            <List>
                <ListItem>
                    <ListItemText primary="Non-Exclusive Service: Customer acknowledges that Services are provided on a non-exclusive basis. Nothing shall prevent us from providing the Services or other technology to other parties." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Personal Data: Customer agrees that our performance of this Agreement may require us to process, transmit and/or store Customer personal data. By submitting personal data to us, Customer agrees that we may process, transmit and/or store personal data as necessary to perform our obligations under this Agreement." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Assignment: Neither party may assign this Agreement or any right under this Agreement without the consent of the other party, which shall not be unreasonably withheld or delayed." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Notices: Notices under this Agreement shall be in writing and shall be deemed to have been given five (5) business days after mailing if sent by registered or certified U.S. mail, when transmitted if sent by facsimile, or when delivered if delivered personally or sent by express courier service." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Force Majeure: Each party will be excused from performance for any period during which such party is prevented from performing any obligation as a result of causes beyond its reasonable control." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Waiver: No waiver shall be effective unless it is in writing and signed by the waiving party." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Severability: If any term of this Agreement is held to be invalid or unenforceable, that term shall be reformed to achieve as nearly as possible the same effect as the original term, and the remainder of this Agreement shall remain in full force." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Entire Agreement: This Agreement contains the entire agreement of the parties and supersedes all previous oral and written communications by the parties concerning the subject matter of this Agreement." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Governing Law: This Agreement shall be governed by the laws of the State of California, excluding its conflict of law principles." />
                </ListItem>
            </List>

            <Typography variant="body1" paragraph>
                Acknowledged and Agreed:
            </Typography>

            <Typography variant="h5" gutterBottom>
                EXHIBIT A
            </Typography>
            <Typography variant="h6" gutterBottom>
                SCHEDULE A-1
            </Typography>
            <Typography variant="body1" paragraph>
                SOFTWARE & PRICE SCHEDULE
            </Typography>
            <Typography variant="body1" paragraph>
                This Schedule No. A-1 ("Schedule"), effective upon the Agreement Effective Date, documents the Services being purchased by Customer under the terms and conditions of the Agreement dated ______ between us and the Customer.
            </Typography>
            <List>
                <ListItem>
                    <ListItemText primary="1. Services: The Services include one or more of the following service offerings: A proprietary database (the 'Database') of real estate information and the proprietary organization and structures for categorizing, sorting, and displaying such information." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="2. Subscription Term: The term begins upon the Schedule Effective Date and ends 1 year thereafter." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="3. Schedule Value: The total value of this Schedule is $49.95 / month. This fee includes access and usage of the Services during the Subscription Term." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="4. Customer Billing Information" />
                </ListItem>
            </List>
        </Container>
    );
};




export default function Privacy() {
       return (<>

        <PrivacyPolicy />
    </>)
}

export function Terms() {
    const websiteAddress = "https://sway.office.com/gTHOLR0qwmMJopGN?ref=Link&loc=play"
    return (<>
<TermsOfService/>
        </>)
   
}