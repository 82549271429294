
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ListItem, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import axios from 'axios';
import {Avatar} from '@mui/material';


export default function ClientsCombo({
  clients,
  selectedClient,
  setSelectedClient,
  setShowProjects,
  getProjects,
  setHighestPermission,

  setClientsDataArray,
  contactId
}) {
  const [inputValue, setInputValue] = useState(selectedClient?.ClientName || '');
  const [resetKey, setResetKey] = useState(0);
  useEffect(() => {
    setInputValue(selectedClient?.ClientName || '');
  }, [selectedClient]);


  const filteredClients = clients.filter((client) =>
    client.ClientName.toLowerCase().includes(inputValue.toLowerCase())
  );
  // Handle input change to filter options or clear selection
  const handleInputChange = (event, newInputValue, reason) => {

    setInputValue(newInputValue);
    if (reason === 'clear') {
      handleClearAndReset(); // Clear selected client when input is cleared
    }
  };

  const handleClearAndReset = () => {

    setInputValue('');
    setSelectedClient(null); // Assuming this is your state reset logic
    setResetKey(prevKey => prevKey + 1); // Increment key to force re-render
    setShowProjects(false)
  };
  // Custom end adornment for clearing input
  const endAdornment = (
    <IconButton onClick={() => handleClearAndReset()} edge="end">
      <ClearIcon />
    </IconButton>
  );
  const handleCreateClient = async (inputValue) => {
    // Implement the logic to handle the creation of a new client
    console.log(`Create client with name: ${inputValue}`);
    var qs = process.env.REACT_APP_DATABASE +
      "api/createClient"
    var newClient = await axios.post(qs,{contactId: contactId, ClientName: inputValue}) 

    if (newClient?.data?.newClient){
      var clientToAdd = newClient.data.newClient
      clientToAdd.clientId = clientToAdd.id

      setClientsDataArray([...clients, clientToAdd])
      
      setSelectedClient(clientToAdd)
      setHighestPermission('Lead Edit');
      setShowProjects(true)
      getProjects(null)
      

    }
    else {
      alert("Problem with new client")
    }

    // Example: setNewClientName(inputValue);
    // Then, you might want to clear the input or close the modal, etc.
  };

  return (

    <Autocomplete
      value={selectedClient}
      key={resetKey} // Use the resetKey here
      onChange={(event, newValue) => {
      
        if (newValue?.clientId) { // Check if the newValue has an id to distinguish it from the create option
         
          setSelectedClient(newValue);
          getProjects(newValue.clientId);
          setHighestPermission(newValue.setHighestPermission);
          setShowProjects(true)
          // Additional logic for when a client is selected
        } else if (newValue?.inputValue) {
          
          handleCreateClient(newValue.inputValue);
          
        }
      }}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      options={filteredClients.length > 0 ? filteredClients : inputValue ? [{ inputValue, ClientName: `Create "${inputValue}"` }] : []}
      getOptionLabel={(option) => option.ClientName || option.inputValue}
      filterOptions={(options) => options} // Prevent filtering options internally, as we already do it
      renderOption={(props, option) => (
        <ListItem {...props} key={option.clientId || option.inputValue}>
          <Avatar
            alt={option.ClientName}
            src={option.images && option.images.length > 0 ? option.images[0].img : ''}
            sx={{
              marginRight: 2,
              width: 40, // Set the width of the avatar
              height: 40, // Set the height of the avatar
              borderRadius: '10%', // Circular avatar
              // For a square avatar with rounded corners, you might use:
              // borderRadius: '10%', 
              img: {
                objectFit: 'contain', // Cover the container, maintaining aspect ratio
                width: '100%', // Ensure the image spans the full width
                height: '100%' // Ensure the image spans the full height
              }
            }}
          />
          {option.ClientName}
        </ListItem>
        
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Client Name"
          InputProps={{
            ...params.InputProps,
            endAdornment: inputValue ? endAdornment : undefined,
          }}
        />
      )}
      isOptionEqualToValue={(option, value) => option.clientId === value.clientId}
      noOptionsText={inputValue ? `Press Enter to create "${inputValue}"` : 'No options'}
      freeSolo // Allow arbitrary input values not tied directly to options
    />


  );
}