import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import DemoCarousel from "../../Images/Carousel";
import { mobileAndTabletCheck } from "../../Search/Deck";
import { BuildingGroup } from "../../Search/InfoCard";
import { Paper, Grid } from "@mui/material";
export default function BuildingGroupsRows({ groupsIn, contactId, buildingId, building }) {
  
  if (groupsIn.length > 0) {
    return (
     tableRows(groupsIn, contactId, buildingId, building)
    );
  } else {
    return <></>;
  }
}

function tableRows(groupsIn, contactId, buildingId, building) {


  // 
  return (
    <>
      
      <Grid2 container spacing={4}>
      {groupsIn.map((group, index) => (
        <Grid2>
        <Paper elevation={6} key={group.id.toString()+index}>
          {BuildingGroup(group, contactId, building)}
  
          </Paper>
        </Grid2>
      ))}
      </Grid2>
    </>
  );
}
