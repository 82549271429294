import { ListBoxID } from "./ListBox";
import ListBox from "./ListBox";

import { useRef } from "react";

import { DatePicker } from "@mui/x-date-pickers-pro";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";


export function InputComponent({
  inputType,
  defaultShow,
  id,
  name,
  valueIn,
  setvalueIn,
  options,
  formatting,
  titleFormatting,
  title,
}) {
  // if (inputType === "simpleselect") {
  //   var listOptions;
  //   options.map((optionPair) => {
  //     if (optionPair.field === name) {
  //       listOptions = optionPair.options;
  //     }
  //   });
  // }
  var listOptions = options;
  const oldValueRef = useRef(0);
  const handleChange = (event) => {
    setvalueIn(event.target.value);
    //test - console.log("try again");
    //test - console.log(event.target.value);
  };
  const handleChangeNumber = (event) => {
    const oldValue = oldValueRef.current;
    const value = event.target.value;

    // after everything

    //test - console.log(!isNaN(+value)); // true if its a number, false if not
    if (!isNaN(+value)) {
      // setvalueIn(value);
      oldValueRef.current = event.target.value;
    } else {
      alert("Please enter a number");
      event.target.value = oldValue;
    }
  };

  if (inputType === "dateRangeStart") {
    if (typeof defaultShow === "string") {
      defaultShow = new Date(defaultShow);
    }
    var date = defaultShow ? defaultShow.toISOString().substring(0, 10) : "";
    

    return (
      //   <DatePicker
      //   label="StartDate"
      //   defaultValue={date}
      //   onChange={(e) => {
      //     setvalueIn
      //       ? setvalueIn(new Date(e.target.value ? e.target.value : "1/1/2028"))
      //       : //test - console.log("no set");
      //   }}
      // />
      // <DatePicker label="Uncontrolled picker" defaultValue={dayjs('2022-04-17')} />
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={'en'}
        
      >
        <DatePicker
         
          sx={{colorScheme:'normal', zIndex: 1601}}
          defaultValue={dayjs(defaultShow)}
          slotProps={{
            // The actions will be the same between desktop and mobile
            actionBar: {
              actions: ['clear'],
            },

          }}
          onChange={(e) => {
            var timestamp = Date.parse(e);

            if (isNaN(timestamp) === false) {
              var d = new Date(timestamp);
            }

            d ? setvalueIn(d) : setvalueIn(new Date("01/01/1980"));
           
          }}
        />{" "}
      </LocalizationProvider>
    );
  } else if (inputType === "dateRangeEnd") {
    if (typeof defaultShow === "string") {
      defaultShow = new Date(defaultShow);
    }
    var date = defaultShow ? defaultShow.toISOString().substring(0, 10) : "";
    return (
      // <DatePicker label="Uncontrolled picker" defaultValue={dayjs('2022-04-17')} />
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={'en'}
       
      >
        <DatePicker
          // className="border-0"
          sx={{zIndex: 1601}}
          defaultValue={dayjs(defaultShow)}
          slotProps={{
            // The actions will be the same between desktop and mobile
            actionBar: {
              actions: ['clear'],
            },
       
          }}
          onChange={(e) => {
            var timestamp = Date.parse(e);

            if (isNaN(timestamp) === false) {
              var d = new Date(timestamp);
            }

            d ? setvalueIn(d) : setvalueIn(new Date("01/01/2040"));
           
          }}
        />{" "}
      </LocalizationProvider>
    );
  } else if (inputType === "date") {
    if (typeof defaultShow === "string") {
      defaultShow = new Date(defaultShow);
    }
    var date = defaultShow ? defaultShow.toISOString().substring(0, 10) : "";

    return (
      <input
        className="shadow-sm focus:ring-red-500 focus:border-red-500 w-full text-xs sm:text-sm border-gray-300 rounded-md"
        type="date"
        placeholder="mm/dd/yyyy"
        name={name}
        id={name}
        defaultValue={date}
        onChange={(e) => {
          if (setvalueIn)
          { setvalueIn(new Date(e.target.value ? e.target.value : "1/1/2028")) }
          else 
            {console.log("no set");}
        }}
      />
    );
  } else if (inputType === "hidden") {
    return (
      <input
        className="shadow-sm focus:ring-red-500 focus:border-red-500 w-full sm:text-sm border-gray-300 rounded-md"
        type="text"
        name={name}
        id={name}
        defaultChecked={defaultShow && defaultShow !== "null" ? defaultShow : ""}
        // value={valueIn}
      />
    );
  } else if (inputType === "dynamic") {
    return (
      <input
        className={
          "shadow-sm focus:ring-red-500 focus:border-red-500  sm:text-sm border-gray-300 rounded-md " +
          formatting
        }
        type="text"
        name={name}
        id={name}
        defaultValue={valueIn && valueIn !== "null" ? valueIn : ""}
        onChange={handleChange}
      />
    );
  } else if (inputType === "select") {
    return (
      <>
        <input
          className="shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300 rounded-md hidden"
          type="text"
          name={name}
          id={name}
          defaultValue={defaultShow && defaultShow !== "null" ? defaultShow : ""}
          // value={valueIn}
        />
        <ListBox 
          selected={valueIn}
          setSelected={setvalueIn}
          options={options}
        />
      </>
    );
  } else if (inputType === "simpleselect") {
    var myVal = { defaultShow };

    return (
      <>
        <input
          type={"text"}
          list={name + "list"}
          className="shadow-sm  focus:ring-red-500 focus:border-red-500 text-right sm:text-sm border-gray-300 rounded-md"
          name={name}
          id={name}
          defaultValue={defaultShow && defaultShow !== "null" ? defaultShow : ""}
        />
        <datalist id={name + "list"} className="z-101">
          {listOptions.map((option) => {
            return <option value={option}>{option}</option>;
          })}
        </datalist>
      </>
    );
  } else if (inputType === "selectID") {
    return (
      <>
        <input
          className="shadow-sm focus:ring-red-500 focus:border-red-500  sm:text-sm border-gray-300 rounded-md hidden"
          type="text"
          name={name}
          id={name}
          defaultValue={defaultShow && defaultShow !== "null" ? defaultShow : ""}
          // value={valueIn}
        />
        <ListBoxID
          selectedOption={valueIn}
          setSelectedOption={setvalueIn}
          options={options}
          titleFormatting={titleFormatting}
          title={title}
        />
      </>
    );
  } else if (inputType === "placeholder") {
    return (
      <input
        className={
          "shadow-sm focus:ring-red-500 focus:border-red-500  sm:text-sm border-gray-300 rounded-md " +
          formatting
        }
        type="text"
        name={name}
        id={name}
        placeholder={defaultShow && defaultShow !== "null" ? defaultShow : ""}
        defaultValue={valueIn}
        onChange={setvalueIn}
      />
    );
  } else if (inputType === "number") {
    return (
      <input
        className="shadow-sm focus:ring-red-500 focus:border-red-500  sm:text-sm border-gray-300 rounded-md"
        type="number"
        name={name}
        id={name}
        placeholder={
          defaultShow && defaultShow !== "null"
            ? "was (" + defaultShow + ")"
            : ""
        }
        defaultValue={valueIn === "" || !valueIn ? null : valueIn}
        onChange={handleChangeNumber}
        // onKeyDown={(event) => {
        //   //test - console.log(event);
        //   if (event.key !== "Control" && (/[a-b]/.test(event.key) || /[d-u]/.test(event.key)|| /[w-w]/.test(event.key))) {
        //     //test - console.log(event);
        //     alert("You entered " + event.key +" Please enter a number");
        //   }
        // }}
        step={1}
      />
    );
  } else if (inputType === "decimal") {
    return (
      <input
        className="shadow-sm focus:ring-red-500 focus:border-red-500  sm:text-sm border-gray-300 rounded-md"
        type="number"
        step={".01"}
        name={name}
        id={name}
        placeholder={defaultShow && defaultShow !== "null" ? defaultShow : ""}
        defaultValue={valueIn === "" || !valueIn ? null : valueIn}
        onChange={setvalueIn}
      />
    );
  } else if (inputType === "currency") {
    return (
      <>
        <div className="relative rounded-md w-40 shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4">
            <span className="text-gray-500 sm:text-sm">$</span>
          </div>
          <input
            type="text"
            name={name}
            id={name}
            placeholder={
              defaultShow && defaultShow !== "null" ? defaultShow : "0.00"
            }
            defaultValue={valueIn === "" || !valueIn ? null : valueIn}
            onChange={handleChangeNumber}
            className="block w-full rounded-md focus:ring-red-500 focus:border-red-500  pl-10 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            aria-describedby="price-currency"
          />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span className="text-gray-500 sm:text-sm" id="price-currency">
              USD
            </span>
          </div>
        </div>
      </>
    );
  } else if (inputType === "textarea") {
    return (
      <div>
        {/* <dt className="text-sm font-medium text-gray-500">Space Notes</dt> */}
        {/* <div className=" mt-2 text-sm w-full text-gray-500">{inputComponent("text", jsonformsDataIn.Notes, jsonformsDataIn.id, "Notes", "w-full h-40 self-start")}</div>                */}
        <textarea
          className="shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300 rounded-md w-full h-20"
          type={"textarea"}
          name={name}
          id={name}
          defaultValue={valueIn}
          placeholder={defaultShow && defaultShow !== "null" ? defaultShow : ""}
        ></textarea>
      </div>
    );
  } else {
    return (
      <input
        className={
          "shadow-sm focus:ring-red-500 focus:border-red-500  sm:text-sm border-gray-300 rounded-md " +
          formatting
        }
        type={inputType}
        name={name}
        id={name}
        //   defaultValue={defaultShow && defaultShow !== 'null'? defaultShow : ""}
        defaultValue={valueIn}
      />
    );
  }
}
