/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Person as UserIcon } from "@mui/icons-material";
import ProjectContactLookupComboBox from "../Availables/AvailableContactLookupComboBox"//"./ProjectContactLookupComboBox";
import ContactEdit from "../Contacts/ContactEdit";
import ProjectContactNew from "./ProjectContactNew";
import RoleCombo from "../Building/RoleCombo";
// import GroupCombo from "./ProjectGroupContactCombo";
import GroupCombo from "../Common/GroupCombo";
import MissingFieldsSplash from "../Common/MissingFieldsSplash";

export default function ProjectContactSelectModal({
  open,
  setOpen,
  // peopleCombo,
  peopleIn,
  setPeopleIn,
  projectId,
  triggerSetItems,
  contactId,
  groupsIn,
}) {
  //test - console.log("LOOP IN AVAIALABLE");
  const [buttonText, setButtonText] = useState("Select Contact");

  const cancelButtonRef = useRef(null);
  const [selectedPerson, setSelectedPerson] = useState();
  const [addPerson, setAddPerson] = useState(false);
  const [selectedRole, setSelectedRole] = useState({ id: 1, name: "Landlord Broker" });
  const [selectedGroup, setSelectedGroup] = useState(
    groupsIn.length > 0 ? { groupId: groupsIn[0].id, name: groupsIn[0].GroupName } : null
  );
  const [openSplash, setOpenSplash] = useState(false);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <UserIcon
                    className="h-6 w-6 text-red-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Select or Add Contact
                  </Dialog.Title>
                  <div className="mt-2">
                    <ProjectContactLookupComboBox
                      // peopleCombo={peopleCombo}
                      selectedPerson={selectedPerson}
                      setSelectedPerson={setSelectedPerson}
                      setButtonText={setButtonText}
                      setAddPerson={setAddPerson}
                    />
                  </div>
                  <div style={{ marginTop: 12 }}>
                    <RoleCombo
                      selectedRole={selectedRole}
                      setSelectedRole={setSelectedRole}
                    />
                  </div>
                  <div style={{marginTop: 12}}>
                    <GroupCombo
                      groupsIn={groupsIn}
                      selectedGroup={selectedGroup}
                      setSelectedGroup={setSelectedGroup}
                    />
                  </div>
                </div>
              </div>
              {addPerson ? (
                <div>
                  <div className="py-2 text-center">Create A New Contact</div>
                  <ProjectContactNew NameIn={selectedPerson} />
                </div>
              ) : (
                ""
              )}
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm"
                  onClick={() => {
                    //test - console.log("button pushed");
                    //test - console.log(selectedPerson);
                    //test - console.log(projectId);
                    if (selectedGroup) {
                      if (buttonText === "Add New Contact") {
                        //test - console.log("Add a new contact here");
                        var person = {
                          FirstName: document.getElementById("FirstName").value,
                          LastName: document.getElementById("LastName").value,
                          Email: document.getElementById("Email").value,
                          Phone: document.getElementById("Phone").value,
                          Linkedin: document.getElementById("Linkedin").value,
                          Website: document.getElementById("Website").value,
                          Title: document.getElementById("Title").value,
                          office_id: null,
                        };

                        newContact(
                          projectId,
                          person,
                          setPeopleIn,
                          triggerSetItems,
                          selectedRole.name,
                          contactId,
                          selectedGroup.groupId
                        );
                        setOpen(false);
                      } else {
                        addContact(
                          selectedPerson.id,
                          projectId,
                          setPeopleIn,
                          peopleIn,
                          triggerSetItems,
                          selectedRole.name,
                          contactId,
                          selectedGroup.groupId
                        );
                        setOpen(false);
                      }
                    } else {
                      setOpenSplash(true)
                    }
                  }}
                >
                  {buttonText}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
        <MissingFieldsSplash
          open={openSplash}
          setOpen={setOpenSplash}
          message={"Please fill out all fields"}
          title={"Missing Information"}
        />
      </Dialog>
    </Transition.Root>
  );
}

async function newContact(
  projectId,
  person,
  setPeopleIn,
  triggerSetItems,
  role,
  userId,
  selectedGroup
) {
  var fetchLocation =
    process.env.REACT_APP_DATABASE + "api/addContactProject?projectId=" +
    projectId +
    "&groupId=" +
    selectedGroup +
    "&role=" +
    role +
    "&" +
    Object.keys(person)
      .map((key) => key + "=" + person[key])
      .join("&");
  var result = await fetch(fetchLocation);
  fetchLocation =
    process.env.REACT_APP_DATABASE + "api/getProject?id=";
  const searchResults = await fetch(
    fetchLocation + projectId + "&contactId=" + userId
  );
  const data = await searchResults.json();
  // var myJson = data.project[0].contacts; // search results come in an array, and the element is also an array
  var myJson = data.project.contacts; // search results come in an array, and the element is also an array
  var sort = orderProjectContacts(
    myJson.sort(
      (a, b) => a.project_contact.sortOrder - b.project_contact.sortOrder
    )
  );
  setPeopleIn(
    myJson.sort(
      (a, b) => a.project_contact.sortOrder - b.project_contact.sortOrder
    )
  );
  triggerSetItems(
    myJson.sort(
      (a, b) => a.project_contact.sortOrder - b.project_contact.sortOrder
    )
  );
}
async function addContact(
  contactId,
  projectId,
  setPeopleIn,
  peopleIn,
  triggerSetItems,
  role,
  userId,
  selectedGroup
) {
  var fetchLocation =
    process.env.REACT_APP_DATABASE + "api/addProjectContact?projectId=" +
    projectId +
    "&&contactId=" +
    contactId +
    "&role=" +
    role +
    "&groupId=" +
    selectedGroup;
  //test - console.log(fetchLocation);
  const resultInfo = await fetch(fetchLocation);
  var myWaitVar = await resultInfo.json();
  fetchLocation =
    process.env.REACT_APP_DATABASE + "api/getProject?id=";
  const searchResults = await fetch(
    fetchLocation + projectId + "&contactId=" + userId
  );
  //test - console.log(fetchLocation + projectId + "&contactId=" + userId);
  const data = await searchResults.json();
  ;
  // var myJson = data.project[0].contacts; // search results come in an array, and the element is also an array
  var myJson = data.project.contacts; // search results come in an array, and the element is also an array

  var sort = orderProjectContacts(
    myJson.sort(
      (a, b) => a.project_contact.sortOrder - b.project_contact.sortOrder
    )
  );
  setPeopleIn(
    myJson.sort(
      (a, b) => a.project_contact.sortOrder - b.project_contact.sortOrder
    )
  );
  triggerSetItems(
    myJson.sort(
      (a, b) => a.project_contact.sortOrder - b.project_contact.sortOrder
    ),
    peopleIn
  );
}

async function orderProjectContacts(contacts) {
  //test - console.log("Update the order in the database");
  ////test - console.log(projects)
  var mynum = 0;

  contacts.map(async (contact, index) => {
    mynum = index + 1;
    //test - console.log(contact.id);
    var qs = "id=" + contact.project_contact.id + "&sortOrder=" + mynum;
    var resultInfo = await fetch(
      process.env.REACT_APP_DATABASE + "api/sortProjectContacts?" +
      qs
    );
  });
}