import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import axios from 'axios';

const SendCampaignButton = ({ disabled, campaignId }) => {
    const [open, setOpen] = useState(false);
    const [sending, setSending] = useState(false);
    const [message, setMessage] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setMessage('');
    };

    const handleSend = async () => {
        setSending(true);
        
        try {
            const response = await axios.post(process.env.REACT_APP_DATABASE + 'api/sendCampaign', { campaignId });
            setMessage(response.data.message);
        } catch (error) {
            setMessage('Error sending campaign');
        } finally {
            setSending(false);
            setOpen(false);
        }
    };

    return (
        <>
            <Button variant="contained" color="primary" onClick={handleClickOpen} disabled={sending || disabled}>
                Send Campaign
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Send Campaign</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You are about to send this campaign to all recipients. Are you sure you want to proceed?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSend} color="secondary" disabled={sending}>
                        {sending ? 'Sending...' : 'Send'}
                    </Button>
                </DialogActions>
            </Dialog>
            {message && (
                <Dialog open={Boolean(message)} onClose={() => setMessage('')}>
                    <DialogTitle>Send Campaign</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setMessage('')} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default SendCampaignButton;
