import { useState } from "react";
import ListBox from "../../Common/ListBox";

export default function MyGroupNew({ newGroupName }) {
  const [yesNo, setYesNo] = useState("No");

  return (
    <>
      <div className="flex justify-center"><div>New Group Name: </div><div className="px-2">{newGroupName} </div></div>
      <div className="px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1 hidden">
            {" "}
            {/* hidden tag here */}
            <dt className="text-sm font-medium text-gray-500">Group Name</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {inputComponent(
                "hidden",
                "GroupName",
                "id",
                "GroupName",
                newGroupName
              )}{" "}
            </dd>
            {/*<dd className="mt-1 text-sm text-gray-900">{nfObject.format(SizeMin)} - {nfObject.format(SizeMax)} SF</dd>*/}
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Members see group directory
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {inputComponent(
                "select",
                "Members Visible",
                "id",
                "MembersVisible",
                yesNo,
                setYesNo,
                ["Yes", "No"]
              )}
            </dd>
            {/*<dd className="mt-1 text-sm text-gray-900">{Expenses ? nfObjectRate.format(Expenses) : "Please Inquire"}/SF/Month</dd>*/}
          </div>

          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">Notes</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <textarea
                id="Notes"
                name="Notes"
                rows={3}
                className="max-w-lg shadow-sm block w-full focus:ring-red-500 focus:border-red-500 sm:text-sm border border-gray-300 rounded-md"
                defaultValue={""}
              />
              <p className="mt-2 text-sm text-gray-500">
                Write a few notes about the this group.
              </p>
            </dd>
          </div>
        </dl>
      </div>
    </>
  );
}

function inputComponent(
  inputType,
  defaultShow,
  id,
  name,
  valueIn,
  setvalueIn,
  options
) {
  if (inputType === "date") {
    return (
      <input
        className="shadow-sm focus:ring-red-500 focus:border-red-500  sm:text-sm border-gray-300 rounded-md"
        type={inputType}
        name={name}
        id={name}
        defaultValue={defaultShow && defaultShow !== "null" ? defaultShow : ""}
      />
    );
  } else if (inputType === "hidden") {
    return (
      <input
        className="shadow-sm focus:ring-red-500 focus:border-red-500  sm:text-sm border-gray-300 rounded-md"
        type="text"
        name={name}
        id={name}
        defaultValue={defaultShow && defaultShow !== "null" ? defaultShow : ""}
        value={valueIn}
      />
    );
  } else if (inputType === "select") {
    return (
      <>
        <input
          className="shadow-sm focus:ring-red-500 focus:border-red-500  sm:text-sm border-gray-300 rounded-md hidden"
          type="text"
          name={name}
          id={name}
          defaultValue={defaultShow && defaultShow !== "null" ? defaultShow : ""}
          value={valueIn}
        />
        <ListBox
          selected={valueIn}
          setSelected={setvalueIn}
          options={options}
        />
      </>
    );
  } else {
    return (
      <input
        className="shadow-sm focus:ring-red-500 focus:border-red-500  sm:text-sm border-gray-300 rounded-md"
        type={inputType}
        name={name}
        id={name}
        //   defaultValue={defaultShow && defaultShow !== 'null'? defaultShow : ""}
        defaultValue={valueIn}
      />
    );
  }
}
