import React from "react";
import { useState } from "react";
import "keen-slider/keen-slider.min.css";
import Header from "../Common/Header";
import ImageUploadGallery from "../Common/Uploader/ImageUploadGallery";
import axios from 'axios'
import { markDeleteImage, orderImages } from "../Availables/AvailableDetail";

import CompanyEdit from "./CompanyEdit";
import CompanyView from "./CompanyView";
import CompanyOfficesList from "./CompanyOfficesList";


import { Button } from "@mui/material";

import CompanyCompanyModal from "./CompanyCompanyModal";
import KeenSlider, { KeenSliderWide } from "../Images/KeenSlider";

export default function CompanyDetail({
  companyInformation,
  buildingInformation,
  user,
}) {
  const [companyDataIn, setData] = useState(companyInformation);
  const [buildingInfoIn, setBuildingInfoIn] = useState(buildingInformation);
  var initialPics = [];
  const [editMode, setEditMode] = useState(false);
  const [showCompanyModal, setShowCompanyModal] = useState(false);

  if (companyDataIn && companyDataIn.images) {
    companyDataIn.images.map((image) => {
      initialPics.push({
        source: image.img,
        img: image.img,
        name: image.id,
      });
    });
  }



  async function addCompanyImage(image) {
    const updatedImages = [...companyDataIn.images, {
      source: image.message,
      img: image.message,
      url: image.message,
      name: image.name,
      id: image.name
    }];

    var result = await axios.get(process.env.REACT_APP_DATABASE + "api/addCompanyImage", {
      params: {
        img: image.message,
        myId: user.contact.id,
        companyId: companyDataIn.id,
        sortOrder: updatedImages.length
      }
    })
    setData({ ...companyDataIn, images: updatedImages });
  }

  return (
    <div>
      <nav className="sticky z-50 top-0">
        <Header
          key="1"
          icon="company"
          placeholderLocation={companyDataIn.Name}
          user={user}
        />
      </nav>

      <div style={{ display: 'block', maxWidth: '600px', margin: '0 auto', textAlign: 'left' }}>


        {!editMode ? (
          <KeenSlider dataIn={companyDataIn} />
        ) : (
          <ImageUploadGallery
            initialImages={initialPics}
            onDeleted={(image) =>
              markDeleteImage(image, companyDataIn, setData)
            }
            onSortEnd={async (images) => {

              await orderImages(
                images,
                companyDataIn,
                setData
              )
            }
            }
            action={process.env.REACT_APP_IMAGE}
            onSuccess={(image) => {
              addCompanyImage(image)
            }}
          />
        )}


        <div>
          {!editMode ? (
            <CompanyView companyDataIn={companyDataIn} />
          ) : (
            <CompanyEdit companyDataIn={companyDataIn} setCompanyDataIn={setData}  />
          )}
        </div>
        <div style={{ maxWidth: 600, marginTop: 5, marginBottom: 5 }}>
          {editMode ?<>
            <Button
              variant="contained"
              onClick={
                async () => {
                  var result = axios.post(process.env.REACT_APP_DATABASE + "api/updateCompany", companyDataIn).catch((error)=>{console.log(error)})
                  if (result) {
                    alert("Company data saved")
                  }
                  setEditMode(false)
                }
              }>
              Save
            </Button>
            <Button style={{marginLeft: 2}} variant="outlined" onClick={() => {
              setData({ ...companyInformation })
              setEditMode(false)
            }}>Cancel</Button>
          </> :
            <Button
              onClick={
                () => {
                  
                  setEditMode(true)
                }
              } variant="contained">Edit</Button>}
        </div>
   
          <CompanyOfficesList
            officesIn={buildingInfoIn}
            setShowCompanyModal={setShowCompanyModal}
          />
  
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          {/* <CompanyAvailsList availsIn={companyDataIn.avails}/> */}
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          {/* <CompanyBuildingList buildingsIn={buildingInfoIn.buildings}/>  */}
        </div>
        <div>
          <CompanyCompanyModal
            open={showCompanyModal}
            setOpen={setShowCompanyModal}
            office={companyDataIn.office ? companyDataIn.office : null}
            companyId={companyDataIn.id}
            setCompany={setData}
          />
        </div>
      </div>
    </div>
  );







}


