
import { formatPhoneNumber } from "../Common/FormatPhoneNumber";
import { mobileAndTabletCheck } from "../Search/Deck";
import { ContactData } from "../Search/InfoCard";
import Grid from '@mui/material/Unstable_Grid2';
export default function ContactListView({ peopleIn }) {
  if (peopleIn && peopleIn.length > 0) {
    return (
      <Grid container spacing={4}>


 {     peopleIn.map((contact) => {
        return ContactData(contact)
      }
      )}
       
        </Grid>
    );
  }
}

