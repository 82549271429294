import { useState } from "react";

export default function Pin({
  filters,
  property,
  pinClick,
  pinHover,

  setSearchResults,
  showPrice,
}) {
  var nfObjectRate = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  //

  var markerInfo = getMarkerInfo(property)
  var offMarketBuilding = markerInfo.offMarketBuilding
  var onMarketAvailSF = markerInfo.onMarketAvailSF
  var offMarketAvailSF = markerInfo.offMarketAvailSF
  var onMarketCompsSF = markerInfo.onMarketCompsSF
  var offMarketCompsSF = markerInfo.offMarketCompsSF

  return (
    <div
      onClick={() => {
        pinClick(property, setSearchResults);
      }}
      onMouseOver={() => {
        pinHover(property);
      }}
      className={
        "flex cursor-pointer text-center text-lg font-sans " +
        (offMarketBuilding === "Yes"
          ? "z-3"
          : property.avails.length > 0
            ? "z-2"
            : "z-1")
      }
      style={{
        background:
          offMarketBuilding === "Yes" || offMarketAvailSF > 0
            ? offMarketCompsSF > 0
              ? "linear-gradient(90deg, red 50%, green 50%)" // off market comps and availables
              : "linear-gradient(90deg, red 50%, red 50%)" // off market availables only
            : onMarketAvailSF > 0
              ? offMarketCompsSF > 0
                ? "linear-gradient(90deg, red 50%, green 25%)" // on market availables, and off market comps
                : onMarketCompsSF > 0
                  ? "linear-gradient(90deg, red 50%, green 50%)" // on market comps and on market availables
                  : "red"
              : offMarketCompsSF > 0
                ? "linear-gradient(90deg, green 50%, green 50%)" // off market comps only
                : onMarketCompsSF > 0
                  ? "green" // onMarket comps only
                  : "black", // no comps no availables
        // background: "linear-gradient(90deg, #FFC0CB 50%, #00FFFF 50%)",
        zIndex:
          offMarketBuilding === "Yes" ||
            offMarketAvailSF > 0 ||
            offMarketCompsSF > 0
            ? 10
            : property.avails.length > 0
              ? 9
              : 8,
        color:
          offMarketBuilding === "Yes" ||
            offMarketAvailSF > 0 ||
            offMarketCompsSF > 0
            ? "white"
            : "white",
        fontSize: 12,
        borderRadius: 20,
        padding: 2,
        minWidth: 40,
        justifyContent: "center",
        borderColor: offMarketBuilding === "Yes" ||
          offMarketAvailSF > 0 ||
          offMarketCompsSF > 0 ? "yellow" : "black",
        borderWidth: offMarketBuilding === "Yes" ||
          offMarketAvailSF > 0 ||
          offMarketCompsSF > 0 ? 4 : 0
      }}
    >
      {/* <span>{getPrice(property)}</span> */}
      <span>
        {!showPrice ? (
          // getTotalSF(
          //   onMarketAvailSF,
          //   offMarketAvailSF,
          //   offMarketCompsSF,
          //   onMarketCompsSF,
          //   property
          // )

          <div className="flex justify-between">
            {filters.availablesCheck &&
              onMarketAvailSF + offMarketAvailSF > 0 ? (
              <>
                <div>
                  {getAvailSF(onMarketAvailSF, offMarketAvailSF, property)}
                </div>
              </>
            ) : (
              <></>
            )}

            {filters.compsCheck &&
              filters.availablesCheck &&
              onMarketCompsSF + offMarketCompsSF > 0 &&
              onMarketAvailSF + offMarketAvailSF > 0 ? (
              <>
                <div className="px-3"></div>
              </>
            ) : (
              <></>
            )}

            {onMarketCompsSF +
              offMarketCompsSF +
              onMarketAvailSF +
              offMarketAvailSF ===
              0 ? (
              getBuildingSF(property)
            ) : (
              <></>
            )}

            {filters.compsCheck && onMarketCompsSF + offMarketCompsSF > 0 ? (
              <div>
                {getCompsSF(onMarketCompsSF, offMarketCompsSF, property)}
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <>
            <div className="flex justify-between">
              {filters.availablesCheck &&
                onMarketAvailSF + offMarketAvailSF > 0 ? (
                <>
                  <div>{getAvailPrice(property)}</div>
                </>
              ) : (
                <></>
              )}

              {filters.compsCheck &&
                filters.availablesCheck &&
                onMarketCompsSF + offMarketCompsSF > 0 &&
                onMarketAvailSF + offMarketAvailSF > 0 ? (
                <>
                  <div className="px-3"></div>
                </>
              ) : (
                <></>
              )}

              {onMarketCompsSF +
                offMarketCompsSF +
                onMarketAvailSF +
                offMarketAvailSF ===
                0 ? (
                "n/a"
              ) : (
                <></>
              )}

              {filters.compsCheck && onMarketCompsSF + offMarketCompsSF > 0 ? (
                <div>{getCompsPrice(property)}</div>
              ) : (
                <></>
              )}
            </div>
          </>
        )}
      </span>
    </div>
  );
}

export function getBuildingSF(resultOfBuilding) {
  return Math.round(resultOfBuilding.Size / 1000) + "K SF";
}
function getTotalSF(
  onMarketAvailSF,
  offMarketAvailSF,
  offMarketCompsSF,
  onMarketCompsSF,
  resultOfBuilding
) {
  var totalSize = 0;
  var minSize = 0;
  if (
    onMarketAvailSF + offMarketAvailSF + offMarketCompsSF + onMarketCompsSF ===
    0
  ) {
    return Math.round(resultOfBuilding.Size / 1000) + "K SF";
  } else if (
    resultOfBuilding.avails.length === 1 &&
    onMarketAvailSF + offMarketAvailSF > 0
  ) {
    if (
      Math.round(resultOfBuilding.avails[0].SizeMin / 1000) ===
      Math.round(resultOfBuilding.avails[0].SizeMax / 1000)
    ) {
      return Math.round(resultOfBuilding.avails[0].SizeMax / 1000) + "K SF";
    } else {
      return (
        Math.round(resultOfBuilding.avails[0].SizeMin / 1000) +
        "-" +
        Math.round(resultOfBuilding.avails[0].SizeMax / 1000) +
        "K SF"
      );
    }
  } else if (
    onMarketAvailSF + offMarketAvailSF === 0 &&
    onMarketCompsSF + offMarketCompsSF > 0
  ) {
    return Math.round((onMarketCompsSF + offMarketCompsSF) / 1000) + "K SF";
  } else if (onMarketAvailSF + offMarketAvailSF > 0) {
    resultOfBuilding.avails.map((available) => {
      totalSize = totalSize + available.SizeMax;
    });

    resultOfBuilding.avails.map((available) => {
      if (available.SizeMin > 0 && minSize === 0) {
        minSize = available.SizeMin;
      } else if (available.SizeMin > 0) {
        if (available.SizeMin < minSize) {
          minSize = available.SizeMin;
        }
      }
    });
    return (

      Math.round(minSize / 1000) + "-" + Math.round(totalSize / 1000) + "K SF"
    );
  }
}

export function getAvailSF(
  onMarketAvailSF,
  offMarketAvailSF,

  resultOfBuilding
) {


  var totalSize = 0;
  var minSize = 0;
  var returnValue = ""
  if (onMarketAvailSF + offMarketAvailSF === 0) {
    returnValue =  Math.round(resultOfBuilding.Size / 1000) + "K SF";
  } else if (
    resultOfBuilding.avails.length === 1 &&
    onMarketAvailSF + offMarketAvailSF > 0
  ) {
    if (
      Math.round(resultOfBuilding.avails[0].SizeMin / 1000) ===
      Math.round(resultOfBuilding.avails[0].SizeMax / 1000)
    ) {
      returnValue =  Math.round(resultOfBuilding.avails[0].SizeMax / 1000) + "K SF";
    } else {
      returnValue =  
        Math.round(resultOfBuilding.avails[0].SizeMin / 1000) +
        "-" +
        Math.round(resultOfBuilding.avails[0].SizeMax / 1000) +
        "K SF"
      
    }
  } else if (onMarketAvailSF + offMarketAvailSF > 0) {
    resultOfBuilding.avails.map((available) => {
      if (available.Status !== "Lease Comp") {
        
        totalSize = totalSize + available.SizeMax;
      }
    });

    resultOfBuilding.avails.map((available) => {
      if (available.Status !== "Lease Comp") {
        if (available.SizeMin > 0 && minSize === 0) {
          minSize = available.SizeMin;
        } else if (available.SizeMin > 0) {
          if (available.SizeMin < minSize) {
            minSize = available.SizeMin;
          }
        }
      }
    });
    if (minSize === totalSize) {
      returnValue =  

        Math.round(minSize / 1000) + "K SF"
      
    }
    else {
      returnValue=

        Math.round(minSize / 1000)+ "-" + Math.round(totalSize / 1000)+ "K SF"
      
    }
  }

  return (returnValue)
}

export function getCompsSF(offMarketCompsSF, onMarketCompsSF, resultOfBuilding) {

  var totalSize = 0;
  var minSize = 0;
  if (onMarketCompsSF + offMarketCompsSF === 0) {
    return null;
  } else {
    resultOfBuilding.avails.map((available) => {

      if (available.Status === "Lease Comp") {
        
        totalSize = totalSize + available.LeasedSpace;
      }
    });

    return Math.round(totalSize / 1000) + "K SF";
  }
}

export function getAvailPrice(resultOfBuilding) {
  ////test - console.log(resultOfBuilding);
  if (resultOfBuilding.avails.length === 0) {
    return "n/a";
  }
  // else if (resultOfBuilding.avails.length === 1) {
  //   if (resultOfBuilding.avails[0].Status !== "Lease Comp") {
  //     return formatProdPriceMap(resultOfBuilding.avails[0].AskRate);
  //   }
  // }
  else {
    var lowPrice = 0;
    var highPrice = 0;

    resultOfBuilding.avails.map((avail) => {
      if (avail.Status !== "Lease Comp") {
        if (lowPrice === 0 && avail.AskRate > 0) {
          lowPrice = avail.AskRate;
        } else if (lowPrice > avail.AskRate) {
          lowPrice = avail.AskRate;
        }
        if (highPrice === 0 && avail.highPrice > 0) {
          highPrice = avail.AskRate;
        } else if (highPrice < avail.AskRate) {
          highPrice = avail.AskRate;
        }
      }
    });

    if (
      lowPrice &&
      highPrice &&
      lowPrice !== 0 &&
      highPrice !== 0 &&
      highPrice > lowPrice
    ) {
      return (
        formatProdPriceMap(lowPrice) + "-\n" + formatProdPriceMap(highPrice)

        // +
        // " "
        //  + resultOfBuilding.avails[0].RentStructure
      );
    } else {
      if (lowPrice & (lowPrice !== 0)) {
        return formatProdPriceMap(lowPrice);
      } else {
        return formatProdPriceMap(highPrice);
      }
    }
  }
}

export function getCompsPrice(resultOfBuilding) {
  ////test - console.log(resultOfBuilding);
  if (resultOfBuilding.avails.length === 0) {
    return "n/a";
  } else if (resultOfBuilding.avails.length === 1) {
    if (resultOfBuilding.avails[0].Status === "Lease Comp") {
      return formatProdPriceMap(resultOfBuilding.avails[0].InitialRent);
    }
  } else {
    var lowPrice = 0;
    var highPrice = 0;

    resultOfBuilding.avails.map((avail) => {
      if (avail.Status === "Lease Comp") {
        if (lowPrice === 0 && avail.InitialRent > 0) {
          lowPrice = avail.InitialRent;
        } else if (lowPrice > avail.InitialRent) {
          lowPrice = avail.InitialRent;
        }
        if (highPrice === 0 && avail.highPrice > 0) {
          highPrice = avail.InitialRent;
        } else if (highPrice < avail.InitialRent) {
          highPrice = avail.InitialRent;
        }
      }
    });

    if (
      lowPrice &&
      highPrice &&
      lowPrice !== 0 &&
      highPrice !== 0 &&
      highPrice > lowPrice
    ) {
      return (
        formatProdPriceMap(lowPrice) + "-\n" + formatProdPriceMap(highPrice)

        // +
        // " "
        //  + resultOfBuilding.avails[0].RentStructure
      );
    } else {
      if (lowPrice & (lowPrice !== 0)) {
        return formatProdPriceMap(lowPrice);
      } else {
        return formatProdPriceMap(highPrice);
      }
    }
  }
}
function formatProdPriceMap(value) {
  ////test - console.log("price: " + value);
  if (isNaN(value) || value === 0 || !value) {
    return "";
  } else {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  }
}

export function getMarkerInfo(property) {
  var offMarketBuilding = "Yes";
  // var offMarketAvailables = [];
  // var offMarketComps = [];
  // var onMarketComps = [];
  // var onMarketAvailables = [];

  var onMarketAvailSF = 0;
  var offMarketAvailSF = 0;
  var onMarketCompsSF = 0;
  var offMarketCompsSF = 0;
  var comps = 0
  var avails = 0
  if (!property || !property.groups) {
    return (
      {
        offMarketBuilding: 0,
        onMarketAvailSF: 0,
        offMarketAvailSF: 0,
        onMarketCompsSF: 0,
        offMarketCompsSF: 0,
        comps: 0,
        avails: 0}
    )
  }
  property.groups.forEach((group) => {
    if (
      group.building_group.groupId === 1 &&
      group.building_group.markedDelete === 0
    ) {
      offMarketBuilding = "No";
    }
  });

  var recentPrice = {
    rate: 0,
    date: new Date("1/1/1600"),
    RentStructure: "NNN",
  };
  property.avails.forEach((available) => {
    var offMarket = "Yes";

    if (available.public > 0) {
      offMarket = "No";
    }

    // available.avail_groups.forEach((avail_group) => {
    //   if (avail_group.groupId === 1 && avail_group.markedDelete === 0) {
    //     offMarket = "No";
    //   }
    // });

    if (available.Status !== "Lease Comp") {
      avails = avails + 1
      if (offMarket === "Yes") {

        offMarketAvailSF = offMarketAvailSF + available.SizeMax;
      } else {
        onMarketAvailSF = onMarketAvailSF + available.SizeMax;
      }
      if (recentPrice.date < new Date(available.DateAvailable)) {
        recentPrice.date = new Date(available.DateAvailable);
        recentPrice.rate = available.AskRate;
        recentPrice.RentStructure = available.RentStructure;
      }
    } else {
      comps = comps + 1
      if (offMarket === "Yes") {
        offMarketCompsSF = offMarketCompsSF + available.LeasedSpace; // this is where to add off market, change to on market if you just like the green and assume all are off market
      } else {
        onMarketCompsSF = onMarketCompsSF + available.LeasedSpace;
      }
      if (recentPrice.date < new Date(available.ExecutionDate)) {
        recentPrice.date = new Date(available.ExecutionDate);
        recentPrice.rate = available.InitialRent;
        recentPrice.RentStructure = available.RentStructure;
      }
    }
  });

  return {
    offMarketBuilding: offMarketBuilding,
    onMarketAvailSF: onMarketAvailSF,
    offMarketAvailSF: offMarketAvailSF,
    onMarketCompsSF: onMarketCompsSF,
    offMarketCompsSF: offMarketCompsSF,
    comps: comps,
    avails, avails
  }
}