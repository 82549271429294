/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { InputComponent } from "./InputComponent";


export default function WelcomeSplash({ contact }) {
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);


  if (!contact || !contact.id) {
    alert("There was a problem with the contact id.")
    window.open("/")
    return -1
  }
  else {
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-100"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-100 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 ">
                      {/* <UserIcon className="h-6 w-6 text-red-600" aria-hidden="true" /> */}
                      <img
                        src="https://images.cubicsearch.com/UserLogo.png"
                        className="h-12 w-12 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Welcome to Cubic Search!
                      </Dialog.Title>
                      <div className="mt-2">
                        <div className="text-sm text-gray-500">
                          Please enter your name.{" "}
                          <p className="text-sm text-gray-500">
                            Fill in more on My Profile found on the upper right
                            menu.{" "}
                          </p>
                        </div>
                      </div>
                      <div className="stackOnSmall justify-center py-2">
                        <div className="px-2">
                          <InputComponent
                            inputType="placeholder"
                            defaultShow="First Name"
                            id="id"
                            name="FirstName"
                            setvalueIn={setFirstName}
                          />
                        </div>
                        <div>
                          <InputComponent
                            inputType="placeholder"
                            defaultShow="Last Name"
                            id="id"
                            name="LastName"
                            setvalueIn={setLastName}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6  sm:gap-3 sm:grid-flow-row-dense">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm"
                      onClick={() => {
                        var myName = {
                          FirstName: document.getElementById("FirstName").value,
                          LastName: document.getElementById("LastName").value,
                        };

                        
                        fetch(
                          process.env.REACT_APP_DATABASE +
                          "api/updateContactJson?id=" +
                          contact.id +
                          "&newData=" +
                          encodeURI(JSON.stringify(myName))
                        ).then(() => {
                          setOpen(false);
                        });
                      }}
                    >
                      OK
                    </button>
                    {/* <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Create
                  </button> */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}
