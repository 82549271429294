import Slider from '@mui/material/Slider';
import { Typography } from "@mui/material";
import React from "react";


function valueLabelFormat(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  
  const sfMarks = [
    // {
    //   value: 0,
    //   scaledValue: 0,
    //   label: "0"
    // },
    // {
    //   value: 3,
    //   scaledValue: 500,
    //   label: "500"
    // },
    {
      value: 0,
      scaledValue: 0,
      label: "0"
    },
    {
      value: 25,
      scaledValue: 5000,
      label: "5k"
    },
    {
      value: 50,
      scaledValue: 10000,
      label: "10k"
    },
    {
      value: 75,
      scaledValue: 25000,
      label: "25k"
    },
    {
      value: 100,
      scaledValue: 50000,
      label: "50k"
    },
    {
      value: 125,
      scaledValue: 100000,
      label: "100k"
    },
    {
      value: 150,
      scaledValue: 250000,
      label: window.innerWidth > 800 ? "250K":""
    },
    {
      value: 175,
      scaledValue: 500000,
      label: "500k"
    },
    {
      value: 200,
      scaledValue: 1000000,
      label: "1M"
    }
  ];
  
  
  const scale = value => {

    const previousMarkIndex = Math.floor(value / 25);
    const previousMark = sfMarks[previousMarkIndex];
    const remainder = value % 25;
    if (remainder === 0) {
      return previousMark.scaledValue;
    }
    const nextMark = sfMarks[previousMarkIndex + 1];
    const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
    return remainder * increment + previousMark.scaledValue;
  };
  
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(0) + "M"; // convert to M for number from > 1 million
    } else if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  }
  
  export default function NonLinearSlider( {setMinAvailSize ,
    setMaxAvailSize, minAvailSize, maxAvailSize, addSizeToFilters}) {
   // const [value, setValue] = React.useState([minAvailSize,maxAvailSize]);



    const handleChange = (event, newValue) => {
        setMinAvailSize(newValue[0])
      setMaxAvailSize(newValue[1])

      
    //   setValue(newValue);

    };
  
    return (
      <div className="text-xs">
        {/* <Typography component={'div'}id="non-linear-slider" gutterBottom>
         Available SF
        </Typography> */}
         Available Square Feet: {scale(minAvailSize).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} - {scale(maxAvailSize).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} SF
        <Slider

        //   value={value}
        size = "small"
          value={[minAvailSize, maxAvailSize]}
          min={0}
          step={1}
          max={200}
          valueLabelFormat={numFormatter}
          marks={sfMarks}
          scale={scale}
          onChange={handleChange}
          valueLabelDisplay="auto"
          aria-labelledby="non-linear-slider"
          
          // sx={{color: 'pri'}}
        />
       
      </div>
    );
  }