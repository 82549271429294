/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import { Edit as PencilIcon } from "@mui/icons-material"

import JournalTable from './JournalTable'

export default function JournalSplash({open, setOpen, contactId, entityId, type}) {
  const [journalsIn, setJournalsIn] = useState(null)
  const cancelButtonRef = useRef(null)
  if (open && !journalsIn)  {

    var qs = process.env.REACT_APP_DATABASE 
    if (type==="Available") {
      qs = qs + "api/getAvailableJournals?id=" + entityId + "&contactId=" + contactId
    }
    else if (type ==="Building")
    {
      qs = qs + "api/getBuildingJournals?id=" + entityId + "&contactId=" + contactId

    }
    else if (type === "Contact") {
      qs = qs + "api/getContactJournals?id=" + entityId + "&contactId=" + contactId
    }

    //test - console.log(qs)
    fetch(qs).then((journalsRaw)=>{journalsRaw.json().then((journalsInJSON)=>{
      // 
      setJournalsIn(journalsInJSON)
    })})

  }


  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-102" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-100 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8  sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 ">
                    {/* <UserIcon className="h-6 w-6 text-red-600" aria-hidden="true" /> */}
                    <PencilIcon className="h-6 w-6 text-red-600" aria-hidden="true"/>
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Historical Changes
                    </Dialog.Title>
                    <div className="mt-2 ">
                      <p className="text-sm text-gray-500 overflow-y-scroll max-h-halfscreen ">
                        <JournalTable journals={journalsIn}/>
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"> */}
               <div> 
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    OK
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )

 
}
