import React from 'react';
import { ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Box, IconButton } from '@mui/material';
import { RemoveCircle } from '@mui/icons-material';
import PeopleAvatars from './PeopleAvatars'; // Ensure this path is correct


function CustomListItem({ item, toggleItem, isSelected,
    clusterHighlightedProperties, getPeople,
    keywords, mailListId, contactId, removeCompany, itemRefs, MailListName, setSearchMethod, recipients, fetchData }) {

    

    item.keywords = keywords
    return (
        <ListItem
            onClick={() => toggleItem(item)}
            ref={el => itemRefs.current[item.properties.placeId] = el}
            sx={{
                backgroundColor: isSelected ? 'yellow' : 'transparent',
                border: clusterHighlightedProperties ? 5 : 0,
                borderColor: 'green',
                borderRadius: 5,
                marginBottom: 1,
                flexDirection: 'column', // Changes flex direction to column
                alignItems: 'flex-start' // Aligns items to the start, preventing full width stretch
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <ListItemAvatar>
                        <Avatar
                            style={{
                                marginRight: 2,
                                borderRadius: '10%',
                                objectFit: 'contain'
                            }}
                            imgProps={{
                                style: {
                                    objectFit: 'contain'
                                }
                            }}
                            src={item.properties.images?.[0]?.img || 'https://cubicsearch.com/broken'}
                            alt={item.properties.Name}
                        />
                    </ListItemAvatar>
                    <ListItemText
                        primary={item.properties.Name}
                        secondary={
                            <>
                                <Typography component="span" variant="body2" color="textPrimary">
                                    {item.properties.StreetNumber} {item.properties.StreetName}, {item.properties.City}
                                </Typography>
                                <Typography>
                                    {item.properties.Website}
                                </Typography>
                                <br />
                                {item.properties.Notes}
                            </>
                        }
                    />
                    <IconButton onClick={() => {

                        removeCompany(item.properties.placeId)
                    }
                    } sx={{ marginLeft: 'auto' }}>
                        <RemoveCircle />
                    </IconButton>
                </Box>
                {/* PeopleAvatars is now placed below the text and avatar */}

                <PeopleAvatars office={item.properties} setSearchMethod={setSearchMethod}
                    getPeople={getPeople} mailListId={mailListId} contactId={contactId}    recipients={recipients}
                                        fetchData={fetchData} Title={
                        <Box>
                            <Box><Typography variant='h6'>For Recipient List - {MailListName}</Typography></Box>
                            <Box sx={{ border: 1, borderRadius: 5, padding: 2 }}>Local Office
                                <Box sx={{ display: 'flex' }}>

                                    <ListItemAvatar title='hello'>

                                        <Avatar
                                            style={{
                                                marginTop: 6,
                                                marginRight: 2,
                                                borderRadius: '10%',
                                                objectFit: 'contain'
                                            }}
                                            imgProps={{
                                                style: {
                                                    objectFit: 'contain'
                                                }
                                            }}
                                            src={item.properties.images?.[0]?.img || 'https://cubicsearch.com/broken'}
                                            alt={item.properties.Name}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={item.properties.Name}
                                        secondary={
                                            <>
                                                <Typography component="span" variant="body2" color="textPrimary">
                                                    {item.properties.StreetNumber} {item.properties.StreetName}, {item.properties.City}  {item.properties.State}
                                                </Typography>
                                                <Typography>
                                                    {item.properties.Website}
                                                </Typography>
                                                <br />
                                                {item.properties.Notes}
                                            </>
                                        }
                                    /></Box>
                            </Box>
                        </Box>} />

            </Box>

        </ListItem>

    );
}

export default CustomListItem;

