import React, {
    useState,
    // useRef,
    useImperativeHandle, useEffect,
    // useMemo
} from 'react';
import './Deck.css'

import {
    GeoJsonLayer,
    // IconLayer, TextLayer, LineLayer,
    ColumnLayer
} from '@deck.gl/layers';
// import { MVTLayer } from '@deck.gl/geo-layers';
import {
    Tile3DLayer,
    // TerrainLayer
} from '@deck.gl/geo-layers';

import { WebMercatorViewport } from '@deck.gl/core';
import {
    // DataFilterExtension,
    _TerrainExtension as TerrainExtension
} from '@deck.gl/extensions';
import {
    _MapContext as
         MapContext,
    NavigationControl,

    Map as DeckMap
} from 'react-map-gl'

import { getGeo } from './ParcelData/getGeo';

import { getMarkerInfo, getAvailPrice, getCompsPrice, getAvailSF, getCompsSF, getBuildingSF } from './Pin';

import TestAlert from './TestAlert';
import polylabel from 'polylabel';
import {

    ScenegraphLayer
} from  'deck.gl'

import { geoContains, } from 'd3-geo';

import axios from 'axios';

import { createCookie, getCookie } from '../Common/Cookies';
import { prepareTestAlert } from './TestAlert';

import SwipeableTemporaryDrawer from './Drawer';

import { AmbientLight, PointLight, DirectionalLight, LightingEffect } from '@deck.gl/core';
import Typography from '@mui/material/Typography';

import InfoCards from './InfoCards';

import { removeById } from './InfoCard';

import { getAxios, postAxios } from './Axios/getAndPostAxios';
import { getElevationFromZoom, getViewportElevation, getZoomFromElevation, center, sizeThresholds } from './ElevationFunctions/ElevationsFunctions';




import { MapboxOverlay } from '@deck.gl/mapbox';
// import { useControl, Source, Layer as MBLayer } from 'react-map-gl';
// import { Backup, WindowSharp } from '@mui/icons-material';
// import { DataGridPro } from '@mui/x-data-grid-pro';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
// import { arrayOf } from 'prop-types';





// const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GoogleMapsAPIKey; // eslint-disable-line
var TILESET_URL = 'https://tile.googleapis.com/v1/3dtiles/root.json?key=' + process.env.REACT_APP_GoogleMapsAPIKey;

export const COLORS = [
    [254, 235, 226],
    [251, 180, 185],
    [247, 104, 161],
    [197, 27, 138],
    [122, 1, 119]
];

// const colorScale = scaleLinear().clamp(true).domain([0, 50, 100, 200, 300]).range(COLORS);

// const ICON_MAPPING = {
//     marker: { x: 0, y: 800, z: 5, width: 344, height: 1720, mask: true }
// };
var newAllController = new AbortController()

// var newStreetsController = new AbortController()
// var newController = new AbortController()
// var newEuropeController = new AbortController()
var newNAController = new AbortController()
var newCitiesController = new AbortController()
var newStateController = new AbortController()


var coords = null
// const [coordsCircles, setCoordsCircles] = useState(null)
var coordsLines = null
var coordsCircles = null
var clusterVarData = null
var streetsOnMap = null
var citiesOnMap = null
var streetsLinesOnMap = null
var controller = new AbortController()
var controllerCities = new AbortController()
var controllerEurope = new AbortController()
var controllerNA = new AbortController()
var controllerAll = new AbortController()
var searchedLocations = null
var viewportElevation = { elevation: -1 }
const overlay = new MapboxOverlay({
    // interleaved: true,

    layers: []
});

// const [streetsLineOnMap, setStreetsLineOnMap] = useState(null)
// const [citiesOnMap, setCitiesOnMap] = useState(null)

// var newAllMaster = { controller: newAllController }
// var newControllerMaster = { controller: newController }
// var newEuropeMaster = { controller: newEuropeController }
var newNAMaster = { controller: newNAController }
var newCitiesMaster = { controller: newCitiesController }

// function DeckGLOverlay(props) {
//     const overlay = useControl(() => new MapboxOverlay(props));
//     overlay.setProps(props);
//     return null;
// }

// function getZoomCity(elevationCity) {
//     var newElevation = elevationCity + 2500
//     return getZoomFromElevation(newElevation)
// }
// function getZoomBuildingFar(elevationAndHeight) {
//     var newElevation = elevationAndHeight + 1000
//     return getZoomFromElevation(newElevation)
// }
// function getZoomBuildingClose(elevationAndHeight) {
//     var newElevation = elevationAndHeight + 300
//     return getZoomFromElevation(newElevation)
// }

const debounce = (callback, wait) => {
    let timeoutId = null;
    return (...args) => {
        window.clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
            callback.apply(null, args);
        }, wait);
    };
}

const Decky = React.forwardRef((
    {
        fabRefreshRef,
        filterInputs,
        // data = TILESET_URL,
        // distance = 0,
        // opacity = 0.2,
        geometry,
        // superMapRef,
        // setSuperMapRef,
        // setFilters,
        filters,
        searchResults,
        setSearchResults,
        // bounds,
        selectedLocation,
        setSelectedLocation,
        onMarkerClick,
        // onMarkerHover,
        popupInfo,
        setPopupInfo,
        // open,
        // toggleDrawer,
        // setID,
        mapButton,
        contactId,
        // setBoundsRef,
        reloadOnMove,
        setReloadOnMove,
        elevationFactorState,
        // setElevationFactorState,
        // setAddingNewProperties,
        // addingNewProperties,
        // showMap,
        // showPrice,
        mapRef,
        mapData,
        setMapData,
        sortByProperty,
        // pins,
        initialViewState,
        showParcels,
        setInitialViewState,
        confirmIn,
        // showAlert,
        // setShowAlert
        surveyId,
        setShowSurveys,
        setShowWait,
        // setCurrentViewState,
        getSelectedLocation,
        setSaveSurveyOpen
    }, ref) => {





    controller = new AbortController()
    controllerCities = new AbortController()
    controllerEurope = new AbortController()
    controllerNA = new AbortController()
    controllerAll = new AbortController()
    const [clusterData, setClusterData] = useState({ features: [] })
    const [isLayerAdded, setIsLayerAdded] = useState(false);
  
    const [drawerOpen, setDrawerOpen] = useState(popupInfo ? true : false)
    // const [selectedLocation, setSelectedLocation] = useState(null)
    // const [deckShow, setDeckShow] = useState(true)

    var refs = React.useRef([])

    // const [mapLayers, setMapLayers] = useState(null)
    // const [openAlert, setOpenAlert] = useState(false)
    // const [waitMessage, setWaitMessage] = useState("Loading")
    // const [refreshMessage, setRefreshMessage] = useState(null)
    // const deckLayersRef = useRef(null)
    

    // const [clusterData, setClusterData] = useState(null)

    // const [idPicked, setIdPicked] = useState(null)
    var currentElevation = 0

    console.log(JSON.stringify(initialViewState))
    const [params, setParams] = useState({

        parcelAPN: null,
        parcelInfo: null,
        open: false,
        // setOpen: setOpen,
        parcelResults: null,



    })
    // const [searchedLocations, setSearchedLocations] = useState(null)

    var myViewport
    var viewStateCookie

    const scrollToResult = (result) => {


        if (refs && refs.current[result.index]) {
            refs.current[result.index].scrollIntoView({
                // behavior: 'smooth',
                block: 'nearest', inline: 'start'
            });
        }
    }

  
      
 
    useImperativeHandle(ref, () => ({
        async mapReload() {


            // if (viewStates.length === 0) {
            //     viewStates.push(1)
            // }
            // setShowWait(true)
            console.log("map reload 1")
            await mapReload().catch((error) => {
                console.log(error)
            })
            // setShowWait(false)


        },
        removeProperty(property) {
            // setSearchedLocations(coordinates)
           removeProperty(property)

        },
        redoIcons() {
            var billBoardLayer = returnBillBoardLayer()
        
            // alert("I got called")
            overlay.setProps({ layers: [ billBoardLayer] })
            overlay.setProps({ layers: recalcLayers() })
        },

        setSearchedLocations(coordinates) {
            // setSearchedLocations(coordinates)
            searchedLocations = coordinates
            overlay.setProps({ layers: recalcLayers() })

        },
        async refreshMapProperties() {
            // var myViewport = getCurrentViewState()
            console.log("refresh viewportElevation")
            if (viewportElevation.elevation === -1) {
                viewportElevation = await getViewportElevation(mapRef, searchResults && (searchResults.length > 0) && searchResults[0])
            }

            var groundElevation = viewportElevation
            console.log("refresn in imperitive handle: " + new Date().getSeconds())

            refreshMapProperties(searchResults, elevationFactorState, groundElevation, viewportElevation, filters)
            overlay.setProps({ layers: recalcLayers() })
        }
    }))

    var mapDataElevation = mapData && mapData.elevation && mapData.elevation.data && mapData.elevation.data.elevation
    const removeCustomLayer = (map) => {
        if (map.getLayer('custom-geojson-layer')) {
          
         
            map.removeLayer('custom-geojson-layer'); // Also remove the source if it's unique to this layer
            if (map.getSource('custom-geojson-source')) {
                map.removeSource('custom-geojson-source');
            }
            
        }
        setIsLayerAdded(false);
    };
    const addCustomLayer = (map) => {
        // Ensure the map and your GeoJSON data are loaded
        if (!clusterData || !clusterData.features){
            return
    }
        const convertedData = {
            ...clusterData,
            features: clusterData.features.map(feature => {
                // Assuming `feature.data` contains the necessary fields like `SUM(avails.SizeMax)` and `idArray`
                const sizeInMillions = Math.round(feature.data['SUM(avails.SizeMax)'] / 1000000);
                const numberOfBuildings = feature.data.idArray.length;

                const textDisplay = `${sizeInMillions} Million SF\n${numberOfBuildings} Buildings\nAvailable`;
                const sizeFactor = sizeInMillions / 50
                const textSize = sizeInMillions / 6
                return {
                    ...feature,
                    properties: {
                        ...feature.data, // Move or copy the data from 'data' to 'properties'
                        textDisplay, // Add the 'textDisplay' property
                        sizeFactor,
                        textSize
                    }
                };
            })
        };

        
        if (map && clusterData) {
            const sourceId = 'custom-geojson-source';

            if (map.getSource(sourceId)) {
                // Source exists, so just update its data
                map.getSource(sourceId).setData(convertedData);
            } else {
                // Source doesn't exist, create it and add a layer
                map.addSource(sourceId, {
                    type: 'geojson',
                    data: convertedData
                });

                // Now add your layer using this source
                map.addLayer({
                    id: 'custom-geojson-layer',
                    type: 'symbol', // Change as needed (e.g., 'circle', 'line')
                    source: sourceId,
                    layout: {
                        // Define your layer layout (icon-image, text-field, etc.) here
                         'icon-image': 'turning-circle', // Example, use your icon
                        'text-field': '{textDisplay}', // Assuming features have a 'name' property
                        'icon-size': [
                            'get', 'sizeFactor' // Directly get the sizeFactor value from each feature's properties
                        ],
                        'text-size': [
                            'get', 'textSize' // Directly get the sizeFactor value from each feature's properties
                        ],
                   
                    },
                    'paint': {
                        'text-color': '#000000', // Text color
                        'text-halo-color': '#ffffff', // Halo (border) color
                        'text-halo-width': 2, // Halo width in pixels
                        // Optional: Add blur to the halo for a softer effect
                        'text-halo-blur': 1
                    }
                });
            }
        };
    }

    // Effect to handle layer visibility based on zoom level
    useEffect(() => {
        var map = mapRef.current && mapRef.current.getMap();
        if (!map) return;

        const handleZoom = () => {
            if (!map) {
                map = mapRef?.current && mapRef.current.getMap();
                if (!map) {
                    return
                }
            }
          
            const currentZoom = map.getZoom();
            
            if ((currentZoom < 6) ) {
              
                // Check if the layer already exists before adding
                // removeCustomLayer(map)
                    addCustomLayer(map);
                    setIsLayerAdded(true);
        
             
            }
            else if ((currentZoom >=6) && isLayerAdded) {
               removeCustomLayer(map)
            }
        };

        // Ensure the custom layer is added and initially set to the correct visibility
     
        handleZoom();

        // Listen to zoom events to toggle layer visibility
        map.on('zoom', handleZoom);

        // Clean up
        return () => {
            map.off('zoom', handleZoom);
        };
    }, [clusterData]); // Re-run effect if viewport changes

    function getCurrentViewState(mapRefIn) {
        // var theMapRef = mapRefIn || mapRef
        var viewState = null
        if (mapRef && mapRef.current && mapRef.current.getZoom) {
            viewState = {}

            viewState.zoom = mapRef.current.getZoom()
            viewState.pitch = mapRef.current.getPitch()
            viewState.bearing = mapRef.current.getBearing()
            viewState.center = mapRef.current.getCenter()
            viewState.maxZoom = mapRef.current.getMaxZoom()
            viewState.minZoom = mapRef.current.getMinZoom()
            viewState.latitude = viewState.center.lat
            viewState.longitude = viewState.center.lng



        }
        else if (mapRef && mapRef.current && mapRef.current.deck) {

            viewState = mapRef.current.deck.viewState["default-view"]
        }
        return viewState

    }
    var currentState = getCurrentViewState()





    const onLayerClick = (eventClick, info) => {


        // info.preventDefault();
        // info.stopImmediatePropagation();

        // eventClick.stopPropigation()
        
   
        onMarkerClick(eventClick.object, setSearchResults)

    }



    function onMapLoadMapBox(event) {
    
     
        onMapLoad()
     

    }


    async function onMapLoad() {
        fabRefreshRef.current.setShowWait(true)
        console.log("map loading!")

        if (geometry) {
            console.log("geometry call in map load")

            if (!geometry.location.elevation) {
                var elevationData = await axios.get(process.env.REACT_APP_DATABASE +
                    "api/getElevationLocal?latitude=" + (geometry.location.lat +
                        "&longitude=" + geometry.location.lng))


                if (elevationData && elevationData.data.elevation !== "not found") {

                    geometry.location.elevation = elevationData.data.elevation

                } else {
                    geometry.location.elevation = 5
                }
            }

            currentElevation = geometry.location.elevation
            searchedLocations =
                [
                    {
                        "type": "Feature",
                        "properties": {
                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [geometry.location.lng, geometry.location.lat, geometry.location.elevation - 10]
                        },
                    }]
            console.log("refresh started from onMapLoad")



            refreshMapProperties(searchResults, elevationFactorState, viewportElevation, viewportElevation, filters)

      
            mapRef.current.addControl(overlay)

            await mapReload()
            overlay.setProps({ layers: recalcLayers() })
            fabRefreshRef && fabRefreshRef.current && fabRefreshRef.current.setShowWait && fabRefreshRef.current.setShowWait(false)
            return true

        }
        else {
            console.log("mapload else getviewportElevation")
            if (viewportElevation.elevation === -1) {
                viewportElevation = await getViewportElevation(mapRef, searchResults && (searchResults.length > 0) && searchResults[0])
            }

            if (viewportElevation && viewportElevation.elevation) {
                currentElevation = viewportElevation.elevation
            }


            refreshMapProperties(searchResults, elevationFactorState, viewportElevation, viewportElevation, filters)

         
            
            await mapReload()
            getCities(myViewport, theViewStateOuter, elevationFactorState, viewportElevation)
            overlay.setProps({ layers: recalcLayers() })
            mapRef.current.addControl(overlay)
            fabRefreshRef.current.setShowWait(false)
            
            return true
        }

    }




    const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX


    // const TERRAIN_IMAGE = `https://api.mapbox.com/v4/mapbox.terrain-rgb/{z}/{x}/{y}.png?access_token=${MAPBOX_TOKEN}`;
    // const SURFACE_IMAGE = `https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}@2x.png?access_token=${MAPBOX_TOKEN}`;

    // const ELEVATION_DECODER = {
    //     rScaler: 6553.6,
    //     gScaler: 25.6,
    //     bScaler: 0.1,
    //     offset: -10000
    // };

    const [credits, setCredits] = useState('');


    function recursivePointEdit(coordinateArrays, zValue) {
        if ((coordinateArrays.length === 3 || coordinateArrays.length === 2) && !coordinateArrays[0].length) {
            if (coordinateArrays.length === 3) {
                coordinateArrays[2] = zValue
            }
            else {
                coordinateArrays.push(zValue)
            }
            return coordinateArrays
        }
        else {
            coordinateArrays.map((eachArray, index) => {
                coordinateArrays[index] = recursivePointEdit(eachArray, zValue)
            })
            return coordinateArrays
        }
    }






    if (mapData && mapData.features && (parseInt(mapDataElevation) > 0)) { // this is parcel data
        mapData.features.map((result, index) => {
            mapData.features[index].geometry.coordinates = recursivePointEdit(mapData.features[index].geometry.coordinates, mapDataElevation)

        })
    }



    // var sessionKey = await axios.post("https://tile.googleapis.com/tile/v1/createSession?maptype=satellite&key="+ process.env.REACT_APP_GoogleMapsAPIKey )






    function getMyViewport(newView) {

        var viewState = {}
        var bounds = null

        if (newView) {
            viewState = newView
        }
        else if (mapRef && mapRef.current && mapRef.current.getZoom) {



            bounds = mapRef.current.getBounds()

            var myViewport = bounds


            return myViewport



        }
        else if (mapRef && mapRef.current && mapRef.current.deck) {

            viewState = mapRef.current.deck.viewState["default-view"]
        }
        else { viewState = initialViewState }


        if (!viewState || !viewState.zoom) {
            // alert(" check getMyViewPort" + JSON.stringify(viewState))
            return null
        }
        const viewport = (viewState && viewState.zoom) ? new WebMercatorViewport({

            width: viewState.width,//mapRef.current.deck.width*.0625 ,
            height: viewState.height, //mapRef.current.deck.height*.0625 ,
            longitude: viewState.longitude,
            latitude: viewState.latitude,
            zoom: viewState.zoom,
            pitch: viewState.pitch,
            bearing: viewState.bearing
        }) : new WebMercatorViewport({});

        const nw = viewport.unproject([0, 0]);

        const se = viewport.unproject([viewport.width, viewport.height]);

        var myBounds = viewport.getBounds()

        var myViewport = {
            _ne: {
                lat: myBounds?.[3],//nw[1],
                // lat: nw[1],
                lng: myBounds?.[2]//se[0],
                // lng: se[0],

            },
            _sw: {
                lat: myBounds?.[1],//,se[1],
                // lat: se[1],
                lng: myBounds?.[0],//nw[0]
                //lng: nw[0]
            }

        }
        return myViewport
    }



    var theViewStateOuter = initialViewState
    if (mapRef && mapRef.current && mapRef.current.getZoom) {

        theViewStateOuter.zoom = mapRef.current.getZoom()
        theViewStateOuter.pitch = mapRef.current.getPitch()
        theViewStateOuter.bearing = mapRef.current.getBearing()
        theViewStateOuter.center = mapRef.current.getCenter()
        theViewStateOuter.maxZoom = mapRef.current.getMaxZoom()
        theViewStateOuter.minZoom = mapRef.current.getMinZoom()
        theViewStateOuter.latitude = theViewStateOuter.center.lat
        theViewStateOuter.longitude = theViewStateOuter.center.lng
        
        

    }
    else if (mapRef.current && mapRef.current.deck.viewState["default-view"]) {
        theViewStateOuter = mapRef.current.deck.viewState["default-view"]
    }

    function aboveOrBelow() {
        var aboveGroundSummarizeDistance = 10000

        var theViewState = getCurrentViewState()
        console.log(initialViewState)
        console.log("ttt")
        // var theViewPort = getMyViewport()
        var camElevation = getElevationFromZoom((theViewState && theViewState.zoom) || 15)
        var theVarElevation = currentElevation > 0 ? currentElevation : searchResults && searchResults.length > 0 ? searchResults[0].Elevation : 5
        if (!theVarElevation) {
            theVarElevation = 10
        }
        var distanceFromGround = camElevation - theVarElevation
        if (distanceFromGround > aboveGroundSummarizeDistance) {
            return "above"
        }
        else {
            return "below"
        }
    }

    // const nonReloadZoomCheck = async () => {
    //     var overlayLayers = overlay && overlay._props && overlay._props.layers ? overlay._props.layers : []

    //     var googleIndex = overlayLayers.findIndex((layer) => {
    //         return layer.id === "google-3d-tiles"
    //     })






    //     if (aboveOrBelow() === 'above') {
    //         // we will stick with 3d map if its on, it doesn't work to switch back and forth


    //     }
    //     else {
    //         if (googleIndex < 0) {
    //             var googleLayer = returnGoogletileLayer()
    //             var billBoardLayer = returnBillBoardLayer()

    //             overlay.setProps({ layers: [googleLayer, billBoardLayer] })
    //         }
    //     }

    //     console.log("hello")
    // }
    const mapReload = async () => {
        
        console.log("map reload called " + new Date().getSeconds())

  
        return new Promise(async function (resolve, reject) {
            fabRefreshRef.current.setShowWait(true)

            

                
         
            var newClusterData
            var theViewPort = getMyViewport()
            theViewState = getCurrentViewState()
            var camElevation = getElevationFromZoom((theViewState && theViewState.zoom) || 15)
            
            if (!viewportElevation || viewportElevation.elevation === -1) {
                viewportElevation = await getViewportElevation(mapRef, searchResults && (searchResults.length > 0) && searchResults[0])
            }
            var distanceFromGround = camElevation - viewportElevation.elevation

            if (!theViewState)
            {
                theViewState = {}
                }
            theViewState.height = distanceFromGround
            console.log(theViewState)

                
            if (aboveOrBelow() === "below" || (!reloadOnMove && aboveOrBelow ==="above")) {
                // we look for cities and streets regardless so long as we are below the threashold


                var citiesSet = await getCities(theViewPort, theViewState, elevationFactorState, viewportElevation)
                // this will set the cities state
                var streetsSet = await getStreets(theViewPort, theViewState, viewportElevation, elevationFactorState, viewportElevation)


            }

            var maxPropsForDetail = mobileAndTabletCheck() ? 20 : 50
            filters.maxPropsForDetail = maxPropsForDetail
            filters.contactId = contactId
            filters.contact_id = contactId

            if (typeof filters.availPropertyType === 'string' ) {
                filters.availPropertyType = filters.availPropertyType.split(",")
            } 
            if (typeof filters.buildingPropertyType === 'string') {
                filters.buildingPropertyType = filters.buildingPropertyType.split(",")
            } 

            if (reloadOnMove  && contactId) { // reload has to be set to get a refresh
                
                newClusterData = await postAxios(process.env.REACT_APP_DATABASE + "api/clusterAvails", {
                    filters: filters,
                    viewPort: theViewPort,
                    viewState: theViewState,
                    contactId: contactId
                }, newStateController)
                    .catch((error) => { console.log(error) })


     
                if ((newClusterData && (newClusterData.data.totalClusterBuildings || newClusterData.data.totalClusterBuildingsComps )) && (!(showParcels
                    // && aboveOrBelow() === 'below'
                )
                )) { // we are high up 
                    
            
                    clusterVarData = newClusterData.data
                    
                    setClusterData(clusterVarData) // we keep cluster and erase old results
                    
                    if (searchResults && (searchResults.length > 0)) { // clear search results
                        setSearchResults([])
                    }
                    searchResults = []
                    coords = []

               
                    // refreshMapProperties(searchResults, elevationFactorState, viewportElevation, viewportElevation, filters) // this gets the data for the layers
                    overlay.setProps({ layers: recalcLayers() })
                    fabRefreshRef?.current?.setShowWait(false)
                    console.log(theViewState)
                    resolve(true)
                    return true

                }



                else { // we have detail and the data will be searchResults in stead of - we are close in

                    
                    clusterVarData = []
                    
                    setClusterData(clusterVarData)
                    

              
                    if (!mobileAndTabletCheck()) {
                        if (theViewState.zoom >= 15 && showParcels) {  // this sets parcels  we get parcels not on a phone and close in

                            //test - console.log("getGeo")
                            getGeo( setMapData, theViewPort)


                        }
                    }

                   

                    var cubicBuildingResultsData = newClusterData

  

                    var myBoundsArcGIS = theViewPort._sw.lng
                        + "," + theViewPort._sw.lat
                        + "," + theViewPort._ne.lng
                        + "," + theViewPort._ne.lat



                    var osmEuropeBuildingsData = {


                        data: {
                            features: []

                        }


                    }
                    var osmNABuildingsData = {
                        data: {
                            features: []

                        }
                    }


                    
             
                    if ((filters?.allCheck && cubicBuildingResultsData?.data?.length < maxPropsForDetail)) { // if both are unchecked  there are less than 50 buildings  we check osm database
                    
                        var qsBuildings = "https://services6.arcgis.com/Do88DoK2xjTUCXd1/arcgis/rest/services/OSM_NA_Buildings/FeatureServer/0/query?where=Shape__Area>1000&outFields=Shape__Area,height,levels,addr_street,addr_housenumber,type,name,official_name,addr_city,addr_state,addr_postcode,addr_district,start_date,building_structure,power,description&geometry="

                            + myBoundsArcGIS + "&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelIntersects&outSR=4326&f=geojson"

                        osmNABuildingsData = await getAxios(qsBuildings, newNAMaster).catch((err) => {
                            console.log("osm request aborted")
                            //reject(err)
                            console.log(err)
                            //setShowWait(false)
                            return (false)
                        });





                        if (!osmNABuildingsData || (osmNABuildingsData.data.features.length === 0)) {
                            var qsBuildingsEurope = "https://services-eu1.arcgis.com/zci5bUiJ8olAal7N/arcgis/rest/services/OSM_Buildings_EU/FeatureServer/0/query?where=Shape__Area>1000&outFields=Shape__Area,height,levels,addr_street,addr_housenumber,type,name,official_name,addr_city,addr_state,addr_postcode,addr_district,start_date,building_structure,power,description&geometry="

                                + myBoundsArcGIS + "&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelIntersects&outSR=4326&f=geojson"
                            osmEuropeBuildingsData = await axios.get(qsBuildingsEurope).catch((err) => {
                                console.log("get request aborted")

                            });
                            if (!osmEuropeBuildings) {
                                qsBuildingsEurope = "https://services6.arcgis.com/Do88DoK2xjTUCXd1/arcgis/rest/services/OSM_CA_Buildings/FeatureServer/0/query?where=Shape__Area>1000&outFields=Shape__Area,height,levels,addr_street,addr_housenumber,type,name,official_name,addr_city,addr_state,addr_postcode,addr_district,start_date,building_structure,power,description&geometry="

                                    + myBoundsArcGIS + "&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelIntersects&outSR=4326&f=geojson"
                                osmEuropeBuildingsData = await axios.get(qsBuildingsEurope).catch((err) => {
                                    console.log("get request aborted")

                                });
                                
                            }
                        }
                    }

                    else {
                        osmNABuildingsData.data = []
                    }
                





                    var geojsonArea = require('@mapbox/geojson-area');
                    var minArea = 500
     
                    var cubicBuildingResults = cubicBuildingResultsData ? cubicBuildingResultsData.data : []  // we get cubic results data 
                    var allViewportResults = cubicBuildingResults
                    var osmNABuildings = osmNABuildingsData.data
                    // var osmNABuildings = pointResult && pointResult.data ? pointResult.data : osmNABuildingsData.data
                    var osmEuropeBuildings = osmEuropeBuildingsData ? osmEuropeBuildingsData.data : null
                    // var missingBuildingHeight = []
                    // var missingElevation = []

   


                    if (osmNABuildings && osmNABuildings.features && (osmNABuildings.features.length > 75)) {  // if thre are a lot of osm buildings take teh 75 tallest
                        osmNABuildings.features = osmNABuildings.features.sort(
                            (a, b) => {

                                //return b.properties.Shape__Area - a.properties.Shape__Area
                                if (!b.properties.height) {
                                    return -1
                                }
                                if (!a.properties.height) {
                                    return 1
                                }

                                return b.properties.height - a.properties.height

                            })

                        osmNABuildings.features = osmNABuildings.features.slice(0, 75)
                    }
                    //test - console.log(qsBuildingsEurope)



                    if (osmEuropeBuildings && osmEuropeBuildings.features && (osmEuropeBuildings.features.length > 75)) {  // if thre are a lot of osm buildings take teh 75 tallest
                        osmEuropeBuildings.features = osmEuropeBuildings.features.sort(
                            (a, b) => {

                                //return b.properties.Shape__Area - a.properties.Shape__Area
                                if (!b.properties.height) {
                                    return -1
                                }
                                if (!a.properties.height) {
                                    return 1
                                }

                                return b.properties.height - a.properties.height

                            })

                        osmEuropeBuildings.features = osmEuropeBuildings.features.slice(0, 75)
                    }

                    

                    if (!osmNABuildings && osmEuropeBuildings) {
                        osmNABuildings = osmEuropeBuildings
                    }


                    if (osmNABuildings && osmNABuildings.features  && osmNABuildings.features.length > 0) { // we have North America Features to check

                       
                        var cubicOverlappers = await cubicOverlap(allViewportResults, osmNABuildings)
                        
                        var newSearchResults = await overLap(osmNABuildings, allViewportResults, mapRef)
                        // .then((newSearchResults) => {

                        newSearchResults = newSearchResults.concat(cubicBuildingResults)
                        cubicOverlappers.buildingsToDelete.map((deadBuilding) => {
                            newSearchResults = newSearchResults.filter((eachResult) => {
                                return (newSearchResults.id != deadBuilding.id)
                            })
                        })



                        //test - console.log("setting nA search results")
                        // console.log("filtering at 1036")
                        newSearchResults = newSearchResults.sort(sortByProperty("Latitude"))  // sort the rest north to South.  This is only for OSMs

                        newSearchResults = newSearchResults.filter((result, index) => {

                            
                            if (result.avails && result.avails.length > 0) {
                                return true
                            }
                            if (!result.Footprint && !result.Size && !result.PropertyName && !result.CampusName) {
                                return false
                            }
                            else {
                                if (newSearchResults.length > 50) {
                                    if ((result.FootPrint && result.Footprint > 10000) ||
                                        (result.Size && result.Size > 15000)) {
                                        return true
                                        }
                                } 
                                else {
                                    return true
                                }
                            }
                            
                        })




                        setSearchResults(newSearchResults);

                        searchResults = newSearchResults



                        // refreshMapProperties(newSearchResults, setCoords, setCoordsCircles, setCoordsLines, elevationFactorState, groundElevation, viewportElevation)
                        if (mobileAndTabletCheck() && theViewState) {
                            if (theViewState.zoom >= 15 && showParcels) {

                                //test - console.log("getGeo")
                                getGeo( setMapData, theViewPort)


                            }
                        }
                        var theViewState = getCurrentViewState()



                        var viewStateCookie = { ViewState: theViewState, searchResults: newSearchResults, filters: filters, URL: window.location.href }

                        
                        var postResult = axios.post(
                            process.env.REACT_APP_DATABASE +
                            "api/createViewStates", {
                            userId: contactId,
                            ViewState: viewStateCookie,


                        }
                        ).catch((error) => {
                            console.log(error)
                        })



                        refreshMapProperties(searchResults, elevationFactorState, viewportElevation, viewportElevation, filters) // this gets the data for the layers
                        overlay.setProps({ layers: recalcLayers() })
                        fabRefreshRef.current.setShowWait(false)
                        console.log(theViewState)
                        resolve(true)
                        return true
                    }
                    else if (osmEuropeBuildings && osmEuropeBuildings.features && osmEuropeBuildings.features.length > 0) {
                        // europe features to check
                        overLap(osmEuropeBuildings, allViewportResults, mapRef).then((newSearchResults) => {
                            newSearchResults = newSearchResults.concat(cubicBuildingResults).sort(sortByProperty("Latitude"))
                            // refs = newSearchResults.reduce((acc, result) => {
                            //     acc[result.id] = React.createRef();
                            //     return acc;
                            // }, {})
                            //test - console.log("setting Europe search results")
                            console.log("filter at 1064")
                            alert("removed these filters temp")
                            //setFilters(filters)
                            // newSearchResults = filter(newSearchResults, filters)
                            setSearchResults(newSearchResults);
                            // refreshMapProperties(newSearchResults, setCoords, setCoordsCircles, setCoordsLines, elevationFactorState, groundElevation, viewportElevation)
                            if (mobileAndTabletCheck()) {
                                if (theViewState && (theViewState.zoom >= 15) && showParcels) {

                                    //test - console.log("getGeo")
                                    getGeo( setMapData, theViewPort)


                                }
                            }
                        })
                    }
                    else { // most common, we are not looking for empty buildings, just the ones available

                              

                               
                                   
                        setSearchResults(cubicBuildingResults && (cubicBuildingResults.length >0) ? cubicBuildingResults.sort(sortByProperty("Latitude")): [])
                        searchResults = cubicBuildingResults && cubicBuildingResults.length > 0 ? cubicBuildingResults : []

                     
                                   
                        var theViewState = getCurrentViewState()



                        var viewStateCookie = { ViewState: theViewState, searchResults: searchResults, filters: filters, URL: window.location.href }
                        

                        var postResult = axios.post(
                            process.env.REACT_APP_DATABASE +
                            "api/createViewStates", {
                            userId: contactId,
                            ViewState: viewStateCookie,


                        }
                        ).catch((error) => {
                            console.log(error)
                        })


                             
                        refreshMapProperties(searchResults, elevationFactorState, viewportElevation, viewportElevation, filters) // this gets the data for the layers
                        overlay.setProps({ layers: recalcLayers() })
                        fabRefreshRef && fabRefreshRef.current && fabRefreshRef.current.setShowWait &&  fabRefreshRef.current.setShowWait(false)
                        console.log(theViewState)
                        resolve(true)
                        return true
                    }

                    alert("not supposed to get here")
              
                        







                }
            }
            else {
                console.log(theViewState)
                resolve(true)
                return 1
            }
        })
    }



    // const clickHandle = (event, info) => {
    //     // info.preventDefault()
    //     // info.stopImmediatePropagation();
    //     // setPopupInfo(null)

    // }

    // const showAlertMessage = () => {

    // }

    if (mapData && !showParcels) {
        //test - console.log("nulling map data")
        setMapData(null)
    }

    // useEffect(() => {
    //     recalcLayers()

    //     
    // }, [clusterData]
    // )





    useEffect(() => {




        if (initialViewState.transitionDuration > 0) {

            if (mapRef && mapRef.current && mapRef.current.getCenter) {

                // alert("transition longitude: " + initialViewState.longitude + " latitude: " + initialViewState.latitude)
                var rawCookie = axios.get(
                    process.env.REACT_APP_DATABASE +
                    "api/getViewStates?userId=" +
                    contactId
                ).then(async (rawCookie) => {
                    if (!rawCookie || !rawCookie.data) {
                        viewStateCookie = []
                    }
                    {
                        viewStateCookie = rawCookie.data
                    }



                    if (
                        viewStateCookie &&
                        // (viewStateCookie.viewStates.length > 0) &&

                        window.location.href === viewStateCookie.viewStates[0].ViewState.URL) {
                        
                        myViewport = viewStateCookie.viewStates[0].ViewState.ViewState

                        if (myViewport && mapRef && mapRef.current) {


                            myViewport.transitionDuration = 0
                            // var elevation = await getViewportElevation(mapRef)
                            // if (elevation.elevation > 1000) {
                            //     myViewport.maxZoom = 13.5
                            // }
                            // else if (elevation.elevation > 100) {
                            //     myViewport.maxZoom = 14
                            // }
                            // else {
                            //     myViewport.maxZoom =19.5
                            // }
                         
                            setInitialViewState(myViewport)


                            mapRef.current.flyTo({ center: [myViewport.longitude, myViewport.latitude], zoom: myViewport.zoom, duration: 2000 });
                            refreshMapProperties(searchResults, elevationFactorState, viewportElevation, viewportElevation, filters)
                            overlay.setProps({ layers: recalcLayers() })
                        }
                    }
                }).catch((error) => {
                    console.log(error)
                })
                //test - console.log("we have view states")



            }
        }
    }, [initialViewState])



    // create ambient light source
    const ambientLight = new AmbientLight({
        color: [255, 255, 255],
        intensity: 1.0
    });
    // create point light source
    const pointLight = new PointLight({
        color: [255, 255, 255],
        intensity: 1.0,
        // use coordinate system as the same as view state
        position: [-125, 50.5, 5000]
    });
    // create directional light source
    const directionalLight = new DirectionalLight({
        color: [255, 255, 255],
        intensity: 3.0,
        direction: [3, -20, 15]
    });
    // create lighting effect with light sources
    // const lightingEffect = new LightingEffect({ ambientLight, pointLight, directionalLight });

    //test - console.log("map deck return")
    //test - console.log(initialViewState)

    if (popupInfo && !drawerOpen) {

        setDrawerOpen(true)
    }




    function returnGoogletileLayer() {
        return (new Tile3DLayer({
            id: 'google-3d-tiles',
            data: TILESET_URL,
            // visible: visibleState,
            onTilesetLoad: tileset3d => {
                tileset3d.options.onTraversalComplete = selectedTiles => {
                    const uniqueCredits = new Set();

                    selectedTiles.forEach((tile, index) => {
                        const { copyright } = tile.content.gltf.asset;
                        copyright.split(';').forEach(uniqueCredits.add, uniqueCredits);

                        // //test - console.log("latlongarray " + index)
                        // var latlngElevationArray = projector.unproject(tile.boundingVolume.center[0], tile.boundingVolume.center[1], tile.boundingVolume.center[2])
                        // if (latlngElevationArray[2] > -30) {

                        //     //test - console.log("entering recursive")
                        //     recursiveAdd(tile)
                        // }
                        // else {
                        //     // //test - console.log("deleteing")
                        //     // //test - console.log(theTile)
                        //     // //test - console.log(selectedTiles[index])
                        //     selectedTiles.splice(index, 1)
                        //     // //test - console.log(selectedTiles[index])
                        // }
                    });
                    setCredits([...uniqueCredits].join('; '));
                    // //test - console.log(selectedTiles)





                    // setMapTiles(latlngElevation)
                    // setSearchResults(searchResults)

                    return selectedTiles;
                };

                // //test - console.log("hello world")
            },
            
            // loadOptions: {
            //     fetch: { headers: { 'X-GOOG-API-KEY': GOOGLE_MAPS_API_KEY } }
            // },
            pointSize: 10,
            operation: 'terrain+draw'
        }))
    }


    const ICON_MAPPING = {
        selected: {
            x: 1250,
            y: 1220,
            width: 388,//128
            height: 337,//128
            anchorY: 182,
            mask: false
        },
        availablesummary: {
            x: 1649,
            y: 137,
            width: 388,//128
            height: 337,//128
            anchorY: 182,
            mask: false
        },
        available: {
            x: 1649,
            y: 883,
            width: 388,//128
            height: 337,//128
            anchorY: 182,
            mask: false
        },
        leased: {
            x: 2061,
            y: 883,
            width: 388,//128
            height: 337,//128
            anchorY: 182,
            mask: false
        },
        other: {
            x: 1250,
            y: 883,
            width: 388,//128
            height: 337,//128
            anchorY: 182,
            mask: false
        }
    };


    const getTextAvails = (d) => {


        var propName = ""
        var campusName = ""
        var priceShow = ""
        var infoToShow = ""

        var types = []
        
        if (d.avails.length > 0) {
          types =   d.avails.map((avail) => {
            return avail.PropertyType
        })


        if (types.length >1){
        const uniqueSet = new Set(types);

        // Convert the Set back to an array
            types = Array.from(uniqueSet);
        }


        }
        
        if (types.length > 0) {
            types = types.join(", ")
            if (types.length >10){
                types = types.slice(0, 8) + "..." 
            }
           
            types = types + " \n "
            
            if ((types.trim()[0] === ",") && types.length > 2){
                types = types.substring(2)
            }
        }
        
        
        
        if ((d.properties.summary.comps === 0) && (d.properties.summary.avails === 0)) {
   
            var returnvalue = ""
            if (d.properties.PropertyName && (d.properties.PropertyName.length > 0)) {
                if (d.properties.PropertyName.length > 7) { propName = d.properties.PropertyName }
                else { propName = d.properties.PropertyName }
                returnvalue = propName
            }
            else if (d.properties.CampusName && (d.properties.CampusName.length > 0)) {
                if (d.properties.CampusName.length > 7) { campusName = d.properties.CampusName }
                else {
                    returnvalue = d.properties.CampusName
                }
                // return campusName
            }
            if (d.properties.Footprint && d.properties.Footprint > 0) {
                return returnvalue + ' ' + (Math.round(d.properties.Footprint / 1000) + " K \n Floor")
            }
            else if (d.properties.Size && d.properties.Size > 0) {
                return returnvalue + ' ' + (Math.round(d.properties.Size / 1000) + " K \n Bldg")
            }
            else {
                return d.properties.StreetName
            }

        }
        else {


            if ((d.properties.summary.rank === 'big')) {

                if (d.properties.PropertyName) {
                    if (d.properties.PropertyName.length > 7) { propName = d.properties.PropertyName.slice(0, 7) }
                    else {
                        propName = d.properties.PropertyName
                    }

                }

                if (d.properties.CampusName) {
                    if (d.properties.CampusName.length > 7) { campusName = d.properties.CampusName.slice(0, 7) }
                    else {
                        campusName = d.properties.CampusName
                    }

                }

            }
            else if ((d.properties.summary.rank === 'medium')) {

                if (d.properties.PropertyName) {
                    if (d.properties.PropertyName.length > 10) { propName = d.properties.PropertyName.slice(0, 7) }

                }

                else {
                    propName = d.properties.PropertyName
                }
                if (d.properties.CampusName) {
                    if (d.properties.CampusName.length > 10) { campusName = d.properties.CampusName.slice(0, 7) }
                    else {
                        campusName = d.properties.CampusName
                    }
                }
            }
            // else {
            if (
                d.properties.summary.onMarketAvailSF + d.properties.summary.offMarketAvailSF > 0) {
                priceShow = getAvailPrice(d)
                if (priceShow.length > 0) {
                    priceShow = priceShow + (d.properties.summary.rank != 'big' ? "" : " SF/Mo")
                }
            }
            else if (filters.compsCheck && ((d.properties.summary.onMarketCompsSF + d.properties.summary.offMarketCompsSF) > 0)) {

                priceShow = getCompsPrice(d)

                if (priceShow.length > 0) {
                    priceShow = priceShow + " SF/Mo"

                }
            }
            else if (filters.allCheck && !(
                d.properties.summary.onMarketCompsSF + d.properties.summary.offMarketCompsSF > 0 &&
                d.properties.summary.onMarketAvailSF + d.properties.summary.offMarketAvailSF > 0)) {
                if (d.properties.summary.onMarketCompsSF +
                    d.properties.summary.offMarketCompsSF +
                    d.properties.summary.onMarketAvailSF +
                    d.properties.summary.offMarketAvailSF ===
                    0) { priceShow = "" }
            }

            // }

            if ((
                // d.properties.summary.rank === 'medium' ||
                d.properties.summary.rank === 'big')
                && campusName && (campusName.length > 0)
                // (distanceFromGround < 5000) && d.properties.PropertyName && d.properties.PropertyName !== "null"
            ) {

                infoToShow = campusName
            }
            else if ((
                // d.properties.summary.rank === 'medium' ||
                d.properties.summary.rank === 'big')
                && propName && (propName.length > 0)
                // (distanceFromGround < 5000) && d.properties.CampusName && d.properties.CampusName !== "null"
            ) {
                infoToShow = propName
                // + "   " + infoToShow
            }

            // if (!showPrice && ((d.properties.summary.onMarketCompsSF + d.properties.summary.offMarketCompsSF) === 0)) {
            var slice = 40
            if ((d.properties.summary.rank === 'small')) {
                slice = 30
            }
            if (filters.availablesCheck &&
                d.properties.summary.onMarketAvailSF + d.properties.summary.offMarketAvailSF > 0) {

                var SF = getAvailSF(d.properties.summary.onMarketAvailSF, d.properties.summary.offMarketAvailSF, d)
                var returnValue = priceShow +
                    (infoToShow.length > 0 ? " \n " + infoToShow + " \n " : " \n ")
                    + SF
                
                if (returnValue.replaceAll(" ", "").length === 0)
                    {
                       return types
                    }
                return (
                    types + returnValue
                )
            }
            else if (filters.compsCheck && ((d.properties.summary.onMarketCompsSF + d.properties.summary.offMarketCompsSF) > 0)) {

                var returnValue = (priceShow
                    + (infoToShow.length > 0 ? " \n " + infoToShow + " \n " : " \n ") +
                    getCompsSF(d.properties.summary.onMarketCompsSF, d.properties.summary.offMarketCompsSF, d))
                if (returnValue.replaceAll(" ", "").length === 0) {
                    return types 
                }
                return (
                    types +  returnValue
                )
                
            }


            else if (d.properties.summary.onMarketCompsSF +
                d.properties.summary.offMarketCompsSF +
                d.properties.summary.onMarketAvailSF +
                d.properties.summary.offMarketAvailSF ===
                0) {

                if (d.properties.Size > 0) {
                   var returnValue = (priceShow + (infoToShow.length > 0 ? " \n " + infoToShow + " \n " : " \n ") + getBuildingSF(d.properties)).replaceAll(" ", "").slice(0, 20)
                    if (returnValue.replaceAll(" ", "").length === 0) {
                        return types //+ d.properties.StreetName
                    }
                    return (
                        types + returnValue
                    )
                }
                else {

                    var returnValue = (priceShow + (infoToShow.length > 0 ? " \n " + infoToShow + " \n " : " \n ") + Math.round(d.properties.Footprint / 1000).toString() + "K SF \n Footprint").replaceAll(" ", "").slice(0, 20)
                    if (returnValue.replaceAll(" ", "").length === 0) {
                        return types //+d.properties.StreetName
                    }
                    return (
                        types + returnValue
                    )
                }
            }
            else {
                if (d.properties.Size > 0) {

                    var returnValue = (priceShow + (infoToShow.length > 0 ? " \n " + infoToShow + " \n " : " \n ") + getBuildingSF(d.properties)).replaceAll(" ", "").slice(0, 20)
                    if (returnValue.replaceAll(" ", "").length === 0) {
                        return types +d.properties.StreetName
                    }
                    return (
                        types + returnValue
                    )
}
                else {


                    var returnValue = (priceShow + (infoToShow.length > 0 ? " \n " + infoToShow + " \n " : " \n ") + (Math.round((d.properties.summary.offMarketCompsSF +
                        d.properties.summary.onMarketAvailSF +
                        d.properties.summary.offMarketAvailSF) / 1000)).toString() + "K SF").replaceAll(" ", "").slice(0, 20)
                    if (returnValue.replaceAll(" ", "").length === 0) {
                        return types //+d.properties.StreetName
                    }
                    return (
                        types + returnValue
                    )
                    }
            }



        }
        // }




    }



    function returnBillBoardLayer() {
        var camElevation = getElevationFromZoom((currentState && currentState.zoom) || 15)
        var theVarElevation = currentElevation > 0 ? currentElevation : searchResults && searchResults.length > 0 ? searchResults[0].Elevation : 5
        if (!theVarElevation) {
            theVarElevation = 10
        }
        var distanceFromGround = camElevation - theVarElevation
        // alert(JSON.stringify(selectedLocation))
        return (new GeoJsonLayer({
            id: 'geojson-billboards-avails',
            data: coords,
            extensions: mobileAndTabletCheck() ? [] : [ new TerrainExtension()],
            terrainDrawMode: mobileAndTabletCheck() ? null : 'offset',
            // stroked: true,
            pointType: 'icon+text',

            iconMapping:ICON_MAPPING,
            iconAtlas: 'https://images.cubicsearch.com/icons/bigmap.png',//'https://images.cubicsearch.com/markers/icon-atlas.png',
            getIcon: d => {
                
                var cookieLocation
                if (!cookieLocation) {
                    try {
                        
                        var rawCookie = getCookie("selectedLocation")
                        cookieLocation = parseInt(rawCookie)
                    } catch (error) {
                        console.log(error)
                        cookieLocation = getSelectedLocation()?.id
                    }
                }
             
                if (d?.id === cookieLocation) {
                    return 'selected'
                }
                if (filters.availablesCheck){
                if (d?.properties?.summary?.onMarketAvailSF > 0 || d?.properties?.summary?.offMarketAvailSF > 0) { // on market in some way still might have comps off market
                
             
                 if (d.properties.summary.offMarketAvailSF > 0) {
                    // return "linear-gradient(90deg, red 50%, green 25%)" // off market comps and availables
                    return 'available'
                }
                else if (d.properties.summary.onMarketAvailSF > 0) {
                    // return "linear - gradient(90deg, red 50 %, green 50 %)" // off market availables only
                    return 'available'
                }
                else {
                    return 'available'
                }}
            }
            // else { // nothing available
                if (d.properties.summary.offMarketCompsSF > 0 || d.properties.summary.onMarketCompsSF > 0) {
                    if (filters.compsCheck){
                        return 'leased'
                    }
                    else {return 'other'}
                }
                else {

                    if (d.properties.summary.avails > 0) {
                        if (filters.availablesCheck)
                        { return 'available' }
                        else {
                            return 'other'
                        }
                    } else if (d.properties.summary.comps > 0) {
                        if (filters.compsCheck) {
                            return 'leased'
                        }
                        else { return 'other' }
                    }
                    else {
                        return 'other'
                    }
                }

            // }
            },
                // },
            iconSizeUnits: 'pixels',
            getIconSize: d => {

                // if ((d.properties.summary.comps === 0) && (d.properties.summary.avails === 0)){
                //     return 60
                // }
                

                if (d.properties.summary.rank === 'small') {
                    return 110
                } else if (d.properties.summary.rank === 'medium') {
                    return 130
                }
                else if (d.properties.summary.rank === 'big') {
                    return 170
                }
                else {
                    return 120
                }
            },

            iconSizeScale: 0.5,
            iconBillboard: true,
            
            pickable: true,
            onClick: (info) => {

    
                
                var targetId
                try {
                    var rawCookie = getCookie("selectedLocation")
               
                    targetId = parseInt(rawCookie)
                }
                catch (error){
                    console.log(error)
                }
                var myNumber = parseInt(info?.object?.id)
             
               
                if (myNumber !== targetId) {
                    createCookie("selectedLocation", info?.object?.id)
                    setSelectedLocation(info?.object)
                }
                else {
                    createCookie("selectedLocation", null)
                    setSelectedLocation(null)
                }
               
                

                if (!mobileAndTabletCheck()) {
                   
                    if (myNumber !== targetId) {
                        setSelectedLocation(info.object)
                    }
                    
                
                    scrollToResult(info)
                    overlay.setProps({ layers: recalcLayers() })
                }
                else {
                    onLayerClick(info)
                }



            },
            // onHover: (info) => {

            //     if (info.object) {
            //         setPopupInfo(info)
            //         // onLayerClick(info)
            //     }
            // },

            // autoHighlight: true,
            // highlightColor:[0,0,0, 50],

          

            textFontFamily: "Gill Sans, sans-serif",
            getTextColor:  [0, 0, 0],
            textMaxWidth: 10,
            getText: (d) => { return getTextAvails(d).replaceAll(/\n\s*\n/g, ' \n ') },

            getTextSize: d => {
                var text = getTextAvails(d)

              
                if (d.properties.summary.rank === 'small') {
                    return 8
                } else if (d.properties.summary.rank === 'medium') {
                    if (text.length > 15) {
                        return 8
                    }
                    else if (text.length > 20) { return 8 }
                    else {
                        return 10
                    }
                }
                else if (d.properties.summary.rank === 'big') {
                    if (text.length > 15) {
                        return 8
                    }
                    else if (text.length > 20) { return 8 }
                    else {
                        return 10
                    }
                }
                else {
                    if (text.length > 15) {
                        return 8
                    }
                    else if (text.length > 20) { return 8 }
                    else {
                        return 10
                    }
                }
                
                
               
            }

            ,


            getTextPixelOffset: d => {

                    if ((d.properties.summary.rank === 'small')) {
                        return [0, -10]
                    } else if (d.properties.summary.rank === 'medium') {
                        return [0, -15]
                    }
                    else if (d.properties.summary.rank === 'big') {
                        return [0, -15]
                    }
                // }
            },
            textSizeUnits: 'pixels',
            textSizeScale: 1,


        }))
    }

    async function refreshMapPropertiesInMain() {
        if (!viewportElevation || viewportElevation.elevation === -1) {
            var groundElevation = await getViewportElevation(mapRef, theViewStateOuter)
        }
        viewportElevation = groundElevation
        console.log("calling refresh from 2519: " + new Date().getSeconds())
        refreshMapProperties(searchResults, elevationFactorState, groundElevation, viewportElevation, filters)
    }

    const recalcLayers = () => {
        // if you are here for the first time, you might have a geometry that had an empty set of properties or an incomplete viewstate
        // just make sure you get to a map layer.
        fabRefreshRef && fabRefreshRef.current && fabRefreshRef.current.setShowWait && fabRefreshRef.current.setShowWait(true)
        console.log("setting layers" + new Date().getSeconds())


        

        var camElevation = getElevationFromZoom((currentState && currentState.zoom) || 15)
        var theVarElevation = currentElevation > 0 ? currentElevation : searchResults && searchResults.length > 0 ? searchResults[0].Elevation : 5
        if (!theVarElevation) {
            theVarElevation = 10
        }
        var distanceFromGround = camElevation - theVarElevation


      

        console.log("bb")
        
  
            
        const buildingBillboards = returnBillBoardLayer()

        console.log("cc")



        if (searchResults) {
            console.log("Numvber of search reulsts have " + (searchResults ? searchResults.length : 0))
        }
        if (searchResults) {
            console.log("Numvber of coords are " + (coords ? coords.length : 0))
        }
        if (clusterVarData && clusterVarData.features) {
            console.log("Numvber of clusterfeatures are " + (clusterVarData.features.length))
        }

        const getTextFunctionState = (d) => {
            var size = sizeThresholds(d, camElevation, distanceFromGround)

      
                var types = ["LifeScience", "Office", "Industrial", "Flex", "Retail", "Other", "Land", "Medical", "Overlap"]
                var arrayOfSpace = []

                for (var type of types) {
                    if (d.data.ConsolidatedSF[type].count > 0) {
                        arrayOfSpace.push({type: type, pair: d.data.ConsolidatedSF[type]})
                    }
                }
            var extraInfo = ""
                
            if (arrayOfSpace.length >= 3) {
                extraInfo = arrayOfSpace.length + " Uses \n "  //+ Math.round(row.pair.SF / 1000) + "K SF \n "
            }
            else {

                if (arrayOfSpace.length === 1) {
                    extraInfo = arrayOfSpace[0].type + " \n "
                }
                else if (arrayOfSpace.length === 2) {
                    extraInfo = arrayOfSpace[0].type + "," + arrayOfSpace[1].type + " \n "
                }
            }
                
            if (d.data["SUM(avails.LeasedSpace)"]) {
               

                    if ((
                        d.data["SUM(avails.LeasedSpace)"] / 100000
                    ) < 10) {
                        extraInfo = extraInfo + (Math.round(
                            d.data["SUM(avails.LeasedSpace)"] / 1000) + "K SF \n ")
                        // + " \n (" + d.data["count(buildings.Latitude)"] + ")")

                    }
                    else {
                        
                        extraInfo = extraInfo +
                            (Math.round(d.data["SUM(avails.LeasedSpace)"] / 1000000
                            )
                            ).toString()
                            + " Million SF \n "
                        // ) + " \n (" + d.data["count(buildings.Latitude)"] + ")"
                    }
            }
            else if (d.data["SUM(avails.SizeMax)"]) {
                
                
                if ((
                    d.data["SUM(avails.SizeMax)"] / 100000
                ) < 10) {
                    extraInfo = extraInfo + (Math.round(
                        d.data["SUM(avails.SizeMax)"] / 1000) + "K SF \n ")
                    // + " \n (" + d.data["count(buildings.Latitude)"] + ")")

                }
                else {
                    
                    extraInfo = extraInfo +
                        (Math.round(d.data["SUM(avails.SizeMax)"] / 1000000
                        )
                        ).toString()
                        + " Million SF \n "
                    // ) + " \n (" + d.data["count(buildings.Latitude)"] + ")"
                }
            }
            
                return (extraInfo + " " + d.data.idArray.length + "  Bldg(s)")
            // }
        }

 
        const stateSummaryLayer = new GeoJsonLayer({ // summary Circles
            id: 'geojson-stateSummaryLayer',
            data: clusterVarData && clusterVarData.features ? clusterVarData.features : [],
            extensions: mobileAndTabletCheck() ? [] : [new TerrainExtension()], //: [],
            terrainDrawMode: 'offset',
            stroked: true,
            pickable: true,
            autoHighlight: true,
            highlightColor: [0, 0, 0, 50],
            extruded: true,

            
            pointType: 'icon+text',
          
            billboard: true,
            iconMapping: ICON_MAPPING,
            iconAtlas: 'https://images.cubicsearch.com/icons/bigmap.png',
            getIcon: d => {
                
                if (d.data["SUM(avails.SizeMax)"]){
                    return 'available'
                }
                else if (d.data["SUM(avails.LeasedSpace)"]) {
                    return 'leased'
                }
            },
            iconSizeUnits: 'pixels'
            ,
     

            iconBillboard: true,
            iconSizeMinPixels: 100,
            iconSizeMaxPixels: 200,
            pickable: true,
            onClick: async (info, event) => {



               

                if (info?.object?.data["count(buildings.Latitude)"] === 1) {
                    // change bounds to zoom out a bit


                    info.object.data["min(buildings.Longitude)"] = info.object.data["min(buildings.Longitude)"] - .0025 / 2
                    info.object.data["min(buildings.Latitude)"] = info.object.data["min(buildings.Latitude)"] - .0025 / 2

                    info.object.data["max(buildings.Longitude)"] = info.object.data["max(buildings.Longitude)"] + .0025 / 2
                    info.object.data["max(buildings.Latitude)"] = info.object.data["max(buildings.Latitude)"] + .0025 / 2

                }
                else if ((info.object?.data["max(buildings.Longitude)"] - info.object.data["min(buildings.Longitude)"] > .5) ||
                    (info.object.data["max(buildings.Latitude)"] - info.object.data["min(buildings.Latitude)"] > .25)) {
                    var longSpread = info.object.data["max(buildings.Longitude)"] - info.object.data["min(buildings.Longitude)"]
                    var latSpread = info.object.data["max(buildings.Latitude)"] - info.object.data["min(buildings.Latitude)"]
                    // change bounds to zoom out a bit
                    
                    info.object.data["min(buildings.Longitude)"] = info.coordinate[0] - longSpread / 8
                    info.object.data["min(buildings.Latitude)"] = info.coordinate[1] - latSpread / 8

                    info.object.data["max(buildings.Longitude)"] = info.coordinate[0] + longSpread / 8
                    info.object.data["max(buildings.Latitude)"] = info.coordinate[1] + latSpread / 8
                }
             

              
                var bounds =   [
                        [info.object?.data["min(buildings.Longitude)"], info.object?.data["max(buildings.Latitude)"]],
                        [info.object?.data["max(buildings.Longitude)"], info.object?.data["min(buildings.Latitude)"]]
                    ]
                   
          
 
                mapRef.current.fitBounds(
                    bounds, // New York City, replace with your desired center
                    {
                        padding: 20,
                        bearing: 0,
                        pitch: 45,
                        speed: 0.8, // Controls the speed of the animation (default is 1.2)
                        curve: 1, // Controls the smoothness of the animation (default is 1)
                    });
           
                


            },
            // onHover: (info, event) => { alert(info) },

            getText: d => { return getTextFunctionState(d)
                
            },
            getTextColor: [0, 0, 0],
            // getIconColor: [255, 0, 0, 255],
            getTextAlignmentBaseline: 'bottom',
            getTextPixelOffset: [0,0],

            textSizeUnits: 'pixels',
            textSizeScale: 1,
            textFontFamily: 'sans-serif',
            getTextSize: d => {
                
                var text = getTextFunctionState(d)
                if (text.length > 10) {
                    return 10
                }
                else {
                    return 14
                }
            },
            

        })


        console.log("dd")
        const buildingCirclesLayer = new GeoJsonLayer({ // availables and building Circles
            id: 'geojson-buildingCircles',
            data: mobileAndTabletCheck() ? [] : coordsCircles,
            extensions: mobileAndTabletCheck() ? [] : [ new TerrainExtension()], //: [],
            terrainDrawMode: 'offset',
            stroked: true,
            pickable: true,
            pointType: 'circle',
            getFillColor: [255, 20, 0],
            getLineColor: [255, 20, 0],
            getPointRadius: d => {
                if ((d.properties.summary.comps === 0) && (d.properties.summary.avails === 0)){
                    return 0}
                if (popupInfo && popupInfo.object.id === d.id) {
                    return 12
                }
                else {
                    return 6

                }
            },
            getLineColor: [255, 20, 0],

            highlightColor: [255, 0, 0],



        })

        console.log("ee")
        const buildingPostLayer = new ColumnLayer({
            id: 'properties-lines',
            data: coordsLines,
            diskResolution: 9,
            radius: mobileAndTabletCheck() ? 1: 0.5,
            extensions: mobileAndTabletCheck() ? [] : [ new TerrainExtension()],
            terrainDrawMode: mobileAndTabletCheck() ? null : 'offset',
            extruded: true,
            pickable: false,
            elevationScale: 1,
            getPosition: d => d.geometry.coordinates,
            getFillColor: d => [255, 255, 255],
            getLineColor: [0, 0, 0],
            getElevation: d => mobileAndTabletCheck() ? d.mobileElevationHeight -2 : 40 * elevationFactorState - 2//d.elevationHeight
        })

        console.log("fff")
        const parcelLayer = new GeoJsonLayer({ // this is the parcels
            id: 'geojson-layer',
            data: mapData,
            extensions: [ new TerrainExtension()],
            stroked: true,
            extruded: true,
            wireframe: true,
            getElevation: 1,
            //     (d) => {
            //         if (mapDataElevation){
            //     

            //         //test - console.log(mapDataElevation)
            //             return mapDataElevation
            //         }
            //         else {
            //             return 0
            //         }
            //     // return d.elevation.data.elevation
            // },
            filled: true,
            pickable: true,
            getFillColor: [100, 100, 180, 50],

            getLineColor: [255, 20, 0, 200],
            getLineWidth: 4,
            autoHighlight: true,
            highlightColor: [0, 20, 255, 100],
            terrainDrawMode: 'offset',

            onClick: (info, event) => {
                // setShowWait(true)


                var hoverInfo = {
                    parcelProperties: info.object.properties,
                    center: center(info.object.geometry.type ===
                        "MultiPolygon" ? info.object.geometry.coordinates[0] : info.object.geometry.coordinates),
                    latitude: info.coordinate[1],
                    longitude: info.coordinate[0],
                    geometry: info.object.geometry
                }
                // setParcelInfo(hoverInfo)
                // alert("Click called!!!")
                // setShowParcel(true)

                prepareTestAlert(hoverInfo, true, params, setParams, setShowWait)
                // var newparams = JSON.parse(JSON.stringify(params))
                // newparams.open = true;
                // setParams(newparams)

            }
            // extensions: [new TerrainExtension()]

        })
        console.log("gg")
        console.log(JSON.stringify(searchedLocations))
        const flagLayer = new ScenegraphLayer({  // you are here change icon
            id: 'geojson-Icon',
            data: searchedLocations,
            sizeMinPixels: 50,
            sizeMaxPixels: 200,
            scenegraph: "https://images.cubicsearch.com/icons/whiteflag.glb",
            extensions: mobileAndTabletCheck() ? [] : [ new TerrainExtension()],
            terrainDrawMode: mobileAndTabletCheck() ? null : 'offset',
            getPosition: d => {

                return [d.geometry.coordinates[0], d.geometry.coordinates[1], d.geometry.coordinates[2] - 20]
            },
            getOrientation: d => [0, 0, 90],

            sizeScale: 20,
            _lighting: 'pbr',

            pickable: true,

            onClick: (info) => {

                // var text = mapRef.current.pickObject({ x: info.pixel[0], y: info.pixel[1], id: ['google-3d-tiles'], unproject3D : true})
                // var group = mapRef.current.pickMultipleObjects({ x: info.pixel[0], y: info.pixel[1], radius: 400, id: ['geojson-layerText'], unproject3D: true })

                alert("This is the location you just searched!")




            },


        })

        console.log("hh")
        
        const streetPostLayer = new ColumnLayer({
            id: 'line-layer',
            data: streetsLinesOnMap,
            diskResolution: 9,
            radius: 1,
            extruded: true,
            extensions: mobileAndTabletCheck() ? [] : [ new TerrainExtension()],
            terrainDrawMode: mobileAndTabletCheck() ? null : 'offset',
            pickable: false,
            elevationScale: .99,
            getPosition: d => d.geometry.coordinates,
            getFillColor: d => [255, 0, 0],
            getLineColor: [0, 0, 0],
            getElevation: d => mobileAndTabletCheck() ? d.elevation : 50 //
        })
        console.log("ii")


        const streetBillboards = new GeoJsonLayer({
            id: 'geojson-streets',
            data: streetsOnMap,
            extensions: !mobileAndTabletCheck() ? [ new TerrainExtension()] : [],
            terrainDrawMode: 'offset',
            // stroked: true,
            pointType: 'text',
            getLineColor: [255, 20, 0],
            getText: (d) => {
                return d.name
            },
            // getTextAngle: d =>
            //     // mapRef.current.deck.viewState["default-view"].bearing +
            //     d.angle,
            textBackground: true,
            textFontWeight: 900,
            getTextBackgroundColor: d => {
                if (d.properties.highway === "motorway") {
                    return [100, 155, 100]
                }
                else { return [150, 150, 150] }

            }
            ,
            textBillboard: true,
            textBackgroundPadding: [4, 4],
            textFontFamily: "Gill Sans, sans-serif",
            getTextColor: [255, 255, 255],

            // getAlignmentBaseline: () => 'bottom',

            getTextAnchor: d => 'middle',
            // getAligmentBaseline: d => "top",
            lineWidthScale: 10,
            lineWidthMinPixels: 2,
            getFillColor: [160, 160, 180],
            getTextSize: 12,


        })
        console.log("jj")
        const cityBillboards = new GeoJsonLayer({
            id: 'geojson-cities',
            data: citiesOnMap,
            extensions: !mobileAndTabletCheck() ? [ new TerrainExtension()] : [],
            terrainDrawMode: 'offset',
            // stroked: true,
            pointType: 'text',
            getLineColor: [255, 20, 0],
            getText: (d) => {
                return d.name
            },

            textBackground: true,
            textFontWeight: 900,
            getTextBackgroundColor: d => {
                if (d.properties.highway === "city") {
                    return [100, 155, 100, 255]
                }
                else { return [0, 0, 0] }

            }
            ,

            textBackgroundPadding: [4, 4],
            textFontFamily: "Gill Sans, sans-serif",
            getTextColor: [255, 255, 255],
            // getAlignmentBaseline: () => 'bottom',

            getTextAnchor: d => 'middle',
            // getAligmentBaseline: d => "top",
            lineWidthScale: 10,
            lineWidthMinPixels: 2,
            getFillColor: [160, 160, 180, 200],
            getTextSize: 20,


        })



        var layersDeckGL = []

    

    
        // console.log("You have" + clusterVarData && clusterVarData.features && clusterVarData.features.length + "results and you are " && aboveOrBelow())
         
    
        if (coords
            && coords.length > 0 // we may have reset the search so this may need to go.  We try it.
        ) {
            layersDeckGL.push(buildingPostLayer)
            if (!mobileAndTabletCheck()) {
                layersDeckGL.push(buildingCirclesLayer)
            }
            layersDeckGL.push(buildingBillboards)
        }
        else { 
         
            if (currentState?.zoom > 6) {
                
            layersDeckGL.push(stateSummaryLayer)}
        }
        

   
        if (parcelLayer && showParcels && currentState && (aboveOrBelow() === "below")) {
            layersDeckGL.push(parcelLayer)
        }



        if ((aboveOrBelow() === "below")) {
            const google3dLayer = returnGoogletileLayer()
            layersDeckGL.push(cityBillboards, streetBillboards, streetPostLayer, google3dLayer,)
        }

        
        if (flagLayer
            && searchedLocations
            && (searchedLocations.length === 1)
            && (searchedLocations[0].geometry.coordinates.length === 3)
            && !isNaN(searchedLocations[0].geometry.coordinates[0])
            && !isNaN(searchedLocations[0].geometry.coordinates[1])
                && !isNaN(searchedLocations[0].geometry.coordinates[2])
            // && (flagLayer.lifecycle !== "Awaiting state")
        ) {
            // alert(JSON.stringify(searchedLocations[0].geometry.coordinates))
            console.log("scenegraph pushed")
            if (currentState?.zoom > 6) {
                layersDeckGL.push(flagLayer)
            }
        }



        fabRefreshRef && fabRefreshRef.current && fabRefreshRef.current.setShowWait && fabRefreshRef.current.setShowWait(false)
        return (layersDeckGL)
    }




    const handleMoveStart = debounce(() => {
        console.log("restarted")
        console.log(" mapbox onZoomStart")

        var abortAnswer = controller.abort()
        controllerEurope.abort()
        controllerNA.abort()
        controllerAll.abort()
        controllerCities.abort()
        newAllController.abort()
    }, 100)

    const handleOnMoveEnd = debounce(async (ev) => {
        // Do stuff with the event!

        if (reloadOnMove) {
         
            await mapReload()
           
        }
        else {
            // var googleLayer = returnGoogletileLayer()
            // 
            var theViewport = getMyViewport()
            var viewportElevation = await getViewportElevation(mapRef)
            
             if (aboveOrBelow() === 'below')
             {
                 await getCities(theViewport, theViewStateOuter, elevationFactorState, viewportElevation) 
                 var streetsSet = await getStreets(theViewport, theViewStateOuter, viewportElevation, elevationFactorState, viewportElevation)
                 refreshMapProperties(searchResults, elevationFactorState, viewportElevation, viewportElevation, filters)
                 
        
            }

            overlay.setProps({ layers: recalcLayers() })
        }


    }, mobileAndTabletCheck() ?750:1000);



    function removeProperty(property) {
        
        setSelectedLocation(property)
        createCookie("selectedLocation", property.id, 1)
        var splicedArray = removeById(searchResults, property.id);
        refreshMapProperties(splicedArray, elevationFactorState, viewportElevation, viewportElevation, filters)

        overlay.setProps({ layers: recalcLayers() })
        
        setTimeout(
            () =>
            {
                if (splicedArray.length === 0) {
                    mapReload()
                }
                setSearchResults(splicedArray);
     
              
               
            }, 500)

    }
    var heightMap = mobileAndTabletCheck() ? (window.innerHeight - 90) : (window.innerHeight - 200)

 console.log("before return ")
 console.log(initialViewState)
        return (
            <>
                <div style={{ flexGrow:1, display: 'flex', flexDirection: 'column', height: '100vh' }}>
                    <Grid2 container spacing={1} style={{height:'100%'}}>
                        <Grid2 xs={3} sx={{
                            display: mobileAndTabletCheck() ? 'none' : null,
                            height: heightMap, paddingTop: 5,
                            paddingLeft: 3, paddingRight: 1, overflow: 'scroll'
                        }}>
                            <InfoCards

                                filterInputs={filterInputs}
                                searchResults={searchResults}
                                setSearchResults={setSearchResults}
                                selectedLocation={selectedLocation}
                                setSelectedLocation={setSelectedLocation}
                                    // || searchResults && (searchResults.length > 0) && searchResults[0]}
                                refs={refs}
                                onClick={

                                    (info) => {
                                        var theProperty = searchResults.find((theresult) => {

                                            if (theresult.id === info) {
                                                return true
                                            }

                                        })

                                        searchedLocations = ([
                                            {
                                                "type": "Feature",
                                                "properties": {
                                                },
                                                "geometry": {
                                                    "type": "Point",
                                                    "coordinates": [theProperty.Longitude, theProperty.Latitude, theProperty.Elevation + theProperty.BuildingHeight / 3]
                                                },
                                            }])
                                        console.log(theProperty.Latitude)
                                        console.log(theProperty.Longitude)
                                        overlay.setProps({ layers: recalcLayers() })
                                        setReloadOnMove(false)
                                        mapRef.current.flyTo({ center: [theProperty.Longitude, theProperty.Latitude], zoom: getZoomFromElevation((theProperty.Elevation || viewportElevation) + 1000), duration: 2000 });

                                    }
                                }
                                showMe={true}
                                remove={true}
                                confirm={confirmIn}
                                contactId={contactId}
                                clusterData={clusterData && clusterData.features}
                                clusterTotal={clusterData && clusterData.totals}
                                clusterTotalComps={clusterData && clusterData.totalsComps}
                                removeProperty={removeProperty}
                                surveyId={surveyId}
                                setShowSurveys={setShowSurveys}
                                setSaveSurveyOpen={setSaveSurveyOpen}

                               

                            />
                        </Grid2>

                        <Grid2 xs={9} style={{ paddingTop: 4, height:heightMap, flex: 1, paddingRight: mobileAndTabletCheck() ? 0: 50 }}>
                            <div style={{ backgroundColor: 'lightblue', borderRadius: mobileAndTabletCheck()? 0: 20,  overflow: 'hidden', height: '100%', width:'100%'}}>
                            <DeckMap
                                
                                attributionControl={false}
                                onError={(error)=>{console.log(error)}}
                                initialViewState={initialViewState
                                }
                                // onClick={()=>{alert(currentState.maxZoom)}}

                                ref={mapRef}
                                    mapStyle="mapbox://styles/schordr/ckvq4te666xrm14lq6wmj1fi0"
                                    projection={'globe'}

                                mapboxAccessToken={MAPBOX_TOKEN}
                                onLoad={onMapLoadMapBox}
                                

                                onMoveStart={
                                    handleMoveStart}


                                onMoveEnd={handleOnMoveEnd}



                            >

                                    {<div id="navControlDiv" style={{ padding: 4 }}>{!mobileAndTabletCheck() ?
                                        <NavigationControl style={{ position: 'relative', padding: 5 }} > </NavigationControl> : <></>}</div>}


                                <div id="CreditsDiv"
                                        style={{ zIndex: 105, width: 200, position: 'absolute', left: 10, bottom: 30 } }
                                    // className='bottomright'
                                    >
                                    <Typography component={'div'}sx={{ fontSize: 10, color: 'white' }} >{aboveOrBelow() === "below" ? credits : ""}</Typography>
                                </div>

                                </DeckMap>
                            </div>
                        </Grid2>
                    </Grid2>
                </div>
             
                <TestAlert
                    params={params}
                    setParams={setParams}
                />
                





                {(mobileAndTabletCheck()  ) ?
                    <SwipeableTemporaryDrawer open={drawerOpen} mapButton={mapButton} setPopupInfo={setPopupInfo} setOpen={setDrawerOpen} removeProperty={removeProperty}
                        searchResults={searchResults} setSearchResults={setSearchResults} cardProps={popupInfo ? popupInfo.object : null}
                        refreshMapPropertiesInMain={refreshMapPropertiesInMain} mapRef={mapRef} selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation} setShowSurveys={setShowSurveys} surveyId={surveyId} setSaveSurveyOpen={setSaveSurveyOpen}
                    />
                    :
                    <></>}
        </>
        )
    


  


})

export default Decky




function getMarkers(searchResults) {
    searchResults.map((searchResult, index) => {
        searchResults[index].summary = getMarkerInfo(searchResult)
    })
    return searchResults
}
const remove = (arr, id) => {
    const requiredIndex = arr.findIndex((el) => {
        return el.id === id;
    });

    if (requiredIndex === -1) {
        return false;
    }
    arr.splice(requiredIndex, 1);
    return arr;
};



// 




// //test - console.log(unique(array, ['class', 'fare']));

function unique(arr, keyProps) {
    const kvArray = arr.map(entry => {
        const key = keyProps.map(k => entry[k].toString()).join('|');
        return [key, entry];
    });
    const map = new Map(kvArray);

    return Array.from(map.values());
}

function cubicOverlap(cubicBuildings, osmBuildings) {
    return new Promise(function (resolve, reject) {
        var dominantBuildings = []
        var buildingsToDelete = []
        

        if (!cubicBuildings || !cubicBuildings.length||  cubicBuildings.length === 0 ) {
            resolve({buildingsToDelete: buildingsToDelete, dominantBuildings: dominantBuildings})
            return false
        }
        osmBuildings.features.forEach ((osmBuilding, index ) =>{
            

            var buildingRoomates = cubicBuildings.filter((cubicBuilding) => {
                var contained = geoContains(osmBuilding.geometry, [cubicBuilding.Longitude, cubicBuilding.Latitude] )

                return contained
            })
            if (buildingRoomates && buildingRoomates.length === 1) {
                dominantBuildings.push(buildingRoomates[0])
            }
            else if (buildingRoomates && buildingRoomates.length > 1) {
                buildingRoomates.sort((a, b) => { return b.createdAt - a.createdAt })
                buildingRoomates.sort((a, b) => { return (b.avails.length || 0)- (a.avails.length || 0) })

                
                var dominantFound = false
                var deletedFound = false
                var concatBoth = dominantBuildings.concat(buildingsToDelete)
                for (var buildingRoomate of buildingRoomates) {
                   
                    
                    if (!concatBoth.some((concatBuilding) => {
                        return buildingRoomate.id === concatBuilding.id
                    })){

                        if ((buildingRoomate.avails && buildingRoomate.avails > 0) ||
                            (!dominantFound &&
                                (buildingRoomate.Size && buildingRoomate.Size > 0) || (buildingRoomate.Footprint && buildingRoomate.Footprint > 0))) {
                       
                            // buildingRoomate.buddies = buildingRoomates
                        dominantBuildings.push({id: buildingRoomate.id})
                        dominantFound = true
                    }
                    else if ( (dominantFound === true) && (!buildingRoomate.avails || (buildingRoomate.avails.length === 0)))
                    {
                            deletedFound = true
                            // buildingRoomate.buddies = buildingRoomates
                        buildingsToDelete.push({id: buildingRoomate.id})
                        }
                    }
                   
                }
              
                
            }

        })

        resolve({dominantBuildings: dominantBuildings, buildingsToDelete: buildingsToDelete})
    })
}

function overLap(osmBuildings, cubicBuildings, mapRef, lightBoxStructures) {
    
 
    return new Promise( async function (resolve, reject) {
        var axiosArray = []
        var osmKeepers = []
        var tilePromises = [] // this is used to hold promises for new tiles so we can update teh entire array of buldings after all requests tiles have been completed
        var tileCookies = []
        var searchCookies = []
        var newOSMBuildings = 0

        var progressOSM = 0
        // alert(osmBuildings.features.length)
        

       

        osmBuildings.features.forEach((osmBuilding, index) => {
            var esriCoords = polylabel(osmBuilding.geometry.coordinates)
            var latitude = esriCoords[1]
            var longitude = esriCoords[0]
            var buildingOverlap

            if (cubicBuildings && cubicBuildings.length > 0) {
                buildingOverlap = cubicBuildings.some((cubicBuilding, index) => {
             

                    if (geoContains(osmBuilding.geometry, [cubicBuilding.Longitude, cubicBuilding.Latitude]) ||
                        ((Math.abs(latitude - cubicBuilding.Latitude) < .001) && (Math.abs(longitude - cubicBuilding.Longitude) < .0001))) {
                        // cubicBuildings[index].Longitude = polylabel(osmBuilding.geometry.coordinates)[0]
                        // cubicBuildings[index].Latitude = polylabel(osmBuilding.geometry.coordinates)[1]
                        // //test - console.log("osm name", osmBuilding.properties.name, "cubic", JSON.stringify(cubicBuilding))
                        return true
                    }
                    else if (osmBuildings.features.indexOf((osmBuildingFromNewList) => {
                        var centerNew = polylabel(osmBuildingFromNewList.geometry.coordinates)
                        var centerCurrent = polylabel(osmBuilding.geometry.coordinates)

                        return (Math.abs(centerNew[0] - centerCurrent[0]) < .001) && (Math.abs(centerNew[1] - centerCurrent[1]) < .001)
                    }) >= 0) {//if the new osmBuilding overlaps another new osmBuilding we can't add two, then make it overlap -- this is to prevent duplicates being added within the same new data set
                        return true
                    }
                    else {
                        return false
                        // only look for ones taht are in there
                    }

                })
            }





            if (!buildingOverlap) {


                var closestTileIndex = 0
                var geojsonArea = require('@mapbox/geojson-area');
                if ((geojsonArea.geometry(osmBuilding.geometry) * 10.7639) > 5000) {

                    if (!osmBuilding.properties.addr_street || !osmBuilding.properties.addr_housenumber || !osmBuilding.properties.addr_city || !osmBuilding.properties.addr_state) {
                        var newbuilding = newBuilding(osmBuilding, esriCoords, false, index)
                        progressOSM++

                        var cubicIndex = osmKeepers.push(newbuilding) - 1
                        axiosArray.push({ cubicIndex: cubicIndex, Longitude: longitude, Latitude: latitude }) // these need to be geocoded


                    }
                    else {

                        var newbuilding = newBuilding(osmBuilding, esriCoords, true, index)
                        // cubicBuildings.push(newbuilding)
                        osmKeepers.push(newbuilding)  // these have addresses
                        progressOSM++

                    }
                }



            }
            else {
                // we need to remove the element from the cubic array
                // //test - console.log("yes overlap")

            }

        }



        )


        // if (((osmBuildings.length - 1) / progressOSM) === 1) {
        //test - console.log("osmTotal" + osmBuildings.features.length)
        //test - console.log("Keepers " + osmKeepers.length)

        axiosArray = (unique(axiosArray, ['Latitude', 'Longitude'])) // these ones need streets and addresses - they wil next be batch geocoded
        osmKeepers = (unique(osmKeepers, ['Latitude', 'Longitude'])) // these ones already have addresses and have been converted to new buildings



        if (axiosArray.length > 0) {

            var batchGeoResults = await axios.post(process.env.REACT_APP_DATABASE + "api/batchGeocode", { axiosArray: axiosArray }).catch((error) => { console.log(error) })
              
            if (batchGeoResults){
                    batchGeoResults.data.forEach((geoResult, index) => { // add in the newly geocoded results into the list

                        if (osmKeepers[geoResult.cubicIndex]) {
                            osmKeepers[geoResult.cubicIndex].StreetName = geoResult.StreetName || ""
                            osmKeepers[geoResult.cubicIndex].StreetNumber = geoResult.StreetNumber || ""
                            osmKeepers[geoResult.cubicIndex].City = geoResult.City || ""
                            osmKeepers[geoResult.cubicIndex].State = geoResult.State || ""
                            // osmKeepers[geoResult.cubicIndex].County = geoResult.County
                            osmKeepers[geoResult.cubicIndex].Country = geoResult.Country || ""
                            osmKeepers[geoResult.cubicIndex].Zip = geoResult.Zip || ""
                        }


                    })
            }




                    osmKeepers.forEach((osmKeeper, index) => {  //  these are the new buildings
                        // if (!osmKeeper.BuildingHeight || (osmKeeper.BuildingHeight === "") || (osmKeeper.BuildingHeight === 0) || !osmKeeper.NumberFloors || (osmKeeper.NumberFloors === "") || (osmKeeper.NumberFloors === 0)) {

                        osmBuildings.features.some((oneFeature) => {
                        // we are seeing if the new buildings are contained by the OSM buildings that we downloaded.  
                        // These came from the downloadeed buidlings, so they should match.This is probalby to make sure teh geocoding properly worked, it also gets the height.some of these will be true, but we are not keeping track

                            var containMe = geoContains(oneFeature.geometry, [osmKeeper.Longitude, osmKeeper.Latitude])

                            if (containMe) {
                                if (oneFeature.properties.height && (!osmKeeper.BuildingHeight || (osmKeeper.BuildingHeight === "") || (osmKeeper.BuildingHeight === 0))) {

                                    osmKeepers[index].BuildingHeight = parseFloat(oneFeature.properties.height) * 3.28084

                                }
                                if (oneFeature.properties.type && (!osmKeeper.PropertyType || (osmKeeper.PropertyType === ""))) {

                                    osmKeepers[index].PropertyType = oneFeature.properties.type

                                }
                                else {
                                    // if we don't do this we may not be able to see the building, and it may keep trying to fill this one
                                    // 20 doesn't work, it shows up too much and is confusing... trying with null - 7/23 update
                                    if (oneFeature.properties.levels > 0) {
                                        osmKeepers[index].BuildingHeight = parseInt(oneFeature.properties.levels) * 15
                                    }
                                    else {
                                        osmKeepers[index].BuildingHeight = 0
                                    }
                                    // this is a guess if the height is null, it is the average height of a warehouse 
                                }
                                if (oneFeature.properties.levels && (!osmKeeper.NumberFloors || (osmKeeper.NumberFloors === "") || (osmKeeper.NumberFloors === 0))) {
                                    osmKeepers[index].NumberFloors = parseInt(oneFeature.properties.levels)
                                }
                                else {
                                    if ((osmKeepers[index].BuildingHeight > 40) && (osmKeepers[index].type && !osmKeepers[index].type.includes("Residential"))) {
                                        osmKeepers[index].NumberFloors = parseInt(osmKeepers[index].BuildingHeight / 16)
                                    }
                                }

                                // cubicBuildings[index].Longitude = polylabel(osmBuilding.geometry.coordinates)[0]
                                // cubicBuildings[index].Latitude = polylabel(osmBuilding.geometry.coordinates)[1]
                                return true
                            }
                        })

                        // }
                    })

                    // we can let this go without waiting
                    axios.post(process.env.REACT_APP_DATABASE + "api/bulkBuildingInsert", {
                        cubicBuildings: osmKeepers

                    }).then((buildingsCreated) => {
                        // 
                        // alert(JSON.stringify(buildingsCreated.data))
                    }).catch((error) => {
                        // 
                        alert(error)
                    })
            
                    resolve(osmKeepers)  // we are only returning the new buildings that did not overlap.
                    // })
            
        }
        else {
            resolve([])
        }




    })
}

//Converts numeric degrees to radians 
Number.prototype.toRad = function () {
    return this * Math.PI / 180;
}


function newBuilding(osmBuilding, esriCoords, addressIncluded, index) {


    var height = osmBuilding.properties.height * 3.28084 || null//parseInt((mapTiles[closestTileIndex][2] + mapTiles[closestTileIndex][3]))
    var numFloors = osmBuilding.properties.levels || osmBuilding.properties.building_levels || null

    var geojsonArea = require('@mapbox/geojson-area');
    var floorSize = parseInt(geojsonArea.geometry(osmBuilding.geometry) * 10.7639)
    var newbuilding = {}

    if (!addressIncluded) {
        newbuilding = {
            OSM: true,
            id: new Date().getTime() - 1687390000000 + (1000 * index + 1),
            Latitude: esriCoords[1],
            Longitude: esriCoords[0],

            avails: [],
            images: [],
            PropertyType: osmBuilding.properties.type,
            PropertyName: osmBuilding.properties.name,
            CampusName: osmBuilding.properties.official_name,
            Size: ((floorSize < 40000) && numFloors && (numFloors < 10)) ? floorSize * (numFloors || 0) : null,// polygonArea(osmBuilding.geometry.coordinates ) ,
            Footprint: floorSize,

            YearBuilt: osmBuilding.properties.start_date ? new Date(osmBuilding.properties.start_date) : null,
            YearRenovated: null,
            NumberFloors: numFloors, //osmBuilding.properties.levels || parseInt((mapTiles[closestTileIndex][2] + mapTiles[closestTileIndex][3])/16),

            Elevation: null, //+ mapTiles[closestTileIndex][3]
            markedDelete: 0,

            BuildingHeight: height,
            FloorToFloor: height && osmBuilding.properties.levels ?
                height / osmBuilding.properties.levels :
                null,
            FloorToFloorL1: null,
            FloorToFloorTOP: null,
            ConstructionType: osmBuilding.properties.building_structure,
            LiveLoad: null,
            FreightElevators: null,
            PassengerElevators: null,
            BackupGenerator: null,
            BackupGeneratorKW: null,
            PowerAmps: osmBuilding.properties.power,
            PowerVolts: null,
            RoofScreenHeight: null,
            InitialPurpose: null,
            CurrentPurpose: null,
            PotentialPurpose: null,
            UtilityPad: null,
            SeparatedLabAir: null,
            CoolingSystem: null,
            HVACYear: null,
            RoofYear: null,
            ParkingRatio: null,
            ParkingStyle: null,
            FiberLines: null,
            DockDoors: null,
            GradeDoors: null,
            ConstructionStatus: null,
            MechanicalMezz: null,
            Showers: null,

            Gym: null,
            MeetingRooms: null,
            Expenses: null,
            ExpensesDescription: null,
            OccupancyRating: null,
            OwnerUserOrInvestor: null,

            Notes: osmBuilding.properties.description,

            contacts: [],
            groups: [{
                building_group:
                {
                    groupId: 1,
                    markedDelete: 0,
                    groupPermission: "Edit"
                }
            }]
        }
    }
    else {

        newbuilding = {
            OSM: true,
            id: new Date().getTime() - 1687390000000 + 1000 * index + 1,
            Latitude: esriCoords[1],
            Longitude: esriCoords[0],

            avails: [],
            images: [],

            StreetName: osmBuilding.properties.addr_street, //+ "- LBS:"+ lightBoxStreet,
            StreetNumber: osmBuilding.properties.addr_housenumber,
            PropertyType: osmBuilding.properties.type,
            PropertyName: osmBuilding.properties.name,
            CampusName: osmBuilding.properties.official_name,
            Size: ((floorSize < 40000) && numFloors && (numFloors < 10)) ? floorSize * (numFloors || 0) : null,// polygonArea(osmBuilding.geometry.coordinates ) ,
            Footprint: floorSize,
            City: osmBuilding.properties.addr_city, //+ "- LBS:" + lightBoxCity,
            State: osmBuilding.properties.addr_state,
            Zip: osmBuilding.properties.addr_postcode,
            County: osmBuilding.properties.addr_district,
            YearBuilt: osmBuilding.properties.start_date ? new Date(osmBuilding.properties.start_date) : null,
            YearRenovated: null,
            NumberFloors: numFloors, //osmBuilding.properties.levels || parseInt((mapTiles[closestTileIndex][2] + mapTiles[closestTileIndex][3])/16),

            Elevation: null, //+ mapTiles[closestTileIndex][3]
            // LightBoxHeight: lightBoxHeight,


            markedDelete: 0,

            BuildingHeight: height,
            FloorToFloor: height && osmBuilding.properties.levels ?
                height / osmBuilding.properties.levels :
                null,
            FloorToFloorL1: null,
            FloorToFloorTOP: null,
            ConstructionType: osmBuilding.properties.building_structure,
            LiveLoad: null,
            FreightElevators: null,
            PassengerElevators: null,
            BackupGenerator: null,
            BackupGeneratorKW: null,
            PowerAmps: osmBuilding.properties.power,
            PowerVolts: null,
            RoofScreenHeight: null,
            InitialPurpose: null,
            CurrentPurpose: null,
            PotentialPurpose: null,
            UtilityPad: null,
            SeparatedLabAir: null,
            CoolingSystem: null,
            HVACYear: null,
            RoofYear: null,
            ParkingRatio: null,
            ParkingStyle: null,
            FiberLines: null,
            DockDoors: null,
            GradeDoors: null,
            ConstructionStatus: null,
            MechanicalMezz: null,
            Showers: null,

            Gym: null,
            MeetingRooms: null,
            Expenses: null,
            ExpensesDescription: null,
            OccupancyRating: null,
            OwnerUserOrInvestor: null,

            Notes: osmBuilding.properties.description,

            contacts: [],
            groups: [{
                building_group:
                {
                    groupId: 1,
                    markedDelete: 0,
                    groupPermission: "Edit"
                }
            }]
        } // make a copy
    }

    return newbuilding

}


function degrees_to_radians(degrees) {
    var pi = Math.PI;
    return degrees * (pi / 180);
}


function latLngToTileXY(lat, lng, zoom) {
    var lat_rad = degrees_to_radians(lat)
    var n = Math.pow(2, zoom)
    var xtile = parseInt(n * ((lng + 180) / 360))
    var ytile = parseInt(n * (1 - (Math.log(Math.tan(lat_rad) + 1 / Math.cos(lat_rad)) / Math.PI)) / 2)

    var result = {
        x: xtile,
        y: ytile
    }
    return result
}


function updateBuildingHeightSpecific(cubicBuildings) {

    var results = cubicBuildings
    var tileArray = []

    results.map((buildingMissingHeight) => {
        var newTile = latLngToTileXY(buildingMissingHeight.Latitude, buildingMissingHeight.Longitude, 15)
        var tileExistsAlready = tileArray.some((existingTile) => {
            return ((existingTile.x === newTile.x) && (existingTile.y === newTile.y))
        })
        if (!tileExistsAlready) {
            tileArray.push(newTile)
        }
    })

    //test - console.log("Results " + results.length)
    //test - console.log("Tiles " + tileArray.length)
    var tilePromiseArray = []
    tileArray.map((tileToFetch) => {
        var qs = "https://data.osmbuildings.org/0.2/anonymous/tile/15/" + tileToFetch.x + "/" + tileToFetch.y + ".json"
        //test - console.log(qs)
        var myPromise = axios.get(qs)
            .catch((error) => console.log("Error in getTiles" + error.message))
        tilePromiseArray.push(myPromise)
    })

    Promise.all(tilePromiseArray).then((tileDataArrays) => {
        //test - console.log("Data ARrays")
        //test - console.log(tileDataArrays)
        tileDataArrays.map((tilePointDataArray) => {
            //test - console.log("tile Point Dta Array")
            //test - console.log(tilePointDataArray)
            tilePointDataArray.data.features.map((tilePointData) => {
                results.map((cubicBuilding) => {
                    var containMe = geoContains(tilePointData.geometry, [cubicBuilding.Longitude, cubicBuilding.Latitude])
                    if (containMe) {
                        var qs = process.env.REACT_APP_DATABASE +
                            "api/updateBuildingHeightSpecific"

                        //test - console.log("updateing building height specific")
                        //test - console.log(qs)
                        axios.post(qs, {
                            building: {
                                id: cubicBuilding.id,
                                PropertyName: cubicBuilding.PropertyName,
                                PropertyType: cubicBuilding.PropertyType,
                                NumberFloors: cubicBuilding.NumberFloors,
                                BuildingHeight: cubicBuilding.BuildingHeight
                            },
                            tilePointData: tilePointData

                        }, { timeout: 1000 }).catch((error) => {
                            //test - console.log("updating building height taking a while, we move mon")
                        })
                    }
                })
            })

        })


    }).catch((error) => {
        //test - console.log("failed in Promise All: " + error)

    })


}

export function getBuildingFromLatLon(latitude, longitude) {
    return new Promise(async function (resolve, reject) {

        var newTile = latLngToTileXY(latitude, longitude, 16)
        var qs = "https://data.osmbuildings.org/0.2/anonymous/tile/16/" + newTile.x + "/" + newTile.y + ".json"
        //test - console.log(qs)
        var buildings = await axios.get(qs)
            .catch((error) => console.log("Error in getBuildingFromLatLon" + error.message))

        var containMe = false

        var earth = 6378.137,  //radius of the earth in kilometer
            pi = Math.PI,
            m = (1 / ((2 * pi / 360) * earth)) / 1000;  //1 meter in degree

        var new_latitude1 = latitude + (10 * m);
        var new_latitude2 = latitude - (10 * m);

        var earth = 6378.137,  //radius of the earth in kilometer
            pi = Math.PI,
            cos = Math.cos,
            m = (1 / ((2 * pi / 360) * earth)) / 1000;  //1 meter in degree

        var new_longitude1 = longitude + (10 * m) / cos(latitude * (pi / 180));
        var new_longitude2 = longitude - (10 * m) / cos(latitude * (pi / 180));

        if (buildings && buildings.data && buildings.data.features && (buildings.data.features.length > 0)) {
            buildings.data.features.some((building) => {

                containMe = geoContains(building.geometry, [longitude, latitude]) ||
                    geoContains(building.geometry, [new_longitude1, latitude]) ||
                    geoContains(building.geometry, [new_longitude2, latitude]) ||
                    geoContains(building.geometry, [longitude, new_latitude1]) ||
                    geoContains(building.geometry, [longitude, new_latitude2])
                if (containMe) {

                    //test - console.log(building) // what is the height field?  if its empty we may have to search cubic search buildings
                    resolve(building)
                    return (containMe)
                }


            })
        }
        else {
            reject({ reason: "Some not valid" })
        }
        if (!containMe) {
            reject({ reason: "Not found" })
        }
    })

}

export function mobileAndTabletCheck() {

    let check = false;
    // (function (a) {

    //     if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
    //         check = true;
    // })(navigator.userAgent || navigator.vendor || window.opera);

    if (window.innerWidth < 1100) {
        check = true
    }

    return check;

}


function getPointByDistance(pnts, distance) {
    var cl = 0;
    var ol;
    var result;
    pnts.forEach(function (point, i, points) {
        ol = cl;
        cl += i ? lineLen([points[i - 1], point]) : 0;
        if (distance <= cl && distance > ol) {
            var dd = distance - ol;
            result = pntOnLine([points[i - 1], point], dd);
        }
    });
    return result
};
// returns a point on a single line (two points) using distance // line=[[x0,y0],[x1,y1]]
function pntOnLine(line, distance) {
    var t = distance / lineLen(line)
    var xt = (1 - t) * line[0][0] + (t * line[1][0])
    var yt = (1 - t) * line[0][1] + (t * line[1][1])
    return [xt, yt]
};
// returns the total length of a linestring (multiple points) // pnts=[[x0,y0],[x1,y1],[x2,y2],...]
function totalLen(pnts) {
    var tl = 0;
    pnts.forEach(function (point, i, points) {
        tl += i ? lineLen([points[i - 1], point]) : 0;
    });
    return tl;
};
// returns the length of a line (two points) // line=[[x0,y0],[x1,y1]]
function lineLen(line) {
    var xd = line[0][0] - line[1][0];
    var yd = line[0][1] - line[1][1];
    return Math.sqrt(xd * xd + yd * yd);
};

function refreshMapProperties(searchResults, elevationFactorState, viewportElevation, groundElevation, filters) {

    // return new Promise(async function (resolve, reject) {
 

    console.log("refresh start " + new Date().getSeconds())

    var coordsData = []
    var coordsDataLines = []
    var coordsDataCircles = []


    var viewportElevationToUse

    if (viewportElevation) {

        viewportElevationToUse = viewportElevation.elevation
    }
    else {
        viewportElevationToUse = 5// await getViewportElevation(mapRef, searchResults[0])
    }

    if (!searchResults
        // || searchResults.length === 0
    ) { // we may have cleared the properties so let it go
        // reject({ reason: "empty" })
        return (false)
    }

    searchResults.map((result) => {

        // var theElevation = result.Elevation ? parseFloat(result.Elevation) : 0
        var theBuildingHeight = result.BuildingHeight ? parseFloat(result.BuildingHeight) : 0
        // var HeightFactor = elevationFactorState
        // console.log("cow")

        coordsDataLines.push({
            "type": "Feature",
            "id": result.id,
            "avails": result.avails,
            "images": result.images,
            "NumberFloors": result.NumberFloors,
            "Latitude": result.Latitude,
            "Longitude": result.Longitude,
            "Elevation": result.Elevation,
            "elevationHeight": 40 * elevationFactorState,
            "mobileElevationHeight": viewportElevationToUse + theBuildingHeight / 3.28 + (20 * elevationFactorState + 1)



            // + theBuildingHeight * HeightFactor / 3.28
            ,
            "BuildingHeight": result.BuildingHeight,
            "properties": {
                "CampusName": result.CampusName,
                "Size": result.Size,
                "Footprint": result.Footprint,
                "PropertyName": result.PropertyName,  //nfObject.format(parseInt((geojsonArea.geometry(esriShape.geometry) * 10.7639)))
                "StreetName": result.StreetName,
                "StreetNumber": result.StreetNumber,
                "City": result.City,
                "State": result.State,
                "summary": getMarkerInfo(result)

            },
            // "geometry": {
            //     "type": "Polygon",
            //     "coordinates": [
            //         [[result.Longitude + .0001, result.Latitude + .0001], [result.Longitude - .0001, result.Latitude + .0001],
            //         [result.Longitude - .0001, result.Latitude - .0001], [result.Longitude + .0001, result.Latitude - .0001], [result.Longitude + .0001, result.Latitude + .0001]]
            //     ]
            // },
            "geometry": {
                "type": "Point",

                "coordinates": (() => {

                    // if (!mobileAndTabletCheck()) {
                    // alert(parseInt(result.Elevation) + parseFloat(result.BuildingHeight) / 3.2084)
                    // return ([result.Longitude, result.Latitude])
                    var ElevationFactor = elevationFactorState
                    var HeightFactor = elevationFactorState





                    var point

                    if (mobileAndTabletCheck()) {

                        point = [result.Longitude, result.Latitude]
                        // , theElevation * ElevationFactor +
                        // theBuildingHeight * HeightFactor / 3.28]//3.28084]
                    }
                    else {

                        point = [result.Longitude, result.Latitude]
                        // , theElevation * ElevationFactor +
                        // theBuildingHeight * HeightFactor / 3.28]//3.28084]
                    }
                    // //test - console.log(JSON.stringify(point))
                    // //test - console.log(result.id)

                    return point
                    // }
                    // else {
                    // var point = [result.Longitude, result.Latitude, parseInt(result.Elevation) + parseFloat(result.BuildingHeight) / 3.28084]//3.28084]

                    // return point
                    // }
                })()

            },
        })
        // console.log("chick")

        coordsDataCircles.push({
            "type": "Feature",
            "id": result.id,
            "avails": result.avails,
            "images": result.images,
            "NumberFloors": result.NumberFloors,
            "Latitude": result.Latitude,
            "Longitude": result.Longitude,
            "Elevation": result.Elevation,
            "elevationHeight": groundElevation * elevationFactorState,
            "BuildingHeight": result.BuildingHeight,
            "properties": {
                "CampusName": result.CampusName,
                "Size": result.Size,
                "Footprint": result.Footprint,
                "PropertyName": result.PropertyName,  //nfObject.format(parseInt((geojsonArea.geometry(esriShape.geometry) * 10.7639)))
                "StreetName": result.StreetName,
                "StreetNumber": result.StreetNumber,
                "City": result.City,
                "State": result.State,
                "summary": getMarkerInfo(result)

            },
            // "geometry": {
            //     "type": "Polygon",
            //     "coordinates": [
            //         [[result.Longitude + .0001, result.Latitude + .0001], [result.Longitude - .0001, result.Latitude + .0001],
            //         [result.Longitude - .0001, result.Latitude - .0001], [result.Longitude + .0001, result.Latitude - .0001], [result.Longitude + .0001, result.Latitude + .0001]]
            //     ]
            // },
            "geometry": {
                "type": "Point",

                "coordinates": (() => {

                    // if (!mobileAndTabletCheck()) {
                    // alert(parseInt(result.Elevation) + parseFloat(result.BuildingHeight) / 3.2084)
                    // return ([result.Longitude, result.Latitude])
                    var ElevationFactor = elevationFactorState
                    var HeightFactor = elevationFactorState





                    var point
                    var theElevation = result.Elevation ? parseFloat(result.Elevation) : 0
                    var theBuildingHeight = result.BuildingHeight ? parseFloat(result.BuildingHeight) : 0
                    if (mobileAndTabletCheck()) {

                        point = [result.Longitude, result.Latitude, 3]
                        // , theElevation * ElevationFactor +
                        // theBuildingHeight * HeightFactor / 3.28]//3.28084]
                    }
                    else {

                        point = [result.Longitude, result.Latitude, 3]
                        // , theElevation * ElevationFactor +
                        // theBuildingHeight * HeightFactor / 3.28]//3.28084]
                    }
                    // //test - console.log(JSON.stringify(point))
                    // //test - console.log(result.id)

                    return point
                    // }
                    // else {
                    // var point = [result.Longitude, result.Latitude, parseInt(result.Elevation) + parseFloat(result.BuildingHeight) / 3.28084]//3.28084]

                    // return point
                    // }
                })()

            },
        })


        coordsData.push({
            "type": "Feature",
            "id": result.id,
            "avails": result.avails,
            "images": result.images,
            "NumberFloors": result.NumberFloors,
            "Latitude": result.Latitude,
            "Longitude": result.Longitude,
            "Elevation": result.Elevation,
            "elevationHeight": groundElevation * elevationFactorState * .99,

            "BuildingHeight": result.BuildingHeight,
            "properties": {
                "CampusName": result.CampusName,
                "Size": result.Size,
                "Footprint": result.Footprint,
                "PropertyName": result.PropertyName,  //nfObject.format(parseInt((geojsonArea.geometry(esriShape.geometry) * 10.7639)))
                "StreetName": result.StreetName,
                "StreetNumber": result.StreetNumber,
                "City": result.City,
                "State": result.State,
                "summary": getMarkerInfo(result)

            },
            // "geometry": {
            //     "type": "Polygon",
            //     "coordinates": [
            //         [[result.Longitude + .0001, result.Latitude + .0001], [result.Longitude - .0001, result.Latitude + .0001],
            //         [result.Longitude - .0001, result.Latitude - .0001], [result.Longitude + .0001, result.Latitude - .0001], [result.Longitude + .0001, result.Latitude + .0001]]
            //     ]
            // },
            "geometry": {
                "type": "Point",

                "coordinates": (() => {

                    // if (!mobileAndTabletCheck()) {
                    // alert(parseInt(result.Elevation) + parseFloat(result.BuildingHeight) / 3.2084)
                    // return ([result.Longitude, result.Latitude])
                    var ElevationFactor = elevationFactorState
                    var HeightFactor = elevationFactorState





                    var point
                    var theElevation = result.Elevation ? parseFloat(result.Elevation) : 0
                    var theBuildingHeight = result.BuildingHeight ? parseFloat(result.BuildingHeight) : 0
                    if (mobileAndTabletCheck()) {

                        point = [result.Longitude, result.Latitude, viewportElevationToUse + theBuildingHeight / 3.28 + (20 + 1)]//3.28084]
                    }
                    else {

                        point = [result.Longitude, result.Latitude, 40 * elevationFactorState]//groundElevation * elevationFactorState +1]
                        // , theElevation * ElevationFactor +
                        // theBuildingHeight * HeightFactor / 3.28]//3.28084]
                    }
                    // //test - console.log(JSON.stringify(point))
                    // //test - console.log(result.id)

                    return point
                    // }
                    // else {
                    // var point = [result.Longitude, result.Latitude, parseInt(result.Elevation) + parseFloat(result.BuildingHeight) / 3.28084]//3.28084]

                    // return point
                    // }
                })()

            },
        })
    })



    if (coordsData) {
        coordsData.sort((a, b) => {

            var availA = filters.availablesCheck ? (a.properties.summary.onMarketAvailSF + a.properties.summary.offMarketAvailSF) : 0
            var compA = filters.compsCheck ? (a.properties.summary.onMarketCompsSF + a.properties.summary.offMarketCompsSF) : 0
            var availB = filters.availablesCheck ? (b.properties.summary.onMarketAvailSF + b.properties.summary.offMarketAvailSF) : 0
            var compB = filters.compsCheck ? (b.properties.summary.onMarketCompsSF + b.properties.summary.offMarketCompsSF) : 0
            return ((availA + compA) - (availB + compB))
        })


        var middleIndex = parseInt(coordsData.length / 2)
        var middleValue =
            (coordsData && coordsData[middleIndex] && filters.availablesCheck ? (coordsData[middleIndex].properties.summary.onMarketAvailSF +
                coordsData[middleIndex].properties.summary.offMarketAvailSF) : 0) +
            (coordsData && coordsData[middleIndex] && filters.compsCheck ? (coordsData[middleIndex].properties.summary.onMarketCompsSF +
                coordsData[middleIndex].properties.summary.offMarketCompsSF) : 0)
        coordsData.forEach(function (value, i) {

            var eachValAvail = filters.availablesCheck ? (coordsData[i].properties.summary.onMarketAvailSF +
                coordsData[i].properties.summary.offMarketAvailSF) : 0
            var eachValComp = filters.compsCheck ? (coordsData[i].properties.summary.onMarketCompsSF +
                coordsData[i].properties.summary.offMarketCompsSF) : 0

            if (coordsData.length < 8) {
                coordsData[i].properties.summary.rank = 'big'
            }
            else if ((middleValue - (eachValAvail + eachValComp) / middleValue) < 0.25) {
                coordsData[i].properties.summary.rank = 'medium'
            }
            else {
                if (i < coordsData.length / 3) {
                    coordsData[i].properties.summary.rank = 'small'
                }
                else if ((i >= (coordsData.length / 3)) && (i <= (2 * coordsData.length / 3))) {
                    coordsData[i].properties.summary.rank = 'medium'
                }
                else {

                    coordsData[i].properties.summary.rank = 'big'
                }
            }
        }

        )

    }
    coordsData.sort((a, b) => {

        return (b.Latitude - a.Latitude)
    })

    coords = coordsData
    coordsLines = coordsDataLines
    coordsCircles = coordsDataCircles


    console.log("refresh end " + new Date().getSeconds())
 

    // resolve(true)
    // })
}





export async function getCities(myViewport, theViewState,  elevationFactorState, viewportElevation) {
    return new Promise(async function (resolve, reject) {
        var groundElevation
        if (!myViewport) {
            resolve(false)
            return null

        }
        var latitude1 = myViewport._ne.lat
        var longitude1 = myViewport._ne.lng
        var latitude2 = myViewport._sw.lat
        var longitude2 = myViewport._sw.lng


        var nodestring = "(" + latitude2 + "," + longitude2 + "," +
            latitude1 + "," + longitude1 + ")"


        // var groundElevation = 0
        var citiesTypes = "\"place\"~\"city\""

        if (theViewState.zoom <= (12)) {
            citiesTypes = "\"place\"~\"city|town|suburb|village|neighbourhood|quarter\""
        }
        else if (theViewState.zoom <= (13)) {
            citiesTypes = "\"place\"~\"city|town|village|neighbourhood|\""
        }
        else if (theViewState.zoom > (13)) {
            citiesTypes = "\"place\"~\"city|town|village|suburb|neighbourhood|quarter\""
        }
        else {
            citiesTypes = "\"place\"~\"city\""
        }

        var populationMin = "100000"

        if (theViewState.zoom > 12) {
            populationMin = "30000"
        }
        if (theViewState.zoom > 10) {
            populationMin = "50000"
        }

        var populationConstraint = "(if:number(t[\"population\"])>" + populationMin + ")"
        if (theViewState.zoom > 15) {
            populationConstraint = ""
        }


        var elevationCity = 1000




        var sampleScreenCities = "https://www.overpass-api.de/api/interpreter?" +
            "data=[out:json];node[" + citiesTypes + "][name]"
            + nodestring + populationConstraint + encodeURI(";convert item ::=::,::geom=geom(),_osm_type=type();") + "out%20geom; "

        if (theViewState.zoom < 7) {
            sampleScreenCities = "https://www.overpass-api.de/api/interpreter?" +
                "data=[out:json];node[\"place\"=\"state\"]"
                + encodeURI(";convert item ::=::,::geom=geom(),_osm_type=type();") + "out%20geom; "

        }

        if (theViewState.zoom < 5) {
            sampleScreenCities = "https://www.overpass-api.de/api/interpreter?" +
                "data=[out:json];node[\"place\"=\"country\"]"
                + encodeURI(";convert item ::=::,::geom=geom(),_osm_type=type();") + "out%20geom; "

        }



        //test - console.log(sampleScreenCities)
        var validCities = true
        // setShowWait(true)

        // var cities = await axios.get(sampleScreenCities, {
        //     signal: newCitiesController.signal
        // })

        var cities = await getAxios(sampleScreenCities, { newCitiesMaster })
            .catch((error) => {
                console.log(error)
                validCities = false
                reject(error)

                return (false)
            })

        // setShowWait(false)


            
        var citiesReduced = []
        var citiesData = []

        if (cities) {
            cities.data.elements.map((citiesFeature, index) => {

                if (citiesFeature && citiesFeature.tags && citiesFeature.tags.name &&
                    (
                        (
                            (theViewState.zoom <= (10)) &&
                            parseFloat(citiesFeature.tags.population) > 200000) ||
                        (theViewState.zoom > (3))
                    )
                ) {
                    var citiesFeatureReducedNameIndex = citiesReduced.findIndex((citiesReduced) => {
                        return citiesReduced.name === citiesFeature.tags.name
                    })
                    if (citiesFeatureReducedNameIndex >= 0) {
                        citiesReduced[citiesFeatureReducedNameIndex].features.push(citiesFeature)
                    }
                    else {
                        citiesReduced.push({
                            name: citiesFeature.tags["name:en"] || citiesFeature.tags.name,
                            features: [citiesFeature]
                        })
                    }
                }

                else if (citiesFeature && citiesFeature.tags && citiesFeature.tags.name &&
                    (
                        (
                            ((theViewState.zoom >= (10)) && (theViewState.zoom <= (13))) &&
                            parseFloat(citiesFeature.tags.population) > 15000) ||
                        (theViewState.zoom > (13))
                    )
                ) {
                    var citiesFeatureReducedNameIndex = citiesReduced.findIndex((citiesReduced) => {
                        return ((citiesReduced.name === citiesFeature.tags.name) || (citiesReduced.name === citiesFeature.tags["name:en"]))
                    })
                    if (citiesFeatureReducedNameIndex >= 0) {
                        citiesReduced[citiesFeatureReducedNameIndex].features.push(citiesFeature)
                    }
                    else {

                        citiesReduced.push({
                            name: citiesFeature.tags["name:en"] || citiesFeature.tags.name,
                            features: [citiesFeature]
                        })
                    }

                }

            })


            // if ((theViewState.zoom > 12)) {
            console.log(new Date().getSeconds())
            console.log(new Date().getMilliseconds())
                elevationCity = await axios.get(process.env.REACT_APP_DATABASE +
                    "api/getElevationLocal?longitude=" +
                    (longitude1 + longitude2) / 2 +
                    "&latitude=" +
                    (latitude1 + latitude2) / 2, { timeout: 2000 }).catch((error) => {
                        console.log(error)

                    })

            console.log(new Date().getSeconds())
            console.log(new Date().getMilliseconds())

                if (elevationCity && (elevationCity.data.elevation !== "not found") && validCities) {
                    groundElevation = parseFloat(elevationCity.data.elevation)
                }
                else {
                    // groundElevation = 3
                }
            // }



            citiesReduced.map((city) => {

                var point = [city.features[0].geometry.coordinates[0], city.features[0].geometry.coordinates[1]]


                var zCord = groundElevation * elevationFactorState//+ Math.pow((20 - mapRef.current.deck.viewState["default-view"].zoom), 3)
                if (groundElevation > 50) {
                    zCord = zCord + 20
                }
                //  alert(zCord)
                citiesData.push({
                    "type": "Feature",
                    "geometry": {
                        "type": "Point",
                        "coordinates": [point[0], point[1], mobileAndTabletCheck() ? 200 + viewportElevation.elevation : 100]
                    },
                    "properties": city.features[0].tags,
                    name: city.name,


                })



            })

            
            citiesOnMap = citiesData
            // alert("we have cities")
            resolve(true)
        }
        else {
            // alert("No Cities")
            resolve(false)
        }

    })

}


async function getStreets(myViewport, theViewState, groundElevation, elevationFactorState, viewportElevation) {
    return new Promise(async function (resolve, reject) {
        
        if (!myViewport) {

            resolve(false)
            return null

        }
        var latitude1 = myViewport._ne.lat
        var longitude1 = myViewport._ne.lng
        var latitude2 = myViewport._sw.lat
        var longitude2 = myViewport._sw.lng


        var nodestring = "(" + latitude2 + "," + longitude2 + "," +
            latitude1 + "," + longitude1 + ")"
        var streetTypes = "\"highway\"~\"motorway\""

        if (theViewState.zoom <= (13 - (groundElevation.elevation / 100))) {
            streetTypes = "\"highway\"~\"motorway\""
        }
        else if (theViewState.zoom <= (14.5 - (groundElevation.elevation / 100))) {
            streetTypes = "\"highway\"~\"motorway|trunk\""
        }
        else if (theViewState.zoom <= (16 - (groundElevation.elevation / 100))) {
            streetTypes = "\"highway\"~\"motorway|trunk|primary|secondary|tertiary|unclassified\""
        }
        else if (theViewState.zoom > (16 - (groundElevation.elevation / 100))) {
            streetTypes = "\"highway\"~\"motorway|trunk|primary|secondary|tertiary|unclassified|residential\""
        }

        else {
            streetTypes = "\"highway\"~\"motorway\""
        }
        var sampleScreenHighwayCount = "https://www.overpass-api.de/api/interpreter?" +
            "data=[out:json];way[" + streetTypes + "][name]"
            + nodestring +
            // ";way[bridge:structure=*]" + nodestring +
            encodeURI(";") + "out%20count; "



        var streetsCount = await axios.get(sampleScreenHighwayCount, { timeout: 1000 }).catch((error) => {
            console.log(error)
            // alert("Timeout due to slow internet...")

        })


        if (streetsCount && streetsCount.data.elements && streetsCount.data.elements[0].tags.total > 100) {
            streetTypes = "\"highway\"~\"motorway|trunk|primary\""
        }


        
        var sampleScreenHighway = "https://www.overpass-api.de/api/interpreter?" +
            "data=[out:json];way[" + streetTypes + "][name]"
            + nodestring +
            // ";way[bridge:structure=*]" + nodestring +
            encodeURI(";convert item ::=::,::geom=geom(),_osm_type=type();") + "out%20geom; "

        //test - console.log(sampleScreenHighway)
        var streets = {
            data: {
                elements: []
            }
        }

        // if (aboveOrBelow() > 13) {
        //setShowWWait(true)
        streets = await axios.get(sampleScreenHighway, { timeout: 1000 }).catch((error) => {
            console.log(error)
            
            //test - console.log("request cancelled")

            // alert("Timeout due to slow internet...")

        })
        //setShowWWait(false)
        // }

        

        var streetsReduced = []
        if (streets) {
            streets.data.elements.map((streetFeature, index) => {
                {
                    if (streetFeature.tags.highway != 'residential')
                        if (streetFeature && streetFeature.tags && streetFeature.tags.ref && !streetFeature.tags.ref.includes(";")) { // if it has a ref see if it is in there or needs to be added
                            var streetFeatureReducedRefIndex = streetsReduced.findIndex((streetReduced) => {
                                return streetReduced.ref === streetFeature.tags.ref
                            })
                            if (streetFeatureReducedRefIndex >= 0) {
                                streetsReduced[streetFeatureReducedRefIndex].features.push(streetFeature)
                            }
                            else {
                                const terms = ["US ", "I ", "CA "]
                                const str = streetFeature.tags.ref

                                // check if the string has some of the terms
                                const result1 = terms.some(term => str.includes(term))

                                streetsReduced.push({
                                    name: result1 ? streetFeature.tags.ref : streetFeature.tags.name,
                                    ref: streetFeature.tags.ref,
                                    features: [streetFeature]
                                })
                            }
                        }
                        else if (streetFeature && streetFeature.tags && !streetFeature.tags.ref && streetFeature.tags.name && !streetFeature.tags.name.includes(";")) {
                            var streetFeatureReducedNameIndex = streetsReduced.findIndex((streetReduced) => {
                                return streetReduced.name === streetFeature.tags.name
                            })
                            if (streetFeatureReducedNameIndex >= 0) {
                                streetsReduced[streetFeatureReducedNameIndex].features.push(streetFeature)
                            }
                            else {
                                streetsReduced.push({
                                    name: streetFeature.tags.name,
                                    features: [streetFeature]
                                })
                            }

                        }
                }
            })

        }

        var streetsData = []
        var streetsLineData = []
        var qsElevation = "https://api.open-elevation.com/api/v1/lookup?locations="
        var divisor = parseInt(streetsData.length / 10)
        if (divisor === 0) {
            divisor = 1
        }
        // alert(divisor)
        streetsReduced.map((street, index) => {
            var middle = parseInt(street.features.length / 2)
            var points = street.features[middle].geometry.coordinates
            var totalLength = totalLen(points);
            var midDistance = totalLength / 2;
            var midPoint = getPointByDistance(points, midDistance)
            var prevPoint = 0
            if (points.len > 0) {
                prevPoint = middle - 1
            }



            var angle = Math.atan2(points[0][1] - points[points.length - 1][1], points[0][0] - points[points.length - 1][0]) * 180 / Math.PI
            // if ((angle < -90)) {
            //     angle = 180 + angle
            // }
            // else if ((angle > 90)) {
            //     angle = angle - 180
            // }

            // var zoomBoost = Math.pow((20 - theViewState.zoom), 2) 
            var viewStateZoom
            if (theViewState) {
                viewStateZoom = theViewState.zoom - 16
            }
            else {
                viewStateZoom = 18 // this was causing an error as deck was null so i put this in as a guess
            }
            var addOn = Math.pow((20 - viewStateZoom), groundElevation > 400 ? 3 : 3)
            
        
            if (streetsData.length < (mobileAndTabletCheck() ? 5 : 10)) {
                if (index % divisor === 0) {
                  
                    streetsData.push({
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": [midPoint[0], midPoint[1], mobileAndTabletCheck() ? viewportElevation.elevation + 21 : 21]
                        },
                        "properties": street.features[middle].tags,
                        name: street.name,
                        angle: angle

                    })
                    streetsLineData.push({
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": [midPoint[0], midPoint[1], -30]
                        },
                        elevation: 30 + viewportElevation.elevation + 20,
                        "properties": street.features[middle].tags,
                        name: street.name,
                        angle: angle


                    })
                }
            }

            // street.features.map((streetFeature) => {
            //     
            //     // if ((streetFeature.tags.highway === "motorway") && streetFeature.geometry.type.toLowerCase() === "linestring") {
            //         streetsData.push({
            //             "type": "Feature",
            //             "geometry": {
            //                 type: "Linestring",
            //                 coordinates: streetFeature.geometry.coordinates
            //             },
            //             "properties": streetFeature.tags,
            //             name: streetFeature.tags.name,


            //         })
            //     // }
            // }) // tried to show the motorways, but looked like a project for another day




        })

        streetsOnMap = streetsData
        
        streetsLinesOnMap = streetsLineData
        resolve(true)
    })
}



// extra code for layers we don't use now
//    new TileLayer({
//       // https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#Tile_servers
//        data: [
//           'https://tile.googleapis.com/tile/v1/tiles/{z}/{x}/{y}?session=YOUR_SESSION_TOKEN&key="+ process.env.REACT_APP_GoogleMapsAPIKey +"'
//         //   'https://api.maptiler.com/tiles/satellite-v2/{z}/{x}/{y}.jpg?key=ExHXqMVsdYjA5liY6dpZ',
//         //   'https://b.tile.openstreetmap.org/{z}/{x}/{y}.png',
//         //   'https://c.tile.openstreetmap.org/{z}/{x}/{y}.png'
//       ],

//       // Since these OSM tiles support HTTP/2, we can make many concurrent requests
//       // and we aren't limited by the browser to a certain number per domain.
//       maxRequests: 20,

//       pickable: true,
//       onViewportLoad: null,
//       autoHighlight: showBorder,
//       highlightColor: [60, 60, 60, 40],
//       // https://wiki.openstreetmap.org/wiki/Zoom_levels
//       minZoom: 0,
//       maxZoom: 19,
//       tileSize: 256,
//       zoomOffset: devicePixelRatio === 1 ? -1 : 0,
//       renderSubLayers: props => {
//           const {
//               bbox: { west, south, east, north }
//           } = props.tile;

//           return [
//               new BitmapLayer(props, {
//                   data: null,
//                   image: props.data,
//                   bounds: [west, south, east, north]
//               }),
//               showBorder &&
//               new PathLayer({
//                   id: `${props.id}-border`,
//                   data: [
//                       [
//                           [west, north],
//                           [west, south],
//                           [east, south],
//                           [east, north],
//                           [west, north]
//                       ]
//                   ],
//                   getPath: d => d,
//                   getColor: [255, 0, 0],
//                   widthMinPixels: 4
//               })
//           ];
//       }
//   }),








// new GeoJsonLayer({  // you are here change icon
//     id: 'geojson-SearchedLocations',
//     data: searchedLocations,
//     extensions: mobileAndTabletCheck() ? [new DataFilterExtension({ filterSize: 1 }), new TerrainExtension()] : [],
//     stroked: true,
//     // extruded: ()=> mobileAndTabletCheck(),
//     // wireframe: false,
//     // getElevation: (d) => d.Elevation + d.BuildingHeight / 3.28084,
//     // filled: false,
//     pickable: true,
//     pointType: 'icon+text+circle',
//     iconAtlas: 'https://images.cubicsearch.com/vert-line.png',
//     iconMapping: ICON_MAPPING,
//     getIcon: d => 'marker',
//     getIconSize: 50,
//     getIconColor: [255, 255, 255, 200],
//     // iconSizeScale: .5,
//     // textLineHeight: 1,
//     textMaxWidth: 6,
//     // getElevation: 10,
//     getFillColor: [255, 160, 180, 0],
//     getLineColor: [255, 20, 0, 200],
//     // getLineWidth: 4,
//     // getPosition: [0, 0, 100],
//     getTextBorderColor: d => {

//         return [255, 255, 255, 255]

//     },
//     getTextBackgroundColor: d => {
//         return [0, 0, 0, 255]
//     },
//     getTextBorderWidth: (d) => {
//         return 4


//     },
//     textBackgroundPadding: [4, 4, 4, 4],
//     textFontFamily: "Gill Sans, sans-serif",
//     getTextColor: [255, 255, 255],
//     // getAlignmentBaseline: () => 'bottom',
//     getTextPixelOffset: (d) => {
//         var offsetFootPrint = 0
//         var offsetCampusProperty = 0
//         offsetCampusProperty = offsetCampusProperty - (1.25 * ((d.properties.PropertyName + d.properties.CampusName).length || 0))

//         if (!d.properties.Size || d.properties.Size === 0) {

//             offsetFootPrint = -20 * (1 / (26 - mapRef.current.deck.viewState["default-view"].zoom))
//         }

//         return [0, -40 + offsetFootPrint + offsetCampusProperty]
//     },
//     getTextAnchor: d => 'middle',
//     // getAligmentBaseline: d => "top",
//     lineWidthScale: 10,
//     lineWidthMinPixels: 2,
//     getFillColor: [160, 160, 180, 200],
//     getText: (d) => {

//         return "Search Location"



//     },
//     getTextSize: d => {
//         return 36
//     },
//     textBackground: true,

//     textBillboard: true,
//     // getLineColor: d => colorToRGBArray(d.properties.color),
//     getPointRadius: d => {

//         return 10

//     },
//     // getLineWidth: 1,

//     getLineColor: [255, 20, 0, 200],
//     // getLineWidth: 1,
//     autoHighlight: true,
//     highlightColor: [0, 20, 255, 100],
//     terrainDrawMode: 'offset',
//     onClick: (info) => {

//         // var text = mapRef.current.pickObject({ x: info.pixel[0], y: info.pixel[1], id: ['google-3d-tiles'], unproject3D : true})
//         // var group = mapRef.current.pickMultipleObjects({ x: info.pixel[0], y: info.pixel[1], radius: 400, id: ['geojson-layerText'], unproject3D: true })

//         alert("This is the location you just searched!")




//     },
//     onHover: (info) => {

//         // do something  if you hover
//         // if (info.object) {
//         //     setPopupInfo(info)
//         //     // onLayerClick(info)
//         // }
//     }

// }),






// new MVTLayer({
//     data: `https://api.maptiler.com/tiles/v3/{z}/{x}/{y}.pbf?key=ExHXqMVsdYjA5liY6dpZ`,
//     // loadOptions: {
//     //     mvt: {
//     //         layers: ['streets']
//     //     }
//     // },
//     minZoom: 0,
//     maxZoom: 15,
//     radiusMinPixels: 6,
//     radiusMaxPixels: 10,
//     getPointRadius: 6,
//     getLineColor: [255, 255, 255],
//     getFillColor: [255,255,255,0],


//     // getFillColor: f => {
//     //     return getPoiColor(f.properties.class)
//     // },
//     getLineWidth: 1,
//     lineWidthMinPixels: 1,
//     pickable: true,
// })
// new TileLayer({
//     // https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#Tile_servers
//     data: [
//         'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png',
//         'https://b.tile.openstreetmap.org/{z}/{x}/{y}.png',
//         'https://c.tile.openstreetmap.org/{z}/{x}/{y}.png'
//     ],

//     // Since these OSM tiles support HTTP/2, we can make many concurrent requests
//     // and we aren't limited by the browser to a certain number per domain.
//     maxRequests: 20,

//     pickable: true,
//     onViewportLoad: null,
//     autoHighlight: showBorder,
//     highlightColor: [60, 60, 60, 40],
//     // https://wiki.openstreetmap.org/wiki/Zoom_levels
//     minZoom: 0,
//     maxZoom: 19,
//     tileSize: 256,
//     zoomOffset: devicePixelRatio === 1 ? -1 : 0,
//     renderSubLayers: props => {
//         const {
//             bbox: { west, south, east, north }
//         } = props.tile;

//         return [
//             new BitmapLayer(props, {
//                 data: null,
//                 image: props.data,
//                 bounds: [west, south, east, north]
//             }),
//             showBorder &&
//             new PathLayer({
//                 id: `${props.id}-border`,
//                 data: [
//                     [
//                         [west, north],
//                         [west, south],
//                         [east, south],
//                         [east, north],
//                         [west, north]
//                     ]
//                 ],
//                 getPath: d => d,
//                 getColor: [255, 0, 0],
//                 widthMinPixels: 4
//             })
//         ];
//     }
// })
// new MVTLayer({
//     data: `https://a.tiles.mapbox.com/v4/mapbox.mapbox-streets-v8/{z}/{x}/{y}.vector.pbf?style=mapbox://styles/schordr/cll1tp85100x701p7hm5ldezy@00&access_token=${MAPBOX_TOKEN}`,

//     minZoom: 0,
//     maxZoom: 23,
//     getLineColor: [200, 200, 0],
//     getFillColor: [200, 200, 0, 0],
//     getTextSize: 5,
//     getTextColor: [100,200,100],


//     getLineWidth: f => {
//         switch (f.properties.class) {
//             case 'street':
//                 return 6;
//             case 'motorway':
//                 return 10;
//             default:
//                 return 1;
//         }
//     },
//     lineWidthMinPixels: 1
// })



//more code fore getting into 3d tiles
// function xyzToLatLongHeight(x, y, z) {



//     // Function to convert xyz ECEF to llh
//     // convert cartesian coordinate into geographic coordinate
//     // ellipsoid definition: WGS84
//     // a = 6, 378, 137m
//     // f = 1 / 298.257

//     // Input
//     // x: coordinate X meters
//     // y: coordinate y meters
//     // z: coordinate z meters
//     // Output
//     // lat: latitude rad
//     // lon: longitude rad
//     // h: height meters
//     // '''
//     // # -- - WGS84 constants
//     var a = 6378137.0
//     var f = 1.0 / 298.257223563
//     // # -- - derived constants
//     var b = a - f * a
//     var e = Math.sqrt(Math.pow(a, 2.0) - Math.pow(b, 2.0)) / a
//     var clambda = Math.atan2(y, x)
//     var p = Math.sqrt(Math.pow(x, 2.0) + Math.pow(y, 2))
//     var h_old = 0.0
//     // # first guess with h = 0 meters
//     var theta = Math.atan2(z, p * (1.0 - Math.pow(e, 2.0)))
//     var cs = Math.cos(theta)
//     var sn = Math.sin(theta)
//     var N = Math.pow(a, 2.0) / Math.sqrt(Math.pow(a * cs, 2.0) + Math.pow(b * sn, 2.0))
//     var h = p / cs - N
//     while (Math.abs(h - h_old) > 1.0*Math.pow(e,-6)) {
//         h_old = h
//         theta = Math.atan2(z, p * (1.0 - Math.pow(e, 2.0) * N / (N + h)))
//         cs = Math.cos(theta)
//         sn = Math.sin(theta)
//         N = Math.pow(a, 2.0) / Math.sqrt(Math.pow(a * cs, 2.0) + Math.pow(b * sn, 2.0))
//         h = p / cs - N
//     }

//     var llh = { 'lon': clambda, 'lat': theta, 'height': h }
//     return llh
// }


// new TextLayer({
//     id: 'text-layer',
//     // searchResults,
//     pickable: true,
//     data: getMarkers(searchResults),
//     // iconAtlas and iconMapping are required
//     // getIcon: return a string
//     // iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
//     // iconMapping: ICON_MAPPING,
//     // getIcon: d => 'marker',
//     background: true,
//     backgroundPadding: [4, 4, 4, 4],
//     getBackgroundColor: (d) => {

//         if (d.summary) {


//             if (d.summary.onMarketAvailSF > 0 || d.summary.offMarketAvailSF) { // on market in some way still might have comps off market
//                 if (d.summary.offMarketCompsSF > 0) {
//                     // return "linear-gradient(90deg, red 50%, green 25%)" // off market comps and availables
//                     return [255, 20, 0, 200]
//                 }
//                 else if (d.summary.onMarketCompsSF > 0) {
//                     // return "linear - gradient(90deg, red 50 %, green 50 %)" // off market availables only
//                     return [255, 20, 0, 200]
//                 }
//                 else {
//                     return [255, 20, 0, 200]
//                 }
//             }
//             else { // nothing available
//                 if (d.summary.offMarketCompsSF > 0 || d.summary.onMarketCompsSF) {
//                     return [1, 100, 32, 200]
//                 }
//                 else {
//                     return [0, 0, 0, 100]
//                 }

//             }



//         }
//         else {
//             return [255, 50, 0, 200]
//         }
//     },
//     getBorderColor: (d) => {
//         if (d.summary.offMarketBuilding === "Yes" ||
//             d.summary.offMarketAvailSF > 0 ||
//             d.summary.offMarketCompsSF > 0) {
//             return [255, 255, 0]
//         }
//         else {
//             return [255, 255, 255, 255]
//         }
//     },

//     fontFamily: "Gill Sans, sans-serif",
//     getBorderWidth: (d) => {
//         if (popupInfo && popupInfo.object.id === d.id) { return 3 }
//         else {
//             if (d.summary.offMarketBuilding === "Yes" ||
//                 d.summary.offMarketAvailSF > 0 ||
//                 d.summary.offMarketCompsSF > 0) {
//                 return 3
//             }
//             else {
//                 return 1
//             }
//         }

//     },
//     getSize: (d) => {
//         if (popupInfo && popupInfo.object.id === d.id) { return 24 }
//         else {
//             return 16
//         }
//     },
//     getText: (d) => {

//         if (!showPrice) {
//             if (filters.availablesCheck &&
//                 d.summary.onMarketAvailSF + d.summary.offMarketAvailSF > 0) {
//                 return getAvailSF(d.summary.onMarketAvailSF, d.summary.offMarketAvailSF, d)
//             }
//             else if (filters.compsCheck && (d.summary.onMarketCompsSF + d.summary.offMarketCompsSF > 0)) {
//                 return getCompsSF(d.summary.onMarketCompsSF, d.summary.offMarketCompsSF, d)
//             }
//             else if (d.summary.onMarketCompsSF +
//                 d.summary.offMarketCompsSF +
//                 d.summary.onMarketAvailSF +
//                 d.summary.offMarketAvailSF ===
//                 0) {
//                 return getBuildingSF(d)
//             }




//         }
//         else {
//             if (filters.availablesCheck &&
//                 d.summary.onMarketAvailSF + d.summary.offMarketAvailSF > 0) {
//                 return getAvailPrice(d)
//             }
//             else if (filters.compsCheck && (d.summary.onMarketCompsSF + d.summary.offMarketCompsSF > 0)) {
//                 return getCompsPrice(d)
//             }
//             else if (!(filters.compsCheck &&
//                 filters.availablesCheck &&
//                 d.summary.onMarketCompsSF + d.summary.offMarketCompsSF > 0 &&
//                 d.summary.onMarketAvailSF + d.summary.offMarketAvailSF > 0)) {
//                 if (d.summary.onMarketCompsSF +
//                     d.summary.offMarketCompsSF +
//                     d.summary.onMarketAvailSF +
//                     d.summary.offMarketAvailSF ===
//                     0) { return "n/a" }
//             }





//         }

//     },
//     getAngle: 0,
//     // getTextAnchor: 'bottom',
//     // getAlignmentBaseline: 'center',
//     getPosition: d => [d.Longitude, d.Latitude, d.Elevation +
//         ((!d.NumberFloors || (d.NumberFloors < 1) ? 4 : d.NumberFloors) * 3.5)
//         + (mapRef.current.deck.viewState["default-view"].zoom <= 16 ? 10000 / Math.pow(zoomFactor, 2.5) : 0)],
//     //  getSize: d => 0.01 * Math.pow(mapRef.current.deck.viewState["default-view"].zoom, 2),
//     getColor: d => [255, 255, 255],
//     onClick: onLayerClick,
//     onHover: (info) => {
//         if (info.object) {
//             setPopupInfo(info)
//             // onLayerClick(info)
//         }
//     }
//     // terrainDrawMode: 'offset',
//     // extensions: [new TerrainExtension()]


// }),

// main function




// rail routes to be added another day
// var railwayTypes = "\"railway\"~\"station\""

// if (mapRef.current.deck.viewState["default-view"].zoom <= 12) {
//     railwayTypes = "\"railway\"~\"station\""
// }
// else if (mapRef.current.deck.viewState["default-view"].zoom <= 13) {
//     railwayTypes = "\"railway\"~\"station|tram\""
// }
// else {
//     railwayTypes = "\"railway\"~\"station|tram|stop\""
// }
// var sampleScreenRail = "https://www.overpass-api.de/api/interpreter?" +
//     "data=[out:json];node[" + railwayTypes + "][name]"
//     + nodestring + encodeURI(";convert item ::=::,::geom=geom(),_osm_type=type();") + "out%20geom; "





// //test - console.log(sampleScreenRail)
// var railway = await axios.get(sampleScreenRail).catch((error) => {
//     console.log(error)

// })


// var railwayReduced = []

// railway.data.elements.map((railwayFeature, index) => {

//     if (railwayFeature && railwayFeature.tags && railwayFeature.tags.name) {
//         var railwayFeatureReducedNameIndex = railwayReduced.findIndex((railwayReduced) => {
//             return railwayReduced.name === railwayFeature.tags.name
//         })
//         if (railwayFeatureReducedNameIndex >= 0) {
//             railwayReduced[railwayFeatureReducedNameIndex].features.push(railwayFeature)
//         }
//         else {
//             railwayReduced.push({
//                 name: railwayFeature.tags.name,
//                 features: [railwayFeature]
//             })
//         }

//     }
// })

// var routeTypes = "\"route\"~\"train|ferry\""

// if (mapRef.current.deck.viewState["default-view"].zoom <= 12) {
//     routeTypes = "\"route\"~\"train|ferry\""
// }
// else if (mapRef.current.deck.viewState["default-view"].zoom <= 13) {
//     routeTypes = "\"route\"~\"train||ferry|subway\""
// }
// else {
//     routeTypes = "\"route\"~\"train||ferry|subway|monorail|tram|bus|trolleybus /\""
// }
// var sampleScreenRail = "https://www.overpass-api.de/api/interpreter?" +
//     "data=[out:json];rel[" + routeTypes + "][name]"
//     + {nodestring + encodeURI(";convert item ::=::,::geom=geom(),_osm_type=type();") + "out%20geom; "





// //test - console.log(sampleScreenRail)
// var route = await axios.get(sampleScreenRail).catch((error) => {
//     console.log(error)

// })


// var routeReduced = []

// route.data.elements.map((routeFeature, index) => {

//     if (routeFeature && routeFeature.tags && routeFeature.tags.network && !routeFeature.tags.network.includes(":")) {
//         var routeFeatureReducedNameIndex = routeReduced.findIndex((routeReduced) => {
//             return routeReduced.name === routeFeature.tags.network
//         })
//         if (routeFeatureReducedNameIndex >= 0) {
//             routeReduced[routeFeatureReducedNameIndex].features.push(routeFeature)
//         }
//         else {
//             routeReduced.push({
//                 name: routeFeature.tags.network,
//                 features: [routeFeature]
//             })
//         }

//     }
// })

//bounding volume attempts at getting height
// const [mapTiles, setMapTiles] = useState(null)
// var latlngElevation = []
// const recursiveAdd = (tileIn) => {


//     if (tileIn.children > 1) {
//         tileIn.children.map((tileChild) => {
//             recursiveAdd(tileChild)
//         })
//     }
//     else if (tileIn.children.length === 1) {
//         //test - console.log("testing")
//         //test - console.log(tileIn)
//         var latlngElevationArray = projector.unproject(tileIn.boundingVolume.center[0], tileIn.boundingVolume.center[1], tileIn.boundingVolume.center[2])
//         if (latlngElevationArray[2] > 0) {
//             latlngElevation.push([latlngElevationArray[0], latlngElevationArray[1], latlngElevationArray[2], Math.abs(tileIn.boundingVolume.halfAxes[8]), tileIn.boundingVolume.halfSize[0] * tileIn.boundingVolume.halfSize[1]*4])
//             //test - console.log(latlngElevation)
//         }
//     }
//     else {

//     }
// }


// code with 2 maps


// if (deckShow && false) {

//     return (
//         <>


//             <div id='deck'>
//                 <DeckGL

//                     ref={mapRef ? mapRef : null}
//                     style={{ backgroundColor: '#061714' }}
//                     initialViewState={initialViewState}
//                     controller={{ touchRotate: true, inertia: false }}

//                     layers={layersDeckGL}
//                     onLoad={onMapLoadDeckGl}

//                     effects={[lightingEffect]}
//                     // onClick={clickHandle}

//                     onViewStateChange={({ viewState, interactionState, oldViewState }) => {

//                         console.log("DeckGL on view state change")

//                         const [lng, lat] = [viewState.longitude, viewState.latitude];
//                         const zoom = viewState.zoom;


//                         var currentState = getCurrentViewState()

//                         if (currentState && currentState.zoom > 13) {
//                             if (!deckShow) {

//                                 console.log("Deck Show true")


//                                 var viewStateCookie = { ViewState: currentState, searchResults: searchResults, filters: filters, URL: window.location.href }

//                                 console.log(" regular Decku JS zoom over 13")
//                                 console.log(currentState.zoom)
//                                 var postResult = axios.post(
//                                     process.env.REACT_APP_DATABASE +
//                                     "api/createViewStates", {
//                                     userId: contactId,
//                                     ViewState: viewStateCookie,


//                                 }
//                                 ).catch((error) => {
//                                     console.log(error)
//                                 })

//                                 setDeckShow(true)

//                                 return (<></>)
//                             }
//                         }
//                         else if (interactionState.inTransition) {             // }


//                             controllerEurope.abort()

//                             controllerNA.abort()
//                             controllerAll.abort()
//                             //test - console.log("pan")
//                             controllerCities.abort()
//                             viewStates.push(1)
//                             // setAbort(false)
//                             // setTimeout(mapReload, 500)



//                         }
//                         else {
//                             if (deckShow && currentState) {
//                                 console.log("Deck Show false")

//                                 var viewStateCookie = { ViewState: currentState, searchResults: searchResults, filters: filters, URL: window.location.href }

//                                 console.log(" regular Decku JS zoom under 13")

//                                 console.log(currentState.zoom)
//                                 var postResult = axios.post(
//                                     process.env.REACT_APP_DATABASE +
//                                     "api/createViewStates", {
//                                     userId: contactId,
//                                     ViewState: viewStateCookie,


//                                 }
//                                 ).catch((error) => {
//                                     console.log(error)
//                                 })
//                                 setInitialViewState(currentState)

//                                 setDeckShow(false)

//                                 return (<></>)
//                             }
//                             if (!viewState || viewState.zoom < 13) {
//                                 console.log("setting low zoom")
//                                 setInitialViewState(viewState)
//                                 return false
//                             }
//                             if (interactionState.isZooming) { // made for phone zooming
//                                 clearInterval(timerRef.current);
//                                 timerRef.current = setTimeout(async () => {
//                                     viewStates.push(1)

//                                     await mapReload().catch((error) => {
//                                         console.log(error)
//                                     })

//                                 }, 100);
//                             }
//                         }



//                         // //test - console.log("setCurrentViewState onViewStateChange") // we don't want to do this for the first one
//                         // setCurrentViewState(viewState)

//                         if (!abort) {
//                             //test - console.log("aborting")
//                             var abortAnswer = controller.abort()

//                             setAbort(true)
//                         }
//                         else {
//                             // //test - console.log("not abort")
//                         }
//                         // if ((interactionState.isZooming && !interactionState.inTransition) || superMapRef) {
//                         //     controllerEurope.abort()
//                         //     controllerNA.abort()
//                         //     controllerAll.abort()
//                         //     if (showWait) {
//                         //         setShowWait(false)
//                         //     }
//                         //     //test - console.log("zoom")
//                         //     setSuperMapRef(false)
//                         //     if (!superMapRef) {
//                         //         viewStates.push(1)
//                         //     }
//                         //     // if(abort){
//                         //     //     setAbort(false)
//                         //     // }

//                         //     if (viewState.zoom > 12) {
//                         //         setTimeout(mapReload, 500)
//                         //     }





//                     }
//                     }

//                     onDrag={
//                         () => {


//                             setViewStates([])
//                             // //test - console.log("check abort")
//                             if (!abort) {
//                                 //test - console.log("I'm in")
//                                 setAbort(true)
//                             }
//                             if (showWait) {
//                                 setShowWait(false)
//                             }
//                             // //test - console.log("Trying to cancel!!")

//                             var abortAnswer = controller.abort()
//                             controllerEurope.abort()
//                             controllerNA.abort()
//                             controllerAll.abort()




//                         }
//                     }


//                     onDragEnd={

//                         async () => {


//                             console.log("mapbox onDrag end")
//                             var currentViewState = getCurrentViewState()
//                             var viewStateCookie = { ViewState: currentViewState, searchResults: searchResults, filters: filters, URL: window.location.href }

//                             console.log(" mapbox onDragEnd")
//                             var postResult = axios.post(
//                                 process.env.REACT_APP_DATABASE +
//                                 "api/createViewStates", {
//                                 userId: contactId,
//                                 ViewState: viewStateCookie,


//                             }
//                             ).catch((error) => {
//                                 console.log(error)
//                             })

//                             if (reloadOnMove) {
//                                 //test - console.log("drage call")


//                                 viewStates.push(1)
//                                 // setTimeout(mapReload, 500)
//                                 await mapReload().catch((error) => {
//                                     console.log(error)
//                                 })
//                             }
//                         }


//                     }

//                 />




//                 {(mobileAndTabletCheck()) ?
//                     <SwipeableTemporaryDrawer open={drawerOpen} setPopupInfo={setPopupInfo} setOpen={setDrawerOpen} searchResults={searchResults} setSearchResults={setSearchResults} cardProps={popupInfo ? popupInfo.object : null} />
//                     :
//                     <></>}
//                 {/* this is the pop up we took out we need to make a better one*/}
//                 <div
//                     style={{ position: 'absolute', left: '8px', bottom: '4px', color: 'white', fontSize: '10px' }}
//                 >
//                     {credits}
//                 </div>
//                 <div>
//                     {



//                     }

//                 </div>
//             </div>
//             <TestAlert
//                 params={params}
//                 setParams={setParams}
//             />
//         </>
//     );
// }
// else {


// const layersDeckMap = [

//     flagLayer,
// new GeoJsonLayer({ // availables and building
//     id: 'geojson-layerText',
//     data: coordsLines,

//     stroked: true,

//     pickable: true,
//     pointType: 'icon+text+circle',
//     iconAtlas: 'https://images.cubicsearch.com/vert-line.png',
//     iconMapping: ICON_MAPPING,
//     getIcon: d => 'marker',
//     getIconSize: 50,
//     getIconColor: [255, 255, 255],

//     textMaxWidth: 6,

//     getFillColor: [255, 160, 180],
//     getLineColor: [255, 20, 0],


//     getTextBorderColor: d => {
//         if (popupInfo && popupInfo.object.id === d.id) {
//             return [0, 0, 0]
//         } else {
//             if (d.properties.summary.offMarketBuilding === "Yes" ||
//                 d.properties.summary.offMarketAvailSF > 0 ||
//                 d.properties.summary.offMarketCompsSF > 0) {
//                 return [255, 255, 0]
//             }
//             else {
//                 return [255, 255, 255]
//             }
//         }
//     },
//     getTextBackgroundColor: d => {
//         if (popupInfo && popupInfo.object.id === d.id) {
//             return [255, 255, 255]
//         }
//         else {

//             if (d.properties.summary.onMarketAvailSF > 0 || d.properties.summary.offMarketAvailSF > 0) { // on market in some way still might have comps off market
//                 if (d.properties.summary.offMarketCompsSF > 0) {
//                     // return "linear-gradient(90deg, red 50%, green 25%)" // off market comps and availables
//                     return [255, 20, 0]
//                 }
//                 else if (d.properties.summary.onMarketCompsSF > 0) {
//                     // return "linear - gradient(90deg, red 50 %, green 50 %)" // off market availables only
//                     return [255, 20, 0]
//                 }
//                 else {
//                     return [255, 20, 0]
//                 }
//             }
//             else { // nothing available
//                 if (d.properties.summary.offMarketCompsSF > 0 || d.properties.summary.onMarketCompsSF > 0) {
//                     return [1, 100, 32]
//                 }
//                 else {
//                     return [0, 0, 0]
//                 }

//             }
//         }
//     },
//     getTextBorderWidth: (d) => {
//         if (popupInfo && popupInfo.object.id === d.id) {
//             return 1
//         } else {
//             if (popupInfo && popupInfo.object.id === d.id) { return 3 }
//             else {
//                 if (d.properties.summary.offMarketBuilding === "Yes" ||
//                     d.properties.summary.offMarketAvailSF > 0 ||
//                     d.properties.summary.offMarketCompsSF > 0) {
//                     return 3
//                 }
//                 else {
//                     return 1
//                 }
//             }
//         }

//     },
//     textBackgroundPadding: [4, 4, 4, 4],
//     textFontFamily: "Gill Sans, sans-serif",
//     getTextColor: d => {
//         if (popupInfo && popupInfo.object.id === d.id) {
//             return [0, 0, 0]
//         }
//         else {
//             return [255, 255, 255]

//         }
//     },
//     // getAlignmentBaseline: () => 'bottom',
//     getTextPixelOffset: (d) => {
//         var offsetFootPrint = 0
//         var offsetCampusProperty = 0
//         offsetCampusProperty = offsetCampusProperty - (1.25 * ((d.properties.PropertyName + d.properties.CampusName).length || 0))

//         if (!d.properties.Size || d.properties.Size === 0) {

//             offsetFootPrint = -20 * (1 / (26 - (initialViewState && initialViewState.zoom ? initialViewState.zoom : 0)))
//         }

//         return [0, -40 + offsetFootPrint + offsetCampusProperty]
//     },
//     getTextAnchor: d => 'middle',
//     // getAligmentBaseline: d => "top",
//     lineWidthScale: 10,
//     lineWidthMinPixels: 2,
//     getFillColor: [160, 160, 180],
//     getText: (d) => {
//         var infoToShow = ""

//         var priceShow = ""

//         // else {
//         if (
//             d.properties.summary.onMarketAvailSF + d.properties.summary.offMarketAvailSF > 0) {
//             priceShow = getAvailPrice(d)
//             if (priceShow.length > 0) {
//                 priceShow = priceShow + "/SF/Mo"
//             }
//         }
//         else if (filters.compsCheck && (d.properties.summary.onMarketCompsSF + d.properties.summary.offMarketCompsSF > 0)) {

//             priceShow = getCompsPrice(d)

//             if (priceShow.length > 0) {
//                 priceShow = priceShow + "/SF/Mo"

//             }
//         }
//         else if (!(filters.compsCheck &&
//             filters.availablesCheck &&
//             d.properties.summary.onMarketCompsSF + d.properties.summary.offMarketCompsSF > 0 &&
//             d.properties.summary.onMarketAvailSF + d.properties.summary.offMarketAvailSF > 0)) {
//             if (d.properties.summary.onMarketCompsSF +
//                 d.properties.summary.offMarketCompsSF +
//                 d.properties.summary.onMarketAvailSF +
//                 d.properties.summary.offMarketAvailSF ===
//                 0) { priceShow = "" }
//         }

//         // }

//         if (d.properties.PropertyName && d.properties.PropertyName !== "null") {
//             infoToShow = d.properties.PropertyName + " "
//         }
//         if (d.properties.CampusName && d.properties.CampusName !== "null") {
//             infoToShow = d.properties.CampusName + " " + infoToShow
//         }

//         // if (!showPrice && ((d.properties.summary.onMarketCompsSF + d.properties.summary.offMarketCompsSF) === 0)) {

//         if (filters.availablesCheck &&
//             d.properties.summary.onMarketAvailSF + d.properties.summary.offMarketAvailSF > 0) {
//             return priceShow + " " + infoToShow + getAvailSF(d.properties.summary.onMarketAvailSF, d.properties.summary.offMarketAvailSF, d)
//         }
//         else if (filters.compsCheck && (d.properties.summary.onMarketCompsSF + d.properties.summary.offMarketCompsSF > 0)) {
//             return priceShow + " " + infoToShow + getCompsSF(d.properties.summary.onMarketCompsSF, d.properties.summary.offMarketCompsSF, d)
//         }
//         else if (d.properties.summary.onMarketCompsSF +
//             d.properties.summary.offMarketCompsSF +
//             d.properties.summary.onMarketAvailSF +
//             d.properties.summary.offMarketAvailSF ===
//             0) {

//             if (d.properties.Size > 0) {
//                 return priceShow + " " + infoToShow + " " + getBuildingSF(d.properties)
//             }
//             else {

//                 return priceShow + " " + infoToShow + " " + Math.round(d.properties.Footprint / 1000) + "K SF Footprint";
//             }
//         }
//         else {
//             if (d.properties.Size > 0) {
//                 return priceShow + " " + infoToShow + " " + getBuildingSF(d.properties)
//             }
//             else {

//                 return priceShow + " " + infoToShow + " " + Math.round((d.properties.summary.offMarketCompsSF +
//                     d.properties.summary.onMarketAvailSF +
//                     d.properties.summary.offMarketAvailSF) / 1000) + "K SF";
//             }
//         }




//         // }




//     },
//     getTextSize: d => {
//         if (d.Elevation <= 500) {
//             if (popupInfo && popupInfo.object.id === d.id) {
//                 return 20
//             }
//             else {
//                 return 16

//             }
//         }
//         else {
//             if (popupInfo && popupInfo.object.id === d.id) {
//                 return 14
//             }
//             else {
//                 return 12

//             }
//         }

//     },
//     textBackground: true,

//     textBillboard: true,
//     // getLineColor: d => colorToRGBArray(d.properties.color),
//     getPointRadius: d => {
//         if (popupInfo && popupInfo.object.id === d.id) {
//             return 10
//         }
//         else {
//             return 3

//         }
//     },
//     // getLineWidth: 1,

//     getLineColor: [255, 20, 0],
//     // getLineWidth: 1,
//     // autoHighlight: true,
//     // highlightColor: [255, 0, 0],
//     terrainDrawMode: 'offset',
//     onClick: (info) => {

//         // var text = mapRef.current.pickObject({ x: info.pixel[0], y: info.pixel[1], id: ['google-3d-tiles'], unproject3D : true})
//         // var group = mapRef.current.pickMultipleObjects({ x: info.pixel[0], y: info.pixel[1], radius: 400, id: ['geojson-layerText'], unproject3D: true })

//         // alert(text.coordinate[2] / 4.85)
//         // info.stopPropagation()
//         if (window.innerWidth > 700) {

//             onLayerClick(info)
//             setCoords(JSON.parse(JSON.stringify(coords)))

//         }



//     },
//     onHover: (info) => {
//         if (info.object) {
//             setPopupInfo(info)
//             // onLayerClick(info)
//         }
//     }
//     // onClick: (info, event) => {

//     //     var hoverInfo = {
//     //         parcelProperties: info.object.properties,
//     //         center: center(info.object.geometry.type === "MultiPolygon" ? info.object.geometry.coordinates[0] : info.object.geometry.coordinates),
//     //         latitude: info.coordinate[1],
//     //         longitude: info.coordinate[0]
//     //     }
//     //     setParcelInfo(hoverInfo)
//     //     setShowParcel(true)

//     // }
//     // extensions: [new TerrainExtension()]

// }),



// ]


// "? " +
// "contactId=" +
// contactId +
// "&availablesCheck=" + filters.availablesCheck + "&compsCheck=" + filters.compsCheck +

// "&viewPort=" +
// encodeURIComponent(JSON.stringify(myViewport));


// const fetchLightboxStrucutres = async (abortController) => {
//     const config = {
//         headers: {
//             accept: 'application/json',
//             'x-api-key': 'NuGAGgdu67i9iuW5pYMlyjqHQJG0yrVq'
//         },

//         signal: abortController.signal

//     };

//     var qs = 'https://api.lightboxre.com/v1/structures/us/geometry?wkt=POLYGON%20%28%28' +
//         longitude1 + "%20" +
//         latitude1 + "%2C" +
//         longitude2 + "%20" +
//         latitude1 + "%2C" +
//         longitude2 + "%20" +
//         latitude2 + "%2C" +
//         longitude1 + "%20" +
//         latitude2 + "%2C" +
//         longitude1 + "%20" +
//         latitude1 +
//         '%29%29&bufferDistance=100&bufferUnit=m'


//     const res = await axios.get(
//         qs,
//         config
//     ).catch((error) => {
//         console.log(error)
//     })
//     if (res) {
//         return res.data;
//     }
// };

// const polyLayer = new ScenegraphLayer({
//     id: 'geojson-billboards-solid',
//     data: coords,
//     extensions: mobileAndTabletCheck() ? [] : [new DataFilterExtension({ filterSize: 1 }), new TerrainExtension()],
//     terrainDrawMode: mobileAndTabletCheck() ? null : 'offset',
//     // stroked: true,
//     pointType: 'icon+text',

//     scenegraph: "https://images.cubicsearch.com/markers/lab.glb",

//     getPosition: d => {

//         return [d.geometry.coordinates[0], d.geometry.coordinates[1], d.geometry.coordinates[2] - 20]
//     },
//     getOrientation: d => [0, 0, 90],

//     sizeScale: 100,
//     _lighting: 'flat',
//     // getScale: d => {

//     //     var scale = sizeThresholds(d, camElevation, distanceFromGround)
//     //     return [scale, scale, scale]
//     // },

//     pickable: true,
//     sizeMinPixels: 70,
//     sizeMaxPixels: 1000,
//     pickable: true,
//     onClick: (info) => {



//         if (window.innerWidth > 700) {

//             onLayerClick(info)
//             setCoords(JSON.parse(JSON.stringify(coords)))
//             setCoordsCircles(JSON.parse(JSON.stringify(coordsCircles)))

//         }
//         else {

//             setCoords(JSON.parse(JSON.stringify(coords)))
//             setCoordsCircles(JSON.parse(JSON.stringify(coordsCircles)))

//             onLayerClick(info)

//             setPopupInfo(info)
//         }



//     },




// })

// async function onMapLoad() {

//     console.log("map load " + new Date().getSeconds() )



//     // setBoundsRef(
//     //     encodeURIComponent(JSON.stringify(myViewport))
//     // );
//     // async function getInitialState() {

//     var goToMeInner = getCookie("SearchMe")

//     if (goToMeInner === 'true') {
//         createCookie("SearchMe", false, 1)
//         var coordinate = [geometry.location.lng, geometry.location.lat]
//         var elevationData = await axios.get(process.env.REACT_APP_DATABASE +
//             "api/getElevationLocal?latitude=" + (coordinate[1] +
//                 "&longitude=" + coordinate[0]))
//         coordinate.push(elevationData.data.elevation)
//         viewStates.push(1)

//         setSearchedLocations([
//             {
//                 "type": "Feature",
//                 "properties": {
//                 },
//                 "geometry": {
//                     "type": "Point",
//                     "coordinates": coordinate
//                 },
//             }])
//         refreshMapProperties(searchResults, setCoords, setCoordsCircles, setCoordsLines, elevationFactorState, groundElevation, viewportElevation, mapRef)
//         mapReload()

//     }
//     else {
//         var rawCookie = await axios.get(
//             process.env.REACT_APP_DATABASE +
//             "api/getViewStates?userId=" +
//             contactId
//         ).catch((error) => {
//             console.log(error)
//         })
//         //test - console.log("we have view states")
//         if (!rawCookie || !rawCookie.data) {
//             mapReload()
//             viewStateCookie = []
//         }
//         {
//             viewStateCookie = rawCookie.data
//         }



//         if (viewStateCookie && (viewStateCookie.viewStates.length > 0) &&
//             window.location.href === viewStateCookie.viewStates[0].ViewState.URL) {

//             myViewport = viewStateCookie.viewStates[0].ViewState.ViewState
//             // var newSearchResults = viewStateCookie.viewStates[0].ViewState.searchResults
//             // setSearchResults(newSearchResults)



//             if (myViewport) {

//                 setInitialViewState(myViewport)
//                 var groundElevation = getgroundElevation(myViewport)
//                 var viewportElevation = await getViewportElevation(mapRef)
//                 console.log("refresh in map load" + new Date().getSeconds())
//                 // refreshMapProperties(searchResults, setCoords, setCoordsCircles, setCoordsLines, elevationFactorState, groundElevation, viewportElevation)
//                 // recalcLayers()
//             }
//         }


//         // }

//         var boundsViewport = new WebMercatorViewport(initialViewState)
//         refreshMapProperties(searchResults, setCoords, setCoordsCircles, setCoordsLines, elevationFactorState, groundElevation, viewportElevation)
//         recalcLayers()

//     }
// }


                    // }



                    // else {



                    //     var viewport = getMyViewport()
                    //     filters.viewport = viewport
                    //     filters.idArray = idArray
                    //     filters.contactId = contactId
                    //     var qs =
                    //         process.env.REACT_APP_DATABASE +
                    //         "api/buildingcampus"

                    //     var cubicBuildingResultsData = await postAxios(qs, filters, newController) // we get our buildingss

                    //         .catch((err) => {
                    //             //test - console.log("cubic request aborted")
                    //             console.log(err)
                    //             // setWaitMessage("loading cubic properties for map move")
                    //             return (false)

                    //         });

                    //     // cubicBuildingResultsData.data = filter(cubicBuildingResultsData.data)
                    //     
                    //     if (cubicBuildingResultsData && cubicBuildingResultsData.data) {
                    //         cubicBuildingResultsData.data.sort((a, b) => { return (b.Latitude - a.Latitude) })
                    //         setSearchResults(cubicBuildingResultsData.data)
                    //         refreshMapProperties(cubicBuildingResultsData.data, elevationFactorState, viewportElevation, viewportElevation, filters)
                    //         overlay.setProps({ layers: recalcLayers() })
                    //     }




                    //     if (mapRef.current && mapRef.current.getZoom) {

                    //         var theViewState = getCurrentViewState()


                    //         var viewStateCookie = { ViewState: theViewState, searchResults: newSearchResults, filters: filters, URL: window.location.href }

                    //         //test - console.log("IS THIS GETTING IN?")


                    //         var postResult = axios.post(
                    //             process.env.REACT_APP_DATABASE +
                    //             "api/createViewStates", {
                    //             userId: contactId,
                    //             ViewState: viewStateCookie,


                    //         }
                    //         ).catch((error) => {
                    //             console.log(error)
                    //         })

                    //     }


                    //     resolve(true)
                    //     //setShowWait(false)
                    //     return (true)

                    // }

                    // if (openAlert) { // snackbar go away
                    //     setOpenAlert(false)
                    // }

                
// else {

//     if (mapRef.current && mapRef.current.getZoom && theViewState) {






//         var viewStateCookie = { ViewState: theViewState, searchResults: searchResults, filters: filters, URL: window.location.href }

//         //test - console.log("IS THIS GETTING IN?")
//         if (reloadOnMove) {
//             setWaitMessage("saving view")
//         }

//         var postResult = axios.post(
//             process.env.REACT_APP_DATABASE +
//             "api/createViewStates", {
//             userId: contactId,
//             ViewState: viewStateCookie,


//         }
//         ).catch((error) => {
//             console.log(error)
//         })
//         if (reloadOnMove) {
//             setWaitMessage("view saved")
//         }
//     }

//     else if (mapRef.current && mapRef.current.deck) {
//         var viewStateCookie = { ViewState: mapRef.current.deck.viewState["default-view"], searchResults: newSearchResults, filters: filters, URL: window.location.href }

//         //test - console.log("IS THIS GETTING IN?")
//         if (reloadOnMove) {
//             setWaitMessage("saving view")
//         }

//         var postResult = axios.post(
//             process.env.REACT_APP_DATABASE +
//             "api/createViewStates", {
//             userId: contactId,
//             ViewState: viewStateCookie,


//         }
//         ).catch((error) => {
//             console.log(error)
//         })
//         if (reloadOnMove) {
//             setWaitMessage("view saved")
//         }
//     }
// }

// function PopUp(popupInfo, setPopupInfo, setSearchResults, searchResults) {

//     if (popupInfo) {
//         return (<>
//             {/* // <Popup
//     //     longitude={Number(popupInfo.Longitude)}
//     //     latitude={Number(popupInfo.Latitude)}
//     //     onClose={() => setPopupInfo(null)}
//     //     maxWidth="none"
//     //     style={{ width: "300px" }}
//     //     offset={30}

//     //className="border-2 w-80 object-cover"
//     // onClick={()=>{window.open("contact?id="+popupInfo.id)}}
//     >
//     */}

//             <div >
//                 <div className="flex w-full py-2 flex-col z-101">
//                     <div className="flex justify-between py-0">
//                         {/* <button
//                         className="w-8 h-8 border-transparent outline-none flex content-center"
//                         onClick={(event, info) => {

//                             setPopupInfo(null)

//                         }}
//                     >
//                         <XIcon className="w-7 h-7 pb-2" />
//                         Close
//                     </button> */}
//                         <button
//                             data-tip
//                             data-for="removeTip"
//                             className="w-7 h-7 border-transparent outline-none flex content-center"
//                             onClick={(event, info) => {

//                                 removeById(searchResults, popupInfo.id);

//                                 setSearchResults(searchResults);
//                             }}
//                         >
//                             <MinusCircleIcon className="w-7 h-7 pb-2" />
//                             Remove
//                         </button>

//                         {/* <MinusCircleIcon
//                         data-tip
//                         data-for="removeTip"
//                         className="h-7 z-100 cursor-pointer outline-none"
//                         onClick={() => {
//                             removeById(searchResults, popupInfo.id);

//                             setSearchResults(searchResults);
//                             // setPopupInfo(null);
//                         }}
//                     >Remove</MinusCircleIcon>{" "} */}
//                     </div>
//                 </div>
//                 <div className='flex justify-center' style={{ overflowX: "hidden" }}>

//                     <PhoneDemoCarousel
//                         key={popupInfo.id}
//                         images={popupInfo.images}
//                         goToURL={"property?id=" + popupInfo.id}
//                         property={popupInfo}
//                     />
//                     {/* <img
//                     src={
//                         popupInfo && popupInfo.images && popupInfo.images.length > 0 && popupInfo.images[0]
//                             ? ConvertImagePath(popupInfo.images[0].img)
//                             : popupInfo.Latitude
//                                 ? "https://dev.virtualearth.net/REST/V1/Imagery/Map/Birdseye/" +
//                                 popupInfo.Latitude.toString().trim() +
//                                 "," +
//                                 popupInfo.Longitude.toString().trim() +
//                                 "/20?dir=270&ms=900,700&key=AntwMqF4SuxAC8AfmB9-AuOVzrtOhbiNMZFVVe68dkbt5IjzUIzmWS8dfFmo9-IL"
//                                 : "https://images.cubicsearch.com/3dgifmaker05280.gif"
//                     }
//                     className="cursor-pointer  "
//                     style={{ height: "200px" }}
//                     onClick={() => {
//                         window.open("property?id=" + popupInfo.id);
//                     }}
//                     onError={({ currentTarget }) => {
//                         currentTarget.onerror = null; // prevents looping
//                         currentTarget.src =
//                             "https://images.cubicsearch.com/small%20logo%20with%20space.png";
//                     }}
//                 /> */}

//                 </div>
//                 <div className="w-full">
//                     <div>
//                         <p className="text-left text-md font-semibold">
//                             {popupInfo && popupInfo.properties && popupInfo.properties.CampusName ? popupInfo.properties.CampusName : ""}
//                         </p>
//                         <p className="text-left text-md font-semibold">
//                             {popupInfo && popupInfo.properties && popupInfo.properties.PropertyName ? popupInfo.properties.PropertyName : ""}
//                         </p>
//                     </div>
//                     <div className="w-full h-full">
//                         {popupInfo && popupInfo.properties && popupInfo.properties.StreetNumber || ""}  {popupInfo && popupInfo.properties && popupInfo.properties.StreetName ? popupInfo.properties.StreetName + " | " : ""}
//                         {(popupInfo && popupInfo.properties && popupInfo.properties.City || "")} {(popupInfo && popupInfo.properties && popupInfo.properties.State || "")}
//                     </div>
//                     <div
//                         className='border-b'
//                         style={{
//                             overflow: 'auto',
//                             maxHeight: '100px',


//                         }
//                         }
//                     >{showData(popupInfo)}</div>
//                 </div>


//             </div>
//         </>
//         )
//         {/* // </Popup> */ }
//     }
// }