import  React from "react";
//import Property from "../components/Building/Property";
//import BingMapsReact from "bingmaps-react";
import {useSearchParams} from "react-router-dom"
import {useQuery} from 'react-query'
import SurveyDetail from "../components/Surveys/SurveyDetail";
import Header from "../components/Common/Header";

export default function Survey({user, guest}) {
 
  let [query] = useSearchParams();

  let id = query.get("id")
 
 
  var qs;

  var qsInvitedTo  = process.env.REACT_APP_DATABASE + "api/getGroupsInvitedTo?id=" + user.contact.id

  qs = process.env.REACT_APP_DATABASE + "api/getSurvey?id=" + id + "&contactId=" + user.contact.id
 //test - console.log(qs)
  const fetchData = async () => {
    const result = await fetch(qs)
    return  await result.json()
}

const fetchGroupsInvitedToData = async () => {
  const result = await fetch(qsInvitedTo)
  return  await result.json()
}





  const { data:surveyData, status:statusSurveyData, error:errorSurveyData } = useQuery('surveyData', fetchData)
  
 

  const { data:groupsInvitedToData, status:statusGroupsInvitedToData, error:errorGroupsInvitedToData } = useQuery('groupsInvitedToData', fetchGroupsInvitedToData)




  if (statusSurveyData === "loading") {
    
    return (
      <>
        <div className="absolute top-1/3 w-full items-center flex flex-col">
          <img style={{width: 100}} alt={"loading..."} src="https://images.cubicsearch.com/Wait.gif" />
          <div>Loading Survey Data...</div>
        </div>
      </>
    );

  }
  

  
  if (statusSurveyData === "error" ) {
    
    return("Survey Error:" + errorSurveyData?.message )

  }

  
  
  if (!surveyData || !surveyData.survey ) {
    return (
      
        <div>
          <div>
            <Header user={user}/>
          </div>
          <div className = "py-4">
          <img src="https://images.cubicsearch.com/noresults.png" alt={"no results..."} className="w-full"/>
          </div>
        </div>
      
    )
  }
  if (statusGroupsInvitedToData === "loading") {
      return (
    <>
        <div className="absolute top-1/3 w-full items-center flex flex-col">
            <img style={{ width: 100 }} alt={"loading..."} src="https://images.cubicsearch.com/Wait.gif" />
          <div>Loading Group Data...</div>
        </div>
      </>
      )

  }

  if (statusGroupsInvitedToData === "error") {
    return( "Groups Invite To Error:" + errorGroupsInvitedToData.message)

  }
  if (!groupsInvitedToData) {
    return ("No results")
  }

   


        //const {Base64} = require('js-base64');
        //var myData = Base64.decode(data.substring(5,data.length));  
        //const surveyData = JSON.parse(myData);
        // const pushPin = {
        //   center: {
        //     latitude: surveyData.Survey.building? surveyData.Survey.building.Latitude : 37.468319,
        //     longitude: surveyData.Survey.building? surveyData.Survey.building.Longitude : -122.143936,
        //   },
        //   options: {
        //     title: surveyData.Survey.building? surveyData.Survey.building.StreetNumber + " " + surveyData.Survey.building.StreetName + ", " + surveyData.Survey.building.City: "Address Info",
        //   },
        // }
        // const pushPins = [pushPin];
    

        return <div>
         
          <div>
            <SurveyDetail PropertyInformation={surveyData}  user = {user} groupsCombo = {groupsInvitedToData} contactId = {user.contact.id}/>
          </div>
        
           <div  className = "px-6 py-4">
    
                {/* {BingMap(surveyData.building.Latitude, surveyData.building.Longitude, pushPins)}  */}
             
          </div> 
          </div>

    }
    

 function getContacts(data) {
    var peopleArray = []
    //   const data = await searchResults.json();
     for (var person of data.contact) {
         var myJsonToAdd = {}
         if (person.images.length >0 ) 
         {
             if(person.office && person.office.company){
            myJsonToAdd = {"id": person.id,
                        "name" : person.FirstName + " " + person.LastName + " - " + person.office.company.Name,
                        "imageURL" : person.images[0].img
                
                        
                        }
                    }
                    else {
                        myJsonToAdd = {"id": person.id,
                        "name" : person.FirstName + " " + person.LastName,
                        "imageURL" : person.images[0].img
                
                        
                        }
                    }
                    
        }
        else 
        {
            if (person.office && person.office.company){
                
            myJsonToAdd =  {"id": person.id,
            "name" : person.FirstName + " " + person.LastName + " - " + person.office.company.Name,
            "imageURL" : "https://images.cubicsearch.com/UserLogo.png"
    
            
            }
        }
            else {
                myJsonToAdd =  {"id": person.id,
            "name" : person.FirstName + " " + person.LastName,
            "imageURL" : "https://images.cubicsearch.com/UserLogo.png"
    
            
            }
    
            }
            
    
        }
        peopleArray.push(myJsonToAdd)
     }
        return peopleArray.sort(sortByProperty("name"))
    }
  
    function sortByProperty(property){  
      return function(a,b){  
         if(a[property] > b[property])  
            return 1;  
         else if(a[property] < b[property])  
            return -1;  
     
         return 0;  
      }  
   }