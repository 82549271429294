
import { ContentCopy } from "@mui/icons-material";


import DemoCarousel from "../Images/Carousel";

function InfoCardEdit({ property, onHover, onClick, setDataIn, contactId }) {
  var nfObject = new Intl.NumberFormat("en-US");
  var nfObjectRate = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  var nfObjectPercent = new Intl.NumberFormat("en-US", { style: "percent" });
  var buildingId = property.id
 
  return (
    <div
      id={property.id}
      className="flex py-7 px-2 cursor-pointer"
      onMouseOver={() => onHover(property)}
    >
      {property.images.length > 0 ? (
        <div className="relative h-24 w-40 md:h-52 md:w-80 flex-shrink-0">
          <DemoCarousel key={property.id} images={property.images} />
        </div>
      ) : (
        <img
          src={
            property.Latitude
              ? "https://dev.virtualearth.net/REST/V1/Imagery/Map/Birdseye/" +
                property.Latitude.toString().trim() +
                "," +
                property.Longitude.toString().trim() +
                "/20?dir=270&ms=900,700&key=AntwMqF4SuxAC8AfmB9-AuOVzrtOhbiNMZFVVe68dkbt5IjzUIzmWS8dfFmo9-IL"
              : "https://images.cubicsearch.com/3dgifmaker05280.gif"
          }
          className=" rounded-2xl h-full"
          //srcSet={`${item.img}?w=400&h=400&fit=crop&auto=format&dpr=2 2x`}
          //alt={item.title}
          //loading="lazy"
        />
      )}

      <div
        className="flex flex-col flex-grow pl-5 "
        // onClick={() => {
        //   // onClick(property.id);
        // }}
      >
        <div className="flex justify-between">
          <div>
            <p className="text-gray-500 text-sm">
              {property.StreetNumber} {property.StreetName}, {property.City}
            </p>
            {/* <HeartIcon className="h-7 cursor-pointer" /> */}
          </div>
          <div>
            {" "}
            <p className="vertical-align: text-bottom; text-right font-extralight pt 4">
              Building Size: {nfObject.format(property.Size)} SF
            </p>
          </div>
        </div>

        <div className="flex justify-between">
          <p className="text-left text-md font-semibold">
            {property.CampusName ? property.CampusName : ""}
          </p>
          <p className="vertical-align: text-bottom; text-right font-extralight">
            Floors: {parseInt(property.NumberFloors)}
          </p>
        </div>

        <div className="vertical-align: text-bottom flex items-end justify-between pt-5 ">
          <p className="text-left text-lg lg:text-2xl font-semibold pb-2">
            {property.PropertyName}
          </p>
          <p className="vertical-align: text-bottom; flex items-center">
            {/* <StarIcon className="vertical-align: text-bottom; h-5 text-red-400" />
            {property.id} */}
          </p>
        </div>
        <div>
                    <button
                      data-tip
                      data-for="copyTip"
                      onClick={() => {
                        // ;
                       

                        //test - console.log(
                        //   process.env.REACT_APP_DATABASE +
                        //     "api/duplicateBuilding?contactId=" +
                        //     contactId +
                        //     "&buildingId=" +
                        //     property.id
                        // );
                        fetch(
                          process.env.REACT_APP_DATABASE +
                            "api/duplicateBuilding?contactId=" +
                            contactId +
                            "&buildingId=" +
                            property.id
                        ).then((response) => {
                          var fetchLocationGet =
                            process.env.REACT_APP_DATABASE +
                            "api/getParcel?id=";
                          //test - console.log("complete");
                          //test - console.log(
                          //   fetchLocationGet +
                          //     buildingId +
                          //     "&contactId=" +
                          //     contactId
                          // );

                          window.location.href = window.location.href
                          // fetch(
                          //   fetchLocationGet +
                          //     buildingId +
                          //     "&contactId=" +
                          //     contactId
                          // ).then((searchResults) => {
                          //   searchResults.json().then((data1) => {
                          //     //test - console.log(data1);
                          //     var myJson = data1.buildings; // search results come in an arra

                          //     setDataIn(data1)
                          //   });
                          // });
                        });

                        // const data = await searchResults.json();
                      }}
                      className=" rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                    >
                      <ContentCopy
                        className="h-4 w-4 text-white shadow-sm"
                        aria-hidden="true"
                      />
       
                    </button>
                  </div>
      </div>

                      

    </div>
  );
}



export default InfoCardEdit;
