import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, List, ListItem, ListItemText, IconButton } from '@mui/material';




export default function  PermissionsDialog ({ open, onClose, item })  {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Manage Permissions</DialogTitle>
            <DialogContent>
                {/* Content showing groups associated with the item. Adjust according to your data structure */}
                <List>
                    {item?.groups?.map((group, index) => (
                        <ListItem key={index}>
                            <ListItemText primary={group.name} />
                        </ListItem>
                    ))}
                </List>
                <Button onClick={onClose}>Close</Button>
            </DialogContent>
        </Dialog>
    );
};
