import { formatPhoneNumber } from "../Common/FormatPhoneNumber";

export default function ContactListView({ peopleIn }) {
  //

  return (
    <div className="mt-8 flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Phone
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Company
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Permission Level
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {peopleIn.map((person, index) => {
                  if (person.PermissionLevel !== "Data") {
                    return (
                      <tr
                        key={person.contact.id}
                        className="hover:bg-gray-200 cursor-pointer"
                        onClick={async () => {
                          // const {Base64} = require('js-base64');
                          // var dataObjectBase64 = Base64.encode(person.contact.id.toString());
                          window.open("contact?id=" + person.contact.id);
                        }}
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6" key = {index}>
                          <div className="flex items-center">
                            {/* <div style={imagecropper}>
                             <img style={profilepic} src={person.contact.image.img} alt="" />
                        </div> */}
                            <div className="h-10 w-10 rounded-full">
                              <img
                                className="h-10 w-10 flex-shrink-0 rounded-full object-cover"
                                src={
                                  person.contact &&
                                  person.contact.images.length > 0
                                    ? person.contact.images.sort(
                                        (a, b) => a.sortOrder - b.sortOrder
                                      )[0].img
                                    : "https://images.cubicsearch.com/UserLogo.png"
                                }
                                alt=""
                              />
                            </div>
                            <div className="ml-4">
                              <div className="font-medium text-gray-900">
                                {person.contact
                                  ? person.contact.FirstName +
                                    " " +
                                    person.contact.LastName
                                  : ""}
                              </div>
                              <div className="text-gray-500">
                                {person.contact ? person.contact.Email : ""}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="text-gray-900">
                            {person.contact ? person.contact.Title : ""}
                          </div>
                          <div className="text-gray-500">
                            {person.contact
                              ? formatPhoneNumber(person.contact.Phone)
                              : ""}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="h-10 w-20 flex-shrink-4">
                            <img
                              className="h-10 w-20 object-contain "
                              src={
                                person.contact &&
                                person.contact.office &&
                                person.contact.office.company &&
                                person.contact.office.company.images
                                  ? person?.contact?.office?.company?.images?.[0]?.img
                                  : "https://images.cubicsearch.com/CompanyLogo.png"
                              }
                              alt=""
                            />
                            {/* <div style={imagecropper}>
                        <img style={profilepic} src={person.contact.office.company.images[0].img} alt="" />
                        </div> */}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.PermissionLevel
                            ? person.PermissionLevel
                            : "undesignated"}
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

// function formatPhoneNumber(phoneNumberString) {
//     var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
//     var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
//     if (match) {
//       return '(' + match[1] + ') ' + match[2] + '-' + match[3]
//     }
//     return null
//   }
