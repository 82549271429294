import React from "react";
import Avatar from "@mui/material/Avatar";
import { deepOrange } from "@mui/material/colors";
import axios from "axios";
import { Typography, Button, Alert, Dialog, AppBar, Link, DialogTitle, DialogContent, IconButton, DialogActions, Tooltip } from "@mui/material";
import { useState } from "react";
import { mobileAndTabletCheck } from "../Deck";
import { Edit, } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const messageRow = {
    display: "flex",
    width: '100%'


}



const messageRowRight = {
    display: "flex",
    justifyContent: "flex-end"
}

const messageBlue = {
    position: "relative",
    // marginLeft: "20px",
    marginBottom: "10px",
    padding: 10,
    paddingBottom: 20,
    backgroundColor: "#A8DDFD",
    maxWidth: "70%",
    minWidth: 150,
    //height: "50px",
    textAlign: "left",
    font: "400 .9em 'Open Sans', sans-serif",
    border: "1px solid #97C6E3",
    borderRadius: "10px",
    "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #A8DDFD",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "0",
        left: "-15px"
    },
    "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #97C6E3",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        left: "-17px"
    }
}

const messageOrange = {
    position: "relative",
    // marginRight: "20px",
    marginBottom: "10px",

    marginRight: 20,
    padding: "10px",
    paddingBottom: 20,
    backgroundColor: "#f8e896",
    maxWidth: mobileAndTabletCheck() ? "50%" : '80%',
    minWidth: 150,
    //height: "50px",
    textAlign: "right",
    font: "400 .9em 'Open Sans', sans-serif",
    border: "1px solid #dfd087",
    borderRadius: "10px",
    "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #f8e896",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "0",
        right: "-15px"
    },
    "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #dfd087",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        right: "-17px"
    }
}

const messageContent = {
    padding: 0,
    margin: 0,
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word'

}

const messageTimeStampRight = {
    position: "absolute",
    fontSize: ".85em",
    fontWeight: "300",
    marginTop: "10px",
    bottom: "-1px",
    right: "5px",
    color: 'gray'
}
const messageTimeStampLeft = {
    position: "absolute",
    fontSize: ".85em",
    fontWeight: "300",
    marginTop: "10px",
    bottom: "-1px",
    left: "5px",
    color: 'gray'
}

const orange = {
    color: 'white',
    backgroundColor: deepOrange[500],

    // width: theme.spacing(4),
    // height: theme.spacing(4)
}

const avatarNothing = {
    color: "transparent",
    backgroundColor: "transparent",
    // width: theme.spacing(4),
    // height: theme.spacing(4)
}
const displayName = {
    marginLeft: "20px"
}




function isImageUrl(url) {
    return /\.(jpg|jpeg|png|gif|bmp|svg)$/i.test(url);
}

function isVideoUrl(url) {
    return /\.(mp4|mov|wmv|avi|mkv)$/i.test(url);
}
const LinkOrImage = ({ part, index }) => {
    const isImage = isImageUrl(part);

    
    return (
        <Link key={index} href={part} target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', maxWidth: 300 }}>
            {isImage ? <img src={part} alt="" style={{ maxWidth: '100%', height: 'auto' }} /> : part}
        </Link>
    );
};

function TextWithWebAddresses({ inputText }) {
    // Use a regular expression to match web addresses
    const webAddressRegex = /(https?:\/\/[^\s]+)/g;

    // Split the inputText using the web address regex
    const textParts = inputText.split(webAddressRegex);

    // Map each part to either text or the WebAddressLink component

    const jsxFragment = textParts.map((part, index) => {

        if (webAddressRegex.test(part)) {
            
           
            return LinkOrImage({part:part, index: index})
        } else {
            return <Typography component={'div'} key={index} variant="body1">{part}</Typography>;
        }
    });

    
    return <>{jsxFragment}</>;
}
//avatarが左にあるメッセージ（他人）
export const MessageLeft = (props) => {
    const message = props.message ? props.message : "no message";
    const timestamp = props.timestamp ? props.timestamp : "";
    const photoURL = props.photoURL;

    const displayName = props.displayName ? props.displayName : "Contact";
    const [open, setOpen] = useState(false)
    var validJson = true

    var obj

    if (message.includes("CubicAttachment=")) {


        // var leftSide = message.substring(0, message.indexOf(" CubicLink="))
        // var rightSide = message.substring(leftSide.length)
        // leftSide = leftSide.replaceAll(" ", "")
        // rightSide = rightSide.replaceAll(" ", "")
        // var trimmedMessage = leftSide + " " + rightSide

        var sub = message.substring("CubicAttachment=".length)

        try {
            obj = JSON.parse(sub)
        }
        catch (error) {
            try {
                obj = JSON.parse(encodeURI(sub))
            }
            catch {
                console.log("error skipping")
                validJson = false
            }

        }
    }
    const navigate = useNavigate()
    if (obj && obj.shareLink && obj.fileName) {
        return (
            <>
                <div style={messageRow} >
                    <div style={{ cursor: 'pointer' }} onClick={() => {
                        navigate("/contact?id=" + props.authorId)
                    }}>  <Avatar
                        alt={displayName}
                        style={orange}
                        src={photoURL}
                    >{displayName.length > 0 ? displayName[0] : ""}
                        </Avatar>
                    </div>

                    <div
                    // style={{ width: '100%' }}
                    >
                        <Typography component={'div'} variant='caption' >{displayName}</Typography>
                        <Typography component={'div'} variant='caption'>{props.displayEmail ? <p>{props.displayEmail}</p> : ""}</Typography>
                        <div style={messageBlue}>
                            <div>

                                <Typography component={'div'} style={messageContent}>
                                    {isImageUrl(obj.shareLink) ?



                                        <Link key={obj.shareLink + Math.random()} href={obj.shareLink} target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', maxWidth: 300 }}>
                                            <img src={obj.shareLink} alt="" style={{ maxWidth: '100%', height: 'auto' }} />
                                        </Link>

                                        : isVideoUrl(obj.shareLink) ? (
                                            <video controls style={{ maxWidth: '100%' }}>
                                                <source src={obj.shareLink} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        ) :
                                            <Button sx={{ maxWidth: '100%' }} variant='contained' onClick={() => { window.open(obj.shareLink) }}>File(s) {obj.fileName}</Button>

                                    }
                                </Typography>
                            </div>
                            <div style={messageTimeStampLeft}>{timestamp}</div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    else {
        return (
            <>
                <div style={messageRow} >
                    <Avatar
                        alt={displayName}
                        style={orange}
                        src={photoURL}
                    >{displayName.length > 0 ? displayName[0] : ""} </Avatar>
                    <div
                    // style={{ width: '100%' }}
                    >
                        <Typography component={'div'} variant='caption' >{displayName}</Typography>
                        <Typography component={'div'} variant='caption'>{props.displayEmail ? <p>{props.displayEmail}</p> : ""}</Typography>
                        <div style={messageBlue}>
                            <div>
                                <Typography component={'div'} style={messageContent}>{message.length > 80 ?
                                    <>
                                        <TextWithWebAddresses inputText={message.substring(0, Math.min(message.length, 80))} />;
                                        <Button variant='contained' onClick={() => { setOpen(true) }}>Full Message</Button>
                                    </> : <TextWithWebAddresses inputText={message} />}</Typography>
                            </div>
                            <div style={messageTimeStampLeft}>{timestamp}</div>
                        </div>
                    </div>
                </div>
                <Dialog maxWidth='xl' open={open} sx={{ padding: 8 }} onClose={() => { setOpen(false) }}>
                    <DialogTitle sx={{ bgcolor: 'ButtonFace', display: 'flex' }}>
                        <Avatar
                            alt={displayName}
                            style={orange}
                            src={photoURL}
                        >{displayName.length > 0 ? displayName[0] : ""}
                        </Avatar>
                        <div>
                            <Typography component={'div'} variant='caption' >{displayName}</Typography>
                            <Typography component={'div'} variant='caption'>{props.displayEmail ? <p>{props.displayEmail}</p> : ""}</Typography>
                        </div>
                        <Typography component={'div'} marginLeft={5} variant="h5">Message</Typography>

                    </DialogTitle>
                    <DialogContent>
                        <pre style={{
                            padding: 40, whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word'

                        }}><TextWithWebAddresses inputText={message} />
                        </pre>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
};
//avatarが右にあるメッセージ（自分）
export const MessageRight = (props) => {

    const message = props.message ? props.message : "no message";
    const timestamp = props.timestamp ? props.timestamp : "";
    var validJson = true
    const [open, setOpen] = useState(false)
    const [deleteShow, setDeleteShow] = useState(false)


    var obj

    if (message.includes("CubicAttachment=")) {


        // var leftSide = message.substring(0, message.indexOf(" CubicLink="))
        // var rightSide = message.substring(leftSide.length)
        // leftSide = leftSide.replaceAll(" ", "")
        // rightSide = rightSide.replaceAll(" ", "")
        // var trimmedMessage = leftSide + " " + rightSide

        var sub = message.substring("CubicAttachment=".length)

        try {
            obj = JSON.parse(sub)
        }
        catch (error) {
            try {
                obj = JSON.parse(encodeURI(sub))
            }
            catch {
                console.log("error skipping")
                validJson = false
            }

        }
    }

    const navigate = useNavigate()
    if (obj && obj.shareLink && obj.fileName) {
        var deleteMessage =
            <Typography component={'div'} style={messageContent}>
                {isImageUrl(obj.shareLink) ?



                    <Link key={obj.shareLink + Math.random()} href={obj.shareLink} target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', maxWidth: 300 }}>
                        <img src={obj.shareLink} alt="" style={{ maxWidth: '100%', height: 'auto' }} />
                    </Link>

                    : isVideoUrl(obj.shareLink) ? (
                        <video controls style={{ maxWidth: '100%' }}>
                            <source src={obj.shareLink} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) :
                        <Button sx={{ maxWidth: '100%' }} variant='contained' onClick={() => { window.open(obj.shareLink) }}>File(s) {obj.fileName}</Button>

                }
            </Typography>
        return (
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'end', flexDirection: 'column' }}>
                <div style={{ cursor: 'pointer', marginRight: 20 }} onClick={() => {
                    navigate("/contact?id=" + props.authorId)
                }}>
                    <Avatar>Me</Avatar>
                </div>
                <div style={messageRowRight} >
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Tooltip arrow title="Edit message">
                            <IconButton size='small' sx={{ marginRight: 2, width: 20, height: 20, backgroundColor: 'whitesmoke' }} onClick={() => {
                                setDeleteShow(true)

                            }}><Edit sx={{ width: 18, height: 18 }}></Edit></IconButton>
                        </Tooltip>

                        <div style={messageOrange}>



                            <Typography component={'div'} style={messageContent}>
                                {isImageUrl(obj.shareLink) ?



                                    <Link key={obj.shareLink + Math.random()} href={obj.shareLink} target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', maxWidth: 300 }}>
                                        <img src={obj.shareLink} alt="" style={{ maxWidth: '100%', height: 'auto' }} />
                                    </Link>

                                    : isVideoUrl(obj.shareLink) ? (
                                        <video controls style={{ maxWidth: '100%' }}>
                                            <source src={obj.shareLink} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    ) :
                                        <Button sx={{ maxWidth: '100%' }} variant='contained' onClick={() => { window.open(obj.shareLink) }}>File(s) {obj.fileName}</Button>

                                }
                            </Typography>
                            <div style={messageTimeStampRight}>{timestamp}</div>
                        </div>
                    </div>
                </div>
                <Dialog maxWidth='xl' open={deleteShow} sx={{ padding: mobileAndTabletCheck() ? 2 : 8 }} onClose={() => { setDeleteShow(false) }}>
                    <DialogTitle sx={{ bgcolor: 'ButtonFace', display: 'flex' }}>
                        <Avatar>
                            Me

                        </Avatar>


                        <Typography component={'div'} marginLeft={5} variant="h5">Edit Message</Typography>

                    </DialogTitle>
                    <DialogContent>

                        <Typography component={'div'} sx={{ marginTop: 5, marginLeft: 5 }} variant="h5">Would you like to delete this message?</Typography>
                        <pre style={{
                            padding: mobileAndTabletCheck() ? 1 : 40, whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word'

                        }}>
                            <div style={{ padding: 20, backgroundColor: 'whitesmoke' }}>
                                {deleteMessage}
                            </div>
                        </pre>
                    </DialogContent>
                    <DialogActions><Button onClick={async () => {
                        //  alert(JSON.stringify(props))
                        var response = await axios.get(process.env.REACT_APP_DATABASE + "api/deleteJournal?id=" + props.messageId)
                        // alert(JSON.stringify(response))
                        props.setTrigger(Math.random())
                        setDeleteShow(false)

                    }} variant='outlined' sx={{ color: 'red', outlineColor: 'red', borderColor: 'red' }}>Yes</Button ><Button onClick={() => { setDeleteShow(false) }} variant="contained">No</Button></DialogActions>
                </Dialog>
            </div>
        );
    }
    else {

        deleteMessage = <TextWithWebAddresses inputText={message} />
        return (<div style={{ display: 'flex', justifyContent: 'end', alignItems: 'end', flexDirection: 'column' }}>
            <div style={{ cursor: 'pointer', marginRight: 20 }} onClick={() => {
                navigate("/contact?id=" + props.authorId)
            }}>
                <Avatar>Me</Avatar>
            </div>
            <div style={messageRowRight}>

                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

                    <Tooltip arrow title="Edit message" >
                        <IconButton size='small' sx={{ marginRight: 2, width: 20, height: 20, backgroundColor: 'whitesmoke' }} onClick={() => {
                            setDeleteShow(true)

                        }}><Edit sx={{ width: 18, height: 18 }}></Edit></IconButton>
                    </Tooltip>
                    <div style={messageOrange}>




                        <Typography component={'div'} style={messageContent}>
                            {message.length > 80 ?
                                <>
                                    {<TextWithWebAddresses inputText={message.substring(0, Math.min(message.length, 80))} />}
                                    <Button
                                        variant='contained'
                                        onClick={
                                            () => {
                                                setOpen(true)
                                            }
                                        }
                                    >Full Message</Button>
                                </>
                                : <TextWithWebAddresses inputText={message} />}</Typography>


                        <div style={messageTimeStampRight}>{timestamp}</div>
                    </div>
                </div>
            </div>



            <Dialog maxWidth='xl' open={open} sx={{ padding: mobileAndTabletCheck() ? 2 : 8 }} onClose={() => { setOpen(false) }}>
                <DialogTitle sx={{ bgcolor: 'ButtonFace', display: 'flex' }}>
                    <Avatar>
                        Me
                    </Avatar>

                    <Typography component={'div'} marginLeft={5} variant="h5">My Message</Typography>

                </DialogTitle>
                <DialogContent>
                    <pre style={{
                        padding: mobileAndTabletCheck() ? 1 : 40, whiteSpace: 'pre-wrap',
                        overflowWrap: 'break-word'

                    }}><TextWithWebAddresses inputText={message} />
                    </pre>
                </DialogContent>
            </Dialog>
            <Dialog maxWidth='xl' open={deleteShow} sx={{ padding: mobileAndTabletCheck() ? 2 : 8 }} onClose={() => { setDeleteShow(false) }}>
                <DialogTitle sx={{ bgcolor: 'ButtonFace', display: 'flex' }}>
                    <Avatar>
                        Me
                    </Avatar>

                    <Typography component={'div'} marginLeft={5} variant="h5">Edit Message</Typography>

                </DialogTitle>
                <DialogContent>

                    <Typography component={'div'} sx={{ marginTop: 5, marginLeft: 5 }} variant="h5">Would you like to delete this message?</Typography>
                    <pre style={{
                        padding: mobileAndTabletCheck() ? 1 : 40, whiteSpace: 'pre-wrap',
                        overflowWrap: 'break-word'

                    }}>
                        <div style={{ padding: 20, backgroundColor: 'whitesmoke' }}>
                            {deleteMessage}
                        </div>
                    </pre>
                </DialogContent>
                <DialogActions><Button onClick={async () => {
                    //  alert(JSON.stringify(props))
                    var response = await axios.get(process.env.REACT_APP_DATABASE + "api/deleteJournal?id=" + props.messageId)
                    // alert(JSON.stringify(response))
                    props.setTrigger(Math.random())
                    setDeleteShow(false)

                }} variant='outlined' sx={{ color: 'red', outlineColor: 'red', borderColor: 'red' }}>Yes</Button ><Button onClick={() => { setDeleteShow(false) }} variant="contained">No</Button></DialogActions>
            </Dialog>
        </div>

        );
    }
};
