// import { formatPhoneNumber } from "../Common/FormatPhoneNumber";

// export default function ContactListView({peopleIn}) {

    
//   if (peopleIn.length ===0) {
//     return("")
//   }
    
//     return (
    
    
//     <div className="mt-8 flex flex-col">
//     <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
//       <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
//         <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
//           <table className="min-w-full divide-y divide-gray-300">
//             <thead className="bg-gray-50">
//               <tr>
                  
//                 <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
//                   Name
//                 </th>
//                 <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
//                   Phone
//                 </th>
//                 <th scope="col" className="px-5 py-3.5 text-left text-sm font-semibold text-gray-900">
//                   Company
//                 </th>
//                 <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
//                   Role
//                 </th>
 
//               </tr>
//             </thead>
//             <tbody className="divide-y divide-gray-200 bg-white">
//               {peopleIn.map((person) => (
//                 <tr key={person.id} className="hover:bg-gray-200 cursor-pointer"  onClick = {
//                   async () => {
//                  // const {Base64} = require('js-base64');
//                  // var dataObjectBase64 = Base64.encode(person.id.toString());
//                   window.open("contact?id="+person.id, mobileAndTabletCheck()?"_self": null);
//                   }
//                 }>
//                   <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
//                     <div className="flex items-center">
        
//                        <div className="h-10 w-10 rounded-full">
//                         <img className="h-10 w-10 flex-shrink-0 rounded-full object-cover" src={person.images.length > 0 ? person.images.sort((a,b)=> a.sortOrder - b.sortOrder)[0].img : "https://images.cubicsearch.com/UserLogo.png"} alt="" />
//                       </div> 
//                       <div className="ml-4">
//                         <div className="font-medium text-gray-900">{person.FirstName} {person.LastName}</div>
//                         <div className="text-gray-500">{person.Email}</div>
//                       </div>
//                     </div>
//                   </td>
//                   <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
//                     <div className="text-gray-900">{person.Title}</div>
//                     <div className="text-gray-500">{formatPhoneNumber(person.Phone)}</div>
//                   </td>
//                   <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
//                       <div className="h-10 w-20 flex-shrink-4"> 
//                          <img className="h-10 w-20 object-contain " src={person.office && person.office.company && person.office.company.images && person.office.company.images.length >0 ? person.office.company.images[0].img: "https://images.cubicsearch.com/CompanyLogo.png"} alt="" /> 
//                         {/* <div style={imagecropper}>
//                         <img style={profilepic} src={person.office.company.images[0].img} alt="" />
//                         </div> */}
//                       </div> 
//                   </td>
//                   <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.project_contact.RelationshipType}</td>
              
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   </div>)
// }

// // function formatPhoneNumber(phoneNumberString) {
// //     var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
// //     var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
// //     if (match) {
// //       return '(' + match[1] + ') ' + match[2] + '-' + match[3]
// //     }
// //     return null
// //   }

import { formatPhoneNumber } from "../Common/FormatPhoneNumber";
import { mobileAndTabletCheck } from "../Search/Deck";

export default function ContactListView({peopleIn}) {

    

    return (
    
    
    <div className="mt-8 flex flex-col">
    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                  
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Name
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Phone
                </th>
                <th scope="col" className="px-5 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Company
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Role
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Your Groups Who See Project Contact Relationship
                </th>
        
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {peopleIn.map((person) => (
                <tr key={person.id} className="hover:bg-gray-200 cursor-pointer"  onClick = {
                  async () => {
                 // const {Base64} = require('js-base64');
                 // var dataObjectBase64 = Base64.encode(person.id.toString());
                  window.open("contact?id="+person.id, mobileAndTabletCheck()?"_self": null);
                  }
                }>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                    <div className="flex items-center">
                         {/* <div style={imagecropper}>
                             <img style={profilepic} src={person.image.img} alt="" />
                        </div> */}
                       <div className="h-10 w-10 rounded-full">
                        <img className="h-10 w-10 flex-shrink-0 rounded-full object-cover" src={person.images.length > 0 ? person.images.sort((a,b)=> a.sortOrder - b.sortOrder)[0].img : "https://images.cubicsearch.com/UserLogo.png"} alt="" />
                      </div> 
                      <div className="ml-4">
                        <div className="font-medium text-gray-900">{person.FirstName} {person.LastName}</div>
                        <div className="text-gray-500">{person.Email}</div>
                      </div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div className="text-gray-900">{person.Title}</div>
                    <div className="text-gray-500">{formatPhoneNumber(person.Phone)}</div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div className="h-10 w-20 flex-shrink-4"> 
                         <img className="h-10 w-20 object-contain " src={person.office && person.office.company ? person.office.company.images[0].img: "https://images.cubicsearch.com/CompanyLogo.png"} alt="" /> 
                        {/* <div style={imagecropper}>
                        <img style={profilepic} src={person.office.company.images[0].img} alt="" />
                        </div> */}
                      </div> 
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.project_contact.RelationshipType}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.group_contacts.map((group_contact) => {
                        return (
                        <div>
                          {group_contact.group.GroupName}
                        </div>)
                  })}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>)
}

// function formatPhoneNumber(phoneNumberString) {
//     var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
//     var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
//     if (match) {
//       return '(' + match[1] + ') ' + match[2] + '-' + match[3]
//     }
//     return null
//   }