import WebsiteCard from "./WebsiteCard"

export  default function WebsiteView ({websites}) {
return (
    <>
    
    <dl className = "grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            {
        websites.map((website)=>{
         return (
         <WebsiteCard website={website}/> 
         )
        })}
    </dl>
    </>
    )
}