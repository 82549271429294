

  // import { Alert } from "@mui/material";
import { useState } from "react";
//  import ContactEditList from ".depreciated/ContactEditList";
 import ContactListView from "./ContactListView";
import ProjectContactEditTable from "./ProjectContactEditTable";
import { arrayRemove } from "./ProjectContactEditTable";
import { Save, Edit } from "@mui/icons-material";

  export default function ContactList({peopleIn, dataIn, setPeopleIn, peopleCombo, contactId, groupsIn, highestPermission}) {

    
    
    const [ editContactsMode, setEditContactsMode] = useState(false);
    
    if (!highestPermission || highestPermission !== "Lead Edit" && highestPermission !== "Edit"){
      groupsIn.map((group, index)=>{
        if (group.id ===1){
          groupsIn = arrayRemove(groupsIn, index)
        }
      })
    }
    return (
      <>
      <div className="px-0 sm:px-0 lg:px-0">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">{peopleIn.length >0 ? "Project Contacts" : "No Project Contacts"}</h1>
            <h1 className="text-m font-semibold text-gray-900">{peopleIn.length >0 ? "Each Project Contact has Specific Visibility"  : ""}</h1>

          </div>
          <div className="mt-4 flex space-x-2 sm:mt-0 sm:ml-16 sm:flex-none">
         
            <div className = "px-0">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
              onClick={()=> setEditContactsMode(!editContactsMode)}
            >
              {!editContactsMode? <Edit sx={{width: 18, height: 18}}/> : <Save/>}
            </button>
            </div>
          </div>
        </div>
          {/* {!editContactsMode? <ContactListView peopleIn = {peopleIn}/> : <ContactEditList peopleIn = {peopleStateIn} /> } */}
          {!editContactsMode ? <ContactListView peopleIn={peopleIn} /> :
            <ProjectContactEditTable peopleIn={peopleIn} setPeopleIn={setPeopleIn}
              bigDataIn={dataIn} projectId={dataIn.id}
            // peopleCombo={peopleCombo}
            contactId={contactId} groupsIn={groupsIn} highestPermission={highestPermission} />}
      </div>
      
        
      </>
    )
  }
  

