import Header from "../Common/Header";
import { Fragment, useState, useEffect } from "react";
import ImageUploadGallery from "../Common/Uploader/ImageUploadGallery";
import axios from 'axios'
import { markDeleteImage, orderImages } from "../Availables/AvailableDetail";
import KeenSlider from "../Images/KeenSlider";


import SurveyGroupList from "./SurveyGroups/SurveyGroupList";
import { Edit, Save } from "@mui/icons-material"
import { TextField, Typography } from "@mui/material";



function editClick(
  setEditModeFunctionIn,
  setEditButtonTextFunctionIn,
  editMode,
  SurveyDataIn,
  setData
) {
  if (editMode === "Edit") {
    // //test - console.log("AskTI: ");
    // //test - console.log(document.getElementById("AskTI").value);
    setEditModeFunctionIn("View");
    setEditButtonTextFunctionIn(<Edit sx={{width: 18, height: 18}}/>);

    
    
    setData(SurveyDataIn);
    updateSurvey(SurveyDataIn);
  } else {
    setEditModeFunctionIn("Edit");
    setEditButtonTextFunctionIn(<Save/>);
  }
}

async function updateSurvey(data) {
  //test - console.log("my Data" + data);
  const qs = Object.keys(data) // turn the parameters into a get query string parameters style
    .map((key) =>
      key !== "images" && key !== "building" && key !== "contacts"
        ? `${key}=${encodeURIComponent(data[key])}`
        : ""
    )
    .join("&");

  
  var resultInfo = await fetch(
    process.env.REACT_APP_DATABASE + "api/updateSurvey?" +
      qs
  );

  var var2 = await resultInfo.json();
  //test - console.log("results = " + var2);
}

export default function SurveyDetail({
  Mode,
  PropertyInformation,

  user,
  groupsCombo,
  contactId,
}) {
  var nfObject = new Intl.NumberFormat("en-US");
  var nfObjectRate = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  var nfObjectPercent = new Intl.NumberFormat("en-US", { style: "percent" });
  const [SurveyDataIn, setData] = useState(PropertyInformation.survey);
  const [peopleIn, setPeopleIn] = useState(
    PropertyInformation.survey.contacts.sort(
      (a, b) => a.survey_contact.sortOrder - b.survey_contact.sortOrder
    )
  );
  const [editMode, setEditMode] = useState(Mode);
  const [editButtonText, setEditButtonText] = useState(<Edit sx={{width: 18, height: 18}}/>);
  var highestPermission = "None";

  var icon = null;

  var initialPics = [];

  PropertyInformation.groups.map((group) => {
    group.survey_groups.map((survey_group) => {
      if (
        highestPermission !== "View" &&
        highestPermission !== "Edit" &&
        highestPermission !== "Lead Edit"
      ) {
        highestPermission = survey_group.groupPermission;
      } else {
        if (
          (highestPermission === "View" &&
            survey_group.groupPermission === "Edit") ||
          survey_group.groupPermission === "Lead Edit"
        ) {
          highestPermission = survey_group.groupPermission;
        } else if (
          highestPermission === "Edit" &&
          survey_group.groupPermission === "Lead Edit"
        ) {
          highestPermission = survey_group.groupPermission;
        }
      }
    });
  });

  SurveyDataIn.images.map((image) => {
    initialPics.push({
      source: image.img,
      img: image.img,
      name: image.id,
    });
  });

  if (!Mode) {
    icon = "survey";
  } else {
    icon = Mode;
  }

  async function addSurveyImage(image) {
    const updatedImages = [...SurveyDataIn.images, {
      source: image.message,
      img: image.message,
      url: image.message,
      name: image.name,
      id: image.name
    }];

    var result = await axios.get(process.env.REACT_APP_DATABASE + "api/addSurveyImage", {
      params: {
        img: image.message,
        myId: user.contact.id,
        surveyId: SurveyDataIn.id,
        sortOrder: updatedImages.length
      }
    })
    setData({ ...SurveyDataIn, images: updatedImages });
  }
  //test - console.log("icon: " + icon);
  return (
    <div>
      <nav className="sticky z-50 top-0">
        <Header
          key="1"
          placeholderLocation={
            SurveyDataIn.SurveyName
          }
          icon={icon}
          user={user}
          highestPermission={highestPermission}
          
        />
      </nav>
      <div className="px-4 py-5 sm:px-6"></div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 sm:px-6">
          <div className="py-4">
            <h1 className="text-xl font-semibold text-gray-900">
              Survey Space Information
            </h1>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {SurveyDataIn.project.ProjectName}{" "}
             
            </p>
            <p>
            {SurveyDataIn.project.client.ClientName}{" "}

            </p>
        
          </div>
          <div className="px-6 py-12">
            {/* <div className="flex flex-col"> */}
              <div className="max-h-300 w-500  md:max-h-300 md:w-500 self-center ">
                {editMode !== "Edit" ? (
                  // <div className="flex flex-col max-w-full sm:max-w-xl self-center">
                  // <div className="flex flex-col  max-w-full">
                  SurveyDataIn.images ? (
                    <KeenSlider key="1" dataIn={SurveyDataIn} />
                  ) : (
                    ""
                  )
                ) : (
                  // </div>
                  // </div>
                  <ImageUploadGallery
                    initialImages={initialPics}
                    onDeleted={(image) =>
                      markDeleteImage(image, SurveyDataIn, setData)
                    }
                    onSortEnd={async (images) => {

                      await orderImages(
                        images,
                        SurveyDataIn,
                        setData
                      )
                    }
                    }
                    action={process.env.REACT_APP_IMAGE}
                    onSuccess={(image) => {
                      addSurveyImage(image)
                    }}
                  />

                )}
              {/* </div> */}
            </div>
          </div>
        </div>
        <div className="md:flex md:items-center md:justify-between border-t border-gray-500 px-6">
          {/* <div className="  ">
            <h1 className="text-xl font-semibold text-gray-900">
              Survey Details
            </h1>
          </div> */}

          <div className="px-0 py-6">
            {highestPermission &&
            (highestPermission === "Edit" ||
              highestPermission === "Lead Edit" ||
              contactId === SurveyDataIn.contactId || 
              contactId === 1) ? (
              <button
                onClick={() =>
                  editClick(
                    setEditMode,
                    setEditButtonText,
                    editMode,
                    SurveyDataIn,
                    setData
                  )
                }
                type="button"
                className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                {editButtonText}
              </button>
            ) : (
              ""
            )}
          </div>
        </div>

      
        {editMode !== "Edit" ? (
          <>  <Typography style={{ margin: 30 }} variant="h4">{SurveyDataIn.SurveyName}</Typography></>
          // <SurveyView jsonformsDataIn={SurveyDataIn} />
        ) : (
          <Typography style={{ margin: 30 }} variant="h4">
              <TextField defaultValue={SurveyDataIn.SurveyName} onChange={(newValue) => {
                SurveyDataIn.SurveyName = newValue.target.value
                setData(SurveyDataIn)
                
            }} /></Typography>
         
        )}
      </div>

      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <SurveyGroupList
          groupsIn={PropertyInformation.groups}
          surveyId={SurveyDataIn.id}
          contactId={user.contact.id}
          groupsCombo={groupsCombo}
          highestPermission={highestPermission}
          setData={setData}
        />
      </div>
      <div></div>
    </div>
  );
}
