import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import FolderIcon from '@mui/icons-material/Folder';
import ListItemIcon from '@mui/material/ListItemIcon';

export default function FolderSelectionDialog({ open, onClose, folders, onSelectFolder, listName }) {

    const handleListItemClick = (folderId) => {
        onSelectFolder(folderId);
        onClose(); // Close dialog after selection
    };

    return (
        <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
            <DialogTitle>Select a folder to copy recipient list: {listName}</DialogTitle>
            <List>
                {folders.map((folder) => (
                    <ListItem button onClick={() => handleListItemClick(folder)} key={folder?.id}>
                        <ListItemIcon>
                            <FolderIcon style={{ color: 'orange' }} />
                        </ListItemIcon>
                        <ListItemText primary={folder?.folderName} />
                    </ListItem>
                ))}
            </List>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}
