import Header from "../Common/Header";
import {  useState } from "react";
import ContactList from "./ContactList";
import GroupView from "./GroupView";
import GroupEdit from "./GroupEdit";
import KeenSlider from "../Images/KeenSlider";

import { Edit, Save } from "@mui/icons-material"
import ImageUploadGallery from "../Common/Uploader/ImageUploadGallery";
import axios from 'axios'
import { markDeleteImage, orderImages } from "../Availables/AvailableDetail";
import { GroupCenter } from "../Search/InfoCard";

function updateJson(jsonFieldIn, reqValueIn) {
  if (reqValueIn !== "") {
    if (reqValueIn === -1) {
      jsonFieldIn = null;
    } else {
      jsonFieldIn = reqValueIn;
    }
  }
  // return jsonFieldIn;
  return reqValueIn;
}

function editClick(
  setEditModeFunctionIn,
  setEditButtonTextFunctionIn,
  editMode,
  GroupInformation,
  setData
) {
  if (editMode === "Edit") {
    setEditModeFunctionIn("View");
    setEditButtonTextFunctionIn(<Edit sx={{ width: 18, height: 18 }} />);

    GroupInformation.GroupName = updateJson(
      GroupInformation.GroupName,
      document.getElementById("GroupName").value
    );
    GroupInformation.Notes = updateJson(
      GroupInformation.Notes,
      document.getElementById("Notes").value
    );
    GroupInformation.MembersVisible = updateJson(
      GroupInformation.MembersVisible,
      document.getElementById("MembersVisible").value
    );

    setData(GroupInformation);
    updateGroup(GroupInformation);
  } else {
    setEditModeFunctionIn("Edit");
    setEditButtonTextFunctionIn(<Save />);
  }
}

async function updateGroup(data) {
  //test - console.log("my Data" + data);
  const qs = Object.keys(data) // turn the parameters into a get query string parameters style
    .map((key) =>
      key !== "images" &&
        key !== "building" &&
        key !== "contacts" &&
        key !== "group_contacts"
        ? `${key}=${encodeURIComponent(data[key])}`
        : ""
    )
    .join("&");

  //test - console.log(process.env.REACT_APP_DATABASE + "api/updateGroup?" + qs);
  //   const {Base64} = require('js-base64');
  //   var dataObjectBase64 = Base64.encode(qs);
  ////test - console.log(dataObjectBase64    );
  //   var resultInfo = await fetch(process.env.REACT_APP_DATABASE + "api/updateGroup?" + qs);

  var resultInfo = await fetch(
    process.env.REACT_APP_DATABASE + "api/updateGroup?" + qs
  );

  var var2 = await resultInfo.json();
  //test - console.log("results = " + var2);
}


export default function GroupDetail({ Mode, Group, people, user, permission }) {
  var nfObject = new Intl.NumberFormat("en-US");
  var nfObjectRate = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  var nfObjectPercent = new Intl.NumberFormat("en-US", { style: "percent" });
  const [GroupInformation, setData] = useState(Group);
  const [peopleIn, setPeopleIn] = useState(
    Group.group_contacts.sort((a, b) => a.sortOrder - b.sortOrder)
  );
  const [editMode, setEditMode] = useState(Mode);
  const [editButtonText, setEditButtonText] = useState(<Edit sx={{ width: 18, height: 18 }} />);

  var icon = null;
  var askRate = "Please Inquire";
  var initialPics = [];
  //test - console.log("Group Detail line 94");
  //test - console.log(user);
  //test - console.log(peopleIn);
  //test - console.log("Permission");
  //test - console.log(permission);

  async function addGroupImage(image) {
    const updatedImages = [...GroupInformation.images, {
      source: image.message,
      img: image.message,
      url: image.message,
      name: image.name,
      id: image.name
    }];

    var result = await axios.get(process.env.REACT_APP_DATABASE + "api/addGroupImage", {
      params: {
        img: image.message,
        myId: user.contact.id,
        groupId: GroupInformation.id,
        sortOrder: updatedImages.length
      }
    })
    setData({ ...GroupInformation, images: updatedImages });
  }

  GroupInformation.images.map((image) => {
    initialPics.push({
      source: image.img,
      img: image.img,
      name: image.id,
    });
  });

  if (!Mode) {
    icon = "group";
  } else {
    icon = Mode;
  }
  //test - console.log("icon: " + icon);
  return (
    <div>
      <nav className="sticky z-50 top-0">
        <Header
          key="1"
          placeholderLocation={"Group - " + GroupInformation.GroupName}
          icon={icon}
          user={user}
          highestPermission={permission}
        />
      </nav>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
        <div style={{ maxWidth: 2000, width: '100%', margin: 20 }}>
          <div className="px-4 sm:px-6">
            <div className="py-4">
              <h1 className="text-xl font-semibold text-gray-900">
                Group Information
              </h1>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                {GroupInformation.GroupName}{" "}
              </p>
              {/* <p className="mt-1 max-w-2xl text-sm text-gray-500">{GroupInformation.Floor !==0? "Floor " + GroupInformation.Floor: "Please Add Floor"}, {GroupInformation.Suite !=="0" ? "Suite " + GroupInformation.Suite: "Please Add Suite" }   </p> */}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            
                {editMode !== "Edit" ? (
                  GroupInformation.images ?
                    <KeenSlider key="1" dataIn={GroupInformation} /> : ("")) :
                  (
                    <ImageUploadGallery
                      initialImages={initialPics}
                      onDeleted={(image) =>
                        markDeleteImage(image, GroupInformation, setData)
                      }
                      onSortEnd={async (images) => {

                        await orderImages(
                          images,
                          GroupInformation,
                          setData
                        )
                      }
                      }
                      action={process.env.REACT_APP_IMAGE}
                      onSuccess={(image) => {
                        addGroupImage(image)
                      }}
                    />

                  )}
              
            </div>
          </div>
          <div className="md:flex md:items-center md:justify-between border-t border-gray-500 px-6">
            <div className="  ">
              <h1 className="text-xl font-semibold text-gray-900">
                Group Details
              </h1>
            </div>
            <div className="px-0 py-6">
              {
                GroupInformation.GroupName !== "Me" &&
                  (permission === "Lead Edit" ||
                    permission === "Edit" ||
                    user.contact.id === 1) ? (
                  <button
                    onClick={() =>
                      editClick(
                        setEditMode,
                        setEditButtonText,
                        editMode,
                        GroupInformation,
                        setData
                      )
                    }
                    type="button"
                    className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    {editButtonText}
                  </button>
                ) : (
                  <></>
                )}
            </div>
          </div>
          {editMode !== "Edit" ? (
            <GroupView GroupData={GroupInformation} />
          ) : (
            <GroupEdit GroupData={GroupInformation} />
          )}
        </div>
        <div style={{ maxWidth: 2000, width: '100%', margin: 20 }}>
          <ContactList
            peopleIn={peopleIn}
            GroupData={GroupInformation}
            setPeopleIn={setPeopleIn}
            peopleCombo={people}
            user={user}
            permission={permission}
          />
        </div>
        {GroupInformation.id === 1?<></>:GroupCenter(GroupInformation, user.contact.id, null)}
      </div>

    </div>
  );
}









