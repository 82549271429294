function inputComponent(inputType, defaultShow, id, name, tailwind) {
  if (inputType === "date") {
    return (
      <input
        className="shadow-sm focus:ring-red-500 focus:border-red-500  sm:text-sm border-gray-300 rounded-md"
        type={inputType}
        name={name}
        id={name}
        defaultValue={defaultShow && defaultShow !== "null" ? defaultShow : ""}
      />
    );
  } else {
    return (
      <input
        className={
          "shadow-sm focus:ring-red-500 focus:border-red-500  sm:text-sm border-gray-300 rounded-md " +
          tailwind
        }
        type={inputType}
        name={name}
        id={name}
        defaultValue={defaultShow && defaultShow !== "null" ? defaultShow : ""}
      />
    );
  }
}

function ClientEdit({ dataIn }) {
  var nfObject = new Intl.NumberFormat("en-US");
  var nfObjectRate = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  var nfObjectPercent = new Intl.NumberFormat("en-US", { style: "percent" });
  const mydate = new Date(dataIn.DateListed);
  const mydateProject = new Date(dataIn.DateProject);

  var defaultAskTI = "Please Inquire";
  if (dataIn.AskTI !== null) {
    defaultAskTI = nfObjectRate.format(dataIn.AskTI);
  }

  return (
    <div className="px-4 py-5 sm:px-6">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Client Name</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {inputComponent("text", dataIn.ClientName, dataIn.id, "ClientName")}{" "}
          </dd>
        </div>
        {/* <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Street Number</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {inputComponent(
              "text",
              dataIn.StreetNumber,
              dataIn.id,
              "StreetNumber"
            )}{" "}
            {inputComponent("text", dataIn.StreetName, dataIn.id, "StreetName")}{" "}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Client Size</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {inputComponent(
              "number",
              nfObject.format(dataIn.Size),
              dataIn.id,
              "Size"
            )}{" "}
            SF
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Client Type</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {inputComponent(
              "text",
              dataIn.ClientType,
              dataIn.id,
              "ClientType"
            )}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Year Built</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {inputComponent("text", dataIn.YearBuilt, dataIn.id, "YearBuilt")}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Year Renovated</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {inputComponent(
              "text",
              dataIn.YearRenovated,
              dataIn.id,
              "YearRenovated"
            )}
          </dd>
        </div> */}
        {/* <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            Number of Floors
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {inputComponent(
              "number",
              dataIn.NumberFloors,
              dataIn.id,
              "NumberFloors"
            )}
          </dd>
        </div> */}

        {/*<dd className="mt-1 text-sm text-gray-900">{dataIn.EstFreeRent || "Please Inquire"} </dd>*/}

        <div className="col-span-2 w-full">
          <dt className="text-sm font-medium text-gray-500">Notes</dt>
          {/* <div className=" mt-2 text-sm w-full text-gray-500">{inputComponent("text", dataIn.Notes, dataIn.id, "Notes", "w-full h-40 self-start")}</div>                */}
          <textarea
            className="shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300 rounded-md w-full h-40"
            type={"textarea"}
            name={"Notes"}
            id={"Notes"}
            defaultValue={dataIn.Notes ? dataIn.Notes : ""}
          ></textarea>
        </div>
      </dl>
    </div>
  );
}

export default ClientEdit;
