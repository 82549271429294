import DemoCarousel from "../../Images/Carousel";
import { mobileAndTabletCheck } from "../../Search/Deck";

export default function ProjectGroupsRows({ groupsIn, contactId }) {
  if (groupsIn.length > 0) {
    return (
      <>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr className="flex items-center text-left">
                      <th className="w-80">
                        <div className="px-6">Group Name</div>
                      </th>
                      <th className="w-80">Date Created</th>
                      <th className="w-80">Permission with this Project</th>

                      <th className="w-80">Contacts</th>
                    </tr>
                  </thead>

                  <tbody>{tableRows(groupsIn, contactId)}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
}

function tableRows(groupsIn, contactId) {
  var nfObject = new Intl.NumberFormat("en-US");
  var nfObjectRate = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  var nfObjectDate = new Intl.DateTimeFormat("en-US");
  ;
  return (
    <>
      {groupsIn.map((group) => (
        <tr
          className="flex items-center text-left bg-white hover:bg-gray-100 hover:cursor-pointer"
          onClick={async () => {
            //const {Base64} = require('js-base64');
            // var dataObjectBase64 = Base64.encode(group.id.toString());
            window.open("group?id=" + group.id, mobileAndTabletCheck()?"_self": null);
            //    await Alert('Alert text', 'Alert title');
          }}
        >
          <th className="w-80 flex items-center">
            <div className="px-8 h-24 w-24 flex items-center">
              {group.images && group.images.length>0? (
                //  <DemoCarousel key={group.id} images={group.images}/>
                <img
                  src={group.images[0].img}
                  className="h-10 object-contain   rounded-md "
                />
              ) : (
                <img
                  src="https://images.cubicsearch.com/GroupImage.png"
                  className="h-10 object-contain   rounded-md"
                />
              )}
            </div>
            <div
              onClick={async () => {
                //const {Base64} = require('js-base64');
                //var dataObjectBase64 = Base64.encode(group.id.toString());
                window.open("group?id=" + group.id, mobileAndTabletCheck()?"_self": null);
                //    await Alert('Alert text', 'Alert title');
              }}
            >
              <div className="pl-2 ">
                <div className="font-medium text-gray-900">
                  {group.GroupName ? group.GroupName : group.createdAt}
                </div>
              </div>
            </div>
          </th>
          <th className="w-80">
            <div className="font-medium text-gray-900">
              {nfObjectDate.format(new Date(group.createdAt)) ||
                "No Recorded Date"}
            </div>{" "}
          </th>
          <th className="w-80">
            <div className="font-medium text-gray-900">
              {/* {nfObjectDate.format(new Date(group.project_groups[0].groupPermission)) || */}
              {group && group.project_groups && group.project_groups[0]
                ? group.project_groups[0].groupPermission
                : "Contact agent or owner"}
            </div>
          </th>

          <th className="w-80">
            {group.contacts
              .sort(
                (a, b) => a.group_contact.sortOrder - b.group_contact.sortOrder
              )
              .map((member) => {
                if (member.group_contact.PermissionLevel !== "Data") {
                  return (
                    <>
                      <div>
                        {/* Show All Members except Data */}
                        {group.MembersVisible === "Yes" ? (
                          <div className="flex items-center py-1">
                            <div className="h-10 w-10 rounded-full">
                              <img
                                className="h-10 w-10 rounded-full object-cover"
                                src={
                                  member.images.length > 0
                                    ? member.images.sort(
                                        (a, b) => a.sortOrder - b.sortOrder
                                      )[0].img
                                    : "https://images.cubicsearch.com/UserLogo.png"
                                }
                                alt=""
                              />
                            </div>
                            <div className="ml-4">
                              <div className="font-medium text-gray-900">
                                {member.FirstName} {member.LastName}
                              </div>
                              <div className="text-gray-500">
                                {member.office && member.office.company
                                  ? member.office.company.Name
                                  : "Add Company"}
                              </div>
                              <div className="text-gray-500">
                                {member.group_contact.PermissionLevel}
                              </div>
                            </div>
                          </div>
                        ) : member.group_contact.PermissionLevel === "Lead Edit" ||
                          member.group_contact.PermissionLevel === "Edit" ||
                          member.group_contact.contact_id === contactId ||
                          contactId ===1? (
                          <div className="flex items-center py-1">
                            <div className="h-10 w-10 rounded-full">
                              <img
                                className="h-10 w-10 flex-shrink-0 rounded-full object-cover"
                                src={
                                  member.images.length > 0
                                    ? member.images.sort(
                                        (a, b) => a.sortOrder - b.sortOrder
                                      )[0].img
                                    : "https://images.cubicsearch.com/UserLogo.png"
                                }
                                alt=""
                              />
                            </div>
                            <div className="ml-4">
                              <div className="font-medium text-gray-900">
                                {member.FirstName} {member.LastName}
                              </div>
                              <div className="text-gray-500">
                                {member.office && member.office.company
                                  ? member.office.company.Name
                                  : "Add Company"}
                              </div>
                              <div className="text-gray-500">
                                {member.group_contact.PermissionLevel}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            {/* {member.group_contact.contact_id} and  <div>{contactId}</div> */}
                          </div>
                        )}
                      </div>
                    </>
                  );
                }
              })}
          </th>
        </tr>
      ))}
    </>
  );
}
