
import * as React from "react";
import { AvatarGroup,  } from "@mui/material";
import {  arrayMove } from "react-movable";
import  { SizableDemoCarousel } from "../Images/Carousel";

import { mobileAndTabletCheck } from "../Search/Deck";
import { Typography, Avatar, CardMedia, CardContent, CardActions } from "@mui/material";
import { Tooltip, Button, Card } from "@mui/material";
import { Add,  DeleteRounded,  FolderOpen,  } from "@mui/icons-material";
import axios from "axios"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

const addMe = async (dataIn, setDataIn, contactId, setFilteredDataIn, ) => {
  var fetchLocationAdd =
    process.env.REACT_APP_DATABASE + "api/addGroup?id=" +
    contactId;
  const resultInfo = await axios.get(fetchLocationAdd).catch((error)=>console.log(error));
  var fetchLocationGet =
    process.env.REACT_APP_DATABASE + "api/getGroupsInvitedTo?id=";
  const searchResults = await axios.get(fetchLocationGet + contactId).catch((error)=>console.log(error));
  const data = await searchResults.data
  //test - console.log(data);
  
  setDataIn(data.sort((a, b) => { return new Date(b.updatedAt) - new Date (a.updatedAt) }))
  
  setFilteredDataIn(data.sort((a, b) => { return new Date(b.updatedAt) - new Date(a.updatedAt) }))
  // var sort = orderGroups(data, setDataIn);
};

export default function MyGroupsEditTable({ dataIn, setDataIn, contactId, filteredDataIn, setFilteredDataIn }) {


  const moveBothArrays = (formattedArray, oldIndex, newIndex) => {
    //setItems(arrayMove(formattedArray, oldIndex, newIndex))
    setDataIn(arrayMove(dataIn, oldIndex, newIndex));
    orderGroups(arrayMove(dataIn, oldIndex, newIndex), setDataIn);
  };
  var items = mapArray(filteredDataIn);




  return (
    <div >

      <Button variant="contained"
        onClick={() => addMe(dataIn, setDataIn, contactId, setFilteredDataIn)}
      >
        Add Group <Add />
      </Button>


      {filteredDataIn.map((dataCard, index) => {
        return (
          <Card sx={{ marginY: 3, maxWidth: 800 , marginBottom:  mobileAndTabletCheck()? 3: null}} >





            <>
              <CardMedia onClick={async () => {
                
                window.open("group?id=" + dataCard.id, mobileAndTabletCheck() ? "_self" : null);

              }}>
                {dataCard.images ? (
                  <SizableDemoCarousel

                    key={dataCard.id}
                    images={dataCard.images}
                  />
                ) : (
                  <Typography component={'div'} variant="h5"
                    sx={{ display: 'flex', padding: 1, backgroundColor: '#4e46e5', color: 'white' }}>
                    <Tooltip placement="top" title={dataCard.GroupName}>
                      <Avatar alt={dataCard.GroupName}

                        onClick={() => { window.open("group?id=" + dataCard.id, mobileAndTabletCheck() ? "_self" : null) }}
                        sx={{ cursor: 'pointer', marginRight: 3 }}
                        src={
                          dataIn?.images?.length > 0
                            ? dataIn?.images.sort(
                              (a, b) => a.sortOrder - b.sortOrder
                            )[0].img
                            : null
                        }

                      >{dataCard.GroupName && dataCard.GroupName.length > 0 && dataCard.GroupName[0]}</Avatar>
                    </Tooltip>{dataCard.GroupName} </Typography>
                )}
              </CardMedia>
              <CardContent>

                <Typography
                  onClick={async () => {

                    window.open("group?id=" + dataCard.id, mobileAndTabletCheck() ? "_self" : null);
                  }}
                >

                  {dataCard.GroupName
                    ? dataCard.GroupName
                    : "Untitled Group Created " +
                    dataCard.createdAt}
                </Typography>
                <Typography>
                  Created:{" "}
                  {
                    new Date(dataCard.createdAt).toLocaleDateString()
                    || "No Recorded Date"}
                </Typography>
                <Typography>
                  Updated:{" "}
                  {new Date(dataCard.updatedAt).toLocaleDateString() ||
                    "No Recorded Date"}
                </Typography>

                {dataCard.id !== 1 ? (
                  <>
                    <div style={{display:'flex' , justifyContent: 'start'}} >
                      <AvatarGroup max={5}>
                        {dataCard.contacts
                          .sort(
                            (a, b) =>
                              a.group_contact.sortOrder - b.group_contact.sortOrder
                          )
                          .map((member, index) => {



                            if (member.group_contact.PermissionLevel !== "Data") {

                              {/* Show All Members except Data */ }
                              return (


                                dataCard.MembersVisible === "Yes" ? (
                                  <Tooltip placement="top" title={member.FirstName + " " + member.LastName + " - " +
                                    (member && member.office && member.office.company ? member.office.company.Name : member.Email)}>
                                    <Avatar key={member.FirstName + member.LastName + index} alt={member.FirstName + ' ' + member.LastName}

                                      onClick={() => { window.open("contact?id=" + member.id, mobileAndTabletCheck() ? "_self" : null) }}
                                      sx={{ cursor: 'pointer' }}
                                      src={
                                        member.images.length > 0
                                          ? member.images.sort(
                                            (a, b) => a.sortOrder - b.sortOrder
                                          )[0].img
                                          : null
                                      }

                                    >{member.FirstName && member.FirstName.length > 0 && member.FirstName[0]}</Avatar>
                                  </Tooltip>


                                ) : member.group_contact.PermissionLevel ===
                                  "Lead Edit" ||
                                  member.group_contact.PermissionLevel === "Edit" ||
                                  member.group_contact.contact_id === contactId ||
                                  contactId === 1 ? (
                                  <Tooltip placement="top"
                                    title={member.FirstName + " " + member.LastName + " - " +
                                      (member && member.office && member.office.company ? member.office.company.Name : member.Email)}>
                                    <Avatar alt={member.FirstName + " " + member.LastName}

                                      onClick={() => { window.open("contact?id=" + member.id, mobileAndTabletCheck() ? "_self" : null) }}
                                      sx={{ cursor: 'pointer' }}
                                      src={
                                        member.images.length > 0
                                          ? member.images.sort(
                                            (a, b) => a.sortOrder - b.sortOrder
                                          )[0].img
                                          : null
                                      }

                                    >{member.FirstName && member.FirstName.length > 0 && member.FirstName[0]}</Avatar>
                                  </Tooltip>
                                ) : (
                                  <div>

                                  </div>
                                )

                              )
                            }
                          })}
                      </AvatarGroup>   </div></>) : (
                  <></>
                )}

              </CardContent>

            </>
            <CardActions sx={{ display: 'flex' }}>

              {
                dataCard.GroupName !== "Me" &&
                  dataCard.GroupName !== "Public Domain" ? (
                  <Tooltip title="Delete">
                    <div>
                      <Button variant="outlined"


                        onClick={() => {

                          removeFromDBandArray(dataCard.id, contactId, setDataIn, dataIn, setFilteredDataIn, filteredDataIn)

                        }}
                      >
                        Delete <DeleteRounded />
                      </Button>

                    </div>
                  </Tooltip>
                ) : (
                  <></>
                )

              }
              {
                items[index].props.GroupName !== null ? (
                  <Tooltip title="Open Group">
                    <div >
                      <Button
                  variant="contained"
                        onClick={async () => {

                          var myArray = mapArray(dataIn);

                          window.open("group?id=" + dataCard.id, mobileAndTabletCheck() ? "_self" : null);
                        }}
                      >
                        Open <FolderOpen />
                      </Button>

                    </div>
                  </Tooltip>
                ) : (
                  <div></div>
                )
                // <div>Me group can not be edited</div>
              }

            </CardActions>
          </Card>
        )
      })}





    </div>
  );
}

export function arrayRemove(array, index) {
  array = array.slice();
  array.splice(index, 1);
  return array;
}

function mapArray(groupIn, setItems) {
  var myArray = [];
  var nfObject = new Intl.NumberFormat("en-US");
  var nfObjectRate = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  var options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    //second: 'numeric',
    hour12: false,
    timeZone: "America/Los_Angeles",
  };
  var nfObjectDate = new Intl.DateTimeFormat("en-US", options);

  groupIn.map((group) =>
    myArray.push(


      <>
        <CardMedia onClick={async () => {

          window.open("group?id=" + group.id, mobileAndTabletCheck() ? "_self" : null);

        }}>
          {group.images ? (
            <SizableDemoCarousel

              key={group.id}
              images={group.images}
            />
          ) : (
            <Typography component={'div'} variant="h5" sx={{ display: 'flex', padding: 1, backgroundColor: '#4e46e5', color: 'white' }}>  <Tooltip placement="top" title={group.GroupName}>
              <Avatar alt={group.GroupName}

                onClick={() => { window.open("group?id=" + group.id, mobileAndTabletCheck() ? "_self" : null) }}
                sx={{ cursor: 'pointer', marginRight: 3 }}
                src={
                  group.images.length > 0
                    ? group.images.sort(
                      (a, b) => a.sortOrder - b.sortOrder
                    )[0].img
                    : null
                }

              >{group.GroupName && group.GroupName.length > 0 && group.GroupName[0]}</Avatar>
            </Tooltip>{group.GroupName} </Typography>
          )}
        </CardMedia>
        <CardContent>
          <Grid2 container key={group.id} GroupName={group.GroupName}>
            <Grid2
              onClick={async () => {

                window.open("group?id=" + group.id, mobileAndTabletCheck() ? "_self" : null);
              }}
            >

              {group.GroupName
                ? group.GroupName
                : "Untitled Group Created " +
                group.createdAt}
            </Grid2>
            <Grid2>
              Created:{" "}
              {nfObjectDate.format(
                new Date(group.createdAt)
              ) || "No Recorded Date"}
            </Grid2>
            <Grid2>
              Updated:{" "}
              {nfObjectDate.format(new Date(group.updatedAt)) ||
                "No Recorded Date"}
            </Grid2>

          </Grid2>
        </CardContent>

      </>

    )
  );

  return myArray;
}

async function deleteGroup(relationshipID, contactId) {
  var qs = "id=" + relationshipID + "&contactId=" + contactId;
  var resultInfo = await fetch(
    process.env.REACT_APP_DATABASE + "api/markDeleteGroup?" +
    qs
  );

}

async function orderGroups(groups, setDataIn) {


  groups.map(async (group, index) => {
    //test - console.log(group.id);
    var qs = "id=" + group.id + "&sortOrder=" + index;
    var resultInfo = await fetch(
      process.env.REACT_APP_DATABASE + "api/sortContactGroups?" +
      qs
    );
    setDataIn(groups);
    return resultInfo;
  });
}

const removeFromDBandArray = (groupId, contactId, setDataIn, dataIn, setFilteredDataIn, filteredDataIn) => {

  //
  deleteGroup(groupId, contactId);
  setDataIn(dataIn.filter((data) => {
    if (data.id !== groupId) {
      return true
    }
  }))
  setFilteredDataIn(filteredDataIn.filter((data) => {
    if (data.id !== groupId) {
      return true
    }
  }))

  


  //return arrayRemove(formattedArray, index)
};
