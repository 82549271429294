/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Person as UserIcon } from '@mui/icons-material'
import OfficeCombo from './OfficeCombo'
import CompanyCombo from './CompanyCombo'
import CompanyCompanyNew from './CompanyCompanyNew'

import CompanyOfficeNew from './CompanyOfficeNew'



async function getData(setOffices, setCompanies) {
  var fetchLocationCompanies = process.env.REACT_APP_DATABASE + "api/getCompanies";
  var fetchLocationOffices = process.env.REACT_APP_DATABASE + "api/getOffices";
  var resultFetchCompanies = await fetch(fetchLocationCompanies)
  var resultCompanies = await resultFetchCompanies.json()
  var resultFetchOffices = await fetch(fetchLocationOffices)
  var resultOffices = await resultFetchOffices.json()
  var companies = []
  var offices = []


  resultOffices.offices.forEach((office, index)=>{
    offices.push({id: office.id, name: office.City + "," + office.State+ " | " + office.StreetNumber + " " + office.StreetName, companyId: office.company_id})
  })
  resultCompanies.companies.forEach((company, index)=>{
    if (offices.find(office => office.companyId === company.id)) {
      companies.push({id: company.id, name:company.Name})
    }
  })


  //test - console.log("GET DATA")
  //test - console.log(offices)
  setOffices(offices)
  setCompanies(companies)

}

export default function CompanyCompanyModal({open, setOpen, office, companyId, setCompany}) {

 
 
  const [resultFetchCompanies, setResultFetchCompanies] = useState();
  const [resultFetchOffices, setResultFetchOffices] = useState();
  const [buttonText, setButtonText] = useState("Select Company")

  const cancelButtonRef = useRef(null);
  const [selectedCompany, setSelectedCompany] = useState({id: office? office.company_id: '', name: office && office.company? office.company.Name : ''});
  const [selectedOffice, setSelectedOffice] = useState( { id: office? office.id: 0, name: office? office.City + " | " + office.StreetNumber + " " + office.StreetName : "Current"})
   const [createNewCompany, setCreateNewCompany] = useState(false);
   const [createNewOffice, setCreateNewOffice] = useState(false);
 

  const [companies,setCompanies] = useState()
  const [offices, setOffices] = useState()



   if (open && !companies) {
    getData(setOffices, setCompanies)
   }
   

   return (

    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-102 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
             
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <UserIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Select or Add Company
                  </Dialog.Title>
                  <div className="mt-2"
                  onClick={()=>{setSelectedOffice({id: 1, name: "Choose an Office"})}}>
              
                     <CompanyCombo companiesCombo={companies} selectedCompany = {selectedCompany} setSelectedCompany = {setSelectedCompany} setButtonText = {setButtonText} setAddCompany={setCreateNewCompany} /> 
                  </div>
                </div>
              </div>
              <div><OfficeCombo createNewCompany = {createNewCompany} offices = {offices} selectedOffice={selectedOffice}  setSelectedOffice = {setSelectedOffice} selectedCompany= {selectedCompany} setCreateNewOffice={setCreateNewOffice} setButtonText={setButtonText}/></div> 

               {createNewCompany ? <div><CompanyCompanyNew/></div> : ""}
               {!createNewCompany && createNewOffice ? <div><CompanyOfficeNew/></div> :""} 
         
              
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm"
                  onClick={() => {
                      //test - console.log("button pushed")
                      //test - console.log(selectedCompany)
                
                    if(buttonText === "Add New Company"){
                      //test - console.log("Add a new company or office here")
                      var company = {
                          'Name' : document.getElementById("Name").value,
                          'Website' : document.getElementById("Website").value,
                          'Notes' : document.getElementById("Notes").value,


                       };
                       var office = {
                           'StreetNumber': document.getElementById("StreetNumber").value,
                           'StreetName': document.getElementById("StreetName").value,
                           'City': document.getElementById("City").value,
                           'State': document.getElementById("State").value,
                           'Zip':  document.getElementById("Zip").value,
                           'StreetNumber': document.getElementById("StreetNumber").value,
                           'Suite': document.getElementById("Suite").value,
                           'Phone': document.getElementById("Phone").value,
                       } 
                       
                         newCompany(company,office, setSelectedCompany, setSelectedOffice, companyId, setCompany)
                       
                      //   updateCompanyOffice(companyId, selectedOffice.id)
                         setOpen(false)
                    }
                    else if(buttonText === "Add New Office"){
                      var office = {
                        'StreetNumber': document.getElementById("StreetNumber").value,
                        'StreetName': document.getElementById("StreetName").value,
                        'City': document.getElementById("City").value,
                        'State': document.getElementById("State").value,
                        'Zip':  document.getElementById("Zip").value,
                        'StreetNumber': document.getElementById("StreetNumber").value,
                        'Suite': document.getElementById("Suite").value,
                        'Phone': document.getElementById("Phone").value,
                    } 
                    
                      newOffice(office, setSelectedOffice, selectedCompany.id, companyId, setCompany)
                    
                   //   updateCompanyOffice(companyId, selectedOffice.id)
                      setOpen(false)
                    }
                    else {
                      //test - console.log("update company or office here")
                         updateCompanyOffice(selectedOffice.id, companyId, setCompany)  
                         setOpen(false)
                        }
                    }
                }
                >
                  {buttonText}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

async function newCompany(company, office, setSelectedCompany, setSelectedOffice, companyId, setCompany) {
  var fetchLocation = process.env.REACT_APP_DATABASE + "api/createCompany?Name=" + company.Name + "&Website=" + company.Website + "&Notes=" + company.Notes 
  var result = await fetch(fetchLocation)
   var myJson = await result.json(); // search results come in an array, and the element is also an array
 
   setSelectedCompany({id: myJson.company.id, name: myJson.company.Name})
   newOffice(office, setSelectedOffice,myJson.company.id, companyId, setCompany)

}

async function newOffice(office, setSelectedOffice, selectedCompanyId, companyId, setCompany) {
  var geoCode = await fetch("https://api.mapbox.com/geocoding/v5/mapbox.places/" + 
    office.StreetNumber + "%20" + 
    office.StreetName + "%20" + 
    office.City+".json?proximity=ip&types=place%2Cpostcode%2Caddress&access_token=pk.eyJ1Ijoic2Nob3JkciIsImEiOiJja3ZxNGNzY3hlb2Y4MnBtYWwyZDF3MDlxIn0.lgiBtS4rcWKx0s-tNdykmg")
  var mycode = await geoCode.json()
  //test - console.log(mycode)
   office.Longitude = mycode.features[0].center[0];
   office.Latitude = mycode.features[0].center[1];
  var fetchLocation = process.env.REACT_APP_DATABASE + "api/createOffice?companyId="+ selectedCompanyId +"&&" + Object.keys(office).map(key=>key+'='+office[key]).join('&')
  //test - console.log(fetchLocation)
  var result = await fetch(fetchLocation)
  var myJson = await result.json(); // search results come in an array, and the element is also an array
  
  setSelectedOffice({id: myJson.office.id, name: myJson.office.City + ", " + myJson.office.State +" | " + myJson.office.StreetNumber + " " + myJson.office.StreetName} )
  updateCompanyOffice( myJson.office.id, companyId,setCompany)
}
async function updateCompanyOffice( officeId, companyId, setCompany) {
    var fetchLocation = process.env.REACT_APP_DATABASE + "api/updateCompanyOffice?id=" + companyId + "&&companyId="+ companyId + "&&officeId=" + officeId 
    const resultInfo = await fetch(fetchLocation);
    const data = await resultInfo.json();
   var myJson = data.company; // search results come in an array, and the element is also an array

   setCompany(myJson)
}

