import React, { useState, useEffect, useCallback } from 'react';
import { Autocomplete, Button, TextField, Box } from '@mui/material';
import { Check, Search } from '@mui/icons-material';
import axios from 'axios';
import { debounce } from 'lodash';
import { CircularProgress } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

function sortByWordMatchAndInitials(options, query) {
  // Split the query into words and filter out empty strings
  const queryWords = query.toLowerCase().split(' ').filter(Boolean);

  return options
    .map(option => {
      // Split the name into words for comparison
      const nameWords = option.name.toLowerCase().split(' ').filter(Boolean);
      // Calculate score
      const score = queryWords.reduce((acc, queryWord) => {
        // Check for whole word matches
        const wholeWordScore = nameWords.includes(queryWord) ? 1 : 0;
        // Check for initial part matches
        const initialPartScore = nameWords.some(nameWord => nameWord.startsWith(queryWord)) ? 0.5 : 0;
        return acc + wholeWordScore + initialPartScore;
      }, 0);
      return { ...option, score };
    })
    .sort((a, b) => b.score - a.score) // Sort by score, descending
    .map(option => {
      // Optionally, remove the score from the final objects
      const { score, ...rest } = option;
      return rest;
    });
}
function ContactLookup({ selectedPerson, setSelectedPerson, setButtonText, setAddPerson, setName, open, contactId, setNewText }) {
  const [query, setQuery] = useState('');
  const [peopleCombo, setPeopleCombo] = useState([]);
  const [isListboxOpen, setIsListboxOpen] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateContact = () => {
    if (setNewText) {
      setNewText(textFieldValue)
    }
    setAddPerson(true)
    // Logic to open a dialog or navigate to a contact creation form
  };
  const handleSearch = useCallback(async (value) => {
    setIsLoading(true);
    setQuery(value);

    if (!value.trim()) {
      setPeopleCombo([]);
      // setAddPerson(false);
      setIsLoading(false);
      return;
    }

    try {
      const comboResults = await getContactsAxios(contactId, value);

      if (comboResults?.contact) {
        // Directly use the fetched results without any frontend filtering
        const sortedPeopleCombo = sortByWordMatchAndInitials(comboResults.contact.map(person => ({
          id: person.id,
          name: `${person.FirstName} ${person.LastName} ${person.Email} - ${checkOffice(person)}`,
          imageURL: person.images.length > 0 ? person.images[0].img : "https://images.cubicsearch.com/UserLogo.png",
        })), query);

        setPeopleCombo(sortedPeopleCombo);

        setIsListboxOpen(true);
        setAddPerson(false)
      }
      else {

        setPeopleCombo([])
      }
    } catch (error) {
      console.error('Error fetching contacts:', error);
    } finally {
      setIsLoading(false);
    }
  }, [contactId]);

  // Using useEffect to create a stable reference for the debounced function
  const debouncedOnChange = useCallback(
    debounce((value) => handleSearch(value), 750),
    [handleSearch] // Recreate the debounced function only if handleSearch changes
  );

  // Cleanup the debounce on component unmount to avoid memory leaks
  useEffect(() => {
    return () => debouncedOnChange.cancel();
  }, [debouncedOnChange]);



  const handleTextFieldChange = (event) => {

    if (event.target.value.trim() === "" || !event.target.value)
      setPeopleCombo([])
    else {
      setTextFieldValue(event.target.value);
      debouncedOnChange(event.target.value);
    }
  };

  const handleChange = (event, newValue) => {
    setSelectedPerson(newValue); // Set the selected person based on the new value
    if (newValue) {
      setButtonText("Select Contact"); // Example of setting button text accordingly
      setAddPerson(false); // Assuming this means a person is now selected, so "add person" mode is off
      setName(`${newValue.FirstName} ${newValue.LastName}`); // Just an example, adjust according to your state structure
    } else {
      // Handle the case where the selection is cleared
      setButtonText("Add New Contact");
      setAddPerson(true);
      setName(''); // Clear the name if the selection is cleared
    }
    setIsListboxOpen(false); // Optionally close the listbox, if required
    setTextFieldValue(''); // Clear the TextField, if required
  };




  return (
    <Autocomplete
      // other props...
      options={peopleCombo}
      open={isListboxOpen}
      onChange={handleChange}
      onOpen={() => setIsListboxOpen(true)}
      onClose={() => setIsListboxOpen(false)}
      filterOptions={(options) => options}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <li {...props}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {option.imageURL && (
              <img src={option.imageURL} alt="" style={{ width: 24, height: 24, marginRight: 8 }} />
            )}
            {option.name}
          </div>
        </li>
      )}

      onInputChange={(event, newInputValue) => {
        // Clear the dropdown list if the input is cleared
        if (newInputValue === '') {
          setPeopleCombo([]);
          setTextFieldValue(''); // Also clear the text field value if needed
        } else {
          // Optionally, update the text field value to the new input value
          // This is not strictly necessary if you're already handling this in handleTextFieldChange
          setTextFieldValue(newInputValue);
        }
        // Trigger the search with the new input value, if you wish to fetch new options based on partial input
        debouncedOnChange(newInputValue);
      }}
      noOptionsText={
        <Box textAlign="center">
          <Button
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleCreateContact}
            // Prevent the dropdown from closing when the button is clicked
            onMouseDown={(event) => event.preventDefault()}
          >
            Create new contact
          </Button>
        </Box>
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Contact Name"
          onChange={handleTextFieldChange}
          value={textFieldValue}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}


async function getContactsAxios(contactId, query) {
  try {
    const response = await axios.get(process.env.REACT_APP_DATABASE + `api/getContacts?contactId=${contactId}&query=${encodeURI(query)}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching contacts:', error);
    return null;
  }
}



function checkOffice(person) {
  return person.office?.company?.Name || "Add Company";
}

export default ContactLookup;
