import React from 'react';
import { Button, Box, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import axios from 'axios';

export  const DangerZone = ({ mailerId, onDeleteSuccess }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const deleteMailer = async () => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_DATABASE}api/campaignMailer/${mailerId}`);
            if (response.status === 200) {
                onDeleteSuccess(mailerId);
                handleClose();
            } else {
                console.error('Failed to delete the mailer');
            }
        } catch (error) {
            console.error('Error deleting mailer:', error);
        }
    };

    return (
        <Box>
            <Button variant="outlined" color="error" disabled={!mailerId} onClick={handleClickOpen}>
                Delete Mailer
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this mailer? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={deleteMailer} autoFocus color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
