import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { Box, Button, CircularProgress } from '@mui/material';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_P);

const PaymentMethodChangeForm = ({ subscriptionId, customerId, clientSecret }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!stripe || !elements) {
            return;
        }

        const { error, setupIntent } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: window.location.href,
            },
            redirect: 'if_required',
        });

        if (error) {
            console.error('Error confirming setup:', error);
            alert('Error confirming setup');
        } else {
            try {
                await axios.post(`${process.env.REACT_APP_DATABASE}api/updateSubscriptionPaymentMethod`, {
                    subscriptionId: subscriptionId,
                    paymentMethodId: setupIntent.payment_method,
                });

                alert('Payment method updated successfully');
            } catch (error) {
                console.error('Error updating payment method:', error);
                alert('Failed to update payment method');
            }
        }

        setLoading(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <Button type="submit" variant="contained" color="primary" disabled={!stripe || loading} sx={{ mt: 2 }}>
                {loading ? <CircularProgress size={24} /> : 'Update Payment Method'}
            </Button>
        </form>
    );
};

const PaymentMethodChange = ({ subscriptionId, customerId }) => {
    const [clientSecret, setClientSecret] = useState('');

    useEffect(() => {
        const fetchClientSecret = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_DATABASE}api/createSetupIntent`, {
                    customerId: customerId,
                });
                setClientSecret(response.data.clientSecret);
            } catch (error) {
                console.error('Error fetching client secret:', error);
            }
        };

        fetchClientSecret();
    }, [customerId]);

    return (
        <Box>
            {clientSecret ? (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <PaymentMethodChangeForm subscriptionId={subscriptionId} customerId={customerId} clientSecret={clientSecret} />
                </Elements>
            ) : (
                <CircularProgress />
            )}
        </Box>
    );
};

export default PaymentMethodChange;
