import React, { useEffect, useState } from 'react';
import { Button, Avatar, Dialog, DialogTitle, Typography, DialogContent, DialogActions, List, ListItem, IconButton, ListItemAvatar, ListItemText } from '@mui/material';
import { AutocompleteAsyncCompany, AutocompleteAsyncGeneral, AutocompleteAsyncSpace, AutocompleteAsyncBuilding } from './AutocompleteAsync';
import DeleteIcon from '@mui/icons-material/Delete';
import { ImageWithAspectRatio } from './AutocompleteAsync';

import axios from 'axios';

import CircularProgress from '@mui/material/CircularProgress';

import EntityTypeDialog from './EntityTypeDialog';


export function ApiCallButton({ onClose, api, entityIds, campaignId, entityType, updateCampaignEntities, saveCampaign }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleClick = async () => {
        setLoading(true);
        setError('');
        try {
            // Replace with your actual API call
            var result = await saveCampaign()
          
            // Handle success
            
            await axios.post(api, { campaignId: campaignId, entityIds: entityIds, entityType: entityType }).catch((error)=>{console.log(error)})
            onClose(); // Call onClose prop function on success
        } catch (error) {
            setError('Failed to fetch data. Please try again.'); // Set error message on failure
        } finally {

          
            updateCampaignEntities()
            setLoading(false); // Ensure loading is false both on success and failure
            
        }
    };

    return (
        <div>
            {error && alert(error)}
            <Button
                variant="contained"
                onClick={handleClick}
                disabled={loading}
                startIcon={loading && <CircularProgress size={24} />}
            >
                {loading ? 'Loading...' : 'Update Campaign'}
            </Button>

        </div>
    );
}


const PersonModalEdit = ({ updateCampaignEntities, open, onClose, contact, contactId, theData, campaignId, saveCampaign }) => {
    const [selectedContact, setSelectedContact] = useState(null)
    const [showProspectDialog, setShowProspectDialog] = useState(false)

    useEffect(() => {
        
        if (theData?.contact?.FirstName) {
            setSelectedContact({
                name: theData?.contact?.FirstName + " " + theData?.contact?.LastName,
                email: theData?.contact?.Email,
                avatar: (theData?.contact?.images?.[0]?.img || null),
                id: theData?.contact?.id
            })
        }
    }, [theData])


    return (
        <Dialog
            maxWidth={'md'} open={open} onClose={onClose}>
            <DialogTitle sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 20, marginRight: 20 }}>
                <Avatar src='https://images.cubicsearch.com/UserLogo.png' >
                </Avatar> Select a Person
            </DialogTitle>
            <DialogContent sx={{ marginTop: 5, padding: 5 }}>
                <div style={{ padding: 5 }}>
                    <AutocompleteAsyncGeneral selectedcontact={selectedContact}
                        handleContactChange={(newContact) => {
                            setSelectedContact(newContact)
                            onClose()
                            //set info for creation of new campaign
                            //create campaign
                        }
                        } contactId={contactId} />
                </div>
            </DialogContent>
            <DialogActions>
                <ApiCallButton onClose={onClose}
                    updateCampaignEntities={updateCampaignEntities}
                    entityType={'contacts'}
                    api={process.env.REACT_APP_DATABASE + 'api/updateCampaignIds'}
                    entityIds={[selectedContact?.id]}
                    campaignId={campaignId}
                    saveCampaign={saveCampaign}
                    
                />

                <Button variant='contained' onClick={() => { onClose() }} sx={{ marginRight: 5 }}>Cancel</Button>
            </DialogActions>

            {/* <NewEmailCampaignDialog id={contactId} open={showProspectDialog} setOpen={setShowProspectDialog} entityType={'Contact'} entityIds={[selectedContact]} /> */}
            {/* <ProspectOptionsDialog open={showProspectDialog} onClose={() => { setShowProspectDialog(false) }} /> */}
            {/* Implement person selection logic here */}
        </Dialog>
    );
}
const CompanyModalEdit = ({ updateCampaignEntities, open, onClose, company, contactId, theData, campaignId, saveCampaign }) => {
    const [selectedCompany, setSelectedCompany] = useState(null)
    const [showProspectDialog, setShowProspectDialog] = useState(false)

    useEffect(() => {
        setSelectedCompany({ name: theData?.company?.Name, website: theData?.company?.Website, avatar: theData?.company?.images?.[0]?.img || null, id: theData?.company?.id })
    }, [theData])
    return (
        <Dialog
            maxWidth={'md'} open={open} onClose={onClose}>
            <DialogTitle sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 20, marginRight: 20 }}>
                <Avatar src='https://images.cubicsearch.com/CompanyLogo.png' >
                </Avatar> Select a Company
            </DialogTitle>
            <DialogContent sx={{ marginTop: 5, padding: 5 }}>
                <div style={{ padding: 5 }}>
                    <AutocompleteAsyncCompany selectedcompany={selectedCompany}
                        handleCompanyChange={(newCompany) => {
                            setSelectedCompany(newCompany)
                            onClose()
                            //set info for creation of new campaign
                            //create campaign
                        }
                        } contactId={contactId} />
                </div>
            </DialogContent>
            <DialogActions>
                <ApiCallButton onClose={onClose}
                    updateCampaignEntities={updateCampaignEntities}
                    entityType={'companies'}
                    api={process.env.REACT_APP_DATABASE + 'api/updateCampaignIds'}
                    entityIds={[selectedCompany?.id]}
                    campaignId={campaignId}
                    saveCampaign={saveCampaign}
                />
                <Button variant='contained' onClick={() => { onClose() }} sx={{ marginRight: 5 }}>Cancel</Button>
            </DialogActions>
            {/* <NewEmailCampaignDialog id={contactId} open={showProspectDialog} setOpen={setShowProspectDialog} entityType={'Company'} entityIds={[selectedCompany]} /> */}
            {/* <ProspectOptionsDialog open={showProspectDialog} onClose={() => { setShowProspectDialog(false) }} /> */}
            {/* Implement company selection logic here */}
        </Dialog>
    );
}

const SpaceModalEdit = ({ updateCampaignEntities, open, onClose, space, contactId, theData, campaignId, saveCampaign }) => {

    const [showProspectDialog, setShowProspectDialog] = useState(false)
    const [selectedSpaces, setSelectedSpaces] = useState([]);


    useEffect(() => {
        const adjustedData = theData?.map((data) => spaceMold(data))
        setSelectedSpaces(adjustedData)
    }, theData)
    // Handler to add a new space to the list
    const handleAddSpace = (newSpace) => {
        if (newSpace?.id) {
            setSelectedSpaces(prevSpaces => [...prevSpaces, newSpace]);
        }
        
    };

    // Handler to remove a space from the list
    const handleRemoveSpace = (indexToRemove) => {
        setSelectedSpaces(prevSpaces => prevSpaces.filter((_, index) => index !== indexToRemove));
    };

    const handleProspectSelection = async () => {
        await axios.post(process.env.REACT_APP_DATABASE + "api/getProspects", { contactId: contactId, entityArray: selectedSpaces, entityType: 'space' })
    }

    return (
        <Dialog maxWidth={'md'} open={open} onClose={onClose}>
            <DialogTitle sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 20, marginRight: 20 }}>
                Add Spaces to Marketing Campaign
            </DialogTitle>
            <DialogContent sx={{ marginTop: 5, padding: 5 }}>
                <div style={{ padding: 5 }}>
                    {/* Your Autocomplete component for selecting a space */}
                    <AutocompleteAsyncSpace
                        handleSpaceChange={handleAddSpace}
                        contactId={contactId}
                    />
                </div>
                {/* List selected spaces */}
                <Typography>Selected Spaces</Typography>
                <List>
                    {selectedSpaces?.map((space, index) => {

                        return (
                            <ListItem
                                key={index}
                                secondaryAction={
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveSpace(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            >
                                <ListItemAvatar>
                                    <ImageWithAspectRatio src={space?.avatar || 'https://images.cubicsearch.com/small%20spaces.png'} name={space?.label} />
                                </ListItemAvatar>
                                <ListItemText primary={space?.label} />
                            </ListItem>
                        )
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <ApiCallButton onClose={onClose}
                    updateCampaignEntities={updateCampaignEntities}
                    entityType={'avails'}
                    api={process.env.REACT_APP_DATABASE + 'api/updateCampaignIds'}
                    entityIds={selectedSpaces.map((avail) => avail.id)}
                    campaignId={campaignId}
                    saveCampaign={saveCampaign}
                    
                />
                <Button variant='contained' onClick={onClose} sx={{ marginRight: 5 }}>Cancel</Button>
            </DialogActions>
            {/* <UpdateButton  entityType={'Space'} entityIds={selectedSpaces} /> */}
            {/* <ProspectOptionsDialog open={showProspectDialog} onSelectOption={handleProspectSelection} onClose={() => { setShowProspectDialog(false) }} /> */}
        </Dialog>
    );
};

const spaceMold = (datum) => {
    var image = null;
    if (datum.images.length > 0) {
        datum.images.sort((a, b) => a.sortOrder - b.sortOrder);
        image = datum.images[0].img;
    }

    var statusAndSF = ""
    if (datum?.Status === "Lease Comp") {
        statusAndSF =
            (datum?.LeasedSpace ? "Lease Comp: " + datum?.LeasedSpace.toLocaleString() + " SF" : "")
        if (datum?.ExecutionDate) {
            statusAndSF = statusAndSF + " Signed: " + new Date(datum?.ExecutionDate).toLocaleDateString()
        }
    }
    else {
        statusAndSF = (datum?.SizeMax ? "Available: " + datum?.SizeMax.toLocaleString() + " SF" : "")
    }


    return {
        label: datum?.building?.StreetNumber + " " +
            datum?.building?.StreetName + ", " + datum?.building?.City + " " + datum?.building?.State,
        space:
            (datum?.SubleaseOrDirect ? " " + datum?.SubleaseOrDirect + " | " : "") +
            (datum?.Suite ? " Suite: " + datum?.Suite : "") +
            (datum?.Floor && datum?.Floor > 0 ? " Floor: " + datum?.Floor : "") +
            (datum?.PropertyType ? " Type: " + datum?.PropertyType : "")
        ,
        avatar: datum?.images?.[0]?.img || datum?.building?.images?.[0]?.img || 'https://images.cubicsearch.com/small%20spaces.png',
        sf: statusAndSF,
        id: datum?.id,

    };
}

const buildingMold = (datum) => {
    var image = null;
    if (datum?.images?.length > 0) {
        datum.images.sort((a, b) => a.sortOrder - b.sortOrder);
        image = datum.images[0].img;
    }

    if (datum) {
        return {
            label: datum?.StreetNumber + " " +
                datum?.StreetName + ", " + datum?.City + " " + datum?.State,
            building:
                datum?.StreetNumber + " " +
                datum?.StreetName + ", " + datum?.City + " " + datum?.State
            ,
            avatar: datum?.images?.[0]?.img || 'https://images.cubicsearch.com/small%20logo.png',
            propertyName: (datum?.PropertyName || datum?.CampusName ? (datum?.PropertyName || datum?.CampusName) : ""),
            id: datum?.id
        };
    }
    else return ([])
}

const BuildingModalEdit = ({ updateCampaignEntities, open, onClose, building, contactId, theData, campaignId, saveCampaign }) => {
    const [showProspectDialog, setShowProspectDialog] = useState(false)
    const [selectedBuildings, setSelectedBuildings] = useState(theData.map((data) => buildingMold(data)));

    // Handler to add a new building to the list
    const handleAddBuilding = (newBuilding) => {
        if (newBuilding?.id) {
            setSelectedBuildings(prevBuildings => [...prevBuildings, newBuilding]);
        }
    };

    // Handler to remove a building from the list
    const handleRemoveBuilding = (indexToRemove) => {
        setSelectedBuildings(prevBuildings => prevBuildings.filter((_, index) => index !== indexToRemove));
    };


    return (
        <Dialog maxWidth={'md'} open={open} onClose={onClose}>
            <DialogTitle sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 20, marginRight: 20 }}>
                Add Buildings to Marketing Campaign
            </DialogTitle>
            <DialogContent sx={{ marginTop: 5, padding: 5 }}>
                <div style={{ padding: 5 }}>
                    {/* Your Autocomplete component for selecting a building */}
                    <AutocompleteAsyncBuilding
                        handleBuildingChange={handleAddBuilding}
                        contactId={contactId}
                    />
                </div>
                {/* List selected buildings */}
                <Typography>Selected Buildings</Typography>
                <List>
                    {selectedBuildings && selectedBuildings.map((building, index) => (
                        <ListItem
                            key={index}
                            secondaryAction={
                                <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveBuilding(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            }
                        >
                            <ListItemAvatar>
                                <ImageWithAspectRatio src={building?.avatar || 'https://images.cubicsearch.com/small%logo.png'} />
                            </ListItemAvatar>
                            <ListItemText primary={building?.label} />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <ApiCallButton onClose={onClose}
                    updateCampaignEntities={updateCampaignEntities}
                    entityType={'buildings'}
                    api={process.env.REACT_APP_DATABASE + 'api/updateCampaignIds'}
                    entityIds={selectedBuildings.map((building) => building.id)}
                    campaignId={campaignId}
                            saveCampaign={saveCampaign}
                />
                <Button variant='contained' onClick={onClose} sx={{ marginRight: 5 }}>Cancel</Button>
            </DialogActions>
            {/* <UpdateCampaignDialog open={showProspectDialog} setOpen={setShowProspectDialog} entityType={'Building'} entityIds={selectedBuildings} id={contactId} /> */}
            {/* <ProspectOptionsDialog open={showProspectDialog} onClose={() => { setShowProspectDialog(false) }} /> */}
        </Dialog>
    );
};


export const EntityTypeSelectionFormEdit = ({ campaign, contact, open, onClose, setEmailDetails }) => {

    const [theData, setTheData] = useState(null)
    const [loading, setLoading] = useState(false)

 
    useEffect(() => {
        const lookupData = async () => {
            var ids = null
            var qs = null
            if (campaign?.contactIds) {
                ids = { id: campaign?.contactIds?.[0], contactId: contact.id }
                qs = "getContact"
            }
            else if (campaign?.companyIds) {
                ids = { id: campaign?.companyIds?.[0], contactId: contact.id }
                qs = "getCompany"
            }
            else if (campaign?.availIds) {
                ids = { ids: campaign?.availIds }
                qs = "getAvailsEntities"
            } else if (campaign?.buildingIds) {
                ids = { ids: campaign?.buildingIds }
                qs = "getBuildingsEntities"
            }

            
            if (qs && ids) {

                var theDataReturn = await axios.post(process.env.REACT_APP_DATABASE + "api/" + qs, ids).catch(error => {
                    
                    console.log(error)
                })
                
                // alert(JSON.stringify(theDataReturn.data))
             
                setTheData(theDataReturn?.data || [])
             
            }
        }

        if (open) {
            lookupData()
        }

    }, [open])


    const saveCampaign = async () => {
        if (campaign?.params) {
            campaign.params.contactId = contact.id
        }
        else {
            campaign.params = {}
            campaign.params.contactId = contact.id
        }
        const result = await axios.put(`${process.env.REACT_APP_DATABASE}api/campaigns/${campaign.id}`, campaign).catch((error) => { console.log(error) })

        if (result) {
            return result
        }
        else {
            return false
        }
    }

    const updateCampaignEntities = async () => {



        var response = await axios.get(`${process.env.REACT_APP_DATABASE}api/campaigns/${campaign.id}`, {
            params: {
                contactId: contact.id,
            },
        }).catch(error => console.error('Error fetching campaign:', error));


        var frequencyDates = response.data.campaignMailers.map((campaignMailer) => campaignMailer.sendDate)

        if (!response?.data?.frequency?.specificDates) {
            response.data.frequency = { frequencyTyp: 'specific', frequencyDates: [] }
        }
        else {
            response.data.frequency.specificDates = frequencyDates || []
        }
        setEmailDetails(response.data);
        setLoading(false)




    }

    // alert(JSON.stringify(campaign?.contactIds))
    // alert(JSON.stringify(campaign?.companyIds))
    // alert(JSON.stringify(campaign?.availIds))
    // alert(JSON.stringify(campaign?.buildingIds))

    if (theData) {
       
        return (
            <>
                {campaign?.contactIds && (
                    <PersonModalEdit saveCampaign={saveCampaign} updateCampaignEntities={updateCampaignEntities} open={open} onClose={onClose} contact={contact} contactId={theData?.contact?.id} entityIds={campaign?.contactIds} theData={theData} campaignId={campaign?.id} />
                )}
                {campaign?.companyIds && (
                    <CompanyModalEdit saveCampaign={saveCampaign} updateCampaignEntities={updateCampaignEntities} open={open} onClose={onClose} company={contact?.office?.company} contactId={contact.id} entityIds={campaign?.companyIds} theData={theData} campaignId={campaign?.id} />
                )}
                {campaign?.availIds && (
                    <SpaceModalEdit saveCampaign={saveCampaign} updateCampaignEntities={updateCampaignEntities} open={open} onClose={onClose} contactId={contact.id} entityIds={campaign?.availIds} theData={theData} campaignId={campaign?.id} />
                )}
                {campaign?.buildingIds && (
                    <BuildingModalEdit saveCampaign={saveCampaign} updateCampaignEntities={updateCampaignEntities} open={open} onClose={onClose} contactId={contact.id} entityIds={campaign?.buildingIds} theData={theData} campaignId={campaign?.id} />
                )}
              

            </>

        );
    }
    else {

        return <>  {
            (!(campaign?.contactIds || campaign?.companyIds || campaign?.availIds || campaign?.buildingIds)) &&
            (
                <EntityTypeDialog open={open} onClose={onClose} setCampaignDetails={setEmailDetails} />
            )
        }</>
    }
};

