import { Typography } from "@mui/material";
import SizableDemoCarousel from "../Images/Carousel";
import { mobileAndTabletCheck } from "../Search/Deck";
import { AvailData, CompData } from "../Search/InfoCard";
import {Card, Paper} from "@mui/material";
export default function AvailListRows({ availsIn, availOrComp }) {
  
  if (availsIn.length > 0) {
    availsIn = availsIn.filter((avail) => {
      avail.avail_groups = avail.avail_groups.filter((avail_group) => {
        return (avail_group.markedDelete === 0)
      })

      return (avail.avail_groups.length >0)
    })
    return (
      
      tableRows(availsIn, availOrComp))
  } else {
    return <></>;
  }
}



var nfObject = new Intl.NumberFormat("en-US");
var nfObjectRate = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
var nfObjectDate = new Intl.DateTimeFormat("en-US");

function tableRows(availsIn, availOrComp) {


  return (availsIn.map((eachDatum, index) => {
    if (availOrComp !== "Comp") {
      return (
        
        <Paper key={"avail"+index } elevation={6} sx={{ marginTop: 1, marginBottom : 4, marginLeft: 2, marginRight: 2 }}>
      {AvailData(eachDatum)}
       </Paper >
    )
    }
    else {
      // <div key={"comp"+ index}>
    return(  <Paper key={"comp" + index} elevation={6} sx={{ marginTop: 1, marginBottom: 4, marginLeft: 2, marginRight: 2, padding: 4 }}>
        {CompData(eachDatum)}
      </Paper >)
    }
   
  })
  
  )
    
}

// function AvailData(availsIn) {
  
//   return (availsIn.map((available, index) =>
//   {
//     var condensedAvailGroups = []
//     available.avail_groups.map((avail_group) => {
//       if (!condensedAvailGroups.find((groupToFind) => {
//         return groupToFind.group.id === avail_group.group.id
//       })) {
//         condensedAvailGroups.push (avail_group)
//       }
//     })
//     available.avail_groups = condensedAvailGroups
    
//     return (



//     <tr className="hover:bg-gray-200  cursor-pointer" key={index}>
//       <td className="h-40 w-80 flex-shrink-0 items-center object-cover">
//         <div className="w-80">
//           <SizableDemoCarousel
//             key={available.id}
//             images={available.images}
//             goToURL={"available?id=" + available.id}
           
//           />
//         </div>
//         <div
//           onClick={async () => {
//             window.open("available?id=" + available.id, mobileAndTabletCheck()?"_self": null);
//           }}
//         >
//           <div className="px-6 ">
//             <div className="font-medium text-gray-900">
//               {available.Floor !== 0 &&
//               available.Floor !== null &&
//               available.Floor !== "null"
//                 ? available.Floor === -1
//                   ? "Entire Building"
//                   : "Floor " + available.Floor
//                 : "Please Add Floor"}
//             </div>
//             <div className="font-medium text-gray-500">
//               {" "}
//               {available.Suite !== "0" && available.Suite
//                 ? "Suite " + available.Suite
//                 : "Please Add Suite"}
//             </div>
//           </div>
//         </div>
//       </td>
//       <td
//         onClick={async () => {
//           window.open("available?id=" + available.id, mobileAndTabletCheck()?"_self": null);
//         }}
//         className="whitespace-nowrapx py-4 pl-4 pr-3 text-sm sm:pl-6"
//       >
//         <div className="font-medium text-gray-900">
//           {nfObject.format(available.SizeMin)} -{" "}
//           {nfObject.format(available.SizeMax)} SF
//         </div>
//         <div className="font-medium text-gray-500">
//           {available.AskRate > 0
//             ? nfObjectRate.format(available.AskRate) + " / SF / Month"
//             : "Please Inquire"}{" "}
//         </div>
//         <div>{available.SubleaseOrDirect}</div>
//       </td>
//       <td
//         onClick={async () => {
//           window.open("available?id=" + available.id, mobileAndTabletCheck()?"_self": null);
//         }}
//         className="whitespace-nowrapx py-4 pl-4 pr-3 text-sm sm:pl-6"
//       >
//         <div className="font-medium text-gray-900">
//           {available?.DateOccupancy
//             ? nfObjectDate.format(new Date(available.DateOccupancy))
//             : ""}
//         </div>
//       </td>
//       <td
//         onClick={async () => {
//           window.open("available?id=" + available.id, mobileAndTabletCheck()?"_self": null);
//         }}
//         className="whitespace-nowrapx py-4 pl-4 pr-3 text-xs sm:pl-6"
//       >
//         <div className="font-medium text-gray-900">
//           {available.Condition || "Contact agent or owner"}
//         </div>
//       </td>
//       <td
//         onClick={async () => {
//           window.open("available?id=" + available.id, mobileAndTabletCheck()?"_self": null);
//         }}
//         className="whitespace-nowrapx py-4 pl-4 pr-3 text-xs sm:pl-6"
//       >
//         <div className="font-medium text-gray-900 whitespace-pre-line">
//           {available.avail_groups.map((avail_group, index) => {
          
//             // return <div>{avail_group.group.GroupName} - {avail_group.groupPermission}</div>;
//             return (
//               <div className="py-2" key={"AvailGroups" + index}>
//                 {" "}
//                 Group Name : {avail_group.group.GroupName}
//                 {"\n"} Avail Permission : {avail_group.groupPermission}
//               </div>
//             );
//           }) || "Contact agent or owner"}
//         </div>
//       </td>
//       <td
//         onClick={async () => {
//           window.open("available?id=" + available.id, mobileAndTabletCheck()?"_self": null);
//         }}
//         className="whitespace-nowrapx py-4 pl-4 pr-3 text-sm sm:pl-6"
//       >
//         {available.contacts
//           .sort((a, b) => a.avail_contact.sortOrder - b.avail_contact.sortOrder)
//           .map((contact, index) => (
//             <div className="flex items-center py-1" key={"contact" + index}>
//               <div className="h-10 w-10 rounded-full">
//                 <img
//                   className="h-10 w-10 flex-shrink-0 rounded-full object-cover"
//                   src={
//                     contact.images.length > 0
//                       ? contact.images.sort(
//                           (a, b) => a.sortOrder - b.sortOrder
//                         )[0].img
//                       : "https://images.cubicsearch.com/UserLogo.png"
//                   }
//                   alt=""
//                 />
//               </div>
//               <div className="ml-4">
//                 <div className="font-medium text-gray-900">
//                   {contact.FirstName} {contact.LastName}
//                 </div>
//                 <div className="text-gray-500">
//                   {contact.office && contact.office.company
//                     ? contact.office.company.Name
//                     : "Add Company"}
//                 </div>
//               </div>
//             </div>
//           ))}
//       </td>
//     </tr>
//   )}
  
//   ))
// }

// function CompData(availsIn) {
  
//   return availsIn.map((available, index) => (
//     <tr className="hover:bg-gray-200  cursor-pointer" key={"availsIn" + index}>
//       <td className="h-40 w-80 flex-shrink-0 items-center object-cover">
//         <div className="w-80">
//           <SizableDemoCarousel
//             key={available.id}
//             images={available.images}
//             goToURL={"available?id=" + available.id}
//           />
//         </div>
//         <div
//           onClick={async () => {
//             window.open("available?id=" + available.id, mobileAndTabletCheck()?"_self": null);
//           }}
//         >
//           <div className="px-6 ">
//             <div>{available.Tenant}</div>
//             <div className="font-medium text-gray-900">
//               {available.Floor !== 0 &&
//               available.Floor !== null &&
//               available.Floor !== "null"
//                 ? available.Floor === -1
//                   ? "Entire Building"
//                   : "Floor " + available.Floor
//                 : "Please Add Floor"}
//             </div>
//             <div className="font-medium text-gray-500">
//               {" "}
//               {available.Suite !== "0" && available.Suite
//                 ? "Suite " + available.Suite
//                 : "Please Add Suite"}
//             </div>
//           </div>
//         </div>
//       </td>
//       <td
//         onClick={async () => {
//           window.open("available?id=" + available.id, mobileAndTabletCheck()?"_self": null);
//         }}
//         className="whitespace-nowrapx py-4 pl-4 pr-3 text-sm sm:pl-6"
//       >
//         <div className="font-medium text-gray-900">
//           {nfObject.format(available.LeasedSpace)} SF
//         </div>
//         <div className="font-medium text-gray-500">
//           {available.InitialRent > 0
//             ? nfObjectRate.format(available.InitialRent) + " / SF / Month"
//             : "Please Inquire"}{" "}
//         </div>
//         <div>{available.SubleaseOrDirect}</div>
//       </td>
//       <td
//         onClick={async () => {
//           window.open("available?id=" + available.id, mobileAndTabletCheck()?"_self": null);
//         }}
//         className="whitespace-nowrapx py-4 pl-4 pr-3 text-sm sm:pl-6"
//       >
//         <div className="font-medium text-gray-900">
//           {available?.DateOccupancy
//             ? nfObjectDate.format(new Date(available.DateOccupancy))
//             + " Occupancy": ""}{" "}
          
//         </div>
//         <div className="font-medium text-gray-900">
//           {available?.ExecutionDate
//             ? nfObjectDate.format(new Date(available.ExecutionDate))
//             + " Execution": ""}{" "}
          
//         </div>
//         <div className="font-medium text-gray-900">
//           {available?.CommencementDate
//             ? nfObjectDate.format(new Date(available.CommencementDate))
//            + " Commencement" : ""}{" "}
          
//         </div>
//       </td>
//       <td
//         onClick={async () => {
//           window.open("available?id=" + available.id, mobileAndTabletCheck()?"_self": null);
//         }}
//         className="whitespace-nowrapx py-4 pl-4 pr-3 text-xs sm:pl-6"
//       >
//         <div className="font-medium text-gray-900">
//           {available.Condition || "Contact agent or owner"}
//         </div>
//       </td>
//       <td
//         onClick={async () => {
//           window.open("available?id=" + available.id, mobileAndTabletCheck()?"_self": null);
//         }}
//         className="whitespace-nowrapx py-4 pl-4 pr-3 text-xs sm:pl-6"
//       >
//         <div className="font-medium text-gray-900 whitespace-pre-line">
//           {available.avail_groups.map((avail_group, index) => {
//             // return <div>{avail_group.group.GroupName} - {avail_group.groupPermission}</div>;
//             return (
//               <div className="py-2" key={"avail_groups" + index}>
//                 {" "}
//                 Group Name : {avail_group.group.GroupName}
//                 {"\n"} Comp Permission : {avail_group.groupPermission}
//               </div>
//             );
//           }) || "Contact agent or owner"}
//         </div>
//       </td>
//       <td
//         onClick={async () => {
//           window.open("available?id=" + available.id, mobileAndTabletCheck()?"_self": null);
//         }}
//         className="whitespace-nowrapx py-4 pl-4 pr-3 text-sm sm:pl-6"
//       >
//         {available.contacts
//           .sort((a, b) => a.avail_contact.sortOrder - b.avail_contact.sortOrder)
//           .map((contact, index) => (
//             <div className="flex items-center py-1" key={"available.contacts"+index}>
//               <div className="h-10 w-10 rounded-full">
//                 <img
//                   className="h-10 w-10 flex-shrink-0 rounded-full object-cover"
//                   src={
//                     contact.images.length > 0
//                       ? contact.images.sort(
//                           (a, b) => a.sortOrder - b.sortOrder
//                         )[0].img
//                       : "https://images.cubicsearch.com/UserLogo.png"
//                   }
//                   alt=""
//                 />
//               </div>
//               <div className="ml-4">
//                 <div className="font-medium text-gray-900">
//                   {contact.FirstName} {contact.LastName}
//                 </div>
//                 <div className="text-gray-500">
//                   {contact.office && contact.office.company
//                     ? contact.office.company.Name
//                     : "Add Company"}
//                 </div>
//               </div>
//             </div>
//           ))}
//       </td>
//     </tr>
//   ));
// }
