import DemoCarousel from "../Images/Carousel";
import { mobileAndTabletCheck } from "../Search/Deck";

export default function ClientListRows({ clientsIn }) {
  if (clientsIn && clientsIn.length > 0) {
    return (
      <>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr >
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        <div className="px-6">Client Picture</div>
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Client Name
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Your Groups with Visibility
                      </th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>{tableRows(clientsIn)}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
}

function tableRows(clientsIn) {


  if (clientsIn && clientsIn.length > 0) {
    return (
      <>
        {clientsIn.map((client) => (
          <tr
            className="hover:bg-gray-200  cursor-pointer"
            onClick={async () => {
              window.open("client?id=" + client.id, mobileAndTabletCheck()?"_self": null);
            }}
          >
            <td className="h-40 w-80 flex-shrink-0 items-center rounded-xl object-cover">
              <div>
                <DemoCarousel key={client.id} images={client.images} />
              </div>
              <div
                onClick={async () => {
                  window.open("client?id=" + client.id, mobileAndTabletCheck()?"_self": null);
                }}
              ></div>
            </td>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
              <div className="font-medium text-gray-500">
                {client.ClientName ? client.ClientName : "New Client"}{" "}
              </div>
            </td>

            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
              <div className="font-medium text-gray-900 whitespace-pre-line">
                {client.client_groups.map((client_group) => {
                  // return <div>{client_group.group.GroupName} - {client_group.groupPermission}</div>;
                  return (
                    <div className="py-2">
                      {" "}
                      Group Name : {client_group.group.GroupName}
                      {"\n"} Client Rights : {client_group.groupPermission}
                    </div>
                  );
                }) || "Contact agent or owner"}
              </div>
            </td>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
              {client.contacts
                .sort(
                  (a, b) =>
                    a.client_contact.sortOrder - b.client_contact.sortOrder
                )
                .map((contact) => (
                  <div className="flex items-center py-1">
                    <div className="h-10 w-10 rounded-full">
                      <img
                        className="h-10 w-10 flex-shrink-0 rounded-full object-cover"
                        src={
                          contact.images.length > 0
                            ? contact.images.sort(
                                (a, b) => a.sortOrder - b.sortOrder
                              )[0].img
                            : "https://images.cubicsearch.com/UserLogo.png"
                        }
                        alt=""
                      />
                    </div>
                    <div className="ml-4">
                      <div className="font-medium text-gray-900">
                        {contact.FirstName} {contact.LastName}
                      </div>
                      <div className="text-gray-500">
                        {contact.office && contact.office.company
                          ? contact.office.company.Name
                          : "Add Company"}
                      </div>
                    </div>
                  </div>
                ))}
            </td>
          </tr>
        ))}
      </>
    );
  } else {
    return <></>;
  }
}
