/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography } from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'; // MU
import { Person as UserIcon } from "@mui/icons-material";
import GroupContactLookupComboBox from "../Availables/AvailableContactLookupComboBox"//"./GroupContactLookupComboBox";
import NewContactDialog from "./NewContactDialog";
import ContactEdit from "../Contacts/ContactEdit";
import GroupContactNew from "./GroupContactNew";
import PermissionCombo from "./PermissionCombo";
import axios from "axios";

export default function GroupContactSelectModal({
  open,
  setOpen,
  peopleCombo,
  peopleIn,
  setPeopleIn,
  groupId,
  triggerSetItems,
  user,
  contactId,
  items,
  setItens
}) {
  //test - console.log("LOOP IN AVAIALABLE");
  const [buttonText, setButtonText] = useState("Select Contact");

  const cancelButtonRef = useRef(null);
  const [selectedPerson, setSelectedPerson] = useState();
  const [addPerson, setAddPerson] = useState(false);
  const [newText, setNewText] = useState(false)
  const [selectedPermission, setSelectedPermission] = useState({
    id: 1,
    name: "Edit",
  });



  return (

    <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={() => setOpen(false)} aria-labelledby="group-contact-dialog-title">
      <DialogTitle id="group-contact-dialog-title">Select or Add Contact To Group</DialogTitle>
      <DialogContent>
        <Box textAlign="center" sx={{ my: 2, }}>


          <div>
            <GroupContactLookupComboBox
              peopleCombo={peopleCombo}
              selectedPerson={selectedPerson}
              setSelectedPerson={setSelectedPerson}
              setButtonText={setButtonText}
              setAddPerson={setAddPerson}
              setName={() => { return ("") }}
              contactId={user.contact.id}
              setNewText={setNewText}
            />
          </div>
          <div style={{ marginTop: 30 }}>
            <PermissionCombo
              selectedPermission={selectedPermission}
              setSelectedPermission={setSelectedPermission}
            />
          </div>
        </Box>

      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
 
    

        <Button
          variant="contained"

          onClick={() => {


            addContact(
              selectedPerson.id,
              groupId,
              setPeopleIn,
              peopleIn,
              triggerSetItems,
              selectedPermission.name,
              user
            );
            setOpen(false);

          }}
        >
          {buttonText}
        </Button>

      </DialogActions>
      <NewContactDialog NameIn={newText} open={addPerson} onClose={() => { setAddPerson(false) }} createContact={async (person) => {


        await newContact(
          groupId,
          person,
          setPeopleIn,
          triggerSetItems,
          selectedPermission.name,
          user.contact.id
        );
        setOpen(false);

        setAddPerson(false); // Close the dialog
      }} />
    </Dialog >
  );
}

export async function newContact(
  groupId,
  person,
  setPeopleIn,
  triggerSetItems,
  PermissionLevel,
  contactId
) {
  var fetchLocation =
    process.env.REACT_APP_DATABASE + "api/addNewGroupContact?groupId=" +
    groupId +
    "&PermissionLevel=" +
    PermissionLevel +
    "&" +
    Object.keys(person)
      .map((key) => key + "=" + person[key])
      .join("&");
  //test - console.log(fetchLocation);
  var result = await axios.get(fetchLocation).catch((error) => { console.log(error) })
  fetchLocation =
    process.env.REACT_APP_DATABASE + "api/getGroup?id=";
  ;
  const searchResults = await axios.get(
    fetchLocation + groupId + "&contactId=" + contactId
  ).catch((error) => { console.log(error) });

  var myJson = searchResults.data.group_contacts; // search results come in an array, and the element is also an array

  //var sort = orderAvailContacts(myJson.sort((a,b)=> a.sortOrder - b.sortOrder))
  setPeopleIn(myJson.sort((a, b) => a.sortOrder - b.sortOrder));
  triggerSetItems(myJson.sort((a, b) => a.sortOrder - b.sortOrder));
}
export async function addContact(
  contactId,
  groupId,
  setPeopleIn,
  peopleIn,
  triggerSetItems,
  PermissionLevel,
  user
) {
  var fetchLocation =
    process.env.REACT_APP_DATABASE + "api/addGroupContact?groupId=" +
    groupId +
    "&&contactId=" +
    contactId +
    "&PermissionLevel=" +
    PermissionLevel +
    "&";
  //test - console.log(fetchLocation);
  const resultInfo = await fetch(fetchLocation);
  fetchLocation =
    process.env.REACT_APP_DATABASE + "api/getGroup?id=" +
    groupId +
    "&contactId=" +
    user.contact.id;
  const searchResults = await fetch(fetchLocation);
  const data = await searchResults.json();

  var myJson = data.group_contacts; // search results come in an array, and the element is also an array
  //  var sort = orderAvailContacts(myJson.sort((a,b)=> a.sortOrder - b.sortOrder))
  setPeopleIn(myJson.sort((a, b) => a.sortOrder - b.sortOrder));
  triggerSetItems(myJson.sort((a, b) => a.sortOrder - b.sortOrder));
}

async function orderAvailContacts(contacts) {
  //test - console.log("Update the order in the database");
  ////test - console.log(groups)
  var mynum = 0;

  contacts.map(async (contact, index) => {
    mynum = index + 1;
    //test - console.log(contact.id);
    var qs = "id=" + contact.group_contact.id + "&sortOrder=" + mynum;
    var resultInfo = await fetch(
      process.env.REACT_APP_DATABASE + "api/sortGroupContacts?" +
      qs
    );
  });
}


