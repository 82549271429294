import React, { useState, useEffect, forwardRef } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    PointElement,
    LineElement,
    TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Bar, Pie, Scatter } from 'react-chartjs-2';
import {
    Typography,
    Paper,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Avatar,
} from '@mui/material';
import axios from 'axios';
import './EmailCampaignReport.css';
import MyTinyMCEEditor from './MyTinyMCEEditor';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend
);

const aggregateCampaignStats = (campaignStats) => {
    const totals = {
        sent: 0,
        delivered: 0,
        uniqueViews: 0,
        uniqueClicks: 0,
        unsubscriptions: 0,
        complaints: 0,
        softBounces: 0,
        hardBounces: 0
    };

    campaignStats.forEach(stat => {
        totals.sent += stat.sent;
        totals.delivered += stat.delivered;
        totals.uniqueViews += stat.uniqueViews;
        totals.uniqueClicks += stat.uniqueClicks;
        totals.unsubscriptions += stat.unsubscriptions;
        totals.complaints += stat.complaints;
        totals.softBounces += stat.softBounces;
        totals.hardBounces += stat.hardBounces;
    });

    return totals;
};

const EmailCampaignReport = forwardRef(({ reportData, open, emailDetails, index }, ref) => {
    const [emailEvents, setEmailEvents] = useState([]);
    const [userActivity, setUserActivity] = useState([]);
    const [linkClicks, setLinkClicks] = useState([]);

    useEffect(() => {
        const fetchEvents = async () => {
            if (open) {
                const events = await fetchEmailEvents(reportData.id);
                
                setEmailEvents(events?.webHooks|| []);
                const activity = calculateUserActivity(events || []);
                setUserActivity(activity);
                const clicks = calculateLinkClicks(events?.webHooks || []);
                setLinkClicks(clicks);
            }
        };

        fetchEvents();
    }, [reportData.id, open]);

    const fetchEmailEvents = async (campaignId) => {
        try {
         
            const response = await axios.get(process.env.REACT_APP_DATABASE + 'api/emailEvents', {
                params: { bId: campaignId }
            });
            
            return response.data;
        } catch (error) {
            console.error('Error fetching email events:', error);
            
            return null;
        }
    };

    const calculateUserActivity = (events) => {
        const webHooks = events?.webHooks;
        const cubicContacts = events?.cubicContacts;
        const activityMap = [];

        
        webHooks.forEach(event => {
            
            const likelyContacts = cubicContacts.filter(cubicContact => cubicContact.Email.toLowerCase() === event.emailAddress.toLowerCase());
            const theContact = likelyContacts.length > 0 ? likelyContacts[0] : null;


            if (theContact) {
                const { Email } = theContact;
                const eventType = event.event;

                if (!activityMap[Email]) {
                    activityMap[Email] = {
                        Email,
                        theContact,
                        opens: 0,
                        clicks: 0,
                        bounces: 0,
                        unsubscribes: 0,
                        complaints: 0,
                        deliveries: 0,
                        dates: []
                    };
                }
                activityMap[Email].dates.push(new Date(event.timestamp))
                switch (eventType) {
                    case 'opened':
                        activityMap[Email].opens += 1;
                        break;
                    case 'click':
                        activityMap[Email].clicks += 1;
                        break;
                    case 'bounce':
                        activityMap[Email].bounces += 1;
                        break;
                    case 'unsubscribed':
                        activityMap[Email].unsubscribes += 1;
                        break;
                    case 'complaint':
                        activityMap[Email].complaints += 1;
                        break;
                    case 'spam':
                        activityMap[Email].complaints += 1;
                        break;
                    case 'delivered':
                        activityMap[Email].deliveries += 1;
                        break;
                    default:
                        break;
                }
            }
            else 
            {
                const { emailAddress } = event;
                const eventType = event.event;
                var Email = emailAddress
                alert (Email)
                if (!activityMap[Email]) {
                    activityMap[Email] = {
                        Email,
                        Email: Email,
                        opens: 0,
                        clicks: 0,
                        bounces: 0,
                        unsubscribes: 0,
                        complaints: 0,
                        deliveries: 0,
                        dates: []
                    };
                }

                activityMap[Email].dates.push(new Date(event.timestamp))
                switch (eventType) {
                    case 'opened':
                        activityMap[Email].opens += 1;
                        break;
                    case 'click':
                        activityMap[Email].clicks += 1;
                        break;
                    case 'bounce':
                        activityMap[Email].bounces += 1;
                        break;
                    case 'unsubscribed':
                        activityMap[Email].unsubscribes += 1;
                        break;
                    case 'complaint':
                        activityMap[Email].complaints += 1;
                        break;
                    case 'spam':
                        activityMap[Email].complaints += 1;
                        break;
                    case 'delivered':
                        activityMap[Email].deliveries += 1;
                        break;
                    default:
                        break;
                }
                
             }
            
        });
        debugger
        return Object.values(activityMap).filter(user => user.opens > 0 || user.clicks > 0);
    };

    const calculateLinkClicks = (events) => {
        const linkMap = {};

        events.forEach(event => {
            if (event.event === 'click') {
                const { linkClicked } = event;
                if (!linkMap[linkClicked]) {
                    linkMap[linkClicked] = {
                        linkClicked,
                        clicks: 0,
                    };
                }
                linkMap[linkClicked].clicks += 1;
            }
        });

        return Object.values(linkMap);
    };
    
    const campaignTotals = aggregateCampaignStats(reportData.statistics.campaignStats);

    const uniqueClicksData = {
        labels: ['Clicks'],
        datasets: [
            {
                label: 'Unique Clicks',
                data: [campaignTotals.uniqueClicks],
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
            },
        ],
    };

    const viewsData = {
        labels: ['Views'],
        datasets: [
            {
                label: 'Unique Views',
                data: [campaignTotals.uniqueViews],
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
            },
        ],
    };
   
    const scatterData = {
        datasets: [
            {
                label: 'Opens',
                data: emailEvents?.filter(event => event.event === 'opened').map(event => ({
                    x: new Date(event.timestamp),
                    y: 1,
                })),
                backgroundColor: 'rgba(54, 162, 235, 0.6)',
            },
            {
                label: 'Clicks',
                data: emailEvents?.filter(event => event.event === 'click').map(event => ({
                    x: new Date(event.timestamp),
                    y: 1,
                })),
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
            },
        ],
    };

    return (
        <Paper ref={ref} sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom>
                Email Campaign Report
            </Typography>
            <Typography variant="h6" gutterBottom>
                Sent {new Date(reportData.sentDate).toLocaleDateString()}
            </Typography>
            <Typography variant="h6" gutterBottom>
                From {reportData.sender.email}
            </Typography>
            <Typography variant="h6" gutterBottom>
                Subject {reportData.name}
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Campaign Totals</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Metric</TableCell>
                                    <TableCell>Value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Unique Clicks</TableCell>
                                    <TableCell>{campaignTotals.uniqueClicks}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Unique Views</TableCell>
                                    <TableCell>{campaignTotals.uniqueViews}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Delivered</TableCell>
                                    <TableCell>{campaignTotals.delivered}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Sent</TableCell>
                                    <TableCell>{campaignTotals.sent}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Complaints</TableCell>
                                    <TableCell>{campaignTotals.complaints}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Unsubscriptions</TableCell>
                                    <TableCell>{campaignTotals.unsubscriptions}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Soft Bounces</TableCell>
                                    <TableCell>{campaignTotals.softBounces}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Hard Bounces</TableCell>
                                    <TableCell>{campaignTotals.hardBounces}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Graphs</Typography>
                    <Bar data={uniqueClicksData} />
                    <Pie data={viewsData} />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Opens and Clicks Over Time</Typography>
                    <Scatter data={scatterData} options={{ scales: { x: { type: 'time', time: { unit: 'day' } } } }} />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">User Activity</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Photo</TableCell>
                                    <TableCell>Name & Email</TableCell>
                                    <TableCell>Company Logo</TableCell>
                                    <TableCell>Location</TableCell>
                                    <TableCell>Stats</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userActivity.map(user => {
                                    // Convert dates to a Set to ensure uniqueness, then map and join
                                    const uniqueDates = Array.from(new Set(user.dates.map(date => date.toLocaleDateString())));

                                    return (
                                    <TableRow key={user.email}>
                                        <TableCell>
                                            <Avatar src={user.theContact?.images[0]?.img} alt={`${user.theContact?.FirstName} ${user.theContact?.LastName}`} />
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{user.theContact?.FirstName} {user.theContact?.LastName}</Typography>
                                            <Typography>{user.theContact?.Email}</Typography>
                                            <Typography>{user.theContact?.Title}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Avatar src={user.theContact?.office?.company?.images[0]?.img} alt={`${user.theContact?.office?.company?.name} Logo`} sx={{ borderRadius: 3 }} />
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{user.theContact?.office?.City}</Typography>
                                            <Typography>{user.theContact?.office?.State}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>Opens: {user.opens}</Typography>
                                            <Typography>Clicks: {user.clicks}</Typography>
                                                <Typography>Dates: {uniqueDates.join(", ")}</Typography>
                                        </TableCell>
                                    </TableRow>
                                )})}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Link Clicks</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Link URL</TableCell>
                                    <TableCell>Clicks</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {linkClicks.map(link => (
                                    <TableRow key={link.linkClicked}>
                                        <TableCell>{link.linkClicked}</TableCell>
                                        <TableCell>{link.clicks}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Typography variant="h6" sx={{ marginTop: 5 }} gutterBottom>
                Email Body
            </Typography>

            <div>
                <MyTinyMCEEditor
                    emailDetails={emailDetails}
                    setEmailDetails={null}
                    index={index}
                    readOnly={true}
                />
            </div>

        </Paper>
    );
});

export default EmailCampaignReport;
