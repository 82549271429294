import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from 'axios';

const CampaignToggle = ({campaign, setCampaign, edit}) => {
    // Assuming campaign state is an object that includes an 'active' property
    

    const handleToggle = async (event) => {
        // Update the campaign's 'active' state based on the switch's checked state
        // Convert true/false to 1/0
        
        setCampaign({
            ...campaign,
            active: event.target.checked ? 1 : 0,
        });
        var qs = process.env.REACT_APP_DATABASE + "api/updateStatusBrevo"
        if (event.target.checked) {
            
        
            var statusResult = await axios.post(qs,{campaignId: campaign?.id, status: 'active'})
        }
        else {
            
            var statusResult = await axios.post(qs, { campaignId: campaign?.id, status: 'draft' })
        }
    };

    
    return (
        <FormControlLabel
            control={<Switch checked={campaign.active === 1} onChange={handleToggle} disabled={!edit} />}
            label={campaign.active === 1 ? 'Active' : 'Inactive'}
        />
    );
};

export default CampaignToggle;
