function inputComponent(inputType, defaultShow, id, name, tailwind) {
  if (inputType === "date") {
    return (
      <input
        className="shadow-sm focus:ring-red-500 focus:border-red-500  sm:text-sm border-gray-300 rounded-md"
        type={inputType}
        name={name}
        id={name}
        defaultValue={defaultShow && defaultShow !== "null" ? defaultShow : ""}
      />
    );
  } else if (inputType === "select") {
    return (
      <select
        id={name}
        name={name}
        className="mt-1 block rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
        defaultValue={defaultShow && defaultShow !== "null" ? defaultShow : ""}
      >
        <option>Yes</option>
        <option>No</option>
      </select>
    );
  } else {
    return (
      <input
        className={
          "shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300 rounded-md " +
          tailwind
        }
        type={inputType}
        name={name}
        id={name}
        defaultValue={defaultShow && defaultShow !== "null" ? defaultShow : ""}
      />
    );
  }
}

function GroupEdit({ GroupData }) {
  //
  return (
    <div className="px-4 py-5 sm:px-6">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Group Name</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {inputComponent(
              "text",
              GroupData.GroupName,
              GroupData.id,
              "GroupName"
            )}
          </dd>
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            Can View Members See Each Other's Contact Information?
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {inputComponent(
              "select",
              GroupData.MembersVisible,
              GroupData.id,
              "MembersVisible"
            )}
          </dd>
        </div>

        <div className="col-span-2">
          <dt className="text-sm font-medium text-gray-500">Group Notes</dt>
          {/* <div className=" mt-2 text-sm w-full text-gray-500">{inputComponent("text", GroupData.Notes, GroupData.id, "Notes", "w-full h-40 self-start")}</div>                */}
          <textarea
            className="shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300 rounded-md w-full h-40"
            type={"textarea"}
            name={"Notes"}
            id={"Notes"}
            defaultValue={
              GroupData.Notes ? GroupData.Notes : "Add a description"
            }
          ></textarea>
        </div>
      </dl>
    </div>
  );
}

export default GroupEdit;
