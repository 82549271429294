// utils/mapUtils.js

export const resizeImageToDataURL = (imageUrl, width, height) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous"; // This enables CORS requests
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        const dataURL = canvas.toDataURL();
        resolve(dataURL);
      };
      img.onerror = (error) => {
        console.error("Error loading image:", imageUrl, error);
        reject(error);
      };
      img.src = imageUrl;
    });
  };
  
  // utils/mapUtils.js

// utils/mapUtils.js

export const generateStaticMapURL = (recipients, accessToken, viewport, width = 800, height = 600) => {
    if (!accessToken) {
      console.error("Mapbox access token is required.");
      return "";
    }
  
    if (recipients.length === 0) {
      // Return a default map view
      return `https://api.mapbox.com/styles/v1/mapbox/dark-v11/static/0,0,2,0/${width}x${height}?access_token=${accessToken}`;
    }
  
    // Extract valid recipients with coordinates
    const validRecipients = recipients.filter(
      (r) =>
        r.office?.Longitude !== undefined &&
        r.office?.Latitude !== undefined &&
        !isNaN(r.office.Longitude) &&
        !isNaN(r.office.Latitude)
    );
  
    if (validRecipients.length === 0) {
      return `https://api.mapbox.com/styles/v1/mapbox/dark-v11/static/0,0,2,0/${width}x${height}?access_token=${accessToken}`;
    }
  
    // Use the provided viewport if available
    let centerLng, centerLat, zoom;
  
    if (viewport) {
      centerLng = viewport.longitude;
      centerLat = viewport.latitude;
      zoom = viewport.zoom;
    } else {
      // Calculate center and zoom based on recipients
      const longitudes = validRecipients.map((r) => r.office.Longitude);
      const latitudes = validRecipients.map((r) => r.office.Latitude);
  
      const minLng = Math.min(...longitudes);
      const maxLng = Math.max(...longitudes);
      const minLat = Math.min(...latitudes);
      const maxLat = Math.max(...latitudes);
  
      centerLng = (minLng + maxLng) / 2;
      centerLat = (minLat + maxLat) / 2;
  
      const lngDiff = maxLng - minLng;
      const latDiff = maxLat - minLat;
      const maxDiff = Math.max(lngDiff, latDiff);
      zoom = 2;
  
      if (maxDiff < 0.1) zoom = 12;
      else if (maxDiff < 1) zoom = 8;
      else if (maxDiff < 10) zoom = 5;
      else if (maxDiff < 50) zoom = 3;
      else zoom = 2;
    }
  
    // Generate markers
    const markers = validRecipients
      .map((r) => {
        const { Longitude, Latitude } = r.office;
        const logoUrl = r.office.company?.images?.[0]?.img || "";
        let marker;
  
        if (logoUrl) {
          // Ensure the logo URL is properly URL-encoded
          const encodedLogoUrl = encodeURIComponent(logoUrl);
          // Use the 'url-<encoded-logo-url>' syntax for custom image markers
          marker = `url-${encodedLogoUrl}(${Longitude},${Latitude})`;
        } else {
          // Use a standard colored pin if no logo is available
          marker = `pin-s+3f51b5(${Longitude},${Latitude})`; // Example: blue pin
        }
  
        return marker;
      })
      .join(",");
  
    // Construct the Static Image API URL
    const url = `https://api.mapbox.com/styles/v1/mapbox/dark-v11/static/${markers}/${centerLng},${centerLat},${zoom}/${width}x${height}?access_token=${accessToken}`;
  
    return url;
  };
  
  // utils/mapUtils.js

// utils/mapUtils.js
// utils/mapUtils.js
// utils/mapUtils.js

// Utility function to sanitize company names for Mapbox image names
export const sanitizeCompanyName = (companyName) => {
  return companyName.replace(/\s+/g, "_").replace(/[^a-zA-Z0-9_]/g, "");
};

// GeoJSON generator function
export const generateGeoJSON = (recipients) => {
  const uniqueFeatures = {};

  recipients.forEach((recipient) => {
    const { company } = recipient.office;
    const { images } = company;
    const { Longitude, Latitude } = recipient.office;

    // Create a unique key based on company name and location
    const key = `${company.Name}-${Longitude}-${Latitude}`;

    if (!uniqueFeatures[key]) {
      const originalCompanyName = company.Name || "Company";
      const sanitizedCompanyName = sanitizeCompanyName(originalCompanyName);

      uniqueFeatures[key] = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [Longitude, Latitude],
        },
        properties: {
          companyName: sanitizedCompanyName, // Use sanitized name
          logo: images && images.length > 0 ? images[0].img : null, // Use the first image as the logo
        },
      };
    }
  });

  return {
    type: "FeatureCollection",
    features: Object.values(uniqueFeatures),
  };
};
