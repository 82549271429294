/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const roles = [
  { id: 1, name: 'Landlord Broker' },
  { id: 2, name: 'Tenant Broker' },
  { id: 3, name: 'Sublandlord Broker' },
  { id: 4, name: 'Subtenant Broker' },
  { id: 5, name: 'Owner' },
  { id: 6, name: 'Tenant' },
  { id: 7, name: 'Asset Manager' },
  { id: 8, name: 'Capital Partner' },
  { id: 9, name: 'Client Manager' },
  { id: 10, name: 'Architect' },
  // More users...
]


export default function RoleCombo({ selectedRole, setSelectedRole }) {
  const [inputValue, setInputValue] = useState('');

  // Handle the scenario where the query doesn't match any roles
  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    const matchedRole = roles.find(role => role.name.toLowerCase() === newInputValue.toLowerCase());
    if (!matchedRole && newInputValue !== '') {
      setSelectedRole({ id: 1, name: newInputValue }); // Example logic, adjust as needed
    }
  };

  return (
    <Autocomplete
      id="role-combo-box"
      options={roles}
      getOptionLabel={(option) => option.name}
      value={selectedRole}
      onChange={(event, newValue) => {
        setSelectedRole(newValue);
      }}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      renderInput={(params) => <TextField {...params} label="Role" />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
}

