import { useState } from "react";
import ClientWebsiteEditTable from "./ClientWebsiteEditTable";
import WebsiteView from "./WebsiteView";
import { Save, Edit } from "@mui/icons-material";

export default function WebsiteList({
  mainPageData,
  setMainPageData,
  contactId,
  highestPermission,
}) {
  const [editWebsitesMode, setEditWebsitesMode] = useState(false);
  return (
    <>
      <div className="px-0 sm:px-0 lg:px-0">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Weblinks</h1>
          </div>
          <div className="mt-4 flex space-x-2 sm:mt-0 sm:ml-16 sm:flex-none">
            <div className="px-0">
              {highestPermission === "Lead Edit" || highestPermission === "Edit" ? (
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                  onClick={() => setEditWebsitesMode(!editWebsitesMode)}
                >
                  {!editWebsitesMode ? <Edit sx={{width: 18, height: 18}}/> : <Save/>}
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {!editWebsitesMode ? (
          <WebsiteView
            websites={mainPageData.websites.sort(
              (a, b) => a.sortOrder - b.sortOrder
            )}
          />
        ) : (
          <ClientWebsiteEditTable
            mainPageData={mainPageData}
            setMainPageData={setMainPageData}
            contactId={contactId}
          />
        )}
      </div>
    </>
  );
}
