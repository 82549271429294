import * as React from 'react';


import { useEffect, useState } from 'react';




import { styled } from '@mui/material/styles';

import Drawer from '@mui/material/Drawer';

import MuiAppBar from '@mui/material/AppBar';

import { getViewportElevation } from './ElevationFunctions/ElevationsFunctions';

import {Tooltip} from '@mui/material';

import { RemoveCircle as MinusCircleIcon } from '@mui/icons-material';


import { Card} from '@mui/material';
import InfoCard from './InfoCard';
import { ZoomInOutlined } from '@mui/icons-material';



function PopUp(popupInfo, setOpen, setPopupInfo, searchResults, setSearchResults,
	refreshMapPropertiesInMain, mapRef, removeProperty, selectedLocation, setSelectedLocation, setShowSurveys, showSurveys, surveyId,  setSaveSurveyOpen ) {


	return (<>


		

		<Card sx={{ borderRadius: 0, position: 'fixed', zIndex:2000, justifyContent: 'space-between', width: '100%' }}>
			<div className="flex w-full  py-2 px-2 flex-col">

				<div  className="flex justify-between py-0">
					{/* <button
						className="w-8 h-8 border-transparent outline-none flex content-center"
						onClick={(event, info) => {
							setPopupInfo(false)
							setOpen(false)

						}}
					>
						<Close className="w-7 h-7 pb-2" />
						Close
					</button> */}
					<Tooltip arrow title="Zoom in">
					<button
						className="w-8 h-8 border-transparent outline-none flex content-center"
						onClick={async (event, info) => {
							
							var myViewport = {} // dummy holder for zoom
							var elevation = await getViewportElevation(mapRef)
							if (elevation.elevation > 1000) {
								myViewport.maxZoom = 14.5
							}
							else if (elevation.elevation > 100) {
								myViewport.maxZoom = 15
							}
							else  {
								myViewport.maxZoom =19.5
							}
							mapRef.current.flyTo({ center: [popupInfo.Longitude, popupInfo.Latitude], zoom: myViewport.maxZoom, duration: 2000,  } )

						}}
					>
						<ZoomInOutlined style={{height:20, width: 20}} />
						Zoom In
						</button>
					</Tooltip>
					<Tooltip arrow title="Remove property from search">
					<button
						
						className="w-7 h-7 border-transparent outline-none flex content-center"
						onClick={(event, info) => {
							removeProperty(popupInfo);
							setPopupInfo(false)
							setOpen(false)
						

							// setSearchResults(searchResults);
							// refreshMapPropertiesInMain()
						}}
					>
						<MinusCircleIcon style={{ height: 20, width: 20 }} />
					
						</button>
					</Tooltip>

					{/* <MinusCircleIcon
                        data-tip
                        data-for="removeTip"
                        className="h-7 z-100 cursor-pointer outline-none"
                        onClick={() => {
                            removeById(searchResults, popupInfo.id);

                            setSearchResults(searchResults);
                            // setPopupInfo(null);
                        }}
                    >Remove</MinusCircleIcon>{" "} */}
				</div>
			</div>
		</Card>
		<div elevation={4} style={{ maxHeight: '100%', overflow: 'scroll' }}>
			<div style={{ height: 50 }}></div> 
			{/* padding for top */}


			
			<InfoCard
				key={popupInfo.id}
				property={popupInfo}
				removeProperty={removeProperty}
				selectedLocation={selectedLocation}
				setShowSurveys={setShowSurveys}
				showSurveys={showSurveys}
				setSelectedLocation={setSelectedLocation}
				surveyId={surveyId}
				// onHover={onHover}
				// showMe={showMe}
				// onClick={onClick}
				searchResults={searchResults}
				setSearchResults={setSearchResults}
				mapButton={true}
				// removeButton={remove}
				// confirm={confirm}
				// contactId={contactId}
				// printing={printing}
				// index={index}
				// setSearchedLocations={setSearchedLocations}
				// setMapButton={setMapButton}
				showGoogle={true}
				setSaveSurveyOpen={setSaveSurveyOpen}

			/>
			{/* <div style={{maxHeight:'100%'}} >
				<div className='flex px-2 justify-center' style={{ overflowX: "auto" }}>
					<PhoneDemoCarousel
						key={popupInfo.id}
						images={popupInfo.images}
						goToURL={"property?id=" + popupInfo.id}
						property={popupInfo}
					/>
					

				</div>
				<div className="w-full px-2">
					<div>
						<p className="text-left text-md font-semibold">
							{popupInfo && popupInfo.properties && popupInfo.properties.CampusName ? popupInfo.properties.CampusName : ""}
						</p>
						<p className="text-left text-md font-semibold">
							{popupInfo && popupInfo.properties && popupInfo.properties.PropertyName ? popupInfo.properties.PropertyName : ""}
						</p>
					</div>
					<div className="w-full h-full">
						{popupInfo && popupInfo.properties && popupInfo.properties.StreetNumber || ""}  {popupInfo && popupInfo.properties && popupInfo.properties.StreetName ? popupInfo.properties.StreetName + " | " : ""}
						{(popupInfo && popupInfo.properties && popupInfo.properties.City || "")} {(popupInfo && popupInfo.properties && popupInfo.properties.State || "")}
					</div>
					<div
						className='border-b'
						style={{
							overflow: 'auto',
							maxHeight: '100px',


						}
						}
					>{showData(popupInfo)}</div>
				</div>
			</div> */}
		</div>


	</>
	)
	{/* // </Popup> */ }
}
export default function SwipeableTemporaryDrawer({ open, setPopupInfo, mapButton, searchResults, setSearchResults, setOpen, cardProps, refreshMapPropertiesInMain, mapRef, removeProperty, selectedLocation, setSelectedLocation, setShowSurveys, showSurveys, surveyId,  setSaveSurveyOpen  }) {

	const getOrientation = () => {
		if (window.matchMedia('(orientation: portrait)').matches) {
			return 'portrait';
		} else if (window.matchMedia('(orientation: landscape)').matches) {
			return 'landscape';
		} else {
			return 'unknown';
		}
	};
	const [orientation, setOrientation] = useState(getOrientation());
	
	useEffect(() => {
		const handleOrientationChange = () => {
			setOrientation(getOrientation());
		};

		window.addEventListener('orientationchange', handleOrientationChange);
		window.matchMedia('(orientation: portrait)').addListener(handleOrientationChange);
		window.matchMedia('(orientation: landscape)').addListener(handleOrientationChange);

		return () => {
			window.removeEventListener('orientationchange', handleOrientationChange);
		};
	}, []);

	

	if (open && mapButton) {
		return (
			<div id={"DrawerParent"}
				
				style={{ height: orientation === 'portrait' ? window.innerHeight * .4 : window.innerWidth, zIndex: 1 }} 
			>
				{
					<Drawer
						id={"TheDrawer"}
					
						anchor={'bottom'}
						open={open}
						onClose={() => {

							setPopupInfo(false)
							setOpen(false)
						}}
						//  PaperProps={{ style: { width: '33.33%', height: '80%'}}}
						// sx={{ width: '33.33%', height: '80%', justifyContent: 'end' }}
						// sx={{
						// 	display: { xs: "block", sm: "none" },
						// 	"& .MuiDrawer-paper": {
						// 		boxSizing: "border-box",
						// 		width: drawerWidth
						// 	}
						// }}
						variant='temporary'





					>

						<div id="TheChild" style={{ height: orientation === 'portrait' ? window.innerHeight *.5 : window.innerWidth, zIndex: 1 }} >

							<>
							
								{PopUp(cardProps, setOpen, setPopupInfo, searchResults, setSearchResults, refreshMapPropertiesInMain, mapRef, removeProperty, selectedLocation, setSelectedLocation, setShowSurveys, showSurveys, surveyId,  setSaveSurveyOpen)}

						
								</>
						</div>
						
					</Drawer>
				}
			</div>

		);
	}
	else {
		return (<></>)
	}
}



const drawerWidth = 100//window.innerWidth/2;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: `-${drawerWidth}px`,
		...(open && {
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: 0,
		}),
	}),
);

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: 'flex-end',
}));

// export function PersistentDrawerLeft({ open, setOpen, cardProps, mapInput }) {
// 	const theme = useTheme();


// 	const handleDrawerOpen = () => {
// 		setOpen(true);
// 	};

// 	const handleDrawerClose = () => {
// 		setOpen(false);

// 	};

// 	return (

// 		<Box sx={{ display: 'flex' }}>
// 			<CssBaseline />
// 			<AppBar position='relative' open={open}>
// 				<Toolbar>
// 					<IconButton
// 						color="inherit"
// 						aria-label="open drawer"
// 						onClick={handleDrawerOpen}
// 						edge="start"
// 						sx={{ mr: 2, ...(open && { display: 'none' }) }}
// 					>
// 						<MenuIcon />
// 					</IconButton>
// 					<Typography component={'div'}variant="h6" noWrap component="div">
// 						Explore Properties
// 					</Typography>
// 				</Toolbar>
// 			</AppBar>
// 			<Drawer
// 				sx={{
// 					width: drawerWidth,
// 					flexShrink: 0,
// 					'& .MuiDrawer-paper': {
// 						width: drawerWidth,
// 						boxSizing: 'border-box',
// 					},
// 				}}
// 				variant="persistent"
// 				anchor="left"
// 				open={open}
// 			>
// 				<DrawerHeader>
// 					<IconButton onClick={handleDrawerClose}>
// 						{theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
// 					</IconButton>
// 				</DrawerHeader>
			
// 			</Drawer>
// 			<Main open={open}>
// 				<DrawerHeader />
// 				<TheMap mapInput={mapInput} />
// 			</Main>
// 		</Box>
// 	);
// }

// export function TheMap({ mapInput,MapInput }) {


// 	return (
// 		<>
		

// 			<PropertyMap
// 				ref={mapInput.propertyMapRef}
// 				superMapRef={mapInput.superMapRef}
// 				setSuperMapRef={mapInput.setSuperMapRef}
// 				geometry={mapInput.geometry}
// 				filters={mapInput.filters}
// 				key={99}
// 				searchResults={mapInput.searchResults}
// 				setSearchResults={mapInput.setSearchResults}
// 				bounds={mapInput.bounds}
// 				selectedLocationId={mapInput.selectedLocationId}
// 				onMarkerClick={mapInput.handleLocationSelected}
// 				onMarkerHover={mapInput.handleLocationHover}
// 				popupInfo={mapInput.popupInfo}
// 				setPopupInfo={mapInput.setPopupInfo}
// 				mapButton={mapButton}
// 				hoverInfo={mapInput.hoverInfo}
// 				setHoverInfo={mapInput.setHoverInfo}
// 				setID={mapInput.setSelectedLocationId}
// 				refs={mapInput.refs}
// 				setRefs={mapInput.setRefs}
// 				contactId={mapInput.user.contact.id}
// 				setBoundsRef={mapInput.setBoundsRef}
// 				reloadOnMove={mapInput.reloadOnMove}
// 				elevationFactorState={mapInput.elevationFactorState}
// 				setElevationFactorState={mapInput.setElevationFactorState}
// 				setReloadOnMove={mapInput.setReloadOnMove}
// 				showParcels={mapInput.showParcels}
// 				setAddingNewProperties={mapInput.setAddingNewProperties}
// 				addingNewProperties={mapInput.addingNewProperties}
// 				showMap={mapInput.mapButton}
// 				showPrice={mapInput.showPrice}
// 				initialViewState={mapInput.initialViewState}
// 				setInitialViewState={mapInput.setInitialViewState}

// 				showWait={mapInput.showWait}
// 				setShowWait={mapInput.setShowWait}

// 				setCurrentViewState={mapInput.setCurrentViewState}
// 			// showAlert={showAlert}
// 			// setShowAlert={setShowAlert}
// 			/>

// 		</>
// 	)
// }

// old legend
{/* <div
	className={
		!mapInput.mapButton && window.innerWidth < 768
			? "hidden"
			: "z-10  bottomcenter w-full flex justify-center "
	}
>
	<div className="text-white  bg-black rounded-lg w-60" style={{
		MozUserSelect: "none",
		WebkitUserSelect: "none",
		msUserSelect: "none"
	}}>


		<div className="flex">
			<div
				style={{
					backgroundColor: 'rgba(180, 180, 180, 0.5)', // no comps no availables
					// background: "linear-gradient(90deg, #FFC0CB 50%, #00FFFF 50%)",

					color: "white",
					fontSize: 12,
					// borderRadius: 20,
					padding: 2,
					minWidth: 40,
					justifyContent: "center",
					borderColor: "white",
					borderWidth: 1,

					width: 80,
				}}
			>
			
				<span className="flex justify-center">Bldg Size</span>
			</div>
			<div
				style={{
					background: 'rgba(255, 0, 0, 0.9)', // no comps no availables
					// background: "linear-gradient(90deg, #FFC0CB 50%, #00FFFF 50%)",

					color: "white",
					fontSize: 12,
					// borderRadius: 20,
					padding: 2,
					minWidth: 40,
					justifyContent: "center",
					borderColor: "white",
					borderWidth: 1,
					width: 80,
				}}
			>
		
				<span className="flex justify-center">Avail</span>
			</div>
			<div
				style={{
					background: "green", // no comps no availables
					// background: "linear-gradient(90deg, #FFC0CB 50%, #00FFFF 50%)",

					color: "white",
					fontSize: 12,
					// borderRadius: 20,
					padding: 2,
					minWidth: 40,
					justifyContent: "center",
					borderColor: "white",
					borderWidth: 1,
					width: 80,
				}}
			>
			
				<span className="flex justify-center">Comp</span>
			</div>
		</div>
		<div className="flex">
			<div
				style={{
					backgroundColor: 'rgba(0, 0, 0, 0.5)', // no comps no availables
	
					color: "white",
					fontSize: 12,
					// borderRadius: 20,

					padding: 2,
					minWidth: 40,
					justifyContent: "center",
					borderColor: "yellow",
					borderWidth: 4,
					width: 300,
				}}
			>
			
				<span className="flex justify-center">
					Yellow Border is Confidential
				</span>
			</div>
		</div>
	</div>


</div> */}