import { useState } from "react";
import BuildingWebsiteEditTable from "./BuildingWebsiteEditTable";
import WebsiteView from "./WebsiteView";
import { Edit, Save } from "@mui/icons-material"
import { Button } from "@mui/material";

export default function WebsiteList({
  mainPageData,
  setMainPageData,
  contactId,
  highestPermission,
  buildingId,
}) {
  const [editWebsitesMode, setEditWebsitesMode] = useState(false);
  return (
    <>
      <div className="px-0 pb-4 sm:px-6 lg:px-8">
        <div className="flex sm:items-center">

            <div className="text-xl font-semibold text-gray-900">Files and Weblinks</div>

            {highestPermission === "Lead Edit" ||
              highestPermission === "Edit" ? (
        <Button variant={'outlined'} sx={{ marginLeft:1, minWidth:0, width: 20, height: 24,  borderColor: 'black', color: 'black' }}
                onClick={() => setEditWebsitesMode(!editWebsitesMode)}
              >
                {!editWebsitesMode ? <Edit sx={{width: 18, height: 18}} /> : <Save />}
              </Button>
            ) : (
              ""
            )}

          </div>
        </div>
        {!editWebsitesMode ? (
          <WebsiteView
            websites={mainPageData.websites.sort(
              (a, b) => a.sortOrder - b.sortOrder
            )}
          />
        ) : (
          <BuildingWebsiteEditTable
            buildingId={buildingId}
            mainPageData={mainPageData}
            setMainPageData={setMainPageData}
            contactId={contactId}
          />
        )}

    </>
  );
}
