// import { Alert } from "@mui/material";
import { useState } from "react";
//  import ContactEditList from ".depreciated/ContactEditList";
import ContactListView from "./ContactListView";
import BuildingContactEditTable from "./BuildingContactEditTable";
import { arrayRemove } from "./BuildingContactEditTable";
import { IconButton } from "@mui/material";
import { Save, Edit } from "@mui/icons-material";
import { mobileAndTabletCheck } from "../Search/Deck";
import { Button } from "@mui/material";
import EditButton from "../Common/AddProperty/EditButton";

export default function ContactList({
  peopleIn,
  dataIn,
  setPeopleIn,

  contactId,
  groupsIn,
  highestPermission,
  guest,
}) {
  const [editContactsMode, setEditContactsMode] = useState(false);

  if (
    !highestPermission ||
    (highestPermission !== "Lead Edit" && highestPermission !== "Edit")
  ) {
    groupsIn.map((group, index) => {
      if (group.id === 1) {
        groupsIn = arrayRemove(groupsIn, index);
      }
    });
  }
  return (
    <>


      <div className="sm:mt-0 ">

        {" "}
        <div style={{ display: 'flex' }}>
          <div style={{ paddingRight: 4 }} className="text-xl font-semibold text-gray-900">
            {peopleIn.length > 0
              ? "Building Contacts"
              : "No Building Contacts"}
          </div>

          <div className="px-0">
            <Button variant={'outlined'}
              sx={{ minWidth: 0, width: 20, height: 24, borderColor: 'black', color: 'black' }}

              onClick={() => {
                if (guest === true || guest === 'true') {
                  alert("Please login or sign up to make edits.");
                } else {
                  setEditContactsMode(!editContactsMode);
                }
              }}
            >
              {!editContactsMode ? <Edit sx={{ width: 18, height: 18 }} /> : <Save />}
            </Button>
          </div>

          {/* {!editContactsMode? <ContactListView peopleIn = {peopleIn}/> : <ContactEditList peopleIn = {peopleStateIn} /> } */}
        </div>
        <div>
          {!editContactsMode ? (
            <ContactListView peopleIn={peopleIn} />
          ) : (
            <BuildingContactEditTable
              peopleIn={peopleIn}
              setPeopleIn={setPeopleIn}
              bigDataIn={dataIn}
              buildingId={dataIn.id}
            
              contactId={contactId}
              groupsIn={groupsIn}
              highestPermission={highestPermission}
            />
          )}
        </div>

      </div>

    </>
  );
}
