import React from 'react';
import { Button, Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import axios from 'axios';

export const DangerZoneCampaign = ({ campaignId, onDeleteSuccess }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const deleteMailer = async () => {
        try {
            setOpen(true)
            console.log(`${process.env.REACT_APP_DATABASE}api/campaign/${campaignId}`)
            const response = await axios.delete(`${process.env.REACT_APP_DATABASE}api/campaigns/${campaignId}`);
            if (response.status === 200) {
                onDeleteSuccess(campaignId);
                handleClose();
            } else {
                console.error('Failed to delete the campaign');
            }
            setOpen(false)
        } catch (error) {
            console.error('Error deleting campaign:', error);
        }
    };

    return (
        <>
        <Box sx={{marginBottom: 40}}>
            <Button variant="outlined" color="error" onClick={handleClickOpen}>
                Delete campaign
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this campaign? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={deleteMailer} autoFocus color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            </Box>
            {open && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 1000,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Optional: background overlay
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
        </>
    );
};
