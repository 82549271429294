import React from 'react';
import { Grid, Typography, Button, TextField } from '@mui/material';
import { Edit, Save } from '@mui/icons-material';

export default function HeaderForProject({
  dataIn,
  setData,
  editMode,
  editClick,
  highestPermission,
  contactId,
}) {
  const handleEditClick = () => {
    editClick();
  };

  const handleProjectNameChange = (event) => {
    const updatedProjectName = event.target.value;
    setData((prevData) => ({
      ...prevData,
      ProjectName: updatedProjectName,
    }));
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={10} md={8} lg={6} xl={6}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h4" component="h2" gutterBottom>
              Project Details
            </Typography>
            {editMode === 'Edit' ? (
              <TextField
                variant="outlined"
                label="Project Name"
                value={dataIn.ProjectName}
                onChange={handleProjectNameChange}
                fullWidth
              />
            ) : (
              <Typography variant="h5" component="h3" gutterBottom>
                {dataIn.ProjectName}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Button variant="outlined" href="#surveys" sx={{ mx: 1, height: 40 }}>
          Surveys
        </Button>
        {(highestPermission === "Edit" || highestPermission === "Lead Edit" || contactId === 1) && (
          <Button
            variant="contained"
            onClick={handleEditClick}
            sx={{ mx: 1, height: 40 }}
          >
            {editMode === 'Edit' ? <Save sx={{ height: 20 }} /> : <Edit sx={{ height: 20 }} />}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
