import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";



import { getBounds } from "geolib";
import axios from "axios";
import polylabel from "polylabel";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function long2tile(lon, zoom) { return (Math.floor((lon + 180) / 360 * Math.pow(2, zoom))); }
function lat2tile(lat, zoom) { return (Math.floor((1 - Math.log(Math.tan(lat * Math.PI / 180) + 1 / Math.cos(lat * Math.PI / 180)) / Math.PI) / 2 * Math.pow(2, zoom))); }

function inside(point, vs) {
  // ray-casting algorithm based on
  // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html

  var x = point[0], y = point[1];
  //test - console.log(x, y)

  var inside = false;
  //test - console.log(JSON.stringify(vs))
  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    var xi = vs[i][0], yi = vs[i][1];
    var xj = vs[j][0], yj = vs[j][1];

    //test - console.log(xi, yi)


    var intersect = ((yi > y) != (yj > y))
      && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    if (intersect) inside = !inside;
  }

  return inside;
};
export async function prepareTestAlert(parcelInfo, open, params, setParams, setShowWait) {

  // const [showDialogue, setShowDialoque] = useState(false)
  if (parcelInfo) {
    var parcelAPN = parcelInfo && parcelInfo.parcelProperties && ((parcelInfo.parcelProperties.PARNO && (parcelInfo.parcelProperties.PARNO.length > 4) && parcelInfo.parcelProperties.PARNO) || parcelInfo.parcelProperties.APN
      || parcelInfo.parcelProperties.apn || parcelInfo.parcelProperties.mapblklot || parcelInfo.parcelProperties.PIN_NUM || parcelInfo.parcelProperties.LS_BOOK && parcelInfo.parcelProperties.LS_PAGE && (parcelInfo.parcelProperties.LS_BOOK + "-" + parcelInfo.parcelProperties.LS_PAGE) ||
      parcelInfo.parcelProperties.MAP_PAR_ID || "xxx")



    // if (!params.parcelResults) {
    if (!parcelInfo && open) {
      return "Loading..."
    }
    else if (parcelInfo && open) {
      //test - console.log(parcelInfo)

      var qs =
        process.env.REACT_APP_DATABASE +
        "api/getParcelNoDash?"
      if ((parcelAPN !== 'xxx') && (parcelAPN.replaceAll("0", "").replaceAll("-", "") !== "")) {
        qs = qs + "apn=" + (parcelAPN + " ").replaceAll("-", "").replaceAll(" ", "");
        //test - console.log(qs)
        // axios.get(qs).then(async (parcelResultData) => {
        var parcelResultData = await axios.get(qs)

        var parcelResult = parcelResultData.data  // what parcels do we have?

        if (parcelResult && parcelResult.length > 0) {

          var qsValidate =
            process.env.REACT_APP_DATABASE +
            "api/validateParcel"
          var bounds = getBounds(parcelInfo.geometry.coordinates && (parcelInfo.geometry.coordinates.length > 0) && parcelInfo.geometry.coordinates[0])
          if (isNaN(bounds.minLat)) {
            bounds = getBounds(parcelInfo.geometry.coordinates)
          }
          if (isNaN(bounds.minLat)) {
            bounds = getBounds(parcelInfo.geometry.coordinates && (parcelInfo.geometry.coordinates.length > 0) && (parcelInfo.geometry.coordinates[0].length > 0) && parcelInfo.geometry.coordinates[0][0])
          }
          var parcelOptions = {

            params: {
              minLat: bounds.minLat,
              maxLat: bounds.maxLat,
              minLng: bounds.minLng,
              maxLng: bounds.maxLng,
              geometry: JSON.stringify(parcelInfo.geometry),

              parcelId: parcelResult[0].id
            },

          }

          
          var resultFromValidate = await axios.get(qsValidate, parcelOptions).catch((error) => {
            console.log(error)

          })
          //test - console.log(resultFromValidate)



          setParams({
            parcelAPN: parcelAPN,
            parcelInfo: parcelInfo,
            open: true,
            // setOpen: setOpen,
            parcelResults: parcelResult,
          })
          return 1

        } // we ended because we found the parcel based on teh parcel APN
      }

      // if (!parcelResult || (parcelResult.length === 0)) {  // we have nothing that matches

      // we should check what parcels or budilings are within our geometry first


      var bounds = getBounds(parcelInfo.geometry.coordinates && (parcelInfo.geometry.coordinates.length > 0) && parcelInfo.geometry.coordinates[0])
      if (isNaN(bounds.minLat)) {
        bounds = getBounds(parcelInfo.geometry.coordinates)
      }
      if (isNaN(bounds.minLat)) {
        bounds = getBounds(parcelInfo.geometry.coordinates && (parcelInfo.geometry.coordinates.length > 0) && (parcelInfo.geometry.coordinates[0].length > 0) && parcelInfo.geometry.coordinates[0][0])
      }

      // we should see what buildings or parcels are within our geometry
      var buildingOptions = {
        method: 'GET',
        url: process.env.REACT_APP_DATABASE + "api/getBuildingsBounds",
        params: {
          minLat: bounds.minLat,
          maxLat: bounds.maxLat,
          minLng: bounds.minLng,
          maxLng: bounds.maxLng,
          geometry: JSON.stringify(parcelInfo.geometry)
        },
      }

      var propertiesInBounds = await axios.request(buildingOptions) // there are the building in the parcel and the main parcel if exists
      
      if (propertiesInBounds.data.success === true) {
        // setParcelFetchResults([propertiesInBounds.data.mainParcel])
        // setOpen(true)
        
        var qs =
          process.env.REACT_APP_DATABASE +
          "api/validateParcel"
        var bounds = getBounds(parcelInfo.geometry.coordinates && (parcelInfo.geometry.coordinates.length > 0) && parcelInfo.geometry.coordinates[0])
        if (isNaN(bounds.minLat)) {
          bounds = getBounds(parcelInfo.geometry.coordinates)
        }
        if (isNaN(bounds.minLat)) {
          bounds = getBounds(parcelInfo.geometry.coordinates && (parcelInfo.geometry.coordinates.length > 0) && (parcelInfo.geometry.coordinates[0].length > 0) && parcelInfo.geometry.coordinates[0][0])
        }
        var parcelOptions = {

          params: {
            minLat: bounds.minLat,
            maxLat: bounds.maxLat,
            minLng: bounds.minLng,
            maxLng: bounds.maxLng,
            geometry: JSON.stringify(parcelInfo.geometry),

            parcelId: propertiesInBounds.data.mainParcel.id
          },

        }
        await axios.get(qs, parcelOptions)
        setParams({
          parcelAPN: parcelAPN,
          parcelInfo: parcelInfo,
          open: true,
          // setOpen: setOpen,
          parcelResults: [propertiesInBounds.data.mainParcel]
          // setParcelFetchResults: setParcelFetchResults

        })
      }
      else { // we didn't find any parcels from properties in Bounds so let's check by latitude and longitude


        var myBoundsArcGIS = bounds.minLng
          + "," + bounds.minLat
          + "," + bounds.maxLng
          + "," + bounds.maxLat




        var qsBuildings = "https://services6.arcgis.com/Do88DoK2xjTUCXd1/arcgis/rest/services/OSM_NA_Buildings/FeatureServer/0/query?where=Shape__Area>1000&outFields=Shape__Area,height,levels,addr_street,addr_housenumber,type,name,official_name,addr_city,addr_state,addr_postcode,addr_district,start_date,building_structure,power,description&geometry="

          + myBoundsArcGIS + "&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelIntersects&outSR=4326&f=geojson"


        var msBuildings = await axios.get(qsBuildings)
        var newTile = {}
        newTile.y = lat2tile(parseFloat(parcelInfo.latitude), 15)
        newTile.x = long2tile(parseFloat(parcelInfo.longitude), 15)
        var qs = "https://data.osmbuildings.org/0.2/anonymous/tile/15/" + newTile.x + "/" + newTile.y + ".json"
        //test - console.log(qs)
        var tilePointData = await axios.get(qs)
          .catch((error) => console.log("Error in getTiles" + error.message))

        tilePointData.data.features = tilePointData.data.features.concat(msBuildings.data.features)
        var polyLabels = []
        var reducedTilePoints = tilePointData.data.features.filter((tilePoint, index) => {


          // if ((Math.abs(poly[1] - parcelInfo.latitude) < .005) &&
          //   (Math.abs(poly[0] - parcelInfo.longitude) < .005))
          var myPoly = polylabel(tilePoint.geometry.coordinates)
          var vsToSend = parcelInfo.geometry.coordinates
          if (parcelInfo.geometry.coordinates &&
            parcelInfo.geometry.coordinates[0]
            && (parcelInfo.geometry.coordinates[0][0])
            && (parcelInfo.geometry.coordinates[0][0][0].length > 0)) {
            //test - console.log("deep geo")
            vsToSend = parcelInfo.geometry.coordinates[0][0]
          }
          else if (parcelInfo.geometry.coordinates &&
            parcelInfo.geometry.coordinates[0]
            && parcelInfo.geometry.coordinates[0][0].length > 0) {
            //test - console.log("deep geo")
            vsToSend = parcelInfo.geometry.coordinates[0]
          }
          // 
          // if ((myPoly[0] > bounds.minLng) && (myPoly[0] < bounds.maxLng) &&
          //   (myPoly[1] > bounds.minLat) && (myPoly[1] < bounds.maxLat)) {
          //   
          // }
          if (inside([myPoly[0], myPoly[1]], vsToSend)) {
            // var found = polyLabels.indexOf((myPolyArray) => {

            //   if (Math.abs((myPoly[0] - myPolyArray[0]) < .005) &&
            //     (Math.abs((myPoly[1] - myPolyArray[1]) < .005))) {
            //     return true
            //   }
            // })

            // if ((found < 0) && (polyLabels.length < 7)) {

            polyLabels.push(myPoly)
            return true
            // }
          }
        })

        polyLabels.map((myPoly, index) => {
          reducedTilePoints[index].Latitude = myPoly[1]
          reducedTilePoints[index].Longitude = myPoly[0]
        })

        var buildingOptions = {



          latitude: parcelInfo.latitude,
          longitude: parcelInfo.longitude,
          apn: (parcelAPN === "xxx" ? "" : parcelAPN),
          address: (parcelInfo.Address && encodeURIComponent(parcelInfo.Address) || ""),
          city: parcelInfo.City && encodeURIComponent(parcelInfo.City),
          minLat: bounds.minLat,
          maxLat: bounds.maxLat,
          minLng: bounds.minLng,
          maxLng: bounds.maxLng,
          geometry: JSON.stringify(parcelInfo.geometry),
          osmBuildings: JSON.stringify(reducedTilePoints),
          parcelInfo: parcelInfo,
          parcelAPN: parcelAPN,

        }



        // var qs1 = process.env.REACT_APP_DATABASE +
        //   "api/getPropertyInfo?latitude=" +
        //   parcelInfo.latitude +//parcelInfo.center[0] +
        //   "&longitude=" +
        //   parcelInfo.longitude + //parcelInfo.center[1] +
        //   "&apn="
        //   + (parcelAPN === "xxx" ? "" : parcelAPN) +
        //   "&address=" + (parcelInfo.Address && encodeURIComponent(parcelInfo.Address) || "") +
        //   "&city= " + (parcelInfo.City && encodeURIComponent(parcelInfo.City) || "")

        //test - console.log("fetch 2", buildingOptions)
        axios.post(process.env.REACT_APP_DATABASE +
          "api/getPropertyInfo", buildingOptions).then((parcelResultData) => {
            var parcelResult = parcelResultData.data

            if (parcelResultData.data.success === true) {
              // setParcelFetchResults(parcelResult.parcelArray)
              // setOpen(true)


              var qs =
                process.env.REACT_APP_DATABASE +
                "api/validateParcel"


              var bounds = getBounds(parcelInfo.geometry.coordinates && (parcelInfo.geometry.coordinates.length > 0) && parcelInfo.geometry.coordinates[0])
              if (isNaN(bounds.minLat)) {
                bounds = getBounds(parcelInfo.geometry.coordinates)
              }
              if (isNaN(bounds.minLat)) {
                bounds = getBounds(parcelInfo.geometry.coordinates && (parcelInfo.geometry.coordinates.length > 0) && (parcelInfo.geometry.coordinates[0].length > 0) && parcelInfo.geometry.coordinates[0][0])
              }
              var parcelOptions = {

                params: {
                  minLat: bounds.minLat,
                  maxLat: bounds.maxLat,
                  minLng: bounds.minLng,
                  maxLng: bounds.maxLng,
                  geometry: JSON.stringify(parcelInfo.geometry),

                  parcelId: parcelResult.parcelArray[0].id
                },

              }
              axios.get(qs, parcelOptions).then((result) => {
                //test - console.log("This is what we got", parcelResultData.data)
                setParams({
                  parcelAPN: parcelAPN,
                  parcelInfo: parcelInfo,
                  open: true,
                  // setOpen: setOpen,
                  parcelResults: parcelResult.parcelArray,
                  // setParcelFetchResults: setParcelFetchResults

                })
              })


            }
            else {
              // we can't find it, so we are going to create a parcel from the data we have and move on
              
              //test - console.log(parcelInfo)
              var bounds = getBounds(parcelInfo.geometry.coordinates && (parcelInfo.geometry.coordinates.length > 0) && parcelInfo.geometry.coordinates[0])
              var parcelDetail = {
                params: {
                  Latitude: parcelInfo.latitude,
                  Longitude: parcelInfo.longitude,

                  APN: parcelAPN || parcelInfo.APN || parcelInfo.parcelProperties.APN || parcelInfo.parcelProperties.PARNO || parcelInfo.parcelProperties.OBJECTID,

                  Notes: JSON.stringify(parcelInfo.parcelProperties),
                  geometry: parcelInfo.geometry
                }
              }
              axios.get(process.env.REACT_APP_DATABASE +
                "api/createParcel", parcelDetail).then((newParcel) => {
                  
                  var qs =
                    process.env.REACT_APP_DATABASE +
                    "api/validateParcel"


                  var bounds = getBounds(parcelInfo.geometry.coordinates && (parcelInfo.geometry.coordinates.length > 0) && parcelInfo.geometry.coordinates[0])
                  if (isNaN(bounds.minLat)) {
                    bounds = getBounds(parcelInfo.geometry.coordinates)
                  }
                  if (isNaN(bounds.minLat)) {
                    bounds = getBounds(parcelInfo.geometry.coordinates && (parcelInfo.geometry.coordinates.length > 0) && (parcelInfo.geometry.coordinates[0].length > 0) && parcelInfo.geometry.coordinates[0][0])
                  }
                  var parcelOptions = {

                    params: {
                      minLat: bounds.minLat,
                      maxLat: bounds.maxLat,
                      minLng: bounds.minLng,
                      maxLng: bounds.maxLng,
                      geometry: JSON.stringify(parcelInfo.geometry),

                      parcelId: newParcel.data[0].id
                    },

                  }
                  axios.get(qs, parcelOptions).then((result) => {
                    setParams({
                      parcelAPN: parcelAPN,
                      parcelInfo: parcelInfo,
                      open: true,
                      // setOpen: setOpen,
                      minLat: bounds.minLat,
                      maxLat: bounds.maxLat,
                      minLng: bounds.minLng,
                      maxLng: bounds.maxLng,
                      parcelResults: newParcel.data,
                      // setParcelFetchResults: setParcelFetchResults

                    })
                  })
                })

            }
          }).catch((error) => {
            //test - console.log("where is the alert1")
            
            //test - console.log(error.message)
            return (<></>)
          })



      }
      // }

      // })

    }
    // else { // we didn't find any parcels from properties in Bounds so let's check by latitude and longitude
    //   var bounds = getBounds(parcelInfo.geometry.coordinates && (parcelInfo.geometry.coordinates.length > 0) && parcelInfo.geometry.coordinates[0])
    //   if (isNaN(bounds.minLat)) {
    //     bounds = getBounds(parcelInfo.geometry.coordinates)
    //   }
    //   if (isNaN(bounds.minLat)) {
    //     bounds = getBounds(parcelInfo.geometry.coordinates && (parcelInfo.geometry.coordinates.length > 0) && (parcelInfo.geometry.coordinates[0].length > 0) && parcelInfo.geometry.coordinates[0][0])
    //   }

    //   // try arggis microsoft buildings here

    //   var myBoundsArcGIS = bounds.minLng
    //     + "," + bounds.minLat
    //     + "," + bounds.maxLng
    //     + "," + bounds.maxLat




    //   var qsBuildings = "https://services6.arcgis.com/Do88DoK2xjTUCXd1/arcgis/rest/services/OSM_NA_Buildings/FeatureServer/0/query?where=Shape__Area>1000&outFields=Shape__Area,height,levels,addr_street,addr_housenumber,type,name,official_name,addr_city,addr_state,addr_postcode,addr_district,start_date,building_structure,power,description&geometry="

    //     + myBoundsArcGIS + "&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelIntersects&outSR=4326&f=geojson"


    //   axios.get(qsBuildings).then((msBuildings) => {
    //     var newTile = {}
    //     newTile.y = lat2tile(parseFloat(parcelInfo.latitude), 15)
    //     newTile.x = long2tile(parseFloat(parcelInfo.longitude), 15)
    //     var qs = "https://data.osmbuildings.org/0.2/anonymous/tile/15/" + newTile.x + "/" + newTile.y + ".json"
    //     //test - console.log(qs)
    //     axios.get(qs)
    //       .catch((error) => //test - console.log("Error in getTiles" + error.message))
    //       .then((tilePointData) => {

    //         var polyLabels = []

    //         tilePointData.data.features = tilePointData.data.features.concat(msBuildings.data.features)
    //         var reducedTilePoints = tilePointData.data.features.filter((tilePoint, index) => {

    //           //  var poly = polylabel(tilePoint.geometry.coordinates)
    //           var myPoly = polylabel(tilePoint.geometry.coordinates)
    //           var vsToSend = parcelInfo.geometry.coordinates
    //           if (parcelInfo.geometry.coordinates &&
    //             parcelInfo.geometry.coordinates[0]
    //             && (parcelInfo.geometry.coordinates[0][0])
    //             && (parcelInfo.geometry.coordinates[0][0][0].length > 0)) {
    //             //test - console.log("deep geo")
    //             vsToSend = parcelInfo.geometry.coordinates[0][0]
    //           }
    //           else if (parcelInfo.geometry.coordinates &&
    //             parcelInfo.geometry.coordinates[0]
    //             && parcelInfo.geometry.coordinates[0][0].length > 0) {
    //             //test - console.log("deep geo")
    //             vsToSend = parcelInfo.geometry.coordinates[0]
    //           }
    //           // 
    //           // if ((myPoly[0] > bounds.minLng) && (myPoly[0] < bounds.maxLng) &&
    //           //   (myPoly[1] > bounds.minLat) && (myPoly[1] < bounds.maxLat) && (polyLabels.length < 10)) {
    //           //   
    //           // }
    //           if (inside([myPoly[0], myPoly[1]], vsToSend)) {
    //             // var found = polyLabels.indexOf((myPolyArray) => {
    //             //   if (Math.abs((myPoly[0] - myPolyArray[0]) < .005) &&
    //             //     (Math.abs((myPoly[1] - myPolyArray[1]) < .005))) {
    //             //     return true
    //             //   }
    //             // }
    //             // )
    //             // if ((found < 0) && (polyLabels.length < 7)) {

    //             polyLabels.push(myPoly)


    //             return true
    //             // }
    //           }

    //         })

    //         polyLabels.map((myPoly, index) => {
    //           reducedTilePoints[index].Latitude = myPoly[1]
    //           reducedTilePoints[index].Longitude = myPoly[0]
    //         })

    //         var buildingOptions = {



    //           latitude: parcelInfo.latitude,
    //           longitude: parcelInfo.longitude,
    //           apn: (parcelAPN === "xxx" ? "" : parcelAPN),
    //           address: (parcelInfo.Address && encodeURIComponent(parcelInfo.Address) || ""),
    //           city: parcelInfo.City && encodeURIComponent(parcelInfo.City),
    //           minLat: bounds.minLat,
    //           maxLat: bounds.maxLat,
    //           minLng: bounds.minLng,
    //           maxLng: bounds.maxLng,
    //           geometry: JSON.stringify(parcelInfo.geometry),
    //           osmBuildings: JSON.stringify(reducedTilePoints),
    //           parcelInfo: parcelInfo

    //         }
    //         //test - console.log("fetch 2", buildingOptions)
    //         axios.post(process.env.REACT_APP_DATABASE +
    //           "api/getPropertyInfo", buildingOptions).then((parcelResultData) => {

    //             if (!parcelResultData.data) {
    //               return (<></>)
    //             }
    //             else {
    //               var parcelResult = parcelResultData.data


    //               // setParcelFetchResults(parcelResult.parcelArray)
    //               // setOpen(true)
    //               setParams({
    //                 parcelAPN: parcelAPN,
    //                 parcelInfo: parcelInfo,
    //                 open: true,
    //                 // setOpen: setOpen,
    //                 parcelResults: parcelResult.parcelArray,
    //                 // setParcelFetchResults: setParcelFetchResults

    //               })

    //             }
    //           }).catch((error) => {
    //             //test - console.log("where is the alert1")

    //             //test - console.log(error.message)
    //             return (<></>)
    //           })


    //       })
    //   })









    //   // var qs1 = process.env.REACT_APP_DATABASE +
    //   //   "api/getPropertyInfo?latitude=" +
    //   //   parcelInfo.latitude +//parcelInfo.center[0] +
    //   //   "&longitude=" +
    //   //   parcelInfo.longitude + //parcelInfo.center[1] +
    //   //   "&apn="
    //   //   + (parcelAPN === "xxx" ? "" : parcelAPN) +
    //   //   "&address=" + (parcelInfo.Address && encodeURIComponent(parcelInfo.Address) || "") +
    //   //   "&city= " + (parcelInfo.City && encodeURIComponent(parcelInfo.City) || "")






    // }

  }
  else {  // this window isn't open
    // setShowWait(false)
    return <></>
  }
  // }
  //setShowWait(false)

}

export default function TestAlert({ params, setParams }
  //  { parcelAPN, parcelInfo, open, setOpen, parcelFsetParcelFetchResultsetchResults, setParcelFetchResults }
) {

  return (<>
    
    <CustomizedDialogsOut
      params={params}
      setParams={setParams}

    // parcelAPN={params.parcelAPN}
    // parcelInfo={params.parcelInfo}
    // open={params.open}
    // setOpen={params.setOpen}
    // parcelResult={params.parcelResults}
    // setParcelFetchResults={params.setParcelFetchResults}
    /></>)


}

// export default function TestAlert({ hoverInfo, open, setOpen }) {
//   const [parcelShow, setParcelShow] = React.useState(null)


//   var hoverAPN = (hoverInfo.parcelProperties.PARNO &&
//     (hoverInfo.parcelProperties.PARNO.length > 4)
//     && hoverInfo.parcelProperties.PARNO) ||
//     hoverInfo.parcelProperties.APN ||
//     hoverInfo.parcelProperties.apn ||
//     hoverInfo.parcelProperties.mapblklot ||
//     hoverInfo.parcelProperties.PIN_NUM || "xxx"
//   const [returnReady, setReturnReady] = React.useState(null);
//   const [customOpen, setCustomOpen] = useState(false)


//   if (hoverInfo && open) {


//     if (hoverAPN) {
//       var qs =
//         process.env.REACT_APP_DATABASE +
//         "api/getParcelNoDash?"
//       if (hoverAPN !== 'xxx') {
//         qs = qs + "apn=" +
//           (hoverAPN + " ").replaceAll("-", "").replaceAll(" ", "");
//       }

//       //test - console.log(qs)
//       // if (!returnReady) {


//       if (!qs.includes("xxx")) {
//         fetch(qs)
//           .then((results) => {

//             results.json().then((parcelInfo) => {
//               // if (results.id) {
//               var dateOneMonthAgo = new Date();

//               // Set it to one month ago
//               dateOneMonthAgo.setMonth(dateOneMonthAgo.getMonth() - 1);
//               if (parcelInfo && parcelInfo.length > 0 && (new Date(parcelInfo.updatedAt) > dateOneMonthAgo)) {
//                 
//                 setReturnReady(
//                   CustomizedDialogsOut(hoverInfo, open, setOpen, parcelInfo)
//                 );
//               }
//               // }
//               else {
//                 findParcelInfo(hoverInfo, hoverAPN, setReturnReady, setOpen, open)
//               }

//             })

//           })
//           .catch((error) => { //test - console.log(error.message) })
//       }
//       else {
//         findParcelInfo(hoverInfo, hoverAPN, setReturnReady, setOpen, open)
//       }




//       // } else {
//       //   return returnReady;
//       // }
//     } else {
//       if (hoverInfo.parcelProperties.OWNER)
//         setReturnReady(
//           CustomizedDialogsOut(hoverInfo, open, setOpen, null, "Wake")
//         )
//       else {
//         // alert("Invalid Parcel");
//         return <></>;
//       }
//     }

//     return returnReady;

//     //   if (results.ok) {
//     //     var parcelInfo = await results.json();

//     //     if (parcelInfo.length > 0) {
//     //       return CustomizedDialogsOut(hoverInfo, open, setOpen, parcelInfo);
//     //     }
//     //   } else {
//     //     alert(JSON.stringify(results));
//     //   }
//     // }
//     // else

//     return <></>;
//   }
// }


// function findParcelInfo(hoverInfo, hoverAPN, setReturnReady, setOpen, open) {

//   var qs1 = process.env.REACT_APP_DATABASE +
//     "api/getPropertyInfo?latitude=" +
//     hoverInfo.center[0] + "&longitude=" + hoverInfo.center[1] +
//     "&apn="
//     + (hoverAPN === "xxx" ? "" : hoverAPN) +
//     "&address=" + (hoverInfo.Address && encodeURIComponent(hoverInfo.Address) || "") +
//     "&city= " + ((hoverInfo.City !== "") && encodeURIComponent(hoverInfo.City) || "")
//   
//   //test - console.log(qs1)
//   fetch(qs1).then((resultsPropInfo) => {
//     
//     resultsPropInfo.text().then((propertyInfo) => {
//       
//       var theParcels = JSON.parse(propertyInfo)
//       

//       setReturnReady(
//         CustomizedDialogsOut(hoverInfo, open, setOpen, theParcels.parcelArray)
//       );
//     })
//   }).catch((error) => {
//     //test - console.log(error.message)
//   })



// }

export function CustomizedDialogsOut({ params, setParams, district }) {

  // const [open, setOpen] = React.useState(true);
  // var hoverAPN = parcelInfo.parcelProperties.PARNO ||
  //   parcelInfo.parcelProperties.APN ||
  //   parcelInfo.parcelProperties.apn ||
  //   parcelInfo.parcelProperties.mapblklot ||
  //   (parcelInfo && parcelInfo.length > 0 && parcelInfo[0].APN) || null
  var nfObject = new Intl.NumberFormat("en-US");
  var nfObjectRate = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });



  const handleClose = () => {
    var newparams = JSON.parse(JSON.stringify(params))
    newparams.open = false;
    setParams(null)
  };

  if (!params || !params.parcelResults) {

    return <></>
  }


  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={params.open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {
            "Parcel: " +
            (params.parcelResults && (params.parcelResults.length > 0) && params.parcelResults[0].APN ?
              params.parcelResults[0].APN + " | " + params.parcelResults[0].StreetNumber + " " + params.parcelResults[0].StreetName
              + ", " + params.parcelResults[0].City
              : "")
          }
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {/* {  params.parcelInfo && params.parcelInfo.img && params.parcelInfo.img.includes(".TIF" ) ? <TIFFViewer tiff={params.parcelInfo.img}/> :    */}
          <img
            src={
              // params.parcelInfo && params.parcelInfo.img
              //   ? params.parcelInfo.img
              //   :
              params.parcelInfo.center[0]
                ?
                "https://dev.virtualearth.net/REST/V1/Imagery/Map/Birdseye/" +
                params.parcelInfo.center[0] +
                "," +
                params.parcelInfo.center[1] +
                "/20?dir=270&ms=900,700&key=AntwMqF4SuxAC8AfmB9-AuOVzrtOhbiNMZFVVe68dkbt5IjzUIzmWS8dfFmo9-IL"
                : "https://images.cubicsearch.com/3dgifmaker05280.gif"

            }
            className="cursor-pointer w-full  h-full"
            onClick={() => {
              if (params.parcelResults.length > 0) {
                window.open("parcel?id=" + params.parcelResults[0].id);
              } else {
                alert("This parcel is missing from the database!");
              }
              //  window.open("property?id=" + popupInfo.id);
            }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = 'https://images.cubicsearch.com/small%20logo.png'; // prevents looping
              currentTarget.src =
                // property && property.Latitude ?
                // "https://dev.virtualearth.net/REST/V1/Imagery/Map/Birdseye/" +
                // property.Latitude.toString().trim() +
                // "," +
                // property.Longitude.toString().trim() +
                // "/20?dir=270&ms=900,700&key=AntwMqF4SuxAC8AfmB9-AuOVzrtOhbiNMZFVVe68dkbt5IjzUIzmWS8dfFmo9-IL"  :
                "https://images.cubicsearch.com/small%20logo.png";
            }}
          />
          {/* } */}
          <div className="w-full">
            <div>
              <p className="text-left text-md font-semibold">
                Acres:{" "}
                {(() => {

                  return (params && params.parcelResults && (params.parcelResults.length > 0) &&
                    (params.parcelResults[0].Acres > 0) ?
                    nfObject.format(params.parcelResults[0].Acres) : "Research")

                }
                )()}
              </p>
              <p className="text-left text-md font-semibold">
                {params.parcelResults && (params.parcelResults.length > 0) && params.parcelResults[0]
                  ? params.parcelResults[0] && params.parcelResults[0].LastMarketSaleDate && params.parcelResults[0].LastMarketSalePrice > 10
                    ? "Sale Details:" +
                    new Date(params.parcelResults[0].LastMarketSaleDate).toLocaleDateString() +
                    " " +
                    // params.parcelResults[0].LastMarketSalePrice
                    moneyConvert(params.parcelResults[0].LastMarketSalePrice)
                    : "Assessed Value:" + moneyConvert(params.parcelResults[0].AssessedTotalValue)
                  : " "}
              </p>
              <p className="text-left text-md font-semibold">
                Owner:{" "}
                {(() => {

                  return (
                    params.parcelResults.length > 0
                      ? (params.parcelResults[0].Owner1FirstName || "") +
                      " " +
                      params.parcelResults[0].Owner1LastName +
                      " - " +
                      (params.parcelResults[0].MailingStreetNumber || "")
                      + (params.parcelResults[0].MailingStreetName || "") +

                      (params.parcelResults[0].MailingCity || "") +
                      " " +

                      (params.parcelResults[0].MailingState || "")
                      : "")
                })()}
              </p>
            </div>
            <div className="w-full h-full underline">
              {params.parcelInfo.img && params.parcelInfo.img.trim().length > 10 ? <a href={params.parcelInfo.img}>
                Download Parcel Map for APN
                {params.parcelAPN}</a> : <></>}


            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {

              if (params.parcelResults.length > 0) {
                if (!params.parcelResults[0].id) {
                  //test - console.log("AAAAAH", params.parcelResults[0])
                  alert("Please refresh this parcel by clicking on it again, it is still loading")
                }
                else {
                  window.open("parcel?id=" + params.parcelResults[0].id);
                }
                ;
              } else {
                alert("This parcel is missing from the database!");
              }
              handleClose()
            }}
          >
            Go to Parcel Detail Page
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

function moneyConvert(stringIn) {
  var nfObjectRate = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  if (stringIn && (stringIn.length > 0) && isNaN(stringIn)) {


    stringIn = stringIn.replaceAll("$", "")
    stringIn = stringIn.replaceAll(",", "")


  }


  return nfObjectRate.format(stringIn)
}