import React, { useEffect, useState } from 'react';
import { Avatar, AvatarGroup, CircularProgress, Dialog, DialogTitle, Typography, Button, Tooltip, Badge } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import axios from 'axios';
import FindContactsResultDialog from './FindContactsResultDialog';


function PeopleAvatars({ office, getPeople, contactId, mailListId, Title, setSearchMethod, recipients, fetchData }) {
    const [people, setPeople] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);

    const getPeopleAPI = async (officeAPI) => {
        var peopleResult = await axios.post(process.env.REACT_APP_DATABASE + "api/enhanceSingleBusinessAndFindPeople",
            {
                office: officeAPI,
                contactId: contactId
            }
        ).catch((error) => { console.log(error) })

        if (peopleResult?.data) {
            if (peopleResult.data?.people) {
                setPeople(peopleResult.data?.people)
            }
            else {
                console.log("no office or people")
            }
        }
        else {
            console.log("api failed in people avatars")
        }
        setLoading(false)
    }

    useEffect(() => {
        if (office && getPeople) {
            setLoading(true)
            getPeopleAPI(office)
        }

    }, [getPeople]);

    if (!getPeople) { return <></> }

    if (loading) {
        return <CircularProgress />;
    }

    const handleAvatarClick = (event) => {
        event.stopPropagation();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const renderAvatar = (person, index) => {
        const fullName = `${person.FirstName} ${person.LastName}`;
        const tooltipTitle = (
            <>
                {fullName}<br />
                {person.Title}<br />
                {person.Email ? person.Email : <strong>No email, click to get it</strong>}<br />
                <em>Click to choose people for list</em>
            </>
        );
        let avatarContent = person?.images?.[0]?.img ? <img alt={person.name} src={person?.images?.[0]?.img} onError={(e) => { e.target.onerror = null; e.target.src = "defaultAvatar.png"; }} /> :
            (person?.FirstName?.[0] || "n/a");
        return (
            <Tooltip key={person.Email + index} title={tooltipTitle} placement={'top'}>
                <Badge
                    color="error"
                    overlap="circular"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    badgeContent={!person.Email ? <Cancel style={{ color: 'white', fontSize: '0.9rem' }} /> : null}
                >
                    <Avatar key={index} onClick={handleAvatarClick}>
                        {avatarContent}
                    </Avatar>
                </Badge>
            </Tooltip>
        );
    };

    const extraCount = people.length > 5 ? (people.length - 5) : null;

    if (people && people?.length > 0) {
        

        return (
            <>
                <AvatarGroup max={5}>
                    {people?.map(renderAvatar)}

                </AvatarGroup>
                <ClickStopper >
                    <FindContactsResultDialog
                        open={open}
                        onClose={() => {
                            setOpen(false)
                            // handleClose()
                            // setSearchMethod('')
                        }}
                        contacts={people}
                        setContacts={setPeople}
                        contactId={contactId}
                        mailListId={mailListId}
                        Title={Title}
                           recipients={recipients}
                                        fetchData={fetchData}
                    />
                </ClickStopper >
            </>
        );
    }
    else {
        return (<>
            <Typography>No results found in Cubic Search
                <Button
                    onClick={async () => {

                        console.log(office)
                        setLoading(true)
                        var people = await axios.post(process.env.REACT_APP_DATABASE +
                            "api/findPeopleForOfficeLocation",
                            office).catch((error) => { console.log(error) })

                        setLoading(false)
                        if (people?.data) {
                            setPeople(people.data)
                            setOpen(true)
                        }
                    }}>Search For More
                </Button>
            </Typography>
            {loading && <CircularProgress />}
            <ClickStopper>
                <FindContactsResultDialog
                    open={open}
                    onClose={() => {
                        setOpen(false)
                        // handleClose()
                        setSearchMethod('')
                    }}
                    contacts={people}
                    setContacts={setPeople}
                    contactId={contactId}
                    mailListId={mailListId}
                    Title={Title}
                       recipients={recipients}
                                        fetchData={fetchData}
                />
            </ClickStopper>
        </>)
    }
}

export default PeopleAvatars;
const ClickStopper = ({ children }) => {
    const handleClick = (event) => {
        event.stopPropagation();
    };

    return (
        <div onClick={handleClick}>
            {children}
        </div>
    );
};